import { Button, TextInput, Tile } from 'carbon-components-react';
import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Draggable20, TrashCan20 } from '@carbon/icons-react';
import { useFormContext } from 'react-hook-form';
import { ContentEditor } from 'app/components/ContentEditor';
import EditorJS from '@editorjs/editorjs';
import {
  AboutUsListControl,
  SortableItemProps,
} from 'modules/static-pages/components/StaticPageForm/AboutUsListControl';
import { hasPath, path } from 'ramda';

const StyledTextInput = styled(TextInput)`
  cursor: default;
`;

const StyledTile = styled(Tile)`
  margin: 0.125rem 0;
  padding-bottom: 0;
`;

const Row = styled.div`
  align-items: flex-start;
  display: flex;
`;

const SortableItem: FC<SortableItemProps> = ({
  controlId,
  index,
  onDelete,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: controlId });

  const {
    formState: { errors },
    register,
  } = useFormContext();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || '',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <StyledTile>
        <Row>
          <Button
            hasIconOnly
            iconDescription="Przenieś"
            kind="ghost"
            renderIcon={Draggable20}
            size="small"
            tooltipAlignment="center"
            tooltipPosition="top"
            {...listeners}
          />

          <StyledTextInput
            hideLabel={true}
            id={`${controlId}_row_${index}`}
            invalid={hasPath(`content.${index}.heading`.split('.'), errors)}
            invalidText={path(
              `content.${index}.heading.message`.split('.'),
              errors
            )}
            labelText=""
            size="sm"
            {...register(`content.${index}.heading` as const)}
          />

          <Button
            hasIconOnly
            iconDescription="Usuń"
            kind="danger--ghost"
            onClick={() => onDelete(controlId)}
            renderIcon={TrashCan20}
            size="small"
            tooltipAlignment="center"
            tooltipPosition="top"
          />
        </Row>

        <ContentEditor name={`content.${index}.content`} />
      </StyledTile>
    </div>
  );
};

export interface StaticPageContentControlProps {
  label?: string;
  name: string;
}

export const StaticPageContentControl: FC<StaticPageContentControlProps> = (
  props
) => {
  const getEmptyItem = useCallback(
    () => ({
      content: {
        blocks: [],
        time: +new Date(),
        version: EditorJS.version,
      },
      heading: '',
    }),
    []
  );

  return (
    <AboutUsListControl
      {...props}
      getEmptyItem={getEmptyItem}
      SortableItem={SortableItem}
    />
  );
};
