export const createRootRoute = (...args: string[]) =>
  `${['', ...args].join('/')}`;

export const createRoute = (...args: string[]) => `${[...args].join('/')}`;

export const setParam = (param: string) => (route: string) => (value: string) =>
  route.replace(param, value);

export const setParams =
  (...params: string[]) =>
  (route: string) =>
  (...values: string[]) =>
    params.reduce((curr, param, i) => curr.replace(param, values[i]), route);
