import { useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { RootRoute } from 'routes';
import { push } from 'connected-react-router';
import { snackbarActions } from 'state/notification/notificationActions';
import { useDispatch } from 'react-redux';
import { getStaticPage } from 'modules/static-pages/services/staticPage';
import { StaticPageTos } from 'modules/static-pages/models/staticPage';
import { StaticPageCode } from 'modules/static-pages/models/staticPageCode';

export const useStaticPageQuery = (
  staticPageCode: StaticPageCode,
  onSuccess: (data: StaticPageTos) => void
) => {
  const dispatch = useDispatch();

  return useQuery(
    [QueryKey.StaticPage, staticPageCode],
    () => getStaticPage(staticPageCode),
    {
      onError: () => {
        dispatch(push(RootRoute));
        dispatch(
          snackbarActions.enqueueSnackbar({
            title: 'Wybrana strona statyczna nie została znaleziona',
            options: { variant: 'error' },
          })
        );
      },
      onSuccess,
    }
  );
};
