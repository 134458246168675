import { productiveHeading01 } from '@carbon/type';
import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { textOverflow } from 'common/styles/typography';
import { FileUploaderProgressBar } from 'app/components/FileUploader/FileUploaderProgressBar';
import { spacing02, spacing03 } from '@carbon/layout';
import { Close20 } from '@carbon/icons-react';
import { gray80, white } from '@carbon/colors';
import { ConfirmationModal } from 'common/components/ConfirmationModal';
import {
  FileUploaderStatus,
  fileUploaderStatusColorMapper,
} from 'common/models/fileUploader';
import { createFileUploaderItemSelector } from 'state/fileUploader/fileUploaderSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { fileUploaderActions } from 'state/fileUploader/fileUploaderActions';

const CloseButton = styled.button`
  background: transparent;
  border: none;
  color: ${white};
  cursor: pointer;
  padding: ${spacing02};
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color 110ms, border-color 110ms;

  &:hover {
    background: ${gray80};
  }

  svg {
    display: block;
  }
`;

const Container = styled.div<{ $status: FileUploaderStatus }>`
  border-left: ${spacing02} solid
    ${({ $status }) => fileUploaderStatusColorMapper[$status]};
  margin-bottom: ${spacing02};
  padding: ${spacing03};
  position: relative;
`;

const Label = styled.div`
  ${productiveHeading01};
  ${textOverflow(1)};

  padding-right: 1rem;
`;

export interface FileUploaderItemProps {
  itemId: string;
}

export const FileUploaderItem: FC<FileUploaderItemProps> = ({ itemId }) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const fileUploaderItemSelector = useMemo(createFileUploaderItemSelector, []);

  const item = useSelector((state: RootState) =>
    fileUploaderItemSelector(state, itemId)
  );

  return (
    <Container $status={item.status}>
      <Label>{item.title}</Label>

      <FileUploaderProgressBar
        progress={item.progress.current}
        status={item.status}
        total={item.progress.total}
      />

      <CloseButton
        onClick={() => {
          item.status === FileUploaderStatus.Uploading
            ? setShowDeleteModal(true)
            : dispatch(fileUploaderActions.removeItem(itemId));
        }}
      >
        <Close20 />
      </CloseButton>

      <ConfirmationModal
        buttonSubmitText="Przerwij"
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={() => {
          dispatch(fileUploaderActions.cancelUploading(itemId));
        }}
        text="Czy na pewno chcesz przerwać wysyłanie wybranego pliku?"
        title="Przerwij wysyłanie pliku"
      />
    </Container>
  );
};
