import { Category } from 'common/models/category';
import { StoredImageDTO } from 'common/models/image';
import { validateSize } from 'lib/validateSize';
import { BannerSubpage } from 'modules/banner/types.banner';
import * as yup from 'yup';

const ALLOWED_MIMES = ['image/jpeg', 'image/png'];

export function bannerSchema() {
  return yup.object({
    title: yup.string().min(1, 'Pole obowiązkowe'),
    redirectURL: yup.string(),
    displayStatus: yup.string().min(1, 'Pole obowiązkowe'),
    page: yup.string().min(1, 'Pole obowiązkowe'),
    category: yup.mixed<Category>().nullable().when('page', {
      is: BannerSubpage.CATEGORY,
      then: yup.object().required(),
    }),
    upload: yup
      .mixed<File | StoredImageDTO | null>()
      .test({
        message: 'Grafika jest wymagana',
        test: Boolean,
      })
      .test({
        message: 'Niepoprawny typ pliku (.jpg, .jpeg, .png)',
        test: (value) => {
          if (!value) {
            return false;
          }

          if (!(value instanceof File)) {
            return true;
          }

          return ALLOWED_MIMES.includes(value.type);
        },
      })
      .test({
        message: 'Niepoprawny rozmiar (max 684px x 365px)',
        test: async (value) => {
          if (value instanceof File) {
            return await validateSize(value, {
              height: 365,
              width: 684,
              mimes: ALLOWED_MIMES,
            });
          } else {
            return true;
          }
        },
      }),
  });
}
