export enum QueryKey {
  AboutUsStaticPage = 'aboutUsStaticPage',
  AllCategoryList = 'allCategoryList',
  BannerList = 'bannerList',
  Category = 'category',
  CategoryList = 'categoryList',
  CertificateSettings = 'certificateSettings',
  Course = 'course',
  CourseBundle = 'courseBundle',
  CourseBundleAssignableElearnings = 'courseBundleAssignableElearnings',
  CourseBundleAssignableEvents = 'courseBundleAssignableEvents',
  CourseBundleAssignableSpeakers = 'courseBundleAssignableSpeakers',
  CourseBundleElearningList = 'courseBundleElearningList',
  CourseBundleEventList = 'courseBundleEventList',
  CourseEvent = 'courseEvent',
  CourseEventList = 'courseEventList',
  CourseEventParticipantList = 'courseEventParticipantList',
  CourseList = 'courseList',
  CourseListByCategory = 'courseListByCategory',
  Elearning = 'elearning',
  ElearningLesson = 'elearningLesson',
  ElearningLessonList = 'elearningLessonList',
  ElearningLessonVideo = 'elearningLessonVideo',
  ElearningPreview = 'elearningPreview',
  Material = 'material',
  Partner = 'partner',
  RefreshToken = 'refresh-token',
  ReportsCourseEventList = 'reports-course-event-list',
  ReportsCourseList = 'reports-course-list',
  ReportsElearningList = 'reports-elearning-list',
  Speaker = 'speaker',
  SpeakerList = 'speakerList',
  StaticPage = 'staticPage',
  Survey = 'survey',
  SurveyList = 'surveyList',
  SystemSettings = 'systemSettings',
  TrainerList = 'trainerList',
  UserList = 'userList',
  WebinarStartUrl = 'webinarStartUrl',
  DashboardEvents = 'dashboardEvents',
}
