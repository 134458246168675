import { QueryObserverOptions, useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { ResultResponse } from 'common/models/response';
import { Course } from 'common/models/course';
import { getCourseListByCategory } from 'common/services/course';
import { ID } from 'lib/id';

export const useCourseListByCategoryQuery = (
  categoryId: ID,
  options?: QueryObserverOptions<ResultResponse<Course>>
) => {
  return useQuery<ResultResponse<Course>>(
    [QueryKey.CourseListByCategory, categoryId],
    () => getCourseListByCategory(categoryId),
    {
      ...options,
      keepPreviousData: true,
    }
  );
};
