import {
  Button,
  DataTableCustomRenderProps,
  FileUploaderButton,
  Loading,
  TableBody,
  TableExpandHeader,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react';
import {
  Table,
  TableContainer,
  TableContent,
  TableLoader,
} from 'app/components/Table';
import React, { ChangeEvent, FC, useCallback } from 'react';
import { MaterialsCenteredTableHeader } from 'app/components/Materials/MaterialsTable';
import { MaterialsTableRow } from 'app/components/Materials/MaterialsTableRow';
import { Add20 } from '@carbon/icons-react';
import styled from 'styled-components';
import { Material, MaterialResource } from 'common/models/material';
import { useDispatch } from 'react-redux';
import { materialsActions } from 'state/materials/materialsActions';
import { UPLOAD_ALLOWED_MIME, UPLOAD_MAX_SIZE } from 'config/courseMaterials';
import { getExtension } from 'common/services/file';
import { snackbarActions } from 'state/notification/notificationActions';
import fileSize from 'filesize';
import { isEmpty } from 'ramda';
import { DataTableEmptyRow } from 'app/components/DataTableEmptyRow';
import {
  validatorMessages,
  validFileNameRegExp,
} from 'common/services/validation';

const StyledFileUploaderButton = styled(FileUploaderButton)`
  margin-right: 1px;

  .bx--btn__icon {
    top: 50%;
    transform: translateY(-50%);
  }
`;

export interface MaterialsDataTableContentProps {
  materialsMap: { [id: string]: Material };
  isFetching: boolean;
  onNewFiles?: (event: ChangeEvent<HTMLInputElement>) => void;
  title: string;
  resource: MaterialResource;
}

export const MaterialsTableContent: FC<
  MaterialsDataTableContentProps & DataTableCustomRenderProps
> = ({
  title,
  materialsMap,
  isFetching,

  rows,
  headers,
  getBatchActionProps,
  getTableProps,
  getTableContainerProps,
  getToolbarProps,
  getHeaderProps,
  getRowProps,
}) => {
  const dispatch = useDispatch();

  const handleNewFiles = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const validFiles: File[] = [];

    if (!event.target.files) {
      return;
    }

    Array.from(event.target.files).forEach((file) => {
      let isValid = true;

      if (!file.name.match(validFileNameRegExp)) {
        isValid = false;
        dispatch(
          snackbarActions.enqueueSnackbar({
            title: validatorMessages.invalidFileName,
            subtitle: validatorMessages.validFileName,
            options: { variant: 'error' },
          })
        );
      }

      if (file.size > UPLOAD_MAX_SIZE) {
        isValid = false;
        dispatch(
          snackbarActions.enqueueSnackbar({
            title: `Rozmiar pliku "${file.name}" jest zbyt duży`,
            subtitle: `Plik powinien mieć maksymalnie ${fileSize(
              UPLOAD_MAX_SIZE
            )}`,
            options: { variant: 'error' },
          })
        );
      }

      if (!UPLOAD_ALLOWED_MIME.includes(`.${getExtension(file.name)}`)) {
        isValid = false;
        dispatch(
          snackbarActions.enqueueSnackbar({
            title: `Nieprawidłowy format pliku "${file.name}"`,
            subtitle: `Obsługiwane formaty to: ${UPLOAD_ALLOWED_MIME.join(
              ', '
            )}`,
            options: { variant: 'error' },
          })
        );
      }

      if (isValid) {
        validFiles.push(file);
      }
    });

    dispatch(materialsActions.addFiles(validFiles));
  }, []);

  const openUrlFormModal = useCallback((courseMaterial) => {
    dispatch(
      materialsActions.showUrlFormModal({
        material: courseMaterial,
      })
    );
  }, []);

  return (
    <TableContainer title={title} {...getTableContainerProps()}>
      <TableToolbar {...getToolbarProps()}>
        <TableToolbarContent>
          <StyledFileUploaderButton
            accept={UPLOAD_ALLOWED_MIME}
            buttonKind="primary"
            disableLabelChanges={true}
            labelText={
              <>
                Dodaj nowy plik
                <Add20 className="bx--btn__icon" />
              </>
            }
            multiple={true}
            onChange={handleNewFiles}
            size="small"
            tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
          />
          <Button
            kind="primary"
            onClick={() => openUrlFormModal(null)}
            renderIcon={Add20}
            size="small"
            tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
          >
            Dodaj nowy url
          </Button>
        </TableToolbarContent>
      </TableToolbar>

      <TableContent>
        <Table $loading={isFetching} {...getTableProps()}>
          <TableHead>
            <TableRow>
              <TableExpandHeader />

              {headers.map((header) => {
                if (header.key === 'type') {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <MaterialsCenteredTableHeader
                      {...getHeaderProps({ header })}
                    >
                      {header.header}
                    </MaterialsCenteredTableHeader>
                  );
                }
                return (
                  // eslint-disable-next-line react/jsx-key
                  <TableHeader {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                );
              })}

              <TableHeader />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(rows) &&
              rows.map(
                (row) =>
                  materialsMap[row.id] && (
                    // eslint-disable-next-line react/jsx-key
                    <MaterialsTableRow
                      material={materialsMap[row.id]}
                      getRowProps={getRowProps}
                      key={row.id}
                      row={row}
                    />
                  )
              )}

            {isEmpty(rows) && <DataTableEmptyRow colSpan={4} />}
          </TableBody>
        </Table>

        {isFetching && (
          <TableLoader>
            <Loading active={true} withOverlay={false} />
          </TableLoader>
        )}
      </TableContent>
    </TableContainer>
  );
};
