import {
  Button,
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import { Edit20 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { useDeleteSpeakerMutation } from 'common/hooks/speaker/useDeleteSpeakerMutation';
import { SpeakerFormRoute } from 'routes';
import { ActionsTableCell } from 'app/components/Table';
import { FC } from 'react';
import { DataTableDeleteButton } from 'app/components/DataTableDeleteButton';
import { setParam } from 'common/utils/routing';

export interface SpeakerListDataTableRowProps {
  onDeleteSuccess?: (row: DenormalizedRow) => void;
  row: DenormalizedRow;
}

export const SpeakerListDataTableRow: FC<
  SpeakerListDataTableRowProps & Pick<DataTableCustomRenderProps, 'getRowProps'>
> = ({ onDeleteSuccess, getRowProps, row }) => {
  const deleteMutation = useDeleteSpeakerMutation();

  return (
    <TableRow {...getRowProps({ row })}>
      {row.cells.map((cell) => (
        <TableCell key={cell.id}>{cell.value}</TableCell>
      ))}

      <ActionsTableCell>
        <Button
          as={Link}
          hasIconOnly
          iconDescription="Edytuj"
          kind="ghost"
          renderIcon={Edit20}
          size="small"
          to={setParam(':id')(SpeakerFormRoute)(row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <DataTableDeleteButton
          confirmationTitle="Usuń wykładowcę"
          isLoading={deleteMutation.isLoading}
          onSubmit={() =>
            deleteMutation.mutate(row.id, {
              onSuccess: () => onDeleteSuccess && onDeleteSuccess(row),
            })
          }
        />
      </ActionsTableCell>
    </TableRow>
  );
};
