import React, { FC, ReactNode } from 'react';
import { Modal } from 'carbon-components-react';
import { Portal } from 'common/components/Portal';

export interface ConfirmationModalProps {
  buttonSubmitText: string;
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  text: ReactNode;
  title: string;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  buttonSubmitText,
  onClose,
  onSubmit,
  open,
  text,
  title,
}) => {
  return (
    <Portal>
      <Modal
        danger
        modalHeading={title}
        onRequestClose={onClose}
        onRequestSubmit={onSubmit}
        open={open}
        primaryButtonText={buttonSubmitText}
        secondaryButtonText="Anuluj"
        size="sm"
      >
        {text}
      </Modal>
    </Portal>
  );
};
