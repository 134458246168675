export interface ResultResponse<T> {
  result: T[];
  totalResult: number;
}

export const mapPageResults: <T, R>(
  mapper: (x: T) => R
) => (x: ResultResponse<T>) => ResultResponse<R> =
  (mapper) =>
  ({ result, ...rest }) => ({ result: result.map(mapper), ...rest });

export function unwrap<T>({ result }: ResultResponse<T>): T[] {
  return result;
}
