import { useMutation } from 'react-query';
import { updateCourseCategory } from 'common/services/course';
import { ID } from 'lib/id';

export interface UpdateCourseCategoryMutationParams {
  categoryId: ID;
  courseId: ID;
}

export const useUpdateCourseCategoryMutation = (onSuccess: () => void) =>
  useMutation<unknown, unknown, UpdateCourseCategoryMutationParams>(
    ({ categoryId, courseId }) => updateCourseCategory(courseId, categoryId),
    {
      onSuccess,
    }
  );
