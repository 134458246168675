import { FC, useEffect, useState } from 'react';

export interface ImageFromFileProps {
  file: File;
}

export const ImageFromFile: FC<ImageFromFileProps> = ({ file }) => {
  const [data, setData] = useState<string | null>(null);

  useEffect(() => {
    const fileReader = new FileReader();

    setData(null);

    fileReader.onload = (event) => {
      setData((event.target?.result as string) ?? null);
    };

    fileReader.readAsDataURL(file);

    return () => {
      fileReader.abort();
    };
  }, [file]);

  if (!data) {
    return <></>;
  }

  return <img src={data} />;
};
