import {
  CourseCategoriesDTO,
  CourseCategory,
  CourseLevel,
  CourseParameter,
  CourseParameterDTO,
  CourseSpeakerDTO,
  ListItem,
  mapCourseParametersToDTO,
} from 'common/models/course';
import { Speaker } from 'common/models/speaker';
import { SortDirection } from 'common/models/pagination';
import { pipe } from 'fp-ts/function';
import { find } from 'ramda';
import { ID } from 'lib/id';
import { StoredImageDTO } from 'common/models/image';
import { statusLabelMap } from 'common/models/status';
import { TableColumnSettings } from 'common/models/tableSettings';

export enum ElearningListColumn {
  CategoryName = 'CATEGORY_NAME',
  CourseCreateDate = 'COURSE_CREATE_DATE',
  CourseModificationDate = 'COURSE_MODIFICATION_DATE',
  CourseSpeaker = 'COURSE_SPEAKER',
  CourseTitle = 'COURSE_TITLE',
  Status = 'STATUS',
}

export const elearningListColumnSettings: Record<
  ElearningListColumn,
  TableColumnSettings
> = {
  [ElearningListColumn.CategoryName]: { sortable: true },
  [ElearningListColumn.CourseCreateDate]: { sortable: true },
  [ElearningListColumn.CourseModificationDate]: { sortable: true },
  [ElearningListColumn.CourseSpeaker]: { sortable: false },
  [ElearningListColumn.CourseTitle]: { sortable: true },
  [ElearningListColumn.Status]: { sortable: true },
};

export enum ElearningStatus {
  Draft = 'DRAFT',
  Public = 'PUBLIC',
}

export interface Elearning {
  readonly coverPhoto: StoredImageDTO;
  readonly createDate: string;
  readonly id: number;
  readonly image: StoredImageDTO;
  readonly modificationTime: string;
  readonly surveyId: number;

  averageRating: number;
  categories: CourseCategory[];
  certificatePoints: CourseParameter[];
  comment?: string;
  description: string;
  duration: number;
  elearningStatus: ElearningStatus;
  keywords: CourseParameter[];
  learningPoints: CourseParameter[];
  level?: CourseLevel;
  recommendationPercent?: number;
  slug: string;
  speakers: Speaker[];
  survey: number;
  targetGroups: CourseParameter[];
  title: string;
  visible: boolean;
}

export interface ElearningFormModel {
  additionalCategories: string[];
  averageRating: number;
  certificatePoints: CourseParameter[];
  comment?: string;
  coverPhotoFile?: File;
  currentCoverPhoto: string;
  currentImage: string;
  description: string;
  duration: number;
  elearningStatus: ElearningStatus;
  imageFile?: File;
  keywords: CourseParameter[];
  learningPoints: CourseParameter[];
  level?: CourseLevel;
  mainCategory?: string;
  recommendationPercent?: number;
  slug: string;
  speakers: string[];
  survey: string;
  surveyId: string;
  targetGroups: CourseParameter[];
  title: string;
  visible: boolean;
}

export interface ElearningVideoUrl {
  url: string;
}

export interface ElearningUploadVideoFormModel {
  file: File;
}

export interface ElearningBasicInfoDTO {
  category: CourseCategory;
  courseId: ID;
  courseTitle: string;
  createDate: string;
  elearningStatus: ElearningStatus;
  modifyDate: string;
  speakerName: string;
}

export interface ElearningDTO {
  averageRating: number;
  categories: CourseCategoriesDTO[];
  certificatePoints: CourseParameterDTO[];
  comment?: string;
  description: string;
  duration: number;
  elearningStatus: ElearningStatus;
  keywords: CourseParameterDTO[];
  learningPoints: CourseParameterDTO[];
  level?: CourseLevel;
  recommendationPercent?: number;
  slug: string;
  speakers: CourseSpeakerDTO[];
  surveyId: number;
  surveyTemplateId: number | null;
  targetGroups: CourseParameterDTO[];
  title: string;
  visible: boolean;
}

export interface ElearningCreateDTO {
  coverPhoto: File;
  elearning: ElearningDTO;
  image: File;
}

export interface ElearningUpdateDTO {
  coverPhoto?: File;
  id: ID;
  elearning: ElearningDTO;
  image?: File;
}

export interface ElearningSortData {
  direction: SortDirection;
  orderBy: ElearningListColumn;
}

export const initialElearningFormValues: Partial<ElearningFormModel> = {
  additionalCategories: [],
  certificatePoints: [],
  comment: '',
  description: '',
  elearningStatus: ElearningStatus.Draft,
  keywords: [],
  learningPoints: [],
  mainCategory: '',
  slug: '',
  speakers: [],
  survey: '',
  surveyId: '',
  targetGroups: [],
  title: '',
  visible: true,
};

export const elearningStatuses: ListItem[] = [
  {
    id: ElearningStatus.Draft,
    label: statusLabelMap[ElearningStatus.Draft],
  },
  {
    id: ElearningStatus.Public,
    label: statusLabelMap[ElearningStatus.Public],
  },
];

export const mapToElearningDTO = ({
  additionalCategories,
  averageRating,
  certificatePoints,
  comment,
  description,
  duration,
  elearningStatus,
  keywords,
  learningPoints,
  level,
  mainCategory,
  recommendationPercent,
  slug,
  speakers,
  survey,
  surveyId,
  targetGroups,
  title,
  visible,
}: ElearningFormModel): ElearningDTO => ({
  averageRating: Number(averageRating.toString().replace(/,/g, '.')),
  categories: [
    { id: Number(mainCategory), main: true },
    ...additionalCategories
      .filter((item) => Number(item) !== Number(mainCategory))
      .map((item) => ({ id: Number(item), main: false })),
  ],
  certificatePoints: certificatePoints.map(mapCourseParametersToDTO),
  duration: Number(duration),
  keywords: keywords.map(mapCourseParametersToDTO),
  learningPoints: learningPoints.map(mapCourseParametersToDTO),
  recommendationPercent: Number(recommendationPercent),
  speakers: speakers.map((speakerId) => ({ id: speakerId })),
  surveyId: Number(surveyId),
  surveyTemplateId: Number(survey),
  targetGroups: targetGroups.map(mapCourseParametersToDTO),
  comment,
  description,
  elearningStatus,
  level,
  slug,
  title,
  visible,
});

export const mapToElearningCreateDTO = (
  params: ElearningFormModel
): ElearningCreateDTO => ({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  coverPhoto: params.coverPhotoFile!,
  elearning: mapToElearningDTO(params),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  image: params.imageFile!,
});

export const mapToElearningUpdateDTO = (
  params: ElearningFormModel,
  id: ID
): ElearningUpdateDTO => ({
  coverPhoto: params.coverPhotoFile,
  elearning: mapToElearningDTO(params),
  image: params.imageFile,
  id,
});

export const mapToElearningFormModel = ({
  averageRating,
  categories,
  certificatePoints,
  comment,
  coverPhoto,
  description,
  duration,
  elearningStatus,
  image,
  keywords,
  learningPoints,
  level,
  recommendationPercent,
  slug,
  speakers,
  surveyId,
  targetGroups,
  title,
  visible,
}: Elearning): ElearningFormModel => ({
  additionalCategories: categories
    .filter((item) => !item.main)
    .map((item) => item.id),
  currentCoverPhoto: coverPhoto.url,
  currentImage: image.url,
  mainCategory: pipe(
    categories,
    find((category) => category.main === true),
    (category) => (category ? category.id : undefined)
  ),
  speakers: speakers.map((item) => item.id),
  survey: surveyId.toString(),
  surveyId: surveyId.toString(),
  averageRating,
  certificatePoints,
  comment,
  description,
  duration,
  elearningStatus,
  keywords,
  learningPoints,
  level,
  recommendationPercent,
  slug,
  targetGroups,
  title,
  visible,
});
