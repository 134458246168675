import { useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { getTrainerList } from 'common/services/trainer';
import { Trainer } from 'common/models/trainer';

const page = 1;
const pageSize = 1000;

export const useAllTrainersListQuery = () => {
  return useQuery<Trainer[]>([QueryKey.TrainerList, page, pageSize], () =>
    getTrainerList({ page, pageSize })
  );
};
