import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withSentryRouting } from '@sentry/react';
import { StaticPageList } from 'modules/static-pages/pages/StaticPageList';
import { StaticPageAboutUsForm } from 'modules/static-pages/pages/StaticPageAboutUsForm';
import { StaticPageCookiesPolicyForm } from 'modules/static-pages/pages/StaticPageCookiesPolicyForm';
import { StaticPagePrivacyPolicyForm } from 'modules/static-pages/pages/StaticPagePrivacyPolicyForm';
import { StaticPageTosForm } from 'modules/static-pages/pages/StaticPageTosForm';
import {
  StaticPageAboutUsFormRoute,
  StaticPageCookiesPolicyFormRoute,
  StaticPageFaqFormRoute,
  StaticPageListRoute,
  StaticPagePrivacyPolicyFormRoute,
  StaticPageTosFormRoute,
} from 'modules/static-pages/routes';
import { StaticPageFaqForm } from 'modules/static-pages/pages/StaticPageFaqForm';

const SentryRoute = withSentryRouting(Route);

const StaticPagesRouter: FC = () => (
  <Switch>
    <SentryRoute path={StaticPageListRoute} exact>
      <StaticPageList />
    </SentryRoute>
    <SentryRoute path={StaticPageAboutUsFormRoute} exact>
      <StaticPageAboutUsForm />
    </SentryRoute>
    <SentryRoute path={StaticPageCookiesPolicyFormRoute} exact>
      <StaticPageCookiesPolicyForm />
    </SentryRoute>
    <SentryRoute path={StaticPageFaqFormRoute} exact>
      <StaticPageFaqForm />
    </SentryRoute>
    <SentryRoute path={StaticPagePrivacyPolicyFormRoute} exact>
      <StaticPagePrivacyPolicyForm />
    </SentryRoute>
    <SentryRoute path={StaticPageTosFormRoute} exact>
      <StaticPageTosForm />
    </SentryRoute>
  </Switch>
);

// eslint-disable-next-line import/no-default-export
export default StaticPagesRouter;
