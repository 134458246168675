import { FC, useCallback, useEffect, useMemo } from 'react';
import { PageHeading } from 'common/components/Heading';
import {
  emptyMaterialList,
  MaterialResource,
  materialsHeaders,
} from 'common/models/material';
import {
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
} from 'carbon-components-react';
import { MaterialsTableContent } from 'app/components/Materials/MaterialsTableContent';
import { MaterialsFileFormModal } from 'app/components/Materials/MaterialsFileFormModal';
import { MaterialsUrlFormModal } from 'app/components/Materials/MaterialsUrlFormModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  materialsFileFormModalOpenSelector,
  materialsMapSelector,
  materialsRowsSelector,
  materialsUrlFormModalOpenSelector,
} from 'state/materials/materialsSelectors';
import { materialsActions } from 'state/materials/materialsActions';
import { useMaterialsQuery } from 'common/hooks/materials/useMaterialsQuery';
import { MaterialsContext } from 'common/contexts/MaterialsContext';

export interface MaterialsProps {
  parentId: string;
  resource: MaterialResource;
  title?: string;
}

export const Materials: FC<MaterialsProps> = ({
  parentId,
  resource,
  title = 'Materiały szkoleniowe',
}) => {
  const dispatch = useDispatch();

  const { data, isFetching, isLoading } = useMaterialsQuery(parentId, resource);

  useEffect(() => {
    dispatch(materialsActions.setMaterials(data || emptyMaterialList));
  }, [data]);

  useEffect(() => {
    dispatch(materialsActions.setParentId(parentId));

    return () => {
      dispatch(materialsActions.clear());
    };
  }, []);

  const materialsMap = useSelector(materialsMapSelector);
  const rows = useSelector(materialsRowsSelector);
  const showFileFormModal = useSelector(materialsFileFormModalOpenSelector);
  const showUrlFormModal = useSelector(materialsUrlFormModalOpenSelector);

  const handleFileFormModalClose = useCallback(() => {
    dispatch(materialsActions.closeFileFormModal());
  }, []);

  const handleUrlFormModalClose = useCallback(() => {
    dispatch(materialsActions.closeUrlFormModal());
  }, []);

  const contextValue = useMemo(
    () => ({
      resource,
    }),
    []
  );

  return (
    <MaterialsContext.Provider value={contextValue}>
      <PageHeading>{title}</PageHeading>

      {isLoading && (
        <DataTableSkeleton headers={materialsHeaders} rowCount={5}>
          {(props: DataTableCustomRenderProps) => (
            <MaterialsTableContent
              {...props}
              materialsMap={{}}
              isFetching={false}
              title={title}
              resource={resource}
            />
          )}
        </DataTableSkeleton>
      )}

      {data && !isLoading && (
        <DataTable
          headers={materialsHeaders}
          overflowMenuOnHover={false}
          rows={rows}
        >
          {(props: DataTableCustomRenderProps) => (
            <MaterialsTableContent
              {...props}
              materialsMap={materialsMap}
              isFetching={isFetching}
              title={title}
              resource={resource}
            />
          )}
        </DataTable>
      )}

      <MaterialsFileFormModal
        onClose={handleFileFormModalClose}
        open={showFileFormModal}
      />

      <MaterialsUrlFormModal
        onClose={handleUrlFormModalClose}
        open={showUrlFormModal}
      />
    </MaterialsContext.Provider>
  );
};
