import React, { FC, useState } from 'react';
import { useDebounce } from 'react-use';
import styled from 'styled-components';
import { FormLabel } from 'carbon-components-react';
import { blue40, gray50, white } from '@carbon/colors';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  locale,
  reactDraftEditorLabels,
} from 'app/components/CourseBundleForm/SectionItem/ReactDraftEditorLabels';

const EditorContainer = styled.div`
  border-bottom: 1px solid ${gray50};
  background-color: ${white};

  .rdw-editor-main {
    padding: 0.35rem 1rem;
  }

  .rdw-editor-toolbar {
    margin: 0;
  }

  .rdw-option-wrapper {
    :hover {
      border-bottom: 1px solid ${white};
      box-shadow: 0 2px ${blue40};
    }
  }

  .rdw-link-modal-target-option {
    display: none;
  }
`;

interface RichTextEditorProps {
  value: string;
  onChange: (...event: unknown[]) => void;
}

export const RichTextEditor: FC<RichTextEditorProps> = ({
  value,
  onChange,
}) => {
  const blocksFromHTML = convertFromHTML(value);
  const initialState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(initialState)
  );

  useDebounce(
    () => {
      const editorStringContents = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      onChange(editorStringContents);
    },
    400,
    [editorState]
  );

  return (
    <div>
      <FormLabel>Opis</FormLabel>
      <EditorContainer>
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbar={{
            options: ['list', 'link'],
            link: {
              defaultTargetOption: '_blank',
            },
          }}
          localization={{
            locale: locale,
            translations: reactDraftEditorLabels,
          }}
        />
      </EditorContainer>
    </div>
  );
};
