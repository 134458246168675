import React, { FC, useEffect, useMemo, useState } from 'react';
import { Checkbox, CheckboxProps } from 'carbon-components-react';
import { ID } from 'lib/id';

interface CheckboxSelectAllProps {
  allDependentCheckboxIds: ID[];
  initialSelectedDependentCheckboxIds: ID[];
  onChange: (...e: unknown[]) => void; // RHF onChange handler or custom logic
}

/**
 * Provides basic logic to select/deselect all dependent checkboxes (with indeterminate state)
 */
export const CheckboxSelectAll: FC<CheckboxProps & CheckboxSelectAllProps> = ({
  allDependentCheckboxIds,
  initialSelectedDependentCheckboxIds,
  onChange,
  ...props
}) => {
  const [indeterminateState, setIndeterminateState] = useState(false);

  const allChecked = useMemo(
    () =>
      allDependentCheckboxIds.every((checked) =>
        initialSelectedDependentCheckboxIds?.includes(checked)
      ),
    [initialSelectedDependentCheckboxIds, allDependentCheckboxIds]
  );

  const noneChecked = useMemo(
    () =>
      !allDependentCheckboxIds.some((checked) =>
        initialSelectedDependentCheckboxIds.includes(checked)
      ),
    [initialSelectedDependentCheckboxIds, allDependentCheckboxIds]
  );

  useEffect(() => {
    setIndeterminateState(!allChecked && !noneChecked);
  }, [allChecked, noneChecked]);

  const handleSelectAll = (batchState: boolean) =>
    batchState
      ? Array.from(
          new Set([
            ...initialSelectedDependentCheckboxIds,
            ...allDependentCheckboxIds,
          ])
        )
      : initialSelectedDependentCheckboxIds.filter(
          (el) => !allDependentCheckboxIds.includes(el)
        );

  return (
    <Checkbox
      indeterminate={indeterminateState}
      checked={allChecked}
      onChange={(batchState: boolean) => {
        onChange(handleSelectAll(batchState));
      }}
      {...props}
    />
  );
};
