import { createSelector } from 'reselect';
import { BannerState } from 'state/banner/bannerReducer';
import { RootState } from 'state/reducer';

export const bannerStateSelector = (state: RootState) => state.banner;

export const bannerPaginationSelector = createSelector(
  bannerStateSelector,
  (state: BannerState) => state.pagination
);
