import React, { FC, useCallback } from 'react';
import { TableHeader } from 'carbon-components-react';
import { SortDirection } from 'common/models/pagination';

export interface DataTableHeaderProps {
  isSortHeader: boolean;
  isSortable: boolean;
  onClick: (direction: SortDirection) => void;
  sortDirection: SortDirection;
}

export const DataTableHeader: FC<DataTableHeaderProps> = ({
  children,
  isSortHeader,
  isSortable,
  onClick,
  sortDirection,
  ...rest
}) => {
  const handleClick = useCallback(() => {
    onClick?.(
      !isSortHeader || sortDirection === SortDirection.Desc
        ? SortDirection.Asc
        : SortDirection.Desc
    );
  }, [isSortHeader, sortDirection]);

  return (
    <TableHeader
      isSortHeader={isSortHeader}
      isSortable={isSortable}
      onClick={handleClick}
      sortDirection={sortDirection}
      {...rest}
    >
      {children}
    </TableHeader>
  );
};
