import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';
import { CourseEventParticipantState } from 'state/courseEventParticipant/courseEventParticipantReducer';

export const courseEventParticipantStateSelector = (state: RootState) =>
  state.courseEventParticipant;

export const courseEventParticipantCourseIdSelector = createSelector(
  courseEventParticipantStateSelector,
  (state: CourseEventParticipantState) => state.courseId
);

export const courseEventParticipantEventIdSelector = createSelector(
  courseEventParticipantStateSelector,
  (state: CourseEventParticipantState) => state.eventId
);

export const courseEventParticipantPaginationSelector = createSelector(
  courseEventParticipantStateSelector,
  (state: CourseEventParticipantState) => state.pagination
);

export const courseEventParticipantSortSelector = createSelector(
  courseEventParticipantStateSelector,
  (state) => state.sort
);
