import { QueryObserverOptions, useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { ResultResponse } from 'common/models/response';
import { useSelector } from 'react-redux';
import { getOrElse, isSome } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/function';
import { initialPagination } from 'common/models/pagination';
import { CourseListItem } from 'common/models/course';
import { getCourseList } from 'common/services/course';
import {
  coursePaginationSelector,
  courseSortSelector,
  courseSpeakerSelector,
} from 'state/course/courseSelectors';

export const useCourseListQuery = (
  options: QueryObserverOptions<ResultResponse<CourseListItem>>
) => {
  const filterSpeakerOption = useSelector(courseSpeakerSelector);
  const paginationOption = useSelector(coursePaginationSelector);
  const sortData = useSelector(courseSortSelector);

  const filterSpeaker = pipe(
    filterSpeakerOption,
    getOrElse(() => 'undefined')
  );

  const pagination = pipe(
    paginationOption,
    getOrElse(() => initialPagination)
  );

  return useQuery<ResultResponse<CourseListItem>>(
    [
      QueryKey.CourseList,
      pagination.page,
      pagination.pageSize,
      sortData,
      filterSpeaker,
    ],
    () =>
      getCourseList(pagination, sortData, {
        speakerId: isSome(filterSpeakerOption)
          ? Number(filterSpeaker)
          : undefined,
      }),
    {
      ...options,
      enabled: isSome(paginationOption),
      keepPreviousData: true,
    }
  );
};
