import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Column,
  ComboBox,
  Form,
  InlineLoading,
  MultiSelect,
  Row,
  TextArea,
  TextAreaSkeleton,
  TextInput,
  TextInputSkeleton,
} from 'carbon-components-react';
import {
  FormAccordion,
  FormAccordionItem,
  FormItem,
} from 'app/components/FormItem';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ImageControl } from 'app/components/ImageControl';
import { forEachObjIndexed, mergeAll } from 'ramda';
import { isNotNilEmpty } from 'lib/isNilEmpty';
import { useScrollToError } from 'common/hooks/useScrollToError';
import {
  courseBundleFormValidationSchema,
  fieldsOrder,
} from 'app/components/CourseBundleForm/config';
import { setYupDefaultMessages } from 'common/services/yup';
import slug from 'slug';
import {
  BaseCourseDTO,
  CourseBundleFormModel,
  courseBundleStatuses,
  initialCourseBundleFormValues,
} from 'common/models/courseBundle';
import { Partner } from 'common/models/partner';
import { usePartnerListQuery } from 'common/hooks/partner/usePartnerListQuery';
import { useCourseBundleAssignableElearningsQuery } from 'common/hooks/courseBundle/useCourseBundleAssignableElearningsQuery';
import { useCourseBundleAssignableEventsQuery } from 'common/hooks/courseBundle/useCourseBundleAssignableEventsQuery';
import { useParams } from 'react-router-dom';
import { CourseBundleFormPageParams } from 'app/pages/CourseBundle/CourseBundleForm/CourseBundleFormPage';
import { SectionItem } from 'app/components/CourseBundleForm/SectionItem';
import { NestedMultiSelect } from 'app/components/CourseBundleForm/NestedMultiSelect';
import { SpeakersMultiSelect } from 'app/components/CourseBundleForm/SpeakersMultiSelect';
import { ListItem } from 'common/models/course';
import { ErrorMessage } from 'app/components/ErrorMessage';

const SectionControls = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

const AddSectionButton = styled(Button)`
  margin-bottom: 2rem;
`;

const SectionErrorMessage = styled(ErrorMessage)`
  padding-bottom: 1rem;
`;

export interface CourseBundleFormProps {
  formValues?: CourseBundleFormModel;
  isCreate: boolean;
  isCourseBundleDataLoading?: boolean;
  isSubmitting?: boolean;
  onSubmit: (value: CourseBundleFormModel) => void;
}

setYupDefaultMessages();

export const CourseBundleForm: FC<CourseBundleFormProps> = ({
  formValues,
  isCreate,
  isSubmitting,
  isCourseBundleDataLoading,
  onSubmit,
}) => {
  const { courseBundleId } = useParams<CourseBundleFormPageParams>();
  const [loaded, setLoaded] = useState(isCreate);

  const methods = useForm<CourseBundleFormModel>({
    resolver: yupResolver(courseBundleFormValidationSchema),
    shouldFocusError: false,
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'sections',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    defaultValues: {
      sections: [{ heading: '', content: '' }],
    },
  });

  const onError = useScrollToError(fieldsOrder);

  const { data: partnerData, isFetching: isFetchingPartnerList } =
    usePartnerListQuery({ enabled: true }, { page: 1, pageSize: 1000 });
  const partnerList = useMemo(() => partnerData?.result, [partnerData]);

  const {
    data: assignableElearnings,
    isFetching: isFetchingAssignableElearnings,
  } = useCourseBundleAssignableElearningsQuery(courseBundleId);

  const { data: assignableEvents, isFetching: isFetchingAssignableEvents } =
    useCourseBundleAssignableEventsQuery(courseBundleId);

  useEffect(() => {
    let formModel = initialCourseBundleFormValues;
    if (formValues) {
      formModel = mergeAll([initialCourseBundleFormValues, formValues]);
    }
    forEachObjIndexed((value, key) => {
      methods.setValue(key, value);
    }, formModel);

    formValues && !isCourseBundleDataLoading && setLoaded(true);
  }, [formValues, isCourseBundleDataLoading]);

  const watchElearningList = useWatch({
    control: methods.control,
    name: 'elearningIds',
  });
  const watchEventList = useWatch({
    control: methods.control,
    name: 'eventIds',
  });

  useEffect(() => {
    methods.trigger(['elearningIds', 'eventIds']);
  }, [watchElearningList, watchEventList]);

  const isLoading =
    isFetchingAssignableElearnings ||
    isFetchingAssignableEvents ||
    isFetchingPartnerList ||
    isCourseBundleDataLoading ||
    !loaded;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit, onError)} noValidate>
        <Row>
          <Column lg={8}>
            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <>
                  <Controller
                    control={methods.control}
                    name="title"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      const { dirtyFields } = useFormState({
                        control: methods.control,
                        name: 'slug',
                      });

                      return (
                        <TextInput
                          id="title"
                          invalid={error != null}
                          invalidText={error?.message}
                          onChange={(event) => {
                            onChange(event);

                            if (!formValues?.slug && !dirtyFields.slug) {
                              methods.setValue(
                                'slug',
                                slug(event.target.value),
                                {
                                  shouldValidate: true,
                                }
                              );
                            }
                          }}
                          labelText="Tytuł kursu"
                          value={value}
                        />
                      );
                    }}
                  />
                </>
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <TextInput
                  id="slug"
                  invalid={methods.formState.errors.slug != null}
                  invalidText={methods.formState.errors.slug?.message}
                  labelText="Slug"
                  {...methods.register('slug')}
                />
              )}
            </FormItem>

            <FormAccordion>
              <FormAccordionItem title="Informacje o kursie" open={true}>
                <SectionControls>
                  <AddSectionButton
                    kind="tertiary"
                    size="small"
                    onClick={() => {
                      append({ heading: '', content: '' });
                    }}
                  >
                    Dodaj nową sekcję
                  </AddSectionButton>
                </SectionControls>
                <SectionErrorMessage className="bx--form-requirement">
                  {methods.formState.errors?.sections?.message}
                </SectionErrorMessage>
                {fields.map((section, index) => (
                  <SectionItem
                    key={section.id}
                    index={index}
                    remove={remove}
                    isLoading={isLoading}
                  />
                ))}
              </FormAccordionItem>
            </FormAccordion>
          </Column>

          <Column lg={6}>
            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="status"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <ComboBox<ListItem>
                      id="status"
                      invalid={!!error}
                      invalidText={error?.message}
                      items={courseBundleStatuses}
                      itemToString={(item) => item?.label || ''}
                      onChange={(value) => {
                        onChange(value.selectedItem?.id);
                      }}
                      placeholder=""
                      selectedItem={
                        courseBundleStatuses.find(
                          (item) => item.id === value
                        ) ?? null
                      }
                      titleText="Status"
                    />
                  )}
                />
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextAreaSkeleton />
              ) : (
                <TextArea
                  id="seoDescription"
                  invalid={!!methods.formState.errors.seoDescription}
                  invalidText={methods.formState.errors.seoDescription?.message}
                  labelText="SEO"
                  {...methods.register('seoDescription')}
                />
              )}
            </FormItem>

            <FormAccordion>
              <FormAccordionItem title="Wydarzenia powiązane" open={true}>
                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <Controller
                      control={methods.control}
                      name="eventIds"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <NestedMultiSelect
                          id="eventIds"
                          title="Eventy"
                          invalid={!!error}
                          invalidText={error?.message}
                          items={assignableEvents ?? []}
                          initialSelectedItems={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  )}
                </FormItem>

                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <Controller
                      control={methods.control}
                      name="elearningIds"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <MultiSelect<BaseCourseDTO>
                          id="elearningIds"
                          initialSelectedItems={
                            assignableElearnings?.filter((item) =>
                              value?.includes(item.id)
                            ) ?? []
                          }
                          invalid={!!error}
                          invalidText={error?.message}
                          items={assignableElearnings ?? []}
                          itemToString={(item) => (item ? `${item.title}` : '')}
                          label=""
                          onChange={(a) => {
                            onChange(
                              a.selectedItems
                                .filter(isNotNilEmpty)
                                .map((item) => item.id)
                            );
                          }}
                          titleText="E-learningi"
                          useTitleInItem
                        />
                      )}
                    />
                  )}
                </FormItem>
              </FormAccordionItem>
            </FormAccordion>

            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <SpeakersMultiSelect control={methods.control} />
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="partnerIds"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <MultiSelect<Partner>
                      id="partnerIds"
                      initialSelectedItems={
                        partnerList?.filter((item) =>
                          value?.includes(item.id)
                        ) ?? []
                      }
                      invalid={!!error}
                      invalidText={error?.message}
                      items={partnerList ?? []}
                      itemToString={(item) =>
                        item ? `${item.partnerName}` : ''
                      }
                      label=""
                      onChange={(a) => {
                        onChange(
                          a.selectedItems
                            .filter(isNotNilEmpty)
                            .map((item) => item.id)
                        );
                      }}
                      titleText="Partnerzy"
                    />
                  )}
                />
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextAreaSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="imageFile"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <ImageControl
                      currentImage={formValues?.currentImage}
                      id="imageFile"
                      invalid={!!error}
                      invalidText={error?.message}
                      label="Zdjęcie"
                      onChange={(file) => {
                        onChange(file);
                      }}
                    />
                  )}
                />
              )}
            </FormItem>
          </Column>
        </Row>

        {isSubmitting ? (
          <Button disabled kind="primary" tabIndex={-1}>
            <InlineLoading description={'Zapisywanie...'} status="active" />
          </Button>
        ) : (
          <Button kind="primary" tabIndex={0} type="submit">
            {formValues == null ? 'Utwórz' : 'Zapisz zmiany'}
          </Button>
        )}
      </Form>
    </FormProvider>
  );
};
