import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createMatchSelector,
  push,
  RouterRootState,
} from 'connected-react-router';
import { stringify } from 'qs';
import { pipe } from 'fp-ts/function';
import { filter, fromNullable, getOrElse, Option } from 'fp-ts/lib/Option';
import { getQueryParamsMap } from 'lib/getQueryParams';
import { mapObjIndexed, mergeLeft } from 'ramda';
import {
  initialPagination,
  PaginationState,
  SortDirection,
} from 'common/models/pagination';
import { isSomeEnum, isString } from 'lib/typeGuards';
import {
  CourseEventParticipantListRoute,
  getCourseEventParticipantListRoute,
} from 'routes';
import { rootStateSelector } from 'state/router/routerSelectors';
import {
  courseEventParticipantCourseIdSelector,
  courseEventParticipantEventIdSelector,
  courseEventParticipantPaginationSelector,
  courseEventParticipantSortSelector,
} from 'state/courseEventParticipant/courseEventParticipantSelectors';
import {
  CourseEventParticipantListColumn,
  courseEventParticipantListColumnSettings,
  CourseEventParticipantListParams,
  CourseEventParticipantSortData,
} from 'common/models/courseEventParticipant';
import { courseEventParticipantActions } from 'state/courseEventParticipant/courseEventParticipantActions';
import { defaultSort } from 'state/courseEventParticipant/courseEventParticipantReducer';

function* writeToURLQuery() {
  const courseId: string = yield select(courseEventParticipantCourseIdSelector);
  const eventId: string = yield select(courseEventParticipantEventIdSelector);
  const pagination: Option<PaginationState> = yield select(
    courseEventParticipantPaginationSelector
  );
  const sortData: CourseEventParticipantSortData = yield select(
    courseEventParticipantSortSelector
  );

  const qs = pipe(
    pagination,
    getOrElse(() => initialPagination),
    mergeLeft(sortData),
    stringify
  );

  yield put(
    push(`${getCourseEventParticipantListRoute(courseId, eventId)}?${qs}`, {
      replace: true,
    })
  );
}

function* readFromURLQuery() {
  const qp = getQueryParamsMap();

  const routerState: RouterRootState = yield select(rootStateSelector);
  const matchSelector = createMatchSelector<
    RouterRootState,
    CourseEventParticipantListParams
  >(CourseEventParticipantListRoute);

  const match = matchSelector(routerState);

  const pagination = pipe(
    initialPagination,
    mapObjIndexed((value, key) =>
      pipe(
        fromNullable(qp[key]),
        filter(isString),
        getOrElse(() => value.toString()),
        Number
      )
    )
  );

  const sortData: CourseEventParticipantSortData = {
    direction: pipe(
      fromNullable(qp['direction']),
      filter(isSomeEnum(SortDirection)),
      getOrElse(() => defaultSort.direction)
    ),
    orderBy: pipe(
      fromNullable(qp['orderBy']),
      filter(isSomeEnum(CourseEventParticipantListColumn)),
      filter(
        (column) => courseEventParticipantListColumnSettings[column].sortable
      ),
      getOrElse(() => defaultSort.orderBy)
    ),
  };

  yield put(
    courseEventParticipantActions.setFilters({
      courseId: match?.params.courseId,
      eventId: match?.params.eventId,
      pagination: pagination,
      sort: sortData,
    })
  );
}

function* readFromURLQueryWatcher() {
  yield takeEvery(
    courseEventParticipantActions.loadPagination.type,
    readFromURLQuery
  );
}

function* writeToURLQueryWatcher() {
  yield takeLatest(
    courseEventParticipantActions.setFilters.type,
    writeToURLQuery
  );
  yield takeLatest(
    courseEventParticipantActions.setPagination.type,
    writeToURLQuery
  );
  yield takeLatest(courseEventParticipantActions.setSort.type, writeToURLQuery);
}

export function* courseEventParticipantSaga() {
  yield all([writeToURLQueryWatcher(), readFromURLQueryWatcher()]);
}
