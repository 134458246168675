import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { stringify } from 'qs';
import { pipe } from 'fp-ts/function';
import {
  filter,
  fromNullable,
  getOrElse,
  isSome,
  Option,
} from 'fp-ts/lib/Option';
import {
  initialPagination,
  PaginationState,
  SortDirection,
} from 'common/models/pagination';
import { ElearningListRoute } from 'routes';
import {
  elearningCategorySelector,
  elearningPaginationSelector,
  elearningSortSelector,
} from 'state/elearning/elearningSelectors';
import { elearningActions } from 'state/elearning/elearningActions';
import {
  ElearningListColumn,
  elearningListColumnSettings,
  ElearningSortData,
} from 'common/models/elearning';
import { mapObjIndexed, mergeLeft } from 'ramda';
import { getQueryParamsMap } from 'lib/getQueryParams';
import { isSomeEnum, isString } from 'lib/typeGuards';
import { defaultSort } from 'state/elearning/elearningReducer';

function* writeToURLQuery() {
  const filterCategoryOption: Option<string> = yield select(
    elearningCategorySelector
  );
  const pagination: Option<PaginationState> = yield select(
    elearningPaginationSelector
  );
  const sortData: ElearningSortData = yield select(elearningSortSelector);

  const filterCategory = pipe(
    filterCategoryOption,
    getOrElse(() => 'undefined')
  );

  const qs = pipe(
    pagination,
    getOrElse(() => initialPagination),
    (item) =>
      isSome(filterCategoryOption)
        ? {
            ...item,
            category: filterCategory,
          }
        : item,
    mergeLeft(sortData),
    stringify
  );

  yield put(
    push(`${ElearningListRoute}?${qs}`, {
      replace: true,
    })
  );
}

function* readFromURLQuery() {
  const qp = getQueryParamsMap();

  const pagination = pipe(
    initialPagination,
    mapObjIndexed((value, key) =>
      pipe(
        fromNullable(qp[key]),
        filter(isString),
        getOrElse(() => value.toString()),
        Number
      )
    )
  );

  const sortData: ElearningSortData = {
    direction: pipe(
      fromNullable(qp['direction']),
      filter(isSomeEnum(SortDirection)),
      getOrElse(() => defaultSort.direction)
    ),
    orderBy: pipe(
      fromNullable(qp['orderBy']),
      filter(isSomeEnum(ElearningListColumn)),
      filter((column) => elearningListColumnSettings[column].sortable),
      getOrElse(() => defaultSort.orderBy)
    ),
  };

  yield put(
    elearningActions.setFilters({
      categoryId: qp.category ? String(qp.category) : undefined,
      pagination: pagination,
      sort: sortData,
    })
  );
}

function* readFromURLQueryWatcher() {
  yield takeEvery(elearningActions.loadPagination.type, readFromURLQuery);
}

function* writeToURLQueryWatcher() {
  yield takeLatest(elearningActions.setCategory.type, writeToURLQuery);
  yield takeLatest(elearningActions.setCategoryId.type, writeToURLQuery);
  yield takeLatest(elearningActions.setFilters.type, writeToURLQuery);
  yield takeLatest(elearningActions.setPagination.type, writeToURLQuery);
  yield takeLatest(elearningActions.setSort.type, writeToURLQuery);
}

export function* elearningSaga() {
  yield all([writeToURLQueryWatcher(), readFromURLQueryWatcher()]);
}
