import { FC } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DataTable,
  DataTableCustomRenderProps,
  Grid,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { Content } from 'common/components/Grid';
import { StaticPageListColumn } from 'modules/static-pages/models/staticPage';
import { StaticPageListContent } from 'modules/static-pages/components/StaticPageList/StaticPageListContent';
import { staticPages } from 'modules/static-pages/models/staticPageItem';

const headers = [
  {
    key: StaticPageListColumn.Page,
    header: 'Strona',
  },
];

const rows = staticPages.map((staticPage) => ({
  [StaticPageListColumn.Page]: staticPage.title,
  id: staticPage.id,
}));

export const StaticPageList: FC = () => (
  <Content>
    <Grid>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={RootRoute}>PFP Admin</Link>
        </BreadcrumbItem>
      </Breadcrumb>

      <PageHeading>Zarządzanie treścią stron statycznych</PageHeading>

      <DataTable headers={headers} overflowMenuOnHover={false} rows={rows}>
        {(props: DataTableCustomRenderProps) => (
          <StaticPageListContent {...props} />
        )}
      </DataTable>
    </Grid>
  </Content>
);
