import { DashboardColumn } from 'modules/dashboard/types';

export const dashboardHeaders = [
  {
    key: DashboardColumn.TITLE,
    header: 'Nazwa szkolenia',
  },
  {
    key: DashboardColumn.DATE,
    header: 'Data i Godzina szkolenia',
  },
  {
    key: DashboardColumn.ATTENDEES,
    header: 'Ilość zapisanych osób na szkolenie',
  },
  {
    key: DashboardColumn.STATUS,
    header: 'Status wydarzenia',
  },
  {
    key: DashboardColumn.INSTRUCTOR,
    header: 'Prowadzący',
  },
];
