import { FC } from 'react';
import { Breadcrumb, BreadcrumbItem, Grid } from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import { CourseListRoute, getCourseFormRoute, RootRoute } from 'routes';
import { Content } from 'common/components/Grid';
import { useCourseByIdQuery } from 'common/hooks/course/useCourseByIdQuery';
import { MaterialResource } from 'common/models/material';
import { Materials } from 'app/components/Materials';

export interface CourseMaterialsUrlParams {
  courseId: string;
}

export const CourseMaterials: FC = () => {
  const { courseId } = useParams<CourseMaterialsUrlParams>();

  const { data: courseData } = useCourseByIdQuery(courseId);

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={CourseListRoute}>Lista szkoleń</Link>
          </BreadcrumbItem>

          {courseData && (
            <BreadcrumbItem>
              <Link to={getCourseFormRoute(courseData.id)}>
                {courseData.title}
              </Link>
            </BreadcrumbItem>
          )}
        </Breadcrumb>

        <Materials
          parentId={courseId}
          resource={MaterialResource.StandardCourse}
        />
      </Grid>
    </Content>
  );
};
