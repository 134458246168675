import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { snackbarActions } from 'state/notification/notificationActions';
import {
  MaterialDTO,
  MaterialStatus,
  MaterialUpdateRequestDTO,
} from 'common/models/material';
import { materialsActions } from 'state/materials/materialsActions';
import { TranslatedError } from 'common/models/error';
import { captureException } from 'common/services/sentry';
import { isApiErrorItem } from 'common/services/error';
import { updateMaterialById } from 'common/services/material';

export const useUpdateMaterialMutation = (materialId: string) => {
  const dispatch = useDispatch();

  return useMutation<unknown, unknown, MaterialUpdateRequestDTO>(
    (params) => updateMaterialById(materialId, params),
    {
      onError: (errors) => {
        (errors as TranslatedError[]).map((translatedError) => {
          captureException(translatedError);
          dispatch(
            snackbarActions.enqueueSnackbar({
              subtitle:
                isApiErrorItem(translatedError.error) &&
                translatedError.error.errorCode,
              title: translatedError.message,
              options: {
                variant: 'error',
              },
            })
          );
        });

        dispatch(
          materialsActions.updateStatus({
            id: materialId,
            status: MaterialStatus.Changed,
          })
        );
      },
      onMutate: () => {
        dispatch(
          materialsActions.updateStatus({
            id: materialId,
            status: MaterialStatus.Saving,
          })
        );
      },
      onSuccess: (data) => {
        dispatch(materialsActions.setMaterial(data as MaterialDTO));
        dispatch(
          snackbarActions.enqueueSnackbar({
            title: 'Materiał szkoleniowy został zapisany pomyślnie',
            options: { variant: 'success' },
          })
        );
      },
    }
  );
};
