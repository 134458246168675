import styled from 'styled-components';
import { Form } from 'carbon-components-react';

export const MaterialsModalForm = styled(Form)`
  padding: 1rem 0 0;
`;

export const MaterialsModalFormItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;
