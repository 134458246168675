import { FC } from 'react';
import {
  Header,
  HeaderContainer,
  HeaderGlobalBar,
  HeaderMenuButton,
  HeaderName,
  SideNav,
  SideNavDivider,
  SideNavItems,
  SideNavLink,
  SkipToContent,
} from 'carbon-components-react';
import {
  Analytics32,
  Calendar32,
  Category32,
  Certificate32,
  DocumentTasks32,
  Education32,
  LicenseDraft32,
  Report32,
  Screen32,
  Settings32,
  User32,
  UserAvatar20,
  Currency32,
} from '@carbon/icons-react';
import { Link, LinkProps } from 'react-router-dom';
import {
  CategoryListRoute,
  CertificateSettingsRoute,
  CourseListRoute,
  ElearningListRoute,
  PartnerListRoute,
  RootRoute,
  SpeakerListRoute,
  SurveyListRoute,
  SystemSettingsRoute,
  CourseBundleListRoute,
  UserListRoute,
  BannerListRoute,
} from 'routes';
import { useSelector } from 'react-redux';
import { userFullNameSelector } from 'state/auth/authSelectors';
import { Partnership } from '@carbon/icons-react/next';
import { HeaderGlobalActionWithText } from 'common/components/Header';
import { LogoutButton } from 'app/components/LogoutButton';
import { ReportsRootRoute } from 'modules/reports-wizard/routes';
import { StaticPageListRoute } from 'modules/static-pages/routes';

export const AppHeader: FC = () => {
  const userFullName = useSelector(userFullNameSelector);

  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header aria-label="Carbon Tutorial">
          <SkipToContent />

          <HeaderMenuButton
            aria-label="Open menu"
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />

          <HeaderName<LinkProps> element={Link} to={RootRoute} prefix="PFP">
            Admin
          </HeaderName>

          <SideNav
            aria-label="Side navigation"
            expanded={isSideNavExpanded}
            isRail
          >
            <SideNavItems>
              <SideNavLink<LinkProps>
                renderIcon={Analytics32}
                element={Link}
                to={CourseListRoute}
              >
                Szkolenia
              </SideNavLink>

              <SideNavLink<LinkProps>
                renderIcon={Screen32}
                element={Link}
                to={ElearningListRoute}
              >
                E-learning
              </SideNavLink>

              <SideNavLink<LinkProps>
                renderIcon={Calendar32}
                element={Link}
                to={CourseBundleListRoute}
              >
                Kursy
              </SideNavLink>

              <SideNavDivider />

              <SideNavLink<LinkProps>
                renderIcon={DocumentTasks32}
                element={Link}
                to={SurveyListRoute}
              >
                Ankiety
              </SideNavLink>

              <SideNavLink<LinkProps>
                renderIcon={Education32}
                element={Link}
                to={SpeakerListRoute}
              >
                Wykładowcy
              </SideNavLink>

              <SideNavDivider />

              <SideNavLink<LinkProps>
                renderIcon={User32}
                element={Link}
                to={UserListRoute}
              >
                Użytkownicy
              </SideNavLink>

              <SideNavLink<LinkProps>
                renderIcon={Partnership}
                element={Link}
                to={PartnerListRoute}
              >
                Partnerzy
              </SideNavLink>

              <SideNavDivider />

              <SideNavLink<LinkProps>
                renderIcon={Currency32}
                element={Link}
                to={BannerListRoute}
              >
                Reklamy
              </SideNavLink>

              <SideNavLink<LinkProps>
                renderIcon={Category32}
                element={Link}
                to={CategoryListRoute}
              >
                Kategorie specjalne
              </SideNavLink>

              <SideNavLink<LinkProps>
                renderIcon={Certificate32}
                element={Link}
                to={CertificateSettingsRoute}
              >
                Dane certyfikatu
              </SideNavLink>

              <SideNavLink<LinkProps>
                renderIcon={Report32}
                element={Link}
                to={ReportsRootRoute}
              >
                Raporty
              </SideNavLink>

              <SideNavLink<LinkProps>
                renderIcon={LicenseDraft32}
                element={Link}
                to={StaticPageListRoute}
              >
                Strony statyczne
              </SideNavLink>

              <SideNavLink<LinkProps>
                renderIcon={Settings32}
                element={Link}
                to={SystemSettingsRoute}
              >
                Ustawienia systemowe
              </SideNavLink>
            </SideNavItems>
          </SideNav>

          <HeaderGlobalBar>
            <HeaderGlobalActionWithText aria-label="Zalogowany użytkownik">
              {userFullName}
              <UserAvatar20 />
            </HeaderGlobalActionWithText>

            <LogoutButton />
          </HeaderGlobalBar>
        </Header>
      )}
    />
  );
};
