import { Button, TextInput, Tile } from 'carbon-components-react';
import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Draggable20, TrashCan20 } from '@carbon/icons-react';
import { Controller, useFormContext } from 'react-hook-form';
import { ContentEditor } from 'app/components/ContentEditor';
import EditorJS from '@editorjs/editorjs';
import {
  AboutUsListControl,
  SortableItemProps,
} from 'modules/static-pages/components/StaticPageForm/AboutUsListControl';
import { editorjsTextOnlyTools } from 'config/editorjs';
import { hasPath, path } from 'ramda';

const StyledTextInput = styled(TextInput)`
  cursor: default;
  margin: 0 0.3125rem;
`;

const StyledTile = styled(Tile)`
  margin: 0.125rem 0;
  padding-bottom: 0;
`;

const Row = styled.div`
  align-items: flex-start;
  display: flex;
`;

const SortableItem: FC<SortableItemProps> = ({
  controlId,
  index,
  name,
  onDelete,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: controlId });

  const {
    control,
    formState: { errors },
    register,
  } = useFormContext();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || '',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <StyledTile>
        <Row>
          <Button
            hasIconOnly
            iconDescription="Przenieś"
            kind="ghost"
            renderIcon={Draggable20}
            size="small"
            tooltipAlignment="center"
            tooltipPosition="top"
            {...listeners}
          />

          <Controller
            control={control}
            name={`${name}.${index}.value`}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <StyledTextInput
                hideLabel={true}
                id={`${name}_${controlId}_row_${index}_value`}
                invalid={!!error}
                invalidText={error?.message}
                labelText=""
                onChange={onChange}
                placeholder="Wartość"
                size="sm"
                value={value}
              />
            )}
          />

          <StyledTextInput
            hideLabel={true}
            id={`${name}_${controlId}_row_${index}_unit`}
            invalid={hasPath(`${name}.${index}.unit`.split('.'), errors)}
            invalidText={path(
              `${name}.${index}.unit.message`.split('.'),
              errors
            )}
            labelText=""
            placeholder="Jednostka"
            size="sm"
            {...register(`${name}.${index}.unit` as const)}
          />

          <Button
            hasIconOnly
            iconDescription="Usuń"
            kind="danger--ghost"
            onClick={() => onDelete(controlId)}
            renderIcon={TrashCan20}
            size="small"
            tooltipAlignment="center"
            tooltipPosition="top"
          />
        </Row>

        <ContentEditor
          name={`${name}.${index}.text`}
          tools={editorjsTextOnlyTools}
        />
      </StyledTile>
    </div>
  );
};

export interface AboutUsStrengthsListControlProps {
  label?: string;
  name: string;
}

export const AboutUsStrengthsListControl: FC<
  AboutUsStrengthsListControlProps
> = (props) => {
  const getEmptyItem = useCallback(
    () => ({
      text: {
        blocks: [],
        time: +new Date(),
        version: EditorJS.version,
      },
      unit: '',
      value: '',
    }),
    []
  );

  return (
    <AboutUsListControl
      {...props}
      getEmptyItem={getEmptyItem}
      SortableItem={SortableItem}
    />
  );
};
