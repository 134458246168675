import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { snackbarActions } from 'state/notification/notificationActions';
import { push } from 'connected-react-router';
import { RootRoute } from 'routes';
import { updateSystemSettings } from 'common/services/systemSettings';
import { SystemSettingsDTO } from 'common/models/systemSettings';

export const useUpdateSystemSettingsMutation = () => {
  const dispatch = useDispatch();

  return useMutation<SystemSettingsDTO, unknown, SystemSettingsDTO>(
    (params) => updateSystemSettings(params),
    {
      onSuccess: () => {
        dispatch(push(RootRoute));
        dispatch(
          snackbarActions.enqueueSnackbar({
            title: 'Ustawienia systemowe zostały zapisane pomyślnie',
            options: { variant: 'success' },
          })
        );
      },
    }
  );
};
