import { createReducer } from '@reduxjs/toolkit';
import { PaginationState, SortDirection } from 'common/models/pagination';
import { none, Option, some } from 'fp-ts/lib/Option';
import {
  CourseBundleListColumn,
  CourseBundleSortData,
} from 'common/models/courseBundle';
import { courseBundleActions } from 'state/courseBundle/courseBundleActions';

export interface CourseBundleState {
  pagination: Option<PaginationState>;
  sort: CourseBundleSortData;
}

export const defaultSort: CourseBundleSortData = {
  direction: SortDirection.Asc,
  orderBy: CourseBundleListColumn.Title,
};

const initialState: CourseBundleState = {
  pagination: none,
  sort: defaultSort,
};

export const courseBundleReducer = createReducer<CourseBundleState>(
  initialState,
  (builder) =>
    builder
      .addCase(courseBundleActions.clear, () => initialState)
      .addCase(courseBundleActions.setFilters, (state, action) => {
        state.pagination = some(action.payload.pagination);
        state.sort = action.payload.sort;
      })
      .addCase(courseBundleActions.setPagination, (state, action) => {
        state.pagination = some(action.payload);
      })
      .addCase(courseBundleActions.setSort, (state, action) => {
        state.sort = action.payload;
      })
);
