import React, { FC, useEffect, useMemo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  Grid,
  Pagination,
} from 'carbon-components-react';
import { Content } from 'common/components/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/lib/Option';
import { initialPagination, pageSizes } from 'common/models/pagination';
import { courseBundleElearningListActions } from 'state/courseBundleContent/elearningList/courseBundleElearningListActions';
import { ElearningBasicInfoDTO } from 'common/models/elearning';
import { statusLabelMap } from 'common/models/status';
import {
  CourseBundleContentCategory,
  CourseBundleContentUrlParams,
  CourseBundleElearningListColumn,
} from 'common/models/courseBundleContent';
import { useAllCategoriesListQuery } from 'common/hooks/category/useAllCategoriesListQuery';
import { find } from 'ramda';
import { Category } from 'common/models/category';
import { CourseBundleElearningListTableContent } from 'app/components/CourseBundleContent/ElearningList/CourseBundleElearningListTableContent';
import { useCourseBundleElearningListQuery } from 'common/hooks/courseBundle/useCourseBundleElearningListQuery';
import { Link, useParams } from 'react-router-dom';
import {
  courseBundleElearningListCategorySelector,
  courseBundleElearningListPaginationSelector,
} from 'state/courseBundleContent/elearningList/courseBundleElearningListSelectors';
import { CourseBundleListRoute, RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { CourseBundleContentSwitcher } from 'app/components/CourseBundleContent/ContentSwitcher';
import { courseBundleContentSwitcherActions } from 'state/courseBundleContent/contentSwitcher/contentSwitcherActions';

const headers = [
  {
    key: CourseBundleElearningListColumn.Title,
    header: 'Nazwa',
  },
  {
    key: CourseBundleElearningListColumn.CategoryName,
    header: 'Kategoria',
  },
  {
    key: CourseBundleElearningListColumn.Status,
    header: 'Status',
  },
];

const prepareRow = (elearning: ElearningBasicInfoDTO) => ({
  [CourseBundleElearningListColumn.Title]: elearning.courseTitle,
  [CourseBundleElearningListColumn.CategoryName]: elearning.category.label,
  [CourseBundleElearningListColumn.Status]:
    statusLabelMap[elearning.elearningStatus],
  id: elearning.courseId.toString(),
});

export const CourseBundleElearningList: FC = () => {
  const { courseBundleId } = useParams<CourseBundleContentUrlParams>();
  const dispatch = useDispatch();

  dispatch(
    courseBundleContentSwitcherActions.setCourseBundleContentCategory(
      CourseBundleContentCategory.ElearningList
    )
  );

  const categoryOption = useSelector(courseBundleElearningListCategorySelector);
  const paginationOption = useSelector(
    courseBundleElearningListPaginationSelector
  );

  const categoryId = useMemo(
    () =>
      pipe(
        categoryOption,
        getOrElse(() => 'undefined')
      ),
    [categoryOption]
  );

  const pagination = useMemo(
    () =>
      pipe(
        paginationOption,
        getOrElse(() => initialPagination)
      ),
    [paginationOption]
  );

  const { data: categoriesData, isLoading: isLoadingCategories } =
    useAllCategoriesListQuery({
      onSuccess: (data) => {
        if (
          data &&
          (data.result.length === 0 ||
            !find<Category>((item) => String(item.id) === categoryId)(
              data.result
            ))
        ) {
          dispatch(courseBundleElearningListActions.setCategoryId(undefined));
        }
      },
    });

  const {
    data,
    isFetching: isFetchingElearning,
    isLoading: isLoadingElearning,
  } = useCourseBundleElearningListQuery(courseBundleId, {
    enabled: true,
    onSuccess: (data) => {
      if (data && data.result.length === 0 && pagination.page !== 1) {
        dispatch(
          courseBundleElearningListActions.setPagination({
            page: Math.ceil(data.totalResult / pagination.pageSize),
            pageSize: pagination.pageSize,
          })
        );
      }
    },
  });

  const rows = useMemo(() => data?.result.map(prepareRow), [data]);

  useEffect(() => {
    dispatch(courseBundleElearningListActions.loadPagination());

    return () => {
      dispatch(courseBundleElearningListActions.clear());
    };
  }, [dispatch]);

  const isLoading = isLoadingCategories || isLoadingElearning;

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={CourseBundleListRoute}>Lista kursów</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageHeading>Zawartość kursu</PageHeading>
        <CourseBundleContentSwitcher />

        {isLoading && (
          <DataTableSkeleton headers={headers} rowCount={10}>
            {(props: DataTableCustomRenderProps) => (
              <CourseBundleElearningListTableContent {...props} />
            )}
          </DataTableSkeleton>
        )}

        {rows && !isLoading && (
          <DataTable headers={headers} overflowMenuOnHover={false} rows={rows}>
            {(props: DataTableCustomRenderProps) => (
              <CourseBundleElearningListTableContent
                {...props}
                categories={categoriesData?.result ?? []}
                isFetching={isFetchingElearning}
              />
            )}
          </DataTable>
        )}

        <Pagination
          onChange={(pagination) => {
            dispatch(
              courseBundleElearningListActions.setPagination({
                page: pagination.page,
                pageSize: pagination.pageSize,
              })
            );
          }}
          page={pagination.page}
          pageSize={pagination.pageSize}
          pageSizes={pageSizes}
          totalItems={data?.totalResult ?? 0}
        />
      </Grid>
    </Content>
  );
};
