import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Column,
  Form,
  InlineLoading,
  Row,
  TextAreaSkeleton,
  TextInput,
  TextInputSkeleton,
} from 'carbon-components-react';
import {
  FormAccordion,
  FormAccordionItem,
  FormItem,
} from 'app/components/FormItem';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ImageControl } from 'app/components/ImageControl';
import { forEachObjIndexed, mergeAll } from 'ramda';
import { CourseParametersControl } from 'app/components/CourseParametersControl';
import { NumberInput } from 'app/components/NumberInput';
import { ChangeEventImaginaryTarget } from 'lib/carbonExtraTypes';
import { newElearningLessonAgendaValidationSchema } from 'common/services/validation';
import {
  ElearningLessonFormModel,
  initialElearningLessonFormValues,
} from 'common/models/elearningLesson';
import {
  elearningLessonFormValidationSchema,
  fieldsOrder,
} from 'app/components/ElearningLessonForm/config';
import { useScrollToError } from 'common/hooks/useScrollToError';
import { durationStep } from 'config/elearning';

export interface ElearningLessonFormProps {
  formValues?: ElearningLessonFormModel;
  isCreate: boolean;
  isElearningLessonLoading?: boolean;
  isSubmitting?: boolean;
  onSubmit: (value: ElearningLessonFormModel) => void;
}

export const ElearningLessonForm: FC<ElearningLessonFormProps> = ({
  formValues,
  isCreate,
  isSubmitting,
  isElearningLessonLoading,
  onSubmit,
}) => {
  const [loaded, setLoaded] = useState(isCreate);

  const methods = useForm<ElearningLessonFormModel>({
    resolver: yupResolver(elearningLessonFormValidationSchema),
    shouldFocusError: false,
  });

  const onError = useScrollToError(fieldsOrder);

  useEffect(() => {
    let formModel = initialElearningLessonFormValues;

    if (formValues) {
      formModel = mergeAll([initialElearningLessonFormValues, formValues]);
    }

    forEachObjIndexed((value, key) => {
      methods.setValue(key, value);
    }, formModel);

    formValues && setLoaded(true);
  }, [formValues]);

  const isLoading = isElearningLessonLoading || !loaded;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit, onError)} noValidate>
        <Row>
          <Column lg={8}>
            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <TextInput
                  id="title"
                  invalid={methods.formState.errors.title != null}
                  invalidText={methods.formState.errors.title?.message}
                  labelText="Nazwa"
                  {...methods.register('title')}
                />
              )}
            </FormItem>

            <FormAccordion>
              <FormAccordionItem title="Opis" open={true}>
                <FormItem>
                  {isLoading ? (
                    <TextAreaSkeleton />
                  ) : (
                    <Controller
                      control={methods.control}
                      name="agenda"
                      render={({
                        field: { onChange },
                        fieldState: { error },
                      }) => (
                        <CourseParametersControl
                          id="agenda"
                          inputValidationSchema={
                            newElearningLessonAgendaValidationSchema
                          }
                          invalid={!!error}
                          invalidText={error?.message}
                          onChange={(rows) => {
                            onChange(rows);
                          }}
                          value={formValues?.agenda}
                        />
                      )}
                    />
                  )}
                </FormItem>
              </FormAccordionItem>
            </FormAccordion>
          </Column>

          <Column lg={4}>
            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="duration"
                  render={({
                    field: { name, value, onChange },
                    fieldState: { error },
                  }) => (
                    <NumberInput
                      allowEmpty
                      helperText="w minutach"
                      id={name}
                      invalid={!!error}
                      invalidText={error?.message}
                      label="Czas trwania"
                      min={durationStep}
                      name={name}
                      onChange={(
                        ev:
                          | React.ChangeEvent<HTMLInputElement>
                          | React.MouseEvent<HTMLButtonElement>
                      ) =>
                        onChange(
                          (ev as ChangeEventImaginaryTarget<HTMLInputElement>)
                            .imaginaryTarget.value
                        )
                      }
                      step={durationStep}
                      value={value ?? ''}
                    />
                  )}
                />
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextAreaSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="coverPhotoFile"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <ImageControl
                      currentImage={formValues?.currentCoverPhoto}
                      id="coverPhotoFile"
                      invalid={!!error}
                      invalidText={error?.message}
                      label="Cover photo"
                      onChange={(file) => {
                        onChange(file);
                      }}
                    />
                  )}
                />
              )}
            </FormItem>
          </Column>
        </Row>

        {isSubmitting ? (
          <Button disabled kind="primary" tabIndex={-1}>
            <InlineLoading description={'Zapisywanie...'} status="active" />
          </Button>
        ) : (
          <Button kind="primary" tabIndex={0} type="submit">
            {formValues == null ? 'Utwórz' : 'Zapisz zmiany'}
          </Button>
        )}
      </Form>
    </FormProvider>
  );
};
