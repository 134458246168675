import * as yup from 'yup';
import { mixed, object } from 'yup';
import { durationStep } from 'config/elearning';
import {
  isElearningDurationValidTest,
  validatorMessages,
} from 'common/services/validation';

export const elearningLessonFormValidationSchema = object({
  agenda: yup.array().min(1).required(),
  duration: yup
    .number()
    .min(durationStep)
    .positive()
    .integer()
    .test(
      'isDurationValid',
      validatorMessages.duration.invalidStep,
      isElearningDurationValidTest
    ),
  title: yup.string().max(200).required(),

  coverPhotoFile: mixed().test((value, context) => {
    const { currentCoverPhoto } = context.parent;
    return !(currentCoverPhoto == null && value == null);
  }),
});

export const fieldsOrder = ['title', 'duration', 'agenda', 'coverPhotoFile'];
