import { useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { getElearningLessonList } from 'common/services/elearningLesson';
import { ID } from 'lib/id';

export const useElearningLessonListQuery = (elearningId: ID) => {
  return useQuery(
    [QueryKey.ElearningLessonList, elearningId],
    () => getElearningLessonList(elearningId),
    {
      keepPreviousData: true,
    }
  );
};
