import {
  Button,
  DataTableCustomRenderProps,
  Loading,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react';
import { Add20 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { CourseBundleCreateRoute } from 'routes';
import {
  Table,
  TableContainer,
  TableContent,
  TableLoader,
} from 'app/components/Table';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTableEmptyRow } from 'app/components/DataTableEmptyRow';
import { isEmpty } from 'ramda';
import { DataTableHeader } from 'app/components/DataTableHeader';
import { CourseBundleListColumn } from 'common/models/courseBundle';
import { courseBundleActions } from 'state/courseBundle/courseBundleActions';
import { courseBundleSortSelector } from 'state/courseBundle/courseBundleSelectors';
import { CourseBundleListTableRow } from 'app/components/CourseBundleList/CourseBundleListTableRow';

export interface CourseBundleListTableContentProps {
  isFetching?: boolean;
}

export const CourseBundleListTableContent: FC<
  CourseBundleListTableContentProps & DataTableCustomRenderProps
> = ({
  isFetching = false,
  rows,
  headers,
  getBatchActionProps,
  getTableProps,
  getTableContainerProps,
  getToolbarProps,
  getRowProps,
}) => {
  const dispatch = useDispatch();
  const sortData = useSelector(courseBundleSortSelector);

  return (
    <TableContainer title="Kursy" {...getTableContainerProps()}>
      <TableToolbar {...getToolbarProps()}>
        <TableToolbarContent>
          <Button
            as={Link}
            kind="primary"
            renderIcon={Add20}
            size="small"
            tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
            to={CourseBundleCreateRoute}
          >
            Dodaj nowy kurs
          </Button>
        </TableToolbarContent>
      </TableToolbar>

      <TableContent>
        <Table $loading={isFetching} {...getTableProps()}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <DataTableHeader
                  isSortable
                  isSortHeader={sortData.orderBy === header.key}
                  sortDirection={sortData.direction}
                  onClick={(direction) => {
                    dispatch(
                      courseBundleActions.setSort({
                        direction,
                        orderBy: header.key as CourseBundleListColumn,
                      })
                    );
                  }}
                  key={header.key}
                >
                  {header.header}
                </DataTableHeader>
              ))}

              <TableHeader />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(rows) &&
              rows.map((row) => (
                <CourseBundleListTableRow
                  getRowProps={getRowProps}
                  key={row.id}
                  row={row}
                />
              ))}

            {isEmpty(rows) && <DataTableEmptyRow colSpan={5} />}
          </TableBody>
        </Table>

        {isFetching && (
          <TableLoader>
            <Loading active={true} withOverlay={false} />
          </TableLoader>
        )}
      </TableContent>
    </TableContainer>
  );
};
