import {
  Button,
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import { Edit20, TrashCan20 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import {
  CategoryDeleteRoute,
  CategoryFormRoute,
  categoryIdRouteParam,
} from 'routes';
import { ActionsTableCell } from 'app/components/Table';
import { FC } from 'react';
import { setParam } from 'common/utils/routing';

export interface CategoryListTableRowProps {
  row: DenormalizedRow;
}

export const CategoryListTableRow: FC<
  CategoryListTableRowProps & Pick<DataTableCustomRenderProps, 'getRowProps'>
> = ({ getRowProps, row }) => {
  return (
    <TableRow {...getRowProps({ row })}>
      {row.cells.map((cell) => (
        <TableCell key={cell.id}>{cell.value}</TableCell>
      ))}

      <ActionsTableCell>
        <Button
          as={Link}
          hasIconOnly
          iconDescription="Edytuj"
          kind="ghost"
          renderIcon={Edit20}
          size="small"
          to={setParam(categoryIdRouteParam)(CategoryFormRoute)(row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <Button
          as={Link}
          hasIconOnly
          iconDescription="Usuń"
          kind="danger--ghost"
          renderIcon={TrashCan20}
          size="small"
          to={setParam(categoryIdRouteParam)(CategoryDeleteRoute)(row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />
      </ActionsTableCell>
    </TableRow>
  );
};
