import React, { FC } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Form,
  Grid,
  InlineLoading,
  Row,
  TextInputSkeleton,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { pipe } from 'fp-ts/function';
import { Content } from 'common/components/Grid';
import { setYupDefaultMessages } from 'common/services/yup';
import { FormItem } from 'app/components/FormItem';
import { useScrollToError } from 'common/hooks/useScrollToError';
import { NumberInput } from 'app/components/NumberInput';
import { ChangeEventImaginaryTarget } from 'lib/carbonExtraTypes';
import {
  mapToSystemSettingsDTO,
  mapToSystemSettingsFormModel,
  SystemSettingsFormModel,
} from 'common/models/systemSettings';
import { fieldsOrder, formSchema } from 'app/pages/SystemSettingsForm/config';
import { useSystemSettingsQuery } from 'common/hooks/systemSettings/useSystemSettingsQuery';
import { useUpdateSystemSettingsMutation } from 'common/hooks/systemSettings/useUpdateSystemSettingsMutation';

setYupDefaultMessages();

export const SystemSettingsForm: FC = () => {
  const updateMutation = useUpdateSystemSettingsMutation();

  const { control, reset, handleSubmit } = useForm<SystemSettingsFormModel>({
    resolver: yupResolver(formSchema),
    shouldFocusError: false,
  });

  const onError = useScrollToError(fieldsOrder);

  const { isLoading } = useSystemSettingsQuery((data) =>
    reset(mapToSystemSettingsFormModel(data))
  );

  const onSubmit = handleSubmit(
    async (values) =>
      pipe(values, mapToSystemSettingsDTO, updateMutation.mutate),
    onError
  );

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageHeading>Ustawienia systemowe</PageHeading>

        <Form onSubmit={onSubmit} noValidate>
          <Row>
            <Column lg={4}>
              <Row>
                <Column>
                  <FormItem>
                    {isLoading ? (
                      <TextInputSkeleton />
                    ) : (
                      <Controller
                        control={control}
                        name="presentPercentage"
                        render={({
                          field: { name, value, onChange },
                          fieldState: { error },
                        }) => (
                          <NumberInput
                            id={name}
                            name={name}
                            label="Procent wymagany do automatycznego oznaczenia obecności"
                            onChange={(
                              ev:
                                | React.ChangeEvent<HTMLInputElement>
                                | React.MouseEvent<HTMLButtonElement>
                            ) =>
                              onChange(
                                (
                                  ev as ChangeEventImaginaryTarget<HTMLInputElement>
                                ).imaginaryTarget.value
                              )
                            }
                            value={value ?? ''}
                            allowEmpty
                            invalid={!!error}
                            invalidText={error?.message}
                          />
                        )}
                      />
                    )}
                  </FormItem>
                </Column>
              </Row>

              {updateMutation.isLoading ? (
                <Button disabled kind="primary" tabIndex={-1}>
                  <InlineLoading description="Zapisywanie..." status="active" />
                </Button>
              ) : (
                <Button kind="primary" tabIndex={0} type="submit">
                  Zapisz
                </Button>
              )}
            </Column>
          </Row>
        </Form>
      </Grid>
    </Content>
  );
};
