import styled from 'styled-components';
import { Button } from 'carbon-components-react';

export const BlockButton = styled(Button)`
  display: flex !important;
`;

export const BlockLoadingButton = styled(BlockButton)`
  color: transparent !important;
`;

export const LoadingButton = styled(Button)`
  color: transparent !important;
`;
