import {
  Button,
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import { Edit20 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { SurveyRoute } from 'routes';
import { useDeleteSurveyMutation } from 'common/hooks/survey/useDeleteSurveyMutation';
import { ActionsTableCell } from 'app/components/Table';
import { FC } from 'react';
import { DataTableDeleteButton } from 'app/components/DataTableDeleteButton';

export interface SurveyListDataTableRowProps {
  onDeleteSuccess?: (row: DenormalizedRow) => void;
  row: DenormalizedRow;
}

export const SurveyListDataTableRow: FC<
  SurveyListDataTableRowProps & Pick<DataTableCustomRenderProps, 'getRowProps'>
> = ({ onDeleteSuccess, getRowProps, row }) => {
  const deleteMutation = useDeleteSurveyMutation();

  return (
    <TableRow {...getRowProps({ row })}>
      {row.cells.map((cell) => (
        <TableCell key={cell.id}>{cell.value}</TableCell>
      ))}

      <ActionsTableCell>
        <Button
          as={Link}
          hasIconOnly
          iconDescription="Edytuj"
          kind="ghost"
          renderIcon={Edit20}
          size="small"
          to={SurveyRoute(row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <DataTableDeleteButton
          confirmationTitle="Usuń ankietę"
          isLoading={deleteMutation.isLoading}
          onSubmit={() =>
            deleteMutation.mutate(row.id, {
              onSuccess: () => onDeleteSuccess && onDeleteSuccess(row),
            })
          }
        />
      </ActionsTableCell>
    </TableRow>
  );
};
