import { FC, useCallback, useState } from 'react';
import {
  CarbonFile,
  FileUploaderItem,
  FormLabel,
} from 'carbon-components-react';
import styled from 'styled-components';
import { isNil } from 'ramda';
import { ImageFromFile } from 'app/components/ImageFromFile';
import { FileUploaderDropContainer } from 'app/components/FileUploaderDropContainer';
import { ErrorMessage } from 'app/components/ErrorMessage';
import { validatorMessages } from 'common/services/validation';
import { AllowedExtensionsHelperText } from 'app/components/ImageControl/AllowedExtensionsHelperText';
import { UploadedFile } from 'common/models/uploadedFile';

const Container = styled.div<{ $inline: boolean }>`
  display: ${({ $inline }) => ($inline ? 'grid' : 'block')};
  gap: 1.25rem;
  grid-template-columns: 2.75rem minmax(min-content, max-content) minmax(
      12.5rem,
      1fr
    );
  width: 100%;
`;

const Image = styled.div`
  padding: 1rem 0;
  text-align: center;

  img {
    max-width: 100%;
  }
`;

const FileContainer = styled.div<{ $inline: boolean }>`
  margin-top: ${({ $inline }) => ($inline ? '0' : '1rem')};
`;

const FileUploaderContainer = styled.div`
  .bx--file-browse-btn {
    margin-bottom: 0;
  }
`;

const StyledFileUploaderDropContainer = styled(FileUploaderDropContainer)<{
  $inline: boolean;
}>`
  height: ${({ $inline }) => ($inline ? '3rem' : '6rem')};
`;

const validateFile = (
  fileToUpload: CarbonFile,
  allowedExtensions: string[]
): UploadedFile => {
  const file: UploadedFile = {
    file: fileToUpload,
    size: fileToUpload.size,
    invalidFileType: fileToUpload.invalidFileType,
    mime: fileToUpload.type,
    name: fileToUpload.name,
    status: 'new',
  };

  if (fileToUpload.size > 1048576) {
    file.errorBody = 'Plik powinien mieć maksymalnie 1mb';
    file.errorSubject = 'Rozmiar pliku jest zbyt duży';
    file.invalid = true;

    return file;
  }

  if (fileToUpload.invalidFileType) {
    file.errorBody = 'Nieobsługiwany typ pliku.';

    if (allowedExtensions.length === 1) {
      file.errorBody = `Obsługiwany format to: ${allowedExtensions[0]}`;
    } else if (allowedExtensions.length > 1) {
      file.errorBody = `Obsługiwane formaty to: ${allowedExtensions.join(
        ', '
      )}`;
    }

    file.errorSubject = validatorMessages.invalidFileType;
    file.invalid = true;
  }

  return file;
};

export interface ImageControlProps {
  accept?: string[];
  allowedExtensions?: string[];
  currentImage?: string;
  id: string;
  inline?: boolean;
  invalid: boolean;
  invalidText?: string;
  label?: string;
  onChange?: (value: File | null) => void;
}

export const ImageControl: FC<ImageControlProps> = ({
  accept = ['image/png'],
  allowedExtensions = ['.png'],
  currentImage,
  id,
  inline = false,
  invalid,
  invalidText,
  label,
  onChange,
}) => {
  const [newImage, setNewImage] = useState<UploadedFile | null>(null);
  const [newFile, setNewFile] = useState<File | null>(null);

  const handleAddFiles = useCallback(
    (event, { addedFiles }: { addedFiles: CarbonFile[] }) => {
      event.stopPropagation();

      const file = validateFile(addedFiles[0], allowedExtensions);

      setNewFile(addedFiles[0]);
      setNewImage(file);
      onChange && onChange(!file.invalid ? addedFiles[0] : null);
    },
    [allowedExtensions]
  );

  const handleDelete = useCallback(() => {
    setNewFile(null);
    setNewImage(null);
    onChange && onChange(null);
  }, []);

  return (
    <Container id={id} $inline={inline}>
      {!inline && !label && (
        <FormLabel>
          {currentImage ? 'Zmień zdjęcie' : 'Dodaj zdjęcie'}
        </FormLabel>
      )}

      {!inline && label && <FormLabel>{label}</FormLabel>}

      <Image>
        {!newFile && currentImage && <img alt="" src={currentImage} />}
        {newFile && <ImageFromFile file={newFile} />}
      </Image>

      {!inline && (
        <AllowedExtensionsHelperText allowedExtensions={allowedExtensions} />
      )}

      <FileUploaderContainer>
        <StyledFileUploaderDropContainer
          accept={accept}
          labelText={currentImage ? 'Zmień zdjęcie' : 'Dodaj zdjęcie'}
          onAddFiles={handleAddFiles}
          $inline={inline}
          $invalid={isNil(newImage?.invalid) && invalid}
        />

        {inline && (!isNil(newImage?.invalid) || !invalid) && (
          <AllowedExtensionsHelperText allowedExtensions={allowedExtensions} />
        )}

        {isNil(newImage?.invalid) && invalid && (
          <ErrorMessage className="bx--form-requirement">
            {invalidText}
          </ErrorMessage>
        )}
      </FileUploaderContainer>

      {newImage && (
        <FileContainer $inline={inline}>
          <FileUploaderItem
            errorBody={newImage.errorBody}
            errorSubject={newImage.errorSubject}
            invalid={newImage.invalid}
            name={newImage.name}
            onDelete={handleDelete}
            status="edit"
          />
        </FileContainer>
      )}
    </Container>
  );
};
