import { useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { getCourseBundleAssignableSpeakers } from 'common/services/courseBundle';

export const useCourseBundleAssignableSpeakersQuery = (
  elearningIds?: number[],
  eventIds?: number[]
) =>
  useQuery(
    [QueryKey.CourseBundleAssignableSpeakers, elearningIds, eventIds],
    () => getCourseBundleAssignableSpeakers(elearningIds, eventIds),
    {
      enabled: !!elearningIds?.length || !!eventIds?.length,
    }
  );
