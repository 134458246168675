import { Content } from 'common/components/Grid';
import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  Grid,
  Row,
  SkeletonText,
} from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import {
  ElearningFormRoute,
  elearningIdRouteParam,
  ElearningLessonListRoute,
  ElearningListRoute,
  RootRoute,
} from 'routes';
import { PageHeading } from 'common/components/Heading';
import React, { FC, useMemo } from 'react';
import { useCreateElearningLessonMutation } from 'common/hooks/elearningLesson/useCreateElearningLessonMutation';
import { useUpdateElearningLessonMutation } from 'common/hooks/elearningLesson/useUpdateElearningLessonMutation';
import { useElearningLessonByIdQuery } from 'common/hooks/elearningLesson/useElearningLessonByIdQuery';
import { useElearningByIdQuery } from 'common/hooks/elearning/useElearningByIdQuery';
import { ElearningLessonForm } from 'app/components/ElearningLessonForm';
import {
  mapToElearningLessonCreateDTO,
  mapToElearningLessonFormModel,
  mapToElearningLessonUpdateDTO,
} from 'common/models/elearningLesson';
import { setParam } from 'common/utils/routing';

export interface ElearningLessonListUrlParams {
  elearningId: string;
  elearningLessonId: string;
}

export interface ElearningLessonFormPageProps {
  create?: boolean;
}

export const ElearningLessonFormPage: FC<ElearningLessonFormPageProps> = ({
  create,
}) => {
  const { elearningId, elearningLessonId } =
    useParams<ElearningLessonListUrlParams>();

  const createMutation = useCreateElearningLessonMutation(elearningId);
  const updateMutation = useUpdateElearningLessonMutation(elearningId);

  const { data: elearningData } = useElearningByIdQuery(elearningId);

  const { data: elearningLessonData, isLoading: isElearningLessonLoading } =
    useElearningLessonByIdQuery(
      elearningId,
      elearningLessonId,
      !create && elearningId.length > 0
    );

  const formValues = useMemo(
    () =>
      elearningLessonData && mapToElearningLessonFormModel(elearningLessonData),
    [elearningLessonData]
  );

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={ElearningListRoute}>Lista e-learningów</Link>
          </BreadcrumbItem>

          {elearningData && (
            <BreadcrumbItem>
              <Link
                to={setParam(elearningIdRouteParam)(ElearningFormRoute)(
                  elearningId
                )}
              >
                {elearningData.title}
              </Link>
            </BreadcrumbItem>
          )}

          <BreadcrumbItem>
            <Link
              to={setParam(elearningIdRouteParam)(ElearningLessonListRoute)(
                elearningId
              )}
            >
              Lista lekcji
            </Link>
          </BreadcrumbItem>
        </Breadcrumb>

        {create && <PageHeading>Nowa lekcja</PageHeading>}

        {!create && (
          <PageHeading>
            {isElearningLessonLoading ? (
              <SkeletonText heading={true} width="300px" />
            ) : (
              `${elearningLessonData?.title}`
            )}
          </PageHeading>
        )}

        <Row>
          <Column lg={18} xlg={14}>
            {create && (
              <ElearningLessonForm
                onSubmit={(values) =>
                  createMutation.mutate(mapToElearningLessonCreateDTO(values))
                }
                isCreate={true}
                isSubmitting={createMutation.isLoading}
              />
            )}
            {!create && (
              <ElearningLessonForm
                onSubmit={(values) =>
                  updateMutation.mutate(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    mapToElearningLessonUpdateDTO(values, elearningLessonId!)
                  )
                }
                formValues={elearningLessonData ? formValues : undefined}
                isCreate={false}
                isElearningLessonLoading={isElearningLessonLoading}
                isSubmitting={updateMutation.isLoading}
              />
            )}
          </Column>
        </Row>
      </Grid>
    </Content>
  );
};
