import { BannerFields } from 'modules/banner/types.banner';

export function bannerPayload(submitted: BannerFields) {
  const payload = new FormData();

  payload.set(
    'banner',
    new Blob(
      [
        JSON.stringify({
          title: submitted.title,
          subpage: submitted.page,
          displayStatus: submitted.displayStatus,
          link: submitted.redirectURL,
          categoryId: submitted.category?.id,
        }),
      ],
      {
        type: 'application/json',
      }
    )
  );

  const upload = submitted.upload;

  if (upload) {
    payload.set(
      'image',
      upload instanceof File ? upload : JSON.stringify(upload)
    );
  }

  return payload;
}
