import { createAction } from 'lib/createAction';
import { PaginationState } from 'common/models/pagination';
import { CourseEventParticipantSortData } from 'common/models/courseEventParticipant';

export interface CourseEventParticipantsListFilters {
  courseId?: string;
  eventId?: string;
  pagination: PaginationState;
  sort: CourseEventParticipantSortData;
}

export const courseEventParticipantActions = {
  clear: createAction('courseEventParticipant/clear')(),
  loadPagination: createAction('courseEventParticipant/loadPagination')(),
  setCourseId: createAction('courseEventParticipant/setCourseId')<
    string | undefined
  >(),
  setEventId: createAction('courseEventParticipant/setEventId')<
    string | undefined
  >(),
  setFilters: createAction(
    'courseEventParticipant/setFilters'
  )<CourseEventParticipantsListFilters>(),
  setPagination: createAction(
    'courseEventParticipant/setPage'
  )<PaginationState>(),
  setSort: createAction(
    'courseEventParticipant/setSort'
  )<CourseEventParticipantSortData>(),
} as const;
