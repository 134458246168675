import { createReducer } from '@reduxjs/toolkit';
import { PaginationState } from 'common/models/pagination';
import { none, Option, some } from 'fp-ts/lib/Option';
import { partnerActions } from 'state/partner/partnerActions';

export interface PartnerState {
  pagination: Option<PaginationState>;
}

const initialState: PartnerState = {
  pagination: none,
};

export const partnerReducer = createReducer<PartnerState>(
  initialState,
  (builder) =>
    builder.addCase(partnerActions.setPagination, (state, action) => {
      state.pagination = some(action.payload);
    })
);
