import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { withSentryRouting } from '@sentry/react';

const SentryRoute = withSentryRouting(Route);

interface ConditionalRouteProps extends RouteProps {
  condition: boolean;
  redirect: string;
}

export const ConditionalRoute = ({
  children,
  condition,
  redirect,
  ...rest
}: ConditionalRouteProps) => {
  if (!children) {
    return null;
  }

  return (
    <SentryRoute {...rest}>
      {condition ? children : <Redirect to={redirect} />}
    </SentryRoute>
  );
};
