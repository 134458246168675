import { useDispatch, useSelector } from 'react-redux';
import { notificationSnackbarsSelector } from 'state/notification/notificationSelectors';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef } from 'react';
import { snackbarActions } from 'state/notification/notificationActions';
import { SnackbarMessage } from 'common/components/SnackbarMessage';

export const StoreSnackbarController = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(notificationSnackbarsSelector);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const displayed = useRef<string[]>([]);

  const storeDisplayed = useCallback((id: string) => {
    displayed.current = [...displayed.current, id];
  }, []);

  const removeDisplayed = useCallback((id: string) => {
    displayed.current = [...displayed.current.filter((key) => id !== key)];
  }, []);

  useEffect(() => {
    notifications.forEach(
      ({ key, title, options = {}, subtitle = '', dismissed = false }) => {
        if (dismissed) {
          closeSnackbar(key);
          return;
        }

        if (displayed.current.includes(key)) return;

        enqueueSnackbar(title, {
          key,
          ...options,
          content: (key, message) => (
            <SnackbarMessage
              id={key}
              options={options}
              subtitle={subtitle}
              title={message}
            />
          ),
          onClose: (event, reason, myKey) => {
            options.onClose?.(event, reason, myKey);
          },
          onExited: (event, key) => {
            dispatch(snackbarActions.removeSnackbar({ key: `${key}` }));
            removeDisplayed(`${key}`);
          },
        });

        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return <></>;
};
