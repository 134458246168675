import { TranslatedError } from 'common/models/error';
import * as Sentry from '@sentry/react';
import Axios from 'axios';

const blockedClientErrors = [401, 404];

export const captureException = ({
  error,
  message,
  originalError,
}: TranslatedError) => {
  if (
    Axios.isAxiosError(originalError) &&
    originalError.response &&
    blockedClientErrors.includes(originalError.response?.status)
  ) {
    return;
  }

  Sentry.withScope(function (scope) {
    scope.setFingerprint([
      message,
      (Axios.isAxiosError(originalError) &&
        originalError.response?.status.toString()) ||
        'undefined',
    ]);
    scope.setLevel('error');

    Sentry.captureException(originalError, {
      contexts: {
        'Error Details': {
          error,
          message,
          originalError,
        },
      },
    });
  });
};
