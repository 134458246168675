import { FC } from 'react';
import styled from 'styled-components';
import { Loading } from 'carbon-components-react';

const Container = styled.div`
  align-items: center;
  display: flex;
`;

const Label = styled.span`
  margin-left: 0.25rem;
`;

export interface LoadingStatusIndicatorProps {
  label: string;
}

export const LoadingStatusIndicator: FC<LoadingStatusIndicatorProps> = ({
  label,
}) => {
  return (
    <Container>
      <Loading small withOverlay={false} />
      <Label>{label}</Label>
    </Container>
  );
};
