import { FC } from 'react';
import { unstable_ProgressBar as ProgressBar } from 'carbon-components-react';
import fileSize from 'filesize';
import styled from 'styled-components';

const ProgressBarContainer = styled.div`
  padding: 0.5rem 0;
  text-align: left;
`;

export interface MaterialsProgressBarProps {
  progress: number;
  total: number;
}

export const MaterialsProgressBar: FC<MaterialsProgressBarProps> = ({
  progress,
  total,
}) => {
  return (
    <ProgressBarContainer>
      <ProgressBar
        helperText={
          progress === total
            ? 'Gotowe'
            : `${fileSize(progress)} z ${fileSize(total)}`
        }
        label="Wysyłanie"
        max={total}
        value={progress}
      />
    </ProgressBarContainer>
  );
};
