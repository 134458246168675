import styled, { css } from 'styled-components';
import { ChevronDown16 as ChevronDown } from '@carbon/icons-react';
import { DropdownComponentProps } from 'app/components/CourseBundleForm/NestedMultiSelect/DropdownComponentProps';

export const DropdownArrowIcon = styled(ChevronDown)<DropdownComponentProps>`
  ${(props) =>
    !props.$dropdownHidden &&
    css`
      transform: rotate(180deg);
    `}

  transition: transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
`;
