import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Loading } from 'carbon-components-react';
import { spacing10 } from '@carbon/layout';
import { queryClient } from 'common/providers/ReactQueryClientProvider';
import { QueryKey } from 'config/queryKey';
import { ID } from 'lib/id';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: ${spacing10} 0;
`;

export interface CategoryDeleteStep2Props {
  categoryId: ID;
}

export const CategoryDeleteStep2: FC<CategoryDeleteStep2Props> = ({
  categoryId,
}) => {
  useEffect(() => {
    queryClient.invalidateQueries([QueryKey.CourseListByCategory, categoryId]);
  }, []);

  return (
    <Container>
      <Loading withOverlay={false} />
    </Container>
  );
};
