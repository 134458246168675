import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, FormLabel, TextInput } from 'carbon-components-react';
import { Controller, useFormContext } from 'react-hook-form';
import { FileInput } from 'app/components/FileInput';
import { UploadedFile } from 'common/models/uploadedFile';
import { mapToUploadedFile, StrapiFile } from 'common/models/strapiFile';
import styled from 'styled-components';
import { hasPath, path } from 'ramda';
import { StrapiItemResponse } from 'modules/static-pages/models/strapi';

const ButtonContainer = styled.div`
  margin-top: 1rem;
  text-align: right;
`;

export interface StaticPageAttachmentControlProps {
  currentFile?: StrapiItemResponse<StrapiFile>;
}

export const StaticPageAttachmentControl: FC<
  StaticPageAttachmentControlProps
> = ({ currentFile }) => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
  } = useFormContext();

  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);

  const clearAttachment = useCallback(() => {
    setValue('attachment.file', null);
    setValue('attachment.id', null);
    setValue('attachment.text', '');
    setValue('attachmentFile', null);
    setUploadedFile(null);
  }, [setValue]);

  const resetUploadedFile = useCallback(() => {
    setUploadedFile(currentFile ? mapToUploadedFile(currentFile) : null);
  }, [currentFile]);

  useEffect(() => {
    resetUploadedFile();
  }, [currentFile]);

  return (
    <>
      <FormLabel>Załącznik</FormLabel>

      <Controller
        control={control}
        name="attachmentFile"
        render={({ field: { onChange }, fieldState: { error } }) => (
          <FileInput
            id="attachmentFile"
            invalid={!!error}
            invalidText={error?.message}
            onChange={(uploadedFile) => {
              onChange(
                uploadedFile && !uploadedFile.invalid ? uploadedFile.file : null
              );
              setUploadedFile(uploadedFile);
            }}
            onClear={resetUploadedFile}
            uploadedFile={uploadedFile}
          />
        )}
      />

      {uploadedFile && (
        <>
          <TextInput
            id="attachment-text"
            invalid={hasPath(['attachment', 'text'], errors)}
            invalidText={path(['attachment', 'text', 'message'], errors)}
            labelText="Etykieta załącznika"
            {...register('attachment.text')}
          />

          <ButtonContainer>
            <Button kind="danger" onClick={clearAttachment} size="sm">
              Usuń załącznik
            </Button>
          </ButtonContainer>
        </>
      )}
    </>
  );
};
