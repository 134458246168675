import { CaretLeft16, CaretRight16 } from '@carbon/icons-react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  Grid,
  Select,
  SelectItem,
} from 'carbon-components-react';
import { Content } from 'common/components/Grid';
import { PageHeading } from 'common/components/Heading';
import { addWeeks, isMonday, nextSunday, previousMonday } from 'date-fns';
import { format } from 'lib/date';
import { DashboardListTableContent } from 'modules/dashboard/components/DashboardListTableContent';
import { dashboardHeaders } from 'modules/dashboard/config/dashboardHeaders';
import { dashboardRow } from 'modules/dashboard/config/dashboardRow';
import { useQueryEvents } from 'modules/dashboard/hooks/useQueryEvents';
import { times } from 'ramda';
import { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootRoute } from 'routes';
import { dashboardActions } from 'state/dashboard/dashboardActions';
import { dashboardPaginationSelector } from 'state/dashboard/dashboardSelectors';

export function DashboardPage() {
  const dispatch = useDispatch();
  const pagination = useSelector(dashboardPaginationSelector);

  const query = useQueryEvents({ page: pagination.page, pageSize: 20 });

  const rows = useMemo(
    () => query.data?.events.map(dashboardRow),
    [query.data]
  );

  const pageSize = rows?.length ?? 0;

  const today = useRef(Date.now()).current;
  const lastMonday = isMonday(today) ? today : previousMonday(today);

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageHeading>Panel administratora</PageHeading>

        {query.isLoading && (
          <DataTableSkeleton headers={dashboardHeaders} rowCount={pageSize}>
            {(props: DataTableCustomRenderProps) => (
              <DashboardListTableContent
                {...props}
                isFetching={query.isFetching}
              />
            )}
          </DataTableSkeleton>
        )}

        {rows && !query.isLoading && (
          <DataTable
            headers={dashboardHeaders}
            overflowMenuOnHover={false}
            rows={rows}
          >
            {(props: DataTableCustomRenderProps) => (
              <DashboardListTableContent
                {...props}
                events={query.data}
                isFetching={query.isFetching}
              />
            )}
          </DataTable>
        )}

        <div
          style={{
            background: '#ffffff',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div>
            <Select
              id="week"
              hideLabel
              inline
              onChange={(e) => {
                dispatch(
                  dashboardActions.setPagination({
                    page: Number(e.target.value),
                  })
                );
              }}
              value={pagination.page}
            >
              {query.data?.totalPages &&
                times((index) => {
                  const start = addWeeks(lastMonday, index);
                  const end = nextSunday(start);

                  const toFormat = (date: Date) => format(date, 'dd.MM');
                  const range = () => toFormat(start) + ' - ' + toFormat(end);

                  return (
                    <SelectItem
                      key={index}
                      value={index + 1}
                      text={`${range()} Tydzień ${index + 1}`}
                    />
                  );
                }, query.data?.totalPages)}
            </Select>
          </div>

          <Button
            hasIconOnly
            iconDescription="Następny tydzień"
            kind="ghost"
            renderIcon={CaretLeft16}
            size="md"
            tooltipAlignment="center"
            tooltipPosition="top"
            disabled={pagination.page === 1}
            onClick={() =>
              dispatch(
                dashboardActions.setPagination({ page: pagination.page - 1 })
              )
            }
          />

          <Button
            hasIconOnly
            iconDescription="Następny tydzień"
            kind="ghost"
            renderIcon={CaretRight16}
            size="md"
            tooltipAlignment="center"
            tooltipPosition="top"
            disabled={query.data?.totalPages === pagination.page}
            onClick={() =>
              dispatch(
                dashboardActions.setPagination({ page: pagination.page + 1 })
              )
            }
          />
        </div>
      </Grid>
    </Content>
  );
}
