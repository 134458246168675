import { FC } from 'react';
import { ForwardRefProps } from 'carbon-components-react/typings/shared';
import {
  ButtonDefaultProps,
  ButtonKindProps,
} from 'carbon-components-react/lib/components/Button/Button';
import { Button, Loading } from 'carbon-components-react';
import styled from 'styled-components';
import { LoadingButton } from 'app/components/Button';

const InvisibleButton = styled(Button)`
  visibility: hidden;
`;

export interface DataTableButtonProps {
  isLoading: boolean;
  isVisible?: boolean;
}

export const DataTableButton: FC<
  DataTableButtonProps &
    ForwardRefProps<HTMLButtonElement, ButtonDefaultProps & ButtonKindProps>
> = ({ hasIconOnly, isLoading, isVisible = true, kind, size, ...rest }) => {
  if (!isVisible) {
    return (
      <InvisibleButton
        disabled
        hasIconOnly={hasIconOnly}
        kind={kind}
        size={size}
        {...rest}
      />
    );
  }

  if (isLoading) {
    return (
      <LoadingButton disabled hasIconOnly={hasIconOnly} kind={kind} size={size}>
        <Loading small withOverlay={false} />
      </LoadingButton>
    );
  }

  return <Button hasIconOnly={hasIconOnly} kind={kind} size={size} {...rest} />;
};
