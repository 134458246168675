import React, { memo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Form,
  FormLabel,
  Grid,
  InlineLoading,
  Row,
  TextInput,
  TextInputSkeleton,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Content } from 'common/components/Grid';
import { setYupDefaultMessages } from 'common/services/yup';
import { FormItem } from 'app/components/FormItem';
import { useScrollToError } from 'common/hooks/useScrollToError';
import { useUpdateStaticPageMutation } from 'modules/static-pages/hooks/useUpdateStaticPageMutation';
import {
  mapToStaticPageTosDTO,
  mapToStaticPageTosFormModel,
  StaticPageTosFormModel,
} from 'modules/static-pages/models/staticPage';
import { StaticPageContentControl } from 'modules/static-pages/components/StaticPageForm/StaticPageContentControl';
import { useStaticPageQuery } from 'modules/static-pages/hooks/useStaticPageQuery';
import { ContentEditor } from 'app/components/ContentEditor';
import {
  StaticPageCode,
  staticPageCodeTitleMapper,
} from 'modules/static-pages/models/staticPageCode';
import { StaticPageAttachmentControl } from 'modules/static-pages/components/StaticPageForm/StaticPageAttachmentControl';
import { pipe } from 'fp-ts/function';
import {
  staticPageFieldsOrder,
  staticPageFormSchema,
} from 'modules/static-pages/config/staticPageValidationSchema';
import { StaticPageListRoute } from 'modules/static-pages/routes';

setYupDefaultMessages();

export interface StaticPageFormContentProps {
  code: StaticPageCode;
}

export const StaticPageFormContent = memo<StaticPageFormContentProps>(
  ({ code }) => {
    const updateMutation = useUpdateStaticPageMutation(code);

    const methods = useForm<StaticPageTosFormModel>({
      resolver: yupResolver(staticPageFormSchema),
      shouldFocusError: false,
    });

    const onError = useScrollToError(staticPageFieldsOrder);

    const { data: staticPageData, isFetching: isLoading } = useStaticPageQuery(
      code,
      (data) => methods.reset(mapToStaticPageTosFormModel(data))
    );

    const onSubmit = methods.handleSubmit(
      async (values) =>
        pipe(values, mapToStaticPageTosDTO, updateMutation.mutate),
      onError
    );

    return (
      <Content>
        <Grid>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={RootRoute}>PFP Admin</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={StaticPageListRoute}>Strony statyczne</Link>
            </BreadcrumbItem>
          </Breadcrumb>

          <PageHeading>{staticPageCodeTitleMapper[code]}</PageHeading>

          <FormProvider {...methods}>
            <Form onSubmit={onSubmit} noValidate>
              <Row>
                <Column xlg={10}>
                  <FormItem>
                    {isLoading ? (
                      <TextInputSkeleton />
                    ) : (
                      <TextInput
                        id="title"
                        invalid={!!methods.formState.errors.title}
                        invalidText={methods.formState.errors.title?.message}
                        labelText="Tytuł"
                        {...methods.register('title')}
                      />
                    )}
                  </FormItem>

                  <FormItem id="intro">
                    {isLoading ? (
                      <TextInputSkeleton />
                    ) : (
                      <>
                        <FormLabel>Wstęp</FormLabel>
                        <ContentEditor name="intro" />
                      </>
                    )}
                  </FormItem>

                  <FormItem id="content">
                    {isLoading ? (
                      <TextInputSkeleton />
                    ) : (
                      <StaticPageContentControl label="Treść" name="content" />
                    )}
                  </FormItem>

                  {updateMutation.isLoading ? (
                    <Button disabled kind="primary" tabIndex={-1}>
                      <InlineLoading
                        description="Zapisywanie..."
                        status="active"
                      />
                    </Button>
                  ) : (
                    <Button kind="primary" tabIndex={0} type="submit">
                      Zapisz
                    </Button>
                  )}
                </Column>

                <Column xlg={4}>
                  <FormItem id="attachment">
                    {isLoading ? (
                      <TextInputSkeleton />
                    ) : (
                      <StaticPageAttachmentControl
                        currentFile={
                          staticPageData?.data.attributes.attachment?.file
                        }
                      />
                    )}
                  </FormItem>
                </Column>
              </Row>
            </Form>
          </FormProvider>
        </Grid>
      </Content>
    );
  }
);
