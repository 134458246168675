import { createReducer } from '@reduxjs/toolkit';
import { PaginationState } from 'common/models/pagination';
import { none, Option, some } from 'fp-ts/lib/Option';
import { ID } from 'lib/id';
import { partnerUsersActions } from 'state/partnerUsers/partnerUsersActions';

export interface PartnerUsersState {
  pagination: Option<PaginationState>;
  partnerId: ID;
}

const initialState: PartnerUsersState = {
  pagination: none,
  partnerId: '',
};

export const partnerUsersReducer = createReducer<PartnerUsersState>(
  initialState,
  (builder) =>
    builder
      .addCase(partnerUsersActions.setPagination, (state, action) => {
        state.pagination = some(action.payload);
      })
      .addCase(partnerUsersActions.setPartnerId, (state, action) => {
        state.partnerId = action.payload || '';
      })
);
