import { Button, Tile } from 'carbon-components-react';
import React, { FC, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Draggable20, TrashCan20 } from '@carbon/icons-react';
import { Controller, useFormContext } from 'react-hook-form';
import { ContentEditor } from 'app/components/ContentEditor';
import { ImageControl } from 'app/components/ImageControl';
import { prependCMSUrl } from 'config/environment';
import {
  AboutUsListControl,
  SortableItemProps,
} from 'modules/static-pages/components/StaticPageForm/AboutUsListControl';
import EditorJS from '@editorjs/editorjs';
import { editorjsTextOnlyTools } from 'config/editorjs';
import {
  aboutUsAcceptedFiles,
  aboutUsAllowedExtensions,
} from 'modules/static-pages/config/upload';

const StyledTile = styled(Tile)`
  margin: 0.125rem 0;
  padding-bottom: 0;
`;

const Row = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

const SortableItem: FC<SortableItemProps> = ({
  controlId,
  index,
  name,
  onDelete,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: controlId });

  const { control, getValues } = useFormContext();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || '',
  };

  const currentIcon = useMemo(
    () => getValues(`${name}.${index}.currentIcon`),
    [getValues, name, index]
  );

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <StyledTile>
        <Row>
          <Button
            hasIconOnly
            iconDescription="Przenieś"
            kind="ghost"
            renderIcon={Draggable20}
            size="small"
            tooltipAlignment="center"
            tooltipPosition="top"
            {...listeners}
          />

          <Controller
            control={control}
            name={`${name}.${index}.iconFile`}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <ImageControl
                accept={aboutUsAcceptedFiles}
                allowedExtensions={aboutUsAllowedExtensions}
                currentImage={
                  currentIcon ? prependCMSUrl(currentIcon) : undefined
                }
                id={`${name}.${index}.iconFile`}
                inline={true}
                invalid={!!error}
                invalidText={error?.message}
                onChange={(file) => {
                  onChange(file);
                }}
              />
            )}
          />

          <Button
            hasIconOnly
            iconDescription="Usuń"
            kind="danger--ghost"
            onClick={() => onDelete(controlId)}
            renderIcon={TrashCan20}
            size="small"
            tooltipAlignment="center"
            tooltipPosition="top"
          />
        </Row>

        <ContentEditor
          name={`${name}.${index}.text`}
          tools={editorjsTextOnlyTools}
        />
      </StyledTile>
    </div>
  );
};

export interface AboutUsBenefitsListControlProps {
  label?: string;
  name: string;
}

export const AboutUsBenefitsListControl: FC<AboutUsBenefitsListControlProps> = (
  props
) => {
  const getEmptyItem = useCallback(
    () => ({
      text: {
        blocks: [],
        time: +new Date(),
        version: EditorJS.version,
      },
    }),
    []
  );

  return (
    <AboutUsListControl
      {...props}
      getEmptyItem={getEmptyItem}
      SortableItem={SortableItem}
    />
  );
};
