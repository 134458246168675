import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  DenormalizedRow,
  Grid,
  Pagination,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { CourseListRoute, getCourseFormRoute, RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarActions } from 'state/notification/notificationActions';
import { QueryKey } from 'config/queryKey';
import { useQueryClient } from 'react-query';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/lib/Option';
import { initialPagination, pageSizes } from 'common/models/pagination';
import { Content } from 'common/components/Grid';
import { coerceUTCDateString } from 'lib/coerceUTCDateString';
import { parseISO } from 'date-fns';
import {
  courseEventCourseIdSelector,
  courseEventPaginationSelector,
  courseEventStatusSelector,
} from 'state/courseEvent/courseEventSelectors';
import { courseEventActions } from 'state/courseEvent/courseEventActions';
import { useCourseEventListQuery } from 'common/hooks/courseEvent/useCourseEventListQuery';
import { format } from 'lib/date';
import { CourseEventListDataTableContent } from 'app/components/CourseEventList/CourseEventListDataTableContent';
import { useCourseByIdQuery } from 'common/hooks/course/useCourseByIdQuery';
import {
  CourseEventListColumn,
  CourseEventListItem,
  CourseEventStatus,
  courseEventStatusLabelMap,
} from 'common/models/courseEvent';
import { isSome } from 'fp-ts/Option';

const headers = [
  {
    key: CourseEventListColumn.StartDateTime,
    header: 'Data i godzina rozpoczęcia',
  },
  {
    key: CourseEventListColumn.ParticipantsAccepted,
    header: 'Ilość zgłoszeń',
  },
  {
    key: CourseEventListColumn.ParticipantsPresent,
    header: 'Ilość uczestników',
  },
  {
    key: CourseEventListColumn.Status,
    header: 'Status',
  },
];

const prepareCourseEventRow = (courseEvent: CourseEventListItem) => ({
  id: courseEvent.id.toString(),
  [CourseEventListColumn.ParticipantsAccepted]:
    courseEvent.participantsAccepted,
  [CourseEventListColumn.ParticipantsPresent]: courseEvent.participantsPresent,
  [CourseEventListColumn.StartDateTime]: courseEvent.startDateTime
    ? parseISO(coerceUTCDateString(courseEvent.startDateTime))
    : null,
  [CourseEventListColumn.Status]: courseEventStatusLabelMap[courseEvent.status],
});

export const CourseEventList: FC = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const courseId = useSelector(courseEventCourseIdSelector);
  const paginationOption = useSelector(courseEventPaginationSelector);
  const eventStatusOption = useSelector(courseEventStatusSelector);

  const eventStatus = useMemo(
    () =>
      pipe(
        eventStatusOption,
        getOrElse(() => 'undefined')
      ),
    [eventStatusOption]
  );

  const pagination = useMemo(
    () =>
      pipe(
        paginationOption,
        getOrElse(() => initialPagination)
      ),
    [paginationOption]
  );

  const { data: courseData, isLoading: isLoadingCourse } =
    useCourseByIdQuery(courseId);

  const {
    data,
    isFetching: isFetchingCourseEvents,
    isLoading: isLoadingCourseEvents,
  } = useCourseEventListQuery(
    {
      courseId,
      status: isSome(eventStatusOption)
        ? (eventStatus as CourseEventStatus)
        : undefined,
    },
    {
      onSuccess: (data) => {
        if (data && data.result.length === 0 && pagination.page !== 1) {
          dispatch(
            courseEventActions.setPagination({
              page: Math.ceil(data.totalResult / pagination.pageSize),
              pageSize: pagination.pageSize,
            })
          );
        }
      },
    }
  );

  const handleCloseSuccess = useCallback(
    (row: DenormalizedRow) => {
      dispatch(
        snackbarActions.enqueueSnackbar({
          options: { variant: 'success' },
          title: `Wydarzenie ${format(
            row.cells[0].value,
            'dd.MM.yyyy HH:mm'
          )} zostało zamknięte`,
        })
      );
      queryClient.invalidateQueries(QueryKey.CourseEventList);
    },
    [data, dispatch, pagination]
  );

  const handleDeleteSuccess = useCallback(
    (row: DenormalizedRow) => {
      dispatch(
        snackbarActions.enqueueSnackbar({
          options: { variant: 'success' },
          title: `Wydarzenie ${format(
            row.cells[0].value,
            'dd.MM.yyyy HH:mm'
          )} zostało usunięte`,
        })
      );
      queryClient.invalidateQueries(QueryKey.CourseEventList);
    },
    [data, dispatch, pagination]
  );

  const handleStartSuccess = useCallback(
    (row: DenormalizedRow) => {
      dispatch(
        snackbarActions.enqueueSnackbar({
          options: { variant: 'success' },
          title: `Wydarzenie ${format(
            row.cells[0].value,
            'dd.MM.yyyy HH:mm'
          )} zostało rozpoczęte`,
        })
      );
      queryClient.invalidateQueries(QueryKey.CourseEventList);
    },
    [data, dispatch, pagination]
  );

  useEffect(() => {
    dispatch(courseEventActions.loadPagination());

    return () => {
      dispatch(courseEventActions.clear());
    };
  }, [dispatch]);

  const rows = useMemo(() => data?.result.map(prepareCourseEventRow), [data]);

  const isLoading = isLoadingCourse || isLoadingCourseEvents;

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={CourseListRoute}>Lista szkoleń</Link>
          </BreadcrumbItem>

          {courseData && (
            <BreadcrumbItem>
              <Link to={getCourseFormRoute(courseData.id)}>
                {courseData.title}
              </Link>
            </BreadcrumbItem>
          )}
        </Breadcrumb>

        <PageHeading>Lista wydarzeń</PageHeading>

        {!rows && (
          <DataTableSkeleton headers={headers} rowCount={10}>
            {(props: DataTableCustomRenderProps) => (
              <CourseEventListDataTableContent {...props} />
            )}
          </DataTableSkeleton>
        )}

        {rows && !isLoading && (
          <DataTable headers={headers} overflowMenuOnHover={false} rows={rows}>
            {(props: DataTableCustomRenderProps) => (
              <CourseEventListDataTableContent
                {...props}
                courseEventListItems={data?.result}
                isFetching={isFetchingCourseEvents}
                onCloseSuccess={handleCloseSuccess}
                onDeleteSuccess={handleDeleteSuccess}
                onStartSuccess={handleStartSuccess}
              />
            )}
          </DataTable>
        )}

        <Pagination
          onChange={(pagination) => {
            dispatch(
              courseEventActions.setPagination({
                page: pagination.page,
                pageSize: pagination.pageSize,
              })
            );
          }}
          page={pagination.page}
          pageSize={pagination.pageSize}
          pageSizes={pageSizes}
          totalItems={data?.totalResult ?? 0}
        />
      </Grid>
    </Content>
  );
};
