import { QueryObserverOptions, useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { ResultResponse } from 'common/models/response';
import { useSelector } from 'react-redux';
import { getOrElse, isSome } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/function';
import { initialPagination } from 'common/models/pagination';
import { Speaker } from 'common/models/speaker';
import { speakerPaginationSelector } from 'state/speaker/speakerSelectors';
import { getSpeakerList } from 'common/services/speaker';

export const useSpeakerListQuery = (
  options: QueryObserverOptions<ResultResponse<Speaker>>
) => {
  const paginationOption = useSelector(speakerPaginationSelector);

  const pagination = pipe(
    paginationOption,
    getOrElse(() => initialPagination)
  );

  return useQuery<ResultResponse<Speaker>>(
    [QueryKey.SpeakerList, pagination.page, pagination.pageSize],
    () => getSpeakerList(pagination),
    {
      ...options,
      enabled: isSome(paginationOption),
      keepPreviousData: true,
    }
  );
};
