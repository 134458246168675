import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  DenormalizedRow,
  Grid,
  Pagination,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarActions } from 'state/notification/notificationActions';
import { QueryKey } from 'config/queryKey';
import { useQueryClient } from 'react-query';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/lib/Option';
import { initialPagination, pageSizes } from 'common/models/pagination';
import { useSpeakerListQuery } from 'common/hooks/speaker/useSpeakerListQuery';
import { speakerActions } from 'state/speaker/speakerActions';
import { speakerPaginationSelector } from 'state/speaker/speakerSelectors';
import { SpeakerListDataTableContent } from 'app/components/SpeakerList/SpeakerListDataTableContent';
import { Content } from 'common/components/Grid';

const headers = [
  {
    key: 'name',
    header: 'Imię i nazwisko',
  },
];

export const SpeakerList: FC = () => {
  const dispatch = useDispatch();
  const paginationOption = useSelector(speakerPaginationSelector);
  const queryClient = useQueryClient();

  const pagination = useMemo(
    () =>
      pipe(
        paginationOption,
        getOrElse(() => initialPagination)
      ),
    [paginationOption]
  );

  const { data, isFetching, isLoading } = useSpeakerListQuery({
    onSuccess: (data) => {
      if (data && data.result.length === 0 && pagination.page !== 1) {
        dispatch(
          speakerActions.setPagination({
            page: Math.ceil(data.totalResult / pagination.pageSize),
            pageSize: pagination.pageSize,
          })
        );
      }
    },
  });

  const rows = useMemo(
    () =>
      data?.result.map((item) => ({
        id: item.id.toString(),
        name: `${item.firstName} ${item.lastName}`,
      })),
    [data]
  );

  const handleDeleteSuccess = useCallback(
    (row: DenormalizedRow) => {
      dispatch(
        snackbarActions.enqueueSnackbar({
          options: { variant: 'success' },
          title: `Wykładowca ${row.cells[0].value} został usunięty`,
        })
      );
      queryClient.invalidateQueries(QueryKey.SpeakerList);
    },
    [data, dispatch, pagination]
  );

  useEffect(() => {
    dispatch(speakerActions.loadPagination());
  }, [dispatch]);

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageHeading>Lista wykładowców</PageHeading>

        {!rows && (
          <DataTableSkeleton headers={headers} rowCount={10}>
            {(props: DataTableCustomRenderProps) => (
              <SpeakerListDataTableContent {...props} />
            )}
          </DataTableSkeleton>
        )}

        {rows && !isLoading && (
          <DataTable headers={headers} overflowMenuOnHover={false} rows={rows}>
            {(props: DataTableCustomRenderProps) => (
              <SpeakerListDataTableContent
                {...props}
                isFetching={isFetching}
                onDeleteSuccess={handleDeleteSuccess}
              />
            )}
          </DataTable>
        )}

        <Pagination
          onChange={(pagination) => {
            dispatch(
              speakerActions.setPagination({
                page: pagination.page,
                pageSize: pagination.pageSize,
              })
            );
          }}
          page={pagination.page}
          pageSize={pagination.pageSize}
          pageSizes={pageSizes}
          totalItems={data?.totalResult ?? 0}
        />
      </Grid>
    </Content>
  );
};
