import { g10 } from '@carbon/themes';
import { CancelablePromise } from 'common/services/axios';

export interface UploadProgress {
  loaded: number;
  total: number;
}

export enum FileUploaderStatus {
  Error = 'error',
  Success = 'success',
  Uploading = 'uploading',
}

export type FileUploaderMapItem = { [id: string]: FileUploaderListItem };

export type FileUploaderUploadFn = (
  onUploadProgress: (progress: UploadProgress) => void
) => CancelablePromise<unknown>;

export interface FileUploaderProgress {
  current: number;
  total: number;
}

export interface FileUploaderListItem {
  progress: FileUploaderProgress;
  promise?: CancelablePromise<unknown>;
  status: FileUploaderStatus;
  title: string;
}

export interface UploadFileActionPayload {
  id: string;
  title: string;
  uploadFn: FileUploaderUploadFn;
}

export interface UpdateProgressActionPayload {
  id: string;
  progress: FileUploaderProgress;
}

export const fileUploaderStatusColorMapper: Record<FileUploaderStatus, string> =
  {
    [FileUploaderStatus.Error]: g10.support01,
    [FileUploaderStatus.Success]: g10.support02,
    [FileUploaderStatus.Uploading]: g10.interactive01,
  };
