import { DataProp } from 'editorjs-blocks-react-renderer';
import { StrapiFile, StrapiFileFormType } from 'common/models/strapiFile';
import { omit } from 'ramda';
import { StrapiItemResponse } from 'modules/static-pages/models/strapi';

export interface AboutUsBenefitsListItem {
  icon: StrapiItemResponse<StrapiFile>;
  id: string;
  text: DataProp;
}

export interface AboutUsBenefitsListItemDTO {
  icon?: {
    id: string;
  };
  id?: string;
  text: DataProp;
}

export interface AboutUsBenefitsListItemFormModel {
  currentIcon?: string;
  icon?: StrapiFileFormType;
  iconFile?: File;
  id: string;
  text: DataProp;
}

export interface AboutUsBulletListItem {
  id: string;
  text: DataProp;
}

export interface AboutUsBulletListItemDTO {
  id?: string;
  text: DataProp;
}

export interface AboutUsStrengthsListItem {
  id: string;
  text: DataProp;
  unit: string;
  value: string;
}

export interface AboutUsStrengthsListItemDTO {
  id?: string;
  text: DataProp;
  unit: string;
  value: string;
}

export interface AboutUsAdditional {
  heading: string;
  id: string;
  image: StrapiItemResponse<StrapiFile>;
  subtitle: string;
  text: DataProp;
}

export interface AboutUsAdditionalDTO {
  heading: string;
  id: string;
  image?: {
    id: string;
  };
  subtitle: string;
  text: DataProp;
}

export interface AboutUsAdditionalFormModel {
  heading: string;
  id: string;
  image?: StrapiFileFormType;
  imageFile?: File;
  subtitle: string;
  text: DataProp;
}

export interface AboutUsBenefits {
  heading: string;
  id: string;
  list: AboutUsBenefitsListItem[];
  subtitle: string;
}

export interface AboutUsBenefitsDTO {
  heading: string;
  id: string;
  list: AboutUsBenefitsListItemDTO[];
  subtitle: string;
}

export interface AboutUsBenefitsFormModel {
  heading: string;
  id: string;
  list: AboutUsBenefitsListItemFormModel[];
  subtitle: string;
}

export interface AboutUsKnowledge {
  heading: string;
  id: string;
  image: StrapiItemResponse<StrapiFile>;
  list: AboutUsBulletListItem[];
  subtitle: string;
  text: DataProp;
}

export interface AboutUsKnowledgeDTO {
  heading: string;
  id: string;
  image?: {
    id: string;
  };
  list: AboutUsBulletListItemDTO[];
  subtitle: string;
  text: DataProp;
}

export interface AboutUsKnowledgeFormModel {
  heading: string;
  id: string;
  image?: StrapiFileFormType;
  imageFile?: File;
  list: AboutUsBulletListItem[];
  subtitle: string;
  text: DataProp;
}

export interface AboutUsPossibilities {
  heading: string;
  id: string;
  list: AboutUsBulletListItem[];
  subtitle: string;
  text: DataProp;
}

export interface AboutUsPossibilitiesDTO {
  heading: string;
  id: string;
  list: AboutUsBulletListItemDTO[];
  subtitle: string;
  text: DataProp;
}

export interface AboutUsStrengths {
  heading: string;
  id: string;
  list: AboutUsStrengthsListItem[];
  subtitle: string;
}

export interface AboutUsStrengthsDTO {
  heading: string;
  id: string;
  list: AboutUsStrengthsListItemDTO[];
  subtitle: string;
}

export interface AboutUsWhatIfPfp {
  heading: string;
  id: string;
  image: StrapiItemResponse<StrapiFile>;
  text: DataProp;
}

export interface AboutUsWhatIfPfpDTO {
  heading: string;
  id: string;
  image?: {
    id: string;
  };
  text: DataProp;
}

export interface AboutUsWhatIfPfpFormModel {
  heading: string;
  id: string;
  image?: StrapiFileFormType;
  imageFile?: File;
  text: DataProp;
}

export type AboutUsStaticPage = StrapiItemResponse<{
  additional: AboutUsAdditional;
  benefits: AboutUsBenefits;
  knowledge: AboutUsKnowledge;
  possibilities: AboutUsPossibilities;
  strengths: AboutUsStrengths;
  updatedAt: string;
  whatIsPfp: AboutUsWhatIfPfp;
}>;

export interface AboutUsStaticPageDTO {
  data: {
    additional: AboutUsAdditionalDTO;
    benefits: AboutUsBenefitsDTO;
    knowledge: AboutUsKnowledgeDTO;
    possibilities: AboutUsPossibilitiesDTO;
    strengths: AboutUsStrengthsDTO;
    whatIsPfp: AboutUsWhatIfPfpDTO;
  };
  additionalImage?: File;
  benefitsImages?: (File | undefined)[];
  knowledgeImage?: File;
  whatIsPfpImage?: File;
}

export interface AboutUsStaticPageFormModel {
  additional: AboutUsAdditionalFormModel;
  benefits: AboutUsBenefitsFormModel;
  knowledge: AboutUsKnowledgeFormModel;
  possibilities: AboutUsPossibilities;
  strengths: AboutUsStrengths;
  whatIsPfp: AboutUsWhatIfPfpFormModel;
}

export const mapToAboutUsStaticPageFormModel = ({
  data: {
    attributes: {
      additional: { image: additionalImage, ...additionalRest },
      benefits: { list: benefitsList, ...benefitsRest },
      knowledge: { image: knowledgeImage, ...knowledgeRest },
      possibilities,
      strengths,
      whatIsPfp: { image: whatIsPfpImage, ...whatIsPfpRest },
    },
  },
}: AboutUsStaticPage): AboutUsStaticPageFormModel => ({
  additional: {
    ...additionalRest,
    image: additionalImage?.data.id,
  },
  benefits: {
    ...benefitsRest,
    list: benefitsList.map(({ icon, ...rest }) => ({
      ...rest,
      currentIcon: icon?.data.attributes.url,
      icon: icon?.data.id,
    })),
  },
  knowledge: {
    ...knowledgeRest,
    image: knowledgeImage?.data.id,
  },
  possibilities,
  strengths,
  whatIsPfp: {
    ...whatIsPfpRest,
    image: whatIsPfpImage?.data.id,
  },
});

export const mapToAboutUsStaticPageDTO = ({
  additional,
  benefits,
  knowledge,
  possibilities,
  strengths,
  whatIsPfp,
}: AboutUsStaticPageFormModel): AboutUsStaticPageDTO => ({
  data: {
    additional: {
      ...omit(['image', 'imageFile'], additional),
      image:
        additional.image && !additional.imageFile
          ? { id: additional.image }
          : undefined,
    },
    benefits: {
      ...omit(['list'], benefits),
      list: benefits.list.map((benefitsListItem) => ({
        ...omit(['currentIcon', 'icon', 'iconFile'], benefitsListItem),
        image:
          benefitsListItem.icon && !benefitsListItem.iconFile
            ? { id: benefitsListItem.icon }
            : undefined,
      })),
    },
    knowledge: {
      ...omit(['image', 'imageFile'], knowledge),
      image:
        knowledge.image && !knowledge.imageFile
          ? { id: knowledge.image }
          : undefined,
    },
    possibilities,
    strengths,
    whatIsPfp: {
      ...omit(['image', 'imageFile'], whatIsPfp),
      image:
        whatIsPfp.image && !whatIsPfp.imageFile
          ? { id: whatIsPfp.image }
          : undefined,
    },
  },
  additionalImage: additional.imageFile,
  benefitsImages: benefits.list.map(
    (benefitsListItem) => benefitsListItem.iconFile
  ),
  knowledgeImage: knowledge.imageFile,
  whatIsPfpImage: whatIsPfp.imageFile,
});
