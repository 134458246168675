import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';
import { MaterialsState } from 'state/materials/materialsReducer';

export const materialsStateSelector = (state: RootState) => state.materials;

export const materialsIdsSelector = createSelector(
  materialsStateSelector,
  (state: MaterialsState) => state.ids
);

export const materialsMapSelector = createSelector(
  materialsStateSelector,
  (state: MaterialsState) => state.map
);

export const materialsParentIdSelector = createSelector(
  materialsStateSelector,
  (state: MaterialsState) => state.parentId
);

export const materialsUploadedMaterialsIdsSelector = createSelector(
  materialsStateSelector,
  (state: MaterialsState) => state.uploadedMaterialsIds
);

export const materialsFileFormModalSelector = createSelector(
  materialsStateSelector,
  (state: MaterialsState) => state.fileFormModal
);

export const materialsFileFormModalOpenSelector = createSelector(
  materialsFileFormModalSelector,
  (fileFormModal) => fileFormModal.open
);

export const materialsFileFormModalItemSelector = createSelector(
  materialsFileFormModalSelector,
  (fileFormModal) => fileFormModal.material
);

export const materialsUrlFormModalSelector = createSelector(
  materialsStateSelector,
  (state: MaterialsState) => state.urlFormModal
);

export const materialsUrlFormModalOpenSelector = createSelector(
  materialsUrlFormModalSelector,
  (urlFormModal) => urlFormModal.open
);

export const materialsUrlFormModalItemSelector = createSelector(
  materialsUrlFormModalSelector,
  (urlFormModal) => urlFormModal.material
);

export const materialsRowsSelector = createSelector(
  materialsIdsSelector,
  materialsUploadedMaterialsIdsSelector,
  materialsMapSelector,
  (ids, uploadedMaterialsIds, map) =>
    ids.concat(uploadedMaterialsIds).map((id) => ({
      id,
      name: map[id].name,
      status: map[id].status,
      type: map[id].type,
    }))
);
