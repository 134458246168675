import { addSeconds } from 'date-fns';
import { UserRole } from 'common/models/user';

export interface AuthDTO {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  role: UserRole;
  scope: string;
  token_type: string;
}

export interface Auth extends AuthDTO {
  expires_at?: string;
}

export interface LoginDTO {
  username: string;
  password: string;
}

export interface LoginFormModel {
  login: string;
  password: string;
}

export const mapToAuth = (a: AuthDTO): Auth => ({
  ...a,
  expires_at: addSeconds(new Date(), a.expires_in).toISOString(),
});

export const mapToLoginDTO = ({
  login,
  password,
}: LoginFormModel): LoginDTO => ({
  username: login,
  password,
});
