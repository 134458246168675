import * as Dom from 'editorjs/nested-list/utils/dom';

// eslint-disable-next-line import/no-default-export
export default class Caret {
  savedFakeCaret?: HTMLElement;

  constructor() {
    this.savedFakeCaret = undefined;
  }

  save() {
    const range = Caret.range;
    const cursor = Dom.make('span');

    cursor.hidden = true;

    range?.insertNode(cursor);

    this.savedFakeCaret = cursor;
  }

  restore() {
    if (!this.savedFakeCaret) {
      return;
    }

    const sel = window.getSelection();
    const range = new Range();

    range.setStartAfter(this.savedFakeCaret);
    range.setEndAfter(this.savedFakeCaret);

    sel?.removeAllRanges();
    sel?.addRange(range);

    setTimeout(() => {
      this.savedFakeCaret?.remove();
    }, 150);
  }

  static get range() {
    const selection = window.getSelection();

    return selection && selection.rangeCount ? selection.getRangeAt(0) : null;
  }

  static extractFragmentFromCaretPositionTillTheEnd() {
    const selection = window.getSelection();

    if (!selection?.rangeCount) {
      return;
    }

    const selectRange = selection.getRangeAt(0);
    let startNode = selectRange.startContainer;

    if (startNode.nodeType !== Node.ELEMENT_NODE) {
      if (!startNode.parentNode) {
        return;
      }

      startNode = startNode.parentNode;
    }

    const currentBlockInput = (startNode as Element).closest(
      '[contenteditable]'
    );

    selectRange.deleteContents();

    const range = selectRange.cloneRange();

    if (!currentBlockInput) {
      return;
    }

    range.selectNodeContents(currentBlockInput);
    range.setStart(selectRange.endContainer, selectRange.endOffset);

    return range.extractContents();
  }

  static focus(element: Element, atStart = true) {
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(element);
    range.collapse(atStart);

    selection?.removeAllRanges();
    selection?.addRange(range);
  }

  static isAtStart() {
    const selection = window.getSelection();

    if (!selection || selection.focusOffset > 0) {
      return false;
    }

    const focusNode = selection.focusNode;

    if (!focusNode) {
      return false;
    }

    const leftSiblings = Caret.getHigherLevelSiblings(
      focusNode as HTMLElement,
      'left'
    );

    return leftSiblings.every((node) => {
      return Dom.isEmpty(node);
    });
  }

  static getHigherLevelSiblings(
    from: HTMLElement,
    direction: 'left' | 'right' = 'left'
  ) {
    let current = from;
    const siblings = [];

    while (
      current.parentNode &&
      (current.parentNode as HTMLElement).contentEditable !== 'true'
    ) {
      current = current.parentNode as HTMLElement;
    }

    const sibling = direction === 'left' ? 'previousSibling' : 'nextSibling';

    while (current[sibling]) {
      current = current[sibling] as HTMLElement;
      siblings.push(current);
    }

    return siblings;
  }
}
