import { FieldError } from 'react-hook-form';
import { isNil } from 'ramda';

interface BlocksError {
  blocks: FieldError;
}

export const isBlocksError = (e: unknown): e is BlocksError =>
  typeof e === 'object' && !isNil(e) && 'blocks' in e;
export const isFieldError = (e: unknown): e is FieldError =>
  typeof e === 'object' && !isNil(e) && 'message' in e;
