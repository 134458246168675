import { useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { MaterialList, MaterialResource } from 'common/models/material';
import { getMaterials } from 'common/services/material';

export const useMaterialsQuery = (
  id: string,
  materialResource: MaterialResource
) => {
  return useQuery<MaterialList>(
    [QueryKey.Material, id, materialResource],
    () => getMaterials(id, materialResource),
    {
      keepPreviousData: true,
    }
  );
};
