import { useMutation, useQueryClient } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { UserRoleRequest } from 'common/models/user';
import { setUserRole } from 'common/services/user';

export const useChangeUserRoleMutation = (role: UserRoleRequest) => {
  const queryClient = useQueryClient();

  return useMutation((userId: string) => setUserRole(userId, role), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.UserList]);
    },
  });
};
