import { AuthorizedAxios, throwErrorResponse } from 'common/services/axios';
import { flatten } from 'lib/axios';
import {
  Category,
  CategoryCreateDTO,
  CategoryDTO,
  CategoryUpdateDTO,
  isSpecialCategoryPredicate,
  mapToCategory,
} from 'common/models/category';
import { ResultResponse } from 'common/models/response';
import { PaginationParams } from 'common/models/pagination';
import { filter, map } from 'ramda';
import { AppError } from 'common/errors/AppError';
import { ErrorCode } from 'common/models/error';
import { ID } from 'lib/id';

const mapResultResponseToCategory = (
  response: ResultResponse<CategoryDTO>
): ResultResponse<Category> => ({
  result: map(mapToCategory, response.result),
  totalResult: response.totalResult,
});

const pickSpecialCategories = (
  response: ResultResponse<CategoryDTO>
): ResultResponse<CategoryDTO> => ({
  result: filter(isSpecialCategoryPredicate, response.result),
  totalResult: response.totalResult,
});

const prepareFormData = (params: CategoryCreateDTO | CategoryUpdateDTO) => {
  const formData = new FormData();

  formData.set(
    'category',
    new Blob([JSON.stringify(params.category)], {
      type: 'application/json',
    })
  );

  if (params.partnerLogo != null) {
    formData.set('logo', params.partnerLogo);
  }

  return formData;
};

export const createCategory = (params: CategoryCreateDTO) =>
  AuthorizedAxios.post<CategoryDTO>(
    `/api/courses/parameters/categories/category`,
    prepareFormData(params),
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const deleteCategory = (id: ID) =>
  AuthorizedAxios.delete<null>(
    `/api/courses/parameters/categories/category/${id}`
  ).catch(throwErrorResponse);

export const getCategoryById = (id: ID) =>
  AuthorizedAxios.get<CategoryDTO>(
    `/api/courses/parameters/categories/category/${id}`
  )
    .then(flatten)
    .then((dto) => {
      if (!dto.isSpecialCategory) {
        throw new AppError(ErrorCode.CategoryDoNotExists);
      }

      return dto;
    })
    .then(mapToCategory)
    .catch(throwErrorResponse);

export const getCategoryList = ({ page, pageSize }: PaginationParams) =>
  AuthorizedAxios.get<ResultResponse<CategoryDTO>>(
    '/api/courses/parameters/categories/category',
    {
      params: { page, pageSize },
    }
  )
    .then(flatten)
    .then(pickSpecialCategories)
    .then(mapResultResponseToCategory)
    .catch(throwErrorResponse);

export const getAllCategoryList = ({ page, pageSize }: PaginationParams) =>
  AuthorizedAxios.get<ResultResponse<Category>>(
    '/api/courses/parameters/categories/category',
    {
      params: { page, pageSize },
    }
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const updateCategoryById = (params: CategoryUpdateDTO) =>
  AuthorizedAxios.put<CategoryDTO>(
    `/api/courses/parameters/categories/category/${params.id}`,
    prepareFormData(params),
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  )
    .then(flatten)
    .catch(throwErrorResponse);
