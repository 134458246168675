import React, { FC } from 'react';
import styled from 'styled-components';
import {
  Button,
  TextAreaSkeleton,
  TextInput,
  TextInputSkeleton,
} from 'carbon-components-react';
import { FormItem } from 'app/components/FormItem';
import {
  Controller,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form';
import { RichTextEditor } from 'app/components/CourseBundleForm/SectionItem/RichTextEditor';
import { CourseBundleFormModel } from 'common/models/courseBundle';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

const SectionContainer = styled.fieldset`
  & > * + * {
    margin-top: 1rem;
  }
`;

const SectionControls = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

interface SectionProps {
  index: number;
  remove: UseFieldArrayRemove;
  isLoading: boolean;
}

export const SectionItem: FC<SectionProps> = ({ index, remove, isLoading }) => {
  const { control } = useFormContext();

  return (
    <FormItem>
      {isLoading ? (
        <SectionContainer>
          <TextInputSkeleton />
          <TextAreaSkeleton />
        </SectionContainer>
      ) : (
        <SectionContainer>
          <Controller
            control={control}
            name={`sections.${index}.heading`}
            render={({ field: { onChange, value }, formState }) => (
              <TextInput
                id="sectionHeading"
                invalid={
                  (formState.errors as FieldErrors<CourseBundleFormModel>)
                    .sections?.[index] != null
                }
                invalidText={
                  (formState.errors as FieldErrors<CourseBundleFormModel>)
                    .sections?.[index]?.heading?.message
                }
                labelText="Nagłówek"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name={`sections.${index}.content`}
            render={({ field: { onChange, value } }) => (
              <RichTextEditor value={value} onChange={onChange} />
            )}
          />
          <SectionControls>
            <Button
              kind="danger--ghost"
              size="small"
              onClick={() => remove(index)}
            >
              Usuń sekcję
            </Button>
          </SectionControls>
        </SectionContainer>
      )}
    </FormItem>
  );
};
