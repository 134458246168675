import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Column,
  ComboBox,
  Form,
  InlineLoading,
  MultiSelect,
  Row,
  TextArea,
  TextAreaSkeleton,
  TextInput,
  TextInputSkeleton,
  Toggle,
} from 'carbon-components-react';
import {
  FormAccordion,
  FormAccordionItem,
  FormItem,
} from 'app/components/FormItem';
import {
  Controller,
  FormProvider,
  useForm,
  useFormState,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ImageControl } from 'app/components/ImageControl';
import {
  ElearningFormModel,
  elearningStatuses,
  initialElearningFormValues,
} from 'common/models/elearning';
import { forEachObjIndexed, mergeAll } from 'ramda';
import { isNotNilEmpty } from 'lib/isNilEmpty';
import { CourseParametersControl } from 'app/components/CourseParametersControl';
import { NumberInput } from 'app/components/NumberInput';
import { ChangeEventImaginaryTarget } from 'lib/carbonExtraTypes';
import { courseLevels, ListItem } from 'common/models/course';
import { Speaker } from 'common/models/speaker';
import { SurveyListItem } from 'common/models/survey';
import { Category, filterSpecialCategories } from 'common/models/category';
import {
  newKeywordValidationSchema,
  newLearningPointsValidationSchema,
  newTargetGroupsValidationSchema,
} from 'common/services/validation';
import { useAllCategoriesListQuery } from 'common/hooks/category/useAllCategoriesListQuery';
import { useAllSpeakersListQuery } from 'common/hooks/speaker/useAllSpeakersListQuery';
import { useAllSurveysListQuery } from 'common/hooks/survey/useAllSurveysListQuery';
import { durationStep } from 'config/elearning';
import { useScrollToError } from 'common/hooks/useScrollToError';
import {
  elearningFormValidationSchema,
  fieldsOrder,
} from 'app/components/ElearningForm/config';
import { setYupDefaultMessages } from 'common/services/yup';
import slug from 'slug';

export interface ElearningFormProps {
  formValues?: ElearningFormModel;
  isCreate: boolean;
  isElearningLoading?: boolean;
  isSubmitting?: boolean;
  onSubmit: (value: ElearningFormModel) => void;
}

setYupDefaultMessages();

export const ElearningForm: FC<ElearningFormProps> = ({
  formValues,
  isCreate,
  isSubmitting,
  isElearningLoading,
  onSubmit,
}) => {
  const [loaded, setLoaded] = useState(isCreate);

  const methods = useForm<ElearningFormModel>({
    resolver: yupResolver(elearningFormValidationSchema),
    shouldFocusError: false,
  });

  const onError = useScrollToError(fieldsOrder);

  const { data: categoriesData, isFetching: isLoadingCategories } =
    useAllCategoriesListQuery();

  const { data: speakersData, isFetching: isLoadingSpeakers } =
    useAllSpeakersListQuery();

  const { data: surveysData, isFetching: isLoadingSurveys } =
    useAllSurveysListQuery();

  useEffect(() => {
    let formModel = initialElearningFormValues;

    if (formValues) {
      formModel = mergeAll([initialElearningFormValues, formValues]);
    }

    forEachObjIndexed((value, key) => {
      methods.setValue(key, value);
    }, formModel);

    formValues && !isElearningLoading && setLoaded(true);
  }, [formValues, isElearningLoading]);

  const additionalCategories = useMemo(
    () => categoriesData && filterSpecialCategories(categoriesData.result),
    [categoriesData]
  );

  const isLoading =
    isLoadingCategories ||
    isLoadingSpeakers ||
    isLoadingSurveys ||
    isElearningLoading ||
    !loaded;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit, onError)} noValidate>
        <Row>
          <Column lg={8}>
            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <>
                  <Controller
                    control={methods.control}
                    name="title"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      const { dirtyFields } = useFormState({
                        control: methods.control,
                        name: 'slug',
                      });

                      return (
                        <TextInput
                          id="title"
                          invalid={error != null}
                          invalidText={error?.message}
                          onChange={(event) => {
                            onChange(event);

                            if (!formValues?.slug && !dirtyFields.slug) {
                              methods.setValue(
                                'slug',
                                slug(event.target.value),
                                {
                                  shouldValidate: true,
                                }
                              );
                            }
                          }}
                          labelText="Nazwa"
                          value={value}
                        />
                      );
                    }}
                  />
                </>
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <TextInput
                  id="slug"
                  invalid={methods.formState.errors.slug != null}
                  invalidText={methods.formState.errors.slug?.message}
                  labelText="Slug"
                  {...methods.register('slug')}
                />
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextAreaSkeleton />
              ) : (
                <TextArea
                  id="comment"
                  invalid={!!methods.formState.errors.comment}
                  invalidText={methods.formState.errors.comment?.message}
                  labelText="Słowo od prowadzącego"
                  {...methods.register('comment')}
                />
              )}
            </FormItem>

            <FormAccordion>
              <FormAccordionItem title="Czego się dowiesz" open={true}>
                <FormItem>
                  {isLoading ? (
                    <TextAreaSkeleton />
                  ) : (
                    <Controller
                      control={methods.control}
                      name="learningPoints"
                      render={({
                        field: { onChange },
                        fieldState: { error },
                      }) => (
                        <CourseParametersControl
                          id="learningPoints"
                          inputValidationSchema={
                            newLearningPointsValidationSchema
                          }
                          invalid={!!error}
                          invalidText={error?.message}
                          onChange={(rows) => {
                            onChange(rows);
                          }}
                          value={formValues?.learningPoints}
                        />
                      )}
                    />
                  )}
                </FormItem>
              </FormAccordionItem>

              <FormAccordionItem title="Grupy docelowe" open={true}>
                <FormItem>
                  {isLoading ? (
                    <TextAreaSkeleton />
                  ) : (
                    <Controller
                      control={methods.control}
                      name="targetGroups"
                      render={({
                        field: { onChange },
                        fieldState: { error },
                      }) => (
                        <CourseParametersControl
                          id="targetGroups"
                          inputValidationSchema={
                            newTargetGroupsValidationSchema
                          }
                          invalid={!!error}
                          invalidText={error?.message}
                          onChange={(rows) => {
                            onChange(rows);
                          }}
                          value={formValues?.targetGroups}
                        />
                      )}
                    />
                  )}
                </FormItem>
              </FormAccordionItem>

              <FormAccordionItem title="Certyfikat - punkty" open={true}>
                <FormItem>
                  {isLoading ? (
                    <TextAreaSkeleton />
                  ) : (
                    <Controller
                      control={methods.control}
                      name="certificatePoints"
                      render={({
                        field: { onChange },
                        fieldState: { error },
                      }) => (
                        <CourseParametersControl
                          id="certificatePoints"
                          inputValidationSchema={
                            newLearningPointsValidationSchema
                          }
                          invalid={!!error}
                          invalidText={error?.message}
                          onChange={(rows) => {
                            onChange(rows);
                          }}
                          value={formValues?.certificatePoints}
                        />
                      )}
                    />
                  )}
                </FormItem>
              </FormAccordionItem>

              <FormAccordionItem title="SEO" open={true}>
                <FormItem>
                  {isLoading ? (
                    <TextAreaSkeleton />
                  ) : (
                    <TextArea
                      id="description"
                      invalid={!!methods.formState.errors.description}
                      invalidText={
                        methods.formState.errors.description?.message
                      }
                      labelText="Opis"
                      {...methods.register('description')}
                    />
                  )}
                </FormItem>

                <FormItem>
                  {isLoading ? (
                    <TextAreaSkeleton />
                  ) : (
                    <Controller
                      control={methods.control}
                      name="keywords"
                      render={({
                        field: { onChange },
                        fieldState: { error },
                      }) => (
                        <CourseParametersControl
                          id="keywords"
                          invalid={!!error}
                          invalidText={error?.message}
                          inputValidationSchema={newKeywordValidationSchema}
                          label="Słowa kluczowe"
                          onChange={(rows) => {
                            onChange(rows);
                          }}
                          value={formValues?.keywords}
                        />
                      )}
                    />
                  )}
                </FormItem>
              </FormAccordionItem>
            </FormAccordion>
          </Column>
          <Column lg={4}>
            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="visible"
                  render={({ field: { name, value, onChange } }) => (
                    <Toggle
                      id={name}
                      labelText="Wyświetlanie szkolenia"
                      labelA="Nie wyświetlaj szkolenia"
                      labelB="Wyświetlaj szkolenie"
                      toggled={value}
                      onToggle={onChange}
                      defaultToggled
                    />
                  )}
                />
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="duration"
                  render={({
                    field: { name, value, onChange },
                    fieldState: { error },
                  }) => (
                    <NumberInput
                      allowEmpty
                      helperText="w minutach"
                      id={name}
                      invalid={!!error}
                      invalidText={error?.message}
                      label="Czas trwania"
                      min={durationStep}
                      name={name}
                      onChange={(
                        ev:
                          | React.ChangeEvent<HTMLInputElement>
                          | React.MouseEvent<HTMLButtonElement>
                      ) =>
                        onChange(
                          (ev as ChangeEventImaginaryTarget<HTMLInputElement>)
                            .imaginaryTarget.value
                        )
                      }
                      step={durationStep}
                      value={value ?? ''}
                    />
                  )}
                />
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="averageRating"
                  render={({
                    field: { name, value, onChange },
                    fieldState: { error },
                  }) => (
                    <NumberInput
                      id={name}
                      name={name}
                      label="Średnia ocena e-learningu"
                      onChange={(
                        ev:
                          | React.ChangeEvent<HTMLInputElement>
                          | React.MouseEvent<HTMLButtonElement>
                      ) =>
                        onChange(
                          (ev as ChangeEventImaginaryTarget<HTMLInputElement>)
                            .imaginaryTarget.value
                        )
                      }
                      value={value ?? ''}
                      allowEmpty
                      invalid={!!error}
                      invalidText={error?.message}
                    />
                  )}
                />
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="elearningStatus"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <ComboBox<ListItem>
                      id="elearningStatus"
                      invalid={!!error}
                      invalidText={error?.message}
                      items={elearningStatuses}
                      itemToString={(item) => item?.label || ''}
                      onChange={(value) => {
                        onChange(value.selectedItem?.id);
                      }}
                      placeholder=""
                      selectedItem={
                        elearningStatuses.find((item) => item.id === value) ??
                        null
                      }
                      titleText="Status"
                    />
                  )}
                />
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="recommendationPercent"
                  render={({
                    field: { name, value, onChange },
                    fieldState: { error },
                  }) => (
                    <NumberInput
                      id={name}
                      name={name}
                      label="Procent użytkowników polecających"
                      onChange={(
                        ev:
                          | React.ChangeEvent<HTMLInputElement>
                          | React.MouseEvent<HTMLButtonElement>
                      ) =>
                        onChange(
                          (ev as ChangeEventImaginaryTarget<HTMLInputElement>)
                            .imaginaryTarget.value
                        )
                      }
                      value={value ?? ''}
                      allowEmpty
                      invalid={!!error}
                      invalidText={error?.message}
                    />
                  )}
                />
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="level"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <ComboBox<ListItem>
                      id="level"
                      invalid={!!error}
                      invalidText={error?.message}
                      items={courseLevels}
                      itemToString={(item) => item?.label || ''}
                      onChange={(value) => {
                        onChange(value.selectedItem?.id);
                      }}
                      placeholder=""
                      selectedItem={
                        courseLevels.find((item) => item.id === value) ?? null
                      }
                      titleText="Poziom trudności"
                    />
                  )}
                />
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="speakers"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <MultiSelect<Speaker>
                      id="speakers"
                      initialSelectedItems={
                        speakersData?.result.filter((item) =>
                          value?.includes(item.id)
                        ) ?? []
                      }
                      invalid={!!error}
                      invalidText={error?.message}
                      items={speakersData?.result ?? []}
                      itemToString={(item) =>
                        item ? `${item.firstName} ${item.lastName}` : ''
                      }
                      label=""
                      onChange={(a) => {
                        onChange(
                          a.selectedItems
                            .filter(isNotNilEmpty)
                            .map((item) => item.id)
                        );
                      }}
                      titleText="Prelegenci"
                    />
                  )}
                />
              )}
            </FormItem>

            <input
              id="surveyId"
              type="hidden"
              {...methods.register('surveyId')}
            />

            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="survey"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <ComboBox<SurveyListItem>
                      id="survey"
                      invalid={!!error}
                      invalidText={error?.message}
                      items={surveysData?.result ?? []}
                      itemToString={(item) =>
                        item ? `${item.title} (${item.courseTitle})` : ''
                      }
                      onChange={(value) => {
                        onChange(value.selectedItem?.id);
                      }}
                      placeholder=""
                      selectedItem={
                        surveysData?.result.find((item) => item.id === value) ??
                        null
                      }
                      titleText="Ankieta"
                    />
                  )}
                />
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="mainCategory"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <ComboBox<Category>
                      id="mainCategory"
                      invalid={!!error}
                      invalidText={error?.message}
                      items={categoriesData?.result ?? []}
                      itemToString={(item) => item?.label || ''}
                      onChange={(value) => {
                        onChange(value.selectedItem?.id);
                      }}
                      placeholder=""
                      selectedItem={
                        categoriesData?.result.find(
                          (item) => item.id === value
                        ) ?? null
                      }
                      titleText="Główna kategoria"
                    />
                  )}
                />
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="additionalCategories"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <MultiSelect<Category>
                      id="additionalCategories"
                      initialSelectedItems={
                        additionalCategories?.filter((item) =>
                          value?.includes(item.id)
                        ) ?? []
                      }
                      invalid={!!error}
                      invalidText={error?.message}
                      items={additionalCategories ?? []}
                      itemToString={(item) => item?.label || ''}
                      label=""
                      onChange={(a) => {
                        onChange(
                          a.selectedItems
                            .filter(isNotNilEmpty)
                            .map((item) => item.id)
                        );
                      }}
                      titleText="Dodatkowe kategorie"
                    />
                  )}
                />
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextAreaSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="imageFile"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <ImageControl
                      currentImage={formValues?.currentImage}
                      id="imageFile"
                      invalid={!!error}
                      invalidText={error?.message}
                      label="Zdjęcie"
                      onChange={(file) => {
                        onChange(file);
                      }}
                    />
                  )}
                />
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextAreaSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="coverPhotoFile"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <ImageControl
                      currentImage={formValues?.currentCoverPhoto}
                      id="coverPhotoFile"
                      invalid={!!error}
                      invalidText={error?.message}
                      label="Cover photo"
                      onChange={(file) => {
                        onChange(file);
                      }}
                    />
                  )}
                />
              )}
            </FormItem>
          </Column>
        </Row>

        {isSubmitting ? (
          <Button disabled kind="primary" tabIndex={-1}>
            <InlineLoading description={'Zapisywanie...'} status="active" />
          </Button>
        ) : (
          <Button kind="primary" tabIndex={0} type="submit">
            {formValues == null ? 'Utwórz' : 'Zapisz zmiany'}
          </Button>
        )}
      </Form>
    </FormProvider>
  );
};
