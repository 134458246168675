import styled from 'styled-components';
import { HeaderGlobalAction } from 'carbon-components-react';

export const HeaderGlobalActionWithText = styled(HeaderGlobalAction)`
  padding: 0 0.9375rem;
  width: auto;

  svg {
    margin-left: 0.625rem;
  }
`;
