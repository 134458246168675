import { User } from 'common/models/user';
import { SortDirection } from 'common/models/pagination';
import { TableColumnSettings } from 'common/models/tableSettings';

export enum CourseEventParticipantListColumn {
  EnrollDateTime = 'ENROLL_DATE_TIME',
  FirstName = 'FIRST_NAME',
  HasDownloadedCertificate = 'HAS_DOWNLOADED_CERTIFICATE',
  LastName = 'LAST_NAME',
  Status = 'STATUS',
}

export const courseEventParticipantListColumnSettings: Record<
  CourseEventParticipantListColumn,
  TableColumnSettings
> = {
  [CourseEventParticipantListColumn.EnrollDateTime]: { sortable: true },
  [CourseEventParticipantListColumn.FirstName]: { sortable: true },
  [CourseEventParticipantListColumn.HasDownloadedCertificate]: {
    sortable: false,
  },
  [CourseEventParticipantListColumn.LastName]: { sortable: true },
  [CourseEventParticipantListColumn.Status]: { sortable: false },
};

export interface CourseEventParticipantSortData {
  direction: SortDirection;
  orderBy: CourseEventParticipantListColumn;
}

export interface CourseEventParticipantListParams {
  courseId: string;
  eventId: string;
}

export enum CourseEventParticipantEventStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Disenrolled = 'DISENROLLED',
  Present = 'PRESENT',
  Absent = 'ABSENT',
  Trainer = 'TRAINER',
}

export interface CourseEventParticipantListItem {
  enrollDateTime: string;
  eventId: number;
  hasDownloadedCertificate: boolean;
  id: number;
  participantEventStatus: CourseEventParticipantEventStatus;
  user: User;
}
