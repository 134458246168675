import { FC, useCallback, useState } from 'react';
import { TrashCan20 } from '@carbon/icons-react';
import { DataTableButton } from 'app/components/DataTableButton';
import { ConfirmationModal } from 'common/components/ConfirmationModal';

export interface DataTableDeleteButtonProps {
  confirmationTitle: string;
  isLoading: boolean;
  onSubmit: () => void;
}

export const DataTableDeleteButton: FC<DataTableDeleteButtonProps> = ({
  confirmationTitle,
  isLoading,
  onSubmit,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleSubmit = useCallback(() => {
    setShowDeleteModal(false);
    onSubmit();
  }, [onSubmit, setShowDeleteModal]);

  return (
    <>
      <DataTableButton
        hasIconOnly
        iconDescription="Usuń"
        isLoading={isLoading}
        kind="danger--ghost"
        onClick={() => setShowDeleteModal(true)}
        renderIcon={TrashCan20}
        size="small"
        tooltipAlignment="center"
        tooltipPosition="top"
      />

      <ConfirmationModal
        buttonSubmitText="Usuń"
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={handleSubmit}
        text="Czy na pewno chcesz usunąć wybrany element?"
        title={confirmationTitle}
      />
    </>
  );
};
