import { SortDirection } from 'common/models/pagination';
import { StoredImageDTO } from 'common/models/image';
import { Partner } from 'common/models/partner';
import { SpeakerDTO } from 'common/models/speaker';
import { TableColumnSettings } from 'common/models/tableSettings';
import { ID } from 'lib/id';
import { ListItem } from 'common/models/course';
import { statusLabelMap } from 'common/models/status';

export enum CourseBundleListColumn {
  Title = 'BUNDLE_TITLE',
  CreateDate = 'BUNDLE_CREATE_DATE',
  ModificationDate = 'BUNDLE_MODIFICATION_DATE',
  Status = 'STATUS',
}

export enum CourseBundleStatus {
  Draft = 'DRAFT',
  Public = 'PUBLIC',
}

export const courseBundleStatuses: ListItem[] = [
  {
    id: CourseBundleStatus.Draft,
    label: statusLabelMap[CourseBundleStatus.Draft],
  },
  {
    id: CourseBundleStatus.Public,
    label: statusLabelMap[CourseBundleStatus.Public],
  },
];

export interface CourseBundleSortData {
  direction: SortDirection;
  orderBy: CourseBundleListColumn;
}

export interface SectionDTO {
  readonly id?: string;

  heading: string;
  content: string;
}

export interface CourseBundleBasicInfoDTO {
  createdDate: string;
  id: number;
  modifiedDate: string;
  slug: string;
  status: CourseBundleStatus;
  title: string;
}

// todo: refactor
export interface CourseBundleSpeaker extends SpeakerDTO {
  id: number;
}

export interface CourseBundleDTO {
  createdDate: string;
  id: number;
  image: StoredImageDTO;
  modifiedDate: string;
  partners: Partner[];
  sections: SectionDTO[];
  seoDescription: string;
  slug: string;
  speakers: CourseBundleSpeaker[];
  status: CourseBundleStatus;
  title: string;
  eventIds: number[];
  elearningIds: number[];
}

export interface CreateBundleDTO {
  elearningIds: number[];
  eventIds: number[];
  partnerIds: ID[];
  sections: SectionDTO[];
  seoDescription: string;
  slug: string;
  speakerIds: number[];
  status: CourseBundleStatus;
  title: string;
}

export interface BaseCourseDTO {
  id: number;
  title: string;
}

export interface BaseEventDTO {
  id: number;
  startDateTime: string;
}

export interface BaseCourseWithEventsDTO {
  events: BaseEventDTO[];
  id: number;
  title: string;
}

export const courseBundleListColumnSettings: Record<
  CourseBundleListColumn,
  TableColumnSettings
> = {
  [CourseBundleListColumn.Title]: { sortable: true },
  [CourseBundleListColumn.CreateDate]: { sortable: true },
  [CourseBundleListColumn.ModificationDate]: { sortable: true },
  [CourseBundleListColumn.Status]: { sortable: true },
};

export interface CourseBundleFormModel {
  title: string;
  slug: string;
  seoDescription: string;
  sections: SectionDTO[];
  elearningIds: number[];
  eventIds: number[];
  partnerIds: ID[];
  speakerIds: number[];
  status: CourseBundleStatus;
  imageFile?: File;
  currentImage: string;
}

export const initialCourseBundleFormValues: Partial<CourseBundleFormModel> = {
  title: '',
  slug: '',
  seoDescription: '',
  sections: [
    {
      content: '',
      heading: '',
      id: '',
    },
  ],
  partnerIds: [],
  speakerIds: [],
  elearningIds: [],
  eventIds: [],
  status: CourseBundleStatus.Draft,
};

export interface CourseBundleUpdateDTO {
  createBundleDTO: CreateBundleDTO;
  image?: File;
}

export const mapToCourseBundleCreateDTO = (
  params: CourseBundleFormModel
): Required<CourseBundleUpdateDTO> => ({
  createBundleDTO: { ...params },
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  image: params.imageFile!,
});

export const mapToCourseBundleUpdateDTO = (
  params: CourseBundleFormModel
): CourseBundleUpdateDTO => ({
  createBundleDTO: { ...params },
  image: params.imageFile,
});

export const mapToCourseBundleFormModel = ({
  title,
  slug,
  seoDescription,
  sections,
  partners,
  eventIds,
  elearningIds,
  speakers,
  status,
  image,
}: CourseBundleDTO): CourseBundleFormModel => ({
  title,
  slug,
  seoDescription,
  sections,
  elearningIds,
  eventIds,
  status,
  partnerIds: partners.map((partner) => partner.id),
  speakerIds: speakers.map((speaker) => speaker.id),
  currentImage: image.url,
});
