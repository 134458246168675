import { FC, useCallback } from 'react';
import { CarbonFile, FileUploaderItem } from 'carbon-components-react';
import styled from 'styled-components';
import { isNil } from 'ramda';
import { FileUploaderDropContainer } from 'app/components/FileUploaderDropContainer';
import { ErrorMessage } from 'app/components/ErrorMessage';
import { UploadedFile } from 'common/models/uploadedFile';

const FileContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const HelperText = styled.div`
  margin-bottom: 1rem;
`;

const validateFile = (fileToUpload: CarbonFile): UploadedFile => {
  const file: UploadedFile = {
    file: fileToUpload,
    size: fileToUpload.size,
    invalidFileType: fileToUpload.invalidFileType,
    mime: fileToUpload.type,
    name: fileToUpload.name,
    status: 'new',
  };

  if (fileToUpload.size > 10485760) {
    file.errorBody = 'Plik powinien mieć maksymalnie 10mb';
    file.errorSubject = 'Rozmiar pliku jest zbyt duży';
    file.invalid = true;

    return file;
  }

  return file;
};

export interface FileInputProps {
  id: string;
  invalid: boolean;
  invalidText?: string;
  onChange?: (uploadedFile: UploadedFile | null) => void;
  onClear?: () => void;
  uploadedFile: UploadedFile | null;
}

export const FileInput: FC<FileInputProps> = ({
  id,
  invalid,
  invalidText,
  onChange,
  onClear,
  uploadedFile,
}) => {
  const handleAddFiles = useCallback(
    (event, { addedFiles }: { addedFiles: CarbonFile[] }) => {
      event.stopPropagation();

      const file = validateFile(addedFiles[0]);

      onChange && onChange(file);
    },
    []
  );

  const handleDeleteUploadedFile = useCallback(() => {
    onClear && onClear();
  }, []);

  const isSavedFile = uploadedFile?.status === 'saved';

  return (
    <div id={id}>
      <HelperText className="bx--form__helper-text">
        Maksymalny rozmiar pliku 1mb.
      </HelperText>

      <FileUploaderDropContainer
        labelText={isSavedFile ? 'Zmień plik' : 'Dodaj plik'}
        onAddFiles={handleAddFiles}
        $invalid={isNil(uploadedFile?.invalid) && invalid}
      />

      {isNil(uploadedFile?.invalid) && invalid && (
        <ErrorMessage className="bx--form-requirement">
          {invalidText}
        </ErrorMessage>
      )}

      {uploadedFile && (
        <FileContainer>
          <FileUploaderItem
            errorBody={uploadedFile.errorBody}
            errorSubject={uploadedFile.errorSubject}
            invalid={uploadedFile.invalid}
            name={uploadedFile.name}
            onDelete={handleDeleteUploadedFile}
            status={isSavedFile ? 'complete' : 'edit'}
          />
        </FileContainer>
      )}
    </div>
  );
};
