import {
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import { format } from 'lib/date';
import {
  CourseEventListColumn,
  EventStatusListItem,
} from 'common/models/courseEvent';
import { FC } from 'react';

export interface CourseBundleEventListDataTableRowProps {
  courseEventListItem?: EventStatusListItem;
  row: DenormalizedRow;
}

export const CourseBundleEventListTableRow: FC<
  CourseBundleEventListDataTableRowProps &
    Pick<DataTableCustomRenderProps, 'getRowProps' | 'getSelectionProps'>
> = ({
  courseEventListItem,
  row,

  getRowProps,
}) => {
  if (!courseEventListItem) {
    return null;
  }

  return (
    <TableRow {...getRowProps({ row })}>
      {row.cells.map((cell) => {
        switch (cell.info.header) {
          case CourseEventListColumn.StartDateTime:
            return (
              <TableCell key={cell.id}>
                {format(cell.value, 'dd.MM.yyyy HH:mm')}
              </TableCell>
            );
          default:
            return <TableCell key={cell.id}>{cell.value}</TableCell>;
        }
      })}
    </TableRow>
  );
};
