import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist';
import {
  authActions,
  fetchUserActions,
  loginActions,
} from 'state/auth/authActions';
import { snackbarActions } from 'state/notification/notificationActions';
import { AnyAction } from 'redux';
import { isNone, isSome } from 'fp-ts/lib/Option';
import { authStateSelector } from 'state/auth/authSelectors';
import { getLoggedInUser, login } from 'common/services/user';
import { push } from 'connected-react-router';
import { LoginRoute } from 'routes';
import { TranslatedError } from 'common/models/error';
import { captureException } from 'common/services/sentry';
import * as Sentry from '@sentry/react';
import { User } from 'common/models/user';
import { OAuthError } from 'lib/apiError';
import { Auth } from 'common/models/auth';
import { AuthState } from 'state/auth/authReducer';

function* processLogin(action: ReturnType<typeof loginActions.request>) {
  try {
    const response: Auth = yield call(login, action.payload);
    yield put(loginActions.requestSuccess(response));
  } catch (errors) {
    yield put(loginActions.requestFailure(errors));
    yield all(
      (errors as TranslatedError[]).map((translatedError) => {
        captureException(translatedError);
        return put(
          snackbarActions.enqueueSnackbar({
            options: { variant: 'error' },
            subtitle: (translatedError.error as OAuthError)?.error,
            title: translatedError.message,
          })
        );
      })
    );
  }
}

function* processFetchUser() {
  try {
    const response: User = yield call(getLoggedInUser);

    Sentry.setUser({ id: response.id });

    yield put(fetchUserActions.requestSuccess(response));
  } catch (errors) {
    yield put(fetchUserActions.requestFailure(errors));
    yield put(push(LoginRoute));
    yield all(
      (errors as TranslatedError[]).map((translatedError) => {
        captureException(translatedError);
        return put(
          snackbarActions.enqueueSnackbar({
            options: { variant: 'error' },
            subtitle: (translatedError.error as OAuthError)?.error,
            title: translatedError.message,
          })
        );
      })
    );
  }
}

function* processLogout() {
  Sentry.setUser(null);
}

function* fetchUserWatcher() {
  yield takeEvery(
    [fetchUserActions.request.type, loginActions.requestSuccess.type],
    processFetchUser
  );
}

function* loginWatcher() {
  yield takeEvery(loginActions.request.type, processLogin);
}

function* logoutWatcher() {
  yield takeEvery(authActions.logout.type, processLogout);
}

function* rehydrationWatcher() {
  yield take(
    (action: AnyAction) => action.type === REHYDRATE && action.key === 'auth'
  );

  const authState: AuthState = yield select(authStateSelector);
  if (isSome(authState.token) && isNone(authState.user)) {
    yield put(fetchUserActions.request());
  } else if (isNone(authState.token)) {
    yield put(authActions.logout());
  }
}

export function* authSagas() {
  yield all([
    fetchUserWatcher(),
    loginWatcher(),
    logoutWatcher(),
    rehydrationWatcher(),
  ]);
}
