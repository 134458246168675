import { Content } from 'common/components/Grid';
import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  Grid,
  Row,
  SkeletonText,
} from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import { CategoryListRoute, RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import React, { FC, useMemo } from 'react';
import { useCategoryByIdQuery } from 'common/hooks/category/useCategoryByIdQuery';
import {
  CategoryFormPageParams,
  mapToCategoryCreateDTO,
  mapToCategoryFormModel,
  mapToCategoryUpdateDTO,
} from 'common/models/category';
import { CategoryForm } from 'app/components/CategoryForm';
import { useCreateCategoryMutation } from 'common/hooks/category/useCreateCategoryMutation';
import { useUpdateCategoryMutation } from 'common/hooks/category/useUpdateCategoryMutation';

export const CategoryFormPage: FC<{ create?: boolean }> = ({ create }) => {
  const { categoryId } = useParams<CategoryFormPageParams>();

  const createMutation = useCreateCategoryMutation();
  const updateMutation = useUpdateCategoryMutation();

  const { data: categoryData, isLoading } = useCategoryByIdQuery(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    categoryId!,
    !create && categoryId.length > 0
  );

  const formValues = useMemo(
    () => categoryData && mapToCategoryFormModel(categoryData),
    [categoryData]
  );

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={CategoryListRoute}>Lista kategorii specjalnych</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        {create && <PageHeading>Nowa kategoria</PageHeading>}

        {!create && (
          <PageHeading>
            {isLoading ? (
              <SkeletonText heading={true} width="300px" />
            ) : (
              `${categoryData?.label}`
            )}
          </PageHeading>
        )}

        <Row>
          <Column lg={18} xlg={14}>
            {create && (
              <CategoryForm
                onSubmit={(values) =>
                  createMutation.mutate(mapToCategoryCreateDTO(values))
                }
                isCreate={true}
                isSubmitting={createMutation.isLoading}
              />
            )}
            {!create && (
              <CategoryForm
                onSubmit={(values) =>
                  updateMutation.mutate(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    mapToCategoryUpdateDTO(values, categoryId!)
                  )
                }
                formValues={categoryData ? formValues : undefined}
                isCreate={false}
                isCategoryLoading={isLoading}
                isSubmitting={updateMutation.isLoading}
              />
            )}
          </Column>
        </Row>
      </Grid>
    </Content>
  );
};
