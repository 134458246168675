import React, { memo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Form,
  FormLabel,
  Grid,
  InlineLoading,
  Row,
  TextAreaSkeleton,
  TextInput,
  TextInputSkeleton,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Content } from 'common/components/Grid';
import { setYupDefaultMessages } from 'common/services/yup';
import { useScrollToError } from 'common/hooks/useScrollToError';
import {
  StaticPageCode,
  staticPageCodeTitleMapper,
} from 'modules/static-pages/models/staticPageCode';
import {
  AboutUsStaticPageFormModel,
  mapToAboutUsStaticPageDTO,
  mapToAboutUsStaticPageFormModel,
} from 'modules/static-pages/models/aboutUsStaticPage';
import { FormItem } from 'app/components/FormItem';
import { ContentEditor } from 'app/components/ContentEditor';
import { ImageControl } from 'app/components/ImageControl';
import { useAboutUsStaticPageQuery } from 'modules/static-pages/hooks/useAboutUsStaticPageQuery';
import { prependCMSUrl } from 'config/environment';
import { AboutUsStrengthsListControl } from 'modules/static-pages/components/StaticPageForm/AboutUsStrengthsListControl';
import { AboutUsBulletListControl } from 'modules/static-pages/components/StaticPageForm/AboutUsBulletListControl';
import { AboutUsBenefitsListControl } from 'modules/static-pages/components/StaticPageForm/AboutUsBenefitsListControl';
import styled from 'styled-components';
import { useUpdateAboutUsStaticPageMutation } from 'modules/static-pages/hooks/useUpdateAboutUsStaticPageMutation';
import {
  aboutUsFieldsOrder,
  aboutUsFormSchema,
} from 'modules/static-pages/config/aboutUsValidationSchema';
import { editorjsTextOnlyTools } from 'config/editorjs';
import {
  aboutUsAcceptedFiles,
  aboutUsAllowedExtensions,
} from 'modules/static-pages/config/upload';
import { pipe } from 'fp-ts/function';
import { StaticPageListRoute } from 'modules/static-pages/routes';

const SectionHeading = styled.h3`
  margin-bottom: 1.25rem;
`;

setYupDefaultMessages();

export const StaticPageAboutUsForm = memo(() => {
  const updateMutation = useUpdateAboutUsStaticPageMutation();

  const methods = useForm<AboutUsStaticPageFormModel>({
    resolver: yupResolver(aboutUsFormSchema),
    shouldFocusError: false,
  });

  const onError = useScrollToError(aboutUsFieldsOrder);

  const { data: staticPageData, isFetching: isLoading } =
    useAboutUsStaticPageQuery((data) =>
      methods.reset(mapToAboutUsStaticPageFormModel(data))
    );

  const onSubmit = methods.handleSubmit(
    async (values) =>
      pipe(values, mapToAboutUsStaticPageDTO, updateMutation.mutate),
    onError
  );

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={StaticPageListRoute}>Strony statyczne</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageHeading>
          {staticPageCodeTitleMapper[StaticPageCode.AboutUs]}
        </PageHeading>

        <FormProvider {...methods}>
          <Form onSubmit={onSubmit} noValidate>
            <SectionHeading>Czym jest PFP</SectionHeading>

            <Row id="whatIsPfp">
              <Column lg={10}>
                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <TextInput
                      id="whatIsPfp.heading"
                      invalid={!!methods.formState.errors.whatIsPfp?.heading}
                      invalidText={
                        methods.formState.errors.whatIsPfp?.heading?.message
                      }
                      labelText="Nagłówek"
                      {...methods.register('whatIsPfp.heading')}
                    />
                  )}
                </FormItem>

                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <>
                      <FormLabel>Tekst</FormLabel>
                      <ContentEditor
                        name="whatIsPfp.text"
                        tools={editorjsTextOnlyTools}
                      />
                    </>
                  )}
                </FormItem>
              </Column>

              <Column lg={4}>
                <FormItem>
                  {isLoading ? (
                    <TextAreaSkeleton />
                  ) : (
                    <Controller
                      control={methods.control}
                      name="whatIsPfp.imageFile"
                      render={({
                        field: { onChange },
                        fieldState: { error },
                      }) => (
                        <ImageControl
                          accept={aboutUsAcceptedFiles}
                          allowedExtensions={aboutUsAllowedExtensions}
                          currentImage={
                            staticPageData?.data.attributes.whatIsPfp.image.data
                              .attributes.url
                              ? prependCMSUrl(
                                  staticPageData?.data.attributes.whatIsPfp
                                    .image.data.attributes.url
                                )
                              : undefined
                          }
                          id="whatIsPfp.imageFile"
                          invalid={!!error}
                          invalidText={error?.message}
                          onChange={(file) => {
                            onChange(file);
                          }}
                        />
                      )}
                    />
                  )}
                </FormItem>
              </Column>
            </Row>

            <SectionHeading>Co nas wyróżnia</SectionHeading>

            <Row id="strengths">
              <Column lg={10}>
                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <TextInput
                      id="strengths.subtitle"
                      invalid={!!methods.formState.errors.strengths?.subtitle}
                      invalidText={
                        methods.formState.errors.strengths?.subtitle?.message
                      }
                      labelText="Podtytuł"
                      {...methods.register('strengths.subtitle')}
                    />
                  )}
                </FormItem>

                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <TextInput
                      id="strengths.heading"
                      invalid={!!methods.formState.errors.strengths?.heading}
                      invalidText={
                        methods.formState.errors.strengths?.heading?.message
                      }
                      labelText="Nagłówek"
                      {...methods.register('strengths.heading')}
                    />
                  )}
                </FormItem>

                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <AboutUsStrengthsListControl
                      label="Lista"
                      name="strengths.list"
                    />
                  )}
                </FormItem>
              </Column>
            </Row>

            <SectionHeading>Źródła wiedzy jakie oferujemy</SectionHeading>

            <Row id="knowledge">
              <Column lg={10}>
                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <TextInput
                      id="knowledge.subtitle"
                      invalid={!!methods.formState.errors.knowledge?.subtitle}
                      invalidText={
                        methods.formState.errors.knowledge?.subtitle?.message
                      }
                      labelText="Podtytuł"
                      {...methods.register('knowledge.subtitle')}
                    />
                  )}
                </FormItem>

                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <TextInput
                      id="knowledge.heading"
                      invalid={!!methods.formState.errors.knowledge?.heading}
                      invalidText={
                        methods.formState.errors.knowledge?.heading?.message
                      }
                      labelText="Nagłówek"
                      {...methods.register('knowledge.heading')}
                    />
                  )}
                </FormItem>

                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <>
                      <FormLabel>Tekst</FormLabel>
                      <ContentEditor
                        name="knowledge.text"
                        tools={editorjsTextOnlyTools}
                      />
                    </>
                  )}
                </FormItem>

                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <AboutUsBulletListControl
                      label="Lista"
                      name="knowledge.list"
                    />
                  )}
                </FormItem>
              </Column>

              <Column lg={4}>
                <FormItem>
                  {isLoading ? (
                    <TextAreaSkeleton />
                  ) : (
                    <Controller
                      control={methods.control}
                      name="knowledge.imageFile"
                      render={({
                        field: { onChange },
                        fieldState: { error },
                      }) => (
                        <ImageControl
                          accept={aboutUsAcceptedFiles}
                          allowedExtensions={aboutUsAllowedExtensions}
                          currentImage={
                            staticPageData?.data.attributes.knowledge.image.data
                              .attributes.url
                              ? prependCMSUrl(
                                  staticPageData?.data.attributes.knowledge
                                    .image.data.attributes.url
                                )
                              : undefined
                          }
                          id="knowledge.imageFile"
                          invalid={!!error}
                          invalidText={error?.message}
                          onChange={(file) => {
                            onChange(file);
                          }}
                        />
                      )}
                    />
                  )}
                </FormItem>
              </Column>
            </Row>

            <SectionHeading>Co od nas otrzymasz</SectionHeading>

            <Row id="benefits">
              <Column lg={10}>
                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <TextInput
                      id="benefits.subtitle"
                      invalid={!!methods.formState.errors.benefits?.subtitle}
                      invalidText={
                        methods.formState.errors.benefits?.subtitle?.message
                      }
                      labelText="Podtytuł"
                      {...methods.register('benefits.subtitle')}
                    />
                  )}
                </FormItem>

                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <TextInput
                      id="benefits.heading"
                      invalid={!!methods.formState.errors.benefits?.heading}
                      invalidText={
                        methods.formState.errors.benefits?.heading?.message
                      }
                      labelText="Nagłówek"
                      {...methods.register('benefits.heading')}
                    />
                  )}
                </FormItem>

                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <AboutUsBenefitsListControl
                      label="Lista"
                      name="benefits.list"
                    />
                  )}
                </FormItem>
              </Column>
            </Row>

            <SectionHeading>
              Z jakich programów możesz skorzystać
            </SectionHeading>

            <Row id="possibilities">
              <Column lg={10}>
                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <TextInput
                      id="possibilities.subtitle"
                      invalid={
                        !!methods.formState.errors.possibilities?.subtitle
                      }
                      invalidText={
                        methods.formState.errors.possibilities?.subtitle
                          ?.message
                      }
                      labelText="Podtytuł"
                      {...methods.register('possibilities.subtitle')}
                    />
                  )}
                </FormItem>

                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <TextInput
                      id="possibilities.heading"
                      invalid={
                        !!methods.formState.errors.possibilities?.heading
                      }
                      invalidText={
                        methods.formState.errors.possibilities?.heading?.message
                      }
                      labelText="Nagłówek"
                      {...methods.register('possibilities.heading')}
                    />
                  )}
                </FormItem>

                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <>
                      <FormLabel>Tekst</FormLabel>
                      <ContentEditor
                        name="possibilities.text"
                        tools={editorjsTextOnlyTools}
                      />
                    </>
                  )}
                </FormItem>

                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <AboutUsBulletListControl
                      label="Lista"
                      name="possibilities.list"
                    />
                  )}
                </FormItem>
              </Column>
            </Row>

            <SectionHeading>PFP to nie tylko szkolenia</SectionHeading>

            <Row id="additional">
              <Column lg={10}>
                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <TextInput
                      id="additional.subtitle"
                      invalid={!!methods.formState.errors.additional?.subtitle}
                      invalidText={
                        methods.formState.errors.additional?.subtitle?.message
                      }
                      labelText="Podtytuł"
                      {...methods.register('additional.subtitle')}
                    />
                  )}
                </FormItem>

                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <TextInput
                      id="additional.heading"
                      invalid={!!methods.formState.errors.additional?.heading}
                      invalidText={
                        methods.formState.errors.additional?.heading?.message
                      }
                      labelText="Nagłówek"
                      {...methods.register('additional.heading')}
                    />
                  )}
                </FormItem>

                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <>
                      <FormLabel>Tekst</FormLabel>
                      <ContentEditor
                        name="additional.text"
                        tools={editorjsTextOnlyTools}
                      />
                    </>
                  )}
                </FormItem>
              </Column>

              <Column lg={4}>
                <FormItem>
                  {isLoading ? (
                    <TextAreaSkeleton />
                  ) : (
                    <Controller
                      control={methods.control}
                      name="additional.imageFile"
                      render={({
                        field: { onChange },
                        fieldState: { error },
                      }) => (
                        <ImageControl
                          accept={aboutUsAcceptedFiles}
                          allowedExtensions={aboutUsAllowedExtensions}
                          currentImage={
                            staticPageData?.data.attributes.additional.image
                              .data.attributes.url
                              ? prependCMSUrl(
                                  staticPageData?.data.attributes.additional
                                    .image.data.attributes.url
                                )
                              : undefined
                          }
                          id="additional.imageFile"
                          invalid={!!error}
                          invalidText={error?.message}
                          onChange={(file) => {
                            onChange(file);
                          }}
                        />
                      )}
                    />
                  )}
                </FormItem>
              </Column>
            </Row>

            {updateMutation.isLoading ? (
              <Button disabled kind="primary" tabIndex={-1}>
                <InlineLoading description="Zapisywanie..." status="active" />
              </Button>
            ) : (
              <Button kind="primary" tabIndex={0} type="submit">
                Zapisz
              </Button>
            )}
          </Form>
        </FormProvider>
      </Grid>
    </Content>
  );
});
