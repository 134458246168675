export function make(
  tagName: string,
  classNames: string | string[] | null = null,
  attributes: Record<string, unknown> = {}
) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    el[attrName] = attributes[attrName];
  }

  return el;
}

export function fragmentToString(fragment?: DocumentFragment): string {
  if (!fragment) {
    return '';
  }

  const div = make('div');

  div.appendChild(fragment);

  return div.innerHTML;
}

export function isEmpty(node?: Node | null): boolean {
  if (!node) {
    return true;
  }

  let content;

  if (node.nodeType !== Node.ELEMENT_NODE) {
    content = node.textContent;
  } else {
    content = (node as Element).innerHTML;

    /**
     * Don't count <br>s as content
     */
    content = content.replaceAll('<br>', '');
  }

  return content?.trim().length === 0;
}
