import React, { FC } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Form,
  Grid,
  InlineLoading,
  Row,
  TextAreaSkeleton,
  TextInput,
  TextInputSkeleton,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { pipe } from 'fp-ts/function';
import { mergeRight } from 'ramda';
import { Content } from 'common/components/Grid';
import { ImageControl } from 'app/components/ImageControl';
import { convertFileToBase64 } from 'common/services/file';
import { setYupDefaultMessages } from 'common/services/yup';
import { FormItem } from 'app/components/FormItem';
import { useUpdateCertificateSettingsMutation } from 'common/hooks/certificateSettings/useUpdateCertificateSettingsMutation';
import {
  CertificatesAssetsFormModel,
  mapToCertificatesAssetsDTO,
} from 'common/models/certificateSettings';
import { useCertificateSettingsQuery } from 'common/hooks/certificateSettings/useCertificateSettingsQuery';
import {
  fieldsOrder,
  formSchema,
} from 'app/pages/CertificateSettingsForm/config';
import { useScrollToError } from 'common/hooks/useScrollToError';

setYupDefaultMessages();

export const CertificateSettingsForm: FC = () => {
  const updateMutation = useUpdateCertificateSettingsMutation();

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<CertificatesAssetsFormModel>({
    resolver: yupResolver(formSchema),
    shouldFocusError: false,
  });

  const onError = useScrollToError(fieldsOrder);

  const { data, isLoading } = useCertificateSettingsQuery((data) =>
    reset({
      boardMemberName: data.boardMemberName.value,
      certificationNumber: data.certificationNumber.value,
    })
  );

  const onSubmit = handleSubmit(async (values) => {
    const boardMemberSignatureString =
      values.boardMemberSignature &&
      (await convertFileToBase64(values.boardMemberSignature));
    const isoLogoString =
      values.isoLogo && (await convertFileToBase64(values.isoLogo));

    pipe(
      { boardMemberSignatureString, isoLogoString },
      mergeRight(values),
      mapToCertificatesAssetsDTO,
      updateMutation.mutate
    );
  }, onError);

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageHeading>Dane certyfikatu</PageHeading>

        <Form onSubmit={onSubmit} noValidate>
          <Row>
            <Column lg={8}>
              <Row>
                <Column>
                  <FormItem>
                    {isLoading ? (
                      <TextInputSkeleton />
                    ) : (
                      <TextInput
                        id="boardMemberName"
                        invalid={!!errors.boardMemberName}
                        invalidText={errors.boardMemberName?.message}
                        labelText="Prezes zarządu"
                        {...register('boardMemberName')}
                      />
                    )}
                  </FormItem>
                </Column>

                <Column>
                  <FormItem>
                    {isLoading ? (
                      <TextInputSkeleton />
                    ) : (
                      <TextInput
                        id="certificationNumber"
                        invalid={!!errors.certificationNumber}
                        invalidText={errors.certificationNumber?.message}
                        labelText="Numer certyfikatu ISO"
                        {...register('certificationNumber')}
                      />
                    )}
                  </FormItem>
                </Column>
              </Row>

              {updateMutation.isLoading ? (
                <Button disabled kind="primary" tabIndex={-1}>
                  <InlineLoading description="Zapisywanie..." status="active" />
                </Button>
              ) : (
                <Button kind="primary" tabIndex={0} type="submit">
                  Zapisz
                </Button>
              )}
            </Column>

            <Column lg={4}>
              <FormItem>
                {isLoading ? (
                  <TextAreaSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="boardMemberSignature"
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <ImageControl
                        currentImage={
                          data?.boardMemberSignature &&
                          `data:image/png;base64,${data?.boardMemberSignature.value}`
                        }
                        id="boardMemberSignature"
                        invalid={!!error}
                        invalidText={error?.message}
                        label="Zmień podpis prezesa zarządu"
                        onChange={(file) => {
                          onChange(file);
                        }}
                      />
                    )}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextAreaSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="isoLogo"
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <ImageControl
                        currentImage={
                          data?.isoLogo &&
                          `data:image/png;base64,${data?.isoLogo.value}`
                        }
                        id="isoLogo"
                        invalid={!!error}
                        invalidText={error?.message}
                        label="Zmień logo ISO"
                        onChange={(file) => {
                          onChange(file);
                        }}
                      />
                    )}
                  />
                )}
              </FormItem>
            </Column>
          </Row>
        </Form>
      </Grid>
    </Content>
  );
};
