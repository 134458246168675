import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { snackbarActions } from 'state/notification/notificationActions';
import { Course, CourseRequestDTO } from 'common/models/course';
import { updateCourseById } from 'common/services/course';
import { push } from 'connected-react-router';
import { CourseListRoute } from 'routes';

export const useUpdateCourseMutation = (id: string) => {
  const dispatch = useDispatch();

  return useMutation<Course, unknown, CourseRequestDTO>(
    (params) => updateCourseById(id, params),
    {
      onSuccess: () => {
        dispatch(push(CourseListRoute));
        dispatch(
          snackbarActions.enqueueSnackbar({
            title: 'Kurs został zapisany pomyślnie',
            options: { variant: 'success' },
          })
        );
      },
    }
  );
};
