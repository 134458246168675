import * as yup from 'yup';

export const formSchema = yup.object({
  agenda: yup
    .array()
    .min(1)
    .required()
    .of(
      yup.object({
        id: yup.string().required(),
        item: yup.string().required(),
        startTime: yup.string().required(),
      })
    ),
  duration: yup.number().min(1).required(),
  location: yup.string().required(),
  startDate: yup
    .string()
    .matches(/\d{2}\.\d{2}\.\d{4}/)
    .required(),
  startTime: yup.string().required(),
  trainer: yup.string().required(),
});

export const remarksSchema = yup.object();

export const fieldsOrder = [
  'remarks',
  'duration',
  'location',
  'agenda',
  'trainer',
  'startDate',
  'startTime',
];
