import {
  createRootRoute,
  createRoute,
  setParam,
  setParams,
} from 'common/utils/routing';
import { CourseBundleContentCategory } from 'common/models/courseBundleContent';

export const RootRoute = '/';
export const createParam = 'create';
export const isCreateParam = (a: string) => a === createParam;

export const CertificateSettingsRoute = createRootRoute('certificate-settings');
export const SystemSettingsRoute = createRootRoute('system-settings');
export const LoginRoute = createRootRoute('login');

export const CategoryListRoute = createRootRoute('categories');
export const CategoryCreateRoute = createRoute(CategoryListRoute, 'create');
export const categoryIdRouteParam = ':categoryId';
export const CategoryFormRoute = createRoute(
  CategoryListRoute,
  categoryIdRouteParam
);
export const CategoryDeleteRoute = createRoute(CategoryFormRoute, 'delete');

export const CourseListRoute = createRootRoute('courses');
export const CourseFormRoute = createRoute(CourseListRoute, ':courseId');
export const CourseMaterialsRoute = createRoute(
  CourseListRoute,
  ':courseId',
  'materials'
);
export const getCourseFormRoute = setParam(':courseId')(CourseFormRoute);
export const getCourseMaterialsRoute =
  setParam(':courseId')(CourseMaterialsRoute);
export const CourseFormCreateRoute = getCourseFormRoute(createParam);

export const CourseEventListRoute = createRoute(CourseFormRoute, 'events');
export const getCourseEventListRoute =
  setParam(':courseId')(CourseEventListRoute);
export const CourseEventFormRoute = createRoute(
  CourseEventListRoute,
  ':eventId'
);
export const getCourseEventFormRoute = setParams(
  ':courseId',
  ':eventId'
)(CourseEventFormRoute);
export const CourseEventFormCreateRoute =
  setParam(':eventId')(CourseEventFormRoute)(createParam);
export const getCourseEventFormCreateRoute = setParam(':courseId')(
  CourseEventFormCreateRoute
);

export const CourseEventParticipantListRoute = createRoute(
  CourseEventFormRoute,
  'participants'
);
export const getCourseEventParticipantListRoute = setParams(
  ':courseId',
  ':eventId'
)(CourseEventParticipantListRoute);

export const SpeakerListRoute = createRootRoute('speakers');
export const SpeakerFormRoute = createRoute(SpeakerListRoute, ':id');
export const SpeakerFormCreateRoute =
  setParam(':id')(SpeakerFormRoute)(createParam);

export const SurveyListRoute = createRootRoute('survey');
export const SurveyFormRoute = createRoute(SurveyListRoute, ':surveyId');
export const SurveyRoute = setParam(':surveyId')(SurveyFormRoute);
export const SurveyFormCreateRoute =
  setParam(':surveyId')(SurveyFormRoute)(createParam);

export const UserListRoute = createRootRoute('users');

export const PartnerListRoute = createRootRoute('partners');
export const PartnerCreateRoute = createRoute(PartnerListRoute, 'create');
export const partnerIdRouteParam = ':partnerId';
export const PartnerFormRoute = createRoute(
  PartnerListRoute,
  partnerIdRouteParam
);
export const PartnerUsersListRoute = createRoute(PartnerFormRoute, 'users');

export const ElearningListRoute = createRootRoute('elearnings');
export const ElearningCreateRoute = createRoute(ElearningListRoute, 'create');
export const elearningIdRouteParam = ':elearningId';
export const ElearningFormRoute = createRoute(
  ElearningListRoute,
  elearningIdRouteParam
);
export const ElearningUploadPreviewRoute = createRoute(
  ElearningFormRoute,
  'upload-preview'
);

export const ElearningLessonListRoute = createRoute(
  ElearningFormRoute,
  'lessons'
);
export const ElearningLessonCreateRoute = createRoute(
  ElearningLessonListRoute,
  createParam
);
export const elearningLessonIdRouteParam = ':elearningLessonId';
export const ElearningLessonFormRoute = createRoute(
  ElearningLessonListRoute,
  elearningLessonIdRouteParam
);
export const ElearningLessonUploadVideoRoute = createRoute(
  ElearningLessonFormRoute,
  'upload-video'
);
export const ElearningLessonMaterialsRoute = createRoute(
  ElearningLessonFormRoute,
  'materials'
);

export const CourseBundleListRoute = createRootRoute('course-bundles');
export const CourseBundleCreateRoute = createRoute(
  CourseBundleListRoute,
  createParam
);
export const courseBundleIdRouteParam = ':courseBundleId';
export const CourseBundleIdRoute = createRoute(
  CourseBundleListRoute,
  courseBundleIdRouteParam
);

export const CourseBundleElearningListRoute = createRoute(
  CourseBundleIdRoute,
  CourseBundleContentCategory.ElearningList
);
export const CourseBundleEventListRoute = createRoute(
  CourseBundleIdRoute,
  CourseBundleContentCategory.EventList
);

export const CourseBundleMaterialsRoute = createRoute(
  CourseBundleIdRoute,
  'materials'
);
export const getCourseBundleMaterialsRoute = setParam(courseBundleIdRouteParam)(
  CourseBundleMaterialsRoute
);

export const BannerListRoute = createRootRoute('banners');
export const BannerCreateRoute = createRoute(BannerListRoute, 'create');
export const bannerIdRouteParam = ':bannerId';
export const BannerFormRoute = createRoute(BannerListRoute, bannerIdRouteParam);
export const BannerDeleteRoute = createRoute(BannerFormRoute, 'delete');
