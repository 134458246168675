import {
  Button,
  ComboBox,
  DataTableCustomRenderProps,
  DenormalizedRow,
  Loading,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react';
import { Add20 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { CourseFormCreateRoute } from 'routes';
import {
  Table,
  TableContainer,
  TableContent,
  TableLoader,
} from 'app/components/Table';
import { CourseListDataTableRow } from 'app/components/CourseList/CourseListDataTableRow';
import React, { FC, useMemo } from 'react';
import { isEmpty } from 'ramda';
import { DataTableEmptyRow } from 'app/components/DataTableEmptyRow';
import { Speaker } from 'common/models/speaker';
import { useDispatch, useSelector } from 'react-redux';
import {
  courseSortSelector,
  courseSpeakerSelector,
} from 'state/course/courseSelectors';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/Option';
import { ListFilters } from 'app/components/ListFilters';
import { courseActions } from 'state/course/courseActions';
import { DataTableHeader } from 'app/components/DataTableHeader';
import { CourseListColumn } from 'common/models/course';

export interface CourseListDataTableContentProps {
  isFetching?: boolean;
  onDeleteSuccess?: (row: DenormalizedRow) => void;
  speakers?: Speaker[];
}

export const CourseListDataTableContent: FC<
  CourseListDataTableContentProps & DataTableCustomRenderProps
> = ({
  isFetching = false,
  onDeleteSuccess,
  speakers = [],

  rows,
  headers,
  getBatchActionProps,
  getTableProps,
  getTableContainerProps,
  getToolbarProps,
  getRowProps,
}) => {
  const dispatch = useDispatch();

  const speakerOption = useSelector(courseSpeakerSelector);
  const sortData = useSelector(courseSortSelector);

  const speakerId = useMemo(
    () =>
      pipe(
        speakerOption,
        getOrElse(() => 'undefined')
      ),
    [speakerOption]
  );

  return (
    <TableContainer title="Szkolenia" {...getTableContainerProps()}>
      <TableToolbar {...getToolbarProps()}>
        <TableToolbarContent>
          <ListFilters>
            <ComboBox<Speaker>
              id="filterCategory"
              initialSelectedItem={speakers.find(
                (item) => String(item.id) === speakerId
              )}
              items={speakers}
              itemToString={(item) =>
                item ? `${item.firstName} ${item.lastName}` : ''
              }
              light={true}
              onChange={(value) => {
                dispatch(courseActions.setSpeaker(value.selectedItem));
              }}
              placeholder="Wybierz wykładowcę.."
            />
          </ListFilters>

          <Button
            as={Link}
            kind="primary"
            renderIcon={Add20}
            size="small"
            tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
            to={CourseFormCreateRoute}
          >
            Dodaj nowe szkolenie
          </Button>
        </TableToolbarContent>
      </TableToolbar>

      <TableContent>
        <Table $loading={isFetching} {...getTableProps()}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <DataTableHeader
                  isSortable={true}
                  isSortHeader={sortData.orderBy === header.key}
                  sortDirection={sortData.direction}
                  onClick={(direction) => {
                    dispatch(
                      courseActions.setSort({
                        direction,
                        orderBy: header.key as CourseListColumn,
                      })
                    );
                  }}
                  key={header.key}
                >
                  {header.header}
                </DataTableHeader>
              ))}

              <TableHeader />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(rows) &&
              rows.map((row) => (
                // eslint-disable-next-line react/jsx-key
                <CourseListDataTableRow
                  onDeleteSuccess={onDeleteSuccess}
                  getRowProps={getRowProps}
                  key={row.id}
                  row={row}
                />
              ))}

            {isEmpty(rows) && <DataTableEmptyRow colSpan={4} />}
          </TableBody>
        </Table>

        {isFetching && (
          <TableLoader>
            <Loading active={true} withOverlay={false} />
          </TableLoader>
        )}
      </TableContent>
    </TableContainer>
  );
};
