import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';

export const elearningLessonStateSelector = (state: RootState) =>
  state.elearningLesson;

export const elearningLessonElearningIdSelector = createSelector(
  elearningLessonStateSelector,
  (state) => state.elearningId
);
