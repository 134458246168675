import { throwErrorResponse } from 'common/services/axios';
import Axios from 'axios';
import { UploadProgress } from 'common/models/fileUploader';
import { ErrorCode } from 'common/models/error';
import { isAbortError } from 'common/services/error';
import { ElearningLessonUploadToAzureDTO } from 'common/models/elearningLesson';
import { AbortError } from 'common/errors/AbortError';

export const AWSAxios = Axios.create();

export const uploadFileToAWS = async (
  params: ElearningLessonUploadToAzureDTO,
  onUploadProgress: (progress: UploadProgress) => void,
  abortSignal: AbortSignal
) => {
  try {
    const form = new FormData();

    form.set('file', params.file);

    await AWSAxios.put(params.url, params.file, {
      onUploadProgress: onUploadProgress,
      signal: abortSignal,
    });
  } catch (error) {
    if (isAbortError(error)) {
      throwErrorResponse(new AbortError(ErrorCode.UploadCanceled));
    }

    throwErrorResponse(error);
  }
};
