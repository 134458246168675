import { getQueryParamsMap } from 'lib/getQueryParams';
import { pipe } from 'fp-ts/function';
import { initialPagination } from 'common/models/pagination';
import { mapObjIndexed } from 'ramda';
import { filter, fromNullable, getOrElse } from 'fp-ts/Option';
import { isString } from 'lib/typeGuards';

export const getPaginationQueryParams = () => {
  const qp = getQueryParamsMap();
  return pipe(
    initialPagination,
    mapObjIndexed((value, key) =>
      pipe(
        fromNullable(qp[key]),
        filter(isString),
        getOrElse(() => value.toString()),
        Number
      )
    )
  );
};
