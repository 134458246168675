import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { stringify } from 'qs';
import { pipe } from 'fp-ts/function';
import { getOrElse, Option } from 'fp-ts/lib/Option';
import { initialPagination, PaginationState } from 'common/models/pagination';
import { partnerActions } from 'state/partner/partnerActions';
import { partnerPaginationSelector } from 'state/partner/partnerSelectors';
import { PartnerListRoute } from 'routes';
import { getPaginationQueryParams } from 'common/services/pagination';

function* writeToURLQuery() {
  const pagination: Option<PaginationState> = yield select(
    partnerPaginationSelector
  );

  const qs = pipe(
    pagination,
    getOrElse(() => initialPagination),
    stringify
  );

  yield put(
    push(`${PartnerListRoute}?${qs}`, {
      replace: true,
    })
  );
}

function* readFromURLQuery() {
  yield put(partnerActions.setPagination(getPaginationQueryParams()));
}

function* readFromURLQueryWatcher() {
  yield takeEvery(partnerActions.loadPagination.type, readFromURLQuery);
}

function* writeToURLQueryWatcher() {
  yield takeLatest(partnerActions.setPagination.type, writeToURLQuery);
}

export function* partnerSaga() {
  yield all([writeToURLQueryWatcher(), readFromURLQueryWatcher()]);
}
