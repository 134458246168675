import { QueryObserverOptions, useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { ResultResponse } from 'common/models/response';
import { useSelector } from 'react-redux';
import { getOrElse, isSome } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/function';
import { initialPagination } from 'common/models/pagination';
import {
  CourseEventListFilters,
  CourseEventListItem,
} from 'common/models/courseEvent';
import { getCourseEventList } from 'common/services/courseEvent';
import {
  courseEventPaginationSelector,
  courseEventSortSelector,
} from 'state/courseEvent/courseEventSelectors';

export const useCourseEventListQuery = (
  filters: CourseEventListFilters,
  options: QueryObserverOptions<ResultResponse<CourseEventListItem>>
) => {
  const paginationOption = useSelector(courseEventPaginationSelector);
  const sortData = useSelector(courseEventSortSelector);

  const pagination = pipe(
    paginationOption,
    getOrElse(() => initialPagination)
  );

  return useQuery<ResultResponse<CourseEventListItem>>(
    [
      QueryKey.CourseEventList,
      pagination.page,
      pagination.pageSize,
      sortData,
      filters,
    ],
    () => getCourseEventList(pagination, sortData, filters),
    {
      ...options,
      enabled: isSome(paginationOption),
      keepPreviousData: true,
    }
  );
};
