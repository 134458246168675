import { createReducer } from '@reduxjs/toolkit';
import { CourseBundleContentCategory } from 'common/models/courseBundleContent';
import { courseBundleContentSwitcherActions } from 'state/courseBundleContent/contentSwitcher/contentSwitcherActions';

export interface CourseBundleContentSwitcherState {
  courseBundleContentCategory: CourseBundleContentCategory;
}

const initialState: CourseBundleContentSwitcherState = {
  courseBundleContentCategory: CourseBundleContentCategory.ElearningList,
};

export const courseBundleContentSwitcherReducer =
  createReducer<CourseBundleContentSwitcherState>(initialState, (builder) =>
    builder.addCase(
      courseBundleContentSwitcherActions.setCourseBundleContentCategory,
      (state, action) => {
        state.courseBundleContentCategory = action.payload || '';
      }
    )
  );
