import { useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { RootRoute } from 'routes';
import { push } from 'connected-react-router';
import { snackbarActions } from 'state/notification/notificationActions';
import { useDispatch } from 'react-redux';
import { SystemSettingsDTO } from 'common/models/systemSettings';
import { getSystemSettings } from 'common/services/systemSettings';

export const useSystemSettingsQuery = (
  onSuccess: (data: SystemSettingsDTO) => void
) => {
  const dispatch = useDispatch();

  return useQuery([QueryKey.SystemSettings], getSystemSettings, {
    onError: () => {
      dispatch(push(RootRoute));
      dispatch(
        snackbarActions.enqueueSnackbar({
          title: 'Ustawienia systemowe nie zostały znalezione',
          options: { variant: 'error' },
        })
      );
    },
    onSuccess,
  });
};
