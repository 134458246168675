import { ConditionControlModel } from 'app/components/SurveyFormPage/SurveyQuestionControl/ConditionControl';

export interface ChoiceOption {
  id?: number;
  enablesComment?: boolean;
  text: string;
}

export enum ComparisonOperator {
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
  Equal = 'EQUAL',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  GreaterThan = 'GREATER_THAN',
}

export enum QuestionControlType {
  Option = 'OPTION',
  MultiOption = 'MULTI_OPTION',
  Scale = 'SCALE',
}

interface SurveyQuestionControlModelBase {
  readonly id?: string;
  title: string;
  mandatory: boolean;
}

export interface ScaleSurveyQuestionControlModel
  extends SurveyQuestionControlModelBase {
  type: QuestionControlType.Scale;
  minLabel?: string;
  maxLabel?: string;
  condition?: ConditionControlModel;

  hasConditionalComment: boolean;
}

export interface OptionSurveyQuestionControlModel
  extends SurveyQuestionControlModelBase {
  type: QuestionControlType.Option;
  choiceOptions: ChoiceOption[];
}

export interface MultiOptionSurveyQuestionControlModel
  extends SurveyQuestionControlModelBase {
  type: QuestionControlType.MultiOption;
  choiceOptions: ChoiceOption[];
  customOptionAllowed?: boolean;
  customOptionLabel?: string;
  customOptionPlaceholder?: string;
}

export type SurveyQuestionControlModel =
  | ScaleSurveyQuestionControlModel
  | OptionSurveyQuestionControlModel
  | MultiOptionSurveyQuestionControlModel;
