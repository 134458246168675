import { FC, useEffect, useMemo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  Grid,
  Pagination,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { useDispatch, useSelector } from 'react-redux';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/lib/Option';
import { initialPagination, pageSizes } from 'common/models/pagination';
import { Content } from 'common/components/Grid';
import { usePartnerListQuery } from 'common/hooks/partner/usePartnerListQuery';
import { partnerPaginationSelector } from 'state/partner/partnerSelectors';
import { partnerActions } from 'state/partner/partnerActions';
import { PartnerListDataTableContent } from 'app/components/PartnerList/PartnerListDataTableContent';
import { preparePartnerRow } from 'app/components/PartnerList/PartnerListDataTableRow';

const headers = [
  {
    key: 'partnerName',
    header: 'Nazwa',
  },
  {
    key: 'partnerCode',
    header: 'Kod',
  },
  {
    key: 'registrationUrl',
    header: 'Fragment URL partnera',
  },
];

export const PartnerList: FC = () => {
  const dispatch = useDispatch();
  const paginationOption = useSelector(partnerPaginationSelector);

  const pagination = useMemo(
    () =>
      pipe(
        paginationOption,
        getOrElse(() => initialPagination)
      ),
    [paginationOption]
  );

  const { data, isFetching, isLoading } = usePartnerListQuery({
    onSuccess: (data) => {
      if (data && data.result.length === 0 && pagination.page !== 1) {
        dispatch(
          partnerActions.setPagination({
            page: Math.ceil(data.totalResult / pagination.pageSize),
            pageSize: pagination.pageSize,
          })
        );
      }
    },
  });

  const rows = useMemo(() => data?.result.map(preparePartnerRow), [data]);

  useEffect(() => {
    dispatch(partnerActions.loadPagination());
  }, [dispatch]);

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageHeading>Lista partnerów</PageHeading>

        {isLoading && (
          <DataTableSkeleton headers={headers} rowCount={10}>
            {(props: DataTableCustomRenderProps) => (
              <PartnerListDataTableContent {...props} />
            )}
          </DataTableSkeleton>
        )}

        {rows && !isLoading && (
          <DataTable headers={headers} overflowMenuOnHover={false} rows={rows}>
            {(props: DataTableCustomRenderProps) => (
              <PartnerListDataTableContent {...props} isFetching={isFetching} />
            )}
          </DataTable>
        )}

        <Pagination
          onChange={(pagination) => {
            dispatch(
              partnerActions.setPagination({
                page: pagination.page,
                pageSize: pagination.pageSize,
              })
            );
          }}
          page={pagination.page}
          pageSize={pagination.pageSize}
          pageSizes={pageSizes}
          totalItems={data?.totalResult ?? 0}
        />
      </Grid>
    </Content>
  );
};
