import React, { FC, useCallback, useEffect, useState } from 'react';
import { NumberInput as CdsNumberInput } from 'carbon-components-react';
import { NumberInputProps as CdsNumberInputProps } from 'carbon-components-react/lib/components/NumberInput/NumberInput';

export const NumberInput: FC<CdsNumberInputProps> = (props) => {
  const [numberInputRef, setNumberInputRef] = useState<HTMLInputElement | null>(
    null
  );

  const handleWheel = useCallback((event) => {
    event.preventDefault();
  }, []);

  useEffect(() => {
    numberInputRef?.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      numberInputRef?.removeEventListener('wheel', handleWheel);
    };
  }, [numberInputRef]);

  return <CdsNumberInput {...props} ref={setNumberInputRef} />;
};
