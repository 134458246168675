import { createRootRoute, createRoute } from 'common/utils/routing';
import { StaticPageCode } from 'modules/static-pages/models/staticPageCode';

export const StaticPageListRoute = createRootRoute('static-pages');
export const StaticPageAboutUsFormRoute = createRoute(
  StaticPageListRoute,
  StaticPageCode.AboutUs
);
export const StaticPageCookiesPolicyFormRoute = createRoute(
  StaticPageListRoute,
  StaticPageCode.CookiesPolicy
);
export const StaticPageFaqFormRoute = createRoute(
  StaticPageListRoute,
  StaticPageCode.Faq
);
export const StaticPagePrivacyPolicyFormRoute = createRoute(
  StaticPageListRoute,
  StaticPageCode.PrivacyPolicy
);
export const StaticPageTosFormRoute = createRoute(
  StaticPageListRoute,
  StaticPageCode.TermsAndConditions
);
