import React, { FC, useCallback, useState } from 'react';
import { courseTypeIconMap } from 'common/models/courseType';
import { Category } from 'common/models/category';
import {
  Button,
  ComboBox,
  Loading,
  StructuredListCell,
  StructuredListRow,
} from 'carbon-components-react';
import styled from 'styled-components';
import { Course } from 'common/models/course';
import { Checkmark20 } from '@carbon/icons-react';
import { useUpdateCourseCategoryMutation } from 'common/hooks/course/useUpdateCourseCategoryMutation';
import { LoadingButton } from 'app/components/Button';
import { ID } from 'lib/id';

const Icon = styled.svg`
  margin-right: 0.75rem;
`;

export interface CategoryDeleteRowProps {
  allCategoriesList?: Category[];
  onSuccess: (id: ID) => void;
  row: Course;
}

export const CategoryDeleteRow: FC<CategoryDeleteRowProps> = ({
  allCategoriesList,
  onSuccess,
  row,
}) => {
  const [newCategoryId, setNewCategoryId] = useState<ID>();

  const updateCourseCategoryMutation = useUpdateCourseCategoryMutation(() =>
    onSuccess(row.id)
  );

  const changeCategory = useCallback(() => {
    if (!newCategoryId) {
      return;
    }

    updateCourseCategoryMutation.mutate({
      categoryId: newCategoryId,
      courseId: row.id,
    });
  }, [newCategoryId, row]);

  return (
    <StructuredListRow tabIndex={0}>
      <StructuredListCell>
        <Icon as={courseTypeIconMap[row.type]} />
        {row.title}
      </StructuredListCell>

      <StructuredListCell>
        <ComboBox<Category>
          id="newCategory"
          items={allCategoriesList ?? []}
          itemToString={(item) => item?.label || ''}
          onChange={(value) => {
            setNewCategoryId(value.selectedItem?.id);
          }}
          placeholder=""
          selectedItem={
            allCategoriesList?.find((item) => item.id === newCategoryId) ?? null
          }
          titleText=""
        />
      </StructuredListCell>

      <StructuredListCell>
        {!updateCourseCategoryMutation.isLoading && (
          <Button
            disabled={!newCategoryId}
            hasIconOnly
            iconDescription="Zmień kategorię"
            kind="danger--tertiary"
            onClick={changeCategory}
            renderIcon={Checkmark20}
            size="field"
            tooltipAlignment="center"
            tooltipPosition="top"
          />
        )}

        {updateCourseCategoryMutation.isLoading && (
          <LoadingButton disabled hasIconOnly kind="danger" size="field">
            <Loading small withOverlay={false} />
          </LoadingButton>
        )}
      </StructuredListCell>
    </StructuredListRow>
  );
};
