import { all, put, select, takeEvery } from 'redux-saga/effects';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { elearningLessonActions } from 'state/elearningLesson/elearningLessonActions';
import { ElearningLessonListRoute } from 'routes';
import { rootStateSelector } from 'state/router/routerSelectors';
import { ElearningLessonListParams } from 'common/models/elearningLesson';

function* readFromURLQuery() {
  const routerState: RouterRootState = yield select(rootStateSelector);
  const matchSelector = createMatchSelector<
    RouterRootState,
    ElearningLessonListParams
  >(ElearningLessonListRoute);

  const match = matchSelector(routerState);

  yield put(elearningLessonActions.setElearningId(match?.params.elearningId));
}

function* readFromURLQueryWatcher() {
  yield takeEvery(elearningLessonActions.loadPagination.type, readFromURLQuery);
}

export function* elearningLessonSaga() {
  yield all([readFromURLQueryWatcher()]);
}
