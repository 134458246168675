import {
  CloseFilled20,
  DocumentExport32,
  Edit20,
  List20,
  Run20,
  WatsonHealthDownloadStudy32,
} from '@carbon/icons-react';
import { DataTableButton } from 'app/components/DataTableButton';
import { DataTableDeleteButton } from 'app/components/DataTableDeleteButton';
import { ActionsTableCell } from 'app/components/Table';
import {
  Button,
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import { useCloseCourseEventMutation } from 'common/hooks/courseEvent/useCloseCourseEventMutation';
import { useDeleteCourseEventMutation } from 'common/hooks/courseEvent/useDeleteCourseEventMutation';
import { useStartCourseEventMutation } from 'common/hooks/courseEvent/useStartCourseEventMutation';
import { useWebinarStartUrlQuery } from 'common/hooks/courseEvent/useWebinarStartUrlQuery';
import { useCourseEventParticipantsListExportMutation } from 'common/hooks/courseEventParticipant/useCourseEventParticipantsListExportMutation';
import { useExportEventSurveyResultsMutation } from 'common/hooks/survey/useExportEventSurveyResultsMutation';
import { CourseEventStatus } from 'common/models/courseEvent';
import { environment } from 'config/environment';
import { isNilEmpty } from 'lib/isNilEmpty';
import { DashboardEvent } from 'modules/dashboard/types';
import { Link } from 'react-router-dom';
import {
  getCourseEventFormRoute,
  getCourseEventParticipantListRoute,
} from 'routes';

export function DashboardListTableRow({
  row,
  event,
  getRowProps,
  onCloseSuccess,
  onDeleteSuccess,
  onStartSuccess,
}: Pick<DataTableCustomRenderProps, 'getRowProps'> & {
  row: DenormalizedRow;
  event: DashboardEvent | undefined;
  onCloseSuccess?: (row: DenormalizedRow) => void;
  onDeleteSuccess?: (row: DenormalizedRow) => void;
  onStartSuccess?: (row: DenormalizedRow) => void;
}) {
  const { participantsAccepted, courseId, status } = event ?? {};

  const isHighlighted =
    Number(participantsAccepted) > environment.majorEventThreshold;

  const deleteMutation = useDeleteCourseEventMutation();
  const surveyMutation = useExportEventSurveyResultsMutation();
  const exportMutation = useCourseEventParticipantsListExportMutation();
  const closeMutation = useCloseCourseEventMutation();
  const startMutation = useStartCourseEventMutation();

  const { data: zoomURL } = useWebinarStartUrlQuery(
    row.id,
    status &&
      [CourseEventStatus.Open, CourseEventStatus.Ongoing].includes(status)
  );

  return (
    <TableRow
      {...getRowProps({ row })}
      style={isHighlighted ? { background: 'rgb(208, 226, 255)' } : {}}
    >
      {row.cells.map((cell) => (
        <TableCell key={cell.id} style={{ background: 'transparent' }}>
          {cell.value}
        </TableCell>
      ))}

      <ActionsTableCell style={{ width: 288, background: 'transparent' }}>
        {status &&
          [CourseEventStatus.Open].includes(status) &&
          !isNilEmpty(zoomURL) && (
            <Button
              as="a"
              hasIconOnly
              href={zoomURL?.url}
              iconDescription="Rozpocznij wydarzenie"
              kind="primary"
              onClick={() =>
                startMutation.mutate(row.id, {
                  onSuccess: () => onStartSuccess && onStartSuccess(row),
                })
              }
              renderIcon={Run20}
              size="small"
              target="_blank"
              tooltipAlignment="center"
              tooltipPosition="top"
            />
          )}

        {status &&
          [CourseEventStatus.Ongoing].includes(status) &&
          !isNilEmpty(zoomURL) && (
            <Button
              as="a"
              hasIconOnly
              href={zoomURL?.url}
              iconDescription="Przejdź do Zoom"
              kind="primary"
              renderIcon={Run20}
              size="small"
              target="_blank"
              tooltipAlignment="center"
              tooltipPosition="top"
            />
          )}

        <DataTableButton
          hasIconOnly
          iconDescription="Zamknij wydarzenie"
          isLoading={closeMutation.isLoading}
          isVisible={status && [CourseEventStatus.Ongoing].includes(status)}
          kind="danger"
          onClick={() =>
            closeMutation.mutate(row.id, {
              onSuccess: () => onCloseSuccess && onCloseSuccess(row),
            })
          }
          renderIcon={CloseFilled20}
          size="small"
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <DataTableButton
          hasIconOnly
          iconDescription="Eksportuj uczestników"
          isLoading={exportMutation.isLoading}
          kind="ghost"
          onClick={() => {
            exportMutation.mutate(row.id);
          }}
          renderIcon={WatsonHealthDownloadStudy32}
          size="small"
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <DataTableButton
          hasIconOnly
          iconDescription="Eksportuj wyniki ankiet"
          isLoading={surveyMutation.isLoading}
          kind="ghost"
          onClick={() => {
            surveyMutation.mutate(row.id);
          }}
          renderIcon={DocumentExport32}
          size="small"
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <Button
          as={Link}
          hasIconOnly
          iconDescription="Uczestnicy"
          kind="ghost"
          renderIcon={List20}
          size="small"
          to={getCourseEventParticipantListRoute(courseId ?? '', row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <Button
          as={Link}
          hasIconOnly
          iconDescription="Edytuj"
          kind="ghost"
          renderIcon={Edit20}
          size="small"
          to={getCourseEventFormRoute(courseId ?? '', row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <DataTableDeleteButton
          confirmationTitle="Usuń wydarzenie"
          isLoading={deleteMutation.isLoading}
          onSubmit={() => {
            deleteMutation.mutate(row.id, {
              onSuccess: () => onDeleteSuccess && onDeleteSuccess(row),
            });
          }}
        />
      </ActionsTableCell>
    </TableRow>
  );
}
