import {
  Button,
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import {
  DocumentExport32,
  Edit20,
  List20,
  VideoAdd20,
  WatsonHealthDownloadStudy32,
} from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import {
  ElearningFormRoute,
  elearningIdRouteParam,
  ElearningLessonListRoute,
  ElearningUploadPreviewRoute,
} from 'routes';
import { ActionsTableCell } from 'app/components/Table';
import { FC } from 'react';
import { DataTableDeleteButton } from 'app/components/DataTableDeleteButton';
import { useDeleteElearningMutation } from 'common/hooks/elearning/useDeleteElearningMutation';
import styled from 'styled-components';
import { DataTableButton } from 'app/components/DataTableButton';
import { useElearningParticipantsListExportMutation } from 'common/hooks/elearning/useElearningParticipantsListExportMutation';
import { useExportElearningSurveyResultsMutation } from 'common/hooks/survey/useExportElearningSurveyResultsMutation';
import { setParam } from 'common/utils/routing';
import { statusLabelMap } from 'common/models/status';
import { ElearningStatus } from 'common/models/elearning';

const StyledActionsTableCell = styled(ActionsTableCell)`
  width: 16rem;
`;

export interface ElearningListTableRowProps {
  row: DenormalizedRow;
}

export const ElearningListTableRow: FC<
  ElearningListTableRowProps & Pick<DataTableCustomRenderProps, 'getRowProps'>
> = ({ getRowProps, row }) => {
  const deleteMutation = useDeleteElearningMutation();
  const exportParticipantsListMutation =
    useElearningParticipantsListExportMutation();
  const exportResultsMutation = useExportElearningSurveyResultsMutation();

  return (
    <TableRow {...getRowProps({ row })}>
      {row.cells.map((cell) => (
        <TableCell key={cell.id}>{cell.value}</TableCell>
      ))}

      <StyledActionsTableCell>
        {row.cells[5].value === statusLabelMap[ElearningStatus.Public] && (
          <>
            <DataTableButton
              hasIconOnly
              iconDescription="Eksportuj uczestników"
              isLoading={exportParticipantsListMutation.isLoading}
              kind="ghost"
              onClick={() => {
                exportParticipantsListMutation.mutate(row.id);
              }}
              renderIcon={WatsonHealthDownloadStudy32}
              size="small"
              tooltipAlignment="center"
              tooltipPosition="top"
            />

            <DataTableButton
              hasIconOnly
              iconDescription="Eksportuj wyniki ankiet"
              isLoading={exportResultsMutation.isLoading}
              kind="ghost"
              onClick={() => {
                exportResultsMutation.mutate(row.id);
              }}
              renderIcon={DocumentExport32}
              size="small"
              tooltipAlignment="center"
              tooltipPosition="top"
            />
          </>
        )}

        <Button
          as={Link}
          hasIconOnly
          iconDescription="Dodaj / zmień zapowiedź"
          kind="ghost"
          renderIcon={VideoAdd20}
          size="small"
          to={setParam(elearningIdRouteParam)(ElearningUploadPreviewRoute)(
            row.id
          )}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <Button
          as={Link}
          hasIconOnly
          iconDescription="Lekcje"
          kind="ghost"
          renderIcon={List20}
          size="small"
          to={setParam(elearningIdRouteParam)(ElearningLessonListRoute)(row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <Button
          as={Link}
          hasIconOnly
          iconDescription="Edytuj"
          kind="ghost"
          renderIcon={Edit20}
          size="small"
          to={setParam(elearningIdRouteParam)(ElearningFormRoute)(row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <DataTableDeleteButton
          confirmationTitle="Usuń e-learning"
          isLoading={deleteMutation.isLoading}
          onSubmit={() => deleteMutation.mutate(row.id)}
        />
      </StyledActionsTableCell>
    </TableRow>
  );
};
