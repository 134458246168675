import { QueryObserverOptions, useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { ResultResponse } from 'common/models/response';
import { useSelector } from 'react-redux';
import { getOrElse, isSome } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/function';
import { initialPagination } from 'common/models/pagination';
import { getCourseBundleEventList } from 'common/services/courseBundle';
import {
  CourseEventListFilters,
  EventStatusListItem,
} from 'common/models/courseEvent';
import {
  courseBundleEventListPaginationSelector,
  courseBundleEventListSortSelector,
} from 'state/courseBundleContent/eventList/courseBundleEventListSelectors';
import { ID } from 'lib/id';

export const useCourseBundleEventListQuery = (
  bundleId: ID,
  filters?: CourseEventListFilters, // exists in 2 places; change accordingly
  options?: QueryObserverOptions<ResultResponse<EventStatusListItem>>
) => {
  const paginationOption = useSelector(courseBundleEventListPaginationSelector);
  const sortData = useSelector(courseBundleEventListSortSelector);

  const pagination = pipe(
    paginationOption,
    getOrElse(() => initialPagination)
  );

  return useQuery<ResultResponse<EventStatusListItem>>(
    [
      QueryKey.CourseBundleEventList,
      pagination.page,
      pagination.pageSize,
      sortData,
      filters,
    ],
    () => getCourseBundleEventList(bundleId, pagination, sortData, filters),
    {
      enabled: isSome(paginationOption),
      keepPreviousData: true,
      ...options,
    }
  );
};
