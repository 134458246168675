import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { QueryKey } from 'config/queryKey';
import { elearningIdRouteParam, ElearningLessonListRoute } from 'routes';
import { push } from 'connected-react-router';
import { getElearningLessonById } from 'common/services/elearningLesson';
import { ID } from 'lib/id';
import { setParam } from 'common/utils/routing';

export function useElearningLessonByIdQuery(
  elearningId: string,
  elearningLessonId: ID,
  enabled?: boolean
) {
  const dispatch = useDispatch();

  return useQuery(
    [QueryKey.ElearningLesson, elearningLessonId],
    () => getElearningLessonById(elearningLessonId),
    {
      enabled,
      onError: () => {
        dispatch(
          push(
            setParam(elearningIdRouteParam)(ElearningLessonListRoute)(
              elearningId
            )
          )
        );
      },
    }
  );
}
