import { forwardRef, ReactNode, useCallback } from 'react';
import { OptionsObject, SnackbarContent, useSnackbar } from 'notistack';
import { NotificationKind, ToastNotification } from 'carbon-components-react';

const variantMap: Record<string, NotificationKind> = {
  default: 'info',
  error: 'error',
  success: 'success',
  warning: 'warning',
  info: 'info',
};

export interface SnackbarMessageProps {
  id: string | number;
  options: OptionsObject;
  subtitle: string | ReactNode;
  title: string | ReactNode;
}

export const SnackbarMessage = forwardRef<HTMLDivElement, SnackbarMessageProps>(
  ({ id, options, subtitle, title }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const handleClose = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref}>
        <ToastNotification
          caption=""
          kind={variantMap[options.variant ?? 'default']}
          onCloseButtonClick={handleClose}
          subtitle={subtitle}
          timeout={0}
          title={title ?? ''}
        />
      </SnackbarContent>
    );
  }
);
