import { createReducer } from '@reduxjs/toolkit';
import { fileUploaderActions } from 'state/fileUploader/fileUploaderActions';
import {
  FileUploaderMapItem,
  FileUploaderStatus,
} from 'common/models/fileUploader';
import { isNil, without } from 'ramda';
import { removeFinishedItems } from 'common/services/fileUploader';

export interface FileUploaderState {
  list: string[];
  map: FileUploaderMapItem;
  open: boolean;
}

const initialState: FileUploaderState = {
  list: [],
  map: {},
  open: false,
};

export const fileUploaderReducer = createReducer<FileUploaderState>(
  initialState,
  (builder) =>
    builder
      .addCase(fileUploaderActions.finishUploading, (state, action) => {
        if (isNil(state.map[action.payload])) {
          return;
        }

        state.map[action.payload].status = FileUploaderStatus.Success;
      })
      .addCase(fileUploaderActions.removeFinished, (state) => {
        removeFinishedItems(state);

        if (state.list.length === 0) {
          state.open = false;
        }
      })
      .addCase(fileUploaderActions.removeItem, (state, action) => {
        if (isNil(state.map[action.payload])) {
          return;
        }

        state.list = without([action.payload], state.list);
        delete state.map[action.payload];

        if (state.list.length === 0) {
          state.open = false;
        }
      })
      .addCase(fileUploaderActions.reset, () => initialState)
      .addCase(fileUploaderActions.setPromise, (state, action) => {
        if (isNil(state.map[action.payload.id])) {
          return;
        }

        state.map[action.payload.id].promise = action.payload.promise;
      })
      .addCase(fileUploaderActions.toggleOpen, (state) => {
        removeFinishedItems(state);
        state.open = !state.open;
      })
      .addCase(fileUploaderActions.uploadFile, (state, action) => {
        state.list.unshift(action.payload.id);
        state.map[action.payload.id] = {
          progress: {
            current: 0,
            total: 0,
          },
          status: FileUploaderStatus.Uploading,
          title: action.payload.title,
        };
      })
      .addCase(fileUploaderActions.uploadingFailed, (state, action) => {
        if (isNil(state.map[action.payload])) {
          return;
        }

        state.map[action.payload].status = FileUploaderStatus.Error;
      })
      .addCase(fileUploaderActions.updateProgress, (state, action) => {
        if (isNil(state.map[action.payload.id])) {
          return;
        }

        state.map[action.payload.id].progress = action.payload.progress;
      })
);
