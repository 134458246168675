import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';
import { CategoryState } from 'state/category/categoryReducer';

export const categoryStateSelector = (state: RootState) => state.category;

export const categoryPaginationSelector = createSelector(
  categoryStateSelector,
  (state: CategoryState) => state.pagination
);
