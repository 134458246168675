import { FC, useEffect, useMemo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  Grid,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { useDispatch, useSelector } from 'react-redux';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/lib/Option';
import { initialPagination } from 'common/models/pagination';
import { Content } from 'common/components/Grid';
import { Category, CategoryListColumn } from 'common/models/category';
import { useCategoryListQuery } from 'common/hooks/category/useCategoryListQuery';
import { categoryActions } from 'state/category/categoryActions';
import { CategoryListTableContent } from 'app/components/CategoryList/CategoryListTableContent';
import { categoryPaginationSelector } from 'state/category/categorySelectors';

const headers = [
  {
    key: CategoryListColumn.Label,
    header: 'Nazwa',
  },
];

const prepareCategoryRow = (category: Category) => ({
  [CategoryListColumn.Label]: category.label,
  id: `${category.id}`,
});

export const CategoryList: FC = () => {
  const dispatch = useDispatch();

  const paginationOption = useSelector(categoryPaginationSelector);

  const pagination = useMemo(
    () =>
      pipe(
        paginationOption,
        getOrElse(() => initialPagination)
      ),
    [paginationOption]
  );

  const {
    data,
    isFetching: isFetchingCategories,
    isLoading: isLoadingCategories,
  } = useCategoryListQuery({
    onSuccess: (data) => {
      if (data && data.result.length === 0 && pagination.page !== 1) {
        dispatch(
          categoryActions.setPagination({
            page: Math.ceil(data.totalResult / pagination.pageSize),
            pageSize: pagination.pageSize,
          })
        );
      }
    },
  });

  const rows = useMemo(() => data?.result.map(prepareCategoryRow), [data]);

  useEffect(() => {
    dispatch(categoryActions.loadPagination());

    return () => {
      dispatch(categoryActions.clear());
    };
  }, [dispatch]);

  const isLoading = isLoadingCategories || isLoadingCategories;

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageHeading>Lista kategorii specjalnych</PageHeading>

        {isLoading && (
          <DataTableSkeleton headers={headers} rowCount={10}>
            {(props: DataTableCustomRenderProps) => (
              <CategoryListTableContent {...props} />
            )}
          </DataTableSkeleton>
        )}

        {rows && !isLoading && (
          <DataTable headers={headers} overflowMenuOnHover={false} rows={rows}>
            {(props: DataTableCustomRenderProps) => (
              <CategoryListTableContent
                {...props}
                isFetching={isFetchingCategories}
              />
            )}
          </DataTable>
        )}
      </Grid>
    </Content>
  );
};
