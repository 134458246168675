import {
  DataTableCustomRenderProps,
  Loading,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react';
import {
  Table,
  TableContainer,
  TableContent,
  TableLoader,
} from 'app/components/Table';
import React, { FC } from 'react';
import { isEmpty } from 'ramda';
import { DataTableEmptyRow } from 'app/components/DataTableEmptyRow';
import { PartnerUsersListRow } from 'app/components/PartnerUsersList/PartnerUsersListRow';
import { User } from 'common/models/user';

export interface PartnerUsersListContentProps {
  isFetching?: boolean;
  users: User[];
}

export const PartnerUsersListContent: FC<
  PartnerUsersListContentProps & DataTableCustomRenderProps
> = ({
  isFetching = false,
  users,

  rows,
  headers,
  getTableProps,
  getTableContainerProps,
  getToolbarProps,
  getHeaderProps,
  getRowProps,
}) => {
  return (
    <TableContainer title="Użytkownicy" {...getTableContainerProps()}>
      <TableToolbar {...getToolbarProps()}>
        <TableToolbarContent />
      </TableToolbar>

      <TableContent>
        <Table $loading={isFetching} {...getTableProps()}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                // eslint-disable-next-line react/jsx-key
                <TableHeader {...getHeaderProps({ header })}>
                  {header.header}
                </TableHeader>
              ))}

              <TableHeader />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(rows) &&
              rows.map((row, i) => (
                // eslint-disable-next-line react/jsx-key
                <PartnerUsersListRow
                  getRowProps={getRowProps}
                  key={row.id}
                  row={row}
                  user={users[i]}
                />
              ))}

            {isEmpty(rows) && <DataTableEmptyRow colSpan={4} />}
          </TableBody>
        </Table>

        {isFetching && (
          <TableLoader>
            <Loading active={true} withOverlay={false} />
          </TableLoader>
        )}
      </TableContent>
    </TableContainer>
  );
};
