import { Link } from 'carbon-components-react';
import { Category } from 'common/models/category';
import { formatDateOnLists } from 'lib/date';
import {
  BannerColumn,
  BannerDTO,
  BannerStatus,
  BannerSubpage,
} from 'modules/banner/types.banner';

export function bannerRow(banner: BannerDTO, categories: Category[]) {
  const category = categories.find((i) => i.id === banner.categoryId);

  return {
    id: String(banner.id),
    [BannerColumn.TITLE]: banner.title,
    [BannerColumn.SUBPAGE]: {
      [BannerSubpage.CATEGORY]: 'Kategoria - ' + category?.label,
      [BannerSubpage.COURSE_BUNDLE]: 'Kursy',
      [BannerSubpage.E_LEARNING]: 'E-learning',
      [BannerSubpage.ONLINE]: 'Szkolenia online',
    }[banner.subpage],
    [BannerColumn.LINK]: banner.link ? (
      <Link href={banner.link} target="_blank">
        {banner.link}
      </Link>
    ) : (
      'Brak'
    ),
    [BannerColumn.CREATE_DATE]: formatDateOnLists(banner.createDate),
    [BannerColumn.DISPLAY_STATUS]: {
      [BannerStatus.VISIBLE]: 'Widoczny',
      [BannerStatus.HIDDEN]: 'Ukryty',
    }[banner.displayStatus],
  };
}
