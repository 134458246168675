export enum StaticPageCode {
  AboutUs = 'about-us',
  CookiesPolicy = 'cookies-policy',
  Faq = 'faq',
  PrivacyPolicy = 'privacy-policy',
  TermsAndConditions = 'terms-and-conditions',
}

export const staticPageCodeTitleMapper = {
  [StaticPageCode.AboutUs]: 'O PFP',
  [StaticPageCode.CookiesPolicy]: 'Polityka cookies',
  [StaticPageCode.Faq]: 'FAQ / Pomoc',
  [StaticPageCode.PrivacyPolicy]: 'Polityka prywatności',
  [StaticPageCode.TermsAndConditions]: 'Regulamin',
};
