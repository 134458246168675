import React from 'react';
import { MaterialResource } from 'common/models/material';

export interface MaterialsContextInterface {
  resource: MaterialResource;
}

export const MaterialsContext = React.createContext<MaterialsContextInterface>({
  resource: MaterialResource.StandardCourse,
});
