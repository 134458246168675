import { createReducer } from '@reduxjs/toolkit';
import { PaginationState } from 'common/models/pagination';
import { none, Option, some } from 'fp-ts/lib/Option';
import { speakerActions } from 'state/speaker/speakerActions';

export interface SpeakerState {
  pagination: Option<PaginationState>;
}

const initialState: SpeakerState = {
  pagination: none,
};

export const speakerReducer = createReducer<SpeakerState>(
  initialState,
  (builder) =>
    builder.addCase(speakerActions.setPagination, (state, action) => {
      state.pagination = some(action.payload);
    })
);
