import * as yup from 'yup';
import { TestContext } from 'yup';
import { isNilEmpty } from 'lib/isNilEmpty';
import { validateDuration } from 'lib/duration';
import { isNil } from 'ramda';
import { durationStep } from 'config/elearning';
import { setYupDefaultMessages } from 'common/services/yup';

export const validFileNameRegExp = /^[A-Za-z\d.\-_ ]*$/;

const printMessageRegExp = /\$\{\s*(\w+)\s*\}/g;

export const validatorMessages = {
  duration: {
    invalidStep: 'Wartość musi być wielokrotnością liczby: ${step}',
    unknownUnit: 'Nieznana jednostka: ${unit}',
  },
  invalidFileName: 'Nieprawidłowa nazwa pliku',
  invalidFileType: 'Nieprawidłowy format pliku',
  required: 'Pole wymagane',
  validFileName:
    'Nazwa pliku może zawierać jedynie małe i duże litery z przedziału (A-Z - bez polskich znaków), cyfry oraz znaki . - _ oraz spacje',
  validFileTypesSingular: 'Obługiwany typ pliku: ${types}',
};

export const printMessage = (
  message: string,
  params: Record<string, string>
) => {
  return message.replace(printMessageRegExp, (substring, key) => params[key]);
};

export const isElearningDurationValidTest = (
  value: number | undefined,
  context: TestContext
) => {
  if (isNil(value)) {
    return context.createError({
      message: validatorMessages.required,
      path: 'duration',
    });
  }

  if (value % durationStep !== 0) {
    return context.createError({
      message: validatorMessages.duration.invalidStep,
      params: { step: durationStep },
      path: 'duration',
    });
  }

  return true;
};

export const isVideoDurationValidTest = (
  value: string | undefined,
  context: TestContext
) => {
  if (isNilEmpty(value)) {
    return context.createError({
      message: validatorMessages.duration.unknownUnit,
      path: 'duration',
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const durationStatus = validateDuration(value!);

  if (!durationStatus.valid) {
    return context.createError({
      message: validatorMessages.duration.unknownUnit,
      params: { unit: durationStatus.unit },
      path: 'duration',
    });
  }

  return true;
};

setYupDefaultMessages();

export const newElearningLessonAgendaValidationSchema = yup.string().max(255);
export const newKeywordValidationSchema = yup.string().max(100);
export const newLearningPointsValidationSchema = yup.string().max(255);
export const newTargetGroupsValidationSchema = yup.string().max(255);
export const averageRatingValidationSchema = yup
  .number()
  .min(1)
  .max(5)
  .test(
    'maxDigitsAfterDecimal',
    'Wartosć może mieć tylko jedno miejsce dziesiętne',
    (number) => (number ? /^\d+(\.\d)?$/.test(number.toString()) : true)
  )
  .required();
export const slugValidationSchema = yup
  .string()
  .ensure()
  .max(200)
  .matches(/^[a-z0-9\-]*$/, {
    excludeEmptyString: true,
    message:
      'Slug może zawierać jedynie małe litery z przedziału (a-z - bez polskich znaków), cyfry oraz znak -',
  })
  .test(
    'containsLetters',
    'Slug musi zawierać co najmniej jedną małą literą z przedziału (a-z - bez polskich znaków)',
    (slug) => !!slug?.match(/[a-z]/)
  )
  .test(
    'notStartWithDash',
    'Slug musi zaczynać się małą literą z przedziału (a-z - bez polskich znaków) albo cyfrą',
    (slug) => !slug?.match(/^-/)
  )
  .test(
    'notEndsWithDash',
    'Slug musi kończyć się małą literą z przedziału (a-z - bez polskich znaków) albo cyfrą',
    (slug) => !slug?.match(/-$/)
  )
  .required();
