import { useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { ResultResponse } from 'common/models/response';
import { useDispatch, useSelector } from 'react-redux';
import { getOrElse, isSome } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/function';
import { initialPagination } from 'common/models/pagination';
import { SurveyListItem } from 'common/models/survey';
import { getSurveyList } from 'common/services/survey';
import { surveyActions } from 'state/survey/surveyActions';
import { surveyPaginationSelector } from 'state/survey/surveySelectors';

export const useSurveyListQuery = () => {
  const paginationOption = useSelector(surveyPaginationSelector);
  const dispatch = useDispatch();

  const pagination = pipe(
    paginationOption,
    getOrElse(() => initialPagination)
  );

  return useQuery<ResultResponse<SurveyListItem>>(
    [QueryKey.SurveyList, pagination.page, pagination.pageSize],
    () => getSurveyList(pagination),
    {
      onSuccess: (data) => {
        if (data?.result?.length === 0 && pagination.page !== 1) {
          dispatch(
            surveyActions.setPagination({
              page: Math.ceil(data.totalResult / pagination.pageSize),
              pageSize: pagination.pageSize,
            })
          );
        }
      },
      enabled: isSome(paginationOption),
      keepPreviousData: true,
    }
  );
};
