import {
  Button,
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import { Attachment20, Edit20, VideoAdd20 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import {
  elearningIdRouteParam,
  ElearningLessonFormRoute,
  elearningLessonIdRouteParam,
  ElearningLessonMaterialsRoute,
  ElearningLessonUploadVideoRoute,
} from 'routes';
import { ActionsTableCell } from 'app/components/Table';
import { FC } from 'react';
import { DataTableDeleteButton } from 'app/components/DataTableDeleteButton';
import { useDeleteElearningLessonMutation } from 'common/hooks/elearningLesson/useDeleteElearningLessonMutation';
import { elearningLessonElearningIdSelector } from 'state/elearningLesson/elearningLessonSelectors';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { setParams } from 'common/utils/routing';

const StyledActionsTableCell = styled(ActionsTableCell)`
  width: 12rem;
`;

export interface ElearningLessonListRowProps {
  row: DenormalizedRow;
}

export const ElearningLessonListRow: FC<
  ElearningLessonListRowProps & Pick<DataTableCustomRenderProps, 'getRowProps'>
> = ({ getRowProps, row }) => {
  const elearningId = useSelector(elearningLessonElearningIdSelector);

  const deleteMutation = useDeleteElearningLessonMutation();

  return (
    <TableRow {...getRowProps({ row })}>
      {row.cells.map((cell) => (
        <TableCell key={cell.id}>{cell.value}</TableCell>
      ))}

      <StyledActionsTableCell>
        <Button
          as={Link}
          hasIconOnly
          iconDescription="Dodaj / zmień film"
          kind="ghost"
          renderIcon={VideoAdd20}
          size="small"
          to={setParams(
            elearningIdRouteParam,
            elearningLessonIdRouteParam
          )(ElearningLessonUploadVideoRoute)(elearningId, row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <Button
          as={Link}
          hasIconOnly
          iconDescription="Materiały szkoleniowe"
          kind="ghost"
          renderIcon={Attachment20}
          size="small"
          to={setParams(
            elearningIdRouteParam,
            elearningLessonIdRouteParam
          )(ElearningLessonMaterialsRoute)(elearningId, row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <Button
          as={Link}
          hasIconOnly
          iconDescription="Edytuj"
          kind="ghost"
          renderIcon={Edit20}
          size="small"
          to={setParams(
            elearningIdRouteParam,
            elearningLessonIdRouteParam
          )(ElearningLessonFormRoute)(elearningId, row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <DataTableDeleteButton
          confirmationTitle="Usuń lekcję"
          isLoading={deleteMutation.isLoading}
          onSubmit={() => deleteMutation.mutate(row.id)}
        />
      </StyledActionsTableCell>
    </TableRow>
  );
};
