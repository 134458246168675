import React from 'react';
import { IconBox, IconProps } from 'lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <g clipRule="evenodd" fillRule="evenodd">
      <path d="M63.10741 5.46509c1.1901 1.19013 1.1901 3.1197 0 4.30982L32.63121 40.2499c-1.1902 1.1901-3.1198 1.1901-4.31 0l-9.1429-9.1425c-1.1901-1.1901-1.1901-3.1197 0-4.3098 1.1902-1.1901 3.1199-1.1901 4.31 0l6.9879 6.9876 28.3212-28.32011c1.1902-1.19012 3.1198-1.19012 4.31 0z" />
      <path d="M9.14287 7.62a3.04772 3.04772 0 00-2.155.89259 3.04748 3.04748 0 00-.89262 2.15491v42.665c0 .8083.3211 1.5834.89262 2.1549a3.0478 3.0478 0 002.155.8926h42.66664c.8083 0 1.5835-.3211 2.155-.8926a3.0472 3.0472 0 00.8926-2.1549V32c0-1.6831 1.3645-3.0475 3.0477-3.0475 1.6831 0 3.0476 1.3644 3.0476 3.0475v21.3325c0 2.4247-.9633 4.7502-2.6779 6.4647-1.7146 1.7146-4.0401 2.6778-6.465 2.6778H9.14287c-2.42484 0-4.75036-.9632-6.46498-2.6778-1.71462-1.7145-2.67788-4.04-2.67788-6.4647v-42.665c0-2.42474.96326-4.75017 2.67788-6.46472S6.71803 1.525 9.14287 1.525h33.52384c1.6831 0 3.0476 1.36441 3.0476 3.0475S44.34981 7.62 42.66671 7.62z" />
    </g>
  </IconBox>
);
