import { createAction } from 'lib/createAction';
import { createRequestActions } from 'lib/requestActions';
import { Auth, LoginDTO } from 'common/models/auth';
import { User } from 'common/models/user';

export const authActions = {
  logout: createAction('auth/logout')(),
  updateAuth: createAction('auth/updateAuth')<Auth>(),
} as const;

export const fetchUserActions = createRequestActions(
  'auth/fetchUser/request',
  'auth/fetchUser/requestSuccess',
  'auth/fetchUser/requestFailure'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
)<void, User, any>();

export const loginActions = createRequestActions(
  'auth/login/request',
  'auth/login/requestSuccess',
  'auth/login/requestFailure'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
)<LoginDTO, Auth, any>();
