import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Column,
  FluidForm,
  Grid,
  InlineLoading,
  Row,
  TextInput,
  Tile,
} from 'carbon-components-react';
import { miniUnits, spacing05, spacing08, spacing10 } from '@carbon/layout';
import { ArrowRight32 } from '@carbon/icons-react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { pipe } from 'fp-ts/function';
import { LoginFormModel, mapToLoginDTO } from 'common/models/auth';
import { fetchUserActions, loginActions } from 'state/auth/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { isUserSomeSelector } from 'state/auth/authSelectors';
import { useHistory } from 'react-router-dom';
import { RootRoute } from 'routes';
import { useRequestEffect } from 'lib/useRequestEffect';
import { Content } from 'common/components/Grid';

const ButtonRow = styled.div`
  display: flex;
  margin-top: ${spacing10};
`;

const Heading = styled.h4`
  margin-bottom: ${spacing08};
`;

const StyledButton = styled(Button)`
  margin-left: auto;
  width: 50%;
`;

const StyledContent = styled(Content)`
  display: flex;
  min-height: calc(100vh - ${miniUnits(6)});
`;

const StyledForm = styled(FluidForm)`
  margin: 0 -${spacing05} -${spacing05};
`;

const StyledGrid = styled(Grid)`
  display: flex;
  width: 100%;
`;

const StyledInlineLoading = styled(InlineLoading)`
  min-height: 0;
`;

const StyledRow = styled(Row)`
  align-items: center;
  flex: 1 1 auto;
`;

const formSchema = yup.object().shape({
  login: yup.string().email().required(),
  password: yup.string().required(),
});

export const Login: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormModel>({
    resolver: yupResolver(formSchema),
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector(isUserSomeSelector);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useRequestEffect(fetchUserActions.request, {
    onError: () => setIsSubmitting(false),
  });
  useRequestEffect(loginActions.request, {
    onError: () => setIsSubmitting(false),
  });

  const onSubmit = handleSubmit((values) => {
    setIsSubmitting(true);
    pipe(values, mapToLoginDTO, loginActions.request, dispatch);
  });

  useEffect(() => {
    if (isLoggedIn) {
      history.push(RootRoute);
    }
  }, [isLoggedIn]);

  return (
    <StyledContent>
      <StyledGrid>
        <StyledRow>
          <Column lg={{ span: 6, offset: 5 }}>
            <Tile>
              <Heading>Logowanie</Heading>

              <StyledForm onSubmit={onSubmit}>
                <TextInput
                  autoComplete="username"
                  id="login"
                  labelText="Email"
                  invalid={!!errors.login}
                  invalidText={errors.login?.message}
                  {...register('login')}
                />

                <TextInput
                  autoComplete="current-password"
                  id="password"
                  labelText="Hasło"
                  type="password"
                  invalid={!!errors.password}
                  invalidText={errors.password?.message}
                  {...register('password')}
                />

                <ButtonRow>
                  {isSubmitting && (
                    <StyledButton disabled size="lg">
                      <StyledInlineLoading
                        description="Loading..."
                        status="active"
                      />
                    </StyledButton>
                  )}

                  {!isSubmitting && (
                    <StyledButton
                      renderIcon={ArrowRight32}
                      size="lg"
                      type="submit"
                    >
                      Zaloguj
                    </StyledButton>
                  )}
                </ButtonRow>
              </StyledForm>
            </Tile>
          </Column>
        </StyledRow>
      </StyledGrid>
    </StyledContent>
  );
};
