import { AuthorizedAxios, throwErrorResponse } from 'common/services/axios';
import { flatten } from 'lib/axios';
import { LargeBlobDTO, LargeBlobType } from 'common/models/azure';
import { BlockBlobClient } from '@azure/storage-blob';
import { AbortError, AbortSignalLike } from '@azure/abort-controller';
import { CancelToken } from 'axios';
import { UploadProgress } from 'common/models/fileUploader';
import { ErrorCode } from 'common/models/error';
import { isAbortError } from 'common/services/error';
import { ElearningLessonUploadToAzureDTO } from 'common/models/elearningLesson';

export const createBlob = (type: LargeBlobType, cancelToken: CancelToken) =>
  AuthorizedAxios.post<LargeBlobDTO>(
    '/api/blobs',
    {
      type,
    },
    {
      cancelToken,
    }
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const uploadFileToAzure = async (
  params: ElearningLessonUploadToAzureDTO,
  onUploadProgress: (progress: UploadProgress) => void,
  abortSignal: AbortSignalLike
) => {
  try {
    const blockBlob = new BlockBlobClient(params.url);
    await blockBlob.uploadData(params.file, {
      abortSignal: abortSignal,
      onProgress: (event) => {
        onUploadProgress({
          loaded: event.loadedBytes,
          total: params.file.size,
        });
      },
    });
    await blockBlob.setHTTPHeaders({ blobContentType: 'video/mp4' });
  } catch (error) {
    if (isAbortError(error)) {
      throwErrorResponse(new AbortError(ErrorCode.UploadCanceled));
    }

    throwErrorResponse(error);
  }
};
