import { createReducer } from '@reduxjs/toolkit';
import { PaginationState, SortDirection } from 'common/models/pagination';
import { none, Option, some } from 'fp-ts/lib/Option';
import { courseBundleElearningListActions } from 'state/courseBundleContent/elearningList/courseBundleElearningListActions';
import {
  CourseBundleElearningListColumn,
  CourseBundleElearningListSortData,
} from 'common/models/courseBundleContent';

export interface CourseBundleElearningListState {
  courseBundleId: string;
  categoryId?: string;
  category: Option<string>;
  pagination: Option<PaginationState>;
  sort: CourseBundleElearningListSortData;
}

export const defaultSort: CourseBundleElearningListSortData = {
  direction: SortDirection.Asc,
  orderBy: CourseBundleElearningListColumn.Title,
};

const initialState: CourseBundleElearningListState = {
  courseBundleId: '',
  category: none,
  pagination: none,
  sort: defaultSort,
};

export const courseBundleElearningListReducer =
  createReducer<CourseBundleElearningListState>(initialState, (builder) =>
    builder
      .addCase(courseBundleElearningListActions.clear, () => initialState)
      .addCase(
        courseBundleElearningListActions.setCourseBundleId,
        (state, action) => {
          state.courseBundleId = action.payload || '';
        }
      )
      .addCase(
        courseBundleElearningListActions.setCategory,
        (state, action) => {
          state.category = action.payload ? some(action.payload.id) : none;
        }
      )
      .addCase(
        courseBundleElearningListActions.setCategoryId,
        (state, action) => {
          state.category = action.payload ? some(action.payload) : none;
        }
      )
      .addCase(courseBundleElearningListActions.setFilters, (state, action) => {
        state.courseBundleId = action.payload.courseBundleId || '';
        state.category = action.payload.categoryId
          ? some(action.payload.categoryId)
          : none;
        state.pagination = some(action.payload.pagination);
        state.sort = action.payload.sort;
      })
      .addCase(
        courseBundleElearningListActions.setPagination,
        (state, action) => {
          state.pagination = some(action.payload);
        }
      )
      .addCase(courseBundleElearningListActions.setSort, (state, action) => {
        state.sort = action.payload;
      })
  );
