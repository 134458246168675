import styled from 'styled-components';
import { g10 } from '@carbon/themes';
import { label01 } from '@carbon/type';
import React, { FC } from 'react';
import { StyleProps } from 'lib/styleProps';

const Container = styled.div`
  color: ${g10.danger02};
  ${label01}
`;

export const ErrorBlock: FC<StyleProps> = (props) => <Container {...props} />;
