import { useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { RootRoute } from 'routes';
import { push } from 'connected-react-router';
import { snackbarActions } from 'state/notification/notificationActions';
import { useDispatch } from 'react-redux';
import { getAboutUsStaticPage } from 'modules/static-pages/services/staticPage';
import { AboutUsStaticPage } from 'modules/static-pages/models/aboutUsStaticPage';

export const useAboutUsStaticPageQuery = (
  onSuccess: (data: AboutUsStaticPage) => void
) => {
  const dispatch = useDispatch();

  return useQuery([QueryKey.AboutUsStaticPage], () => getAboutUsStaticPage(), {
    onError: () => {
      dispatch(push(RootRoute));
      dispatch(
        snackbarActions.enqueueSnackbar({
          title: 'Wybrana strona statyczna nie została znaleziona',
          options: { variant: 'error' },
        })
      );
    },
    onSuccess,
  });
};
