import { FC } from 'react';
import styled from 'styled-components';
import { environment } from 'config/environment';
import { code01 } from '@carbon/type';

const Version = styled.div`
  bottom: 0.3125rem;
  pointer-events: none;
  position: fixed;
  right: 0.3125rem;
  z-index: 7000;

  ${code01}
`;

export const AppVersion: FC = () => {
  return <Version>v.{environment.appVersion}</Version>;
};
