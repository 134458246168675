import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Column,
  ComboBox,
  Form,
  InlineLoading,
  Row,
  TextAreaSkeleton,
  TextInput,
  TextInputSkeleton,
  Toggle,
  ToggleSkeleton,
} from 'carbon-components-react';
import { FormItem } from 'app/components/FormItem';
import {
  Controller,
  FormProvider,
  useForm,
  useFormState,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { forEachObjIndexed, mergeAll } from 'ramda';
import { ListItem } from 'common/models/course';
import {
  categoryCodeItems,
  CategoryFormModel,
  initialCategoryFormValues,
} from 'common/models/category';
import { useScrollToError } from 'common/hooks/useScrollToError';
import { setYupDefaultMessages } from 'common/services/yup';
import {
  categoryFormValidationSchema,
  fieldsOrder,
} from 'app/components/CategoryForm/config';
import { ImageControl } from 'app/components/ImageControl';
import { courseCategoryIconMap } from 'config/icons';
import EmptyIcon from 'assets/icons/EmptyIcon';
import styled from 'styled-components';
import slug from 'slug';

const Icon = styled.svg`
  margin-right: 0.75rem;
`;

setYupDefaultMessages();

export interface CategoryFormProps {
  formValues?: CategoryFormModel;
  isCreate: boolean;
  isCategoryLoading?: boolean;
  isSubmitting?: boolean;
  onSubmit: (value: CategoryFormModel) => void;
}

export const CategoryForm: FC<CategoryFormProps> = ({
  formValues,
  isCreate,
  isSubmitting,
  isCategoryLoading,
  onSubmit,
}) => {
  const [loaded, setLoaded] = useState(isCreate);

  const methods = useForm<CategoryFormModel>({
    defaultValues: initialCategoryFormValues,
    resolver: yupResolver(categoryFormValidationSchema),
    shouldFocusError: false,
  });

  const onError = useScrollToError(fieldsOrder);

  useEffect(() => {
    let formModel = initialCategoryFormValues;

    if (formValues) {
      formModel = mergeAll([initialCategoryFormValues, formValues]);
    }

    forEachObjIndexed((value, key) => {
      methods.setValue(key, value);
    }, formModel);

    formValues && setLoaded(true);
  }, [formValues]);

  const isLoading = isCategoryLoading || !loaded;

  const watchUsesSpecialCertificateTemplate = methods.watch(
    'usesSpecialCertificateTemplate',
    false
  );

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit, onError)} noValidate>
        <Row>
          <Column lg={8}>
            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <>
                  <Controller
                    control={methods.control}
                    name="label"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      const { dirtyFields } = useFormState({
                        control: methods.control,
                        name: 'slug',
                      });

                      return (
                        <TextInput
                          id="label"
                          invalid={error != null}
                          invalidText={error?.message}
                          onChange={(event) => {
                            onChange(event);

                            if (!formValues?.slug && !dirtyFields.slug) {
                              methods.setValue(
                                'slug',
                                slug(event.target.value),
                                {
                                  shouldValidate: true,
                                }
                              );
                            }
                          }}
                          labelText="Nazwa"
                          value={value}
                        />
                      );
                    }}
                  />
                </>
              )}
            </FormItem>

            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <TextInput
                  id="slug"
                  invalid={methods.formState.errors.slug != null}
                  invalidText={methods.formState.errors.slug?.message}
                  labelText="Slug"
                  {...methods.register('slug')}
                />
              )}
            </FormItem>
          </Column>

          <Column lg={4}>
            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="code"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <ComboBox<ListItem>
                      id="code"
                      invalid={!!error}
                      invalidText={error?.message}
                      items={categoryCodeItems}
                      itemToElement={(item) =>
                        item && (
                          <>
                            <Icon
                              as={
                                courseCategoryIconMap[item.label] ?? EmptyIcon
                              }
                              size={16}
                            />
                            {item?.label}
                          </>
                        )
                      }
                      itemToString={(item) => item?.label || ''}
                      onChange={(value) => {
                        onChange(value.selectedItem?.id);
                      }}
                      placeholder=""
                      selectedItem={
                        categoryCodeItems.find((item) => item.id === value) ??
                        null
                      }
                      titleText="Ikona"
                    />
                  )}
                />
              )}
            </FormItem>
          </Column>
        </Row>

        <Row>
          <Column lg={12}>
            <FormItem>
              {isLoading ? (
                <ToggleSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="usesSpecialCertificateTemplate"
                  render={({ field: { onChange, value } }) => (
                    <Toggle
                      id="usesSpecialCertificateTemplate"
                      labelA="Użyj standardowego szablonu certyfikatu"
                      labelB="Użyj szablonu dla kategorii specjalnej"
                      labelText=""
                      onToggle={(checked) => onChange(checked)}
                      toggled={value || false}
                    />
                  )}
                />
              )}
            </FormItem>
          </Column>
        </Row>

        {watchUsesSpecialCertificateTemplate && (
          <Row>
            <Column lg={8}>
              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <TextInput
                    id="partnerName"
                    invalid={methods.formState.errors.partnerName != null}
                    invalidText={methods.formState.errors.partnerName?.message}
                    labelText="Nazwa partnera"
                    {...methods.register('partnerName')}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <TextInput
                    id="programName"
                    invalid={methods.formState.errors.programName != null}
                    invalidText={methods.formState.errors.programName?.message}
                    labelText="Nazwa programu"
                    {...methods.register('programName')}
                  />
                )}
              </FormItem>
            </Column>

            <Column lg={4}>
              <FormItem>
                {isLoading ? (
                  <TextAreaSkeleton />
                ) : (
                  <Controller
                    control={methods.control}
                    name="partnerLogoFile"
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <ImageControl
                        currentImage={formValues?.currentPartnerLogo}
                        id="partnerLogoFile"
                        invalid={!!error}
                        invalidText={error?.message}
                        label="Logotyp partnera"
                        onChange={(file) => {
                          onChange(file);
                        }}
                      />
                    )}
                  />
                )}
              </FormItem>
            </Column>
          </Row>
        )}

        {isSubmitting ? (
          <Button disabled kind="primary" tabIndex={-1}>
            <InlineLoading description={'Zapisywanie...'} status="active" />
          </Button>
        ) : (
          <Button kind="primary" tabIndex={0} type="submit">
            {formValues == null ? 'Utwórz' : 'Zapisz zmiany'}
          </Button>
        )}
      </Form>
    </FormProvider>
  );
};
