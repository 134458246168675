import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';
import { PartnerUsersState } from 'state/partnerUsers/partnerUsersReducer';

export const partnerUsersStateSelector = (state: RootState) =>
  state.partnerUsers;

export const partnerUsersPaginationSelector = createSelector(
  partnerUsersStateSelector,
  (state: PartnerUsersState) => state.pagination
);

export const partnerUsersPartnerIdSelector = createSelector(
  partnerUsersStateSelector,
  (state) => state.partnerId
);
