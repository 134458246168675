import {
  DataTableCustomRenderProps,
  DenormalizedRow,
  Loading,
  OverflowMenu,
  OverflowMenuItem,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import { useSelector } from 'react-redux';
import { courseEventParticipantEventIdSelector } from 'state/courseEventParticipant/courseEventParticipantSelectors';
import { useCourseEventParticipantStatusMutation } from 'common/hooks/courseEventParticipant/useCourseEventParticipantStatusMutation';
import { CourseEventParticipantEventStatus } from 'common/models/courseEventParticipant';
import { useCourseEventParticipantPresenceMutation } from 'common/hooks/courseEventParticipant/useCourseEventParticipantPresenceMutation';
import { CourseEvent } from 'common/models/courseEvent';
import {
  canChangeParticipantPresence,
  canChangeParticipantStatus,
} from 'common/services/courseEventParticipant';
import { ActionsTableCell } from 'app/components/Table';
import { BlockLoadingButton } from 'app/components/Button';
import { FC } from 'react';

export interface CourseEventParticipantListDataTableContentProps {
  event?: CourseEvent;
  row: DenormalizedRow;
}

export const CourseEventParticipantListDataTableRow: FC<
  CourseEventParticipantListDataTableContentProps &
    Pick<DataTableCustomRenderProps, 'getRowProps'>
> = ({ event, getRowProps, row }) => {
  const eventId = useSelector(courseEventParticipantEventIdSelector);

  const absentParticipantAbsentMutation =
    useCourseEventParticipantPresenceMutation(
      eventId,
      CourseEventParticipantEventStatus.Absent
    );
  const presentParticipantPresenceMutation =
    useCourseEventParticipantPresenceMutation(
      eventId,
      CourseEventParticipantEventStatus.Present
    );

  const acceptParticipantStatusMutation =
    useCourseEventParticipantStatusMutation(
      eventId,
      CourseEventParticipantEventStatus.Accepted
    );
  const rejectParticipantStatusMutation =
    useCourseEventParticipantStatusMutation(
      eventId,
      CourseEventParticipantEventStatus.Rejected
    );

  const isMutationLoading =
    absentParticipantAbsentMutation.isLoading ||
    presentParticipantPresenceMutation.isLoading ||
    acceptParticipantStatusMutation.isLoading ||
    rejectParticipantStatusMutation.isLoading;

  return (
    <TableRow {...getRowProps({ row })}>
      {row.cells.map((cell) => (
        <TableCell key={cell.id}>{cell.value}</TableCell>
      ))}

      <ActionsTableCell>
        {isMutationLoading && (
          <BlockLoadingButton disabled hasIconOnly kind="ghost" size="small">
            <Loading small withOverlay={false} />
          </BlockLoadingButton>
        )}

        {!isMutationLoading && (
          <OverflowMenu size="sm" light flipped>
            {canChangeParticipantStatus(event) &&
              [CourseEventParticipantEventStatus.Pending].includes(
                row.cells[4].value
              ) && (
                <OverflowMenuItem
                  itemText="Zaakceptuj"
                  onClick={() => acceptParticipantStatusMutation.mutate(row.id)}
                />
              )}

            {canChangeParticipantPresence(event) &&
              [
                CourseEventParticipantEventStatus.Accepted,
                CourseEventParticipantEventStatus.Absent,
              ].includes(row.cells[4].value) && (
                <OverflowMenuItem
                  itemText="Obecny"
                  onClick={() =>
                    presentParticipantPresenceMutation.mutate(row.id)
                  }
                />
              )}

            {canChangeParticipantPresence(event) &&
              [
                CourseEventParticipantEventStatus.Accepted,
                CourseEventParticipantEventStatus.Present,
              ].includes(row.cells[4].value) && (
                <OverflowMenuItem
                  isDelete
                  itemText="Nieobecny"
                  onClick={() => absentParticipantAbsentMutation.mutate(row.id)}
                />
              )}

            {canChangeParticipantStatus(event) &&
              [
                CourseEventParticipantEventStatus.Pending,
                CourseEventParticipantEventStatus.Accepted,
              ].includes(row.cells[4].value) && (
                <OverflowMenuItem
                  hasDivider={
                    row.cells[4].value ===
                    CourseEventParticipantEventStatus.Accepted
                  }
                  isDelete
                  itemText="Odrzuć"
                  onClick={() => rejectParticipantStatusMutation.mutate(row.id)}
                />
              )}
          </OverflowMenu>
        )}
      </ActionsTableCell>
    </TableRow>
  );
};
