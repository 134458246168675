import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';
import { SurveyState } from 'state/survey/surveyReducer';

export const surveyStateSelector = (state: RootState) => state.survey;

export const surveyPaginationSelector = createSelector(
  surveyStateSelector,
  (state: SurveyState) => state.pagination
);
