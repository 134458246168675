import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { stringify } from 'qs';
import { pipe } from 'fp-ts/function';
import { getOrElse, Option } from 'fp-ts/lib/Option';
import {
  initialPagination,
  PaginationState,
  SortDirection,
} from 'common/models/pagination';
import { CourseListRoute } from 'routes';
import {
  coursePaginationSelector,
  courseSortSelector,
  courseSpeakerSelector,
} from 'state/course/courseSelectors';
import { courseActions } from 'state/course/courseActions';
import { filter, fromNullable, isSome } from 'fp-ts/Option';
import { mapObjIndexed, mergeLeft } from 'ramda';
import {
  CourseListColumn,
  courseListColumnSettings,
  CourseSortData,
} from 'common/models/course';
import { getQueryParamsMap } from 'lib/getQueryParams';
import { isSomeEnum, isString } from 'lib/typeGuards';
import { defaultSort } from 'state/course/courseReducer';

function* writeToURLQuery() {
  const filterSpeakerOption: Option<string> = yield select(
    courseSpeakerSelector
  );
  const pagination: Option<PaginationState> = yield select(
    coursePaginationSelector
  );
  const sortData: CourseSortData = yield select(courseSortSelector);

  const filterSpeaker = pipe(
    filterSpeakerOption,
    getOrElse(() => 'undefined')
  );

  const qs = pipe(
    pagination,
    getOrElse(() => initialPagination),
    (item) =>
      isSome(filterSpeakerOption)
        ? {
            ...item,
            speaker: filterSpeaker,
          }
        : item,
    mergeLeft(sortData),
    stringify
  );

  yield put(
    push(`${CourseListRoute}?${qs}`, {
      replace: true,
    })
  );
}

function* readFromURLQuery() {
  const qp = getQueryParamsMap();

  const pagination = pipe(
    initialPagination,
    mapObjIndexed((value, key) =>
      pipe(
        fromNullable(qp[key]),
        filter(isString),
        getOrElse(() => value.toString()),
        Number
      )
    )
  );

  const sortData: CourseSortData = {
    direction: pipe(
      fromNullable(qp['direction']),
      filter(isSomeEnum(SortDirection)),
      getOrElse(() => defaultSort.direction)
    ),
    orderBy: pipe(
      fromNullable(qp['orderBy']),
      filter(isSomeEnum(CourseListColumn)),
      filter((column) => courseListColumnSettings[column].sortable),
      getOrElse(() => defaultSort.orderBy)
    ),
  };

  yield put(
    courseActions.setFilters({
      pagination: pagination,
      sort: sortData,
      speakerId: qp.speaker ? String(qp.speaker) : undefined,
    })
  );
}

function* readFromURLQueryWatcher() {
  yield takeEvery(courseActions.loadPagination.type, readFromURLQuery);
}

function* writeToURLQueryWatcher() {
  yield takeLatest(courseActions.setFilters.type, writeToURLQuery);
  yield takeLatest(courseActions.setPagination.type, writeToURLQuery);
  yield takeLatest(courseActions.setSort.type, writeToURLQuery);
  yield takeLatest(courseActions.setSpeaker.type, writeToURLQuery);
  yield takeLatest(courseActions.setSpeakerId.type, writeToURLQuery);
}

export function* courseSaga() {
  yield all([writeToURLQueryWatcher(), readFromURLQueryWatcher()]);
}
