import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { stringify } from 'qs';
import { pipe } from 'fp-ts/function';
import { filter, fromNullable, getOrElse, Option } from 'fp-ts/lib/Option';
import {
  initialPagination,
  PaginationState,
  SortDirection,
} from 'common/models/pagination';
import { CourseBundleListRoute } from 'routes';
import { mapObjIndexed, mergeLeft } from 'ramda';
import { getQueryParamsMap } from 'lib/getQueryParams';
import { isSomeEnum, isString } from 'lib/typeGuards';
import {
  CourseBundleListColumn,
  courseBundleListColumnSettings,
  CourseBundleSortData,
} from 'common/models/courseBundle';
import { courseBundleActions } from 'state/courseBundle/courseBundleActions';
import {
  courseBundlePaginationSelector,
  courseBundleSortSelector,
} from 'state/courseBundle/courseBundleSelectors';
import { defaultSort } from 'state/courseBundle/courseBundleReducer';

function* writeToURLQuery() {
  const pagination: Option<PaginationState> = yield select(
    courseBundlePaginationSelector
  );
  const sortData: CourseBundleSortData = yield select(courseBundleSortSelector);

  const qs = pipe(
    pagination,
    getOrElse(() => initialPagination),
    mergeLeft(sortData),
    stringify
  );

  yield put(
    push(`${CourseBundleListRoute}?${qs}`, {
      replace: true,
    })
  );
}

function* readFromURLQuery() {
  const qp = getQueryParamsMap();

  const pagination = pipe(
    initialPagination,
    mapObjIndexed((value, key) =>
      pipe(
        fromNullable(qp[key]),
        filter(isString),
        getOrElse(() => value.toString()),
        Number
      )
    )
  );

  const sortData: CourseBundleSortData = {
    direction: pipe(
      fromNullable(qp['direction']),
      filter(isSomeEnum(SortDirection)),
      getOrElse(() => defaultSort.direction)
    ),
    orderBy: pipe(
      fromNullable(qp['orderBy']),
      filter(isSomeEnum(CourseBundleListColumn)),
      filter((column) => courseBundleListColumnSettings[column].sortable),
      getOrElse(() => defaultSort.orderBy)
    ),
  };

  yield put(
    courseBundleActions.setFilters({
      pagination: pagination,
      sort: sortData,
    })
  );
}

function* readFromURLQueryWatcher() {
  yield takeEvery(courseBundleActions.loadPagination.type, readFromURLQuery);
}

function* writeToURLQueryWatcher() {
  yield takeLatest(courseBundleActions.setFilters.type, writeToURLQuery);
  yield takeLatest(courseBundleActions.setPagination.type, writeToURLQuery);
  yield takeLatest(courseBundleActions.setSort.type, writeToURLQuery);
}

export function* courseBundleSaga() {
  yield all([writeToURLQueryWatcher(), readFromURLQueryWatcher()]);
}
