import { createReducer } from '@reduxjs/toolkit';
import { elearningLessonActions } from 'state/elearningLesson/elearningLessonActions';

export interface ElearningLessonState {
  elearningId: string;
}

const initialState: ElearningLessonState = {
  elearningId: '',
};

export const elearningLessonReducer = createReducer<ElearningLessonState>(
  initialState,
  (builder) =>
    builder.addCase(elearningLessonActions.setElearningId, (state, action) => {
      state.elearningId = action.payload || '';
    })
);
