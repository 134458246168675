import {
  Button,
  ComboBox,
  DataTableCustomRenderProps,
  DenormalizedRow,
  Loading,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react';
import { Add20 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { getCourseEventFormCreateRoute } from 'routes';
import {
  courseEventCourseIdSelector,
  courseEventSortSelector,
  courseEventStatusSelector,
} from 'state/courseEvent/courseEventSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { CourseEventListDataTableRow } from 'app/components/CourseEventList/CourseEventListDataTableRow';
import {
  Table,
  TableContainer,
  TableContent,
  TableLoader,
} from 'app/components/Table';
import React, { FC, useMemo } from 'react';
import { isEmpty } from 'ramda';
import { DataTableEmptyRow } from 'app/components/DataTableEmptyRow';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/Option';
import { ListFilters } from 'app/components/ListFilters';
import {
  CourseEventListColumn,
  CourseEventListItem,
  courseEventStatusList,
  CourseEventStatusListItem,
} from 'common/models/courseEvent';
import { courseEventActions } from 'state/courseEvent/courseEventActions';
import { DataTableHeader } from 'app/components/DataTableHeader';

export interface CourseEventListDataTableContentProps {
  courseEventListItems?: CourseEventListItem[];
  isFetching?: boolean;
  onCloseSuccess?: (row: DenormalizedRow) => void;
  onDeleteSuccess?: (row: DenormalizedRow) => void;
  onStartSuccess?: (row: DenormalizedRow) => void;
}

export const CourseEventListDataTableContent: FC<
  CourseEventListDataTableContentProps & DataTableCustomRenderProps
> = ({
  courseEventListItems,
  isFetching = false,
  onCloseSuccess,
  onDeleteSuccess,
  onStartSuccess,

  rows,
  headers,
  getBatchActionProps,
  getSelectionProps,
  getTableProps,
  getTableContainerProps,
  getToolbarProps,
  getRowProps,
}) => {
  const dispatch = useDispatch();

  const courseId = useSelector(courseEventCourseIdSelector);
  const eventStatusOption = useSelector(courseEventStatusSelector);
  const sortData = useSelector(courseEventSortSelector);

  const eventStatus = useMemo(
    () =>
      pipe(
        eventStatusOption,
        getOrElse(() => 'undefined')
      ),
    [eventStatusOption]
  );

  return (
    <TableContainer title="Wydarzenia" {...getTableContainerProps()}>
      <TableToolbar {...getToolbarProps()}>
        <TableToolbarContent>
          <ListFilters>
            <ComboBox<CourseEventStatusListItem>
              id="filterStatus"
              initialSelectedItem={courseEventStatusList.find(
                (item) => item.id === eventStatus
              )}
              items={courseEventStatusList}
              itemToString={(item) => (item ? item.label : '')}
              light={true}
              onChange={(value) => {
                dispatch(courseEventActions.setStatus(value.selectedItem?.id));
              }}
              placeholder="Wybierz status.."
            />
          </ListFilters>

          <Button
            as={Link}
            kind="primary"
            renderIcon={Add20}
            size="small"
            tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
            to={getCourseEventFormCreateRoute(courseId)}
          >
            Dodaj nowe wydarzenie
          </Button>
        </TableToolbarContent>
      </TableToolbar>

      <TableContent>
        <Table $loading={isFetching} {...getTableProps()}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <DataTableHeader
                  isSortable={true}
                  isSortHeader={sortData.orderBy === header.key}
                  sortDirection={sortData.direction}
                  onClick={(direction) => {
                    dispatch(
                      courseEventActions.setSort({
                        direction,
                        orderBy: header.key as CourseEventListColumn,
                      })
                    );
                  }}
                  key={header.key}
                >
                  {header.header}
                </DataTableHeader>
              ))}

              <TableHeader />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(rows) &&
              rows.map((row, index) => (
                // eslint-disable-next-line react/jsx-key
                <CourseEventListDataTableRow
                  courseEventListItem={courseEventListItems?.[index]}
                  getRowProps={getRowProps}
                  getSelectionProps={getSelectionProps}
                  key={row.id}
                  onCloseSuccess={onCloseSuccess}
                  onDeleteSuccess={onDeleteSuccess}
                  onStartSuccess={onStartSuccess}
                  row={row}
                />
              ))}

            {isEmpty(rows) && <DataTableEmptyRow colSpan={3} />}
          </TableBody>
        </Table>

        {isFetching && (
          <TableLoader>
            <Loading active={true} withOverlay={false} />
          </TableLoader>
        )}
      </TableContent>
    </TableContainer>
  );
};
