import { createAction } from 'lib/createAction';
import { PaginationState } from 'common/models/pagination';
import { CourseEventStatus } from 'common/models/courseEvent';
import { CourseBundleEventListSortData } from 'common/models/courseBundleContent';

export interface CourseBundleEventListFilters {
  courseId?: string;
  courseBundleId?: string;
  pagination: PaginationState;
  sort: CourseBundleEventListSortData;
  status?: CourseEventStatus;
}

export const courseBundleEventListActions = {
  clear: createAction('courseBundleEventList/clear')(),
  loadPagination: createAction('courseBundleEventList/loadPagination')(),
  setCourseId: createAction('courseBundleEventList/setCourseId')<
    string | undefined
  >(),
  setCourseBundleId: createAction('courseBundleEventList/setCourseBundleId')<
    string | undefined
  >(),
  setFilters: createAction(
    'courseBundleEventList/setFilters'
  )<CourseBundleEventListFilters>(),
  setPagination: createAction(
    'courseBundleEventList/setPagination'
  )<PaginationState>(),
  setSort: createAction(
    'courseBundleEventList/setSort'
  )<CourseBundleEventListSortData>(),
  setStatus: createAction('courseBundleEventList/setStatus')<
    CourseEventStatus | null | undefined
  >(),
} as const;
