import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { QueryKey } from 'config/queryKey';
import { isNilEmpty } from 'lib/isNilEmpty';
import { isCreateParam, SurveyListRoute } from 'routes';
import { push } from 'connected-react-router';
import { snackbarActions } from 'state/notification/notificationActions';
import { getSurveyById } from 'common/services/survey';

export function useSurveyByIdQuery(id: string) {
  const dispatch = useDispatch();

  return useQuery([QueryKey.Survey, id], () => getSurveyById(id), {
    enabled: !isNilEmpty(id) && !isCreateParam(id),
    onError: () => {
      dispatch(push(SurveyListRoute));
      dispatch(
        snackbarActions.enqueueSnackbar({
          title: 'Ankieta nie została znaleziona',
          options: { variant: 'error' },
        })
      );
    },
  });
}
