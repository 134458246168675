import { FC } from 'react';
import { AppRouter } from 'app/AppRouter';
import { AppHeader } from 'app/components/AppHeader';
import { useSelector } from 'react-redux';
import {
  isAuthReadySelector,
  isUserSomeSelector,
} from 'state/auth/authSelectors';
import { Loading } from 'carbon-components-react';
import { AppVersion } from 'app/AppVersion';
import { FileUploaderDrawer } from 'app/components/FileUploaderDrawer';

export const AppLayout: FC = () => {
  const isAuthReady = useSelector(isAuthReadySelector);
  const isLoggedIn = useSelector(isUserSomeSelector);

  return (
    <>
      {!isAuthReady && <Loading active />}

      {isAuthReady && (
        <>
          {isLoggedIn && <AppHeader />}
          <AppRouter />
          <AppVersion />
          {isLoggedIn && <FileUploaderDrawer />}
        </>
      )}
    </>
  );
};
