import { AuthorizedAxios, throwErrorResponse } from 'common/services/axios';
import { flatten } from 'lib/axios';
import { ResultResponse } from 'common/models/response';
import { PaginationParams } from 'common/models/pagination';
import { Speaker, SpeakerRequestDTO } from 'common/models/speaker';

const prepareFormData = (params: SpeakerRequestDTO) => {
  const formData = new FormData();

  formData.set(
    'speaker',
    new Blob([JSON.stringify(params.speaker)], {
      type: 'application/json',
    })
  );

  if (params.photo) {
    formData.set('photo', params.photo);
  }

  return formData;
};

export const createSpeaker = (params: SpeakerRequestDTO) =>
  AuthorizedAxios.post<Speaker>(`/api/speakers`, prepareFormData(params), {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(flatten)
    .catch(throwErrorResponse);

export const deleteSpeaker = (id: string) =>
  AuthorizedAxios.delete<null>(`/api/speakers/${id}`).catch(throwErrorResponse);

export const getSpeakerById = (id: string) =>
  AuthorizedAxios.get<Speaker>(`/api/speakers/${id}`)
    .then(flatten)
    .catch(throwErrorResponse);

export const getSpeakerList = ({ page, pageSize }: PaginationParams) =>
  AuthorizedAxios.get<ResultResponse<Speaker>>('/api/speakers', {
    params: { page, pageSize, speakerSortType: 'NAME' },
  })
    .then(flatten)
    .catch(throwErrorResponse);

export const updateSpeakerById = (id: string, params: SpeakerRequestDTO) =>
  AuthorizedAxios.put<Speaker>(`/api/speakers/${id}`, prepareFormData(params), {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(flatten)
    .catch(throwErrorResponse);
