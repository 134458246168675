import * as yup from 'yup';

export const formSchema = yup.object({
  boardMemberName: yup.string().max(100).required(),
  certificationNumber: yup.string().max(100).required(),
});

export const fieldsOrder = [
  'boardMemberName',
  'boardMemberSignature',
  'certificationNumber',
  'isoLogo',
];
