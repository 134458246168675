import { useQuery } from 'react-query';
import { getSpeakerById } from 'common/services/speaker';
import { QueryKey } from 'config/queryKey';
import { Speaker } from 'common/models/speaker';
import { isCreateParam, SpeakerListRoute } from 'routes';
import { push } from 'connected-react-router';
import { snackbarActions } from 'state/notification/notificationActions';
import { useDispatch } from 'react-redux';

export const useSpeakerByIdQuery = (
  id: string,
  onSuccess: (data: Speaker) => void
) => {
  const dispatch = useDispatch();

  return useQuery([QueryKey.Speaker, id], () => getSpeakerById(id), {
    enabled: !isCreateParam(id),
    onError: () => {
      dispatch(push(SpeakerListRoute));
      dispatch(
        snackbarActions.enqueueSnackbar({
          title: 'Wykładowca nie został znaleziony',
          options: { variant: 'error' },
        })
      );
    },
    onSuccess,
  });
};
