import React, { FC, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Content } from 'common/components/Grid';
import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  Grid,
  Row,
  SkeletonText,
} from 'carbon-components-react';
import { CourseBundleListRoute, RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { CourseBundleForm } from 'app/components/CourseBundleForm';
import { useCourseBundleByIdQuery } from 'common/hooks/courseBundle/useCourseBundleByIdQuery';
import { useCreateCourseBundleMutation } from 'common/hooks/courseBundle/useCreateCourseBundleMutation';
import { useUpdateCourseBundleMutation } from 'common/hooks/courseBundle/useUpdateCourseBundleMutation';
import {
  mapToCourseBundleCreateDTO,
  mapToCourseBundleFormModel,
  mapToCourseBundleUpdateDTO,
} from 'common/models/courseBundle';

export interface CourseBundleFormPageParams {
  courseBundleId: string;
}

export const CourseBundleFormPage: FC<{ create?: boolean }> = ({ create }) => {
  const { courseBundleId } = useParams<CourseBundleFormPageParams>();

  const createMutation = useCreateCourseBundleMutation();
  const updateMutation = useUpdateCourseBundleMutation(courseBundleId);

  const { data: courseBundleData, isFetching } = useCourseBundleByIdQuery(
    courseBundleId,
    !create && !!courseBundleId
  );

  const formValues = useMemo(
    () => courseBundleData && mapToCourseBundleFormModel(courseBundleData),
    [courseBundleData]
  );

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={CourseBundleListRoute}>Lista kursów</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        {create && <PageHeading>Nowy kurs</PageHeading>}

        {!create && (
          <PageHeading>
            {isFetching ? (
              <SkeletonText heading={true} width="300px" />
            ) : (
              `${courseBundleData?.title}`
            )}
          </PageHeading>
        )}

        <Row>
          <Column lg={18} xlg={14}>
            {create && (
              <CourseBundleForm
                onSubmit={(data) => {
                  createMutation.mutate(mapToCourseBundleCreateDTO(data));
                }}
                isCreate={true}
                isSubmitting={createMutation.isLoading}
              />
            )}
            {!create && (
              <CourseBundleForm
                onSubmit={(data) => {
                  updateMutation.mutate(mapToCourseBundleUpdateDTO(data));
                }}
                formValues={courseBundleData ? formValues : undefined}
                isCreate={false}
                isCourseBundleDataLoading={isFetching}
                isSubmitting={updateMutation.isLoading}
              />
            )}
          </Column>
        </Row>
      </Grid>
    </Content>
  );
};
