import { FC, useCallback } from 'react';
import { DataTableButton } from 'app/components/DataTableButton';
import { Edit20 } from '@carbon/icons-react';
import { Material, MaterialType } from 'common/models/material';
import { materialsActions } from 'state/materials/materialsActions';
import { useDispatch } from 'react-redux';

export interface CourseMaterialsEditButtonProps {
  material: Material;
}

export const MaterialsEditButton: FC<CourseMaterialsEditButtonProps> = ({
  material,
}) => {
  const dispatch = useDispatch();

  const handleEdit = useCallback(() => {
    material.type === MaterialType.Blob
      ? dispatch(
          materialsActions.showFileFormModal({
            material: material,
          })
        )
      : dispatch(
          materialsActions.showUrlFormModal({
            material: material,
          })
        );
  }, [material]);

  return (
    <DataTableButton
      hasIconOnly
      iconDescription="Edytuj"
      isLoading={false}
      kind="ghost"
      onClick={handleEdit}
      renderIcon={Edit20}
      size="small"
      tooltipAlignment="center"
      tooltipPosition="top"
    />
  );
};
