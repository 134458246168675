import { createReducer } from '@reduxjs/toolkit';
import { PaginationState, SortDirection } from 'common/models/pagination';
import { none, Option, some } from 'fp-ts/lib/Option';
import { courseEventParticipantActions } from 'state/courseEventParticipant/courseEventParticipantActions';
import {
  CourseEventParticipantListColumn,
  CourseEventParticipantSortData,
} from 'common/models/courseEventParticipant';

export interface CourseEventParticipantState {
  courseId: string;
  eventId: string;
  pagination: Option<PaginationState>;
  sort: CourseEventParticipantSortData;
}

export const defaultSort: CourseEventParticipantSortData = {
  direction: SortDirection.Desc,
  orderBy: CourseEventParticipantListColumn.EnrollDateTime,
};

const initialState: CourseEventParticipantState = {
  courseId: '',
  eventId: '',
  pagination: none,
  sort: defaultSort,
};

export const courseEventParticipantReducer =
  createReducer<CourseEventParticipantState>(initialState, (builder) =>
    builder
      .addCase(courseEventParticipantActions.clear, () => initialState)
      .addCase(courseEventParticipantActions.setCourseId, (state, action) => {
        state.courseId = action.payload || '';
      })
      .addCase(courseEventParticipantActions.setEventId, (state, action) => {
        state.eventId = action.payload || '';
      })
      .addCase(courseEventParticipantActions.setFilters, (state, action) => {
        state.courseId = action.payload.courseId || '';
        state.eventId = action.payload.eventId || '';
        state.pagination = some(action.payload.pagination);
        state.sort = action.payload.sort;
      })
      .addCase(courseEventParticipantActions.setPagination, (state, action) => {
        state.pagination = some(action.payload);
      })
      .addCase(courseEventParticipantActions.setSort, (state, action) => {
        state.sort = action.payload;
      })
  );
