import { useMutation } from 'react-query';
import { queryClient } from 'common/providers/ReactQueryClientProvider';
import { QueryKey } from 'config/queryKey';
import { deleteElearningLesson } from 'common/services/elearningLesson';

export const useDeleteElearningLessonMutation = () =>
  useMutation(deleteElearningLesson, {
    onSuccess: () =>
      queryClient.invalidateQueries(QueryKey.ElearningLessonList),
  });
