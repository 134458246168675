import { useMutation } from 'react-query';
import { queryClient } from 'common/providers/ReactQueryClientProvider';
import { QueryKey } from 'config/queryKey';
import { updateCourseBundleElearnings } from 'common/services/courseBundle';
import { ID } from 'lib/id';

export const useUpdateCourseBundleElearningListMutation = (bundleId: ID) =>
  useMutation(
    (elearningIds: number[]) =>
      updateCourseBundleElearnings(bundleId, elearningIds),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([QueryKey.CourseBundleElearningList]),
    }
  );
