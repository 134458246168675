import {
  Breadcrumb,
  BreadcrumbItem,
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  Grid,
  Pagination,
} from 'carbon-components-react';
import { Content } from 'common/components/Grid';
import { PageHeading } from 'common/components/Heading';
import { useAllCategoriesListQuery } from 'common/hooks/category/useAllCategoriesListQuery';
import { pageSizes } from 'common/models/pagination';
import { BannerListTableContent } from 'modules/banner/components/BannerListTableContent';
import { bannerHeaders } from 'modules/banner/config/bannerHeaders';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootRoute } from 'routes';
import { bannerPaginationSelector } from 'state/banner/bannerSelectors';
import { bannerActions } from 'state/banner/bannerActions';
import { useQueryBanners } from 'modules/banner/hooks/useQueryBanners';
import { bannerRow } from 'modules/banner/config/bannerRow';

export function BannerPageList() {
  const dispatch = useDispatch();
  const pagination = useSelector(bannerPaginationSelector);

  const bannersQuery = useQueryBanners(pagination);
  const categoriesQuery = useAllCategoriesListQuery();

  const rows = useMemo(
    function () {
      if (!bannersQuery.data || !categoriesQuery.data) {
        return [];
      }

      return bannersQuery.data?.result.map((banner) =>
        bannerRow(banner, categoriesQuery.data.result)
      );
    },
    [bannersQuery.data, categoriesQuery.data]
  );

  const isLoading = bannersQuery.isLoading || categoriesQuery.isLoading;
  const isFetching = bannersQuery.isFetching || categoriesQuery.isFetching;

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageHeading>Lista banerów reklamowych</PageHeading>

        {isLoading && (
          <DataTableSkeleton headers={bannerHeaders} rowCount={10}>
            {(props: DataTableCustomRenderProps) => (
              <BannerListTableContent {...props} isFetching={isFetching} />
            )}
          </DataTableSkeleton>
        )}

        {rows && !isLoading && (
          <DataTable
            headers={bannerHeaders}
            overflowMenuOnHover={false}
            rows={rows}
          >
            {(props: DataTableCustomRenderProps) => (
              <BannerListTableContent {...props} isFetching={isFetching} />
            )}
          </DataTable>
        )}

        <Pagination
          onChange={(pagination) => {
            dispatch(
              bannerActions.setPagination({
                page: pagination.page,
                pageSize: pagination.pageSize,
              })
            );
          }}
          page={pagination.page}
          pageSize={pagination.pageSize}
          pageSizes={pageSizes}
          totalItems={bannersQuery.data?.totalResult ?? 0}
        />
      </Grid>
    </Content>
  );
}
