import { DataTableEmptyRow } from 'app/components/DataTableEmptyRow';
import {
  Table,
  TableContainer,
  TableContent,
  TableLoader,
} from 'app/components/Table';
import {
  DataTableCustomRenderProps,
  Loading,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react';
import { QueryKey } from 'config/queryKey';
import { parse } from 'date-fns';
import { format } from 'lib/date';
import { DashboardListTableRow } from 'modules/dashboard/components/DashboardListTableRow';
import { EventsInWeekDTO } from 'modules/dashboard/types';
import { isEmpty } from 'ramda';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { dashboardActions } from 'state/dashboard/dashboardActions';
import { snackbarActions } from 'state/notification/notificationActions';

function formatDashboardDate(input: string | undefined) {
  try {
    if (!input) {
      return '--';
    }
    return format(parse(input, 'yyyy-MM-dd', new Date()), 'dd.MM');
  } catch (error) {
    console.error('Failed to format dashboard date', error);
    return '--';
  }
}

export function DashboardListTableContent({
  isFetching,
  rows,
  events,
  headers,
  getTableProps,
  getTableContainerProps,
  getToolbarProps,
  getHeaderProps,
  getRowProps,
}: DataTableCustomRenderProps & {
  isFetching: boolean;
  events?: EventsInWeekDTO;
}) {
  const client = useQueryClient();
  const dispatch = useDispatch();

  return (
    <TableContainer
      title={`Nadchodzące wydarzenia, ${formatDashboardDate(
        events?.weekStart
      )} - ${formatDashboardDate(events?.weekEnd)}`}
      {...getTableContainerProps()}
    >
      <TableToolbar {...getToolbarProps()}>
        <TableToolbarContent></TableToolbarContent>
      </TableToolbar>

      <TableContent>
        <Table $loading={isFetching} {...getTableProps()}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableHeader {...getHeaderProps({ header })} key={header.key}>
                  {header.header}
                </TableHeader>
              ))}

              <TableHeader />
            </TableRow>
          </TableHead>

          <TableBody>
            {!isEmpty(rows) &&
              rows.map((row, index) => (
                <DashboardListTableRow
                  getRowProps={getRowProps}
                  key={row.id}
                  row={row}
                  event={events?.events.at(index)}
                  onCloseSuccess={() => {
                    dispatch(
                      snackbarActions.enqueueSnackbar({
                        options: { variant: 'success' },
                        title: `Wydarzenie zostało zamknięte`,
                      })
                    );
                    client.invalidateQueries([QueryKey.DashboardEvents]);
                  }}
                  onDeleteSuccess={() => {
                    dispatch(
                      snackbarActions.enqueueSnackbar({
                        options: { variant: 'success' },
                        title: `Wydarzenie zostało usunięte`,
                      })
                    );
                    client.invalidateQueries([QueryKey.DashboardEvents]);
                    dispatch(dashboardActions.setPagination({ page: 1 }));
                  }}
                  onStartSuccess={() => {
                    dispatch(
                      snackbarActions.enqueueSnackbar({
                        options: { variant: 'success' },
                        title: `Wydarzenie zostało rozpoczęte`,
                      })
                    );
                    client.invalidateQueries([QueryKey.DashboardEvents]);
                  }}
                />
              ))}

            {isEmpty(rows) && <DataTableEmptyRow colSpan={5} />}
          </TableBody>
        </Table>

        {isFetching && (
          <TableLoader>
            <Loading active={true} withOverlay={false} />
          </TableLoader>
        )}
      </TableContent>
    </TableContainer>
  );
}
