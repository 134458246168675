import { QueryObserverOptions, useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { ResultResponse } from 'common/models/response';
import { useSelector } from 'react-redux';
import { getOrElse, isSome } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/function';
import { initialPagination, PaginationParams } from 'common/models/pagination';
import { getPartnerList } from 'common/services/partner';
import { Partner } from 'common/models/partner';
import { partnerPaginationSelector } from 'state/partner/partnerSelectors';

export const usePartnerListQuery = (
  options: QueryObserverOptions<ResultResponse<Partner>>,
  paginationParams?: PaginationParams
) => {
  const paginationOption = useSelector(partnerPaginationSelector);

  const pagination =
    paginationParams ??
    pipe(
      paginationOption,
      getOrElse(() => initialPagination)
    );

  return useQuery<ResultResponse<Partner>>(
    [QueryKey.Partner, pagination.page, pagination.pageSize],
    () => getPartnerList(pagination),
    {
      enabled: isSome(paginationOption),
      keepPreviousData: true,
      ...options,
    }
  );
};
