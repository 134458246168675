import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { snackbarActions } from 'state/notification/notificationActions';
import { createCourse } from 'common/services/course';
import { push } from 'connected-react-router';
import { CourseListRoute } from 'routes';

export const useCreateCourseMutation = () => {
  const dispatch = useDispatch();

  return useMutation(createCourse, {
    onSuccess: () => {
      dispatch(push(CourseListRoute));
      dispatch(
        snackbarActions.enqueueSnackbar({
          title: 'Kurs został dodany pomyślnie',
          options: { variant: 'success' },
        })
      );
    },
  });
};
