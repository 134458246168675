import React from 'react';
import { IconBox, IconProps } from 'lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M29.2185 5.96483C33.0395 2.14561 38.2219 0 43.6257 0s10.5862 2.14561 14.4072 5.96483c3.821 3.81922 5.9677 8.99917 5.9677 14.40037 0 5.4001-2.1458 10.5792-5.9655 14.3982l-.0022.0022-19.6057 19.6545a2.90989 2.90989 0 01-2.0602.8548H11.6378c-1.6067 0-2.90928-1.3019-2.90928-2.9079V27.6496a2.907 2.907 0 01.85211-2.0562L29.2185 5.96483zm14.4072-.14897c-3.8606 0-7.563 1.53287-10.2928 4.26144L14.5471 28.8541V49.459h20.6123l18.7561-18.8028.003-.003c2.7299-2.7286 4.2635-6.4293 4.2635-10.288 0-3.8587-1.5336-7.5594-4.2635-10.2879-2.7298-2.72857-6.4322-4.26144-10.2928-4.26144z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M45.6969 18.3249c1.1361 1.1356 1.1361 2.9768 0 4.1124L4.9665 63.1483c-1.13615 1.1356-2.97823 1.1356-4.11438 0-1.13616-1.1356-1.13616-2.9768 0-4.1124l40.73038-40.711c1.1361-1.1357 2.9782-1.1357 4.1144 0z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M20.3652 40.7365c0-1.606 1.3026-2.9079 2.9093-2.9079h24.7292c1.6068 0 2.9093 1.3019 2.9093 2.9079s-1.3025 2.908-2.9093 2.908H23.2745c-1.6067 0-2.9093-1.302-2.9093-2.908z"
    />
  </IconBox>
);
