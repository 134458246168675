import { Button, FormLabel, TextInput, Tile } from 'carbon-components-react';
import { Dispatch, FC, useEffect, useMemo, useReducer } from 'react';
import styled from 'styled-components';
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { findIndex, propEq, remove } from 'ramda';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { Draggable20, TrashCan20 } from '@carbon/icons-react';
import { TypeaheadDropdown } from 'app/components/TypeaheadDropdown';
import { CourseParameter } from 'common/models/course';
import { AnySchema } from 'yup/lib/schema';

const StyledTextInput = styled(TextInput)`
  cursor: default;
`;

const StyledTile = styled(Tile)`
  align-items: center;
  display: flex;
  margin: 0.125rem 0;
`;

type Action =
  | { type: 'add'; payload: CourseParameter }
  | { type: 'delete'; id: string | number }
  | { type: 'move'; oldId: string | number; newId: string | number }
  | { type: 'set'; payload: CourseParameter[] };

interface State {
  rows: CourseParameter[];
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'add':
      return {
        rows: [...state.rows, action.payload],
      };
    case 'delete':
      return {
        rows: remove(
          findIndex(propEq('id', action.id))(state.rows),
          1,
          state.rows
        ),
      };
    case 'move':
      const oldIndex = findIndex(propEq('id', action.oldId))(state.rows);
      const newIndex = findIndex(propEq('id', action.newId))(state.rows);

      return {
        rows: arrayMove(state.rows, oldIndex, newIndex),
      };
    case 'set':
      return {
        rows: [...action.payload],
      };
    default:
      throw new Error();
  }
}

export interface SortableItemProps {
  controlId: string;
  dispatch: Dispatch<Action>;
  item: CourseParameter;
}

export const SortableItem: FC<SortableItemProps> = ({
  controlId,
  dispatch,
  item,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || '',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <StyledTile>
        <Button
          hasIconOnly
          iconDescription="Przenieś"
          kind="ghost"
          renderIcon={Draggable20}
          size="small"
          tooltipAlignment="center"
          tooltipPosition="top"
          {...listeners}
        />

        <StyledTextInput
          hideLabel={true}
          id={`${controlId}_row_${item.id}`}
          labelText=""
          readOnly
          size="sm"
          value={item.value}
        />

        <Button
          hasIconOnly
          iconDescription="Usuń"
          kind="danger--ghost"
          onClick={() => {
            dispatch({ type: 'delete', id: item.id });
          }}
          renderIcon={TrashCan20}
          size="small"
          tooltipAlignment="center"
          tooltipPosition="top"
        />
      </StyledTile>
    </div>
  );
};

export interface CourseParametersControlProps {
  id: string;
  invalid: boolean;
  inputValidationSchema?: AnySchema;
  invalidText?: string;
  items?: CourseParameter[];
  label?: string;
  onChange?: (rows: CourseParameter[]) => void;
  value?: CourseParameter[];
}

export const CourseParametersControl: FC<CourseParametersControlProps> = ({
  id,
  invalid,
  invalidText,
  inputValidationSchema,
  items,
  label,
  onChange,
  value,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    rows: value || [],
  });
  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      dispatch({
        type: 'move',
        oldId: active.id,
        newId: over.id,
      });
    }
  };

  useEffect(() => {
    onChange && onChange(state.rows);
  }, [state]);

  const typeaheadItems = useMemo(() => items || [], [items]);

  return (
    <>
      {label && <FormLabel>{label}</FormLabel>}

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={state.rows}
          strategy={verticalListSortingStrategy}
        >
          {state.rows.map((row) => (
            <SortableItem
              controlId={id}
              dispatch={dispatch}
              key={row.id}
              item={row}
            />
          ))}
        </SortableContext>
      </DndContext>

      <TypeaheadDropdown
        id={id}
        inputValidationSchema={inputValidationSchema}
        invalid={invalid}
        invalidText={invalidText}
        items={typeaheadItems}
        onSelect={(item) =>
          item &&
          dispatch({
            type: 'add',
            payload: {
              ...item,
              id: item.id === 'new' ? `new_${Date.now()}` : item.id,
            },
          })
        }
      />
    </>
  );
};
