import { Content } from 'common/components/Grid';
import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  Grid,
  Row,
  SkeletonText,
} from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import { isCreateParam, RootRoute, SurveyListRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import React from 'react';
import { SurveyForm } from 'app/components/SurveyFormPage/SurveyForm';
import { useCreateSurveyMutation } from 'common/hooks/survey/useCreateSurveyMutation';
import {
  mapToSurveyDTO,
  mapToSurveyFormModel,
  SurveyFormModel,
} from 'app/components/SurveyFormPage/surveyFormModel';
import { useSurveyByIdQuery } from 'common/hooks/survey/useSurveyByIdQuery';
import { SurveyFormSkeleton } from 'app/components/SurveyFormPage/SurveyFormSkeleton';
import { useUpdateSurveyMutation } from 'common/hooks/survey/useUpdateSurveyMutation';

export interface SurveyFormParams {
  surveyId: string;
}

export const SurveyFormPage = () => {
  const { surveyId } = useParams<SurveyFormParams>();
  const createMutation = useCreateSurveyMutation();
  const updateMutation = useUpdateSurveyMutation();

  const { data, isFetching } = useSurveyByIdQuery(surveyId);
  const handleSubmit = (v: SurveyFormModel) => {
    const surveyDto = mapToSurveyDTO(v);
    isCreateParam(surveyId)
      ? createMutation.mutate(surveyDto)
      : updateMutation.mutate(surveyDto);
  };

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={SurveyListRoute}>Lista ankiet</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        {isCreateParam(surveyId) && <PageHeading>Nowa ankieta</PageHeading>}

        {!isCreateParam(surveyId) && (
          <PageHeading>
            {isFetching ? (
              <SkeletonText heading={true} width="300px" />
            ) : (
              `${data?.title}`
            )}
          </PageHeading>
        )}

        <Row>
          <Column lg={12}>
            {isCreateParam(surveyId) && (
              <SurveyForm
                onSubmit={handleSubmit}
                submitLabel="Utwórz"
                isBusy={createMutation.isLoading}
              />
            )}
            {!isCreateParam(surveyId) && (
              <>
                {isFetching && <SurveyFormSkeleton />}
                {!isFetching && data && (
                  <SurveyForm
                    onSubmit={handleSubmit}
                    value={mapToSurveyFormModel(data)}
                    submitLabel="Zapisz zmiany"
                    isBusy={updateMutation.isLoading}
                  />
                )}
              </>
            )}
          </Column>
        </Row>
      </Grid>
    </Content>
  );
};
