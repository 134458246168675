import * as yup from 'yup';
import { mixed } from 'yup';
import { requiredEditorJsFormSchema } from 'common/form-schemas/editorJs';

export const aboutUsFormSchema = yup.object({
  additional: yup.object({
    heading: yup.string().max(255).required(),
    subtitle: yup.string().max(255).required(),
    text: requiredEditorJsFormSchema,
  }),
  benefits: yup.object({
    heading: yup.string().max(255).required(),
    list: yup
      .array()
      .min(1)
      .of(
        yup.object({
          iconFile: mixed().test(
            'benefit-icon-required',
            'Wybierz ikonę',
            (value, context) => {
              const { currentIcon } = context.parent;
              return !(currentIcon == null && value == null);
            }
          ),
          text: requiredEditorJsFormSchema,
        })
      ),
    subtitle: yup.string().max(255).required(),
  }),
  knowledge: yup.object({
    heading: yup.string().max(255).required(),
    list: yup
      .array()
      .min(1)
      .of(
        yup.object({
          text: requiredEditorJsFormSchema,
        })
      ),
    subtitle: yup.string().max(255).required(),
    text: requiredEditorJsFormSchema,
  }),
  possibilities: yup.object({
    heading: yup.string().max(255).required(),
    list: yup
      .array()
      .min(1)
      .of(
        yup.object({
          text: requiredEditorJsFormSchema,
        })
      ),
    subtitle: yup.string().max(255).required(),
    text: requiredEditorJsFormSchema,
  }),
  strengths: yup.object({
    heading: yup.string().max(255).required(),
    list: yup
      .array()
      .min(1)
      .of(
        yup.object({
          text: requiredEditorJsFormSchema,
          value: yup.string().max(255).required(),
        })
      ),
    subtitle: yup.string().max(255).required(),
  }),
  whatIsPfp: yup.object({
    heading: yup.string().max(255).required(),
    text: requiredEditorJsFormSchema,
  }),
});

export const aboutUsFieldsOrder = [
  'whatIsPfp',
  'strengths',
  'knowledge',
  'benefits',
  'possibilities',
  'additional',
];
