import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { SurveyListRoute } from 'routes';
import { snackbarActions } from 'state/notification/notificationActions';
import { updateSurvey } from 'common/services/survey';
import { useMutation, useQueryClient } from 'react-query';
import { QueryKey } from 'config/queryKey';

export function useUpdateSurveyMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(updateSurvey, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries([QueryKey.SurveyList, `${variable.id}`]);
      dispatch(push(SurveyListRoute));
      dispatch(
        snackbarActions.enqueueSnackbar({
          title: 'Ankieta została zapisana pomyślnie',
          options: { variant: 'success' },
        })
      );
    },
  });
}
