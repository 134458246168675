import { AuthorizedAxios, throwErrorResponse } from 'common/services/axios';
import { flatten } from 'lib/axios';
import {
  CourseEventParticipantEventStatus,
  CourseEventParticipantListItem,
  CourseEventParticipantSortData,
} from 'common/models/courseEventParticipant';
import { CourseEvent, CourseEventStatus } from 'common/models/courseEvent';
import { saveData } from 'common/services/file';
import { ListWrapper, unwrap } from 'lib/listWrapper';

export const canChangeParticipantPresence = (event?: CourseEvent) =>
  event &&
  [CourseEventStatus.Ongoing, CourseEventStatus.Realized].includes(
    event.status
  );

export const canChangeParticipantStatus = (event?: CourseEvent) =>
  event &&
  [CourseEventStatus.Open, CourseEventStatus.Ongoing].includes(event.status);

export const getCourseEventParticipantList = (
  eventId: string,
  sortData: CourseEventParticipantSortData
) =>
  AuthorizedAxios.post<ListWrapper<CourseEventParticipantListItem>>(
    `/api/events/${eventId}/participants/sort`,
    {
      eventParticipantSortData: sortData,
    }
  )
    .then(flatten)
    .then(unwrap)
    .catch(throwErrorResponse);

export const setCourseEventParticipantPresence = (
  eventId: string,
  userId: string,
  status:
    | CourseEventParticipantEventStatus.Absent
    | CourseEventParticipantEventStatus.Present
) =>
  AuthorizedAxios.put<void>(`/api/events/${eventId}/participants/presence`, {
    participantEventStatus: status,
    userId,
  }).catch(throwErrorResponse);

export const setCourseEventParticipantStatus = (
  eventId: string,
  userId: string,
  status:
    | CourseEventParticipantEventStatus.Accepted
    | CourseEventParticipantEventStatus.Rejected
) =>
  AuthorizedAxios.put<void>(`/api/events/${eventId}/participants`, {
    participantEventStatus: status,
    userId,
  }).catch(throwErrorResponse);

export const exportCourseEventParticipantsList = (eventId: string) =>
  AuthorizedAxios.get<string>(`/api/events/${eventId}/participants/export`)
    .then(flatten)
    .then(saveData(`event-${eventId}-participants.csv`))
    .catch(throwErrorResponse);
