import React, { FC } from 'react';
import { CategoryDeleteRow } from 'app/components/CategoryDelete/CategoryDeleteRow';
import {
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
} from 'carbon-components-react';
import { Category } from 'common/models/category';
import styled from 'styled-components';
import { CourseIds, CourseMap } from 'app/components/CategoryDelete/config';
import { ProgressPageContent } from 'app/components/ProgressPageContent';
import { ProgressPageText } from 'app/components/ProgressPageText';
import { ID } from 'lib/id';

const StructuredListCellAction = styled(StructuredListCell)`
  width: 6.25rem;
`;

const StructuredListCellCategory = styled(StructuredListCell)`
  width: 18.75rem;
`;

export interface CategoryDeleteStep1Props {
  allCategoriesList: Category[];
  courseIds: CourseIds;
  courseMap: CourseMap;
  onChange: (id: ID) => void;
}

export const CategoryDeleteStep1: FC<CategoryDeleteStep1Props> = ({
  allCategoriesList,
  courseIds,
  courseMap,
  onChange,
}) => {
  return (
    <>
      <ProgressPageContent>
        <ProgressPageText>
          Przed usunięciem wybranej kategorii należy wszystkie przypisane do
          niej szkolenia / e-learningi przepisać do innych kategorii.
        </ProgressPageText>
        <ProgressPageText>
          Kliknięcie w przycisk <strong>natychmiast</strong> i{' '}
          <strong>nieodwracalnie</strong> przypisuje szkolenie / e-learning do
          wybranej kategorii.
          <br />
          Aby cofnać wybór należy ręcznie zmienić kategorię główną na ekranie
          edycji wybranego szkolenia / e-learningu.
        </ProgressPageText>
      </ProgressPageContent>

      <StructuredListWrapper>
        <StructuredListHead>
          <StructuredListRow head tabIndex={0}>
            <StructuredListCell head>Tytuł</StructuredListCell>
            <StructuredListCellCategory head>
              Nowa kategoria
            </StructuredListCellCategory>
            <StructuredListCellAction head />
          </StructuredListRow>
        </StructuredListHead>

        <StructuredListBody>
          {courseIds.map(
            (id) =>
              courseMap[id].status === 'pending' && (
                <CategoryDeleteRow
                  allCategoriesList={allCategoriesList}
                  key={id}
                  onSuccess={onChange}
                  row={courseMap[id].course}
                />
              )
          )}
        </StructuredListBody>
      </StructuredListWrapper>
    </>
  );
};
