export const UPLOAD_ALLOWED_MIME = [
  '.txt',
  '.pdf',
  '.ppt',
  '.pptx',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.png',
  '.jpg',
];

export const UPLOAD_MAX_SIZE = 50 * 1024 * 1024;
