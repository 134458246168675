import CategoryCareerStartupIcon from 'assets/icons/CategoryCareerStartupIcon';
import CategoryLawIcon from 'assets/icons/CategoryLawIcon';
import CategoryMarketingIcon from 'assets/icons/CategoryMarketingIcon';
import CategorySoftSkillsIcon from 'assets/icons/CategorySoftSkillsIcon';
import CategoryYourCoursesIcon from 'assets/icons/CategoryYourCoursesIcon';
import CategoryProgrammingIcon from 'assets/icons/CategoryProgrammingIcon';
import ChatIcon from 'assets/icons/ChatIcon';
import { IconProps } from 'lib/IconBox';
import { FC } from 'react';
import CategoryFinancesIcon from 'assets/icons/CategoryFinancesIcon';
import CategoryCompanyManagementIcon from 'assets/icons/CategoryCompanyManagementIcon';
import CategoryApplicationsIcon from 'assets/icons/CategoryApplicationsIcon';
import LightbulbIcon from 'assets/icons/LightbulbIcon';

export const courseCategoryIconMap: Record<string, FC<IconProps>> = {
  'category-career-startup': CategoryCareerStartupIcon,
  'category-finances': CategoryFinancesIcon,
  'category-marketing': CategoryMarketingIcon,
  'category-technology': CategoryProgrammingIcon,
  'category-programming': CategoryProgrammingIcon,
  'category-company-management': CategoryCompanyManagementIcon,
  chat: ChatIcon,
  'category-law': CategoryLawIcon,
  'category-applications': CategoryApplicationsIcon,
  'category-soft-skills': CategorySoftSkillsIcon,
  'category-your-courses': CategoryYourCoursesIcon,
  'special-category-1': LightbulbIcon,
  'special-category-2': LightbulbIcon,
  'special-category-3': LightbulbIcon,
  'special-category-4': LightbulbIcon,
  'special-category-5': LightbulbIcon,
};
