import { FC } from 'react';
import { MaterialStatus } from 'common/models/material';
import {
  ShapeStatusIndicator,
  ShapeStatusIndicatorKind,
} from 'app/components/ShapeStatusIndicator';
import { LoadingStatusIndicator } from 'app/components/LoadingStatusIndicator';

export interface MaterialsStatusIndicatorProps {
  status: MaterialStatus;
}

export const MaterialsStatusIndicator: FC<MaterialsStatusIndicatorProps> = ({
  status,
}) => {
  switch (status) {
    case MaterialStatus.Changed:
      return (
        <ShapeStatusIndicator
          kind={ShapeStatusIndicatorKind.MediumSeverity}
          label="Zmieniony"
        />
      );
    case MaterialStatus.New:
      return (
        <ShapeStatusIndicator
          kind={ShapeStatusIndicatorKind.Draft}
          label="Nowy"
        />
      );
    case MaterialStatus.Saved:
      return (
        <ShapeStatusIndicator
          kind={ShapeStatusIndicatorKind.Stable}
          label="Zapisany"
        />
      );
    case MaterialStatus.Deleting:
      return <LoadingStatusIndicator label="Usuwanie.." />;
    case MaterialStatus.Saving:
      return <LoadingStatusIndicator label="Zapisywanie.." />;
    case MaterialStatus.Uploading:
      return <></>;
  }

  return <></>;
};
