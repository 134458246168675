import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';

export const courseBundleStateSelector = (state: RootState) =>
  state.courseBundle;

export const courseBundlePaginationSelector = createSelector(
  courseBundleStateSelector,
  (state) => state.pagination
);

export const courseBundleSortSelector = createSelector(
  courseBundleStateSelector,
  (state) => state.sort
);
