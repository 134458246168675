import React, { FC } from 'react';
import { TextInput } from 'carbon-components-react';
import { useFormContext } from 'react-hook-form';
import {
  ControlProps,
  FormItem,
} from 'app/components/SurveyFormPage/SurveyQuestionControl/common';
import styled from 'styled-components';
import { useControlErrors } from 'common/hooks/useControlErrors';
import { StyleProps } from 'lib/styleProps';

export interface ChoiceOptionControlModel {
  id?: string;
  enablesComment: boolean;
  text: string;
}

export const defaultChoiceOptionControlValue: ChoiceOptionControlModel = {
  text: '',
  enablesComment: false,
};

const Container = styled.div`
  width: 100%;
`;

export const ChoiceOptionControl: FC<
  StyleProps & ControlProps<ChoiceOptionControlModel>
> = ({ className, style, label, name, defaultValue }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const controlErrors = useControlErrors(errors, name);

  return (
    <Container className={className} style={style}>
      <FormItem>
        <TextInput
          id={`${name}`}
          {...register(`${name}.text` as const)}
          defaultValue={defaultValue?.text}
          invalid={!!controlErrors?.text}
          invalidText={controlErrors?.text?.message}
          labelText={label ?? ''}
        />
      </FormItem>
    </Container>
  );
};
