import { FC, useEffect, useMemo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  Grid,
  Pagination,
} from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import {
  PartnerFormRoute,
  partnerIdRouteParam,
  PartnerListRoute,
  RootRoute,
} from 'routes';
import { PageHeading } from 'common/components/Heading';
import { useDispatch, useSelector } from 'react-redux';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/lib/Option';
import { initialPagination, pageSizes } from 'common/models/pagination';
import { Content } from 'common/components/Grid';
import { partnerUsersPaginationSelector } from 'state/partnerUsers/partnerUsersSelectors';
import { partnerUsersActions } from 'state/partnerUsers/partnerUsersActions';
import { useUserListQuery } from 'common/hooks/user/useUserListQuery';
import { PartnerUsersListContent } from 'app/components/PartnerUsersList/PartnerUsersListContent';
import { preparePartnerUsersRow } from 'app/components/PartnerUsersList/PartnerUsersListRow';
import { usePartnerByIdQuery } from 'common/hooks/partner/usePartnerByIdQuery';
import { PartnerUsersListParams } from 'common/models/partner';
import { setParam } from 'common/utils/routing';

const headers = [
  {
    key: 'email',
    header: 'E-mail',
  },
  {
    key: 'name',
    header: 'Imię i nazwisko',
  },
  {
    key: 'plus',
    header: 'Status PLUS',
  },
];

export const PartnerUsersList: FC = () => {
  const dispatch = useDispatch();
  const paginationOption = useSelector(partnerUsersPaginationSelector);

  const { partnerId } = useParams<PartnerUsersListParams>();

  const pagination = useMemo(
    () =>
      pipe(
        paginationOption,
        getOrElse(() => initialPagination)
      ),
    [paginationOption]
  );

  const partnerQuery = usePartnerByIdQuery(partnerId, partnerId.length > 0);

  const { data, isFetching, isLoading } = useUserListQuery({
    filters: {
      partnerId: partnerId,
    },
    options: {
      onSuccess: (data) => {
        if (data && data.result.length === 0 && pagination.page !== 1) {
          dispatch(
            partnerUsersActions.setPagination({
              page: Math.ceil(data.totalResult / pagination.pageSize),
              pageSize: pagination.pageSize,
            })
          );
        }
      },
    },
    paginationSelector: partnerUsersPaginationSelector,
  });

  const rows = useMemo(() => data?.result.map(preparePartnerUsersRow), [data]);

  useEffect(() => {
    dispatch(partnerUsersActions.loadPagination());
  }, [dispatch]);

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={PartnerListRoute}>Lista partnerów</Link>
          </BreadcrumbItem>
          {partnerQuery.data && (
            <BreadcrumbItem>
              <Link
                to={setParam(partnerIdRouteParam)(PartnerFormRoute)(
                  String(partnerQuery.data.id)
                )}
              >
                {partnerQuery.data.partnerName}
              </Link>
            </BreadcrumbItem>
          )}
        </Breadcrumb>

        <PageHeading>Lista użytkowników</PageHeading>

        {isLoading && (
          <DataTableSkeleton headers={headers} rowCount={10}>
            {(props: DataTableCustomRenderProps) => (
              <PartnerUsersListContent {...props} users={[]} />
            )}
          </DataTableSkeleton>
        )}

        {rows && !isLoading && (
          <DataTable headers={headers} overflowMenuOnHover={false} rows={rows}>
            {(props: DataTableCustomRenderProps) => (
              <PartnerUsersListContent
                {...props}
                isFetching={isFetching}
                users={data?.result ?? []}
              />
            )}
          </DataTable>
        )}

        <Pagination
          onChange={(pagination) => {
            dispatch(
              partnerUsersActions.setPagination({
                page: pagination.page,
                pageSize: pagination.pageSize,
              })
            );
          }}
          page={pagination.page}
          pageSize={pagination.pageSize}
          pageSizes={pageSizes}
          totalItems={data?.totalResult ?? 0}
        />
      </Grid>
    </Content>
  );
};
