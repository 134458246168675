import React, { FC, useEffect, useState } from 'react';
import { Modal, TextInput } from 'carbon-components-react';
import { Portal } from 'common/components/Portal';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MaterialsUrlFormModel } from 'common/models/material';
import { useDispatch, useSelector } from 'react-redux';
import { materialsActions } from 'state/materials/materialsActions';
import { materialsUrlFormModalItemSelector } from 'state/materials/materialsSelectors';
import {
  MaterialsModalForm,
  MaterialsModalFormItem,
} from 'app/components/Materials/MaterialsModalForm';

const formSchema = yup.object({
  name: yup.string().max(128).required(),
  url: yup.string().url().required(),
});

export interface MaterialsUrlFormModalProps {
  onClose: () => void;
  open: boolean;
}

export const MaterialsUrlFormModal: FC<MaterialsUrlFormModalProps> = ({
  onClose,
  open,
}) => {
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const material = useSelector(materialsUrlFormModalItemSelector);

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<MaterialsUrlFormModel>({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (open) {
      setIsSubmitted(false);
      reset({
        name: material?.name,
        url: material?.url,
      });
    }
  }, [open]);

  useEffect(() => {
    reset({
      name: material?.name,
      url: material?.url,
    });
  }, [material]);

  const onSubmit = handleSubmit((values) => {
    if (isSubmitted) {
      return;
    }

    setIsSubmitted(true);

    material
      ? dispatch(
          materialsActions.updateUrl({
            id: material.id,
            values,
          })
        )
      : dispatch(materialsActions.addUrl(values));
    onClose();
  });

  return (
    <Portal>
      <Modal
        modalHeading={
          material ? 'Edytuj link zewnętrzny' : 'Nowy link zewnętrzny'
        }
        onRequestClose={onClose}
        onRequestSubmit={onSubmit}
        open={open}
        primaryButtonDisabled={isSubmitted}
        primaryButtonText={material ? 'Zmień' : 'Dodaj'}
        secondaryButtonText="Anuluj"
        shouldSubmitOnEnter
        size="sm"
      >
        <MaterialsModalForm onSubmit={onSubmit}>
          <MaterialsModalFormItem>
            <TextInput
              data-modal-primary-focus
              id="urlName"
              invalid={!!errors.name}
              invalidText={errors.name?.message}
              labelText="Nazwa"
              {...register('name')}
            />
          </MaterialsModalFormItem>

          <MaterialsModalFormItem>
            <TextInput
              id="url"
              invalid={!!errors.url}
              invalidText={errors.url?.message}
              labelText="Url"
              {...register('url')}
            />
          </MaterialsModalFormItem>
        </MaterialsModalForm>
      </Modal>
    </Portal>
  );
};
