import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';

export const courseBundleContentSwitcherStateSelector = (state: RootState) =>
  state.courseBundleContentSwitcher;

export const courseBundleContentSwitcherCategorySelector = createSelector(
  courseBundleContentSwitcherStateSelector,
  (state) => state.courseBundleContentCategory
);
