import { createReducer } from '@reduxjs/toolkit';
import { PaginationState, SortDirection } from 'common/models/pagination';
import { none, Option, some } from 'fp-ts/lib/Option';
import { courseActions } from 'state/course/courseActions';
import { CourseListColumn, CourseSortData } from 'common/models/course';

export interface CourseState {
  pagination: Option<PaginationState>;
  sort: CourseSortData;
  speaker: Option<string>;
}

export const defaultSort: CourseSortData = {
  direction: SortDirection.Asc,
  orderBy: CourseListColumn.CourseTitle,
};

const initialState: CourseState = {
  pagination: none,
  sort: defaultSort,
  speaker: none,
};

export const courseReducer = createReducer<CourseState>(
  initialState,
  (builder) =>
    builder
      .addCase(courseActions.clear, () => initialState)
      .addCase(courseActions.setFilters, (state, action) => {
        state.speaker = action.payload.speakerId
          ? some(action.payload.speakerId)
          : none;
        state.pagination = some(action.payload.pagination);
        state.sort = action.payload.sort;
      })
      .addCase(courseActions.setPagination, (state, action) => {
        state.pagination = some(action.payload);
      })
      .addCase(courseActions.setSort, (state, action) => {
        state.sort = action.payload;
      })
      .addCase(courseActions.setSpeaker, (state, action) => {
        state.speaker = action.payload ? some(action.payload.id) : none;
      })
      .addCase(courseActions.setSpeakerId, (state, action) => {
        state.speaker = action.payload ? some(action.payload) : none;
      })
);
