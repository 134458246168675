import React, { FC, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  BannerCreateRoute,
  BannerFormRoute,
  BannerListRoute,
  CategoryCreateRoute,
  CategoryDeleteRoute,
  CategoryFormRoute,
  CategoryListRoute,
  CertificateSettingsRoute,
  CourseBundleCreateRoute,
  CourseBundleElearningListRoute,
  CourseBundleEventListRoute,
  CourseBundleIdRoute,
  CourseBundleListRoute,
  CourseBundleMaterialsRoute,
  CourseEventFormRoute,
  CourseEventListRoute,
  CourseEventParticipantListRoute,
  CourseFormRoute,
  CourseListRoute,
  CourseMaterialsRoute,
  ElearningCreateRoute,
  ElearningFormRoute,
  ElearningLessonCreateRoute,
  ElearningLessonFormRoute,
  ElearningLessonListRoute,
  ElearningLessonMaterialsRoute,
  ElearningLessonUploadVideoRoute,
  ElearningListRoute,
  ElearningUploadPreviewRoute,
  LoginRoute,
  PartnerCreateRoute,
  PartnerFormRoute,
  PartnerListRoute,
  PartnerUsersListRoute,
  RootRoute,
  SpeakerFormRoute,
  SpeakerListRoute,
  SurveyFormRoute,
  SurveyListRoute,
  SystemSettingsRoute,
  UserListRoute,
} from 'routes';
import { Login } from 'app/pages/Login';
import { PrivateRoute } from 'common/components/PrivateRoute';
import { SpeakerList } from 'app/pages/SpeakerList';
import { SpeakerForm } from 'app/pages/SpeakerForm';
import { CourseForm } from 'app/pages/CourseForm';
import { CourseList } from 'app/pages/CourseList';
import { CourseEventList } from 'app/pages/CourseEventList';
import { CourseEventForm } from 'app/pages/CourseEventForm';
import { CourseEventParticipantsList } from 'app/pages/CourseEventParticipantsList';
import { SurveyList } from 'app/pages/SurveyList';
import { SurveyFormPage } from 'app/pages/SurveyFormPage';
import { UserList } from 'app/pages/UserList';
import { CertificateSettingsForm } from 'app/pages/CertificateSettingsForm';
import { withSentryRouting } from '@sentry/react';
import { CourseMaterials } from 'app/pages/CourseMaterials';
import { PartnerList } from 'app/pages/PartnerList';
import { PartnerFormPage } from 'app/pages/PartnerFormPage';
import { ElearningList } from 'app/pages/ElearningList';
import { ElearningFormPage } from 'app/pages/ElearningFormPage';
import { ElearningUploadPreview } from 'app/pages/ElearningUploadPreview';
import { ElearningLessonList } from 'app/pages/ElearningLessonList';
import { ElearningLessonFormPage } from 'app/pages/ElearningLessonFormPage';
import { ElearningLessonUploadVideo } from 'app/pages/ElearningLessonUploadVideo';
import { ElearningLessonMaterials } from 'app/pages/ElearningLessonMaterials';
import { CategoryList } from 'app/pages/CategoryList';
import { CategoryFormPage } from 'app/pages/CategoryFormPage';
import { CategoryDeletePage } from 'app/pages/CategoryDelete';
import { PartnerUsersList } from 'app/pages/PartnerUsersList';
import { ReportsRootRoute } from 'modules/reports-wizard/routes';
import { Loading } from 'carbon-components-react';
import { SystemSettingsForm } from 'app/pages/SystemSettingsForm';
import StaticPagesRouter from 'modules/static-pages/pages/StaticPagesRouter';
import { StaticPageListRoute } from 'modules/static-pages/routes';
import { CourseBundleFormPage } from 'app/pages/CourseBundle/CourseBundleForm/CourseBundleFormPage';
import { CourseBundleList } from 'app/pages/CourseBundle/CourseBundleList';
import { CourseBundleEventList } from 'app/pages/CourseBundle/CourseBundleContent/CourseBundleEventList';
import { CourseBundleElearningList } from 'app/pages/CourseBundle/CourseBundleContent/CourseBundleElearningList';
import { CourseBundleMaterials } from 'app/pages/CourseBundle/CourseBundleMaterials';
import { BannerPageList } from 'modules/banner/pages/BannerPageList';
import { BannerPageForm } from 'modules/banner/pages/BannerPageForm';
import { DashboardPage } from 'modules/dashboard/DashboardPage';

const ReportsRouter = lazy(
  () => import('modules/reports-wizard/pages/ReportsRouter')
);

const SentryRoute = withSentryRouting(Route);

export const AppRouter: FC = () => (
  <Suspense fallback={<Loading />}>
    <Switch>
      {/* ----------------- Categories --------------------- */}
      <PrivateRoute path={CategoryListRoute} exact>
        <CategoryList />
      </PrivateRoute>
      <PrivateRoute path={CategoryCreateRoute} exact>
        <CategoryFormPage create />
      </PrivateRoute>
      <PrivateRoute path={CategoryFormRoute} exact>
        <CategoryFormPage />
      </PrivateRoute>
      <PrivateRoute path={CategoryDeleteRoute} exact>
        <CategoryDeletePage />
      </PrivateRoute>

      {/* ----------------- Courses --------------------- */}
      <PrivateRoute path={CourseListRoute} exact>
        <CourseList />
      </PrivateRoute>
      <PrivateRoute path={CourseEventParticipantListRoute} exact>
        <CourseEventParticipantsList />
      </PrivateRoute>
      <PrivateRoute path={CourseEventListRoute} exact>
        <CourseEventList />
      </PrivateRoute>
      <PrivateRoute path={CourseEventFormRoute}>
        <CourseEventForm />
      </PrivateRoute>
      <PrivateRoute path={CourseMaterialsRoute}>
        <CourseMaterials />
      </PrivateRoute>
      <PrivateRoute path={CourseFormRoute}>
        <CourseForm />
      </PrivateRoute>

      {/* ----------------- Speakers --------------------- */}
      <PrivateRoute path={SpeakerListRoute} exact>
        <SpeakerList />
      </PrivateRoute>
      <PrivateRoute path={SpeakerFormRoute}>
        <SpeakerForm />
      </PrivateRoute>

      {/* ----------------- Surveys --------------------- */}
      <PrivateRoute path={SurveyListRoute} exact>
        <SurveyList />
      </PrivateRoute>
      <PrivateRoute path={SurveyFormRoute}>
        <SurveyFormPage />
      </PrivateRoute>

      {/* ----------------- Users --------------------- */}
      <PrivateRoute path={UserListRoute} exact>
        <UserList />
      </PrivateRoute>

      {/* ----------------- E-learnings --------------------- */}
      <PrivateRoute path={ElearningLessonMaterialsRoute} exact>
        <ElearningLessonMaterials />
      </PrivateRoute>
      <PrivateRoute path={ElearningLessonUploadVideoRoute} exact>
        <ElearningLessonUploadVideo />
      </PrivateRoute>
      <PrivateRoute path={ElearningLessonCreateRoute} exact>
        <ElearningLessonFormPage create />
      </PrivateRoute>
      <PrivateRoute path={ElearningLessonFormRoute} exact>
        <ElearningLessonFormPage />
      </PrivateRoute>
      <PrivateRoute path={ElearningLessonListRoute} exact>
        <ElearningLessonList />
      </PrivateRoute>
      <PrivateRoute path={ElearningUploadPreviewRoute} exact>
        <ElearningUploadPreview />
      </PrivateRoute>
      <PrivateRoute path={ElearningListRoute} exact>
        <ElearningList />
      </PrivateRoute>
      <PrivateRoute path={ElearningCreateRoute} exact>
        <ElearningFormPage create />
      </PrivateRoute>
      <PrivateRoute path={ElearningFormRoute} exact>
        <ElearningFormPage />
      </PrivateRoute>

      {/* ----------------- Course Bundles --------------------- */}
      <PrivateRoute path={CourseBundleListRoute} exact>
        <CourseBundleList />
      </PrivateRoute>

      <PrivateRoute path={CourseBundleElearningListRoute} exact>
        <CourseBundleElearningList />
      </PrivateRoute>

      <PrivateRoute path={CourseBundleEventListRoute} exact>
        <CourseBundleEventList />
      </PrivateRoute>

      <PrivateRoute path={CourseBundleMaterialsRoute} exact>
        <CourseBundleMaterials />
      </PrivateRoute>

      <PrivateRoute path={CourseBundleCreateRoute} exact>
        <CourseBundleFormPage create />
      </PrivateRoute>
      <PrivateRoute path={CourseBundleIdRoute} exact>
        <CourseBundleFormPage />
      </PrivateRoute>

      {/* ----------------- Partners --------------------- */}
      <PrivateRoute path={PartnerListRoute} exact>
        <PartnerList />
      </PrivateRoute>
      <PrivateRoute path={PartnerCreateRoute} exact>
        <PartnerFormPage create />
      </PrivateRoute>
      <PrivateRoute path={PartnerFormRoute} exact>
        <PartnerFormPage />
      </PrivateRoute>
      <PrivateRoute path={PartnerUsersListRoute} exact>
        <PartnerUsersList />
      </PrivateRoute>

      {/* ----------------- Certificates --------------------- */}
      <PrivateRoute path={CertificateSettingsRoute} exact>
        <CertificateSettingsForm />
      </PrivateRoute>

      {/* ----------------- Reports --------------------- */}
      <PrivateRoute path={ReportsRootRoute}>
        <ReportsRouter />
      </PrivateRoute>

      {/* ----------------- Static pages --------------------- */}
      <PrivateRoute path={StaticPageListRoute}>
        <StaticPagesRouter />
      </PrivateRoute>

      {/* ----------------- System --------------------- */}
      <PrivateRoute path={SystemSettingsRoute} exact>
        <SystemSettingsForm />
      </PrivateRoute>
      <SentryRoute path={LoginRoute}>
        <Login />
      </SentryRoute>
      <PrivateRoute path={RootRoute} exact>
        <DashboardPage />
      </PrivateRoute>

      {/* ----------------- Adverts --------------------- */}
      <PrivateRoute path={BannerListRoute} exact>
        <BannerPageList />
      </PrivateRoute>
      <PrivateRoute path={BannerCreateRoute} exact>
        <BannerPageForm create />
      </PrivateRoute>
      <PrivateRoute path={BannerFormRoute} exact>
        <BannerPageForm />
      </PrivateRoute>
    </Switch>
  </Suspense>
);
