import {
  ApiErrorItem,
  errorMessageMap,
  ErrorMessages,
  TranslatedError,
} from 'common/models/error';
import { AppError } from 'common/errors/AppError';
import { isApiError, isOAuthError, OAuthError } from 'lib/apiError';
import { AbortError } from 'common/errors/AbortError';

export const getErrorMessages = (
  errors: ApiErrorItem[],
  originalError: Error,
  messages?: ErrorMessages
): TranslatedError[] =>
  errors.map((error) => ({
    error,
    originalError,
    message:
      messages?.[error.message] ??
      messages?.[error.errorCode] ??
      messages?.['default'] ??
      error.message,
  }));

export const getOAuthErrorMessages = (
  error: OAuthError,
  originalError: Error,
  messages?: ErrorMessages
): TranslatedError[] => [
  {
    error,
    originalError,
    message:
      messages?.[error.error] ??
      messages?.[error.error_description] ??
      messages?.['default'] ??
      error.error_description,
  },
];

export const buildErrorResponse = (
  response: unknown,
  originalError: Error
): TranslatedError[] => {
  let errors;

  if (isOAuthError(response)) {
    errors = getOAuthErrorMessages(response, originalError, errorMessageMap);
  } else if (isApiError(response)) {
    errors = getErrorMessages(response.errors, originalError, errorMessageMap);
  } else if (
    typeof response === 'object' &&
    response != null &&
    response.hasOwnProperty('status') &&
    (response as { status: number }).status === 404
  ) {
    errors = [
      {
        message: 'Obiekt nie istnieje lub nie został odnaleziony',
        originalError: originalError,
      },
    ];
  } else {
    errors = [
      {
        message: 'An unknown error has occurred',
        originalError: originalError,
      },
    ];
  }

  return errors;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isApiErrorItem(a: unknown): a is ApiErrorItem {
  return (
    typeof a === 'object' &&
    a != null &&
    a.hasOwnProperty('errorCode') &&
    a.hasOwnProperty('message')
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAppError(a: any): a is AppError {
  return a instanceof AppError;
}

export function isAbortError(a: unknown): a is AbortError {
  return (
    typeof a === 'object' &&
    a != null &&
    a.hasOwnProperty('name') &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    a.name === 'AbortError'
  );
}
