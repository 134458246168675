import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { elearningIdRouteParam, ElearningLessonListRoute } from 'routes';
import { snackbarActions } from 'state/notification/notificationActions';
import { queryClient } from 'common/providers/ReactQueryClientProvider';
import { QueryKey } from 'config/queryKey';
import { ElearningLessonUpdateDTO } from 'common/models/elearningLesson';
import { updateElearningLessonById } from 'common/services/elearningLesson';
import { setParam } from 'common/utils/routing';

export function useUpdateElearningLessonMutation(elearningId: string) {
  const dispatch = useDispatch();

  return useMutation<unknown, unknown, ElearningLessonUpdateDTO>(
    updateElearningLessonById,
    {
      onSuccess: () => {
        dispatch(
          push(
            setParam(elearningIdRouteParam)(ElearningLessonListRoute)(
              elearningId
            )
          )
        );
        dispatch(
          snackbarActions.enqueueSnackbar({
            title: 'Lekcja została zmodyfikowana pomyślnie',
            options: { variant: 'success' },
          })
        );
        queryClient.invalidateQueries(QueryKey.ElearningLessonList);
      },
    }
  );
}
