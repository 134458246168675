import {
  DataTableCustomRenderProps,
  Loading,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react';
import { CourseEvent } from 'common/models/courseEvent';
import {
  Table,
  TableContainer,
  TableContent,
  TableLoader,
} from 'app/components/Table';
import { CourseEventParticipantListDataTableRow } from 'app/components/CourseEventParticipantList/CourseEventParticipantListDataTableRow';
import React, { FC } from 'react';
import { isEmpty } from 'ramda';
import { DataTableEmptyRow } from 'app/components/DataTableEmptyRow';
import { useDispatch, useSelector } from 'react-redux';
import { courseEventParticipantSortSelector } from 'state/courseEventParticipant/courseEventParticipantSelectors';
import { DataTableHeader } from 'app/components/DataTableHeader';
import {
  CourseEventParticipantListColumn,
  courseEventParticipantListColumnSettings,
} from 'common/models/courseEventParticipant';
import { courseEventParticipantActions } from 'state/courseEventParticipant/courseEventParticipantActions';

export interface CourseEventParticipantListDataTableContentProps {
  event?: CourseEvent;
  isFetching?: boolean;
}

export const CourseEventParticipantListDataTableContent: FC<
  CourseEventParticipantListDataTableContentProps & DataTableCustomRenderProps
> = ({
  event,
  isFetching = false,

  rows,
  headers,
  getTableProps,
  getTableContainerProps,
  getToolbarProps,
  getRowProps,
}) => {
  const dispatch = useDispatch();

  const sortData = useSelector(courseEventParticipantSortSelector);

  const isLoading = isFetching || !event;

  return (
    <TableContainer title="Uczestnicy" {...getTableContainerProps()}>
      <TableToolbar {...getToolbarProps()}>
        <TableToolbarContent />
      </TableToolbar>

      <TableContent>
        <Table $loading={isLoading} {...getTableProps()}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <DataTableHeader
                  isSortable={
                    courseEventParticipantListColumnSettings[
                      header.key as CourseEventParticipantListColumn
                    ].sortable
                  }
                  isSortHeader={sortData.orderBy === header.key}
                  sortDirection={sortData.direction}
                  onClick={(direction) => {
                    dispatch(
                      courseEventParticipantActions.setSort({
                        direction,
                        orderBy: header.key as CourseEventParticipantListColumn,
                      })
                    );
                  }}
                  key={header.key}
                >
                  {header.header}
                </DataTableHeader>
              ))}

              <TableHeader />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(rows) &&
              rows.map((row) => (
                // eslint-disable-next-line react/jsx-key
                <CourseEventParticipantListDataTableRow
                  event={event}
                  getRowProps={getRowProps}
                  key={row.id}
                  row={row}
                />
              ))}

            {isEmpty(rows) && <DataTableEmptyRow colSpan={4} />}
          </TableBody>
        </Table>

        {isLoading && (
          <TableLoader>
            <Loading active={true} withOverlay={false} />
          </TableLoader>
        )}
      </TableContent>
    </TableContainer>
  );
};
