import { AuthorizedAxios, throwErrorResponse } from 'common/services/axios';
import { flatten } from 'lib/axios';
import {
  CertificatesAssetsDTO,
  CertificatesAssetsRequestDTO,
} from 'common/models/certificateSettings';

export const getCertificateSettings = () =>
  AuthorizedAxios.get<CertificatesAssetsDTO>(
    `/api/certificates/template/assets`
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const updateCertificateSettings = (
  params: CertificatesAssetsRequestDTO
) =>
  AuthorizedAxios.put<CertificatesAssetsDTO>(
    `/api/certificates/template/assets`,
    params
  )
    .then(flatten)
    .catch(throwErrorResponse);
