import { FC } from 'react';
import { unstable_ProgressBar as ProgressBar } from 'carbon-components-react';
import fileSize from 'filesize';
import styled from 'styled-components';
import { white } from '@carbon/colors';
import {
  FileUploaderStatus,
  fileUploaderStatusColorMapper,
} from 'common/models/fileUploader';

const Container = styled.div<{ $status: FileUploaderStatus }>`
  .bx--progress-bar__bar {
    background-color: ${({ $status }) =>
      fileUploaderStatusColorMapper[$status]};
  }

  .bx--progress-bar__helper-text {
    color: ${white};
  }
`;

export interface FileUploaderProgressBarProps {
  progress: number;
  status: FileUploaderStatus;
  total: number;
}

export const FileUploaderProgressBar: FC<FileUploaderProgressBarProps> = ({
  progress,
  status,
  total,
}) => {
  return (
    <Container $status={status}>
      <ProgressBar
        helperText={
          status === FileUploaderStatus.Success
            ? 'Gotowe'
            : `${fileSize(progress)} z ${fileSize(total)}`
        }
        label=""
        max={total}
        value={progress}
      />
    </Container>
  );
};
