import { PaginationParams } from 'common/models/pagination';
import {
  AuthorizedAxios,
  CancelablePromise,
  throwErrorResponse,
} from 'common/services/axios';
import { ResultResponse } from 'common/models/response';
import { flatten } from 'lib/axios';
import {
  Elearning,
  ElearningCreateDTO,
  ElearningSortData,
  ElearningUpdateDTO,
  ElearningVideoUrl,
} from 'common/models/elearning';
import { createBlob } from 'common/services/azure';
import { LargeBlobType } from 'common/models/azure';
import Axios, { CancelToken } from 'axios';
import { ErrorCode } from 'common/models/error';
import { UploadProgress } from 'common/models/fileUploader';
import { saveData } from 'common/services/file';
import { ID } from 'lib/id';
import { uploadFileToAWS } from 'common/services/aws';

export const getElearningList = (
  { page, pageSize }: PaginationParams,
  elearningSortData: ElearningSortData,
  { categoryId }: { categoryId?: number }
) =>
  AuthorizedAxios.post<ResultResponse<Elearning>>('/api/elearnings/list', {
    categoryId,
    elearningSortData,
    page: { page, pageSize },
  })
    .then(flatten)
    .catch(throwErrorResponse);

export const getElearningById = (id: ID) =>
  AuthorizedAxios.get<Elearning>(`/api/elearnings/${id}/admin`)
    .then(flatten)
    .catch(throwErrorResponse);

export const getElearningPreview = (id: ID) =>
  AuthorizedAxios.get<ElearningVideoUrl>(`/api/elearnings/${id}/video`)
    .then(flatten)
    .catch(throwErrorResponse);

export const deleteElearning = (id: ID) =>
  AuthorizedAxios.delete<void>(`/api/elearnings/${id}`).catch(
    throwErrorResponse
  );

const prepareFormData = (params: ElearningCreateDTO | ElearningUpdateDTO) => {
  const formData = new FormData();

  formData.set(
    'elearning',
    new Blob([JSON.stringify(params.elearning)], {
      type: 'application/json',
    })
  );

  if (params.coverPhoto != null) {
    formData.set('coverPhoto', params.coverPhoto);
  }

  if (params.image != null) {
    formData.set('image', params.image);
  }

  return formData;
};

export const createElearning = (params: ElearningCreateDTO) =>
  AuthorizedAxios.post<Elearning>(`/api/elearnings`, prepareFormData(params), {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(flatten)
    .catch(throwErrorResponse);

export const updateElearningById = (params: ElearningUpdateDTO) =>
  AuthorizedAxios.put<Elearning>(
    `/api/elearnings/${params.id}`,
    prepareFormData(params),
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const updateElearningVideo = (
  elearningId: ID,
  blobId: number,
  cancelToken: CancelToken
) =>
  AuthorizedAxios.put<void>(
    `/api/elearnings/${elearningId}/video`,
    {
      id: blobId,
    },
    {
      cancelToken,
    }
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const uploadElearningPreview = (
  elearningId: number,
  file: File,
  onUploadProgress: (progress: UploadProgress) => void
) => {
  const controller = new AbortController();
  const source = Axios.CancelToken.source();

  const promise = new Promise(async (resolve, reject) => {
    try {
      const blobMetadata = await createBlob(
        LargeBlobType.ElearningVideo,
        source.token
      );

      await uploadFileToAWS(
        {
          file,
          url: blobMetadata.updateUrl,
        },
        onUploadProgress,
        controller.signal
      );

      updateElearningVideo(elearningId, blobMetadata.id, source.token).then(
        resolve
      );
    } catch (errors) {
      reject(errors);
    }
  }).catch((errors) => {
    throw errors;
  }) as CancelablePromise<void>;

  promise.cancel = () => {
    controller.abort();
    source.cancel(ErrorCode.UploadCanceled);
  };

  return promise;
};

export const exportElearningParticipantsList = (courseId: string) =>
  AuthorizedAxios.get<string>(`/api/elearnings/${courseId}/participants/export`)
    .then(flatten)
    .then(saveData(`elearning-${courseId}-participants.csv`))
    .catch(throwErrorResponse);
