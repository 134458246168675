import { createReducer } from '@reduxjs/toolkit';
import { categoryActions } from 'state/category/categoryActions';
import { PaginationState } from 'common/models/pagination';
import { Option, some } from 'fp-ts/lib/Option';

export interface CategoryState {
  pagination: Option<PaginationState>;
}

const initialState: CategoryState = {
  pagination: some({
    page: 1,
    pageSize: 1000,
  }),
};

export const categoryReducer = createReducer<CategoryState>(
  initialState,
  (builder) =>
    builder
      .addCase(categoryActions.clear, () => initialState)
      .addCase(categoryActions.setPagination, (state, action) => {
        state.pagination = some(action.payload);
      })
);
