import {
  AuthorizedAxios,
  CancelablePromise,
  throwErrorResponse,
} from 'common/services/axios';
import { flatten } from 'lib/axios';
import { createBlob } from 'common/services/azure';
import { LargeBlobType } from 'common/models/azure';
import Axios, { CancelToken } from 'axios';
import { ErrorCode } from 'common/models/error';
import { UploadProgress } from 'common/models/fileUploader';
import {
  ElearningLesson,
  ElearningLessonCreateDTO,
  ElearningLessonUpdateDTO,
  ElearningLessonUpdateVideoDTO,
  ElearningLessonUploadVideoDTO,
  ElearningLessonVideoUrl,
} from 'common/models/elearningLesson';
import { ID } from 'lib/id';
import { ListWrapper, unwrap } from 'lib/listWrapper';
import { uploadFileToAWS } from 'common/services/aws';

export const getElearningLessonList = (elearningId: ID) =>
  AuthorizedAxios.get<ListWrapper<ElearningLesson>>(
    `/api/lessons/admin/course/${elearningId}`
  )
    .then(flatten)
    .then(unwrap)
    .catch(throwErrorResponse);

export const getElearningLessonById = (id: ID) =>
  AuthorizedAxios.get<ElearningLesson>(`/api/lessons/${id}`)
    .then(flatten)
    .catch(throwErrorResponse);

export const getElearningLessonVideo = (id: ID) =>
  AuthorizedAxios.get<ElearningLessonVideoUrl>(`/api/lessons/${id}/video/admin`)
    .then(flatten)
    .catch(throwErrorResponse);

export const deleteElearningLesson = (id: ID) =>
  AuthorizedAxios.delete<void>(`/api/lessons/${id}`).catch(throwErrorResponse);

const prepareFormData = (
  params: ElearningLessonCreateDTO | ElearningLessonUpdateDTO
) => {
  const formData = new FormData();

  formData.set(
    'elearningLesson',
    new Blob([JSON.stringify(params.elearningLesson)], {
      type: 'application/json',
    })
  );

  if (params.coverPhoto != null) {
    formData.set('coverPhoto', params.coverPhoto);
  }

  return formData;
};

export const createElearningLesson = (
  elearningId: ID,
  params: ElearningLessonCreateDTO
) =>
  AuthorizedAxios.post<ElearningLesson>(
    `/api/lessons/course/${elearningId}`,
    prepareFormData(params),
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const updateElearningLessonById = (params: ElearningLessonUpdateDTO) =>
  AuthorizedAxios.put<ElearningLesson>(
    `/api/lessons/${params.id}`,
    prepareFormData(params),
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const updateElearningLessonVideo = (
  params: ElearningLessonUpdateVideoDTO,
  cancelToken: CancelToken
) =>
  AuthorizedAxios.put<void>(
    `/api/lessons/${params.elearningLessonId}/video`,
    {
      blobId: params.blobId,
      durationSeconds: params.duration,
    },
    {
      cancelToken,
    }
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const uploadElearningLessonVideo = (
  params: ElearningLessonUploadVideoDTO,
  onUploadProgress: (progress: UploadProgress) => void
) => {
  const controller = new AbortController();
  const source = Axios.CancelToken.source();

  const promise = new Promise(async (resolve, reject) => {
    try {
      const blobMetadata = await createBlob(
        LargeBlobType.ElearningLessonVideo,
        source.token
      );

      await uploadFileToAWS(
        {
          file: params.file,
          url: blobMetadata.updateUrl,
        },
        onUploadProgress,
        controller.signal
      );

      updateElearningLessonVideo(
        {
          blobId: blobMetadata.id,
          duration: params.duration,
          elearningLessonId: params.elearningLessonId,
        },
        source.token
      ).then(resolve);
    } catch (errors) {
      reject(errors);
    }
  }).catch((errors) => {
    throw errors;
  }) as CancelablePromise<void>;

  promise.cancel = () => {
    controller.abort();
    source.cancel(ErrorCode.UploadCanceled);
  };

  return promise;
};
