import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { snackbarActions } from 'state/notification/notificationActions';
import { TranslatedError } from 'common/models/error';
import { captureException } from 'common/services/sentry';
import { fileUploaderActions } from 'state/fileUploader/fileUploaderActions';
import {
  FileUploaderListItem,
  FileUploaderMapItem,
  FileUploaderUploadFn,
  UpdateProgressActionPayload,
} from 'common/models/fileUploader';
import { END, eventChannel, TakeableChannel } from 'redux-saga';
import {
  createFileUploaderItemSelector,
  fileUploaderListSelector,
  fileUploaderMapSelector,
  fileUploaderOpenSelector,
} from 'state/fileUploader/fileUploaderSelectors';
import { RootState } from 'state/reducer';
import Axios from 'axios';
import { authActions } from 'state/auth/authActions';
import { OAuthError } from 'lib/apiError';

const createUploader = (itemId: string, uploadFn: FileUploaderUploadFn) => {
  let emit: (input: UpdateProgressActionPayload | END) => void;

  const channel = eventChannel((emitter) => {
    emit = emitter;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  });

  const uploadPromise = uploadFn((event) => {
    if (event.loaded === 1) {
      emit(END);
    }

    emit({
      id: itemId,
      progress: {
        current: event.loaded,
        total: event.total,
      },
    });
  });

  return { channel, uploadPromise };
};

function* watchOnProgress(channel: TakeableChannel<unknown>) {
  while (true) {
    const data: UpdateProgressActionPayload = yield take(channel);
    yield put(fileUploaderActions.updateProgress(data));
  }
}

function* processFileUpload(
  action: ReturnType<typeof fileUploaderActions.uploadFile>
) {
  try {
    const { channel, uploadPromise } = createUploader(
      action.payload.id,
      action.payload.uploadFn
    );
    yield fork(watchOnProgress, channel);

    yield put(
      fileUploaderActions.setPromise({
        id: action.payload.id,
        promise: uploadPromise,
      })
    );

    yield call(() => uploadPromise);
    yield put(fileUploaderActions.finishUploading(action.payload.id));
    yield put(
      snackbarActions.enqueueSnackbar({
        options: { variant: 'success' },
        subtitle: action.payload.title,
        title: 'Upload pliku został zakończony pomyślnie',
      })
    );
  } catch (errors) {
    yield put(fileUploaderActions.uploadingFailed(action.payload.id));
    yield all(
      (errors as TranslatedError[]).map((translatedError) => {
        captureException(translatedError);
        return put(
          snackbarActions.enqueueSnackbar({
            subtitle: (translatedError.error as OAuthError)?.error,
            title: translatedError.message,
            options: {
              variant: Axios.isCancel(translatedError.originalError)
                ? 'warning'
                : 'error',
            },
          })
        );
      })
    );
  } finally {
    yield put(
      fileUploaderActions.setPromise({
        id: action.payload.id,
        promise: undefined,
      })
    );
  }
}

function* processLogout() {
  const list: string[] = yield select(fileUploaderListSelector);
  const map: FileUploaderMapItem = yield select(fileUploaderMapSelector);

  list?.forEach((id) => {
    map[id].promise?.cancel?.();
  });

  yield put(fileUploaderActions.reset());
}

function* processUploadCancel(
  action: ReturnType<typeof fileUploaderActions.cancelUploading>
) {
  const fileUploaderItemSelector = createFileUploaderItemSelector();

  const item: FileUploaderListItem = yield select((state: RootState) =>
    fileUploaderItemSelector(state, action.payload)
  );

  yield item.promise?.cancel?.();
  yield put(fileUploaderActions.removeItem(action.payload));
}

function* processUploadSuccess(
  action: ReturnType<typeof fileUploaderActions.finishUploading>
) {
  const open: boolean = yield select(fileUploaderOpenSelector);

  if (!open) {
    yield put(fileUploaderActions.removeItem(action.payload));
  }
}

function* cancelUploadWatcher() {
  yield takeEvery(
    fileUploaderActions.cancelUploading.type,
    processUploadCancel
  );
}

function* fileUploadWatcher() {
  yield takeEvery(fileUploaderActions.uploadFile.type, processFileUpload);
}

function* logoutWatcher() {
  yield takeEvery(authActions.logout.type, processLogout);
}

function* uploadSuccessWatcher() {
  yield takeEvery(
    fileUploaderActions.finishUploading.type,
    processUploadSuccess
  );
}

export function* fileUploaderSaga() {
  yield all([
    cancelUploadWatcher(),
    fileUploadWatcher(),
    logoutWatcher(),
    uploadSuccessWatcher(),
  ]);
}
