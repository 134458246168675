import { FC, useEffect, useMemo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  Grid,
  Pagination,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { useDispatch, useSelector } from 'react-redux';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/lib/Option';
import { initialPagination, pageSizes } from 'common/models/pagination';
import { Content } from 'common/components/Grid';
import { formatDateOnLists } from 'lib/date';
import {
  CourseBundleBasicInfoDTO,
  CourseBundleListColumn,
} from 'common/models/courseBundle';
import { courseBundlePaginationSelector } from 'state/courseBundle/courseBundleSelectors';
import { courseBundleActions } from 'state/courseBundle/courseBundleActions';
import { CourseBundleListTableContent } from 'app/components/CourseBundleList/CourseBundleListTableContent';
import { useCourseBundleListQuery } from 'common/hooks/courseBundle/useCourseBundleListQuery';
import { statusLabelMap } from 'common/models/status';

const headers = [
  {
    key: CourseBundleListColumn.Title,
    header: 'Nazwa',
  },
  {
    key: CourseBundleListColumn.CreateDate,
    header: 'Data dodania',
  },
  {
    key: CourseBundleListColumn.ModificationDate,
    header: 'Data modyfikacji',
  },
  {
    key: CourseBundleListColumn.Status,
    header: 'Status',
  },
];

const prepareCourseBundleRow = (courseBundle: CourseBundleBasicInfoDTO) => ({
  [CourseBundleListColumn.Title]: courseBundle.title,
  [CourseBundleListColumn.CreateDate]: formatDateOnLists(
    courseBundle.createdDate
  ),
  [CourseBundleListColumn.ModificationDate]: formatDateOnLists(
    courseBundle.modifiedDate
  ),
  [CourseBundleListColumn.Status]: statusLabelMap[courseBundle.status],
  id: `${courseBundle.id}`,
});

export const CourseBundleList: FC = () => {
  const dispatch = useDispatch();
  const paginationOption = useSelector(courseBundlePaginationSelector);

  // todo: refactor duplicated fragments after PTM-241 review
  const pagination = useMemo(
    () =>
      pipe(
        paginationOption,
        getOrElse(() => initialPagination)
      ),
    [paginationOption]
  );

  const {
    data,
    isFetching: isFetchingCourseBundleList,
    isLoading: isLoadingCourseBundleList,
  } = useCourseBundleListQuery({
    onSuccess: (data) => {
      if (data && data.result.length === 0 && pagination.page !== 1) {
        dispatch(
          courseBundleActions.setPagination({
            page: Math.ceil(data.totalResult / pagination.pageSize),
            pageSize: pagination.pageSize,
          })
        );
      }
    },
  });

  const rows = useMemo(() => data?.result.map(prepareCourseBundleRow), [data]);

  useEffect(() => {
    dispatch(courseBundleActions.loadPagination());

    return () => {
      dispatch(courseBundleActions.clear());
    };
  }, [dispatch]);

  const isLoading = isLoadingCourseBundleList;

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageHeading>Lista kursów</PageHeading>

        {isLoading && (
          <DataTableSkeleton headers={headers} rowCount={10}>
            {(props: DataTableCustomRenderProps) => (
              <CourseBundleListTableContent {...props} />
            )}
          </DataTableSkeleton>
        )}

        {rows && !isLoading && (
          <DataTable headers={headers} overflowMenuOnHover={false} rows={rows}>
            {(props: DataTableCustomRenderProps) => (
              <CourseBundleListTableContent
                {...props}
                isFetching={isFetchingCourseBundleList}
              />
            )}
          </DataTable>
        )}

        <Pagination
          onChange={(pagination) => {
            dispatch(
              courseBundleActions.setPagination({
                page: pagination.page,
                pageSize: pagination.pageSize,
              })
            );
          }}
          page={pagination.page}
          pageSize={pagination.pageSize}
          pageSizes={pageSizes}
          totalItems={data?.totalResult ?? 0}
        />
      </Grid>
    </Content>
  );
};
