import { PersistState } from 'redux-persist/es/types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { authReducer, AuthState } from 'state/auth/authReducer';
import {
  notificationReducer,
  NotificationState,
} from 'state/notification/notificationReducer';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import {
  requestLoadingReducer,
  RequestLoadingState,
} from 'state/requestLoading/requestLoadingReducer';
import {
  requestTimestampReducer,
  RequestTimestampState,
} from 'state/requestTimestamp/requestTimestampReducer';
import {
  requestStatusReducer,
  RequestStatusState,
} from 'state/requestStatus/requestStatusReducer';
import { categoryReducer, CategoryState } from 'state/category/categoryReducer';
import { speakerReducer, SpeakerState } from 'state/speaker/speakerReducer';
import { courseReducer, CourseState } from 'state/course/courseReducer';
import {
  courseEventReducer,
  CourseEventState,
} from 'state/courseEvent/courseEventReducer';
import {
  courseEventParticipantReducer,
  CourseEventParticipantState,
} from 'state/courseEventParticipant/courseEventParticipantReducer';
import { surveyReducer, SurveyState } from 'state/survey/surveyReducer';
import { userReducer, UserState } from 'state/user/userReducer';
import {
  materialsReducer,
  MaterialsState,
} from 'state/materials/materialsReducer';
import { partnerReducer, PartnerState } from 'state/partner/partnerReducer';
import {
  elearningReducer,
  ElearningState,
} from 'state/elearning/elearningReducer';
import {
  fileUploaderReducer,
  FileUploaderState,
} from 'state/fileUploader/fileUploaderReducer';
import {
  elearningLessonReducer,
  ElearningLessonState,
} from 'state/elearningLesson/elearningLessonReducer';
import {
  partnerUsersReducer,
  PartnerUsersState,
} from 'state/partnerUsers/partnerUsersReducer';
import { createOptionalTransform } from 'lib/optionalTransform';
import {
  courseBundleReducer,
  CourseBundleState,
} from 'state/courseBundle/courseBundleReducer';
import {
  courseBundleElearningListReducer,
  CourseBundleElearningListState,
} from 'state/courseBundleContent/elearningList/courseBundleElearningListReducer';
import {
  courseBundleEventListReducer,
  CourseBundleEventListState,
} from 'state/courseBundleContent/eventList/courseBundleEventListReducer';
import {
  courseBundleContentSwitcherReducer,
  CourseBundleContentSwitcherState,
} from 'state/courseBundleContent/contentSwitcher/contentSwitcherReducer';
import { bannerReducer, BannerState } from 'state/banner/bannerReducer';
import {
  dashboardReducer,
  DashboardState,
} from 'state/dashboard/dashboardReducer';

interface PersistPartial {
  _persist: PersistState;
}

export interface RootState {
  auth: AuthState & PersistPartial;
  banner: BannerState;
  category: CategoryState;
  course: CourseState;
  courseEvent: CourseEventState;
  courseEventParticipant: CourseEventParticipantState;
  dashboard: DashboardState;
  elearning: ElearningState;
  elearningLesson: ElearningLessonState;
  courseBundle: CourseBundleState;
  courseBundleElearningList: CourseBundleElearningListState;
  courseBundleEventList: CourseBundleEventListState;
  courseBundleContentSwitcher: CourseBundleContentSwitcherState;
  fileUploader: FileUploaderState;
  materials: MaterialsState;
  notification: NotificationState;
  partner: PartnerState;
  partnerUsers: PartnerUsersState;
  requestLoading: RequestLoadingState;
  requestTimestamp: RequestTimestampState;
  requestStatus: RequestStatusState;
  router: RouterState;
  speaker: SpeakerState;
  survey: SurveyState;
  user: UserState;
}

export const createRootReducer = (history: History) => ({
  auth: persistReducer(
    {
      storage: storage,
      key: 'auth',
      whitelist: ['token'],
      transforms: [createOptionalTransform(['token'])],
    },
    authReducer
  ),
  banner: bannerReducer,
  category: categoryReducer,
  course: courseReducer,
  courseEvent: courseEventReducer,
  courseEventParticipant: courseEventParticipantReducer,
  dashboard: dashboardReducer,
  elearning: elearningReducer,
  elearningLesson: elearningLessonReducer,
  courseBundle: courseBundleReducer,
  courseBundleElearningList: courseBundleElearningListReducer,
  courseBundleEventList: courseBundleEventListReducer,
  courseBundleContentSwitcher: courseBundleContentSwitcherReducer,
  fileUploader: fileUploaderReducer,
  materials: materialsReducer,
  notification: notificationReducer,
  partner: partnerReducer,
  partnerUsers: partnerUsersReducer,
  requestLoading: requestLoadingReducer,
  requestTimestamp: requestTimestampReducer,
  requestStatus: requestStatusReducer,
  router: connectRouter(history),
  speaker: speakerReducer,
  survey: surveyReducer,
  user: userReducer,
});
