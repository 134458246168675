import { UploadedFile } from 'common/models/uploadedFile';
import { StrapiItemResponse } from 'modules/static-pages/models/strapi';

export interface StrapiFile {
  mime: string;
  name: string;
  size: number;
  url: string;
}

export type StrapiFileFormType = string;

export const mapToUploadedFile = (
  strapiFile: StrapiItemResponse<StrapiFile>
): UploadedFile => ({
  ...strapiFile.data.attributes,
  id: strapiFile.data.id,
  status: 'saved',
});
