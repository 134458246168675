import { ID } from 'lib/id';

export enum UserRole {
  Admin = 'A',
  Trainer = 'T',
  User = 'U',
}

export enum UserRoleRequest {
  Admin = 'ADMIN',
  Trainer = 'TRAINER',
  User = 'USER',
}

export interface User {
  confirmed: boolean;
  enabled: boolean;
  firstName: string;
  id: string;
  lastName: string;
  login: string;
  role: UserRole;
  accountPlusStatus: boolean;
}

export interface UpdateUserAccountPlusStatusDTO {
  id: ID;
  accountPlusStatus: boolean;
}

export interface UserListQueryFilters {
  partnerId?: ID;
  roleId?: UserRoleRequest;
}

export const userRoleLabelMapper = (role: string) => {
  switch (role) {
    case 'A':
      return 'Administrator';
    case 'T':
      return 'Trener';
    case 'U':
      return 'Zwykły użytkownik';
    default:
      return 'Nieznane';
  }
};
