import { useFormContext } from 'react-hook-form';
import { TextInput } from 'carbon-components-react';
import React, { FC } from 'react';
import {
  OptionSurveyQuestionControlModel,
  QuestionControlType,
} from 'app/components/SurveyFormPage/surveyQuestionModel';
import {
  ControlProps,
  FormItem,
  QuestionControlAccordion,
  QuestionControlAccordionItem,
} from 'app/components/SurveyFormPage/SurveyQuestionControl/common';
import { ChoiceOptionListControl } from 'app/components/SurveyFormPage/SurveyQuestionControl/ChoiceOptionListControl';
import { useControlErrors } from 'common/hooks/useControlErrors';

export const optionSurveyQuestionDefaultValue: OptionSurveyQuestionControlModel =
  {
    type: QuestionControlType.Option,
    title: '',
    mandatory: true,
    choiceOptions: [],
  };

export const OptionSurveyQuestionControl: FC<
  ControlProps<OptionSurveyQuestionControlModel>
> = ({ label, name, defaultValue }) => {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext();

  const questionTitle = watch(`${name}.title`);
  const controlErrors = useControlErrors(errors, name);

  return (
    <QuestionControlAccordion>
      <QuestionControlAccordionItem open title={`${label}: ${questionTitle}`}>
        <FormItem>
          <TextInput
            id={`${name}.title`}
            {...register(`${name}.title`)}
            defaultValue={defaultValue?.title}
            invalid={!!controlErrors?.title}
            invalidText={controlErrors?.title?.message}
            labelText="Treść pytania"
          />
        </FormItem>
        <FormItem>
          <ChoiceOptionListControl
            name={`${name}.choiceOptions`}
            label="Możliwe odpowiedzi"
          />
        </FormItem>
      </QuestionControlAccordionItem>
    </QuestionControlAccordion>
  );
};
