import { flatten } from 'lib/axios';
import { stringify } from 'qs';
import { AuthorizedAxios, throwErrorResponse } from 'common/services/axios';
import { saveData } from 'common/services/file';
import { PaginationParams } from 'common/models/pagination';
import { ListWrapper, unwrap } from 'lib/listWrapper';
import { ResultResponse } from 'common/models/response';
import {
  BaseCourseDTO,
  BaseCourseWithEventsDTO,
  CourseBundleBasicInfoDTO,
  CourseBundleDTO,
  CourseBundleSortData,
  CourseBundleUpdateDTO,
  CreateBundleDTO,
} from 'common/models/courseBundle';
import { SpeakerNameInfo } from 'common/models/speaker';
import { ElearningBasicInfoDTO } from 'common/models/elearning';
import {
  CourseBundleElearningListSortData,
  CourseBundleEventListSortData,
} from 'common/models/courseBundleContent';
import {
  CourseEventListFilters,
  EventStatusListItem,
} from 'common/models/courseEvent';
import { ID } from 'lib/id';

export const getCourseBundleList = (
  page: PaginationParams,
  sortData: CourseBundleSortData
) =>
  AuthorizedAxios.get<ResultResponse<CourseBundleBasicInfoDTO>>(
    `/api/admin/course_bundles?${stringify(
      { page, sortData },
      {
        allowDots: true,
      }
    )}`
  )
    .then(flatten)
    .catch(throwErrorResponse);

const prepareFormData = ({ createBundleDTO, image }: CourseBundleUpdateDTO) => {
  const formData = new FormData();

  formData.set(
    'createBundleDTO',
    new Blob([JSON.stringify(createBundleDTO)], {
      type: 'application/json',
    })
  );

  if (image != null) {
    formData.set('image', image);
  }

  return formData;
};

export const getCourseBundleById = (bundleId: ID) =>
  AuthorizedAxios.get<CourseBundleDTO>(`/api/admin/course_bundles/${bundleId}`)
    .then(flatten)
    .catch(throwErrorResponse);

export const createCourseBundle = ({
  createBundleDTO,
  image,
}: Required<CourseBundleUpdateDTO>) =>
  AuthorizedAxios.post<CourseBundleDTO>(
    `/api/admin/course_bundles`,
    prepareFormData({ createBundleDTO, image }),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const updateCourseBundle = ({
  bundleId,
  createBundleDTO,
  image,
}: {
  bundleId: ID;
  createBundleDTO: CreateBundleDTO;
  image?: File;
}) =>
  AuthorizedAxios.put<CourseBundleDTO>(
    `/api/admin/course_bundles/${bundleId}`,
    prepareFormData({ createBundleDTO, image }),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const deleteCourseBundle = (bundleId: number) =>
  AuthorizedAxios.delete(`/api/admin/course_bundles/${bundleId}`)
    .then(flatten)
    .catch(throwErrorResponse);

export const getCourseBundleElearningList = (
  bundleId: ID,
  page: PaginationParams,
  sortData: CourseBundleElearningListSortData,
  categoryId?: number
) =>
  AuthorizedAxios.get<ResultResponse<ElearningBasicInfoDTO>>(
    `/api/admin/course_bundles/${bundleId}/elearnings?${stringify(
      { page, sortData, categoryId },
      {
        allowDots: true,
      }
    )}`
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const getCourseBundleEventList = (
  bundleId: ID,
  page: PaginationParams,
  sortData: CourseBundleEventListSortData,
  filters?: CourseEventListFilters
) =>
  AuthorizedAxios.get<ResultResponse<EventStatusListItem>>(
    `/api/admin/course_bundles/${bundleId}/events?${stringify(
      { page, sortData, ...filters },
      {
        allowDots: true,
      }
    )}`
  )
    .then(flatten)
    .catch(throwErrorResponse);

const checkForBundleId = (bundleId?: ID) =>
  bundleId ? `?bundleId=${bundleId}` : '';

export const getCourseBundleAssignableElearnings = (bundleId?: ID) =>
  AuthorizedAxios.get<ListWrapper<BaseCourseDTO>>(
    `/api/admin/course_bundles/elearnings${checkForBundleId(bundleId)}`
  )
    .then(flatten)
    .then(unwrap)
    .catch(throwErrorResponse);

export const getCourseBundleAssignableEvents = (bundleId?: ID) =>
  AuthorizedAxios.get<ListWrapper<BaseCourseWithEventsDTO>>(
    `/api/admin/course_bundles/events${checkForBundleId(bundleId)}`
  )
    .then(flatten)
    .then(unwrap)
    .catch(throwErrorResponse);

export const getCourseBundleAssignableSpeakers = (
  elearningIds?: number[],
  eventIds?: number[]
) =>
  AuthorizedAxios.get<ListWrapper<SpeakerNameInfo>>(
    `/api/admin/course_bundles/speakers?${stringify(
      { elearningIds, eventIds },
      {
        allowDots: true,
        arrayFormat: 'comma',
        encode: false,
      }
    )}`
  )
    .then(flatten)
    .then(unwrap)
    .catch(throwErrorResponse);

export const updateCourseBundleEvents = (bundleId: ID, eventIds: number[]) =>
  AuthorizedAxios.put<CourseBundleDTO>(
    `/api/admin/course_bundles/${bundleId}/events`,
    eventIds
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const updateCourseBundleElearnings = (
  bundleId: ID,
  elearningIds: number[]
) =>
  AuthorizedAxios.put<CourseBundleDTO>(
    `/api/admin/course_bundles/${bundleId}/elearnings`,
    elearningIds
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const exportCourseBundleParticipantList = (bundleId: ID) =>
  AuthorizedAxios.get<string>(`/api/admin/course_bundles/${bundleId}/report`)
    .then(flatten)
    .then(saveData(`course-${bundleId}-participants.csv`))
    .catch(throwErrorResponse);
