import { useCallback, useEffect } from 'react';

export const useWindowUnloadPrompt = (enabled: boolean) => {
  const handleBeforeUnload = useCallback((event) => {
    const message = 'Are you sure you want to exit?';

    event.preventDefault();
    event.returnValue = message;

    return message;
  }, []);

  useEffect(() => {
    if (enabled) {
      window.addEventListener('beforeunload', handleBeforeUnload, {
        capture: true,
      });
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload, {
        capture: true,
      });
    };
  }, [enabled]);
};
