import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ElearningListRoute } from 'routes';
import { snackbarActions } from 'state/notification/notificationActions';
import { queryClient } from 'common/providers/ReactQueryClientProvider';
import { QueryKey } from 'config/queryKey';
import { updateElearningById } from 'common/services/elearning';

export function useUpdateElearningMutation() {
  const dispatch = useDispatch();

  return useMutation(updateElearningById, {
    onSuccess: () => {
      dispatch(push(ElearningListRoute));
      dispatch(
        snackbarActions.enqueueSnackbar({
          title: 'E-learning został zmodyfikowany pomyślnie',
          options: { variant: 'success' },
        })
      );
      queryClient.invalidateQueries(QueryKey.Elearning);
    },
  });
}
