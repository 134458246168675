import React, { FC, useState } from 'react';
import { QuestionType } from 'common/models/survey';
import { Button, ComboBox } from 'carbon-components-react';
import { Add20 } from '@carbon/icons-react';
import styled from 'styled-components';
import { StyleProps } from 'lib/styleProps';

interface QuestionTypeSelectionItem {
  id: QuestionType;
  label: string;
}

const questionTypeSelectionItems: QuestionTypeSelectionItem[] = [
  { id: QuestionType.Scale, label: 'Ocena' },
  { id: QuestionType.Option, label: 'Zamknięte jednokrotnego wyboru' },
  { id: QuestionType.MultiOption, label: 'Zamknięte wielokrotnego wyboru' },
];

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  & > .bx--list-box__wrapper {
    flex-grow: 1;
  }
`;

export interface QuestionTypeSelectionControlProps {
  onAdd: (value: QuestionType) => void;
}

export const QuestionTypeSelectionControl: FC<
  StyleProps & QuestionTypeSelectionControlProps
> = ({ className, style, onAdd }) => {
  const [selectedQuestionType, setSelectedQuestionType] =
    useState<QuestionType>();

  return (
    <Container className={className} style={style}>
      <ComboBox<QuestionTypeSelectionItem>
        onChange={({ selectedItem }) =>
          selectedItem?.id && setSelectedQuestionType(selectedItem?.id)
        }
        id="question-type-selector"
        items={questionTypeSelectionItems}
        itemToString={(item) => (item ? item.label : '')}
        placeholder="Wybierz rodzaj pytania"
      />

      <Button
        hasIconOnly
        kind="secondary"
        iconDescription="Dodaj pytanie"
        renderIcon={Add20}
        size="field"
        tooltipAlignment="center"
        tooltipPosition="top"
        onClick={() => selectedQuestionType && onAdd(selectedQuestionType)}
      />
    </Container>
  );
};
