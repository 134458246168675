import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { snackbarActions } from 'state/notification/notificationActions';
import { push } from 'connected-react-router';
import { SpeakerListRoute } from 'routes';
import { updateSpeakerById } from 'common/services/speaker';
import { SpeakerDTO, SpeakerRequestDTO } from 'common/models/speaker';

export const useUpdateSpeakerMutation = (id: string) => {
  const dispatch = useDispatch();

  return useMutation<SpeakerDTO, unknown, SpeakerRequestDTO>(
    (params) => updateSpeakerById(id, params),
    {
      onSuccess: () => {
        dispatch(push(SpeakerListRoute));
        dispatch(
          snackbarActions.enqueueSnackbar({
            title: 'Wykładowca został zapisany pomyślnie',
            options: { variant: 'success' },
          })
        );
      },
    }
  );
};
