import styled from 'styled-components';
import { Accordion, AccordionItem } from 'carbon-components-react';

export interface ControlProps<T> {
  name: string;
  label?: string;
  defaultValue?: T;
  disabled?: boolean;
}

export interface SimpleListItem<T> {
  value: T;
  label: string;
}

export const QuestionControlAccordion = styled(Accordion)`
  margin-bottom: 2rem;
`;

export const QuestionControlAccordionItem = styled(AccordionItem)`
  border: none;
  &:last-child {
    border: none;
  }

  .bx--accordion__content {
    margin-bottom: -2rem;
    padding-right: 1rem;
  }
`;

export const FormItem = styled.div`
  margin-bottom: 2rem;
`;
