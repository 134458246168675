import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';

const requestStatusStateSelector = (state: RootState) => state.requestStatus;

export const createRequestStatusSelector = () =>
  createSelector(
    [
      requestStatusStateSelector,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      (_: RootState, actionName: string) => actionName,
    ],
    (state, actionName) => state[actionName]
  );
