import { Add20 } from '@carbon/icons-react';
import { DataTableEmptyRow } from 'app/components/DataTableEmptyRow';
import {
  Table,
  TableContainer,
  TableContent,
  TableLoader,
} from 'app/components/Table';
import {
  Button,
  DataTableCustomRenderProps,
  Loading,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react';
import { BannerListTableRow } from 'modules/banner/components/BannerListTableRow';
import { isEmpty } from 'ramda';
import { Link } from 'react-router-dom';
import { BannerCreateRoute } from 'routes';

export function BannerListTableContent({
  isFetching,
  rows,
  headers,
  getBatchActionProps,
  getTableProps,
  getTableContainerProps,
  getToolbarProps,
  getHeaderProps,
  getRowProps,
}: DataTableCustomRenderProps & { isFetching: boolean }) {
  return (
    <TableContainer title="Banery reklamowe" {...getTableContainerProps()}>
      <TableToolbar {...getToolbarProps()}>
        <TableToolbarContent>
          <Button
            as={Link}
            to={BannerCreateRoute}
            kind="primary"
            renderIcon={Add20}
            size="small"
            tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
          >
            Dodaj nowy baner
          </Button>
        </TableToolbarContent>
      </TableToolbar>

      <TableContent>
        <Table $loading={isFetching} {...getTableProps()}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableHeader {...getHeaderProps({ header })} key={header.key}>
                  {header.header}
                </TableHeader>
              ))}

              <TableHeader />
            </TableRow>
          </TableHead>

          <TableBody>
            {!isEmpty(rows) &&
              rows.map((row) => (
                <BannerListTableRow
                  getRowProps={getRowProps}
                  key={row.id}
                  row={row}
                />
              ))}

            {isEmpty(rows) && <DataTableEmptyRow colSpan={5} />}
          </TableBody>
        </Table>

        {isFetching && (
          <TableLoader>
            <Loading active={true} withOverlay={false} />
          </TableLoader>
        )}
      </TableContent>
    </TableContainer>
  );
}
