import { Controller, useFormContext } from 'react-hook-form';
import {
  Checkbox,
  TextInput,
  TileAboveTheFoldContent,
  TileBelowTheFoldContent,
} from 'carbon-components-react';
import React, { FC } from 'react';
import {
  MultiOptionSurveyQuestionControlModel,
  QuestionControlType,
} from 'app/components/SurveyFormPage/surveyQuestionModel';
import {
  ControlProps,
  FormItem,
  QuestionControlAccordion,
  QuestionControlAccordionItem,
} from 'app/components/SurveyFormPage/SurveyQuestionControl/common';
import { ChoiceOptionListControl } from 'app/components/SurveyFormPage/SurveyQuestionControl/ChoiceOptionListControl';
import { ExpandableTileBlock } from 'app/components/ExpandableTileBlock';
import { useControlErrors } from 'common/hooks/useControlErrors';

export const multiOptionSurveyQuestionDefaultValue: MultiOptionSurveyQuestionControlModel =
  {
    type: QuestionControlType.MultiOption,
    title: '',
    mandatory: true,
    choiceOptions: [],
    customOptionAllowed: false,
    customOptionLabel: '',
    customOptionPlaceholder: '',
  };

export const MultiOptionSurveyQuestionControl: FC<
  ControlProps<MultiOptionSurveyQuestionControlModel>
> = ({ label, name, defaultValue }) => {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const customOptionAllowed = watch(`${name}.customOptionAllowed`);
  const questionTitle = watch(`${name}.title`);
  const controlErrors = useControlErrors(errors, name);

  return (
    <QuestionControlAccordion>
      <QuestionControlAccordionItem open title={`${label}: ${questionTitle}`}>
        <FormItem>
          <TextInput
            id={`${name}.title`}
            {...register(`${name}.title`)}
            defaultValue={defaultValue?.title}
            invalid={!!controlErrors?.title}
            invalidText={controlErrors?.title?.message}
            labelText="Treść pytania"
          />
        </FormItem>

        <FormItem>
          <ChoiceOptionListControl
            name={`${name}.choiceOptions`}
            label="Możliwe odpowiedzi"
          />
        </FormItem>

        <ExpandableTileBlock
          isExpanded={customOptionAllowed}
          showChevron={false}
        >
          <TileAboveTheFoldContent>
            <FormItem>
              <Controller
                control={control}
                name={`${name}.customOptionAllowed`}
                render={({ field: { onChange, value, name } }) => (
                  <Checkbox
                    id={name}
                    labelText="Pozwól na wprowadzanie odpowiedzi przez użytkownika"
                    checked={!!value}
                    onChange={onChange}
                  />
                )}
              />
            </FormItem>
          </TileAboveTheFoldContent>
          <TileBelowTheFoldContent>
            <FormItem>
              <TextInput
                id={`${name}.customOptionLabel`}
                {...register(`${name}.customOptionLabel`)}
                defaultValue={defaultValue?.customOptionLabel}
                invalid={!!controlErrors?.customOptionLabel}
                invalidText={controlErrors?.customOptionLabel?.message}
                labelText="Etykieta"
              />
            </FormItem>

            <FormItem>
              <TextInput
                id={`${name}.customOptionPlaceholder`}
                {...register(`${name}.customOptionPlaceholder`)}
                defaultValue={defaultValue?.customOptionPlaceholder}
                invalid={!!controlErrors?.customOptionPlaceholder}
                invalidText={controlErrors?.customOptionPlaceholder?.message}
                labelText="Przykładowa wartość (placeholder)"
              />
            </FormItem>
          </TileBelowTheFoldContent>
        </ExpandableTileBlock>
      </QuestionControlAccordionItem>
    </QuestionControlAccordion>
  );
};
