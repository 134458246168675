import React, { FC, useCallback } from 'react';
import {
  Button,
  ComposedModal,
  DatePicker,
  DatePickerInput,
  Form,
  InlineLoading,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'carbon-components-react';
import { Portal } from 'common/components/Portal';
import styled from 'styled-components';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { setYupDefaultMessages } from 'common/services/yup';
import {
  initialUserListExportFormValues,
  UserListExportFormModel,
} from 'common/models/userListExport';
import { userListExportFormValidationSchema } from 'app/components/UserList/config';
import { format } from 'lib/date';
import { bodyLong01 } from '@carbon/type';
import { useUserListExportMutation } from 'common/hooks/user/useUserListExportMutation';

const Link = styled.a`
  cursor: pointer;
  ${bodyLong01}
`;

const Row = styled.div`
  margin-bottom: 1rem;
`;

const StyledInlineLoading = styled(InlineLoading)`
  min-height: 0;
`;

const Text = styled.p`
  margin-bottom: 1rem;
  ${bodyLong01}
`;

const OverflowModal = styled(ComposedModal)`
  & .bx--modal-container-body {
    min-height: 0;

    display: grid;
    grid-template-rows: min-content 1fr min-content;
  }
`;

setYupDefaultMessages();

export interface UserListExportModalProps {
  onClose: () => void;
  open: boolean;
}

export const UserListExportModal: FC<UserListExportModalProps> = ({
  onClose,
  open,
}) => {
  const userListExportMutation = useUserListExportMutation();

  const handleSubmit = useCallback(
    (values) => {
      userListExportMutation.mutate(
        values.fromDate && values.toDate ? values : undefined,
        {
          onSuccess: () => onClose(),
        }
      );
    },
    [onClose]
  );

  const methods = useForm<UserListExportFormModel>({
    defaultValues: initialUserListExportFormValues,
    resolver: yupResolver(userListExportFormValidationSchema),
    shouldFocusError: false,
  });

  return (
    <Portal>
      <OverflowModal open={open} size="xs" onClose={onClose}>
        <ModalHeader>
          <h3 className="bx--modal-header__heading">
            Eksportuj listę użytkowników
          </h3>
        </ModalHeader>

        <ModalBody hasForm>
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(handleSubmit)} noValidate>
              <Text>
                Wybierz zakres dat do eksportu.
                <br />
                W przypadku braku podania dat zostaną zwróceni wszyscy
                użytkownicy.
                <br />
              </Text>

              <Row>
                <Controller
                  control={methods.control}
                  name="fromDate"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <DatePicker
                      dateFormat="Y-m-d"
                      datePickerType="single"
                      locale="pl"
                      onChange={(newDate) => {
                        onChange(format(newDate[0], 'uuuu-LL-dd'));
                      }}
                      value={value}
                    >
                      <DatePickerInput
                        id="fromDate"
                        invalid={!!error}
                        invalidText={error?.message}
                        labelText="Od"
                        pattern=".*"
                        placeholder="yyyy-mm-dd"
                      />
                    </DatePicker>
                  )}
                />
              </Row>

              <Row>
                <Controller
                  control={methods.control}
                  name="toDate"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <DatePicker
                      dateFormat="Y-m-d"
                      datePickerType="single"
                      locale="pl"
                      onChange={(newDate) => {
                        onChange(format(newDate[0], 'uuuu-LL-dd'));
                      }}
                      value={value}
                    >
                      <DatePickerInput
                        id="toDate"
                        invalid={!!error}
                        invalidText={error?.message}
                        labelText="Do"
                        pattern=".*"
                        placeholder="yyyy-mm-dd"
                      />
                    </DatePicker>
                  )}
                />
              </Row>

              <Link onClick={() => methods.reset()}>Wyczyść</Link>
            </Form>
          </FormProvider>
        </ModalBody>

        <ModalFooter onRequestClose={onClose} secondaryButtonText="Anuluj">
          {userListExportMutation.isLoading && (
            <Button disabled size="lg">
              <StyledInlineLoading
                description="Eksportowanie..."
                status="active"
              />
            </Button>
          )}

          {!userListExportMutation.isLoading && (
            <Button
              size="lg"
              onClick={methods.handleSubmit(handleSubmit)}
              type="button"
            >
              Eksportuj
            </Button>
          )}
        </ModalFooter>
      </OverflowModal>
    </Portal>
  );
};
