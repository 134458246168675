import { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 1rem;
`;

export interface HelperTextProps {
  allowedExtensions: string[];
}

export const AllowedExtensionsHelperText: FC<HelperTextProps> = ({
  allowedExtensions,
}) => (
  <Container className="bx--form__helper-text">
    Maksymalny rozmiar pliku 1mb.&nbsp;
    {allowedExtensions.length === 1 &&
      `Obługiwany typ pliku: ${allowedExtensions[0]}`}
    {allowedExtensions.length > 1 &&
      `Obługiwane typy plików: ${allowedExtensions.join(', ')}`}
  </Container>
);
