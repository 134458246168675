import {
  ArrowDown20,
  ArrowUp20,
  Draggable20,
  TrashCan20,
} from '@carbon/icons-react';
import styled from 'styled-components';

import { FC } from 'react';
import { Button, Tile } from 'carbon-components-react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const Container = styled.div`
  outline: none; // attributes passed from DND set tabindex="0" which wil apply outline in safari for this element
`;

const SortButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTile = styled(Tile)`
  align-items: flex-start;
  display: flex;
  margin: 0.125rem 0;
`;

export interface SortableItemProps {
  id: string;
  light?: boolean;
  draggable?: boolean;
  onDelete?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}

export const SortableItem: FC<SortableItemProps> = ({
  id,
  children,
  onDelete,
  light,
  draggable = true,
  onMoveUp,
  onMoveDown,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || '',
  };

  return (
    <Container ref={setNodeRef} style={style} {...attributes}>
      <StyledTile light={light}>
        <SortButtonContainer>
          {onMoveUp && (
            <Button
              hasIconOnly
              iconDescription="Przenieś wyżej"
              kind="ghost"
              renderIcon={ArrowUp20}
              size="small"
              tooltipAlignment="center"
              tooltipPosition="top"
              onClick={onMoveUp}
            />
          )}
          {draggable && (
            <Button
              hasIconOnly
              iconDescription="Przenieś"
              kind="ghost"
              renderIcon={Draggable20}
              size="small"
              tooltipAlignment="center"
              tooltipPosition="top"
              {...listeners}
            />
          )}
          {onMoveDown && (
            <Button
              hasIconOnly
              iconDescription="Przenieś niżej"
              kind="ghost"
              renderIcon={ArrowDown20}
              size="small"
              tooltipAlignment="center"
              tooltipPosition="top"
              onClick={onMoveDown}
            />
          )}
        </SortButtonContainer>

        {children}

        <Button
          hasIconOnly
          iconDescription="Usuń"
          kind="danger--ghost"
          renderIcon={TrashCan20}
          size="small"
          tooltipAlignment="center"
          tooltipPosition="top"
          onClick={onDelete}
        />
      </StyledTile>
    </Container>
  );
};
