import { mixed, object, string } from 'yup';

export const partnerFormValidationSchema = object({
  name: string().required(),
  imageFile: mixed().test((value, context) => {
    const { currentImage } = context.parent;
    return !(currentImage == null && value == null);
  }),
});

export const fieldsOrder = ['title', 'duration', 'agenda', 'coverPhotoFile'];
