import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';

const requestLoadingStateSelector = (state: RootState) => state.requestLoading;

export const createRequestLoadingSelector = () =>
  createSelector(
    [
      requestLoadingStateSelector,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      (_: RootState, actionName: string) => actionName,
    ],
    (state, actionName) => state[actionName]
  );
