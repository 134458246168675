import React, { FC, useEffect, useMemo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  Grid,
} from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import {
  ElearningFormRoute,
  elearningIdRouteParam,
  ElearningListRoute,
  RootRoute,
} from 'routes';
import { PageHeading } from 'common/components/Heading';
import { useDispatch } from 'react-redux';
import { Content } from 'common/components/Grid';
import {
  ElearningLesson,
  ElearningLessonListColumn,
} from 'common/models/elearningLesson';
import { useElearningLessonListQuery } from 'common/hooks/elearningLesson/useElearningLessonListQuery';
import { elearningLessonActions } from 'state/elearningLesson/elearningLessonActions';
import { useElearningByIdQuery } from 'common/hooks/elearning/useElearningByIdQuery';
import { ElearningLessonListContent } from 'app/components/ElearningLessonList/ElearningLessonListContent';
import { setParam } from 'common/utils/routing';

const headers = [
  {
    key: ElearningLessonListColumn.Title,
    header: 'Nazwa',
  },
];

const prepareElearningLessonRow = (elearningLesson: ElearningLesson) => ({
  [ElearningLessonListColumn.Title]: elearningLesson.title,
  id: `${elearningLesson.id}`,
});

export interface ElearningLessonListUrlParams {
  elearningId: string;
}

export const ElearningLessonList: FC = () => {
  const dispatch = useDispatch();
  const { elearningId } = useParams<ElearningLessonListUrlParams>();

  const { data: elearningData, isLoading: isElearningLoading } =
    useElearningByIdQuery(elearningId);

  const {
    data,
    isFetching: isFetchingElearningLesson,
    isLoading: isLoadingElearningLesson,
  } = useElearningLessonListQuery(elearningId);

  const rows = useMemo(() => data?.map(prepareElearningLessonRow), [data]);

  useEffect(() => {
    dispatch(elearningLessonActions.loadPagination());
  }, [dispatch]);

  const isLoading = isElearningLoading || isLoadingElearningLesson;

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={ElearningListRoute}>Lista e-learningów</Link>
          </BreadcrumbItem>

          {elearningData && (
            <BreadcrumbItem>
              <Link
                to={setParam(elearningIdRouteParam)(ElearningFormRoute)(
                  elearningId
                )}
              >
                {elearningData.title}
              </Link>
            </BreadcrumbItem>
          )}
        </Breadcrumb>

        <PageHeading>Lista lekcji</PageHeading>

        {isLoading && (
          <DataTableSkeleton headers={headers} rowCount={10}>
            {(props: DataTableCustomRenderProps) => (
              <ElearningLessonListContent {...props} />
            )}
          </DataTableSkeleton>
        )}

        {rows && !isLoading && (
          <DataTable headers={headers} overflowMenuOnHover={false} rows={rows}>
            {(props: DataTableCustomRenderProps) => (
              <ElearningLessonListContent
                {...props}
                isFetching={isFetchingElearningLesson}
              />
            )}
          </DataTable>
        )}
      </Grid>
    </Content>
  );
};
