import {
  Button,
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import { Copy20, Edit20, List20 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import {
  PartnerFormRoute,
  partnerIdRouteParam,
  PartnerUsersListRoute,
} from 'routes';
import { ActionsTableCell } from 'app/components/Table';
import { FC, useMemo, useRef, useState } from 'react';
import { DataTableDeleteButton } from 'app/components/DataTableDeleteButton';
import { useDeletePartnerMutation } from 'common/hooks/partner/useDeletePartnerMutation';
import { Partner } from 'common/models/partner';
import copy from 'copy-to-clipboard';
import { getPartnerUrlFragment } from 'config/partner';
import { setParam } from 'common/utils/routing';

const RegistrationUrl: FC<{ partnerCode: string }> = ({ partnerCode }) => {
  const [copied, setCopied] = useState(false);
  const ref = useRef<undefined | HTMLButtonElement>(undefined);

  const urlFragment = useMemo(
    () => getPartnerUrlFragment(partnerCode),
    [partnerCode]
  );

  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        ref={ref}
        hasIconOnly
        iconDescription={
          copied ? 'Fragment URL został skopiowany' : 'Kopiuj fragment URL'
        }
        onClick={() => {
          copy(urlFragment);
          setCopied(true);
        }}
        kind="secondary"
        renderIcon={Copy20}
        size="small"
        tooltipAlignment="center"
        tooltipPosition="left"
        style={{ marginRight: 8 }}
        onPointerLeave={() => {
          setCopied(false);
          ref.current?.blur();
        }}
      />
      <span>{urlFragment}</span>
    </span>
  );
};

export const preparePartnerRow = (a: Partner) => ({
  id: `${a.id}`,
  partnerCode: a.partnerCode,
  partnerName: a.partnerName,
  registrationUrl: <RegistrationUrl partnerCode={a.partnerCode} />,
});

export interface PartnerListDataTableRowProps {
  row: DenormalizedRow;
}

export const PartnerListDataTableRow: FC<
  PartnerListDataTableRowProps & Pick<DataTableCustomRenderProps, 'getRowProps'>
> = ({ getRowProps, row }) => {
  const deleteMutation = useDeletePartnerMutation();

  return (
    <TableRow {...getRowProps({ row })}>
      {row.cells.map((cell) => (
        <TableCell key={cell.id}>{cell.value}</TableCell>
      ))}

      <ActionsTableCell>
        <Button
          as={Link}
          hasIconOnly
          iconDescription="Użytkownicy"
          kind="ghost"
          renderIcon={List20}
          size="small"
          to={setParam(partnerIdRouteParam)(PartnerUsersListRoute)(row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <Button
          as={Link}
          hasIconOnly
          iconDescription="Edytuj"
          kind="ghost"
          renderIcon={Edit20}
          size="small"
          to={setParam(partnerIdRouteParam)(PartnerFormRoute)(row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <DataTableDeleteButton
          confirmationTitle="Usuń partnera"
          isLoading={deleteMutation.isLoading}
          onSubmit={() => deleteMutation.mutate(row.id)}
        />
      </ActionsTableCell>
    </TableRow>
  );
};
