import { FileUploaderStatus } from 'common/models/fileUploader';
import { FileUploaderState } from 'state/fileUploader/fileUploaderReducer';
import { Draft } from 'immer';
import { countLabelFormatter } from 'lib/countLabelFormatter';

export const fileUploaderFileCountFormatter = countLabelFormatter(
  '0 plików',
  '1 pliku',
  (count) => `${count} plików`,
  (count) => `${count} plików`
);

export const removeFinishedItems = (state: Draft<FileUploaderState>) => {
  const ongoing: string[] = [];

  state.list.forEach((itemId) => {
    if (state.map[itemId].status === FileUploaderStatus.Uploading) {
      ongoing.push(itemId);
      return;
    }

    delete state.map[itemId];
  });

  state.list = ongoing;
};
