import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';
import { PartnerState } from 'state/partner/partnerReducer';

export const partnerStateSelector = (state: RootState) => state.partner;

export const partnerPaginationSelector = createSelector(
  partnerStateSelector,
  (state: PartnerState) => state.pagination
);
