import { keys } from 'ramda';
import { useCallback, useMemo } from 'react';

export const useScrollToError = (fieldsOrder: string[]) => {
  const orderMap = useMemo(() => {
    const orderMap: { [key: string]: number } = {};

    fieldsOrder.forEach((item, index) => {
      orderMap[item] = index;
    });

    return orderMap;
  }, [fieldsOrder]);

  return useCallback(
    (errors: unknown) => {
      let firstErrorIndex = fieldsOrder.length;

      keys(errors).forEach((key) => {
        if (firstErrorIndex > orderMap[key]) {
          firstErrorIndex = orderMap[key];
        }
      });

      document
        .getElementById(fieldsOrder[firstErrorIndex])
        ?.scrollIntoView({ behavior: `smooth`, block: 'center' });
    },
    [orderMap]
  );
};
