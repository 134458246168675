import { CourseEventStatus } from 'common/models/courseEvent';
import { SpeakerDTO } from 'common/models/speaker';

export enum DashboardColumn {
  TITLE = 'TITLE',
  DATE = 'DATE',
  ATTENDEES = 'ATTENDEES',
  STATUS = 'STATUS',
  INSTRUCTOR = 'INSTRUCTOR',
}

export type EventsInWeekDTO = {
  weekStart: string;
  weekEnd: string;
  events: {
    id: string;
    courseId: string;
    courseTitle: string;
    startDateTime: string;
    speaker: SpeakerDTO;
    participantsAccepted: string;
    status: CourseEventStatus;
  }[];
  page: number;
  totalPages: number;
};

export type DashboardEvent = EventsInWeekDTO['events'][number];
