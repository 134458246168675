import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { lensPath, set } from 'ramda';
import { pipe } from 'fp-ts/function';
import { chainNullableK, fold, fromNullable } from 'fp-ts/Option';
import { store } from 'state';
import { authActions } from 'state/auth/authActions';
import { snackbarActions } from 'state/notification/notificationActions';
import { tokenSelector } from 'state/auth/authSelectors';
import * as TaskEither from 'fp-ts/TaskEither';
import { Auth } from 'common/models/auth';
import * as Either from 'fp-ts/Either';
import * as Task from 'fp-ts/Task';
import { queryClient } from 'common/providers/ReactQueryClientProvider';
import { QueryKey } from 'config/queryKey';
import { refreshToken } from 'common/services/user';
import { isPast, parseISO } from 'date-fns';
import { setAuthHeader } from 'common/utils/http';

export const flatten = <T>(response: AxiosResponse<T>) => response.data;

export const setCmsAuthHeader: typeof setAuthHeader = (token) => (config) =>
  set(lensPath(['headers', 'Backend-Token']), `${token}`, config);

export const showSessionExpiredMessage = () =>
  store.dispatch(
    snackbarActions.enqueueSnackbar({
      key: 'logout-warning',
      title: 'Twoja sesja wygasła. Zaloguj się ponownie.',
      options: {
        variant: 'warning',
      },
    })
  );

export const unauthorizedGuard = (axiosError: AxiosError) =>
  pipe(
    fromNullable(axiosError),
    chainNullableK((error) => error.response),
    fold(
      () => Promise.reject(axiosError),
      (e) => {
        if (e.status === 401) {
          store.dispatch(authActions.logout());
          showSessionExpiredMessage();
        }
        return Promise.reject(axiosError);
      }
    )
  );

/**
 * As there is no on success, we have to update persisted data, and do it inside so it will be only called once when queryFn resolves
 * Fetch query returns the same promise when loading, therefore we avoid multiple calls
 * @param token
 */
const refreshTokenQuery = (token: string) =>
  queryClient.fetchQuery(
    QueryKey.RefreshToken,
    () =>
      refreshToken(token).then((auth) => {
        store.dispatch(authActions.updateAuth(auth));
        return auth;
      }),
    {
      retry: false,
    }
  );

export const attachAccessToken =
  (setHeader: typeof setAuthHeader) => (config: AxiosRequestConfig) =>
    pipe(
      store.getState(),
      tokenSelector,
      fold(
        () =>
          TaskEither.tryCatch(
            (): Promise<Auth> => Promise.reject(null),
            Either.toError
          ),
        (token) =>
          TaskEither.tryCatch((): Promise<Auth> => {
            if (
              token.expires_at == null ||
              isPast(parseISO(token.expires_at))
            ) {
              return refreshTokenQuery(token.refresh_token);
            }

            return Promise.resolve(token);
          }, Either.toError)
      ),
      Task.map(
        Either.fold(
          () => {
            store.dispatch(authActions.logout());
            showSessionExpiredMessage();
            return config;
          },
          (auth) => setHeader(auth.access_token)(config)
        )
      ),
      (task) => task()
    );
