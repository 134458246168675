import { Course } from 'common/models/course';
import { ID } from 'lib/id';

export interface CourseData {
  course: Course;
  status: 'pending' | 'finished';
}

export type CourseIds = ID[];
export type CourseMap = Record<ID, CourseData>;

export enum Steps {
  Loading = -1,
  RewriteCategories,
  DeleteValidation,
  DeleteConfirmation,
}
