import { useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { CourseEventListRoute, isCreateParam } from 'routes';
import { snackbarActions } from 'state/notification/notificationActions';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { getCourseEventById } from 'common/services/courseEvent';
import { CourseEvent } from 'common/models/courseEvent';
import { isNilEmpty } from 'lib/isNilEmpty';
import { setParam } from 'common/utils/routing';

export const useCourseEventByIdQuery = (
  courseId: string,
  eventId: string,
  onSuccess?: (data: CourseEvent) => void
) => {
  const dispatch = useDispatch();

  return useQuery(
    [QueryKey.CourseEvent, eventId],
    () => getCourseEventById(eventId),
    {
      enabled: !isNilEmpty(eventId) && !isCreateParam(eventId),
      onError: () => {
        dispatch(push(setParam(':courseId')(CourseEventListRoute)(courseId)));
        dispatch(
          snackbarActions.enqueueSnackbar({
            title: 'Wydarzenie nie zostało znalezione',
            options: { variant: 'error' },
          })
        );
      },
      onSuccess,
    }
  );
};
