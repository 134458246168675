import { AuthorizedAxios, throwErrorResponse } from 'common/services/axios';
import { flatten } from 'lib/axios';
import {
  SystemSettingsCode,
  SystemSettingsDTO,
} from 'common/models/systemSettings';

export const getSystemSettings = () =>
  AuthorizedAxios.get<SystemSettingsDTO>(
    `/api/settings/${SystemSettingsCode.PresentPercentage}`
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const updateSystemSettings = (params: SystemSettingsDTO) =>
  AuthorizedAxios.put<SystemSettingsDTO>(`/api/settings`, params)
    .then(flatten)
    .catch(throwErrorResponse);
