import { useMutation } from 'react-query';
import { deleteCategory } from 'common/services/category';
import { queryClient } from 'common/providers/ReactQueryClientProvider';
import { QueryKey } from 'config/queryKey';
import { push } from 'connected-react-router';
import { CategoryListRoute } from 'routes';
import { snackbarActions } from 'state/notification/notificationActions';
import { useDispatch } from 'react-redux';

export const useDeleteCategoryMutation = () => {
  const dispatch = useDispatch();

  return useMutation(deleteCategory, {
    onSuccess: () => {
      dispatch(push(CategoryListRoute));
      dispatch(
        snackbarActions.enqueueSnackbar({
          title: 'Kategoria została usunięta pomyślnie',
          options: { variant: 'success' },
        })
      );
      queryClient.invalidateQueries(QueryKey.CategoryList);
    },
  });
};
