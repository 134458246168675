import { QueryObserverOptions, useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { ResultResponse } from 'common/models/response';
import { useSelector } from 'react-redux';
import { getOrElse, isSome } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/function';
import { initialPagination } from 'common/models/pagination';
import { Elearning } from 'common/models/elearning';
import {
  elearningCategorySelector,
  elearningPaginationSelector,
  elearningSortSelector,
} from 'state/elearning/elearningSelectors';
import { getElearningList } from 'common/services/elearning';

export const useElearningListQuery = (
  options: QueryObserverOptions<ResultResponse<Elearning>>
) => {
  const filterCategoryOption = useSelector(elearningCategorySelector);
  const paginationOption = useSelector(elearningPaginationSelector);
  const sortData = useSelector(elearningSortSelector);

  const filterCategory = pipe(
    filterCategoryOption,
    getOrElse(() => 'undefined')
  );

  const pagination = pipe(
    paginationOption,
    getOrElse(() => initialPagination)
  );

  return useQuery<ResultResponse<Elearning>>(
    [
      QueryKey.Elearning,
      pagination.page,
      pagination.pageSize,
      sortData,
      filterCategory,
    ],
    () =>
      getElearningList(pagination, sortData, {
        categoryId: isSome(filterCategoryOption)
          ? Number(filterCategory)
          : undefined,
      }),
    {
      ...options,
      enabled: isSome(paginationOption),
      keepPreviousData: true,
    }
  );
};
