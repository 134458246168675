import { FC, useCallback, useState } from 'react';
import { DataTableButton } from 'app/components/DataTableButton';
import { TrashCan20 } from '@carbon/icons-react';
import { ConfirmationModal } from 'common/components/ConfirmationModal';
import { useDeleteMaterialMutation } from 'common/hooks/materials/useDeleteMaterialMutation';
import { materialsActions } from 'state/materials/materialsActions';
import { Material, MaterialStatus } from 'common/models/material';
import { useDispatch } from 'react-redux';

export interface CourseMaterialsDeleteButtonProps {
  material: Material;
}

export const MaterialsDeleteButton: FC<CourseMaterialsDeleteButtonProps> = ({
  material,
}) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteMutation = useDeleteMaterialMutation(material.id);

  const handleDelete = useCallback(() => {
    if (material.status === MaterialStatus.New) {
      dispatch(materialsActions.delete(material.id));
      return;
    }

    deleteMutation.mutate(material.id);
  }, [material]);

  return (
    <>
      <DataTableButton
        hasIconOnly
        iconDescription="Usuń"
        isLoading={false}
        kind="danger--ghost"
        onClick={() => setShowDeleteModal(true)}
        renderIcon={TrashCan20}
        size="small"
        tooltipAlignment="center"
        tooltipPosition="top"
      />

      <ConfirmationModal
        buttonSubmitText="Usuń"
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={handleDelete}
        text="Czy na pewno chcesz usunąć wybrany element?"
        title="Usuń materiał szkoleniowy"
      />
    </>
  );
};
