import { createAction } from 'lib/createAction';
import {
  Material,
  MaterialDTO,
  MaterialList,
  MaterialsFileFormModel,
  MaterialStatus,
  MaterialsUrlFormModel,
} from 'common/models/material';

export interface MaterialsUpdateStatusPayload {
  id: string;
  status: MaterialStatus;
}

export interface MaterialsUpdateFilePayload {
  id: string;
  values: MaterialsFileFormModel;
}

export interface MaterialsUpdateUrlPayload {
  id: string;
  values: MaterialsUrlFormModel;
}

export interface MaterialsFileFormModalPayload {
  material: Material;
}

export interface MaterialsUrlFormModalPayload {
  material: Material | null;
}

export const materialsActions = {
  addFiles: createAction('materials/addFiles')<File[]>(),
  addUrl: createAction('materials/addUrl')<MaterialsUrlFormModel>(),
  clear: createAction('materials/clear')(),
  closeFileFormModal: createAction('materials/closeFileFormModal')(),
  closeUrlFormModal: createAction('materials/closeUrlFormModal')(),
  delete: createAction('materials/delete')<string>(),
  setParentId: createAction('materials/setParentId')<string | undefined>(),
  setMaterial: createAction('materials/setMaterial')<MaterialDTO>(),
  setMaterials: createAction('materials/setMaterials')<MaterialList>(),
  showFileFormModal: createAction(
    'materials/showFileFormModal'
  )<MaterialsFileFormModalPayload>(),
  showUrlFormModal: createAction(
    'materials/showUrlFormModal'
  )<MaterialsUrlFormModalPayload>(),
  updateFile: createAction(
    'materials/updateFile'
  )<MaterialsUpdateFilePayload>(),
  updateUrl: createAction('materials/updateUrl')<MaterialsUpdateUrlPayload>(),
  updateStatus: createAction(
    'materials/updateStatus'
  )<MaterialsUpdateStatusPayload>(),
} as const;
