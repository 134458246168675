import { QueryObserverOptions, useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { ResultResponse } from 'common/models/response';
import { useSelector } from 'react-redux';
import { getOrElse, isSome } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/function';
import { initialPagination } from 'common/models/pagination';
import { getCourseBundleElearningList } from 'common/services/courseBundle';
import { ElearningBasicInfoDTO } from 'common/models/elearning';
import {
  courseBundleElearningListCategorySelector,
  courseBundleElearningListPaginationSelector,
  courseBundleElearningListSortSelector,
} from 'state/courseBundleContent/elearningList/courseBundleElearningListSelectors';
import { ID } from 'lib/id';

export const useCourseBundleElearningListQuery = (
  bundleId: ID,
  options?: QueryObserverOptions<ResultResponse<ElearningBasicInfoDTO>>
) => {
  const filterCategoryOption = useSelector(
    courseBundleElearningListCategorySelector
  );
  const paginationOption = useSelector(
    courseBundleElearningListPaginationSelector
  );
  const sortData = useSelector(courseBundleElearningListSortSelector);

  const filterCategory = pipe(
    filterCategoryOption,
    getOrElse(() => 'undefined')
  );

  const pagination = pipe(
    paginationOption,
    getOrElse(() => initialPagination)
  );

  const categoryId = isSome(filterCategoryOption)
    ? Number(filterCategory)
    : undefined;

  return useQuery<ResultResponse<ElearningBasicInfoDTO>>(
    [
      QueryKey.CourseBundleElearningList,
      pagination.page,
      pagination.pageSize,
      sortData,
      filterCategory,
    ],
    () =>
      getCourseBundleElearningList(bundleId, pagination, sortData, categoryId),
    {
      enabled: isSome(paginationOption),
      keepPreviousData: true,
      ...options,
    }
  );
};
