import { format as dateFnsFormat, parseISO } from 'date-fns';
import { pl } from 'date-fns/locale';
import { appLocale } from 'config/locale';
import { flatten, range } from 'ramda';
import { pipe } from 'fp-ts/function';
import { coerceUTCDateString } from 'lib/coerceUTCDateString';

const locales = {
  'pl-PL': pl,
};

export const format = (date: Date | number, format: string) =>
  dateFnsFormat(date, format, { locale: locales[appLocale] });

export const timePickerItems = flatten(
  range(0, 24).map((hour) =>
    ['00', '15', '30', '45'].map(
      (minutes) =>
        `${hour.toLocaleString('pl-PL', {
          minimumIntegerDigits: 2,
        })}:${minutes}`
    )
  )
);

export const formatDateOnLists = (date?: string) =>
  date
    ? pipe(coerceUTCDateString(date), parseISO, (a) =>
        format(a, 'dd.MM.yyyy HH:mm')
      )
    : '';
