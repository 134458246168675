import { createSelector } from 'reselect';
import { DashboardState } from 'state/dashboard/dashboardReducer';
import { RootState } from 'state/reducer';

export const dashboardStateSelector = (state: RootState) => state.dashboard;

export const dashboardPaginationSelector = createSelector(
  dashboardStateSelector,
  (state: DashboardState) => state.pagination
);
