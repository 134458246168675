import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { FileUploaderItem } from 'app/components/FileUploader/FileUploaderItem';
import { ScrollContainer } from 'common/components/ScrollContainer';
import { fileUploaderListSelector } from 'state/fileUploader/fileUploaderSelectors';
import { useSelector } from 'react-redux';

const Container = styled.div`
  display: flex;
  grid-area: list;
`;

const Content = styled.div<{ padding: boolean }>`
  padding-right: ${({ padding }) => (padding ? '8px' : '0')};
`;

export const FileUploaderList: FC = () => {
  const [padding, setPadding] = useState(false);

  const list = useSelector(fileUploaderListSelector);

  return (
    <Container>
      <ScrollContainer
        options={{
          callbacks: {
            onOverflowChanged: (args) => {
              args?.yScrollable ? setPadding(true) : setPadding(false);
            },
          },
        }}
      >
        <Content padding={padding}>
          {list.map((itemId) => (
            <FileUploaderItem key={itemId} itemId={itemId} />
          ))}
        </Content>
      </ScrollContainer>
    </Container>
  );
};
