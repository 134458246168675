import { ConditionalRoute } from 'common/components/ConditionalRoute';
import { RouteProps } from 'react-router-dom';
import { FC } from 'react';
import { LoginRoute } from 'routes';
import { useSelector } from 'react-redux';
import { isUserSomeSelector } from 'state/auth/authSelectors';

export const PrivateRoute: FC<RouteProps> = (props) => {
  const isLoggedIn = useSelector(isUserSomeSelector);

  return (
    <ConditionalRoute {...props} condition={isLoggedIn} redirect={LoginRoute} />
  );
};
