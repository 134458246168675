import React, { FC } from 'react';
import styled from 'styled-components';
import { spacing06, spacing08 } from '@carbon/layout';
import { Button, Loading } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { CategoryListRoute } from 'routes';
import { useDeleteCategoryMutation } from 'common/hooks/category/useDeleteCategoryMutation';
import { LoadingButton } from 'app/components/Button';
import { ID } from 'lib/id';

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  .bx--btn {
    justify-content: flex-start;
    width: 12.5rem;
  }
`;

const LoadingButtonText = styled.span`
  color: #8d8d8d;
  margin-left: 0.625rem;
`;

const Content = styled.div`
  margin: ${spacing08} auto;
  max-width: 37.5rem;
`;

const Text = styled.p`
  margin: 0 0 ${spacing06};
`;

export interface CategoryDeleteStep3Props {
  categoryId: ID;
}

export const CategoryDeleteStep3: FC<CategoryDeleteStep3Props> = ({
  categoryId,
}) => {
  const deleteMutation = useDeleteCategoryMutation();

  return (
    <Content>
      <Text>Czy na pewno chcesz usunąć wybraną kategorię?</Text>

      <Actions>
        <Button as={Link} kind="secondary" to={CategoryListRoute}>
          Wróć do listy
        </Button>

        {!deleteMutation.isLoading && (
          <Button
            kind="danger"
            onClick={() => deleteMutation.mutate(categoryId)}
          >
            Usuń
          </Button>
        )}

        {deleteMutation.isLoading && (
          <LoadingButton disabled kind="danger">
            <Loading small withOverlay={false} />{' '}
            <LoadingButtonText>Usuwanie...</LoadingButtonText>
          </LoadingButton>
        )}
      </Actions>
    </Content>
  );
};
