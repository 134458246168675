// eslint-disable-next-line @typescript-eslint/no-explicit-any

export interface ApiErrorItem {
  errorCode: string;
  message: string;
}

export interface ErrorMessages {
  [key: string]: string;
}

export interface TranslatedError {
  error?: unknown;
  message: string;
  originalError: Error;
}

export enum ErrorCode {
  FS_UFE = 'FS.UFE',
  CategoryDoNotExists = 'CategoryDoNotExists',
  RequestCanceled = 'RequestCanceled',
  UploadCanceled = 'UploadCanceled',
  WrongRole = 'WrongRole',
}

export const errorMessageMap: Record<ErrorCode, string> = {
  [ErrorCode.FS_UFE]: 'Błędna nazwa pliku',
  [ErrorCode.CategoryDoNotExists]: 'Kategoria nie istnieje',
  [ErrorCode.RequestCanceled]: 'Zapytanie do API zostało przerwane',
  [ErrorCode.UploadCanceled]: 'Wysyłanie pliku/ów zostało przerwane',
  [ErrorCode.WrongRole]: 'Brak uprawnień administracyjnych',
};
