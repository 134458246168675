import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';

export const elearningStateSelector = (state: RootState) => state.elearning;

export const elearningCategorySelector = createSelector(
  elearningStateSelector,
  (state) => state.category
);

export const elearningPaginationSelector = createSelector(
  elearningStateSelector,
  (state) => state.pagination
);

export const elearningSortSelector = createSelector(
  elearningStateSelector,
  (state) => state.sort
);
