import React, { FC, useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { findIndex, propEq } from 'ramda';
import { Button, FormLabel } from 'carbon-components-react';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { ErrorMessage as ErrorMessageHK } from '@hookform/error-message';
import { Add20 } from '@carbon/icons-react';
import styled from 'styled-components';
import { ErrorMessage } from 'app/components/ErrorMessage';

const ButtonContainer = styled.div`
  text-align: right;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  padding-bottom: 1rem;
`;

export interface SortableItemProps {
  controlId: string;
  index: number;
  name: string;
  onDelete: (id: string) => void;
}

export interface AboutUsListControlProps {
  getEmptyItem: () => Partial<unknown>;
  label?: string;
  name: string;
  SortableItem: FC<SortableItemProps>;
}

export const AboutUsListControl: FC<AboutUsListControlProps> = ({
  getEmptyItem,
  label,
  name,
  SortableItem,
}) => {
  const { fields, append, remove, move } = useFieldArray({
    name,
  });
  const {
    formState: { errors },
  } = useFormContext();

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (over && active.id !== over.id) {
        const oldIndex = findIndex(propEq('id', active.id))(fields);
        const newIndex = findIndex(propEq('id', over.id))(fields);

        move(oldIndex, newIndex);
      }
    },
    [fields]
  );

  return (
    <>
      {label && <FormLabel>{label}</FormLabel>}

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={fields} strategy={verticalListSortingStrategy}>
          {fields.map((row, index) => (
            <SortableItem
              controlId={row.id}
              index={index}
              key={row.id}
              name={name}
              onDelete={() => remove(index)}
            />
          ))}

          {fields.length === 0 && (
            <ErrorMessageHK
              as={<StyledErrorMessage className="bx--form-requirement" />}
              errors={errors}
              name={name}
            />
          )}

          <ButtonContainer>
            <Button
              hasIconOnly
              iconDescription="Dodaj"
              kind="secondary"
              onClick={() => append(getEmptyItem())}
              renderIcon={Add20}
              size="small"
              tooltipAlignment="center"
              tooltipPosition="top"
            />
          </ButtonContainer>
        </SortableContext>
      </DndContext>
    </>
  );
};
