import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { ComboBox, FormLabel, NumberInput } from 'carbon-components-react';
import {
  ControlProps,
  SimpleListItem,
} from 'app/components/SurveyFormPage/SurveyQuestionControl/common';
import { ComparisonOperator } from 'app/components/SurveyFormPage/surveyQuestionModel';
import { ChangeEventImaginaryTarget } from 'lib/carbonExtraTypes';
import { useControlErrors } from 'common/hooks/useControlErrors';
import { ErrorBlock } from 'app/components/ErrorBlock';

export interface ConditionControlModel {
  comparisonOperator: ComparisonOperator;
  comparisonValue: number; // 1-5 or ''
}

const questionTypeSelectionItems: SimpleListItem<ComparisonOperator>[] = [
  { value: ComparisonOperator.LessThan, label: '<' },
  { value: ComparisonOperator.LessThanOrEqual, label: '<=' },
  { value: ComparisonOperator.Equal, label: '=' },
  { value: ComparisonOperator.GreaterThan, label: '>' },
  { value: ComparisonOperator.GreaterThanOrEqual, label: '>=' },
];

const Container = styled.div`
  width: 100%;
  height: 3.75rem;
`;

const ControlGroup = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: min-content 8rem 10rem;
  column-gap: 0.5rem;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  max-height: 2.5rem;
`;

export const ConditionControl: FC<ControlProps<ConditionControlModel>> = ({
  name,
  label,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const controlErrors = useControlErrors(errors, name);

  return (
    <Container>
      <FormLabel>{label}</FormLabel>
      <ControlGroup>
        <Text>Wartość</Text>
        <Controller
          control={control}
          name={`${name}.comparisonOperator`}
          render={({
            field: { onChange, name, value },
            fieldState: { error },
          }) => (
            <ComboBox<SimpleListItem<ComparisonOperator>>
              id={name}
              name={name}
              items={questionTypeSelectionItems}
              itemToString={(item) => (item ? item.label : '')}
              placeholder="Operator"
              onChange={({ selectedItem }) => onChange(selectedItem?.value)}
              selectedItem={
                questionTypeSelectionItems?.find(
                  (item) => item.value === value
                ) ?? null
              }
              invalid={!!error}
              invalidText={''}
            />
          )}
        />

        <Controller
          control={control}
          name={`${name}.comparisonValue`}
          render={({
            field: { name, value, onChange },
            fieldState: { error },
          }) => (
            <NumberInput
              id={name}
              name={name}
              onChange={(
                ev:
                  | React.ChangeEvent<HTMLInputElement>
                  | React.MouseEvent<HTMLButtonElement>
              ) =>
                onChange(
                  (ev as ChangeEventImaginaryTarget<HTMLInputElement>)
                    .imaginaryTarget.value
                )
              }
              allowEmpty
              min={1}
              max={5}
              value={value ?? ''}
              hideLabel
              invalid={!!error}
              invalidText={''}
            />
          )}
        />
      </ControlGroup>
      <ErrorBlock>
        {controlErrors && 'Wymagana poprawna definicja warunku'}
      </ErrorBlock>
    </Container>
  );
};
