import React, { FC, useCallback, useState } from 'react';
import Downshift, { DownshiftState, StateChangeOptions } from 'downshift';
import ListBox from 'carbon-components-react/lib/components/ListBox';
import styled from 'styled-components';
import { TextInput, Tile } from 'carbon-components-react';
import { findIndex, isNil, propEq } from 'ramda';
import { CourseParameter } from 'common/models/course';
import { AnySchema } from 'yup/lib/schema';
import { ValidationError } from 'yup';
import { ErrorsList } from 'app/components/ErrorsList';

const FieldWrapper = styled.div`
  .bx--text-input__field-outer-wrapper {
    position: relative;

    .bx--form-requirement {
      left: 0;
      position: absolute;
      top: 100%;
    }
  }
`;

const InputWrapper = styled.div`
  padding-bottom: 1rem;
  position: relative;
  width: 100%;
`;

const StyledTile = styled(Tile)`
  min-height: 0;
  padding: 0.8125rem 1rem;
`;

export interface TypeaheadDropdownProps {
  id: string;
  invalid: boolean;
  invalidText?: string;
  inputValidationSchema?: AnySchema;
  items: CourseParameter[];
  onSelect?: (value: CourseParameter | null) => void;
}

export const TypeaheadDropdown: FC<TypeaheadDropdownProps> = ({
  id,
  invalid,
  invalidText,
  inputValidationSchema,
  items,
  onSelect,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [inputErrors, setInputErrors] = useState<string[] | undefined>(
    undefined
  );

  const downshiftReducer = useCallback(
    (
      state: DownshiftState<CourseParameter>,
      changes: StateChangeOptions<CourseParameter>
    ) => {
      let highlightedIndex;

      switch (changes.type) {
        case Downshift.stateChangeTypes.clickItem:
        case Downshift.stateChangeTypes.keyDownEnter:
          return {
            ...changes,
            inputValue: '',
            selectedItem: null,
          };
        case Downshift.stateChangeTypes.blurInput:
        case Downshift.stateChangeTypes.mouseUp:
          return {
            ...changes,
            inputValue: state.inputValue,
            selectedItem: null,
          };
        case Downshift.stateChangeTypes.changeInput:
          highlightedIndex = findIndex(propEq('value', changes.inputValue))(
            items
          );

          return {
            ...changes,
            highlightedIndex:
              highlightedIndex > -1 ? highlightedIndex : items.length,
          };
        case undefined:
          highlightedIndex = findIndex(propEq('value', state.inputValue))(
            items
          );

          return {
            ...changes,
            highlightedIndex:
              highlightedIndex > -1 ? highlightedIndex : items.length,
          };
        default:
          return changes;
      }
    },
    [items]
  );

  return (
    <Downshift
      initialInputValue={inputValue}
      itemToString={(item) => item?.value || ''}
      onSelect={(x) => onSelect && onSelect(x)}
      onStateChange={({ inputValue }) => {
        if (!isNil(inputValue)) {
          setInputValue(inputValue);
          inputValidationSchema &&
            inputValidationSchema
              .validate(inputValue)
              .then(() => setInputErrors(undefined))
              .catch((e: ValidationError) => {
                setInputErrors(e.errors);
              });
        }
      }}
      stateReducer={downshiftReducer}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getRootProps,
        highlightedIndex,
        isOpen,
        openMenu,
        selectedItem,
      }) => (
        <InputWrapper {...getRootProps()}>
          <FieldWrapper>
            <TextInput
              {...getInputProps()}
              hideLabel={true}
              id={`${id}`}
              invalid={invalid}
              invalidText={invalidText}
              labelText=""
              onClick={() => openMenu()}
              placeholder="Szukaj.."
            />
          </FieldWrapper>
          <ListBox.Menu style={{ maxHeight: '13.75rem' }} {...getMenuProps()}>
            {isOpen &&
              ((inputValue && inputValue.length <= 2) || !inputValue) && (
                <StyledTile light={false}>
                  Podaj przynajmniej 3 znaki..
                </StyledTile>
              )}

            {isOpen && inputErrors && <ErrorsList errors={inputErrors} />}

            {isOpen &&
              items.length > 0 &&
              inputValue &&
              inputValue.length > 2 &&
              items
                .filter(
                  (item) => !inputValue || item.value.includes(inputValue)
                )
                .map((item, index) => (
                  <ListBox.MenuItem
                    isActive={selectedItem === item}
                    isHighlighted={
                      highlightedIndex === index ||
                      (selectedItem && selectedItem === item) ||
                      false
                    }
                    key={item.id}
                    tabIndex="-1"
                    title={item.value}
                    {...getItemProps({
                      index,
                      item,
                    })}
                  >
                    {item.value}
                  </ListBox.MenuItem>
                ))}

            {isOpen && !inputErrors && inputValue && inputValue.length > 2 && (
              <ListBox.MenuItem
                isActive={false}
                isHighlighted={highlightedIndex === items.length}
                tabIndex="-1"
                {...getItemProps({
                  index: items.length,
                  item: {
                    id: 'new',
                    value: inputValue,
                  },
                })}
              >
                Dodaj <strong>{inputValue}</strong>
              </ListBox.MenuItem>
            )}
          </ListBox.Menu>
        </InputWrapper>
      )}
    </Downshift>
  );
};
