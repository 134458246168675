import * as yup from 'yup';
import { mixed, object } from 'yup';

const courseBundleSlugValidationSchema = yup
  .string()
  .ensure()
  .max(255)
  .matches(/^[a-z0-9\-]*$/, {
    excludeEmptyString: true,
    message:
      'Slug może zawierać jedynie małe litery z przedziału (a-z - bez polskich znaków), cyfry oraz znak -',
  })
  .test(
    'containsLetters',
    'Slug musi zawierać co najmniej jedną małą literą z przedziału (a-z - bez polskich znaków)',
    (slug) => !!slug?.match(/[a-z]/)
  )
  .test(
    'notStartWithDash',
    'Slug musi zaczynać się małą literą z przedziału (a-z - bez polskich znaków) albo cyfrą',
    (slug) => !slug?.match(/^-/)
  )
  .test(
    'notEndsWithDash',
    'Slug musi kończyć się małą literą z przedziału (a-z - bez polskich znaków) albo cyfrą',
    (slug) => !slug?.match(/-$/)
  )
  .required();

const courseBundleSectionValidationSchema = {
  heading: yup
    .string()
    .max(100, 'Wartość może mieć maksymalnie 100 znaków')
    .required('Nagłówek do sekcji jest wymagany'),
  content: yup.string(),
};

export const courseBundleFormValidationSchema = object({
  title: yup.string().max(100).required(),
  slug: courseBundleSlugValidationSchema,
  seoDescription: yup.string().required(),
  sections: yup
    .array()
    .of(object().shape(courseBundleSectionValidationSchema))
    .min(1, 'Wymagana jest przynajmniej jedna sekcja')
    .required(),
  partnerIds: yup.array().max(10),
  eventIds: yup.lazy(() =>
    yup.array().when('elearningIds', {
      is: (elearningIds: []) => !elearningIds || elearningIds.length === 0,
      then: yup
        .array()
        .min(1, 'Wymagany jest przynajmniej jeden e-learning lub jeden event')
        .required(),
      otherwise: yup.array(),
    })
  ),
  elearningIds: yup.lazy(() =>
    yup.array().when('eventIds', {
      is: (eventIds: []) => !eventIds || eventIds.length === 0,
      then: yup
        .array()
        .min(1, 'Wymagany jest przynajmniej jeden e-learning lub jeden event')
        .required(),
      otherwise: yup.array(),
    })
  ),
  speakerIds: yup.array(),
  imageFile: mixed().test((value, context) => {
    const { currentImage } = context.parent;
    return !(currentImage == null && value == null);
  }),
});

export const fieldsOrder = [
  'title',
  'slug',
  'seoDescription',
  'sections',
  'partnerIds',
  'eventIds',
  'elearningIds',
  'speakerIds',
  'imageFile',
];
