import {
  DataTableCustomRenderProps,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react';
import { Table, TableContainer, TableContent } from 'app/components/Table';
import React, { FC } from 'react';
import { StaticPageListRow } from 'modules/static-pages/components/StaticPageList/StaticPageListRow';
import { staticPages } from 'modules/static-pages/models/staticPageItem';

export const StaticPageListContent: FC<DataTableCustomRenderProps> = ({
  rows,
  headers,
  getTableProps,
  getTableContainerProps,
  getToolbarProps,
  getRowProps,
}) => {
  return (
    <TableContainer title="Strony statyczne" {...getTableContainerProps()}>
      <TableToolbar {...getToolbarProps()}>
        <TableToolbarContent />
      </TableToolbar>

      <TableContent>
        <Table $loading={false} {...getTableProps()}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableHeader key={header.key}>{header.header}</TableHeader>
              ))}

              <TableHeader />
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, index) => (
              <StaticPageListRow
                getRowProps={getRowProps}
                key={row.id}
                row={row}
                staticPage={staticPages[index]}
              />
            ))}
          </TableBody>
        </Table>
      </TableContent>
    </TableContainer>
  );
};
