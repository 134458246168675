import { TableColumnSettings } from 'common/models/tableSettings';
import { SortDirection } from 'common/models/pagination';

export interface CourseBundleContentUrlParams {
  courseBundleId: string;
}

export enum CourseBundleContentCategory {
  ElearningList = 'elearnings',
  EventList = 'events',
}

//-------------------- E-learnings --------------------
export enum CourseBundleElearningListColumn {
  Title = 'COURSE_TITLE',
  CategoryName = 'CATEGORY',
  Status = 'STATUS',
}

export interface CourseBundleElearningListSortData {
  direction: SortDirection;
  orderBy: CourseBundleElearningListColumn;
}

export const CourseBundleElearningListColumnSettings: Record<
  CourseBundleElearningListColumn,
  TableColumnSettings
> = {
  [CourseBundleElearningListColumn.Title]: { sortable: true },
  [CourseBundleElearningListColumn.CategoryName]: { sortable: true },
  [CourseBundleElearningListColumn.Status]: { sortable: true },
};

//-------------------- Events --------------------
export enum CourseBundleEventListColumn {
  Title = 'COURSE_TITLE',
  ParticipantsAccepted = 'PARTICIPANTS_ACCEPTED',
  ParticipantsPresent = 'PARTICIPANTS_PRESENT',
  StartDateTime = 'START_DATE_TIME',
  Status = 'STATUS',
}

export interface CourseBundleEventListSortData {
  direction: SortDirection;
  orderBy: CourseBundleEventListColumn;
}

export const courseBundleEventListColumnSettings: Record<
  CourseBundleEventListColumn,
  TableColumnSettings
> = {
  [CourseBundleEventListColumn.Title]: { sortable: true },
  [CourseBundleEventListColumn.ParticipantsAccepted]: { sortable: true },
  [CourseBundleEventListColumn.ParticipantsPresent]: { sortable: true },
  [CourseBundleEventListColumn.StartDateTime]: { sortable: true },
  [CourseBundleEventListColumn.Status]: { sortable: true },
};
