import React, { FC } from 'react';
import { Breadcrumb, BreadcrumbItem, Grid } from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import {
  CourseBundleIdRoute,
  courseBundleIdRouteParam,
  CourseBundleListRoute,
  RootRoute,
} from 'routes';
import { Content } from 'common/components/Grid';
import { MaterialResource } from 'common/models/material';
import { Materials } from 'app/components/Materials';
import { setParam } from 'common/utils/routing';
import { CourseBundleContentUrlParams } from 'common/models/courseBundleContent';
import { useCourseBundleByIdQuery } from 'common/hooks/courseBundle/useCourseBundleByIdQuery';

export const CourseBundleMaterials: FC = () => {
  const { courseBundleId } = useParams<CourseBundleContentUrlParams>();
  const { data: courseBundleData } = useCourseBundleByIdQuery(courseBundleId);

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={CourseBundleListRoute}>Lista kursów</Link>
          </BreadcrumbItem>

          {courseBundleData && (
            <BreadcrumbItem>
              <Link
                to={setParam(courseBundleIdRouteParam)(CourseBundleIdRoute)(
                  courseBundleId
                )}
              >
                {courseBundleData.title}
              </Link>
            </BreadcrumbItem>
          )}
        </Breadcrumb>

        <Materials
          parentId={courseBundleId}
          resource={MaterialResource.CourseBundle}
          title="Materiały dodatkowe"
        />
      </Grid>
    </Content>
  );
};
