import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { Checkbox } from 'carbon-components-react';
import { parseISO } from 'date-fns';
import { format } from 'lib/date';
import { coerceUTCDateString } from 'lib/coerceUTCDateString';
import { transparentize } from 'polished';
import { DropdownComponentProps } from 'app/components/CourseBundleForm/NestedMultiSelect/DropdownComponentProps';
import { BaseEventDTO } from 'common/models/courseBundle';
import { gray50 } from '@carbon/colors';
import { DropdownArrowIcon } from 'app/components/CourseBundleForm/NestedMultiSelect/DropdownArrowIcon';
import { useFormContext } from 'react-hook-form';
import { CheckboxSelectAll } from 'common/components/CheckboxSelectAll';

const Container = styled.div<DropdownComponentProps>`
  width: 0;
  height: 0;
  overflow: hidden;

  visibility: ${(props) => (props.$dropdownHidden ? 'hidden' : 'visible')};

  ${(props) =>
    props.$dropdownHidden
      ? css`
          height: 0;
        `
      : css`
          width: 100%;
          height: max-content;
          background-color: inherit;

          & > * {
            padding: 11px 40px;
          }

          & .bx--form-item.bx--checkbox-wrapper:hover {
            background-color: ${transparentize(0.88, gray50)};
          }
        `}
`;

const Title = styled.div`
  display: flex;

  & .bx--form-item.bx--checkbox-wrapper {
    padding: 11px 24px 11px 0;
    margin: 0 16px;
  }

  &:hover {
    background-color: ${transparentize(0.8, gray50)};
  }
`;

const DropdownIconControl = styled.div`
  padding: 11px 0;
  margin: 0 16px;
`;

interface NestedMultiSelectItemProps {
  title: string;
  checkboxId: string;
  items: BaseEventDTO[];
  initialSelectedItems: number[];
  onChange: (...e: unknown[]) => void;
}

export type NestedMultiSelectItemRef = {
  clearAll: () => void;
};

export const NestedMultiSelectItem = forwardRef<
  NestedMultiSelectItemRef,
  NestedMultiSelectItemProps
>(({ title, checkboxId, items, initialSelectedItems, onChange }, ref) => {
  const { setValue } = useFormContext();
  const [checkboxListHidden, setCheckboxListHidden] = useState(true);
  const allCheckboxIds = useMemo(() => items.map((item) => item.id), [items]);

  const handleSelect = (id: number) =>
    initialSelectedItems?.includes(id)
      ? initialSelectedItems.filter((el) => el !== id)
      : [...initialSelectedItems, id];

  const clearAll = useCallback(() => {
    setValue('eventIds', []);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      clearAll,
    }),
    []
  );

  return (
    <div>
      <Title onClick={() => setCheckboxListHidden((prevState) => !prevState)}>
        <CheckboxSelectAll
          id={`nested-multiselect-item-checkbox-${checkboxId}`}
          labelText={title}
          allDependentCheckboxIds={allCheckboxIds}
          initialSelectedDependentCheckboxIds={initialSelectedItems}
          onChange={onChange}
        />
        <DropdownIconControl tabIndex={0}>
          <DropdownArrowIcon $dropdownHidden={checkboxListHidden} />
        </DropdownIconControl>
      </Title>
      <Container $dropdownHidden={checkboxListHidden}>
        {items?.map((item) => (
          <Checkbox
            key={item.id}
            id={`${item.id}`}
            labelText={format(
              parseISO(coerceUTCDateString(item.startDateTime)),
              'dd.MM.yyyy, HH:mm'
            )}
            checked={initialSelectedItems?.includes(item.id)}
            onChange={() => onChange(handleSelect(item.id))}
          />
        ))}
      </Container>
    </div>
  );
});
