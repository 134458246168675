import { FieldErrors, FieldValues, get } from 'react-hook-form';
import { useMemo } from 'react';

export function useControlErrors<T extends FieldValues>(
  errors: FieldErrors<T>,
  name: string
) {
  return useMemo(() => {
    return get(errors, name);
  }, [errors]);
}
