import { PaginationParams } from 'common/models/pagination';
import { AuthorizedAxios, throwErrorResponse } from 'common/services/axios';
import { ResultResponse } from 'common/models/response';
import { flatten } from 'lib/axios';
import {
  Partner,
  PartnerCreateDTO,
  PartnerUpdateDTO,
} from 'common/models/partner';
import { ID } from 'lib/id';

export const getPartnerList = ({ page, pageSize }: PaginationParams) =>
  AuthorizedAxios.get<ResultResponse<Partner>>('/api/partners', {
    params: { page, pageSize },
  })
    .then(flatten)
    .catch(throwErrorResponse);

export const getPartnerById = (id: ID) =>
  AuthorizedAxios.get<Partner>(`/api/partners/${id}`)
    .then(flatten)
    .catch(throwErrorResponse);

const prepareFormData = (params: PartnerCreateDTO | PartnerUpdateDTO) => {
  const formData = new FormData();

  formData.set(
    'partner',
    new Blob([JSON.stringify(params.partner)], {
      type: 'application/json',
    })
  );
  if (params.image != null) {
    formData.set('image', params.image);
  }

  return formData;
};

export const createPartner = (params: PartnerCreateDTO) =>
  AuthorizedAxios.post<Partner>(`/api/partners`, prepareFormData(params), {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(flatten)
    .catch(throwErrorResponse);

export const updatePartnerById = (params: PartnerUpdateDTO) =>
  AuthorizedAxios.put<Partner>(
    `/api/partners/${params.id}`,
    prepareFormData(params),
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const deletePartner = (id: string) =>
  AuthorizedAxios.delete<void>(`/api/partners/${id}`).catch(throwErrorResponse);
