import { useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { getElearningLessonVideo } from 'common/services/elearningLesson';
import { ID } from 'lib/id';

export function useElearningLessonVideoQuery(id: ID) {
  return useQuery([QueryKey.ElearningLessonVideo, id], () =>
    getElearningLessonVideo(id)
  );
}
