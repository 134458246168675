import * as yup from 'yup';
import { mixed, object } from 'yup';
import { isNilEmpty } from 'lib/isNilEmpty';
import { slugValidationSchema } from 'common/services/validation';

export const categoryFormValidationSchema = object({
  code: yup.string().max(64).required(),
  label: yup.string().max(200).required(),
  slug: slugValidationSchema,
  partnerName: yup.string().max(100).when('usesSpecialCertificateTemplate', {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),
  programName: yup.string().max(100).when('usesSpecialCertificateTemplate', {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),

  partnerLogoFile: mixed().test({
    message: 'Pole wymagane',
    test: (value, context) => {
      const { currentPartnerLogo, usesSpecialCertificateTemplate } =
        context.parent;

      if (!usesSpecialCertificateTemplate) {
        return true;
      }

      return !(isNilEmpty(currentPartnerLogo) && isNilEmpty(value));
    },
  }),
});

export const fieldsOrder = [
  'label',
  'code',
  'slug',
  'usesSpecialCertificateTemplate',
  'partnerName',
  'partnerLogoFile',
  'programName',
];
