import { isPlatformBrowser } from 'lib/isPlatformBrowser';

const apiUrl = process.env.REACT_APP_API_URL;
const appName = process.env.REACT_APP_NAME || 'n/a';
const appVersion = process.env.REACT_APP_VERSION || 'n/a';
const basicAuth = process.env.REACT_APP_BASE_AUTH;
const cmsUrl = process.env.REACT_APP_CMS_URL;
const environmentName = process.env.NODE_ENV;
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
const sentryEnv = process.env.REACT_APP_SENTRY_ENV || 'local';
const majorEventThreshold = process.env.REACT_APP_MAJOR_EVENT_THRESHOLD || 800;

export const environment = {
  appName,
  apiUrl,
  appVersion,
  basicAuth,
  cmsUrl,
  environmentName,
  sentryDsn,
  sentryEnv,
  majorEventThreshold: Number(majorEventThreshold),
} as const;

export const prependCMSUrl = (imageUrl: string) =>
  imageUrl.startsWith('http') ? imageUrl : cmsUrl + imageUrl;

if (isPlatformBrowser()) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.version = {
    version: appVersion,
    environmentName: environmentName,
  };
}
