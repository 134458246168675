import * as yup from 'yup';
import { requiredEditorJsFormSchema } from 'common/form-schemas/editorJs';
import { isNilEmpty } from 'lib/isNilEmpty';
import { validatorMessages } from 'common/services/validation';

export const staticPageFormSchema = yup.object({
  attachment: yup.object().when('attachmentFile', {
    is: (attachmentFile?: File) => !!attachmentFile,
    then: (schema) =>
      schema
        .shape({
          text: yup.string().required(),
        })
        .required(),
    otherwise: (schema) =>
      schema.shape({
        text: yup
          .string()
          .test(
            'isTextRequired',
            validatorMessages.required,
            (value, context) => {
              const { id } = context.parent;

              return id == null || !isNilEmpty(value);
            }
          ),
      }),
  }),
  content: yup
    .array()
    .of(
      yup.object({
        content: requiredEditorJsFormSchema,
        heading: yup.string().required(),
      })
    )
    .min(1)
    .required(),
  title: yup.string().required(),
});

export const staticPageFieldsOrder = [
  'title',
  'attachment',
  'intro',
  'content',
];
