import React from 'react';
import {
  AccordionSkeleton,
  FormItem,
  TextInputSkeleton,
} from 'carbon-components-react';

export const SurveyFormSkeleton = () => {
  return (
    <div>
      <FormItem>
        <TextInputSkeleton />
      </FormItem>

      <FormItem>
        <AccordionSkeleton />
      </FormItem>

      <FormItem>
        <AccordionSkeleton />
      </FormItem>
    </div>
  );
};
