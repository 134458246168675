import { Edit20 } from '@carbon/icons-react';
import { ActionsTableCell } from 'app/components/Table';
import {
  Button,
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import { setParam } from 'common/utils/routing';
import { Link } from 'react-router-dom';
import { BannerFormRoute, bannerIdRouteParam } from 'routes';

export function BannerListTableRow({
  getRowProps,
  row,
}: Pick<DataTableCustomRenderProps, 'getRowProps'> & { row: DenormalizedRow }) {
  return (
    <TableRow {...getRowProps({ row })}>
      {row.cells.map((cell) => (
        <TableCell key={cell.id}>{cell.value}</TableCell>
      ))}

      <ActionsTableCell>
        <Button
          as={Link}
          hasIconOnly
          iconDescription="Edytuj"
          kind="ghost"
          renderIcon={Edit20}
          size="small"
          to={setParam(bannerIdRouteParam)(BannerFormRoute)(row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
          style={{ marginRight: 4 }}
        />
      </ActionsTableCell>
    </TableRow>
  );
}
