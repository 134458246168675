export function validateSize(
  file: File,
  { width, height, mimes }: { width: number; height: number; mimes: string[] }
) {
  return new Promise<boolean>((resolve) => {
    if (!mimes.includes(file.type)) {
      resolve(false);
    }

    const img = new Image();
    const url = URL.createObjectURL(file);

    img.src = url;

    img.onload = () => {
      const isValid = img.naturalWidth <= width && img.naturalHeight <= height;
      URL.revokeObjectURL(url);
      resolve(isValid);
    };
  });
}
