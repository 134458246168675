import {
  AuthorizedAxios,
  BaseAxios,
  throwErrorResponse,
} from 'common/services/axios';
import { flatten } from 'lib/axios';
import { AuthDTO, LoginDTO, mapToAuth } from 'common/models/auth';
import qs from 'qs';
import {
  UpdateUserAccountPlusStatusDTO,
  User,
  UserListQueryFilters,
  UserRole,
  UserRoleRequest,
} from 'common/models/user';
import { PaginationParams } from 'common/models/pagination';
import { ResultResponse } from 'common/models/response';
import { AppError } from 'common/errors/AppError';
import { ErrorCode } from 'common/models/error';
import { saveData } from 'common/services/file';

export const login = (data: LoginDTO) =>
  BaseAxios.post<AuthDTO>(
    '/oauth/token',
    qs.stringify({
      ...data,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      grant_type: 'password',
    })
  )
    .then(flatten)
    .then(mapToAuth)
    .then((auth) => {
      if (auth.role !== UserRole.Admin) {
        throw new AppError(ErrorCode.WrongRole);
      }

      return auth;
    })
    .catch(throwErrorResponse);

export const refreshToken = (refreshToken: string) => {
  const formData = new FormData();
  formData.append('grant_type', 'refresh_token');
  formData.append('refresh_token', refreshToken);

  return BaseAxios.post<AuthDTO>('oauth/token', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
    .then(flatten)
    .then(mapToAuth)
    .catch(throwErrorResponse);
};

export const getLoggedInUser = () =>
  AuthorizedAxios.get<User>('/api/users/me')
    .then(flatten)
    .catch(throwErrorResponse);

export const getUserList = (
  { page, pageSize }: PaginationParams,
  filters?: UserListQueryFilters
) =>
  AuthorizedAxios.get<ResultResponse<User>>('/api/users', {
    params: { page, pageSize, ...filters },
  })
    .then(flatten)
    .catch(throwErrorResponse);

export const setUserRole = (userId: string, role: UserRoleRequest) =>
  AuthorizedAxios.put<void>(`/api/users/${userId}/role/${role}`).catch(
    throwErrorResponse
  );

export const updateUserAccountPlusStatus = ({
  id,
  accountPlusStatus,
}: UpdateUserAccountPlusStatusDTO) =>
  AuthorizedAxios.put<void>(`/api/users/${id}/plus`, {
    value: accountPlusStatus,
  }).catch(throwErrorResponse);

export const exportUserList = (params?: { fromDate: string; toDate: string }) =>
  AuthorizedAxios.post<string>(`/api/users/export`, params)
    .then(flatten)
    .then(saveData(`users.csv`))
    .catch(throwErrorResponse);
