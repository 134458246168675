// https://github.com/jkroso/parse-duration

import { keys } from 'ramda';

export const durationRegExp =
  /(-?(?:\d+\.?\d*|\d*\.?\d+)(?:e[-+]?\d+)?)\s*([\p{L}]*)/giu;

const nanosecond = 1 / 1e6;
const microsecond = 1 / 1e3;
const millisecond = 1;
const second = millisecond * 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;
const month = day * (365.25 / 12);
const year = day * 365.25;

const conversionRatios: { [key: string]: number } = {
  nanosecond: nanosecond,
  ns: nanosecond,
  microsecond: microsecond,
  μs: microsecond,
  us: microsecond,
  millisecond: millisecond,
  ms: millisecond,
  second: second,
  sec: second,
  sek: second,
  s: second,
  minute: minute,
  min: minute,
  m: minute,
  godz: hour,
  g: hour,
  hour: hour,
  hr: hour,
  h: hour,
  day: day,
  d: day,
  week: week,
  wk: week,
  w: week,
  month: month,
  b: month,
  year: year,
  yr: year,
  y: year,
};

const availableUnits = keys(conversionRatios);

const unitRatio = (unit: string) => {
  return (
    conversionRatios[unit] ||
    conversionRatios[unit.toLowerCase().replace(/s$/, '')]
  );
};

export const buildDurationString = (seconds: number) => {
  const rounded = Math.floor(seconds);
  const minutes = Math.floor(rounded / 60);
  return `${minutes}m ${rounded - minutes * 60}s`;
};

export const parseDuration = (duration = '', outputFormat = 'ms') => {
  let result: number | null = null;
  // ignore commas/placeholders
  const str = (duration + '').replace(/(\d)[,_](\d)/g, '$1$2');

  str.replace(durationRegExp, (match, n, unit) => {
    const units = unitRatio(unit);

    if (units) {
      result = (result || 0) + parseFloat(n) * units;
    }

    return '';
  });

  return result && result / (unitRatio(outputFormat) || 1);
};

export interface DurationValidationResponse {
  unit?: string;
  valid: boolean;
}

export const validateDuration = (
  duration: string
): DurationValidationResponse => {
  if (duration.length === 0) {
    return { valid: false };
  }

  const matches = Array.from(duration.matchAll(durationRegExp));

  for (const match of matches) {
    if (!availableUnits.includes(match[2])) {
      return { unit: match[2], valid: false };
    }
  }

  return { valid: true };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.validateDuration = validateDuration;
