import { createReducer } from '@reduxjs/toolkit';
import { dashboardActions } from 'state/dashboard/dashboardActions';
import { PaginationState } from 'common/models/pagination';

export interface DashboardState {
  pagination: Pick<PaginationState, 'page'>;
}

const initialState: DashboardState = {
  pagination: { page: 1 },
};

export const dashboardReducer = createReducer<DashboardState>(
  initialState,
  (builder) =>
    builder
      .addCase(dashboardActions.clear, () => initialState)
      .addCase(dashboardActions.setPagination, (state, action) => {
        state.pagination.page = action.payload.page;
      })
);
