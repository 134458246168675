import { ErrorCode } from 'common/models/error';

export class AppError extends Error {
  readonly code: ErrorCode;

  constructor(code: ErrorCode) {
    super(code);
    Object.setPrototypeOf(this, AppError.prototype);

    this.code = code;
  }
}
