import React, { FC } from 'react';
import styled from 'styled-components';
import { gray90, white } from '@carbon/colors';
import { useWindowUnloadPrompt } from 'common/hooks/useWindowUnloadPrompt';
import { FileUploaderSummary } from 'app/components/FileUploader/FileUploaderSummary';
import { FileUploaderList } from 'app/components/FileUploader/FileUploaderList';
import {
  fileUploaderFinishedCountSelector,
  fileUploaderOngoingCountSelector,
  fileUploaderOpenSelector,
} from 'state/fileUploader/fileUploaderSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { fileUploaderActions } from 'state/fileUploader/fileUploaderActions';
import { label01 } from '@carbon/type';
import { TrashCan16 } from '@carbon/icons-react';
import { spacing02 } from '@carbon/layout';
import { g10 } from '@carbon/themes';

const Wrapper = styled.div<{ $open: boolean }>`
  display: flex;
  position: fixed;
  right: 0;
  top: 3rem;
  width: 16rem;
  z-index: 8000;
  bottom: ${({ $open }) => ($open ? '0' : 'auto')};
`;

const Container = styled.div`
  display: grid;
  width: 100%;
  background: ${gray90};
  color: ${white};
  grid-template-areas:
    'list'
    'button'
    'summary';
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 1fr min-content min-content;
`;

const RemoveFinishedButton = styled.a`
  ${label01};

  align-items: center;
  color: ${g10.inverseLink};
  cursor: pointer;
  display: flex;
  grid-area: button;
  justify-self: end;
  padding: ${spacing02};
  text-align: right;

  svg {
    margin-left: ${spacing02};
  }
`;

export const FileUploader: FC = () => {
  const dispatch = useDispatch();

  const finishedCount = useSelector(fileUploaderFinishedCountSelector);
  const ongoingUploadsCount = useSelector(fileUploaderOngoingCountSelector);
  const open = useSelector(fileUploaderOpenSelector);
  useWindowUnloadPrompt(ongoingUploadsCount > 0);

  return (
    <Wrapper $open={open}>
      <Container>
        {open && <FileUploaderList />}

        {open && finishedCount > 0 && (
          <RemoveFinishedButton
            onClick={() => {
              dispatch(fileUploaderActions.removeFinished());
            }}
          >
            Wyczyść zakończone
            <TrashCan16 />
          </RemoveFinishedButton>
        )}

        <FileUploaderSummary
          onClick={() => {
            dispatch(fileUploaderActions.toggleOpen());
          }}
          open={open}
        />
      </Container>
    </Wrapper>
  );
};
