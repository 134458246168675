import { StoredImageDTO } from 'common/models/image';
import { bannerSchema } from 'modules/banner/config/bannerSchema';
import { InferType } from 'yup';

export type BannerFields = InferType<ReturnType<typeof bannerSchema>>;

export enum BannerColumn {
  TITLE = 'TITLE',
  SUBPAGE = 'SUBPAGE',
  LINK = 'LINK',
  CREATE_DATE = 'CREATE_DATE',
  DISPLAY_STATUS = 'DISPLAY_STATUS',
}

export enum BannerStatus {
  VISIBLE = 'VISIBLE',
  HIDDEN = 'HIDDEN',
}

export enum BannerSubpage {
  E_LEARNING = 'E_LEARNING',
  COURSE_BUNDLE = 'COURSE_BUNDLE',
  ONLINE = 'ONLINE',
  CATEGORY = 'CATEGORY',
}

export type BannerDTO = {
  bannerLogo: StoredImageDTO;
  categoryId: string;
  createDate: string;
  displayStatus: BannerStatus;
  id: number;
  link: string;
  subpage: BannerSubpage;
  title: string;
};

export type BannerCreateDTO = {
  banner: {
    categoryId?: number;
    displayStatus: BannerStatus;
    link: string;
    subpage?: BannerSubpage;
    title: string;
  };
  image: File;
};
