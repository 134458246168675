import React from 'react';
import { IconBox, IconProps } from 'lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M3.90524 41.2378c2.50049-2.5004 5.89187-3.905 9.42806-3.905h21.3334c3.5362 0 6.9276 1.4046 9.4281 3.905C46.5952 43.7381 48 47.1293 48 50.6654v5.333c0 1.4727-1.1939 2.6665-2.6667 2.6665-1.4727 0-2.6666-1.1938-2.6666-2.6665v-5.333c0-2.1216-.8429-4.1564-2.3432-5.6566-1.5003-1.5002-3.5351-2.343-5.6568-2.343H13.3333c-2.1217 0-4.15653.8428-5.65682 2.343-1.50029 1.5002-2.34315 3.535-2.34315 5.6566v5.333c0 1.4727-1.1939 2.6665-2.66666 2.6665C1.19391 58.6649 0 57.4711 0 55.9984v-5.333c0-3.5361 1.40476-6.9273 3.90524-9.4276z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M24 10.6675c-4.4183 0-8 3.5815-8 7.9996 0 4.418 3.5817 7.9996 8 7.9996s8-3.5816 8-7.9996c0-4.4181-3.5817-7.9996-8-7.9996zm-13.3333 7.9996c0-7.3634 5.9695-13.33263 13.3333-13.33263 7.3638 0 13.3333 5.96923 13.3333 13.33263S31.3638 31.9997 24 31.9997c-7.3638 0-13.3333-5.9692-13.3333-13.3326z"
    />
    <path
      className="second-color"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M50.7513 39.679c.3682-1.4259 1.8227-2.2834 3.2487-1.9152 2.8604.7385 5.3946 2.406 7.2046 4.7408A13.33236 13.33236 0 0164 50.6631v5.335c0 1.4727-1.1939 2.6665-2.6667 2.6665-1.4727 0-2.6666-1.1938-2.6666-2.6665V50.667c-.0015-1.7721-.5915-3.4944-1.6773-4.8951a8.00027 8.00027 0 00-4.3227-2.8444c-1.426-.3682-2.2835-1.8226-1.9154-3.2485z"
    />
    <path
      className="second-color"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M40.0833 7.68593c.3653-1.42666 1.8181-2.28708 3.2448-1.92179a13.33356 13.33356 0 017.2255 4.74076 13.33242 13.33242 0 010 16.3504 13.33341 13.33341 0 01-7.2255 4.7408c-1.4267.3653-2.8795-.4952-3.2448-1.9218-.3653-1.4267.4952-2.8793 1.9219-3.2446a7.99968 7.99968 0 004.3353-2.8445 7.99927 7.99927 0 000-9.8102 8.00023 8.00023 0 00-4.3353-2.8445c-1.4267-.3653-2.2872-1.81791-1.9219-3.24457z"
    />
  </IconBox>
);
