import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';
import { SpeakerState } from 'state/speaker/speakerReducer';

export const speakerStateSelector = (state: RootState) => state.speaker;

export const speakerPaginationSelector = createSelector(
  speakerStateSelector,
  (state: SpeakerState) => state.pagination
);
