import { fromNullable, none, Option, some } from 'fp-ts/lib/Option';
import { createReducer } from '@reduxjs/toolkit';
import {
  authActions,
  fetchUserActions,
  loginActions,
} from 'state/auth/authActions';
import { Auth } from 'common/models/auth';
import { User } from 'common/models/user';

export interface AuthState {
  isAuthReady: boolean;
  token: Option<Auth>;
  user: Option<User>;
}

const initialState: AuthState = {
  isAuthReady: false,
  token: none,
  user: none,
};

const loggedOutState: AuthState = {
  isAuthReady: true,
  token: none,
  user: none,
};

export const authReducer = createReducer<AuthState>(initialState, (builder) =>
  builder
    .addCase(authActions.logout, () => loggedOutState)
    .addCase(authActions.updateAuth, (state, action) => {
      state.token = fromNullable(action.payload);
    })
    .addCase(fetchUserActions.requestFailure, () => loggedOutState)
    .addCase(fetchUserActions.requestSuccess, (state, action) => {
      state.isAuthReady = true;
      state.user = some(action.payload);
    })
    .addCase(loginActions.requestSuccess, (state, action) => {
      state.token = fromNullable(action.payload);
    })
);
