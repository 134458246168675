import {
  convertDTOToMaterialList,
  DownloadMaterialDTO,
  Material,
  MaterialCreateRequestDTO,
  MaterialDTO,
  MaterialResource,
  materialResourceUrlMapper,
  MaterialUpdateRequestDTO,
} from 'common/models/material';
import {
  AuthorizedAxios,
  CancelablePromise,
  throwErrorResponse,
} from 'common/services/axios';
import { flatten } from 'lib/axios';
import { validate } from 'uuid';
import { forceDownload } from 'common/services/file';
import Axios from 'axios';
import { ErrorCode } from 'common/models/error';
import { ResultResponse, unwrap } from 'common/models/response';
import { setParam } from 'common/utils/routing';

const prepareMaterialFormData = (params: MaterialCreateRequestDTO) => {
  const formData = new FormData();

  formData.set(
    'material',
    new Blob([JSON.stringify(params.material)], {
      type: 'application/json',
    })
  );

  if (params.file) {
    formData.set('file', params.file);
  }

  return formData;
};

export const createMaterial = (
  id: string,
  materialResource: MaterialResource,
  params: MaterialCreateRequestDTO,
  onUploadProgress?: (event: ProgressEvent) => void
): CancelablePromise<MaterialDTO> => {
  const source = Axios.CancelToken.source();

  const request = AuthorizedAxios.post<MaterialDTO>(
    setParam(':id')(materialResourceUrlMapper(materialResource))(id),
    prepareMaterialFormData(params),
    {
      cancelToken: source.token,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
    }
  )
    .then(flatten)
    .catch(throwErrorResponse) as CancelablePromise<MaterialDTO>;

  request.cancel = () => {
    source.cancel(
      params.file ? ErrorCode.UploadCanceled : ErrorCode.RequestCanceled
    );
  };

  return request;
};

export const deleteMaterial = (id: string) =>
  AuthorizedAxios.delete<void>(`/api/materials/${id}`).catch(
    throwErrorResponse
  );

export const downloadMaterial = async (material: Material) => {
  if (!material || !material.filename || validate(material.id)) {
    throw new Error('[downloadMaterial] Invalid material');
  }

  return AuthorizedAxios.get<DownloadMaterialDTO>(
    `/api/materials/${material.id}/download`
  )
    .then(flatten)
    .then((downloadUrl) => {
      forceDownload(downloadUrl.url);
    })
    .catch(throwErrorResponse);
};

export const getMaterials = (id: string, materialResource: MaterialResource) =>
  AuthorizedAxios.get<ResultResponse<MaterialDTO>>(
    setParam(':id')(materialResourceUrlMapper(materialResource))(id),
    {
      params: {
        pageSize: 1000,
        page: 1,
      },
    }
  )
    .then(flatten)
    .then(unwrap)
    .then(convertDTOToMaterialList)
    .catch(throwErrorResponse);

export const updateMaterialById = (
  materialId: string,
  params: MaterialUpdateRequestDTO
) =>
  AuthorizedAxios.put<MaterialDTO>(`/api/materials/${materialId}`, params)
    .then(flatten)
    .catch(throwErrorResponse);
