import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { snackbarActions } from 'state/notification/notificationActions';
import {
  MaterialCreateRequestDTO,
  MaterialDTO,
  MaterialResource,
  MaterialStatus,
  MaterialType,
} from 'common/models/material';
import { materialsActions } from 'state/materials/materialsActions';
import { QueryKey } from 'config/queryKey';
import { TranslatedError } from 'common/models/error';
import { isApiErrorItem } from 'common/services/error';
import { useEffect, useState } from 'react';
import { CancelablePromise } from 'common/services/axios';
import { captureException } from 'common/services/sentry';
import Axios from 'axios';
import { createMaterial } from 'common/services/material';

export const useCreateMaterialMutation = (
  id: string,
  materialResource: MaterialResource,
  materialId: string,
  onUploadProgress?: (event: ProgressEvent) => void
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [mutationPromise, setMutationPromise] =
    useState<CancelablePromise<MaterialDTO> | null>(null);

  useEffect(() => {
    return () => {
      mutationPromise?.cancel?.();
    };
  }, [mutationPromise]);

  return useMutation<unknown, unknown, MaterialCreateRequestDTO>(
    (params) => {
      const promise = createMaterial(
        id,
        materialResource,
        params,
        onUploadProgress
      );
      // onUploadProgress oraz setMutationPromise potrzebujemy tylko w przypadku uploadu plików
      // memory leak fix
      onUploadProgress && setMutationPromise(promise);
      return promise;
    },
    {
      onError: (errors) => {
        (errors as TranslatedError[]).map((translatedError) => {
          captureException(translatedError);
          dispatch(
            snackbarActions.enqueueSnackbar({
              subtitle:
                isApiErrorItem(translatedError.error) &&
                translatedError.error.errorCode,
              title: translatedError.message,
              options: {
                variant: Axios.isCancel(translatedError.originalError)
                  ? 'warning'
                  : 'error',
              },
            })
          );
        });

        dispatch(
          materialsActions.updateStatus({
            id: materialId,
            status: MaterialStatus.New,
          })
        );
      },
      onMutate: (params) => {
        dispatch(
          materialsActions.updateStatus({
            id: materialId,
            status:
              params.material.type === MaterialType.Blob
                ? MaterialStatus.Uploading
                : MaterialStatus.Saving,
          })
        );
      },
      onSuccess: () => {
        dispatch(materialsActions.delete(materialId));
        queryClient.invalidateQueries([QueryKey.Material, id]);

        dispatch(
          snackbarActions.enqueueSnackbar({
            title: 'Materiał szkoleniowy został dodany pomyślnie',
            options: { variant: 'success' },
          })
        );
      },
    }
  );
};
