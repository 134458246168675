import { useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { CourseEventParticipantListItem } from 'common/models/courseEventParticipant';
import { getCourseEventParticipantList } from 'common/services/courseEventParticipant';
import { isSome } from 'fp-ts/Option';
import { useSelector } from 'react-redux';
import {
  courseEventParticipantPaginationSelector,
  courseEventParticipantSortSelector,
} from 'state/courseEventParticipant/courseEventParticipantSelectors';

export const useCourseEventParticipantListQuery = (eventId: string) => {
  const paginationOption = useSelector(
    courseEventParticipantPaginationSelector
  );
  const sortData = useSelector(courseEventParticipantSortSelector);

  return useQuery<CourseEventParticipantListItem[]>(
    [QueryKey.CourseEventParticipantList, eventId, sortData],
    () => getCourseEventParticipantList(eventId, sortData),
    {
      enabled: isSome(paginationOption),
      keepPreviousData: true,
    }
  );
};
