import { PaginationParams } from 'common/models/pagination';
import { ResultResponse } from 'common/models/response';
import { AuthorizedAxios, throwErrorResponse } from 'common/services/axios';
import { QueryKey } from 'config/queryKey';
import { flatten } from 'lib/axios';
import { BannerDTO } from 'modules/banner/types.banner';
import { useQuery } from 'react-query';

export function useQueryBanners(params: PaginationParams) {
  return useQuery<ResultResponse<BannerDTO>>(
    [QueryKey.BannerList, params],
    () => {
      const result = AuthorizedAxios.get('/api/banners', { params });

      return result.then(flatten).catch(throwErrorResponse);
    }
  );
}
