import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  DenormalizedRow,
  Grid,
  Pagination,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarActions } from 'state/notification/notificationActions';
import { QueryKey } from 'config/queryKey';
import { useQueryClient } from 'react-query';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/lib/Option';
import { initialPagination, pageSizes } from 'common/models/pagination';
import { Content } from 'common/components/Grid';
import { surveyPaginationSelector } from 'state/survey/surveySelectors';
import { surveyActions } from 'state/survey/surveyActions';
import { useSurveyListQuery } from 'common/hooks/survey/useSurveyListQuery';
import { SurveyListDataTableContent } from 'app/components/SurveyList/SurveyListDataTableContent';

const headers = [
  {
    key: 'title',
    header: 'Nazwa',
  },
  {
    key: 'courseTitle',
    header: 'Przypisany kurs',
  },
];

export const SurveyList: FC = () => {
  const dispatch = useDispatch();
  const paginationOption = useSelector(surveyPaginationSelector);
  const queryClient = useQueryClient();

  const pagination = useMemo(
    () =>
      pipe(
        paginationOption,
        getOrElse(() => initialPagination)
      ),
    [paginationOption]
  );

  const { data, isFetching, isLoading } = useSurveyListQuery();
  const rows = data?.result;

  const handleDeleteSuccess = useCallback(
    (row: DenormalizedRow) => {
      dispatch(
        snackbarActions.enqueueSnackbar({
          options: { variant: 'success' },
          title: `Ankieta ${row.cells[0].value} została usunięta`,
        })
      );
      queryClient.invalidateQueries(QueryKey.SurveyList);
    },
    [data, dispatch, pagination]
  );

  useEffect(() => {
    dispatch(surveyActions.loadPagination());
  }, [dispatch]);

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageHeading>Lista ankiet</PageHeading>

        {!rows && (
          <DataTableSkeleton headers={headers} rowCount={10}>
            {(props: DataTableCustomRenderProps) => (
              <SurveyListDataTableContent {...props} />
            )}
          </DataTableSkeleton>
        )}

        {rows && !isLoading && (
          <DataTable headers={headers} overflowMenuOnHover={false} rows={rows}>
            {(props: DataTableCustomRenderProps) => (
              <SurveyListDataTableContent
                {...props}
                isFetching={isFetching}
                onDeleteSuccess={handleDeleteSuccess}
              />
            )}
          </DataTable>
        )}

        <Pagination
          onChange={(pagination) => {
            dispatch(
              surveyActions.setPagination({
                page: pagination.page,
                pageSize: pagination.pageSize,
              })
            );
          }}
          page={pagination.page}
          pageSize={pagination.pageSize}
          pageSizes={pageSizes}
          totalItems={data?.totalResult ?? 0}
        />
      </Grid>
    </Content>
  );
};
