import { QueryObserverOptions, useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { ResultResponse } from 'common/models/response';
import { useSelector } from 'react-redux';
import { getOrElse, isSome } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/function';
import { initialPagination } from 'common/models/pagination';
import { getCourseBundleList } from 'common/services/courseBundle';
import { CourseBundleBasicInfoDTO } from 'common/models/courseBundle';
import {
  courseBundlePaginationSelector,
  courseBundleSortSelector,
} from 'state/courseBundle/courseBundleSelectors';

export const useCourseBundleListQuery = (
  options: QueryObserverOptions<ResultResponse<CourseBundleBasicInfoDTO>>
) => {
  const paginationOption = useSelector(courseBundlePaginationSelector);
  const sortData = useSelector(courseBundleSortSelector);

  const pagination = pipe(
    paginationOption,
    getOrElse(() => initialPagination)
  );

  return useQuery<ResultResponse<CourseBundleBasicInfoDTO>>(
    [QueryKey.CourseBundle, pagination.page, pagination.pageSize, sortData],
    () => getCourseBundleList(pagination, sortData),
    {
      ...options,
      enabled: isSome(paginationOption),
      keepPreviousData: true,
    }
  );
};
