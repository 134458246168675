import React, { FC, useCallback, useMemo, useState } from 'react';
import { settings } from 'carbon-components';
import { printMessage, validatorMessages } from 'common/services/validation';
import { FileUploaderDropContainer } from 'app/components/FileUploaderDropContainer';
import { isNil } from 'ramda';
import { ErrorMessage } from 'app/components/ErrorMessage';
import {
  CarbonFile,
  Column,
  FileUploaderItem,
  Row,
} from 'carbon-components-react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { getVideoDuration } from 'lib/video';
import { buildDurationString } from 'lib/duration';

const CurrentVideo = styled.div`
  margin-bottom: 1rem;
  padding-bottom: 56.25%;
  position: relative;

  video {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

enum UploadFileStatus {
  Complete = 'complete',
  Edit = 'edit',
  Uploading = 'uploading',
}

interface UploadFile {
  errorBody?: string;
  errorSubject?: string;
  file: File;
  filesize: number;
  name: string;
  status: UploadFileStatus;
  uuid: string;
  valid: boolean;
}

export interface VideoUploadControlProps {
  currentVideoDuration?: number;
  currentVideoUrl?: string;
  invalid: boolean;
  invalidText?: string;
  onDurationChange?: (value: number | null) => void;
  onFileChange?: (value: File | null) => void;
}

export const VideoUploadControl: FC<VideoUploadControlProps> = ({
  currentVideoDuration,
  currentVideoUrl,
  invalid,
  invalidText,
  onDurationChange,
  onFileChange,
}) => {
  const [uploadedFile, setUploadedFile] = useState<UploadFile | null>(null);

  const handleAddFiles = useCallback(
    (event, { addedFiles }: { addedFiles: CarbonFile[] }) => {
      event.stopPropagation();

      const newFile: UploadFile = {
        file: addedFiles[0],
        filesize: addedFiles[0].size,
        name: addedFiles[0].name,
        status: UploadFileStatus.Edit,
        uuid: uuidv4(),
        valid: true,
      };

      if (addedFiles[0].invalidFileType) {
        newFile.errorBody = printMessage(
          validatorMessages.validFileTypesSingular,
          { types: 'mp4, h.264, aac' }
        );
        newFile.errorSubject = validatorMessages.invalidFileType;
        newFile.valid = false;
      }

      setUploadedFile(newFile);

      if (!newFile.valid) {
        onDurationChange && onDurationChange(null);
        onFileChange && onFileChange(null);
        return;
      }

      getVideoDuration(
        window.URL.createObjectURL(newFile.file),
        onDurationChange
      );

      onFileChange && onFileChange(newFile.file);
    },
    [onDurationChange, onFileChange]
  );

  const handleFileUploaderItemClick = useCallback(() => {
    setUploadedFile(null);
    onDurationChange && onDurationChange(null);
    onFileChange && onFileChange(null);
  }, [onDurationChange, onFileChange]);

  const currentDuration = useMemo(
    () => currentVideoDuration && buildDurationString(currentVideoDuration),
    [currentVideoDuration]
  );

  return (
    <Row>
      <Column lg={6}>
        <p className={`${settings.prefix}--file--label`}>Upload pliku</p>
        <p className={`${settings.prefix}--label-description`}>
          {printMessage(validatorMessages.validFileTypesSingular, {
            types: 'mp4, h.264, aac',
          })}
        </p>
        <FileUploaderDropContainer
          accept={['video/mp4']}
          labelText="Przeciągnij albo kliknij aby wybrać plik.."
          onAddFiles={handleAddFiles}
          $invalid={isNil(uploadedFile) && invalid}
        />
        {isNil(uploadedFile) && invalid && (
          <ErrorMessage className="bx--form-requirement">
            {invalidText}
          </ErrorMessage>
        )}
        <div className="uploaded-files" style={{ width: '100%' }}>
          {uploadedFile && (
            <FileUploaderItem
              errorBody={uploadedFile.errorBody}
              errorSubject={uploadedFile.errorSubject}
              invalid={!uploadedFile.valid}
              key={uploadedFile.uuid}
              name={uploadedFile.name}
              onDelete={handleFileUploaderItemClick}
              size="default"
              status={uploadedFile.status}
              uuid={uploadedFile.uuid}
            />
          )}
        </div>
      </Column>

      {currentVideoUrl && (
        <Column lg={8}>
          <p className={`${settings.prefix}--file--label`}>Aktualny film</p>

          {currentDuration && (
            <p className={`${settings.prefix}--label-description`}>
              Czas trwania: {currentDuration}
            </p>
          )}

          <ReactPlayer
            controls
            height="auto"
            url={currentVideoUrl}
            width="100%"
            wrapper={CurrentVideo}
          />
        </Column>
      )}
    </Row>
  );
};
