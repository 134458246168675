import styled from 'styled-components';

export const ListFilters = styled.div`
  display: flex;
  margin-right: auto;

  .bx--list-box__wrapper {
    display: flex;
  }

  .bx--list-box {
    height: auto;
    max-height: none;
  }
`;
