import styled from 'styled-components';
import {
  Table as CdsTable,
  TableCell as CdsTableCell,
  TableContainer as CdsTableContainer,
} from 'carbon-components-react';
import { g10 } from '@carbon/themes';

export const ActionsTableCell = styled(CdsTableCell).attrs({
  className: 'bx--table-column-menu',
})`
  text-align: right !important;
  width: 10rem;
`;

export const Table = styled(CdsTable)<{ $loading: boolean }>`
  opacity: ${({ $loading }) => ($loading ? 0.5 : 1)};
`;

export const TableContainer = styled(CdsTableContainer)`
  background: ${g10.ui01};
`;

export const TableContent = styled.div`
  position: relative;
`;

export const TableLoader = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;
