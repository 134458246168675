import { createAction } from 'lib/createAction';
import {
  UpdateProgressActionPayload,
  UploadFileActionPayload,
} from 'common/models/fileUploader';
import { CancelablePromise } from 'common/services/axios';

export const fileUploaderActions = {
  cancelUploading: createAction('fileUploader/cancelUploading')<string>(),
  finishUploading: createAction('fileUploader/finishUploading')<string>(),
  removeFinished: createAction('fileUploader/removeFinished')(),
  removeItem: createAction('fileUploader/removeItem')<string>(),
  reset: createAction('fileUploader/reset')(),
  setPromise: createAction('fileUploader/setPromise')<{
    id: string;
    promise: CancelablePromise<unknown> | undefined;
  }>(),
  toggleOpen: createAction('fileUploader/toggleOpen')(),
  uploadFile: createAction(
    'fileUploader/uploadFile'
  )<UploadFileActionPayload>(),
  uploadingFailed: createAction('fileUploader/uploadingFailed')<string>(),
  updateProgress: createAction(
    'fileUploader/updateProgress'
  )<UpdateProgressActionPayload>(),
} as const;
