import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  Grid,
  ProgressIndicator,
  ProgressStep,
  Row,
} from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import {
  CategoryFormRoute,
  categoryIdRouteParam,
  CategoryListRoute,
  RootRoute,
} from 'routes';
import { PageHeading } from 'common/components/Heading';
import { Content } from 'common/components/Grid';
import { useCategoryByIdQuery } from 'common/hooks/category/useCategoryByIdQuery';
import { useCourseListByCategoryQuery } from 'common/hooks/course/useCourseListByCategoryQuery';
import { useAllCategoriesListQuery } from 'common/hooks/category/useAllCategoriesListQuery';
import { CategoryDeleteSkeleton } from 'app/components/CategoryDelete/CategoryDeleteSkeleton';
import { CategoryDeleteStep1 } from 'app/components/CategoryDelete/CategoryDeleteStep1';
import { CategoryDeleteStep3 } from 'app/components/CategoryDelete/CategoryDeleteStep3';
import { CategoryDeleteStep2 } from 'app/components/CategoryDelete/CategoryDeleteStep2';
import { Category, CategoryFormPageParams } from 'common/models/category';
import {
  CourseIds,
  CourseMap,
  Steps,
} from 'app/components/CategoryDelete/config';
import { ID } from 'lib/id';
import { setParam } from 'common/utils/routing';

export const CategoryDeletePage: FC = () => {
  const [allCategories, setAllCategories] = useState<Category[]>();
  const [items, setItems] = useState<CourseMap | null>(null);
  const [itemIds, setItemIds] = useState<CourseIds | null>(null);
  const [step, setStep] = useState(Steps.Loading);

  const { categoryId } = useParams<CategoryFormPageParams>();

  const { isFetching: isLoadingAllCategories } = useAllCategoriesListQuery({
    onSuccess: (data) => {
      setAllCategories(
        data.result.filter(
          (category) => Number(category.id) !== Number(categoryId)
        )
      );
    },
  });

  const { data: categoryData, isLoading: isLoadingCategory } =
    useCategoryByIdQuery(categoryId, categoryId.length > 0);

  const { data: courses, isLoading: isLoadingCategories } =
    useCourseListByCategoryQuery(categoryId);

  const handleCategoryChange = useCallback((id: ID) => {
    setItems((prevState) => {
      if (!prevState) {
        return null;
      }

      const { [id]: changed, ...rest } = prevState;

      return {
        [id]: {
          course: changed.course,
          status: 'finished' as const,
        },
        ...rest,
      };
    });
  }, []);

  useEffect(() => {
    const isLoading =
      isLoadingCategories || isLoadingCategory || isLoadingAllCategories;

    if (isLoading || !allCategories || !courses) {
      return;
    }

    const ids: CourseIds = [];
    const items: CourseMap = {};

    courses.result.map((categoryCourse) => {
      if (!items[categoryCourse.id]) {
        items[categoryCourse.id] = {
          course: categoryCourse,
          status: 'pending',
        };
        ids.push(categoryCourse.id);
      }
    });

    setItemIds(ids);
    setItems(items);
  }, [
    allCategories,
    courses,
    isLoadingAllCategories,
    isLoadingCategory,
    isLoadingCategories,
  ]);

  useEffect(() => {
    if (!itemIds || !items) {
      return;
    }

    setStep((prevStep) => {
      if (prevStep === Steps.Loading) {
        return itemIds.length !== 0
          ? Steps.RewriteCategories
          : Steps.DeleteConfirmation;
      }

      if (prevStep === Steps.DeleteValidation) {
        return itemIds.length !== 0
          ? Steps.RewriteCategories
          : Steps.DeleteConfirmation;
      }

      if (prevStep === Steps.RewriteCategories) {
        const allFinished = itemIds.reduce(
          (acc, id) => acc && items[id].status === 'finished',
          true
        );
        return allFinished ? Steps.DeleteValidation : prevStep;
      }

      return prevStep;
    });
  }, [items, itemIds]);

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={CategoryListRoute}>Lista kategorii specjalnych</Link>
          </BreadcrumbItem>

          {categoryData && (
            <BreadcrumbItem>
              <Link
                to={setParam(categoryIdRouteParam)(CategoryFormRoute)(
                  categoryId
                )}
              >
                {categoryData.label}
              </Link>
            </BreadcrumbItem>
          )}
        </Breadcrumb>

        <PageHeading>Usuń kategorię</PageHeading>

        <Row>
          <Column lg={10}>
            {step === Steps.Loading && <CategoryDeleteSkeleton />}

            {step !== Steps.Loading && (
              <ProgressIndicator currentIndex={step} spaceEqually={true}>
                <ProgressStep label="Przepisz kategorie" />
                <ProgressStep label="Sprawdzanie kategorii" />
                <ProgressStep label="Potwierdź usunięcie kategorii" />
              </ProgressIndicator>
            )}

            {step === Steps.RewriteCategories && (
              <CategoryDeleteStep1
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                allCategoriesList={allCategories!}
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                courseIds={itemIds!}
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                courseMap={items!}
                onChange={handleCategoryChange}
              />
            )}

            {step === Steps.DeleteValidation && (
              <CategoryDeleteStep2 categoryId={categoryId} />
            )}

            {step === Steps.DeleteConfirmation && (
              <CategoryDeleteStep3 categoryId={categoryId} />
            )}
          </Column>
        </Row>
      </Grid>
    </Content>
  );
};
