import { FC, useEffect, useMemo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  Grid,
  Pagination,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { useDispatch, useSelector } from 'react-redux';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/lib/Option';
import { initialPagination, pageSizes } from 'common/models/pagination';
import { Content } from 'common/components/Grid';
import {
  elearningCategorySelector,
  elearningPaginationSelector,
} from 'state/elearning/elearningSelectors';
import { useElearningListQuery } from 'common/hooks/elearning/useElearningListQuery';
import { elearningActions } from 'state/elearning/elearningActions';
import { Elearning, ElearningListColumn } from 'common/models/elearning';
import { ElearningListTableContent } from 'app/components/ElearningList/ElearningListTableContent';
import { useAllCategoriesListQuery } from 'common/hooks/category/useAllCategoriesListQuery';
import { formatDateOnLists } from 'lib/date';
import { find } from 'ramda';
import { Category } from 'common/models/category';
import { statusLabelMap } from 'common/models/status';

const headers = [
  {
    key: ElearningListColumn.CourseTitle,
    header: 'Nazwa',
  },
  {
    key: ElearningListColumn.CategoryName,
    header: 'Kategoria',
  },
  {
    key: ElearningListColumn.CourseSpeaker,
    header: 'Prowadzący',
  },
  {
    key: ElearningListColumn.CourseCreateDate,
    header: 'Data dodania',
  },
  {
    key: ElearningListColumn.CourseModificationDate,
    header: 'Data modyfikacji',
  },
  {
    key: ElearningListColumn.Status,
    header: 'Status',
  },
];

const prepareElearningRow = (elearning: Elearning) => ({
  [ElearningListColumn.CategoryName]: elearning.categories[0].label,
  [ElearningListColumn.CourseCreateDate]: formatDateOnLists(
    elearning.createDate
  ),
  [ElearningListColumn.CourseSpeaker]: `${elearning.speakers[0].firstName} ${elearning.speakers[0].lastName}`,
  [ElearningListColumn.CourseTitle]: elearning.title,
  [ElearningListColumn.CourseModificationDate]: formatDateOnLists(
    elearning.modificationTime
  ),
  [ElearningListColumn.Status]: statusLabelMap[elearning.elearningStatus],
  id: `${elearning.id}`,
});

export const ElearningList: FC = () => {
  const dispatch = useDispatch();

  const categoryOption = useSelector(elearningCategorySelector);
  const paginationOption = useSelector(elearningPaginationSelector);

  const categoryId = useMemo(
    () =>
      pipe(
        categoryOption,
        getOrElse(() => 'undefined')
      ),
    [categoryOption]
  );

  const pagination = useMemo(
    () =>
      pipe(
        paginationOption,
        getOrElse(() => initialPagination)
      ),
    [paginationOption]
  );

  const { data: categoriesData, isLoading: isLoadingCategories } =
    useAllCategoriesListQuery({
      onSuccess: (data) => {
        if (
          data &&
          (data.result.length === 0 ||
            !find<Category>((item) => String(item.id) === categoryId)(
              data.result
            ))
        ) {
          dispatch(elearningActions.setCategoryId(undefined));
        }
      },
    });

  const {
    data,
    isFetching: isFetchingElearning,
    isLoading: isLoadingElearning,
  } = useElearningListQuery({
    onSuccess: (data) => {
      if (data && data.result.length === 0 && pagination.page !== 1) {
        dispatch(
          elearningActions.setPagination({
            page: Math.ceil(data.totalResult / pagination.pageSize),
            pageSize: pagination.pageSize,
          })
        );
      }
    },
  });

  const rows = useMemo(() => data?.result.map(prepareElearningRow), [data]);

  useEffect(() => {
    dispatch(elearningActions.loadPagination());

    return () => {
      dispatch(elearningActions.clear());
    };
  }, [dispatch]);

  const isLoading = isLoadingCategories || isLoadingElearning;

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageHeading>Lista e-learningów</PageHeading>

        {isLoading && (
          <DataTableSkeleton headers={headers} rowCount={10}>
            {(props: DataTableCustomRenderProps) => (
              <ElearningListTableContent {...props} />
            )}
          </DataTableSkeleton>
        )}

        {rows && !isLoading && (
          <DataTable headers={headers} overflowMenuOnHover={false} rows={rows}>
            {(props: DataTableCustomRenderProps) => (
              <ElearningListTableContent
                {...props}
                categories={categoriesData?.result ?? []}
                isFetching={isFetchingElearning}
              />
            )}
          </DataTable>
        )}

        <Pagination
          onChange={(pagination) => {
            dispatch(
              elearningActions.setPagination({
                page: pagination.page,
                pageSize: pagination.pageSize,
              })
            );
          }}
          page={pagination.page}
          pageSize={pagination.pageSize}
          pageSizes={pageSizes}
          totalItems={data?.totalResult ?? 0}
        />
      </Grid>
    </Content>
  );
};
