import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { snackbarActions } from 'state/notification/notificationActions';
import { push } from 'connected-react-router';
import { CourseEventListRoute } from 'routes';
import { createCourseEvent } from 'common/services/courseEvent';
import { CourseEventDTO } from 'common/models/courseEvent';
import { setParam } from 'common/utils/routing';

export const useCreateCourseEventMutation = (courseId: string) => {
  const dispatch = useDispatch();

  return useMutation<unknown, unknown, CourseEventDTO>(
    (params) => createCourseEvent(courseId, params),
    {
      onSuccess: () => {
        dispatch(push(setParam(':courseId')(CourseEventListRoute)(courseId)));
        dispatch(
          snackbarActions.enqueueSnackbar({
            title: 'Wydarzenie zostało dodane pomyślnie',
            options: { variant: 'success' },
          })
        );
      },
    }
  );
};
