import { Column, Grid, Row } from 'carbon-components-react';
import { FC } from 'react';
import styled from 'styled-components';
import fileSize from 'filesize';
import { Material, MaterialType } from 'common/models/material';
import { format } from 'lib/date';

const RowDetails = styled(Grid)`
  padding: 1.5rem 0;
`;

const RowDetailsItem = styled(Row)`
  padding-bottom: 0.375rem;
  padding-top: 0.375rem;
`;

export interface MaterialsTableRowDetailsProps {
  material: Material;
}

export const MaterialsTableRowDetails: FC<MaterialsTableRowDetailsProps> = ({
  material,
}) => {
  return (
    <RowDetails fullWidth>
      {material.type === MaterialType.Blob && (
        <>
          <RowDetailsItem>
            <Column lg={3}>
              <h6>Nazwa pliku:</h6>
            </Column>
            <Column lg={5}>{material.filename}</Column>
          </RowDetailsItem>
          <RowDetailsItem>
            <Column lg={3}>
              <h6>Rozmiar pliku:</h6>
            </Column>
            <Column lg={5}>{fileSize(material.size || 0)}</Column>
          </RowDetailsItem>
        </>
      )}

      {material.type === MaterialType.Url && (
        <RowDetailsItem>
          <Column lg={3}>
            <h6>Url:</h6>
          </Column>
          <Column lg={5}>{material.url}</Column>
        </RowDetailsItem>
      )}

      {material.createdAt && (
        <RowDetailsItem>
          <Column lg={3}>
            <h6>Dodany:</h6>
          </Column>
          <Column lg={5}>
            {format(material.createdAt, 'dd.MM.yyyy HH:mm')}
          </Column>
        </RowDetailsItem>
      )}
    </RowDetails>
  );
};
