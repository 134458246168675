import { FC } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'carbon-components-react';
import { gray10 } from '@carbon/colors';
import { parseISO } from 'date-fns';
import { format } from 'lib/date';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useUpdateCourseBundleEventListMutation } from 'common/hooks/courseBundle/useUpdateCourseBundleEventListMutation';
import { coerceUTCDateString } from 'lib/coerceUTCDateString';
import { useDebounce } from 'react-use';
import { BaseCourseWithEventsDTO } from 'common/models/courseBundle';

const CourseTitle = styled.h6`
  background-color: ${gray10};
  padding: 0.75rem;
`;

const ListItem = styled.div`
  & .bx--checkbox-wrapper {
    padding: 0.15rem 1rem;
  }
`;

interface AssignableEvent {
  eventId: number;
  checked?: boolean;
}

interface EventListFormModel {
  items: { events: AssignableEvent[] }[];
}

interface SelectableEventsFormProps {
  courseBundleId: string;
  selectableItemList?: BaseCourseWithEventsDTO[];
  initialValues: EventListFormModel;
}

export const SelectableEventsForm: FC<SelectableEventsFormProps> = ({
  courseBundleId,
  selectableItemList,
  initialValues,
}) => {
  const methods = useForm<EventListFormModel>({ defaultValues: initialValues });
  const watchAllFields = useWatch({ control: methods.control });

  const { mutate } = useUpdateCourseBundleEventListMutation(courseBundleId);

  const updateEventList = (data: EventListFormModel) => {
    const eventIds = data.items
      .map((item) => item.events)
      .flat()
      .filter((v) => v.checked)
      .map((e) => e.eventId);

    methods.formState.isDirty && mutate(eventIds);
  };

  useDebounce(
    () => updateEventList(watchAllFields as EventListFormModel),
    400,
    [watchAllFields]
  );

  return (
    <FormProvider {...methods}>
      {selectableItemList?.map((course, index) => (
        <div key={course.id}>
          <CourseTitle>{course.title}</CourseTitle>
          {course.events.map((event, eventIndex) => (
            <ListItem key={event.id}>
              <Controller
                name={`items.${index}.events.${eventIndex}.checked`}
                control={methods.control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    key={event.id}
                    id={`${event.id}`}
                    labelText={format(
                      parseISO(coerceUTCDateString(event.startDateTime)),
                      'dd.MM.yyyy - HH:mm'
                    )}
                    checked={!!value}
                    onChange={onChange}
                  />
                )}
              />
            </ListItem>
          ))}
        </div>
      ))}
    </FormProvider>
  );
};
