export const countLabelFormatter =
  (
    at0: string,
    at1: string,
    atLower: (count: number) => string,
    atHigher: (count: number) => string
  ) =>
  (count: number) => {
    if (count < 1) {
      return at0;
    } else if (count % 100 > 10 && count % 100 < 15) {
      return atHigher(count);
    } else if (count === 1) {
      return at1;
    } else if (count % 10 > 1 && count % 10 < 5) {
      return atLower(count);
    } else {
      return atHigher(count);
    }
  };
