import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createMatchSelector,
  push,
  RouterRootState,
} from 'connected-react-router';
import { stringify } from 'qs';
import { pipe } from 'fp-ts/function';
import { filter, fromNullable, getOrElse, Option } from 'fp-ts/lib/Option';
import { getQueryParamsMap } from 'lib/getQueryParams';
import { mapObjIndexed, mergeLeft } from 'ramda';
import {
  initialPagination,
  PaginationState,
  SortDirection,
} from 'common/models/pagination';
import { isSomeEnum, isString } from 'lib/typeGuards';
import { rootStateSelector } from 'state/router/routerSelectors';
import { CourseEventStatus } from 'common/models/courseEvent';
import { isSome } from 'fp-ts/Option';
import { defaultSort } from 'state/courseBundleContent/eventList/courseBundleEventListReducer';
import { setParam } from 'common/utils/routing';
import {
  CourseBundleContentUrlParams,
  CourseBundleEventListColumn,
  courseBundleEventListColumnSettings,
  CourseBundleEventListSortData,
} from 'common/models/courseBundleContent';
import { courseBundleEventListActions } from 'state/courseBundleContent/eventList/courseBundleEventListActions';
import {
  courseBundleEventListIdSelector,
  courseBundleEventListPaginationSelector,
  courseBundleEventListSortSelector,
  courseBundleEventListStatusSelector,
} from 'state/courseBundleContent/eventList/courseBundleEventListSelectors';
import { CourseBundleEventListRoute, courseBundleIdRouteParam } from 'routes';

function* writeToURLQuery() {
  const courseBundleId: string = yield select(courseBundleEventListIdSelector);
  const filterStatusOption: Option<CourseEventStatus> = yield select(
    courseBundleEventListStatusSelector
  );
  const pagination: Option<PaginationState> = yield select(
    courseBundleEventListPaginationSelector
  );
  const sortData: CourseBundleEventListSortData = yield select(
    courseBundleEventListSortSelector
  );

  const filterStatus = pipe(
    filterStatusOption,
    getOrElse(() => 'undefined')
  );

  const qs = pipe(
    pagination,
    getOrElse(() => initialPagination),
    (item) =>
      isSome(filterStatusOption)
        ? {
            ...item,
            status: filterStatus,
          }
        : item,
    mergeLeft(sortData),
    stringify
  );

  yield put(
    push(
      `${setParam(courseBundleIdRouteParam)(CourseBundleEventListRoute)(
        courseBundleId
      )}?${qs}`,
      {
        replace: true,
      }
    )
  );
}

function* readFromURLQuery() {
  const qp = getQueryParamsMap();
  const routerState: RouterRootState = yield select(rootStateSelector);

  const matchSelector = createMatchSelector<
    RouterRootState,
    CourseBundleContentUrlParams
  >(CourseBundleEventListRoute);
  const match = matchSelector(routerState);

  const pagination = pipe(
    initialPagination,
    mapObjIndexed((value, key) =>
      pipe(
        fromNullable(qp[key]),
        filter(isString),
        getOrElse(() => value.toString()),
        Number
      )
    )
  );

  const sortData: CourseBundleEventListSortData = {
    direction: pipe(
      fromNullable(qp['direction']),
      filter(isSomeEnum(SortDirection)),
      getOrElse(() => defaultSort.direction)
    ),
    orderBy: pipe(
      fromNullable(qp['orderBy']),
      filter(isSomeEnum(CourseBundleEventListColumn)),
      filter((column) => courseBundleEventListColumnSettings[column].sortable),
      getOrElse(() => defaultSort.orderBy)
    ),
  };

  yield put(
    courseBundleEventListActions.setFilters({
      courseBundleId: match?.params.courseBundleId,
      pagination: pagination,
      sort: sortData,
      status: qp.status ? (String(qp.status) as CourseEventStatus) : undefined,
    })
  );
}

function* readFromURLQueryWatcher() {
  yield takeEvery(
    courseBundleEventListActions.loadPagination.type,
    readFromURLQuery
  );
}

function* writeToURLQueryWatcher() {
  yield takeLatest(
    courseBundleEventListActions.setFilters.type,
    writeToURLQuery
  );
  yield takeLatest(
    courseBundleEventListActions.setPagination.type,
    writeToURLQuery
  );
  yield takeLatest(courseBundleEventListActions.setSort.type, writeToURLQuery);
  yield takeLatest(
    courseBundleEventListActions.setStatus.type,
    writeToURLQuery
  );
}

export function* courseBundleEventListSaga() {
  yield all([writeToURLQueryWatcher(), readFromURLQueryWatcher()]);
}
