import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';
import { UserState } from 'state/user/userReducer';

export const userStateSelector = (state: RootState) => state.user;

export const userPaginationSelector = createSelector(
  userStateSelector,
  (state: UserState) => state.pagination
);
