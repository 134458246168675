import React, { FC, useCallback } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonSkeleton,
  FileUploaderSkeleton,
  Form,
  Grid,
} from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import {
  ElearningFormRoute,
  elearningIdRouteParam,
  ElearningListRoute,
  RootRoute,
} from 'routes';
import { PageHeading } from 'common/components/Heading';
import { Content } from 'common/components/Grid';
import { useElearningByIdQuery } from 'common/hooks/elearning/useElearningByIdQuery';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ElearningUploadVideoFormModel } from 'common/models/elearning';
import { yupResolver } from '@hookform/resolvers/yup';
import { mixed, object } from 'yup';
import { FormItem } from 'app/components/FormItem';
import { useDispatch } from 'react-redux';
import { VideoUploadControl } from 'app/components/VideoUploadControl';
import { fileUploaderActions } from 'state/fileUploader/fileUploaderActions';
import { uploadElearningPreview } from 'common/services/elearning';
import { push } from 'connected-react-router';
import { v4 as uuidv4 } from 'uuid';
import { useElearningPreviewQuery } from 'common/hooks/elearning/useElearningPreviewQuery';
import { setParam } from 'common/utils/routing';

const validationSchema = object({
  file: mixed().required(),
});

export interface ElearningUploadPreviewUrlParams {
  elearningId: string;
}

export const ElearningUploadPreview: FC = () => {
  const dispatch = useDispatch();
  const { elearningId } = useParams<ElearningUploadPreviewUrlParams>();

  const { data: elearningData, isFetching: isElearningFetching } =
    useElearningByIdQuery(elearningId);

  const { data: previewData, isFetching: isPreviewFetching } =
    useElearningPreviewQuery(elearningId);

  const methods = useForm<Partial<ElearningUploadVideoFormModel>>({
    resolver: yupResolver(validationSchema),
  });

  const handleFileChange = useCallback(
    (value) => {
      methods.setValue('file', value || undefined);
      methods.trigger('file');
    },
    [methods]
  );

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        fileUploaderActions.uploadFile({
          id: uuidv4(),
          title: `Zapowiedź e-learningu "${elearningData?.title}"`,
          uploadFn: (onUploadProgress) =>
            uploadElearningPreview(
              Number(elearningData?.id),
              values.file,
              onUploadProgress
            ),
        })
      );
      dispatch(push(ElearningListRoute));
    },
    [elearningData]
  );

  const isLoading = isElearningFetching || isPreviewFetching;

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={ElearningListRoute}>Lista e-learningów</Link>
          </BreadcrumbItem>

          {elearningData && (
            <BreadcrumbItem>
              <Link
                to={setParam(elearningIdRouteParam)(ElearningFormRoute)(
                  elearningId
                )}
              >
                {elearningData.title}
              </Link>
            </BreadcrumbItem>
          )}
        </Breadcrumb>

        <PageHeading>Dodaj / zmień zapowiedź</PageHeading>

        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            {isLoading && (
              <FormItem>
                <FileUploaderSkeleton />
              </FormItem>
            )}

            {!isLoading && (
              <FormItem>
                <Controller
                  control={methods.control}
                  name="file"
                  render={({ fieldState: { error } }) => (
                    <VideoUploadControl
                      currentVideoUrl={previewData?.url}
                      invalid={!!error}
                      invalidText={error?.message}
                      onFileChange={handleFileChange}
                    />
                  )}
                />
              </FormItem>
            )}

            {isLoading ? (
              <ButtonSkeleton />
            ) : (
              <Button kind="primary" tabIndex={0} type="submit">
                Wyślij
              </Button>
            )}
          </Form>
        </FormProvider>
      </Grid>
    </Content>
  );
};
