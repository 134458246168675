import { QueryObserverOptions, useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { ResultResponse } from 'common/models/response';
import { useSelector } from 'react-redux';
import { getOrElse, isSome } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/function';
import { initialPagination } from 'common/models/pagination';
import { categoryPaginationSelector } from 'state/category/categorySelectors';
import { Category } from 'common/models/category';
import { getCategoryList } from 'common/services/category';

export const useCategoryListQuery = (
  options: QueryObserverOptions<ResultResponse<Category>>
) => {
  const paginationOption = useSelector(categoryPaginationSelector);

  const pagination = pipe(
    paginationOption,
    getOrElse(() => initialPagination)
  );

  return useQuery<ResultResponse<Category>>(
    [QueryKey.CategoryList, pagination.page, pagination.pageSize],
    () => getCategoryList(pagination),
    {
      ...options,
      enabled: isSome(paginationOption),
      keepPreviousData: true,
    }
  );
};
