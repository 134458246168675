import { AuthorizedAxios, throwErrorResponse } from 'common/services/axios';
import { flatten } from 'lib/axios';
import { PaginationParams } from 'common/models/pagination';
import { Trainer } from 'common/models/trainer';
import { ListWrapper, unwrap } from 'lib/listWrapper';

export const getTrainerList = ({ page, pageSize }: PaginationParams) =>
  AuthorizedAxios.get<ListWrapper<Trainer>>('/api/trainers', {
    params: { page, pageSize },
  })
    .then(flatten)
    .then(unwrap)
    .catch(throwErrorResponse);
