import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { snackbarActions } from 'state/notification/notificationActions';
import { push } from 'connected-react-router';
import { updateAboutUsStaticPage } from 'modules/static-pages/services/staticPage';
import {
  AboutUsStaticPage,
  AboutUsStaticPageDTO,
} from 'modules/static-pages/models/aboutUsStaticPage';
import { StaticPageListRoute } from 'modules/static-pages/routes';

export const useUpdateAboutUsStaticPageMutation = () => {
  const dispatch = useDispatch();

  return useMutation<AboutUsStaticPage, unknown, AboutUsStaticPageDTO>(
    (params) => updateAboutUsStaticPage(params),
    {
      onSuccess: () => {
        dispatch(push(StaticPageListRoute));
        dispatch(
          snackbarActions.enqueueSnackbar({
            title: 'Treść strony została zapisana pomyślnie',
            options: { variant: 'success' },
          })
        );
      },
    }
  );
};
