import Axios, { Cancel } from 'axios';
import { identity } from 'ramda';
import { environment } from 'config/environment';
import { errorMessageMap, TranslatedError } from 'common/models/error';
import {
  attachAccessToken,
  setCmsAuthHeader,
  unauthorizedGuard,
} from 'lib/axios';
import {
  buildErrorResponse,
  isAbortError,
  isAppError,
} from 'common/services/error';
import { captureException } from 'common/services/sentry';
import { setAuthHeader } from 'common/utils/http';

Axios.defaults.headers.common['Accept-Language'] = 'pl-PL';

// ------------------- http clients

export const BaseAxios = Axios.create({
  baseURL: environment.apiUrl,
  headers: {
    Authorization: `Basic ${environment.basicAuth}`,
    'X-client-name': environment.appName,
    'X-client-version': environment.appVersion,
  },
});

export const AuthorizedAxios = Axios.create({
  baseURL: environment.apiUrl,
  headers: {
    'X-client-name': environment.appName,
    'X-client-version': environment.appVersion,
  },
});

export const CmsAxios = Axios.create({
  baseURL: environment.cmsUrl,
});

// ------------------- error handling

// https://github.com/axios/axios#handling-errors
export const toErrorResponse = (error: unknown): TranslatedError[] => {
  let response;

  if (Axios.isAxiosError(error)) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      response = buildErrorResponse(error.response.data, error);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      response = buildErrorResponse(
        {
          errors: [
            {
              // data: error,
              errorCode: '-1',
              message: 'Request error',
            },
          ],
        },
        error
      );
    } else {
      // Something happened in setting up the request that triggered an Error
      response = buildErrorResponse(
        {
          errors: [
            {
              errorCode: '-1',
              message: 'Internal error',
            },
          ],
        },
        error
      );
    }
  } else if (isAppError(error) && errorMessageMap[error.code]) {
    response = buildErrorResponse(
      {
        errors: [
          {
            errorCode: error.code,
            message: errorMessageMap[error.code],
          },
        ],
      },
      error
    );
  } else if (Axios.isCancel(error) || isAbortError(error)) {
    response = buildErrorResponse(
      {
        errors: [
          {
            errorCode: (error as Cancel).message,
            message: 'Canceled request',
          },
        ],
      },
      error as Error
    );
  } else {
    response = buildErrorResponse(
      {
        errors: [
          {
            errorCode: '-1',
            message: 'Unexpected error',
          },
        ],
      },
      error as Error
    );
  }

  response.forEach((error) => {
    captureException(error);
  });

  return response;
};

export const throwErrorResponse = (error: unknown) => {
  throw toErrorResponse(error);
};

// ------------------- http clients configuration

AuthorizedAxios.interceptors.response.use(identity, unauthorizedGuard);
CmsAxios.interceptors.response.use(identity, unauthorizedGuard);

AuthorizedAxios.interceptors.request.use(attachAccessToken(setAuthHeader));
CmsAxios.interceptors.request.use(attachAccessToken(setCmsAuthHeader));

export interface CancelablePromise<T> extends Promise<T> {
  cancel?: () => void;
}
