import { useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { getWebinarStartUrl } from 'common/services/zoom';

export const useWebinarStartUrlQuery = (eventId: string, enabled?: boolean) => {
  return useQuery(
    [QueryKey.WebinarStartUrl, eventId],
    () => getWebinarStartUrl(eventId),
    {
      enabled,
      refetchInterval: 60 * 1000,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    }
  );
};
