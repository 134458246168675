import { useMutation } from 'react-query';
import { materialsActions } from 'state/materials/materialsActions';
import { snackbarActions } from 'state/notification/notificationActions';
import { useDispatch } from 'react-redux';
import { MaterialStatus } from 'common/models/material';
import { TranslatedError } from 'common/models/error';
import { captureException } from 'common/services/sentry';
import { isApiErrorItem } from 'common/services/error';
import { deleteMaterial } from 'common/services/material';

export const useDeleteMaterialMutation = (materialId: string) => {
  const dispatch = useDispatch();

  return useMutation(deleteMaterial, {
    onError: (errors) => {
      (errors as TranslatedError[]).map((translatedError) => {
        captureException(translatedError);
        dispatch(
          snackbarActions.enqueueSnackbar({
            subtitle:
              isApiErrorItem(translatedError.error) &&
              translatedError.error.errorCode,
            title: translatedError.message,
            options: {
              variant: 'error',
            },
          })
        );
      });

      materialsActions.updateStatus({
        id: materialId,
        status: MaterialStatus.Changed,
      });
    },
    onMutate: () => {
      dispatch(
        materialsActions.updateStatus({
          id: materialId,
          status: MaterialStatus.Deleting,
        })
      );
    },
    onSuccess: () => {
      dispatch(materialsActions.delete(materialId));
      dispatch(
        snackbarActions.enqueueSnackbar({
          title: 'Materiał szkoleniowy został usunięty',
          options: { variant: 'success' },
        })
      );
    },
  });
};
