import { createReducer } from '@reduxjs/toolkit';
import { PaginationState, SortDirection } from 'common/models/pagination';
import { none, Option, some } from 'fp-ts/lib/Option';
import { elearningActions } from 'state/elearning/elearningActions';
import {
  ElearningListColumn,
  ElearningSortData,
} from 'common/models/elearning';

export interface ElearningState {
  category: Option<string>;
  pagination: Option<PaginationState>;
  sort: ElearningSortData;
}

export const defaultSort: ElearningSortData = {
  direction: SortDirection.Asc,
  orderBy: ElearningListColumn.CourseTitle,
};

const initialState: ElearningState = {
  category: none,
  pagination: none,
  sort: defaultSort,
};

export const elearningReducer = createReducer<ElearningState>(
  initialState,
  (builder) =>
    builder
      .addCase(elearningActions.clear, () => initialState)
      .addCase(elearningActions.setCategory, (state, action) => {
        state.category = action.payload ? some(action.payload.id) : none;
      })
      .addCase(elearningActions.setCategoryId, (state, action) => {
        state.category = action.payload ? some(action.payload) : none;
      })
      .addCase(elearningActions.setFilters, (state, action) => {
        state.category = action.payload.categoryId
          ? some(action.payload.categoryId)
          : none;
        state.pagination = some(action.payload.pagination);
        state.sort = action.payload.sort;
      })
      .addCase(elearningActions.setPagination, (state, action) => {
        state.pagination = some(action.payload);
      })
      .addCase(elearningActions.setSort, (state, action) => {
        state.sort = action.payload;
      })
);
