import { BannerColumn } from 'modules/banner/types.banner';

export const bannerHeaders = [
  {
    key: BannerColumn.TITLE,
    header: 'Tytuł',
  },
  {
    key: BannerColumn.SUBPAGE,
    header: 'Podstrona',
  },
  {
    key: BannerColumn.LINK,
    header: 'Przekierowanie',
  },
  {
    key: BannerColumn.CREATE_DATE,
    header: 'Data dodania',
  },
  {
    key: BannerColumn.DISPLAY_STATUS,
    header: 'Status',
  },
];
