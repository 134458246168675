import { ListItem } from 'common/models/course';
import { filter, map, whereEq } from 'ramda';
import { ID } from 'lib/id';
import { StoredImageDTO } from 'common/models/image';

export interface CategoryFormPageParams {
  categoryId: string;
}

export interface SpecialCategoryDataDTO {
  readonly partnerLogo: StoredImageDTO | null;
  partnerName: string;
  programName: string;
  usesSpecialCertificateTemplate: boolean;
}

export interface SpecialCategoryDataRequestDTO {
  partnerName: string;
  programName: string;
  usesSpecialCertificateTemplate: boolean;
}

export interface Category {
  code: string;
  id: string;
  isSpecialCategory: boolean;
  label: string;
  slug: string;
  specialCategoryData: SpecialCategoryDataDTO | null;
}

export interface CategoryDTO {
  code: string;
  id: number;
  isSpecialCategory: boolean;
  label: string;
  slug: string;
  specialCategoryData: SpecialCategoryDataDTO | null;
}

export interface CategoryRequestDTO {
  code: string;
  isSpecialCategory: boolean;
  label: string;
  slug: string;
  specialCategoryData: SpecialCategoryDataRequestDTO;
}

export interface CategoryFormModel {
  code: string;
  label: string;
  partnerLogoFile?: File;
  currentPartnerLogo: string;
  partnerName: string;
  programName: string;
  slug: string;
  usesSpecialCertificateTemplate: boolean;
}

export interface CategoryCreateDTO {
  category: CategoryRequestDTO;
  partnerLogo: File;
}

export interface CategoryUpdateDTO {
  id: ID;
  category: CategoryRequestDTO;
  partnerLogo: File;
}

export enum CategoryListColumn {
  Label = 'label',
}

export const categoryCodeItems: ListItem[] = map(
  (code) => ({
    id: code,
    label: code,
  }),
  [
    'category-career-startup',
    'category-finances',
    'category-marketing',
    'category-programming',
    'category-company-management',
    'chat',
    'category-law',
    'category-applications',
    'category-soft-skills',
    'category-your-courses',
    'special-category-1',
    'special-category-2',
    'special-category-3',
    'special-category-4',
    'special-category-5',
  ]
);

export const initialCategoryFormValues: Partial<CategoryFormModel> = {
  code: '',
  label: '',
  partnerName: '',
  programName: '',
  slug: '',
  usesSpecialCertificateTemplate: false,
};

export const mapToCategory = ({ id, ...rest }: CategoryDTO): Category => ({
  id: id.toString(),
  ...rest,
});

export const mapToCategoryRequestDTO = ({
  code,
  label,
  partnerName,
  programName,
  slug,
  usesSpecialCertificateTemplate,
}: CategoryFormModel): CategoryRequestDTO => ({
  isSpecialCategory: true,
  code: code || '',
  specialCategoryData: {
    partnerName,
    programName,
    usesSpecialCertificateTemplate,
  },
  label,
  slug,
});

export const mapToCategoryCreateDTO = (
  params: CategoryFormModel
): CategoryCreateDTO => ({
  category: mapToCategoryRequestDTO(params),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  partnerLogo: params.partnerLogoFile!,
});

export const mapToCategoryUpdateDTO = (
  params: CategoryFormModel,
  id: ID
): CategoryUpdateDTO => ({
  category: mapToCategoryRequestDTO(params),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  partnerLogo: params.partnerLogoFile!,
  id,
});

export const mapToCategoryFormModel = ({
  code,
  label,
  slug,
  specialCategoryData,
}: Category): CategoryFormModel => ({
  currentPartnerLogo: specialCategoryData?.partnerLogo?.url || '',
  partnerName: specialCategoryData?.partnerName || '',
  programName: specialCategoryData?.programName || '',
  usesSpecialCertificateTemplate:
    specialCategoryData?.usesSpecialCertificateTemplate || false,
  code,
  label,
  slug,
});

export const isSpecialCategoryPredicate = whereEq({ isSpecialCategory: true });
export const isNotSpecialCategoryPredicate = whereEq({
  isSpecialCategory: false,
});

export const filterSpecialCategories = (categories: Category[]): Category[] =>
  filter(isNotSpecialCategoryPredicate, categories);
