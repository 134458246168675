export const getVideoDuration = (
  videoUrl: string,
  callback?: (duration: number) => void
) => {
  const video = document.createElement('video');

  video.onloadedmetadata = () => {
    window.URL.revokeObjectURL(video.src);
    callback && callback(video.duration);
  };

  video.preload = 'metadata';
  video.src = videoUrl;
};
