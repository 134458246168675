import { FC, useCallback, useContext } from 'react';
import { DataTableButton } from 'app/components/DataTableButton';
import { Save24 } from '@carbon/icons-react';
import { Material, MaterialType } from 'common/models/material';
import { useSelector } from 'react-redux';
import { useCreateMaterialMutation } from 'common/hooks/materials/useCreateMaterialMutation';
import { materialsParentIdSelector } from 'state/materials/materialsSelectors';
import { MaterialsContext } from 'common/contexts/MaterialsContext';

export interface MaterialsSaveButtonProps {
  material: Material;
}

export const MaterialsSaveUrlButton: FC<MaterialsSaveButtonProps> = ({
  material,
}) => {
  const { resource } = useContext(MaterialsContext);
  const parentId = useSelector(materialsParentIdSelector);

  const createUrlMutation = useCreateMaterialMutation(
    parentId,
    resource,
    material.id
  );

  const handleUrlCreate = useCallback(() => {
    createUrlMutation.mutate({
      material: {
        name: material.name,
        type: MaterialType.Url,
        url: material.url,
      },
    });
  }, [material]);

  return (
    <DataTableButton
      hasIconOnly
      iconDescription="Zapisz"
      isLoading={false}
      kind="ghost"
      onClick={handleUrlCreate}
      renderIcon={Save24}
      size="small"
      tooltipAlignment="center"
      tooltipPosition="top"
    />
  );
};
