import { createAction } from 'lib/createAction';
import { PaginationState } from 'common/models/pagination';
import { CourseBundleElearningListSortData } from 'common/models/courseBundleContent';
import { ListItem } from 'common/models/course';

export interface CourseBundleElearningListFilters {
  categoryId?: string;
  courseBundleId?: string;
  pagination: PaginationState;
  sort: CourseBundleElearningListSortData;
}

export const courseBundleElearningListActions = {
  clear: createAction('courseBundleElearningList/clear')(),
  loadPagination: createAction('courseBundleElearningList/loadPagination')(),
  setCategory: createAction('courseBundleElearningList/setCategory')<
    ListItem | null | undefined
  >(),
  setCategoryId: createAction('courseBundleElearningList/setCategoryId')<
    string | undefined
  >(),
  setCourseBundleId: createAction(
    'courseBundleElearningList/setCourseBundleId'
  )<string | undefined>(),
  setFilters: createAction(
    'courseBundleElearningList/setFilters'
  )<CourseBundleElearningListFilters>(),
  setPagination: createAction(
    'courseBundleElearningList/setPagination'
  )<PaginationState>(),
  setSort: createAction(
    'courseBundleElearningList/setSort'
  )<CourseBundleElearningListSortData>(),
} as const;
