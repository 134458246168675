import { FC, useCallback } from 'react';
import { DataTableButton } from 'app/components/DataTableButton';
import { Save24 } from '@carbon/icons-react';
import { Material, MaterialType } from 'common/models/material';
import { useUpdateMaterialMutation } from 'common/hooks/materials/useUpdateMaterialMutation';

export interface MaterialsUpdateButtonProps {
  material: Material;
}

export const MaterialsUpdateButton: FC<MaterialsUpdateButtonProps> = ({
  material,
}) => {
  const updateMutation = useUpdateMaterialMutation(material.id);

  const handleUpdate = useCallback(() => {
    updateMutation.mutate({
      name: material.name,
      url: material.type === MaterialType.Url ? material.url : undefined,
    });
  }, [material]);

  return (
    <DataTableButton
      hasIconOnly
      iconDescription="Zapisz"
      isLoading={false}
      kind="ghost"
      onClick={handleUpdate}
      renderIcon={Save24}
      size="small"
      tooltipAlignment="center"
      tooltipPosition="top"
    />
  );
};
