import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { Tag } from 'carbon-components-react';
import { blue60, gray50, red60, white } from '@carbon/colors';
import { WarningFilled } from '@carbon/icons-react/next';
import { DropdownArrowIcon } from 'app/components/CourseBundleForm/NestedMultiSelect/DropdownArrowIcon';

const Container = styled.div<{ $isError?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 48px 0 16px;
  background-color: ${white};
  border-bottom: 1px solid ${gray50};
  cursor: pointer;

  &:hover {
    background-color: ${transparentize(0.88, gray50)};
  }

  &:focus-within {
    outline: 2px solid ${blue60};
    outline-offset: -2px;
  }

  ${(props) =>
    props.$isError &&
    css`
      border: 2px solid ${red60};
    `}
`;

const TagContainer = styled.div`
  & > * {
    margin: 0;
  }
`;

const DropdownIconControl = styled.div`
  position: absolute;
  padding: 0 4px;
  margin: 0;
  right: 16px;
`;

const StyledWarningFilled = styled(WarningFilled)`
  color: ${red60};
  margin-right: 4px;
`;

interface NestedMultiSelectInputFieldProps {
  selectedItemsCounter: number;
  onClick: () => void;
  isDropdownHidden: boolean;
  invalid: boolean;
  handleClearAll?: (e?: React.MouseEvent) => void;
}

const handleKeyDown = (
  e: React.KeyboardEvent<HTMLDivElement>,
  onClick: () => void
) => {
  if (e.key === 'Enter') {
    onClick();
  }
};

export const CounterButton: FC<NestedMultiSelectInputFieldProps> = ({
  selectedItemsCounter,
  onClick,
  isDropdownHidden,
  invalid,
  handleClearAll,
}) => (
  <Container
    onClick={onClick}
    onKeyDown={(e) => handleKeyDown(e, onClick)}
    tabIndex={0}
    $isError={invalid}
  >
    {selectedItemsCounter !== 0 && (
      <TagContainer>
        <Tag filter={true} onClose={handleClearAll} type="high-contrast">
          {selectedItemsCounter}
        </Tag>
      </TagContainer>
    )}
    <DropdownIconControl>
      {invalid && <StyledWarningFilled />}
      <DropdownArrowIcon $dropdownHidden={isDropdownHidden} />
    </DropdownIconControl>
  </Container>
);
