import Header from '@editorjs/header';
import Underline from '@editorjs/underline';
import NestedList from 'editorjs/nested-list';

export const editorjsTools = {
  header: Header,
  list: {
    class: NestedList,
    inlineToolbar: true,
  },
  underline: Underline,
};

export const editorjsTextOnlyTools = {
  underline: Underline,
};
