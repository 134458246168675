import { createReducer } from '@reduxjs/toolkit';
import { PaginationState } from 'common/models/pagination';
import { none, Option, some } from 'fp-ts/lib/Option';
import { userActions } from 'state/user/userActions';

export interface UserState {
  pagination: Option<PaginationState>;
}

const initialState: UserState = {
  pagination: none,
};

export const userReducer = createReducer<UserState>(initialState, (builder) =>
  builder.addCase(userActions.setPagination, (state, action) => {
    state.pagination = some(action.payload);
  })
);
