import { Action } from 'redux';
import { splitRequestAction } from 'lib/requestActions';
import { isNone } from 'fp-ts/lib/Option';
import { authActions } from 'state/auth/authActions';
import { RequestStatus } from 'state/requestStatus/requestStatus';

export interface RequestStatusState {
  [action: string]: RequestStatus | undefined;
}

export const requestStatusReducer = (
  state: RequestStatusState = {},
  action: Action
): RequestStatusState => {
  if (action.type === authActions.logout.type) {
    return {};
  }

  const requestInfo = splitRequestAction(action.type);

  if (isNone(requestInfo) || requestInfo.value.state === 'request') {
    return state;
  }

  return {
    ...state,
    [requestInfo.value.name]:
      'requestSuccess' === requestInfo.value.state
        ? RequestStatus.Ok
        : RequestStatus.Error,
  };
};
