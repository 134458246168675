import {
  DataTableCustomRenderProps,
  DenormalizedRow,
  Loading,
  OverflowMenu,
  OverflowMenuItem,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import { ActionsTableCell } from 'app/components/Table';
import { FC } from 'react';
import { User } from 'common/models/user';
import { useUpdateUserAccountPlusStatusMutation } from 'common/hooks/user/useUpdateUserAccountPlusStatusMutation';
import { BlockLoadingButton } from 'app/components/Button';

export const preparePartnerUsersRow = (a: User) => ({
  id: `${a.id}`,
  email: a.login,
  name: `${a.firstName} ${a.lastName}`,
  plus: a.accountPlusStatus ? 'TAK' : '-',
});

export interface PartnerUsersListRowProps {
  row: DenormalizedRow;
  user: User;
}

export const PartnerUsersListRow: FC<
  PartnerUsersListRowProps & Pick<DataTableCustomRenderProps, 'getRowProps'>
> = ({ getRowProps, row, user }) => {
  const updateUserAccountPlusMutation =
    useUpdateUserAccountPlusStatusMutation();

  if (!user) {
    return null;
  }

  return (
    <TableRow {...getRowProps({ row })}>
      {row.cells.map((cell) => (
        <TableCell key={cell.id}>{cell.value}</TableCell>
      ))}

      <ActionsTableCell>
        {updateUserAccountPlusMutation.isLoading && (
          <BlockLoadingButton disabled hasIconOnly kind="ghost" size="small">
            <Loading small withOverlay={false} />
          </BlockLoadingButton>
        )}

        {!updateUserAccountPlusMutation.isLoading && (
          <OverflowMenu size="sm" light flipped>
            {!user.accountPlusStatus && (
              <OverflowMenuItem
                itemText="Ustaw status PLUS"
                onClick={() =>
                  updateUserAccountPlusMutation.mutate({
                    id: row.id,
                    accountPlusStatus: true,
                  })
                }
                requireTitle
              />
            )}

            {user.accountPlusStatus && (
              <OverflowMenuItem
                itemText="Usuń status PLUS"
                onClick={() =>
                  updateUserAccountPlusMutation.mutate({
                    id: row.id,
                    accountPlusStatus: false,
                  })
                }
                requireTitle
              />
            )}
          </OverflowMenu>
        )}
      </ActionsTableCell>
    </TableRow>
  );
};
