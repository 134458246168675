import { createReducer } from '@reduxjs/toolkit';
import { PaginationState, SortDirection } from 'common/models/pagination';
import { none, Option, some } from 'fp-ts/lib/Option';
import { CourseEventStatus } from 'common/models/courseEvent';
import {
  CourseBundleEventListColumn,
  CourseBundleEventListSortData,
} from 'common/models/courseBundleContent';
import { courseBundleEventListActions } from 'state/courseBundleContent/eventList/courseBundleEventListActions';

export interface CourseBundleEventListState {
  courseBundleId: string;
  courseId: string;
  pagination: Option<PaginationState>;
  sort: CourseBundleEventListSortData;
  status: Option<CourseEventStatus>;
}

export const defaultSort: CourseBundleEventListSortData = {
  direction: SortDirection.Asc,
  orderBy: CourseBundleEventListColumn.StartDateTime,
};

const initialState: CourseBundleEventListState = {
  courseBundleId: '',
  courseId: '',
  pagination: none,
  sort: defaultSort,
  status: none,
};

export const courseBundleEventListReducer =
  createReducer<CourseBundleEventListState>(initialState, (builder) =>
    builder
      .addCase(courseBundleEventListActions.clear, () => initialState)
      .addCase(
        courseBundleEventListActions.setCourseBundleId,
        (state, action) => {
          state.courseBundleId = action.payload || '';
        }
      )
      .addCase(courseBundleEventListActions.setCourseId, (state, action) => {
        state.courseId = action.payload || '';
      })
      .addCase(courseBundleEventListActions.setFilters, (state, action) => {
        state.courseBundleId = action.payload.courseBundleId || '';
        state.courseId = action.payload.courseId || '';
        state.pagination = some(action.payload.pagination);
        state.sort = action.payload.sort;
        state.status = action.payload.status
          ? some(action.payload.status)
          : none;
      })
      .addCase(courseBundleEventListActions.setPagination, (state, action) => {
        state.pagination = some(action.payload);
      })
      .addCase(courseBundleEventListActions.setSort, (state, action) => {
        state.sort = action.payload;
      })
      .addCase(courseBundleEventListActions.setStatus, (state, action) => {
        state.status = action.payload ? some(action.payload) : none;
      })
  );
