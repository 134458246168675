import { setLocale } from 'yup';
import { validatorMessages } from 'common/services/validation';
import { countLabelFormatter } from 'lib/countLabelFormatter';

const arrayMinLabelFormatter = countLabelFormatter(
  '',
  '1 element',
  (count) => `${count} elementy`,
  (count) => `${count} elementów`
);

const stringMaxLabelFormatter = countLabelFormatter(
  '',
  '1 znak',
  (count) => `${count} znaki`,
  (count) => `${count} znaków`
);

export const setYupDefaultMessages = () => {
  setLocale({
    array: {
      max: ({ max }: { max: number }) =>
        `Pole może zawierać maksymalnie ${arrayMinLabelFormatter(max)}`,
      min: ({ min }: { min: number }) =>
        `Pole musi zawierać co najmniej ${arrayMinLabelFormatter(min)}`,
    },
    mixed: {
      notType: ({ type }: { type: string }) => {
        switch (type) {
          case 'number':
            return 'Wartość musi być liczbą';
          default:
            return 'Błędny typ wartości';
        }
      },
      required: validatorMessages.required,
    },
    number: {
      min: 'Wartość musi być większa bądź równa ${min}',
      max: 'Wartość musi być mniejsza bądź równa ${max}',
      integer: 'Wartość musi być liczbą całkowitą',
    },
    string: {
      max: ({ max }: { max: number }) =>
        `Wartość może mieć maksymalnie ${stringMaxLabelFormatter(max)}`,
      url: `Wartość musi byc prawidłowym adresem URL`,
    },
  });
};
