import { courseEventStatusLabelMap } from 'common/models/courseEvent';
import { formatDateOnLists } from 'lib/date';
import { DashboardColumn, DashboardEvent } from 'modules/dashboard/types';

export function dashboardRow(event: DashboardEvent) {
  const {
    startDateTime,
    speaker,
    id,
    courseTitle,
    participantsAccepted,
    status,
  } = event;

  return {
    event,

    id: String(id),

    [DashboardColumn.TITLE]: courseTitle ?? '--',

    [DashboardColumn.DATE]: formatDateOnLists(startDateTime),

    [DashboardColumn.ATTENDEES]: `${participantsAccepted} uczestników`,

    [DashboardColumn.STATUS]: courseEventStatusLabelMap[status],

    [DashboardColumn.INSTRUCTOR]: `${speaker.firstName} ${speaker.lastName}`,
  };
}
