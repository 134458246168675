// Backend uses SurveyListItemDTO, they should change it

export interface SurveyListItemDTO {
  courseTitle: string;
  surveyTitle: string;
  surveyId: number;
}

export interface SurveyListItem {
  courseTitle: string;
  title: string;
  id: string;
}

export const mapToSurveyListItem = ({
  courseTitle,
  surveyId,
  surveyTitle,
}: SurveyListItemDTO): SurveyListItem => ({
  courseTitle,
  id: surveyId.toString(),
  title: surveyTitle,
});

export enum QuestionType {
  // Text = 'TEXT',
  Option = 'OPTION',
  MultiOption = 'MULTI_OPTION',
  Scale = 'SCALE',
}

export enum ComparisonOperator {
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
  Equal = 'EQUAL',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  GreaterThan = 'GREATER_THAN',
}

export interface ChoiceOption {
  id?: number;
  enablesComment?: boolean;
  text: string;
}

export interface ConditionDTO {
  comparisonOperator: ComparisonOperator;
  comparisonValue: number; // <1,5>
}

export interface QuestionDTO {
  id?: number;
  mandatory: boolean;

  choiceOptions?: ChoiceOption[];
  condition?: ConditionDTO;

  customOptionAllowed?: boolean;
  customOptionLabel?: string; // <0,30>
  customOptionPlaceholder?: string;
  maxLabel?: string; // <0,30>
  minLabel?: string; // <0,30>
  questionType: QuestionType;
  text: string;
}

export interface SurveyDTO {
  id?: number;
  title: string;
  questions: QuestionDTO[];
}
