import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';
import { CourseEventState } from 'state/courseEvent/courseEventReducer';

export const courseEventStateSelector = (state: RootState) => state.courseEvent;

export const courseEventCourseIdSelector = createSelector(
  courseEventStateSelector,
  (state: CourseEventState) => state.courseId
);

export const courseEventPaginationSelector = createSelector(
  courseEventStateSelector,
  (state: CourseEventState) => state.pagination
);

export const courseEventSortSelector = createSelector(
  courseEventStateSelector,
  (state) => state.sort
);

export const courseEventStatusSelector = createSelector(
  courseEventStateSelector,
  (state) => state.status
);
