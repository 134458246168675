import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';
import { CourseState } from 'state/course/courseReducer';

export const courseStateSelector = (state: RootState) => state.course;

export const coursePaginationSelector = createSelector(
  courseStateSelector,
  (state: CourseState) => state.pagination
);

export const courseSortSelector = createSelector(
  courseStateSelector,
  (state) => state.sort
);

export const courseSpeakerSelector = createSelector(
  courseStateSelector,
  (state) => state.speaker
);
