import { createAction } from 'lib/createAction';
import { PaginationState } from 'common/models/pagination';
import { ListItem } from 'common/models/course';
import { ElearningSortData } from 'common/models/elearning';

export interface ElearningListFilters {
  categoryId?: string;
  pagination: PaginationState;
  sort: ElearningSortData;
}

export const elearningActions = {
  clear: createAction('elearning/clear')(),
  loadPagination: createAction('elearning/loadPagination')(),
  setCategory: createAction('elearning/setCategory')<
    ListItem | null | undefined
  >(),
  setCategoryId: createAction('elearning/setCategoryId')<string | undefined>(),
  setFilters: createAction('elearning/setFilters')<ElearningListFilters>(),
  setPagination: createAction('elearning/setPagination')<PaginationState>(),
  setSort: createAction('elearning/setSort')<ElearningSortData>(),
} as const;
