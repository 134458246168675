import React, { FC, useEffect } from 'react';
import {
  Button,
  Column,
  Form,
  InlineLoading,
  Row,
  TextAreaSkeleton,
  TextInput,
  TextInputSkeleton,
} from 'carbon-components-react';
import { FormItem } from 'app/components/FormItem';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ImageControl } from 'app/components/ImageControl';
import {
  fieldsOrder,
  partnerFormValidationSchema,
} from 'app/components/PartnerForm/config';
import { useScrollToError } from 'common/hooks/useScrollToError';

export interface PartnerFormModel {
  name: string;
  currentImage?: string;
  imageFile?: File;
}

export interface PartnerFormProps {
  initialValue?: PartnerFormModel;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onSubmit: (value: PartnerFormModel) => void;
}

export const PartnerForm: FC<PartnerFormProps> = ({
  initialValue,
  isSubmitting,
  isLoading,
  onSubmit,
}) => {
  const methods = useForm<PartnerFormModel>({
    defaultValues: initialValue ?? {
      name: '',
      currentImage: undefined,
    },
    resolver: yupResolver(partnerFormValidationSchema),
    shouldFocusError: false,
  });

  const onError = useScrollToError(fieldsOrder);

  useEffect(() => {
    methods.reset(initialValue);
  }, [initialValue]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit, onError)} noValidate>
        <Row>
          <Column lg={8}>
            <FormItem>
              {isLoading ? (
                <TextInputSkeleton />
              ) : (
                <TextInput
                  id="name"
                  invalid={methods.formState.errors.name != null}
                  invalidText={methods.formState.errors.name?.message}
                  labelText="Nazwa"
                  placeholder="Nazwa partnera"
                  {...methods.register('name')}
                />
              )}
            </FormItem>
          </Column>
          <Column lg={4}>
            <FormItem>
              {isLoading ? (
                <TextAreaSkeleton />
              ) : (
                <Controller
                  control={methods.control}
                  name="imageFile"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <ImageControl
                      currentImage={initialValue?.currentImage}
                      id="imageFile"
                      invalid={!!error}
                      invalidText={error?.message}
                      onChange={(file) => {
                        onChange(file);
                      }}
                    />
                  )}
                />
              )}
            </FormItem>
          </Column>
        </Row>

        {isSubmitting ? (
          <Button disabled kind="primary" tabIndex={-1}>
            <InlineLoading description={'Zapisywanie...'} status="active" />
          </Button>
        ) : (
          <Button kind="primary" tabIndex={0} type="submit">
            {initialValue == null ? 'Utwórz' : 'Zapisz zmiany'}
          </Button>
        )}
      </Form>
    </FormProvider>
  );
};
