import { Portal } from 'common/components/Portal';
import React, { FC } from 'react';
import { fileUploaderTotalCountSelector } from 'state/fileUploader/fileUploaderSelectors';
import { useSelector } from 'react-redux';
import { FileUploader } from 'app/components/FileUploader';

export const FileUploaderDrawer: FC = () => {
  const totalCount = useSelector(fileUploaderTotalCountSelector);

  if (totalCount === 0) {
    return null;
  }

  return (
    <Portal>
      <FileUploader />
    </Portal>
  );
};
