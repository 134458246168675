import { useMutation } from 'react-query';
import { queryClient } from 'common/providers/ReactQueryClientProvider';
import { QueryKey } from 'config/queryKey';
import { updateCourseBundleEvents } from 'common/services/courseBundle';
import { ID } from 'lib/id';

export const useUpdateCourseBundleEventListMutation = (bundleId: ID) =>
  useMutation(
    (eventIds: number[]) => updateCourseBundleEvents(bundleId, eventIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.CourseBundleEventList]);
      },
    }
  );
