import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createMatchSelector,
  push,
  RouterRootState,
} from 'connected-react-router';
import { stringify } from 'qs';
import { pipe } from 'fp-ts/function';
import { filter, fromNullable, getOrElse, Option } from 'fp-ts/lib/Option';
import { getQueryParamsMap } from 'lib/getQueryParams';
import { mapObjIndexed } from 'ramda';
import { initialPagination, PaginationState } from 'common/models/pagination';
import { isString } from 'lib/typeGuards';
import { PartnerUsersListRoute } from 'routes';
import { rootStateSelector } from 'state/router/routerSelectors';
import {
  partnerUsersPaginationSelector,
  partnerUsersPartnerIdSelector,
} from 'state/partnerUsers/partnerUsersSelectors';
import { PartnerUsersListParams } from 'common/models/partner';
import { partnerUsersActions } from 'state/partnerUsers/partnerUsersActions';
import { setParam } from 'common/utils/routing';

function* writeToURLQuery() {
  const partnerId: string = yield select(partnerUsersPartnerIdSelector);
  const pagination: Option<PaginationState> = yield select(
    partnerUsersPaginationSelector
  );

  const qs = pipe(
    pagination,
    getOrElse(() => initialPagination),
    stringify
  );

  yield put(
    push(`${setParam(':partnerId')(PartnerUsersListRoute)(partnerId)}?${qs}`, {
      replace: true,
    })
  );
}

function* readFromURLQuery() {
  const qp = getQueryParamsMap();

  const routerState: RouterRootState = yield select(rootStateSelector);
  const matchSelector = createMatchSelector<
    RouterRootState,
    PartnerUsersListParams
  >(PartnerUsersListRoute);

  const match = matchSelector(routerState);

  const pagination = pipe(
    initialPagination,
    mapObjIndexed((value, key) =>
      pipe(
        fromNullable(qp[key]),
        filter(isString),
        getOrElse(() => value.toString()),
        Number
      )
    )
  );

  yield put(partnerUsersActions.setPartnerId(match?.params.partnerId));
  yield put(partnerUsersActions.setPagination(pagination));
}

function* readFromURLQueryWatcher() {
  yield takeEvery(partnerUsersActions.loadPagination.type, readFromURLQuery);
}

function* writeToURLQueryWatcher() {
  yield takeLatest(partnerUsersActions.setPagination.type, writeToURLQuery);
}

export function* partnerUsersSaga() {
  yield all([writeToURLQueryWatcher(), readFromURLQueryWatcher()]);
}
