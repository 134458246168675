import React, { FC, useEffect, useState } from 'react';
import { Modal, TextInput } from 'carbon-components-react';
import { Portal } from 'common/components/Portal';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MaterialsFileFormModel } from 'common/models/material';
import { useDispatch, useSelector } from 'react-redux';
import { materialsActions } from 'state/materials/materialsActions';
import { materialsFileFormModalItemSelector } from 'state/materials/materialsSelectors';
import {
  MaterialsModalFormItem,
  MaterialsModalForm,
} from 'app/components/Materials/MaterialsModalForm';

const formSchema = yup.object({
  name: yup.string().required(),
});

export interface CourseMaterialsFileFormModalProps {
  onClose: () => void;
  open: boolean;
}

export const MaterialsFileFormModal: FC<CourseMaterialsFileFormModalProps> = ({
  onClose,
  open,
}) => {
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const material = useSelector(materialsFileFormModalItemSelector);

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<MaterialsFileFormModel>({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (open) {
      setIsSubmitted(false);
      reset({
        name: material?.name,
      });
    }
  }, [open]);

  useEffect(() => {
    reset({
      name: material?.name,
    });
  }, [material]);

  const onSubmit = handleSubmit((values) => {
    if (!material || isSubmitted) {
      return;
    }

    setIsSubmitted(true);

    dispatch(
      materialsActions.updateFile({
        id: material.id,
        values,
      })
    );
    onClose();
  });

  return (
    <Portal>
      <Modal
        modalHeading="Edytuj nazwę materiału szkoleniowego"
        onRequestClose={onClose}
        onRequestSubmit={onSubmit}
        open={open}
        primaryButtonDisabled={isSubmitted}
        primaryButtonText="Zmień"
        secondaryButtonText="Anuluj"
        shouldSubmitOnEnter
        size="sm"
      >
        <MaterialsModalForm onSubmit={onSubmit}>
          <MaterialsModalFormItem>
            <TextInput
              data-modal-primary-focus
              id="fileName"
              invalid={!!errors.name}
              invalidText={errors.name?.message}
              labelText="Nazwa"
              {...register('name')}
            />
          </MaterialsModalFormItem>
        </MaterialsModalForm>
      </Modal>
    </Portal>
  );
};
