import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { CourseBundleListRoute } from 'routes';
import { snackbarActions } from 'state/notification/notificationActions';
import { queryClient } from 'common/providers/ReactQueryClientProvider';
import { QueryKey } from 'config/queryKey';
import { updateCourseBundle } from 'common/services/courseBundle';
import { CourseBundleUpdateDTO } from 'common/models/courseBundle';
import { ID } from 'lib/id';

export function useUpdateCourseBundleMutation(bundleId: ID) {
  const dispatch = useDispatch();

  return useMutation(
    ({ createBundleDTO, image }: CourseBundleUpdateDTO) =>
      updateCourseBundle({ bundleId, createBundleDTO, image }),
    {
      onSuccess: () => {
        dispatch(push(CourseBundleListRoute));
        dispatch(
          snackbarActions.enqueueSnackbar({
            title: 'Kurs został zmodyfikowany pomyślnie',
            options: { variant: 'success' },
          })
        );
        queryClient.invalidateQueries([QueryKey.CourseBundle]);
      },
    }
  );
}
