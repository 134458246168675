import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContentSwitcher, Switch } from 'carbon-components-react';
import styled from 'styled-components';
import { blue60 } from '@carbon/colors';
import { CourseBundleContentCategory } from 'common/models/courseBundleContent';
import { courseBundleContentSwitcherCategorySelector } from 'state/courseBundleContent/contentSwitcher/contentSwitcherSelector';
import { setParam } from 'common/utils/routing';
import {
  CourseBundleElearningListRoute,
  CourseBundleEventListRoute,
  courseBundleIdRouteParam,
} from 'routes';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';

const StyledContentSwitcher = styled(ContentSwitcher)`
  &.bx--content-switcher {
    min-width: fit-content;
    max-width: 10%;
    margin-bottom: 2.5rem;

    .bx--content-switcher-btn {
      min-width: max-content;
    }

    .bx--content-switcher--selected::after {
      background-color: ${blue60};
    }
  }
`;

export const CourseBundleContentSwitcher = () => {
  const dispatch = useDispatch();
  const contentCategory = useSelector(
    courseBundleContentSwitcherCategorySelector
  );

  const { courseBundleId } = useParams<{ courseBundleId: string }>();

  const contentSwitchIndexMap = {
    [CourseBundleContentCategory.ElearningList]: 0,
    [CourseBundleContentCategory.EventList]: 1,
  };

  return (
    <StyledContentSwitcher
      id="courseBundleContentsSwitch"
      selectedIndex={contentSwitchIndexMap[contentCategory]}
    >
      <Switch
        text="E-learningi"
        onClick={() => {
          dispatch(
            push(
              `${setParam(courseBundleIdRouteParam)(
                CourseBundleElearningListRoute
              )(courseBundleId)}`
            )
          );
        }}
      />
      <Switch
        text="Wydarzenia"
        onClick={() => {
          dispatch(
            push(
              `${setParam(courseBundleIdRouteParam)(CourseBundleEventListRoute)(
                courseBundleId
              )}`
            )
          );
        }}
      />
    </StyledContentSwitcher>
  );
};
