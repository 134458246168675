import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { Tile } from 'carbon-components-react';
import { purge } from 'lib/purge';
import { ChevronDown16 } from '@carbon/icons-react';
import styled from 'styled-components';
import { settings } from 'carbon-components';
import { useDerivedState } from 'lib/useDerivedState';
import { StyleProps } from 'lib/styleProps';

const prefix = settings.prefix;

const TileBelowTheFoldContentWrapper = styled.div`
  cursor: default;
`;

export interface ExpandableTileBlockProps {
  isExpanded?: boolean;
  light?: boolean;
  showChevron?: boolean;
}

export const ExpandableTileBlock: FC<StyleProps & ExpandableTileBlockProps> = ({
  style,
  className,
  isExpanded: isExpandedExternal,
  light,
  showChevron = true,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useDerivedState(isExpandedExternal, {
    value: isExpandedExternal,
  });
  const [tileMaxHeight, setTileMaxHeight] = useState(0);

  const childrenAsArray = React.Children.toArray(children);
  const tileContentRef = useRef<HTMLDivElement>(null);
  const aboveTheFoldRef = useRef<HTMLDivElement>(null);

  const classes = (
    purge([
      className,
      `${prefix}--tile--expandable`,
      isExpanded ? [`${prefix}--tile--is-expanded`] : undefined,
      light ? [`${prefix}--tile--light`] : undefined,
    ]) as []
  ).join(' ');

  const setMaxHeight = () => {
    if (isExpanded ? tileContentRef.current : aboveTheFoldRef.current) {
      setTileMaxHeight(
        isExpanded
          ? tileContentRef?.current?.getBoundingClientRect().height ?? 0
          : aboveTheFoldRef.current?.getBoundingClientRect().height ?? 0
      );
    }
  };

  useEffect(() => setMaxHeight(), [setMaxHeight, isExpanded]);

  const tileStyle: CSSProperties = {
    ...style,
    maxHeight: isExpanded ? undefined : tileMaxHeight,
    cursor: isExpandedExternal !== undefined ? 'default' : undefined,
  };

  return (
    <Tile
      light={light}
      style={tileStyle}
      className={classes}
      aria-expanded={isExpanded}
      onClick={() => setIsExpanded((v) => !v)}
    >
      <div ref={tileContentRef}>
        <div ref={aboveTheFoldRef} className={`${prefix}--tile-content`}>
          {childrenAsArray[0]}
        </div>
        {showChevron && (
          <div className={`${prefix}--tile__chevron`}>
            <ChevronDown16 />
          </div>
        )}
        <TileBelowTheFoldContentWrapper
          className={`${prefix}--tile-content`}
          onClick={(ev) => ev.stopPropagation()}
        >
          {childrenAsArray[1]}
        </TileBelowTheFoldContentWrapper>
      </div>
    </Tile>
  );
};
