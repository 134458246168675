import styled from 'styled-components';
import { Accordion, AccordionItem } from 'carbon-components-react';

export const FormItem = styled.div`
  margin-bottom: 2rem;
`;

export const FormAccordion = styled(Accordion)`
  margin-bottom: 2rem;
`;

export const FormAccordionItem = styled(AccordionItem)`
  .bx--accordion__content {
    margin-bottom: -2rem;
    padding-right: 1rem;
  }
`;
