import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { QueryKey } from 'config/queryKey';
import { PartnerListRoute } from 'routes';
import { push } from 'connected-react-router';
import { getPartnerById } from 'common/services/partner';
import { ID } from 'lib/id';

export function usePartnerByIdQuery(id: ID, enabled: boolean) {
  const dispatch = useDispatch();

  return useQuery([QueryKey.Partner, id], () => getPartnerById(id), {
    enabled,
    onError: () => {
      dispatch(push(PartnerListRoute));
    },
  });
}
