import { FC } from 'react';
import {
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableCell,
  TableRow,
} from 'carbon-components-react';

export interface CourseBundleListTableRowProps {
  row: DenormalizedRow;
}

export const CourseBundleElearningListTableRow: FC<
  CourseBundleListTableRowProps &
    Pick<DataTableCustomRenderProps, 'getRowProps'>
> = ({ getRowProps, row }) => (
  <TableRow {...getRowProps({ row })}>
    {row.cells.map((cell) => (
      <TableCell key={cell.id}>{cell.value}</TableCell>
    ))}
  </TableRow>
);
