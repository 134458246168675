import { FC } from 'react';
import { TableCell, TableRow } from 'carbon-components-react';

export interface DataTableEmptyRowProps {
  colSpan: number;
}

export const DataTableEmptyRow: FC<DataTableEmptyRowProps> = ({ colSpan }) => (
  <TableRow>
    <TableCell colSpan={colSpan}>Brak elementów do wyświetlenia</TableCell>
  </TableRow>
);
