import { DataProp } from 'editorjs-blocks-react-renderer';
import { StrapiFile } from 'common/models/strapiFile';
import { StrapiItemResponse } from 'modules/static-pages/models/strapi';

export enum StaticPageListColumn {
  Page = 'page',
}

export interface StaticPageAttachment {
  file: StrapiItemResponse<StrapiFile>;
  id: string;
  text: string;
}

export interface StaticPageAttachmentDTO {
  id?: string;
  file?: {
    id: string;
  };
  text: string;
}

export interface StaticPageContent {
  content: DataProp;
  id: string;
  heading: string;
}

export type StaticPageTos = StrapiItemResponse<{
  attachment: StaticPageAttachment | null;
  content: StaticPageContent[];
  intro: DataProp;
  title: string;
  updatedAt: string;
}>;

export interface StaticPageTosDTO {
  data: {
    attachment: StaticPageAttachmentDTO | null;
    content: StaticPageContent[];
    intro: DataProp;
    title: string;
  };
  file?: File;
}

export interface StaticPageTosFormModel {
  attachment: {
    file?: string;
    id?: string;
    text: string;
  };
  attachmentFile?: File;
  content: StaticPageContent[];
  intro: DataProp;
  title: string;
}

export const mapToStaticPageTosFormModel = ({
  data: {
    attributes: { attachment, content, intro, title },
  },
}: StaticPageTos): StaticPageTosFormModel => ({
  attachment: {
    file: attachment?.file.data.id,
    id: attachment?.id,
    text: attachment?.text ?? '',
  },
  content,
  intro,
  title,
});

export const mapToStaticPageTosDTO = ({
  attachment,
  attachmentFile,
  content,
  intro,
  title,
}: StaticPageTosFormModel): StaticPageTosDTO => ({
  data: {
    attachment:
      attachmentFile || attachment.file
        ? {
            file:
              attachment.file && !attachmentFile
                ? { id: attachment.file }
                : undefined,
            id: attachment.id,
            text: attachment.text,
          }
        : null,
    content,
    intro,
    title,
  },
  file: attachmentFile,
});
