import { Analytics16, Screen16 } from '@carbon/icons-react';

export enum CourseType {
  StandardCourse = 'STANDARD_COURSE',
  ElearningCourse = 'ELEARNING_COURSE',
}

export const courseTypeIconMap = {
  [CourseType.ElearningCourse]: Analytics16,
  [CourseType.StandardCourse]: Screen16,
};

export const courseTypeLabelMap = {
  [CourseType.ElearningCourse]: 'E-learning',
  [CourseType.StandardCourse]: 'Szkolenie online',
};
