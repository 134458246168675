export enum SystemSettingsCode {
  PresentPercentage = 'PRESENT_PERCENTAGE',
}

export interface SystemSettingsDTO {
  code: SystemSettingsCode;
  value: string;
}

export interface SystemSettingsFormModel {
  presentPercentage: number;
}

export const mapToSystemSettingsFormModel = ({
  value,
}: SystemSettingsDTO): SystemSettingsFormModel => ({
  presentPercentage: Number(value),
});

export const mapToSystemSettingsDTO = ({
  presentPercentage,
}: SystemSettingsFormModel): SystemSettingsDTO => ({
  code: SystemSettingsCode.PresentPercentage,
  value: String(presentPercentage),
});
