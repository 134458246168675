import * as yup from 'yup';

export const userListExportFormValidationSchema = yup.object({
  fromDate: yup
    .string()
    .test('fromDateValid', 'Pole wymagane', (value, ctx) => {
      return !(!value && !!ctx.parent.toDate);
    }),
  toDate: yup.string().test('toDateValid', 'Pole wymagane', (value, ctx) => {
    return !(!value && !!ctx.parent.fromDate);
  }),
});
