import { QueryObserverOptions, useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { getAllCategoryList } from 'common/services/category';
import { ResultResponse } from 'common/models/response';
import { Category } from 'common/models/category';

const page = 1;
const pageSize = 1000;

export const useAllCategoriesListQuery = (
  options?: QueryObserverOptions<ResultResponse<Category>>
) => {
  return useQuery<ResultResponse<Category>>(
    [QueryKey.AllCategoryList, page, pageSize],
    () => getAllCategoryList({ page, pageSize }),
    options
  );
};
