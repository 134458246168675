import { parseISO } from 'date-fns';
import { coerceUTCDateString } from 'lib/coerceUTCDateString';
import {
  courseBundleUrl,
  elearningLessonUrl,
  standardCourseUrl,
} from 'config/material';

export enum MaterialResource {
  ElearningLesson = 'ELEARNING_LESSON',
  StandardCourse = 'STANDARD_COURSE',
  CourseBundle = 'COURSE_BUNDLE',
}

export enum MaterialStatus {
  Changed = 'changed',
  Deleting = 'deleting',
  New = 'new',
  Saved = 'saved',
  Saving = 'saving',
  Uploading = 'uploading',
}

export enum MaterialType {
  Blob = 'BLOB',
  Url = 'URL',
}

export interface Material {
  createdAt?: Date;
  extension?: string;
  file?: File;
  filename?: string;
  id: string;
  name: string;
  size?: number;
  status: MaterialStatus;
  type: MaterialType;
  url?: string;
}

export interface MaterialDTO {
  createdAt: string;
  extension?: string;
  filename?: string;
  id: number;
  name: string;
  size?: number;
  type: MaterialType;
  url?: string;
}

export interface MaterialList {
  ids: string[];
  map: { [id: string]: Material };
}

export interface MaterialRow {
  id: string;
  name: string;
  status: MaterialStatus;
  type: MaterialType;
}

export interface MaterialCreateRequestDTO {
  file?: File;
  material: {
    name: string;
    type: MaterialType;
    url?: string;
  };
}

export interface MaterialUpdateRequestDTO {
  name: string;
  url?: string;
}

export interface MaterialsFileFormModel {
  name: string;
}

export interface MaterialsUrlFormModel {
  name: string;
  url: string;
}

export interface DownloadMaterialDTO {
  url: string;
}

export const materialsHeaders = [
  {
    key: 'name',
    header: 'Nazwa',
  },
  {
    key: 'type',
    header: 'Typ',
  },
  {
    key: 'status',
    header: 'Status',
  },
];

export const emptyMaterialList: MaterialList = {
  ids: [],
  map: {},
};

export const materialResourceUrlMapper = (resource: MaterialResource) => {
  switch (resource) {
    case MaterialResource.ElearningLesson:
      return elearningLessonUrl;
    case MaterialResource.StandardCourse:
      return standardCourseUrl;
    case MaterialResource.CourseBundle:
      return courseBundleUrl;
    default:
      throw new Error('Unknown material resource');
  }
};

export const materialTypeLabelMapper = (type: MaterialType) => {
  switch (type) {
    case MaterialType.Blob:
      return 'Plik';
    case MaterialType.Url:
      return 'Url';
    default:
      throw new Error('Unknown material type');
  }
};

export const mapDTOToMaterial = (dto: MaterialDTO): Material => ({
  ...dto,
  createdAt: parseISO(coerceUTCDateString(dto.createdAt)),
  id: dto.id.toString(),
  status: MaterialStatus.Saved,
});

export const convertDTOToMaterialList = (
  dtoList: MaterialDTO[]
): MaterialList => {
  const list: MaterialList = {
    ids: [],
    map: {},
  };

  dtoList.forEach((dto) => {
    const id = dto.id.toString();

    list.ids.push(id);
    list.map[id] = mapDTOToMaterial(dto);
  });

  return list;
};

export const isChanged = (status: MaterialStatus) =>
  MaterialStatus.Changed === status;

export const isExistingFile = (status: MaterialStatus, type: MaterialType) =>
  [MaterialStatus.Changed, MaterialStatus.Saved].includes(status) &&
  MaterialType.Blob === type;

export const isExistingUrl = (status: MaterialStatus, type: MaterialType) =>
  [MaterialStatus.Changed, MaterialStatus.Saved].includes(status) &&
  MaterialType.Url === type;

export const isInProgress = (status: MaterialStatus) =>
  [MaterialStatus.Deleting, MaterialStatus.Saving].includes(status);

export const isNewFile = (status: MaterialStatus, type: MaterialType) =>
  MaterialStatus.New === status && MaterialType.Blob === type;

export const isNewUrl = (status: MaterialStatus, type: MaterialType) =>
  MaterialStatus.New === status && MaterialType.Url === type;

export const isSaved = (status: MaterialStatus) =>
  MaterialStatus.Saved === status;

export const isUploading = (status: MaterialStatus) =>
  MaterialStatus.Uploading === status;
