import styled from 'styled-components';
import { TableCell, TableHeader } from 'carbon-components-react';
import { ActionsTableCell } from 'app/components/Table';

export const MaterialsActionsTableCell = styled(ActionsTableCell)`
  width: 15rem;
`;

export const MaterialsTypeTableCell = styled(TableCell)`
  text-align: center !important;
  width: 10rem;
`;

export const MaterialsCenteredTableHeader = styled(TableHeader)`
  .bx--table-header-label {
    text-align: center;
  }
`;

export const MaterialsStatusTableCell = styled(TableCell)`
  width: 10rem;
`;
