import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { CategoryListRoute } from 'routes';
import { snackbarActions } from 'state/notification/notificationActions';
import { queryClient } from 'common/providers/ReactQueryClientProvider';
import { QueryKey } from 'config/queryKey';
import { updateCategoryById } from 'common/services/category';

export function useUpdateCategoryMutation() {
  const dispatch = useDispatch();

  return useMutation(updateCategoryById, {
    onSuccess: () => {
      dispatch(push(CategoryListRoute));
      dispatch(
        snackbarActions.enqueueSnackbar({
          title: 'Kategoria został zmodyfikowana pomyślnie',
          options: { variant: 'success' },
        })
      );
      queryClient.invalidateQueries(QueryKey.CategoryList);
    },
  });
}
