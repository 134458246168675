import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  ComboBox,
  Form,
  Grid,
  InlineLoading,
  MultiSelect,
  Row,
  SkeletonText,
  TextArea,
  TextAreaSkeleton,
  TextInput,
  TextInputSkeleton,
  Toggle,
  ToggleSkeleton,
} from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import { CourseListRoute, isCreateParam, RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import * as yup from 'yup';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateCourseMutation } from 'common/hooks/course/useCreateCourseMutation';
import { useUpdateCourseMutation } from 'common/hooks/course/useUpdateCourseMutation';
import {
  CourseFormModel,
  courseLevels,
  initialCourseFormValues,
  ListItem,
  mapToCourseFormModel,
  mapToCourseRequestDTO,
} from 'common/models/course';
import { useCourseByIdQuery } from 'common/hooks/course/useCourseByIdQuery';
import { Content } from 'common/components/Grid';
import { pipe } from 'fp-ts/function';
import { useAllCategoriesListQuery } from 'common/hooks/category/useAllCategoriesListQuery';
import { useAllSpeakersListQuery } from 'common/hooks/speaker/useAllSpeakersListQuery';
import { Speaker } from 'common/models/speaker';
import { Category, filterSpecialCategories } from 'common/models/category';
import { CourseParametersControl } from 'app/components/CourseParametersControl';
import { ImageControl } from 'app/components/ImageControl';
import { forEachObjIndexed, mergeAll } from 'ramda';
import { useAllSurveysListQuery } from 'common/hooks/survey/useAllSurveysListQuery';
import { SurveyListItem } from 'common/models/survey';
import { purge } from 'lib/purge';
import { isNilEmpty, isNotNilEmpty } from 'lib/isNilEmpty';
import { setYupDefaultMessages } from 'common/services/yup';
import {
  FormAccordion,
  FormAccordionItem,
  FormItem,
} from 'app/components/FormItem';
import { NumberInput } from 'app/components/NumberInput';
import { ChangeEventImaginaryTarget } from 'lib/carbonExtraTypes';
import {
  newKeywordValidationSchema,
  newLearningPointsValidationSchema,
  newTargetGroupsValidationSchema,
  slugValidationSchema,
} from 'common/services/validation';
import { useScrollToError } from 'common/hooks/useScrollToError';
import { fieldsOrder } from 'app/pages/CourseForm/config';
import slug from 'slug';

setYupDefaultMessages();

export interface CourseFormUrlParams {
  courseId: string;
}

export const CourseForm: FC = () => {
  const createMutation = useCreateCourseMutation();
  const params = useParams<CourseFormUrlParams>();
  const updateMutation = useUpdateCourseMutation(params.courseId);

  const [loaded, setLoaded] = useState(
    isNilEmpty(params.courseId) || isCreateParam(params.courseId)
  );

  const formSchema = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const schema: any = {
      certificatePoints: yup.array().min(1).max(5).required(),
      description: yup.string().required(),
      keywords: yup.array().min(1).required(),
      learningPoints: yup.array().min(1).required(),
      mainCategory: yup.string().required(),
      mainLimit: yup.number().min(1).required(),
      recommendationPercent: yup.number().min(1).max(100).integer(),
      slug: slugValidationSchema,
      speakers: yup.array().min(1).required(),
      survey: yup.string().required(),
      targetGroups: yup.array().min(1).required(),
      title: yup.string().max(200).required(),
      level: yup.string().required(),
    };

    if (isCreateParam(params.courseId)) {
      schema.image = yup.mixed().required();
    }

    return yup.object(schema);
  }, [params]);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CourseFormModel>({
    resolver: yupResolver(formSchema),
    shouldFocusError: false,
  });

  const onError = useScrollToError(fieldsOrder);

  const { data: categoriesData, isFetching: isLoadingCategories } =
    useAllCategoriesListQuery();

  const { data: speakersData, isFetching: isLoadingSpeakers } =
    useAllSpeakersListQuery();

  const { data: surveysData, isFetching: isLoadingSurveys } =
    useAllSurveysListQuery();

  const { data: courseData, isFetching: isLoadingCourse } = useCourseByIdQuery(
    params.courseId
  );

  const onSubmit = handleSubmit((values) => {
    pipe(values, mapToCourseRequestDTO, purge, (a) => {
      return isCreateParam(params.courseId)
        ? createMutation.mutate(a)
        : updateMutation.mutate(a);
    });
  }, onError);

  useEffect(() => {
    let formModel = initialCourseFormValues;

    if (courseData) {
      formModel = mergeAll([
        initialCourseFormValues,
        mapToCourseFormModel(courseData),
      ]);
    }

    forEachObjIndexed((value, key) => {
      setValue(key, value);
    }, formModel);

    courseData && setLoaded(true);
  }, [courseData]);

  const additionalCategories = useMemo(
    () => categoriesData && filterSpecialCategories(categoriesData.result),
    [categoriesData]
  );

  const isLoading =
    isLoadingCategories ||
    isLoadingCourse ||
    isLoadingSpeakers ||
    isLoadingSurveys ||
    !loaded;
  const isMutationLoading =
    createMutation.isLoading || updateMutation.isLoading;

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={CourseListRoute}>Lista szkoleń</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        {isCreateParam(params.courseId) && (
          <PageHeading>Nowe szkolenie</PageHeading>
        )}

        {!isCreateParam(params.courseId) && (
          <PageHeading>
            {isLoading ? (
              <SkeletonText heading={true} width="300px" />
            ) : (
              `${courseData?.title}`
            )}
          </PageHeading>
        )}

        <Form onSubmit={onSubmit}>
          <Row>
            <Column lg={8}>
              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <>
                    <Controller
                      control={control}
                      name="title"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        const { dirtyFields } = useFormState({
                          control,
                          name: 'slug',
                        });

                        return (
                          <TextInput
                            id="title"
                            invalid={!!error}
                            invalidText={error?.message}
                            onChange={(event) => {
                              onChange(event);

                              if (!courseData?.slug && !dirtyFields.slug) {
                                setValue('slug', slug(event.target.value), {
                                  shouldValidate: true,
                                });
                              }
                            }}
                            labelText="Tytuł"
                            value={value}
                          />
                        );
                      }}
                    />
                  </>
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <TextInput
                    id="slug"
                    invalid={!!errors.slug}
                    invalidText={errors.slug?.message}
                    labelText="Slug"
                    {...register('slug')}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextAreaSkeleton />
                ) : (
                  <TextArea
                    id="comment"
                    invalid={!!errors.comment}
                    invalidText={errors.comment?.message}
                    labelText="Słowo od prowadzącego"
                    {...register('comment')}
                  />
                )}
              </FormItem>

              <FormAccordion>
                <FormAccordionItem title="Czego się dowiesz" open={true}>
                  <FormItem>
                    {isLoading ? (
                      <TextAreaSkeleton />
                    ) : (
                      <Controller
                        control={control}
                        name="learningPoints"
                        render={({
                          field: { onChange },
                          fieldState: { error },
                        }) => (
                          <CourseParametersControl
                            id="learningPoints"
                            inputValidationSchema={
                              newLearningPointsValidationSchema
                            }
                            invalid={!!error}
                            invalidText={error?.message}
                            onChange={(rows) => {
                              onChange(rows);
                            }}
                            value={courseData?.learningPoints}
                          />
                        )}
                      />
                    )}
                  </FormItem>
                </FormAccordionItem>

                <FormAccordionItem title="Grupy docelowe" open={true}>
                  <FormItem>
                    {isLoading ? (
                      <TextAreaSkeleton />
                    ) : (
                      <Controller
                        control={control}
                        name="targetGroups"
                        render={({
                          field: { onChange },
                          fieldState: { error },
                        }) => (
                          <CourseParametersControl
                            id="targetGroups"
                            inputValidationSchema={
                              newTargetGroupsValidationSchema
                            }
                            invalid={!!error}
                            invalidText={error?.message}
                            onChange={(rows) => {
                              onChange(rows);
                            }}
                            value={courseData?.targetGroups}
                          />
                        )}
                      />
                    )}
                  </FormItem>
                </FormAccordionItem>

                <FormAccordionItem title="Certyfikat - punkty" open={true}>
                  <FormItem>
                    {isLoading ? (
                      <TextAreaSkeleton />
                    ) : (
                      <Controller
                        control={control}
                        name="certificatePoints"
                        render={({
                          field: { onChange },
                          fieldState: { error },
                        }) => (
                          <CourseParametersControl
                            id="certificatePoints"
                            inputValidationSchema={
                              newLearningPointsValidationSchema
                            }
                            invalid={!!error}
                            invalidText={error?.message}
                            onChange={(rows) => {
                              onChange(rows);
                            }}
                            value={courseData?.certificatePoints}
                          />
                        )}
                      />
                    )}
                  </FormItem>
                </FormAccordionItem>

                <FormAccordionItem title="SEO" open={true}>
                  <FormItem>
                    {isLoading ? (
                      <TextAreaSkeleton />
                    ) : (
                      <TextArea
                        id="description"
                        invalid={!!errors.description}
                        invalidText={errors.description?.message}
                        labelText="Opis"
                        {...register('description')}
                      />
                    )}
                  </FormItem>

                  <FormItem>
                    {isLoading ? (
                      <TextAreaSkeleton />
                    ) : (
                      <Controller
                        control={control}
                        name="keywords"
                        render={({
                          field: { onChange },
                          fieldState: { error },
                        }) => (
                          <CourseParametersControl
                            id="keywords"
                            invalid={!!error}
                            invalidText={error?.message}
                            inputValidationSchema={newKeywordValidationSchema}
                            label="Słowa kluczowe"
                            onChange={(rows) => {
                              onChange(rows);
                            }}
                            value={courseData?.keywords}
                          />
                        )}
                      />
                    )}
                  </FormItem>
                </FormAccordionItem>
              </FormAccordion>

              {isMutationLoading ? (
                <Button disabled kind="primary" tabIndex={-1}>
                  <InlineLoading
                    description={
                      isCreateParam(params.courseId)
                        ? 'Dodawanie...'
                        : 'Zapisywanie...'
                    }
                    status="active"
                  />
                </Button>
              ) : (
                <Button kind="primary" tabIndex={0} type="submit">
                  {isCreateParam(params.courseId) ? 'Dodaj' : 'Zapisz'}
                </Button>
              )}
            </Column>

            <Column lg={4}>
              <FormItem>
                {isLoading ? (
                  <ToggleSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="autoAccept"
                    render={({ field: { onChange, value } }) => (
                      <Toggle
                        id="autoAccept"
                        labelA="Ręczny"
                        labelB="Automatyczny"
                        labelText="Tryb zatwierdzania"
                        onToggle={(checked) => onChange(checked)}
                        toggled={value || false}
                      />
                    )}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="mainLimit"
                    render={({
                      field: { name, value, onChange },
                      fieldState: { error },
                    }) => (
                      <NumberInput
                        id={name}
                        name={name}
                        label="Limit osób (lista główna)"
                        onChange={(
                          ev:
                            | React.ChangeEvent<HTMLInputElement>
                            | React.MouseEvent<HTMLButtonElement>
                        ) =>
                          onChange(
                            (ev as ChangeEventImaginaryTarget<HTMLInputElement>)
                              .imaginaryTarget.value
                          )
                        }
                        value={value ?? ''}
                        allowEmpty
                        invalid={!!error}
                        invalidText={error?.message}
                      />
                    )}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="recommendationPercent"
                    render={({
                      field: { name, value, onChange },
                      fieldState: { error },
                    }) => (
                      <NumberInput
                        id={name}
                        name={name}
                        label="Procent użytkowników polecających"
                        onChange={(
                          ev:
                            | React.ChangeEvent<HTMLInputElement>
                            | React.MouseEvent<HTMLButtonElement>
                        ) =>
                          onChange(
                            (ev as ChangeEventImaginaryTarget<HTMLInputElement>)
                              .imaginaryTarget.value
                          )
                        }
                        value={value ?? ''}
                        allowEmpty
                        invalid={!!error}
                        invalidText={error?.message}
                      />
                    )}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="level"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <ComboBox<ListItem>
                        id="level"
                        invalid={!!error}
                        invalidText={error?.message}
                        items={courseLevels}
                        itemToString={(item) => item?.label || ''}
                        onChange={(value) => {
                          onChange(value.selectedItem?.id);
                        }}
                        placeholder=""
                        selectedItem={
                          courseLevels.find((item) => item.id === value) ?? null
                        }
                        titleText="Poziom trudności"
                      />
                    )}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="speakers"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <MultiSelect<Speaker>
                        id="speakers"
                        initialSelectedItems={
                          speakersData?.result.filter((item) =>
                            value?.includes(item.id)
                          ) ?? []
                        }
                        invalid={!!error}
                        invalidText={error?.message}
                        items={speakersData?.result ?? []}
                        itemToString={(item) =>
                          item ? `${item.firstName} ${item.lastName}` : ''
                        }
                        label=""
                        onChange={(a) => {
                          onChange(
                            a.selectedItems
                              .filter(isNotNilEmpty)
                              .map((item) => item.id)
                          );
                        }}
                        titleText="Prelegenci"
                      />
                    )}
                  />
                )}
              </FormItem>

              <input id="surveyId" type="hidden" {...register('surveyId')} />

              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="survey"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <ComboBox<SurveyListItem>
                        id="survey"
                        invalid={!!error}
                        invalidText={error?.message}
                        items={surveysData?.result ?? []}
                        itemToString={(item) =>
                          item ? `${item.title} (${item.courseTitle})` : ''
                        }
                        onChange={(value) => {
                          onChange(value.selectedItem?.id);
                        }}
                        placeholder=""
                        selectedItem={
                          surveysData?.result.find(
                            (item) => item.id === value
                          ) ?? null
                        }
                        titleText="Ankieta"
                      />
                    )}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="mainCategory"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <ComboBox<Category>
                        id="mainCategory"
                        invalid={!!error}
                        invalidText={error?.message}
                        items={categoriesData?.result ?? []}
                        itemToString={(item) => item?.label || ''}
                        onChange={(value) => {
                          onChange(value.selectedItem?.id);
                        }}
                        placeholder=""
                        selectedItem={
                          categoriesData?.result.find(
                            (item) => item.id === value
                          ) ?? null
                        }
                        titleText="Główna kategoria"
                      />
                    )}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="additionalCategories"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <MultiSelect<Category>
                        id="additionalCategories"
                        initialSelectedItems={
                          additionalCategories?.filter((item) =>
                            value?.includes(item.id)
                          ) ?? []
                        }
                        invalid={!!error}
                        invalidText={error?.message}
                        items={additionalCategories ?? []}
                        itemToString={(item) => item?.label || ''}
                        label=""
                        onChange={(a) => {
                          onChange(
                            a.selectedItems
                              .filter(isNotNilEmpty)
                              .map((item) => item.id)
                          );
                        }}
                        titleText="Dodatkowe kategorie"
                      />
                    )}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextAreaSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="image"
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <ImageControl
                        currentImage={courseData?.image.url}
                        id="image"
                        invalid={!!error}
                        invalidText={error?.message}
                        onChange={(file) => {
                          onChange(file);
                        }}
                      />
                    )}
                  />
                )}
              </FormItem>
            </Column>
          </Row>
        </Form>
      </Grid>
    </Content>
  );
};
