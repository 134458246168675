import {
  Button,
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import { Edit20 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { ActionsTableCell } from 'app/components/Table';
import { FC } from 'react';
import styled from 'styled-components';
import { StaticPageItem } from 'modules/static-pages/models/staticPageItem';

const Spacer = styled.span`
  display: inline-block;
  width: 1rem;
`;

export interface StaticPageListRowProps {
  row: DenormalizedRow;
  staticPage: StaticPageItem;
}

export const StaticPageListRow: FC<
  StaticPageListRowProps & Pick<DataTableCustomRenderProps, 'getRowProps'>
> = ({ getRowProps, row, staticPage }) => {
  return (
    <TableRow {...getRowProps({ row })}>
      {row.cells.map((cell) => (
        <TableCell key={cell.id}>{cell.value}</TableCell>
      ))}

      <ActionsTableCell>
        <Button
          as={Link}
          hasIconOnly
          iconDescription="Edytuj"
          kind="ghost"
          renderIcon={Edit20}
          size="small"
          to={staticPage.route}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <Spacer />
      </ActionsTableCell>
    </TableRow>
  );
};
