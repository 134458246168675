import { Content } from 'common/components/Grid';
import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  Grid,
  Row,
  SkeletonText,
} from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import { PartnerListRoute, RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import React, { FC, useMemo } from 'react';
import { useCreatePartnerMutation } from 'common/hooks/partner/useCreatePartnerMutation';
import { useUpdatePartnerMutation } from 'common/hooks/partner/useUpdatePartnerMutation';
import { usePartnerByIdQuery } from 'common/hooks/partner/usePartnerByIdQuery';
import { PartnerForm } from 'app/components/PartnerForm';
import { PartnerUsersListParams } from 'common/models/partner';

export const PartnerFormPage: FC<{ create?: boolean }> = ({ create }) => {
  const { partnerId } = useParams<PartnerUsersListParams>();

  const createMutation = useCreatePartnerMutation();
  const updateMutation = useUpdatePartnerMutation();

  const partnerQuery = usePartnerByIdQuery(
    partnerId,
    !create && partnerId.length > 0
  );

  const initialValue = useMemo(
    () => ({
      name: partnerQuery.data?.partnerName ?? '',
      currentImage: partnerQuery.data?.partnerLogo.url,
    }),
    [partnerQuery.data]
  );

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={PartnerListRoute}>Lista partnerów</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        {create && <PageHeading>Nowy partner</PageHeading>}

        {!create && (
          <PageHeading>
            {partnerQuery.isLoading ? (
              <SkeletonText heading={true} width="300px" />
            ) : (
              `${partnerQuery.data?.partnerName}`
            )}
          </PageHeading>
        )}

        <Row>
          <Column lg={18} xlg={14}>
            {create && (
              <PartnerForm
                onSubmit={(value) =>
                  createMutation.mutate({
                    partner: { partnerName: value.name },
                    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                    image: value.imageFile!,
                  })
                }
                isSubmitting={createMutation.isLoading}
              />
            )}
            {!create && (
              <PartnerForm
                onSubmit={(value) =>
                  updateMutation.mutate({
                    id: partnerId,
                    partner: { partnerName: value.name },
                    image: value.imageFile,
                  })
                }
                initialValue={initialValue}
                isLoading={partnerQuery.isLoading}
                isSubmitting={updateMutation.isLoading}
              />
            )}
          </Column>
        </Row>
      </Grid>
    </Content>
  );
};
