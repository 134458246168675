import {
  Button,
  ComboBox,
  DataTableCustomRenderProps,
  Loading,
  TableBody,
  TableHead,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react';
import {
  Table,
  TableContainer,
  TableContent,
  TableLoader,
} from 'app/components/Table';
import React, { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTableEmptyRow } from 'app/components/DataTableEmptyRow';
import { isEmpty } from 'ramda';
import { DataTableHeader } from 'app/components/DataTableHeader';
import { courseBundleElearningListActions } from 'state/courseBundleContent/elearningList/courseBundleElearningListActions';
import { CourseBundleElearningListColumn } from 'common/models/courseBundleContent';
import { ListItem } from 'common/models/course';
import { ListFilters } from 'app/components/ListFilters';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/Option';
import {
  courseBundleElearningListCategorySelector,
  courseBundleElearningListSortSelector,
} from 'state/courseBundleContent/elearningList/courseBundleElearningListSelectors';
import { Category } from 'common/models/category';
import { CourseBundleElearningListTableRow } from 'app/components/CourseBundleContent/ElearningList/CourseBundleElearningListTableRow';
import { SelectableListPopover } from 'app/components/CourseBundleContent/SelectableListPopover';
import { useParams } from 'react-router-dom';
import { Add20 } from '@carbon/icons-react';
import { useCourseBundleAssignableElearningsQuery } from 'common/hooks/courseBundle/useCourseBundleAssignableElearningsQuery';
import { SelectableElearningsForm } from 'app/components/CourseBundleContent/SelectableListPopover/SelectableElearningsForm';

export interface CourseBundleElearningListTableContentProps {
  categories?: Category[];
  isFetching?: boolean;
}

export const CourseBundleElearningListTableContent: FC<
  CourseBundleElearningListTableContentProps & DataTableCustomRenderProps
> = ({
  categories = [],
  isFetching = false,
  rows,
  headers,
  getBatchActionProps,
  getTableProps,
  getTableContainerProps,
  getToolbarProps,
  getRowProps,
}) => {
  const dispatch = useDispatch();

  const categoryOption = useSelector(courseBundleElearningListCategorySelector);
  const sortData = useSelector(courseBundleElearningListSortSelector);

  const categoryId = useMemo(
    () =>
      pipe(
        categoryOption,
        getOrElse(() => 'undefined')
      ),
    [categoryOption]
  );

  const { courseBundleId } = useParams<{ courseBundleId: string }>();

  const { data: assignableElearningList } =
    useCourseBundleAssignableElearningsQuery(courseBundleId);

  const assignedElearningsIds = useMemo(
    () => rows.map((row) => parseInt(row.id)),
    [rows]
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const initialValues = useMemo(
    () => ({
      items: assignableElearningList
        ? assignableElearningList.map((course) => ({
            elearningId: course.id,
            checked: assignedElearningsIds?.includes(course.id)
              ? true
              : undefined,
          }))
        : [],
    }),
    [assignableElearningList, assignedElearningsIds]
  );

  return (
    <TableContainer
      title="Lista e-learningów w kursie"
      {...getTableContainerProps()}
    >
      <TableToolbar {...getToolbarProps()}>
        <TableToolbarContent>
          <ListFilters>
            <ComboBox<ListItem>
              id="filterCategory"
              initialSelectedItem={categories.find(
                (item) => String(item.id) === categoryId
              )}
              items={categories}
              itemToString={(item) => item?.label || ''}
              light={true}
              onChange={(value) => {
                dispatch(
                  courseBundleElearningListActions.setCategory(
                    value.selectedItem
                  )
                );
              }}
              placeholder="Wybierz kategorię.."
            />
          </ListFilters>

          <Button
            kind="primary"
            renderIcon={Add20}
            size="small"
            tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
            onClick={handleClick}
          >
            Dodaj nowy e-learning
          </Button>
        </TableToolbarContent>
      </TableToolbar>

      <SelectableListPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <SelectableElearningsForm
          courseBundleId={courseBundleId}
          selectableItemList={assignableElearningList}
          initialValues={initialValues}
        />
      </SelectableListPopover>

      <TableContent>
        <Table $loading={isFetching} {...getTableProps()}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <DataTableHeader
                  isSortable
                  isSortHeader={sortData.orderBy === header.key}
                  sortDirection={sortData.direction}
                  onClick={(direction) => {
                    dispatch(
                      courseBundleElearningListActions.setSort({
                        direction,
                        orderBy: header.key as CourseBundleElearningListColumn,
                      })
                    );
                  }}
                  key={header.key}
                >
                  {header.header}
                </DataTableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(rows) &&
              rows.map((row) => (
                <CourseBundleElearningListTableRow
                  getRowProps={getRowProps}
                  key={row.id}
                  row={row}
                />
              ))}

            {isEmpty(rows) && <DataTableEmptyRow colSpan={3} />}
          </TableBody>
        </Table>

        {isFetching && (
          <TableLoader>
            <Loading active={true} withOverlay={false} />
          </TableLoader>
        )}
      </TableContent>
    </TableContainer>
  );
};
