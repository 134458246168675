import {
  Button,
  ComboBox,
  FormLabel,
  TextInput,
  Tile,
} from 'carbon-components-react';
import { Dispatch, memo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { remove } from 'ramda';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { Add20, Draggable20, TrashCan20 } from '@carbon/icons-react';
import { timePickerItems } from 'lib/date';
import { CourseEventAgendaItem } from 'common/models/courseEvent';
import { useImmerReducer } from 'use-immer';
import { FieldError } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

const ButtonContainer = styled.div`
  margin-top: 2px;
  text-align: right;
`;

const DragHandleButton = styled(Button)`
  &:disabled path {
    fill: #c6c6c6 !important;
  }
`;

const EmptyTile = styled(Tile)<{ $invalid: boolean }>`
  ${({ $invalid }) =>
    $invalid &&
    css`
      outline: 2px solid #da1e28;
      outline-offset: -2px;
    `}
`;

const ErrorMessage = styled.div`
  color: #da1e28;
  display: block;
  font-weight: 400;
  max-height: 12.5rem;
  overflow: visible;
`;

const StyledComboBox = styled(ComboBox)`
  width: 140px;
`;

const StyledTile = styled(Tile)`
  align-items: center;
  display: flex;
  margin: 0.125rem 0;
`;

type Action =
  | { type: 'add'; payload: CourseEventAgendaItem }
  | { type: 'delete'; id: string }
  | { type: 'move'; oldId: string; newId: string }
  | { type: 'set'; payload: CourseEventAgendaItem[] }
  | { type: 'setText'; id: string; payload: string }
  | { type: 'setTime'; id: string; payload: string };

interface State {
  details: {
    [id: string]: CourseEventAgendaItem;
  };
  rows: string[];
}

const initialState: State = {
  details: {},
  rows: [],
};

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'add':
      state.details[action.payload.id] = action.payload;
      state.rows.push(action.payload.id);
      break;
    case 'delete':
      delete state.details[action.id];
      state.rows = remove(state.rows.indexOf(action.id), 1, state.rows);
      break;
    case 'move':
      const oldIndex = state.rows.indexOf(action.oldId);
      const newIndex = state.rows.indexOf(action.newId);

      state.rows = arrayMove(state.rows, oldIndex, newIndex);
      break;
    case 'set':
      state.details = {};
      state.rows = [];

      action.payload.forEach((item) => {
        state.details[item.id] = item;
        state.rows.push(item.id);
      });
      break;
    case 'setText':
      state.details[action.id].item = action.payload;
      break;
    case 'setTime':
      state.details[action.id].startTime = action.payload;
      break;
    default:
      throw new Error();
  }
}

export interface SortableAgendaItemProps {
  disabled: boolean;
  dispatch: Dispatch<Action>;
  errors?: FieldErrors;
  item: CourseEventAgendaItem;
}

export const SortableAgendaItem = memo<SortableAgendaItemProps>(
  ({ disabled, dispatch, errors, item }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: item.id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition: transition || '',
    };

    return (
      <div ref={setNodeRef} style={style} {...attributes}>
        <StyledTile>
          <DragHandleButton
            disabled={disabled}
            hasIconOnly
            iconDescription="Przenieś"
            kind="ghost"
            renderIcon={Draggable20}
            size="small"
            tooltipAlignment="center"
            tooltipPosition="top"
            {...(disabled ? {} : listeners)}
          />

          <StyledComboBox
            disabled={disabled}
            id="time"
            invalid={!!errors?.startTime}
            invalidText={!!errors?.startTime?.message}
            items={timePickerItems}
            onChange={(value) => {
              dispatch({
                type: 'setTime',
                id: item.id,
                payload: (value.selectedItem as string | undefined) || '',
              });
            }}
            placeholder="hh:mm"
            selectedItem={
              timePickerItems?.find(
                (pickerItem) => pickerItem === item.startTime
              ) ?? null
            }
            size="sm"
            titleText="Godzina"
          />

          <TextInput
            disabled={disabled}
            id={`row_${item.id}`}
            invalid={!!errors?.item}
            invalidText={!!errors?.item?.message}
            labelText="Tekst"
            onChange={(event) => {
              dispatch({
                type: 'setText',
                id: item.id,
                payload: event.target.value,
              });
            }}
            size="sm"
            value={item.item}
          />

          <Button
            disabled={disabled}
            hasIconOnly
            iconDescription="Usuń"
            kind="danger--ghost"
            onClick={() => {
              dispatch({ type: 'delete', id: item.id });
            }}
            renderIcon={TrashCan20}
            size="small"
            tooltipAlignment="center"
            tooltipPosition="top"
          />
        </StyledTile>
      </div>
    );
  }
);

export interface EventAgendaControlProps {
  disabled?: boolean;
  errors?: FieldError | FieldErrors[];
  id: string;
  invalid: boolean;
  invalidText?: string;
  items?: CourseEventAgendaItem[];
  label?: string;
  onChange?: (rows: CourseEventAgendaItem[]) => void;
  value?: CourseEventAgendaItem[];
}

export const EventAgendaControl = memo<EventAgendaControlProps>(
  ({
    disabled = false,
    errors,
    invalid,
    invalidText,
    label,
    onChange,
    value,
  }) => {
    const [state, dispatch] = useImmerReducer<State>(
      reducer,
      initialState,
      (state) => {
        state.details = {};
        state.rows = [];

        value?.forEach((item) => {
          state.details[item.id] = item;
          state.rows.push(item.id);
        });

        return state;
      }
    );
    const sensors = useSensors(useSensor(PointerSensor));

    const handleDragEnd = (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        dispatch({ type: 'move', oldId: active.id, newId: over.id });
      }
    };

    useEffect(() => {
      onChange && onChange(state.rows.map((itemId) => state.details[itemId]));
    }, [state]);

    return (
      <>
        {label && <FormLabel>{label}</FormLabel>}

        {state.rows.length > 0 ? (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={state.rows}
              strategy={verticalListSortingStrategy}
            >
              {state.rows.map((itemId, index) => (
                <SortableAgendaItem
                  disabled={disabled}
                  dispatch={dispatch}
                  errors={Array.isArray(errors) ? errors[index] : undefined}
                  key={itemId}
                  item={state.details[itemId]}
                />
              ))}
            </SortableContext>
          </DndContext>
        ) : (
          <>
            <EmptyTile $invalid={invalid}>Brak</EmptyTile>
            {invalid && (
              <ErrorMessage className="bx--form-requirement">
                {invalidText}
              </ErrorMessage>
            )}
          </>
        )}

        <ButtonContainer>
          <Button
            disabled={disabled}
            kind="secondary"
            onClick={() => {
              dispatch({
                type: 'add',
                payload: {
                  id: `new_${Date.now()}`,
                  item: '',
                  startTime: '',
                },
              });
            }}
            renderIcon={Add20}
          >
            Dodaj
          </Button>
        </ButtonContainer>
      </>
    );
  }
);
