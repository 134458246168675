import styled, { css } from 'styled-components';
import { FileUploaderDropContainer as BaseFileUploaderDropContainer } from 'carbon-components-react';

export const FileUploaderDropContainer = styled(BaseFileUploaderDropContainer)<{
  $invalid: boolean;
}>`
  ${({ $invalid }) =>
    $invalid &&
    css`
      outline: 2px solid #da1e28;
      outline-offset: -0.125rem;
    `}
`;
