import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';

export const courseBundleElearningListStateSelector = (state: RootState) =>
  state.courseBundleElearningList;

export const courseBundleElearningListIdSelector = createSelector(
  courseBundleElearningListStateSelector,
  (state) => state.courseBundleId
);

export const courseBundleElearningListCategorySelector = createSelector(
  courseBundleElearningListStateSelector,
  (state) => state.category
);

export const courseBundleElearningListPaginationSelector = createSelector(
  courseBundleElearningListStateSelector,
  (state) => state.pagination
);

export const courseBundleElearningListSortSelector = createSelector(
  courseBundleElearningListStateSelector,
  (state) => state.sort
);
