import { FC } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'carbon-components-react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useDebounce } from 'react-use';
import { BaseCourseDTO } from 'common/models/courseBundle';
import { useUpdateCourseBundleElearningListMutation } from 'common/hooks/courseBundle/useUpdateCourseBundleElearningListMutation';

const ListItem = styled.div`
  & .bx--checkbox-wrapper {
    padding: 0.15rem 1rem;
  }
`;

interface AssignableElearning {
  elearningId: number;
  checked?: boolean;
}

interface ElearningListFormModel {
  items: AssignableElearning[];
}

interface SelectableListPopoverFormProps {
  courseBundleId: string;
  selectableItemList?: BaseCourseDTO[];
  initialValues: ElearningListFormModel;
}

export const SelectableElearningsForm: FC<SelectableListPopoverFormProps> = ({
  courseBundleId,
  selectableItemList,
  initialValues,
}) => {
  const methods = useForm<ElearningListFormModel>({
    defaultValues: initialValues,
  });
  const watchAllFields = useWatch({ control: methods.control });

  const { mutate } = useUpdateCourseBundleElearningListMutation(courseBundleId);

  const updateElearningList = (data: ElearningListFormModel) => {
    const elearningsIds = data.items
      .filter((v) => v.checked)
      .map((e) => e.elearningId);

    methods.formState.isDirty && mutate(elearningsIds);
  };

  useDebounce(
    () => updateElearningList(watchAllFields as ElearningListFormModel),
    400,
    [watchAllFields]
  );

  return (
    <FormProvider {...methods}>
      {selectableItemList?.map((course, index) => (
        <ListItem key={course.id}>
          <Controller
            name={`items.${index}.checked`}
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                key={course.id}
                id={`${course.id}`}
                labelText={course.title}
                checked={!!value}
                onChange={onChange}
              />
            )}
          />
        </ListItem>
      ))}
    </FormProvider>
  );
};
