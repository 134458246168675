import {
  Button,
  DataTableCustomRenderProps,
  DenormalizedRow,
  Loading,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react';
import { Add20 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { SpeakerFormCreateRoute } from 'routes';
import {
  Table,
  TableContainer,
  TableContent,
  TableLoader,
} from 'app/components/Table';
import { SpeakerListDataTableRow } from 'app/components/SpeakerList/SpeakerListDataTableRow';
import React, { FC } from 'react';
import { isEmpty } from 'ramda';
import { DataTableEmptyRow } from 'app/components/DataTableEmptyRow';

export interface SpeakerListDataTableContentProps {
  isFetching?: boolean;
  onDeleteSuccess?: (row: DenormalizedRow) => void;
}

export const SpeakerListDataTableContent: FC<
  SpeakerListDataTableContentProps & DataTableCustomRenderProps
> = ({
  isFetching = false,
  onDeleteSuccess,

  rows,
  headers,
  getBatchActionProps,
  getTableProps,
  getTableContainerProps,
  getToolbarProps,
  getHeaderProps,
  getRowProps,
}) => {
  return (
    <TableContainer title="Wykładowcy" {...getTableContainerProps()}>
      <TableToolbar {...getToolbarProps()}>
        <TableToolbarContent>
          <Button
            as={Link}
            kind="primary"
            renderIcon={Add20}
            size="small"
            tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
            to={SpeakerFormCreateRoute}
          >
            Dodaj nowego wykładowcę
          </Button>
        </TableToolbarContent>
      </TableToolbar>

      <TableContent>
        <Table $loading={isFetching} {...getTableProps()}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                // eslint-disable-next-line react/jsx-key
                <TableHeader {...getHeaderProps({ header })}>
                  {header.header}
                </TableHeader>
              ))}

              <TableHeader />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(rows) &&
              rows.map((row) => (
                // eslint-disable-next-line react/jsx-key
                <SpeakerListDataTableRow
                  onDeleteSuccess={onDeleteSuccess}
                  getRowProps={getRowProps}
                  key={row.id}
                  row={row}
                />
              ))}

            {isEmpty(rows) && <DataTableEmptyRow colSpan={2} />}
          </TableBody>
        </Table>

        {isFetching && (
          <TableLoader>
            <Loading active={true} withOverlay={false} />
          </TableLoader>
        )}
      </TableContent>
    </TableContainer>
  );
};
