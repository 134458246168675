import { createReducer } from '@reduxjs/toolkit';
import {
  isSaved,
  mapDTOToMaterial,
  Material,
  MaterialStatus,
  MaterialType,
} from 'common/models/material';
import { mergeAll, without } from 'ramda';
import { materialsActions } from 'state/materials/materialsActions';
import { v4 as uuidv4 } from 'uuid';
import { getExtension } from 'common/services/file';

export interface MaterialsState {
  parentId: string;
  ids: string[];
  map: { [id: string]: Material };
  uploadedMaterialsIds: string[];
  fileFormModal: {
    material: Material | null;
    open: boolean;
  };
  urlFormModal: {
    material: Material | null;
    open: boolean;
  };
}

const initialState: MaterialsState = {
  parentId: '',
  ids: [],
  map: {},
  uploadedMaterialsIds: [],
  fileFormModal: {
    material: null,
    open: false,
  },
  urlFormModal: {
    material: null,
    open: false,
  },
};

export const materialsReducer = createReducer<MaterialsState>(
  initialState,
  (builder) =>
    builder
      .addCase(materialsActions.addFiles, (state, action) => {
        action.payload.forEach((file) => {
          const id = uuidv4();

          state.uploadedMaterialsIds.push(id);
          state.map[id] = {
            file,
            id,
            extension: getExtension(file.name),
            filename: file.name,
            name: file.name,
            size: file.size,
            status: MaterialStatus.New,
            type: MaterialType.Blob,
          };
        });
      })
      .addCase(materialsActions.addUrl, (state, action) => {
        const id = uuidv4();

        state.uploadedMaterialsIds.push(id);
        state.map[id] = {
          id,
          name: action.payload.name,
          status: MaterialStatus.New,
          type: MaterialType.Url,
          url: action.payload.url,
        };
      })
      .addCase(materialsActions.clear, () => initialState)
      .addCase(materialsActions.closeFileFormModal, (state) => {
        state.fileFormModal = {
          material: null,
          open: false,
        };
      })
      .addCase(materialsActions.closeUrlFormModal, (state) => {
        state.urlFormModal = {
          material: null,
          open: false,
        };
      })
      .addCase(materialsActions.delete, (state, action) => {
        delete state.map[action.payload];

        state.ids = without([action.payload], state.ids);
        state.uploadedMaterialsIds = without(
          [action.payload],
          state.uploadedMaterialsIds
        );
      })
      .addCase(materialsActions.setParentId, (state, action) => {
        state.parentId = action.payload || '';
      })
      .addCase(materialsActions.setMaterials, (state, action) => {
        state.ids = action.payload.ids;
        state.map = mergeAll([action.payload.map, state.map]);
      })
      .addCase(materialsActions.setMaterial, (state, action) => {
        state.map[action.payload.id] = mapDTOToMaterial(action.payload);
      })
      .addCase(materialsActions.showFileFormModal, (state, action) => {
        state.fileFormModal = {
          material: action.payload.material,
          open: true,
        };
      })
      .addCase(materialsActions.showUrlFormModal, (state, action) => {
        state.urlFormModal = {
          material: action.payload.material,
          open: true,
        };
      })
      .addCase(materialsActions.updateFile, (state, action) => {
        if (!state.map[action.payload.id]) {
          return;
        }

        state.map[action.payload.id].name = action.payload.values.name;

        if (isSaved(state.map[action.payload.id].status)) {
          state.map[action.payload.id].status = MaterialStatus.Changed;
        }
      })
      .addCase(materialsActions.updateUrl, (state, action) => {
        if (!state.map[action.payload.id]) {
          return;
        }

        state.map[action.payload.id].name = action.payload.values.name;
        state.map[action.payload.id].url = action.payload.values.url;

        if (isSaved(state.map[action.payload.id].status)) {
          state.map[action.payload.id].status = MaterialStatus.Changed;
        }
      })
      .addCase(materialsActions.updateStatus, (state, action) => {
        if (!state.map[action.payload.id]) {
          return;
        }

        state.map[action.payload.id].status = action.payload.status;
      })
);
