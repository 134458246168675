import { createReducer } from '@reduxjs/toolkit';
import { bannerActions } from 'state/banner/bannerActions';
import { PaginationState, initialPagination } from 'common/models/pagination';

export interface BannerState {
  pagination: PaginationState;
}

const initialState: BannerState = {
  pagination: initialPagination,
};

export const bannerReducer = createReducer<BannerState>(
  initialState,
  (builder) =>
    builder
      .addCase(bannerActions.clear, () => initialState)
      .addCase(bannerActions.setPagination, (state, action) => {
        state.pagination.page = action.payload.page;
        state.pagination.pageSize = action.payload.pageSize;
      })
);
