import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createMatchSelector,
  push,
  RouterRootState,
} from 'connected-react-router';
import { stringify } from 'qs';
import { pipe } from 'fp-ts/function';
import {
  filter,
  fromNullable,
  getOrElse,
  isSome,
  Option,
} from 'fp-ts/lib/Option';
import {
  initialPagination,
  PaginationState,
  SortDirection,
} from 'common/models/pagination';
import {
  CourseBundleElearningListRoute,
  courseBundleIdRouteParam,
} from 'routes';
import { mapObjIndexed, mergeLeft } from 'ramda';
import { getQueryParamsMap } from 'lib/getQueryParams';
import { isSomeEnum, isString } from 'lib/typeGuards';
import { defaultSort } from 'state/courseBundleContent/elearningList/courseBundleElearningListReducer';
import {
  CourseBundleElearningListColumn,
  CourseBundleElearningListColumnSettings,
  CourseBundleElearningListSortData,
  CourseBundleContentUrlParams,
} from 'common/models/courseBundleContent';
import {
  courseBundleElearningListCategorySelector,
  courseBundleElearningListIdSelector,
  courseBundleElearningListPaginationSelector,
  courseBundleElearningListSortSelector,
} from 'state/courseBundleContent/elearningList/courseBundleElearningListSelectors';
import { courseBundleElearningListActions } from 'state/courseBundleContent/elearningList/courseBundleElearningListActions';
import { rootStateSelector } from 'state/router/routerSelectors';
import { setParam } from 'common/utils/routing';

function* writeToURLQuery() {
  const courseBundleId: string = yield select(
    courseBundleElearningListIdSelector
  );
  const filterCategoryOption: Option<string> = yield select(
    courseBundleElearningListCategorySelector
  );
  const pagination: Option<PaginationState> = yield select(
    courseBundleElearningListPaginationSelector
  );
  const sortData: CourseBundleElearningListSortData = yield select(
    courseBundleElearningListSortSelector
  );

  const filterCategory = pipe(
    filterCategoryOption,
    getOrElse(() => 'undefined')
  );

  const qs = pipe(
    pagination,
    getOrElse(() => initialPagination),
    (item) =>
      isSome(filterCategoryOption)
        ? {
            ...item,
            category: filterCategory,
          }
        : item,
    mergeLeft(sortData),
    stringify
  );

  yield put(
    push(
      `${setParam(courseBundleIdRouteParam)(CourseBundleElearningListRoute)(
        courseBundleId
      )}?${qs}`,
      {
        replace: true,
      }
    )
  );
}

function* readFromURLQuery() {
  const qp = getQueryParamsMap();
  const routerState: RouterRootState = yield select(rootStateSelector);

  const matchSelector = createMatchSelector<
    RouterRootState,
    CourseBundleContentUrlParams
  >(CourseBundleElearningListRoute);
  const match = matchSelector(routerState);

  const pagination = pipe(
    initialPagination,
    mapObjIndexed((value, key) =>
      pipe(
        fromNullable(qp[key]),
        filter(isString),
        getOrElse(() => value.toString()),
        Number
      )
    )
  );

  const sortData: CourseBundleElearningListSortData = {
    direction: pipe(
      fromNullable(qp['direction']),
      filter(isSomeEnum(SortDirection)),
      getOrElse(() => defaultSort.direction)
    ),
    orderBy: pipe(
      fromNullable(qp['orderBy']),
      filter(isSomeEnum(CourseBundleElearningListColumn)),
      filter(
        (column) => CourseBundleElearningListColumnSettings[column].sortable
      ),
      getOrElse(() => defaultSort.orderBy)
    ),
  };

  yield put(
    courseBundleElearningListActions.setFilters({
      categoryId: qp.category ? String(qp.category) : undefined,
      courseBundleId: match?.params.courseBundleId,
      pagination: pagination,
      sort: sortData,
    })
  );
}

function* readFromURLQueryWatcher() {
  yield takeEvery(
    courseBundleElearningListActions.loadPagination.type,
    readFromURLQuery
  );
}

function* writeToURLQueryWatcher() {
  yield takeLatest(
    courseBundleElearningListActions.setCategory.type,
    writeToURLQuery
  );
  yield takeLatest(
    courseBundleElearningListActions.setCategoryId.type,
    writeToURLQuery
  );
  yield takeLatest(
    courseBundleElearningListActions.setFilters.type,
    writeToURLQuery
  );
  yield takeLatest(
    courseBundleElearningListActions.setPagination.type,
    writeToURLQuery
  );
  yield takeLatest(
    courseBundleElearningListActions.setSort.type,
    writeToURLQuery
  );
}

export function* courseBundleElearningListSaga() {
  yield all([writeToURLQueryWatcher(), readFromURLQueryWatcher()]);
}
