export enum LargeBlobStatus {
  Finished = 'FINISHED',
  Uploading = 'UPLOADING',
}

export enum LargeBlobType {
  ElearningVideo = 'ELEARNING_VIDEO',
  ElearningLessonVideo = 'ELEARNING_LESSON_VIDEO',
}

export interface LargeBlobDTO {
  createdAt: string;
  id: number;
  status: LargeBlobStatus;
  type: LargeBlobType;
  updateUrl: string;
}
