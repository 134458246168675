import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { snackbarActions } from 'state/notification/notificationActions';
import { push } from 'connected-react-router';
import { SpeakerListRoute } from 'routes';
import { createSpeaker } from 'common/services/speaker';

export const useCreateSpeakerMutation = () => {
  const dispatch = useDispatch();

  return useMutation(createSpeaker, {
    onSuccess: () => {
      dispatch(push(SpeakerListRoute));
      dispatch(
        snackbarActions.enqueueSnackbar({
          title: 'Wykładowca został dodany pomyślnie',
          options: { variant: 'success' },
        })
      );
    },
  });
};
