import React, { FC } from 'react';
import { Breadcrumb, BreadcrumbItem, Grid } from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import {
  ElearningFormRoute,
  elearningIdRouteParam,
  ElearningLessonFormRoute,
  elearningLessonIdRouteParam,
  ElearningLessonListRoute,
  ElearningListRoute,
  RootRoute,
} from 'routes';
import { Content } from 'common/components/Grid';
import { MaterialResource } from 'common/models/material';
import { Materials } from 'app/components/Materials';
import { useElearningByIdQuery } from 'common/hooks/elearning/useElearningByIdQuery';
import { useElearningLessonByIdQuery } from 'common/hooks/elearningLesson/useElearningLessonByIdQuery';
import { setParam, setParams } from 'common/utils/routing';

export interface ElearningLessonMaterialsUrlParams {
  elearningId: string;
  elearningLessonId: string;
}

export const ElearningLessonMaterials: FC = () => {
  const { elearningId, elearningLessonId } =
    useParams<ElearningLessonMaterialsUrlParams>();

  const { data: elearningData } = useElearningByIdQuery(elearningId);

  const { data: elearningLessonData } = useElearningLessonByIdQuery(
    elearningId,
    elearningLessonId
  );

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={ElearningListRoute}>Lista e-learningów</Link>
          </BreadcrumbItem>

          {elearningData && (
            <BreadcrumbItem>
              <Link
                to={setParam(elearningIdRouteParam)(ElearningFormRoute)(
                  elearningId
                )}
              >
                {elearningData.title}
              </Link>
            </BreadcrumbItem>
          )}

          <BreadcrumbItem>
            <Link
              to={setParam(elearningIdRouteParam)(ElearningLessonListRoute)(
                elearningId
              )}
            >
              Lista lekcji
            </Link>
          </BreadcrumbItem>

          {elearningLessonData && (
            <BreadcrumbItem>
              <Link
                to={setParams(
                  elearningIdRouteParam,
                  elearningLessonIdRouteParam
                )(ElearningLessonFormRoute)(elearningId, elearningLessonId)}
              >
                {elearningLessonData.title}
              </Link>
            </BreadcrumbItem>
          )}
        </Breadcrumb>

        <Materials
          parentId={elearningLessonId}
          resource={MaterialResource.ElearningLesson}
        />
      </Grid>
    </Content>
  );
};
