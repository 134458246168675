import { parse, parseISO } from 'date-fns';
import { omit, startsWith } from 'ramda';
import { coerceUTCDateString } from 'lib/coerceUTCDateString';
import { format } from 'lib/date';
import { isNilEmpty } from 'lib/isNilEmpty';
import { SortDirection } from 'common/models/pagination';
import { TableColumnSettings } from 'common/models/tableSettings';

export enum CourseEventListColumn {
  ParticipantsAccepted = 'PARTICIPANTS_ACCEPTED',
  ParticipantsPresent = 'PARTICIPANTS_PRESENT',
  StartDateTime = 'START_DATE_TIME',
  Status = 'STATUS',
}

export interface CourseEventSortData {
  direction: SortDirection;
  orderBy: CourseEventListColumn;
}

export const courseEventListColumnSettings: Record<
  CourseEventListColumn,
  TableColumnSettings
> = {
  [CourseEventListColumn.ParticipantsAccepted]: { sortable: true },
  [CourseEventListColumn.ParticipantsPresent]: { sortable: true },
  [CourseEventListColumn.StartDateTime]: { sortable: true },
  [CourseEventListColumn.Status]: { sortable: true },
};

export interface CourseEventListFilters {
  courseId?: string;
  status?: CourseEventStatus;
}

export interface CourseEventListParams {
  courseId: string;
}

export enum CourseEventStatus {
  Open = 'OPEN',
  Ongoing = 'ONGOING',
  Realized = 'REALIZED',
  Removed = 'REMOVED',
}

export interface CourseEventStatusListItem {
  id: CourseEventStatus;
  label: string;
}

export const courseEventStatusLabelMap: Record<CourseEventStatus, string> = {
  [CourseEventStatus.Open]: 'Otwarte',
  [CourseEventStatus.Ongoing]: 'Trwające',
  [CourseEventStatus.Realized]: 'Zakończone',
  [CourseEventStatus.Removed]: 'Usunięte',
};

export const courseEventStatusList: CourseEventStatusListItem[] = [
  {
    id: CourseEventStatus.Open,
    label: courseEventStatusLabelMap[CourseEventStatus.Open],
  },
  {
    id: CourseEventStatus.Ongoing,
    label: courseEventStatusLabelMap[CourseEventStatus.Ongoing],
  },
  {
    id: CourseEventStatus.Realized,
    label: courseEventStatusLabelMap[CourseEventStatus.Realized],
  },
  {
    id: CourseEventStatus.Removed,
    label: courseEventStatusLabelMap[CourseEventStatus.Removed],
  },
];

export interface CourseEventListItem {
  courseId: string;
  courseTitle: string;
  id: string;
  participantsAccepted: number;
  participantsPresent: number;
  startDateTime: string;
  status: CourseEventStatus;
}

// for CourseBundle's event list
export interface EventStatusListItem {
  courseId: string;
  courseSlug: string;
  courseTitle: string;
  id: number;
  participantsAccepted: number;
  participantsPresent: number;
  startDateTime: string;
  status: CourseEventStatus;
}

export interface CourseEventAgendaItem {
  id: string;
  item: string;
  startTime: string;
}

export interface CourseEventAgendaItemDTO {
  id?: string;
  item: string;
  startTime: string;
}

export interface CourseEvent {
  agendaList: CourseEventAgendaItem[];
  courseId: string;
  remarks?: string;
  duration: number;
  id: string;
  location: string;
  startDateTime: string;
  status: CourseEventStatus;
  trainerId: number;
  webinarCreated: boolean;
}

export interface CourseEventFormModel {
  agenda: CourseEventAgendaItem[];
  remarks: string;
  duration: number;
  location: string;
  startDate: string;
  startTime: string;
  status: CourseEventStatus;
  trainer: number;
}

export interface CourseEventDTO {
  agendaList: CourseEventAgendaItemDTO[];
  remarks: string;
  duration: number;
  location: string;
  startDateTime: string;
  status: CourseEventStatus;
  trainerId: number;
}

export interface CourseEventRemarksDTO {
  remarks: string;
}

export const mapAgendaToDTO = (item: CourseEventAgendaItem) =>
  startsWith('new', item.id) ? omit(['id'], item) : item;

export const mapAgendaToFormData = (
  item: CourseEventAgendaItem,
  startDateTime: Date
) => {
  const startTime = parse(`${item.startTime}`, 'HH:mm:ss', startDateTime);

  return {
    ...item,
    startTime: format(startTime, 'HH:mm'),
  };
};

export const mapToCourseEventDTO = ({
  agenda,
  remarks,
  duration,
  location,
  startDate,
  startTime,
  status,
  trainer,
}: CourseEventFormModel): CourseEventDTO => {
  const parsedStartDate = parse(startDate, 'dd.LL.uuuu', new Date());
  const startDateTime = parse(`${startTime}:00`, 'HH:mm:ss', parsedStartDate);

  return {
    agendaList: agenda.map(mapAgendaToDTO),
    startDateTime: startDateTime.toISOString(),
    status: isNilEmpty(status) ? CourseEventStatus.Open : status,
    trainerId: Number(trainer),
    duration,
    remarks,
    location,
  };
};

export const mapToCourseEventRemarksDTO = ({
  remarks,
}: CourseEventFormModel): CourseEventRemarksDTO => ({
  remarks,
});

export const mapToCourseEventFormModel = ({
  agendaList,
  remarks,
  duration,
  location,
  startDateTime,
  status,
  trainerId,
}: CourseEvent): CourseEventFormModel => {
  const parsedStartDateTime = parseISO(coerceUTCDateString(startDateTime));

  return {
    agenda: agendaList.map((item) =>
      mapAgendaToFormData(item, parsedStartDateTime)
    ),
    startDate: format(parsedStartDateTime, 'dd.LL.uuuu'),
    startTime: format(parsedStartDateTime, 'HH:mm'),
    trainer: trainerId,
    remarks: remarks ?? '',
    duration,
    location,
    status,
  };
};
