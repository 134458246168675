import React, { FC, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FormLabel } from 'carbon-components-react';
import { white } from '@carbon/colors';
import { CounterButton } from 'app/components/CourseBundleForm/NestedMultiSelect/CounterButton';
import {
  NestedMultiSelectItem,
  NestedMultiSelectItemRef,
} from 'app/components/CourseBundleForm/NestedMultiSelect/NestedMultiSelectItem';
import { BaseCourseWithEventsDTO } from 'common/models/courseBundle';
import {
  closeOnBackdropClick,
  closeOnEscapeKeyDown,
} from 'app/components/CourseBundleForm/NestedMultiSelect/dropdownEventHandling';
import { DropdownComponentProps } from 'app/components/CourseBundleForm/NestedMultiSelect/DropdownComponentProps';
import { ErrorMessage } from 'app/components/ErrorMessage';

const NestedMultiSelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownMenu = styled.div<DropdownComponentProps>`
  position: absolute;
  z-index: 1000;
  width: 0;
  height: 0;
  max-height: 0;
  box-shadow: 0 2px 6px rgb(0 0 0 / 30%);

  visibility: ${(props) => (props.$dropdownHidden ? 'hidden' : 'visible')};

  ${(props) =>
    !props.$dropdownHidden &&
    css`
      cursor: pointer;
      overflow-y: auto;
      width: 100%;
      height: auto;
      max-height: 15.5rem;
      background-color: ${white};
    `}

  transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
`;

const StyledErrorMessage = styled(ErrorMessage)`
  position: absolute;
`;

interface NestedMultiSelectProps {
  id: string;
  title: string;
  invalid: boolean;
  invalidText?: string;
  items: BaseCourseWithEventsDTO[];
  initialSelectedItems: number[];
  onChange: (...e: unknown[]) => void;
}

const handleDropdownMenuClose = (
  onClose: () => void,
  backdropRef: React.RefObject<Element>
) => {
  closeOnEscapeKeyDown(onClose);
  closeOnBackdropClick(backdropRef, onClose);
};

export const NestedMultiSelect: FC<NestedMultiSelectProps> = ({
  title,
  invalid,
  invalidText,
  items,
  initialSelectedItems,
  onChange,
}) => {
  const backdropRef = useRef(null);
  const [dropdownHidden, setDropdownHidden] = useState(true);
  const childRef = useRef<NestedMultiSelectItemRef | null>(null);

  const handleClearAll = () => {
    childRef?.current?.clearAll();
  };

  handleDropdownMenuClose(() => setDropdownHidden(true), backdropRef);

  return (
    <NestedMultiSelectContainer ref={backdropRef}>
      <FormLabel>{title}</FormLabel>
      <CounterButton
        selectedItemsCounter={initialSelectedItems.length}
        onClick={() => setDropdownHidden(!dropdownHidden)}
        isDropdownHidden={dropdownHidden}
        invalid={invalid}
        handleClearAll={handleClearAll}
      />
      <StyledErrorMessage className="bx--form-requirement">
        {invalidText}
      </StyledErrorMessage>
      <DropdownMenu $dropdownHidden={dropdownHidden}>
        {items?.map((item) => (
          <NestedMultiSelectItem
            key={item.id}
            checkboxId={item.id.toString()}
            title={item.title}
            items={item.events}
            initialSelectedItems={initialSelectedItems}
            onChange={onChange}
            ref={childRef}
          />
        ))}
      </DropdownMenu>
    </NestedMultiSelectContainer>
  );
};
