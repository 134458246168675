import { useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { getElearningPreview } from 'common/services/elearning';
import { ID } from 'lib/id';

export function useElearningPreviewQuery(id: ID) {
  return useQuery([QueryKey.ElearningPreview, id], () =>
    getElearningPreview(id)
  );
}
