import { FC, useEffect, useMemo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  Grid,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import {
  CourseListRoute,
  getCourseEventFormRoute,
  getCourseEventListRoute,
  getCourseFormRoute,
  RootRoute,
} from 'routes';
import { PageHeading } from 'common/components/Heading';
import { useDispatch, useSelector } from 'react-redux';
import { Content } from 'common/components/Grid';
import { formatDateOnLists } from 'lib/date';
import { useCourseByIdQuery } from 'common/hooks/course/useCourseByIdQuery';
import {
  courseEventParticipantCourseIdSelector,
  courseEventParticipantEventIdSelector,
} from 'state/courseEventParticipant/courseEventParticipantSelectors';
import { useCourseEventByIdQuery } from 'common/hooks/courseEvent/useCourseEventByIdQuery';
import { useCourseEventParticipantListQuery } from 'common/hooks/courseEventParticipant/useCourseEventParticipantListQuery';
import { courseEventParticipantActions } from 'state/courseEventParticipant/courseEventParticipantActions';
import { CourseEventParticipantListDataTableContent } from 'app/components/CourseEventParticipantList/CourseEventParticipantListDataTableContent';
import { CourseEventParticipantListColumn } from 'common/models/courseEventParticipant';

const headers = [
  {
    key: CourseEventParticipantListColumn.FirstName,
    header: 'Imię',
  },
  {
    key: CourseEventParticipantListColumn.LastName,
    header: 'Nazwisko',
  },
  {
    key: CourseEventParticipantListColumn.EnrollDateTime,
    header: 'Data i godzina zgłoszenia',
  },
  {
    key: CourseEventParticipantListColumn.HasDownloadedCertificate,
    header: 'Pobrał certyfikat?',
  },
  {
    key: CourseEventParticipantListColumn.Status,
    header: 'Status',
  },
];

export const CourseEventParticipantsList: FC = () => {
  const dispatch = useDispatch();

  const courseId = useSelector(courseEventParticipantCourseIdSelector);
  const eventId = useSelector(courseEventParticipantEventIdSelector);

  const { data: courseData, isFetching: isLoadingCourse } =
    useCourseByIdQuery(courseId);

  const { data: eventData, isFetching: isLoadingEvent } =
    useCourseEventByIdQuery(courseId, eventId);

  const {
    data,
    isFetching,
    isLoading: isLoadingParticipant,
  } = useCourseEventParticipantListQuery(eventId);

  const rows = useMemo(
    () =>
      data?.map((item) => ({
        [CourseEventParticipantListColumn.EnrollDateTime]: formatDateOnLists(
          item.enrollDateTime
        ),
        [CourseEventParticipantListColumn.FirstName]: item.user.firstName,
        [CourseEventParticipantListColumn.HasDownloadedCertificate]:
          item.hasDownloadedCertificate ? 'Tak' : 'Nie',
        [CourseEventParticipantListColumn.LastName]: item.user.lastName,
        [CourseEventParticipantListColumn.Status]: item.participantEventStatus,
        id: item.user.id.toString(),
      })),
    [data]
  );

  useEffect(() => {
    dispatch(courseEventParticipantActions.loadPagination());
  }, [dispatch]);

  const isLoading = isLoadingCourse || isLoadingEvent || isLoadingParticipant;

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={CourseListRoute}>Lista szkoleń</Link>
          </BreadcrumbItem>

          {courseData && (
            <BreadcrumbItem>
              <Link to={getCourseFormRoute(courseData.id)}>
                {courseData.title}
              </Link>
            </BreadcrumbItem>
          )}

          {eventData && (
            <>
              <BreadcrumbItem>
                <Link to={getCourseEventListRoute(courseId)}>
                  Lista wydarzeń
                </Link>
              </BreadcrumbItem>

              {eventData && (
                <BreadcrumbItem>
                  <Link to={getCourseEventFormRoute(courseId, eventId)}>
                    {formatDateOnLists(eventData.startDateTime)}
                  </Link>
                </BreadcrumbItem>
              )}
            </>
          )}
        </Breadcrumb>

        <PageHeading>Lista uczestników</PageHeading>

        {!rows && isLoading && (
          <DataTableSkeleton headers={headers} rowCount={10}>
            {(props: DataTableCustomRenderProps) => (
              <CourseEventParticipantListDataTableContent {...props} />
            )}
          </DataTableSkeleton>
        )}

        {rows && !isLoading && (
          <DataTable headers={headers} overflowMenuOnHover={false} rows={rows}>
            {(props: DataTableCustomRenderProps) => (
              <CourseEventParticipantListDataTableContent
                {...props}
                event={eventData}
                isFetching={isFetching}
              />
            )}
          </DataTable>
        )}
      </Grid>
    </Content>
  );
};
