import React, { FC, useEffect, useMemo } from 'react';
import { MultiSelect } from 'carbon-components-react';
import { useQueryClient } from 'react-query';
import { isNotNilEmpty } from 'lib/isNilEmpty';
import { SpeakerNameInfo } from 'common/models/speaker';
import { Control, Controller, useFormContext, useWatch } from 'react-hook-form';
import { QueryKey } from 'config/queryKey';
import { useCourseBundleAssignableSpeakersQuery } from 'common/hooks/courseBundle/useCourseBundleAssignableSpeakersQuery';
import { CourseBundleFormModel } from 'common/models/courseBundle';
import { arraysEqual } from 'lib/arrayEquals';

interface SpeakersMultiSelectProps {
  control: Control<CourseBundleFormModel, unknown>;
}

export const SpeakersMultiSelect: FC<SpeakersMultiSelectProps> = ({
  control,
}) => {
  const queryClient = useQueryClient();

  const { setValue } = useFormContext();

  const watchElearningList = useWatch({ control, name: 'elearningIds' });
  const watchEventList = useWatch({ control, name: 'eventIds' });
  const watchSpeakers = useWatch({ control, name: 'speakerIds' });

  const { data: speakersData } = useCourseBundleAssignableSpeakersQuery(
    watchElearningList,
    watchEventList
  );

  useEffect(() => {
    queryClient.invalidateQueries([
      QueryKey.CourseBundleAssignableSpeakers,
      watchElearningList,
      watchEventList,
    ]);
  }, [watchElearningList, watchEventList]);

  const selectedSpeakersIds = useMemo(() => {
    return watchSpeakers.filter((id) =>
      speakersData?.map((speaker) => speaker.id)?.includes(id)
    );
  }, [watchSpeakers, speakersData]);

  const selectedSpeakers = useMemo(
    () => speakersData?.filter((s) => selectedSpeakersIds?.includes(s.id)),
    [watchSpeakers, speakersData, selectedSpeakersIds]
  );

  useEffect(() => {
    if (speakersData && !arraysEqual(watchSpeakers, selectedSpeakersIds)) {
      setValue('speakerIds', selectedSpeakersIds);
    }
  }, [watchSpeakers, speakersData, selectedSpeakersIds]);

  return (
    <Controller
      control={control}
      name="speakerIds"
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <MultiSelect<SpeakerNameInfo>
          id="speakerIds"
          initialSelectedItems={
            speakersData?.filter((item) => value?.includes(item.id)) ?? []
          }
          invalid={!!error}
          invalidText={error?.message}
          items={speakersData ?? []}
          itemToString={(item) =>
            item ? `${item.firstName} ${item.lastName}` : ''
          }
          label=""
          onChange={(a) => {
            onChange(
              a.selectedItems.filter(isNotNilEmpty).map((item) => item.id)
            );
          }}
          disabled={!Array.isArray(speakersData) || !speakersData.length}
          titleText="Wykładowcy"
          selectedItems={selectedSpeakers ?? []}
        />
      )}
    />
  );
};
