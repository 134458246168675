import React, { FC } from 'react';
import { Popover } from '@material-ui/core';
import styled from 'styled-components';

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    border-radius: 0;
  }
`;

const PopoverContents = styled.div`
  max-height: 20rem;
`;

interface SelectableListPopoverProps {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: (a: HTMLButtonElement | null) => void;
}

// update to the carbon-components v11 and change to the Toggletip sometime later
export const SelectableListPopover: FC<SelectableListPopoverProps> = ({
  anchorEl,
  setAnchorEl,
  children,
}) => (
  <StyledPopover
    id="selectable-list-popover"
    open={Boolean(anchorEl)}
    onClose={() => setAnchorEl(null)}
    anchorEl={anchorEl}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    elevation={6}
  >
    <PopoverContents>{children}</PopoverContents>
  </StyledPopover>
);
