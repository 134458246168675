import React, { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormItem, FormLabel } from 'carbon-components-react';
import {
  MultiOptionSurveyQuestionControlModel,
  OptionSurveyQuestionControlModel,
  QuestionControlType,
  ScaleSurveyQuestionControlModel,
  SurveyQuestionControlModel,
} from 'app/components/SurveyFormPage/surveyQuestionModel';
import { QuestionType } from 'common/models/survey';
import { SortableItem } from 'app/components/SurveyFormPage/SortableItem';
import {
  ScaleSurveyQuestionControl,
  scaleSurveyQuestionDefaultValues,
} from 'app/components/SurveyFormPage/SurveyQuestionControl/ScaleSurveyQuestionControl';
import { QuestionTypeSelectionControl } from 'app/components/SurveyFormPage/SurveyQuestionControl/QuestionTypeSelectionControl';
import {
  OptionSurveyQuestionControl,
  optionSurveyQuestionDefaultValue,
} from 'app/components/SurveyFormPage/SurveyQuestionControl/OptionSurveyQuestionControl';
import {
  MultiOptionSurveyQuestionControl,
  multiOptionSurveyQuestionDefaultValue,
} from 'app/components/SurveyFormPage/SurveyQuestionControl/MultiOptionSurveyQuestionControl';
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import styled from 'styled-components';
import { ErrorBlock } from 'app/components/ErrorBlock';
import { useControlErrors } from 'common/hooks/useControlErrors';

const questionTypeDefaultValueMap: Record<string, SurveyQuestionControlModel> =
  {
    [QuestionType.Scale]: scaleSurveyQuestionDefaultValues,
    [QuestionType.Option]: optionSurveyQuestionDefaultValue,
    [QuestionType.MultiOption]: multiOptionSurveyQuestionDefaultValue,
  };

const QuestionTypeSelectionControlContainer = styled(FormItem)`
  align-items: flex-end;
`;

const StyledQuestionTypeSelectionControl = styled(QuestionTypeSelectionControl)`
  max-width: 23rem;
  flex-grow: 1;
`;

export interface SurveyQuestionControlProps {
  label: string;
  name: string;
}

export const SurveyQuestionControl: FC<SurveyQuestionControlProps> = ({
  label,
  name,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove, move, swap } = useFieldArray({
    control,
    name,
  });
  const controlErrors = useControlErrors(errors, name);
  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = fields.findIndex((field) => field.id === active.id);
      const newIndex = fields.findIndex((field) => field.id === over.id);

      move(newIndex, oldIndex);
    }
  };

  return (
    <div>
      <FormLabel>{label}</FormLabel>
      {controlErrors?.message && (
        <ErrorBlock>{controlErrors?.message}</ErrorBlock>
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={fields} strategy={verticalListSortingStrategy}>
          {fields.map((field, index) => (
            <SortableItem
              id={field.id}
              key={`${name}.${field.id}`}
              draggable={false}
              onMoveUp={index > 0 ? () => swap(index, index - 1) : undefined}
              onMoveDown={
                index < fields.length - 1
                  ? () => swap(index, index + 1)
                  : undefined
              }
              onDelete={() => remove(index)}
            >
              {(field as SurveyQuestionControlModel).type ===
                QuestionControlType.Scale && (
                <ScaleSurveyQuestionControl
                  name={`${name}.${index}`}
                  label={`Pytanie nr ${index + 1} (Ocena)`}
                  defaultValue={field as ScaleSurveyQuestionControlModel}
                />
              )}

              {(field as SurveyQuestionControlModel).type ===
                QuestionControlType.Option && (
                <OptionSurveyQuestionControl
                  name={`${name}.${index}`}
                  label={`Pytanie nr ${
                    index + 1
                  } (Zamknięte jednokrotnego wyboru)`}
                  defaultValue={field as OptionSurveyQuestionControlModel}
                />
              )}

              {(field as SurveyQuestionControlModel).type ===
                QuestionControlType.MultiOption && (
                <MultiOptionSurveyQuestionControl
                  name={`${name}.${index}`}
                  label={`Pytanie nr ${
                    index + 1
                  } (Zamknięte wielokrotnego wyboru)`}
                  defaultValue={field as MultiOptionSurveyQuestionControlModel}
                />
              )}
            </SortableItem>
          ))}
        </SortableContext>
      </DndContext>

      <QuestionTypeSelectionControlContainer>
        <StyledQuestionTypeSelectionControl
          onAdd={(questionType) =>
            questionTypeDefaultValueMap[questionType] &&
            append(questionTypeDefaultValueMap[questionType])
          }
        />
      </QuestionTypeSelectionControlContainer>
    </div>
  );
};
