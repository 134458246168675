import {
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableCell,
  TableExpandedRow,
  TableExpandRow,
} from 'carbon-components-react';
import { FC } from 'react';
import { MaterialsStatusIndicator } from 'app/components/Materials/MaterialsStatusIndicator';
import {
  MaterialsActionsTableCell,
  MaterialsStatusTableCell,
  MaterialsTypeTableCell,
} from 'app/components/Materials/MaterialsTable';
import {
  Material,
  materialTypeLabelMapper,
  isUploading,
} from 'common/models/material';
import { MaterialsActions } from 'app/components/Materials/MaterialsActions';
import { MaterialsTableRowDetails } from 'app/components/Materials/MaterialsTableRowDetails';

export interface MaterialsTableRowProps {
  material: Material;
  row: DenormalizedRow;
}

export const MaterialsTableRow: FC<
  MaterialsTableRowProps & Pick<DataTableCustomRenderProps, 'getRowProps'>
> = ({ material, getRowProps, row }) => {
  return (
    <>
      <TableExpandRow {...getRowProps({ row })}>
        {row.cells.map((cell) => {
          switch (cell.info.header) {
            case 'type':
              return (
                <MaterialsTypeTableCell key={cell.id}>
                  {materialTypeLabelMapper(cell.value)}
                </MaterialsTypeTableCell>
              );
            case 'status':
              return (
                !isUploading(material.status) && (
                  <MaterialsStatusTableCell key={cell.id}>
                    <MaterialsStatusIndicator status={cell.value} />
                  </MaterialsStatusTableCell>
                )
              );
            default:
              return <TableCell key={cell.id}>{cell.value}</TableCell>;
          }
        })}

        <MaterialsActionsTableCell
          colSpan={isUploading(material.status) ? 2 : 1}
        >
          <MaterialsActions material={material} />
        </MaterialsActionsTableCell>
      </TableExpandRow>

      <TableExpandedRow colSpan={5}>
        <MaterialsTableRowDetails material={material} />
      </TableExpandedRow>
    </>
  );
};
