import { createAction } from 'lib/createAction';
import { PaginationState } from 'common/models/pagination';
import { CourseBundleSortData } from 'common/models/courseBundle';

export interface CourseBundleListFilters {
  categoryId?: string;
  pagination: PaginationState;
  sort: CourseBundleSortData;
}

export const courseBundleActions = {
  clear: createAction('courseBundle/clear')(),
  loadPagination: createAction('courseBundle/loadPagination')(),
  setFilters: createAction(
    'courseBundle/setFilters'
  )<CourseBundleListFilters>(),
  setPagination: createAction('courseBundle/setPagination')<PaginationState>(),
  setSort: createAction('courseBundle/setSort')<CourseBundleSortData>(),
} as const;
