export const locale = 'pl';
export const reactDraftEditorLabels = {
  // Generic
  'generic.add': 'Dodaj',
  'generic.cancel': 'Anuluj',

  // Link
  'components.controls.link.linkTitle': 'Wyświetlany tekst',
  'components.controls.link.linkTarget': 'Źródło',
  'components.controls.link.linkTargetOption': 'Otwórz w nowym oknie',
  'components.controls.link.link': 'Dodaj link',
  'components.controls.link.unlink': 'Usuń link',

  // List
  'components.controls.list.list': 'Lista',
  'components.controls.list.unordered': 'Lista punktowana',
  'components.controls.list.ordered': 'Lista numerowana',
  'components.controls.list.indent': 'Dodaj wcięcie',
  'components.controls.list.outdent': 'Usuń wcięcie',
};
