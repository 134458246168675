import { Content } from 'common/components/Grid';
import {
  Breadcrumb,
  BreadcrumbItem,
  Grid,
  Loading,
  SkeletonText,
} from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import { BannerListRoute, RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { BannerForm } from 'modules/banner/components/BannerForm';
import { useQuery } from 'react-query';
import {
  AuthorizedAxios as API,
  throwErrorResponse,
} from 'common/services/axios';
import { flatten } from 'lib/axios';
import {
  BannerDTO,
  BannerFields,
  BannerSubpage,
} from 'modules/banner/types.banner';
import { useAllCategoriesListQuery } from 'common/hooks/category/useAllCategoriesListQuery';
import { useMutationBannerCreate } from 'modules/banner/hooks/useMutationBannerCreate';
import { useMutationBannerUpdate } from 'modules/banner/hooks/useMutationBannerUpdate';
import { useQueryBanners } from 'modules/banner/hooks/useQueryBanners';
import { useMemo } from 'react';

export function BannerPageForm({ create = false }: { create?: boolean }) {
  const { bannerId } = useParams<{ bannerId: string }>();

  const bannersQuery = useQueryBanners({ page: 1, pageSize: 1000 });
  const categoriesQuery = useAllCategoriesListQuery();
  const bannerQuery = useQuery(
    ['banner', bannerId],
    () => {
      const result = API.get<BannerDTO>('/api/banners/' + bannerId)
        .then(flatten)
        .catch(throwErrorResponse);

      return result;
    },
    {
      enabled: !create,
      cacheTime: 0,
    }
  );

  const isLoading =
    bannerQuery.isLoading ||
    categoriesQuery.isLoading ||
    bannersQuery.isLoading;

  const category = categoriesQuery.data?.result.find(
    (i) => i.id === bannerQuery.data?.categoryId
  );

  const options = useMemo(() => {
    const occupied = {
      subpages: bannersQuery.data?.result.map((i) => i.subpage),
      categories: bannersQuery.data?.result.map((i) => i.categoryId),
    };

    return {
      subpages:
        Object.values(BannerSubpage).filter(
          (i) =>
            !occupied.subpages?.includes(i) ||
            i === BannerSubpage.CATEGORY ||
            i === bannerQuery.data?.subpage
        ) ?? [],
      categories:
        categoriesQuery.data?.result.filter(
          (i) =>
            !occupied.categories?.includes(i.id) ||
            bannerQuery.data?.categoryId === i.id
        ) ?? [],
    };
  }, [bannersQuery.data, categoriesQuery.data, bannerQuery.data]);

  const createMutation = useMutationBannerCreate();
  const updateMutation = useMutationBannerUpdate(bannerId);
  async function onValidSubmit(values: BannerFields) {
    create ? createMutation.mutate(values) : updateMutation.mutate(values);
  }

  console.log(bannerQuery.data);

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <Link to={BannerListRoute}>Lista banerów reklamowych</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        {create && <PageHeading>Nowy baner reklamowy</PageHeading>}

        {!create && (
          <PageHeading>
            {isLoading && <SkeletonText heading={true} width="300px" />}
            {!isLoading && String(bannerQuery.data?.title ?? 'Brak tytułu')}
          </PageHeading>
        )}

        {isLoading && <Loading />}

        {!create && !isLoading && bannerQuery.data && (
          <BannerForm
            create={create}
            options={options}
            defaultValues={{
              category: category ?? null,
              displayStatus: bannerQuery.data.displayStatus ?? '',
              page: bannerQuery.data.subpage ?? '',
              redirectURL: bannerQuery.data.link ?? '',
              title: bannerQuery.data.title ?? '',
              upload: bannerQuery.data.bannerLogo ?? null,
            }}
            isSubmitting={updateMutation.isLoading}
            onValidSubmit={onValidSubmit}
          />
        )}

        {create && !isLoading && (
          <BannerForm
            create={create}
            options={options}
            isSubmitting={createMutation.isLoading}
            defaultValues={{
              category: null,
              displayStatus: '',
              page: '',
              redirectURL: '',
              title: '',
              upload: null,
            }}
            onValidSubmit={onValidSubmit}
          />
        )}
      </Grid>
    </Content>
  );
}
