import React, { useEffect } from 'react';

export const closeOnBackdropClick = (
  ref: React.RefObject<Element>,
  onClose: () => void
) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Element)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

export const closeOnEscapeKeyDown = (onClose: () => void) => {
  const handleEscapeKeyDown = (e: KeyboardEvent) => {
    if (['Escape', 'Esc'].includes(e.key)) {
      onClose();
    }
  };
  useEffect(() => {
    document.body.addEventListener('keydown', handleEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener('keydown', handleEscapeKeyDown);
    };
  }, []);
};
