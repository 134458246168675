import {
  DataTableCustomRenderProps,
  DenormalizedRow,
  Loading,
  OverflowMenu,
  OverflowMenuItem,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import {
  User,
  UserRole,
  userRoleLabelMapper,
  UserRoleRequest,
} from 'common/models/user';
import { useChangeUserRoleMutation } from 'common/hooks/user/useChangeUserRoleMutation';
import { ActionsTableCell } from 'app/components/Table';
import { BlockLoadingButton } from 'app/components/Button';
import { FC } from 'react';
import { useUpdateUserAccountPlusStatusMutation } from 'common/hooks/user/useUpdateUserAccountPlusStatusMutation';

export interface UserListDataTableRowProps {
  row: DenormalizedRow;
  user: User;
}

export const UserListDataTableRow: FC<
  UserListDataTableRowProps & Pick<DataTableCustomRenderProps, 'getRowProps'>
> = ({ getRowProps, row, user }) => {
  const userRoleAdminMutation = useChangeUserRoleMutation(
    UserRoleRequest.Admin
  );
  const userRoleTrainerMutation = useChangeUserRoleMutation(
    UserRoleRequest.Trainer
  );
  const userRoleUserMutation = useChangeUserRoleMutation(UserRoleRequest.User);

  const updateUserAccountPlusMutation =
    useUpdateUserAccountPlusStatusMutation();

  const isMutationLoading =
    userRoleAdminMutation.isLoading ||
    userRoleTrainerMutation.isLoading ||
    userRoleUserMutation.isLoading ||
    updateUserAccountPlusMutation.isLoading;

  if (!user) {
    return null;
  }

  return (
    <TableRow {...getRowProps({ row })}>
      {row.cells.map((cell) => {
        switch (cell.info.header) {
          case 'role':
            return (
              <TableCell key={cell.id}>
                {userRoleLabelMapper(cell.value)}
              </TableCell>
            );
          default:
            return <TableCell key={cell.id}>{cell.value}</TableCell>;
        }
      })}

      <ActionsTableCell>
        {isMutationLoading && (
          <BlockLoadingButton disabled hasIconOnly kind="ghost" size="small">
            <Loading small withOverlay={false} />
          </BlockLoadingButton>
        )}

        {!isMutationLoading && (
          <OverflowMenu size="sm" light flipped>
            {![UserRole.Admin].includes(user.role) && (
              <OverflowMenuItem
                itemText="Zmień rolę: Admin"
                onClick={() => userRoleAdminMutation.mutate(row.id)}
              />
            )}

            {![UserRole.Trainer].includes(user.role) && (
              <OverflowMenuItem
                itemText="Zmień rolę: Trener"
                onClick={() => userRoleTrainerMutation.mutate(row.id)}
              />
            )}

            {![UserRole.User].includes(user.role) && (
              <OverflowMenuItem
                itemText="Zmień rolę: Zwykły użytkownik"
                onClick={() => userRoleUserMutation.mutate(row.id)}
                requireTitle
              />
            )}

            {!user.accountPlusStatus && (
              <OverflowMenuItem
                itemText="Ustaw status PLUS"
                onClick={() =>
                  updateUserAccountPlusMutation.mutate({
                    id: row.id,
                    accountPlusStatus: true,
                  })
                }
                requireTitle
              />
            )}

            {user.accountPlusStatus && (
              <OverflowMenuItem
                itemText="Usuń status PLUS"
                onClick={() =>
                  updateUserAccountPlusMutation.mutate({
                    id: row.id,
                    accountPlusStatus: false,
                  })
                }
                requireTitle
              />
            )}
          </OverflowMenu>
        )}
      </ActionsTableCell>
    </TableRow>
  );
};
