import {
  StaticPageCode,
  staticPageCodeTitleMapper,
} from 'modules/static-pages/models/staticPageCode';
import {
  StaticPageAboutUsFormRoute,
  StaticPageCookiesPolicyFormRoute,
  StaticPageFaqFormRoute,
  StaticPagePrivacyPolicyFormRoute,
  StaticPageTosFormRoute,
} from 'modules/static-pages/routes';

export interface StaticPageItem {
  id: StaticPageCode;
  route: string;
  title: string;
}

export const staticPages: StaticPageItem[] = [
  {
    id: StaticPageCode.Faq,
    route: StaticPageFaqFormRoute,
    title: staticPageCodeTitleMapper[StaticPageCode.Faq],
  },
  {
    id: StaticPageCode.AboutUs,
    route: StaticPageAboutUsFormRoute,
    title: staticPageCodeTitleMapper[StaticPageCode.AboutUs],
  },
  {
    id: StaticPageCode.CookiesPolicy,
    route: StaticPageCookiesPolicyFormRoute,
    title: staticPageCodeTitleMapper[StaticPageCode.CookiesPolicy],
  },
  {
    id: StaticPageCode.PrivacyPolicy,
    route: StaticPagePrivacyPolicyFormRoute,
    title: staticPageCodeTitleMapper[StaticPageCode.PrivacyPolicy],
  },
  {
    id: StaticPageCode.TermsAndConditions,
    route: StaticPageTosFormRoute,
    title: staticPageCodeTitleMapper[StaticPageCode.TermsAndConditions],
  },
];
