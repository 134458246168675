import { Category } from 'common/models/category';
import { Speaker, SpeakerNameInfo } from 'common/models/speaker';
import { find, omit, startsWith } from 'ramda';
import { pipe } from 'fp-ts/function';
import { CourseType } from 'common/models/courseType';
import { SortDirection } from 'common/models/pagination';
import { StoredImageDTO } from 'common/models/image';
import { TableColumnSettings } from 'common/models/tableSettings';

export enum CourseListColumn {
  CategoryName = 'CATEGORY_NAME',
  CourseTitle = 'COURSE_TITLE',
  Speaker = 'SPEAKER',
  StartDateTime = 'START_DATE_TIME',
}

export const courseListColumnSettings: Record<
  CourseListColumn,
  TableColumnSettings
> = {
  [CourseListColumn.CategoryName]: { sortable: true },
  [CourseListColumn.CourseTitle]: { sortable: true },
  [CourseListColumn.Speaker]: { sortable: true },
  [CourseListColumn.StartDateTime]: { sortable: true },
};

export interface CourseCategory extends Category {
  main: boolean;
}

export interface CourseCategoriesDTO {
  id: number;
  main: boolean;
}

export enum CourseLevel {
  Advanced = 'ADVANCED',
  Beginner = 'BEGINNER',
  Intermediate = 'INTERMEDIATE',
}

export interface CourseParameter {
  id: string;
  value: string;
}

export interface CourseParameterDTO {
  id?: string;
  value: string;
}

export interface CourseSpeakerDTO {
  id: string;
}

export interface CourseListItem {
  categories: CourseCategory[];
  courseId: string;
  courseSlug: string;
  courseTitle: string;
  eventId: string;
  speakers: SpeakerNameInfo[];
  startDateTime: string;
}

export interface Course {
  autoAccept: boolean;
  backupLimit: number;
  categories: CourseCategory[];
  certificatePoints: CourseParameter[];
  comment?: string;
  description: string;
  id: string;
  image: StoredImageDTO;
  keywords: CourseParameter[];
  learningPoints: CourseParameter[];
  level?: CourseLevel;
  mainLimit: number;
  recommendationPercent?: number;
  slug: string;
  speakers: Speaker[];
  survey: number;
  surveyId: number;
  targetGroups: CourseParameter[];
  title: string;
  type: CourseType;
}

export interface CourseFormModel {
  additionalCategories: string[];
  autoAccept: boolean;
  certificatePoints: CourseParameter[];
  comment?: string;
  description: string;
  image?: File;
  learningPoints: CourseParameter[];
  level?: CourseLevel;
  keywords: CourseParameter[];
  mainCategory?: string;
  mainLimit: number;
  recommendationPercent?: number;
  slug: string;
  speakers: string[];
  survey: string;
  surveyId: string;
  targetGroups: CourseParameter[];
  title: string;
}

export interface CourseDTO {
  autoAccept: boolean;
  backupLimit: number;
  categories: CourseCategoriesDTO[];
  certificatePoints: CourseParameterDTO[];
  comment?: string;
  description: string;
  learningPoints: CourseParameterDTO[];
  level?: CourseLevel;
  keywords: CourseParameterDTO[];
  mainLimit: number;
  recommendationPercent?: number;
  slug: string;
  speakers: CourseSpeakerDTO[];
  surveyId: number;
  surveyTemplateId: number | null;
  targetGroups: CourseParameterDTO[];
  title: string;
}

export interface CourseRequestDTO {
  course: CourseDTO;
  image?: File;
}

export interface CourseSortData {
  direction: SortDirection;
  orderBy: CourseListColumn;
}

export const courseLevelLabelMap = {
  [CourseLevel.Beginner]: 'Początkujący',
  [CourseLevel.Intermediate]: 'Średnio zaawansowany',
  [CourseLevel.Advanced]: 'Zaawansowany',
};

export interface ListItem {
  id: string;
  label: string;
}

export const courseLevels: ListItem[] = [
  {
    id: CourseLevel.Beginner,
    label: courseLevelLabelMap[CourseLevel.Beginner],
  },
  {
    id: CourseLevel.Intermediate,
    label: courseLevelLabelMap[CourseLevel.Intermediate],
  },
  {
    id: CourseLevel.Advanced,
    label: courseLevelLabelMap[CourseLevel.Advanced],
  },
];

export const initialCourseFormValues: Partial<CourseFormModel> = {
  additionalCategories: [],
  autoAccept: false,
  certificatePoints: [],
  comment: '',
  description: '',
  keywords: [],
  learningPoints: [],
  mainCategory: '',
  slug: '',
  speakers: [],
  survey: '',
  surveyId: '',
  targetGroups: [],
  title: '',
};

export const mapCourseParametersToDTO = (item: CourseParameter) =>
  startsWith('new', item.id) ? omit(['id'], item) : item;

export const mapToCourseRequestDTO = ({
  additionalCategories,
  autoAccept,
  certificatePoints,
  comment,
  description,
  image,
  keywords,
  learningPoints,
  level,
  mainCategory,
  mainLimit,
  recommendationPercent,
  slug,
  speakers,
  survey,
  surveyId,
  targetGroups,
  title,
}: CourseFormModel): CourseRequestDTO => ({
  course: {
    backupLimit: 30,
    categories: [
      { id: Number(mainCategory), main: true },
      ...additionalCategories
        .filter((item) => Number(item) !== Number(mainCategory))
        .map((item) => ({ id: Number(item), main: false })),
    ],
    certificatePoints: certificatePoints.map(mapCourseParametersToDTO),
    keywords: keywords.map(mapCourseParametersToDTO),
    learningPoints: learningPoints.map(mapCourseParametersToDTO),
    mainLimit: Number(mainLimit),
    recommendationPercent: Number(recommendationPercent),
    speakers: speakers.map((speakerId) => ({ id: speakerId })),
    surveyId: Number(surveyId),
    surveyTemplateId: Number(survey), // survey !== surveyId ? Number(survey) : null,
    targetGroups: targetGroups.map(mapCourseParametersToDTO),
    autoAccept,
    comment,
    description,
    level,
    slug,
    title,
  },
  image,
});

export const mapToCourseFormModel = ({
  autoAccept,
  categories,
  certificatePoints,
  comment,
  description,
  keywords,
  learningPoints,
  level,
  mainLimit,
  recommendationPercent,
  slug,
  speakers,
  surveyId,
  targetGroups,
  title,
}: Course): CourseFormModel => ({
  additionalCategories: categories
    .filter((item) => !item.main)
    .map((item) => item.id),
  mainCategory: pipe(
    categories,
    find((category) => category.main === true),
    (category) => (category ? category.id : undefined)
  ),
  speakers: speakers.map((item) => item.id),
  survey: surveyId.toString(),
  surveyId: surveyId.toString(),
  autoAccept,
  certificatePoints,
  comment,
  description,
  keywords,
  learningPoints,
  level,
  mainLimit,
  recommendationPercent,
  slug,
  targetGroups,
  title,
});
