import { useMutation, useQueryClient } from 'react-query';
import { setCourseEventParticipantPresence } from 'common/services/courseEventParticipant';
import { QueryKey } from 'config/queryKey';
import { CourseEventParticipantEventStatus } from 'common/models/courseEventParticipant';

export const useCourseEventParticipantPresenceMutation = (
  eventId: string,
  status:
    | CourseEventParticipantEventStatus.Absent
    | CourseEventParticipantEventStatus.Present
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (userId: string) =>
      setCourseEventParticipantPresence(eventId, userId, status),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryKey.CourseEventParticipantList,
          eventId,
        ]);
      },
    }
  );
};
