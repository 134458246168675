import {
  Button,
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import {
  CloseFilled20,
  DocumentExport32,
  Edit20,
  List20,
  Run20,
  WatsonHealthDownloadStudy32,
} from '@carbon/icons-react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  getCourseEventFormRoute,
  getCourseEventParticipantListRoute,
} from 'routes';
import { format } from 'lib/date';
import { useDeleteCourseEventMutation } from 'common/hooks/courseEvent/useDeleteCourseEventMutation';
import { courseEventCourseIdSelector } from 'state/courseEvent/courseEventSelectors';
import { useSelector } from 'react-redux';
import { useWebinarStartUrlQuery } from 'common/hooks/courseEvent/useWebinarStartUrlQuery';
import { isNilEmpty } from 'lib/isNilEmpty';
import {
  CourseEventListColumn,
  CourseEventListItem,
  CourseEventStatus,
} from 'common/models/courseEvent';
import { useCloseCourseEventMutation } from 'common/hooks/courseEvent/useCloseCourseEventMutation';
import { ActionsTableCell } from 'app/components/Table';
import { useExportEventSurveyResultsMutation } from 'common/hooks/survey/useExportEventSurveyResultsMutation';
import { useCourseEventParticipantsListExportMutation } from 'common/hooks/courseEventParticipant/useCourseEventParticipantsListExportMutation';
import { DataTableButton } from 'app/components/DataTableButton';
import { FC } from 'react';
import { useStartCourseEventMutation } from 'common/hooks/courseEvent/useStartCourseEventMutation';
import { DataTableDeleteButton } from 'app/components/DataTableDeleteButton';

const StyledActionsTableCell = styled(ActionsTableCell)`
  width: 18rem;
`;

export interface CourseEventListDataTableRowProps {
  courseEventListItem?: CourseEventListItem;
  onCloseSuccess?: (row: DenormalizedRow) => void;
  onDeleteSuccess?: (row: DenormalizedRow) => void;
  onStartSuccess?: (row: DenormalizedRow) => void;
  row: DenormalizedRow;
}

export const CourseEventListDataTableRow: FC<
  CourseEventListDataTableRowProps &
    Pick<DataTableCustomRenderProps, 'getRowProps' | 'getSelectionProps'>
> = ({
  courseEventListItem,
  onCloseSuccess,
  onDeleteSuccess,
  onStartSuccess,
  row,

  getRowProps,
}) => {
  const courseId = useSelector(courseEventCourseIdSelector);
  const closeMutation = useCloseCourseEventMutation();
  const deleteMutation = useDeleteCourseEventMutation();
  const exportResultsMutation = useExportEventSurveyResultsMutation();
  const exportEventParticipantsListMutation =
    useCourseEventParticipantsListExportMutation();
  const startMutation = useStartCourseEventMutation();

  const { data: zoomStartUrl } = useWebinarStartUrlQuery(
    row.id,
    courseEventListItem &&
      [CourseEventStatus.Open, CourseEventStatus.Ongoing].includes(
        courseEventListItem.status
      )
  );

  if (!courseEventListItem) {
    return null;
  }

  return (
    <TableRow {...getRowProps({ row })}>
      {row.cells.map((cell) => {
        switch (cell.info.header) {
          case CourseEventListColumn.StartDateTime:
            return (
              <TableCell key={cell.id}>
                {format(cell.value, 'dd.MM.yyyy HH:mm')}
              </TableCell>
            );
          default:
            return <TableCell key={cell.id}>{cell.value}</TableCell>;
        }
      })}

      <StyledActionsTableCell>
        {[CourseEventStatus.Open].includes(courseEventListItem.status) &&
          !isNilEmpty(zoomStartUrl) && (
            <Button
              as="a"
              hasIconOnly
              href={zoomStartUrl?.url}
              iconDescription="Rozpocznij wydarzenie"
              kind="primary"
              onClick={() =>
                startMutation.mutate(row.id, {
                  onSuccess: () => onStartSuccess && onStartSuccess(row),
                })
              }
              renderIcon={Run20}
              size="small"
              target="_blank"
              tooltipAlignment="center"
              tooltipPosition="top"
            />
          )}

        {[CourseEventStatus.Ongoing].includes(courseEventListItem.status) &&
          !isNilEmpty(zoomStartUrl) && (
            <Button
              as="a"
              hasIconOnly
              href={zoomStartUrl?.url}
              iconDescription="Przejdź do Zoom"
              kind="primary"
              renderIcon={Run20}
              size="small"
              target="_blank"
              tooltipAlignment="center"
              tooltipPosition="top"
            />
          )}

        <DataTableButton
          hasIconOnly
          iconDescription="Zamknij wydarzenie"
          isLoading={closeMutation.isLoading}
          isVisible={[CourseEventStatus.Ongoing].includes(
            courseEventListItem.status
          )}
          kind="danger"
          onClick={() =>
            closeMutation.mutate(row.id, {
              onSuccess: () => onCloseSuccess && onCloseSuccess(row),
            })
          }
          renderIcon={CloseFilled20}
          size="small"
          tooltipAlignment="center"
          tooltipPosition="top"
        />
        <DataTableButton
          hasIconOnly
          iconDescription="Eksportuj uczestników"
          isLoading={exportEventParticipantsListMutation.isLoading}
          kind="ghost"
          onClick={() => {
            exportEventParticipantsListMutation.mutate(row.id);
          }}
          renderIcon={WatsonHealthDownloadStudy32}
          size="small"
          tooltipAlignment="center"
          tooltipPosition="top"
        />
        <DataTableButton
          hasIconOnly
          iconDescription="Eksportuj wyniki ankiet"
          isLoading={exportResultsMutation.isLoading}
          kind="ghost"
          onClick={() => {
            exportResultsMutation.mutate(row.id);
          }}
          renderIcon={DocumentExport32}
          size="small"
          tooltipAlignment="center"
          tooltipPosition="top"
        />
        <Button
          as={Link}
          hasIconOnly
          iconDescription="Uczestnicy"
          kind="ghost"
          renderIcon={List20}
          size="small"
          to={getCourseEventParticipantListRoute(courseId, row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />
        <Button
          as={Link}
          hasIconOnly
          iconDescription="Edytuj"
          kind="ghost"
          renderIcon={Edit20}
          size="small"
          to={getCourseEventFormRoute(courseId, row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <DataTableDeleteButton
          confirmationTitle="Usuń wydarzenie"
          isLoading={deleteMutation.isLoading}
          onSubmit={() =>
            deleteMutation.mutate(row.id, {
              onSuccess: () => onDeleteSuccess && onDeleteSuccess(row),
            })
          }
        />
      </StyledActionsTableCell>
    </TableRow>
  );
};
