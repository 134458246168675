import { queryClient } from 'common/providers/ReactQueryClientProvider';
import { AuthorizedAxios, throwErrorResponse } from 'common/services/axios';
import { QueryKey } from 'config/queryKey';
import { push } from 'connected-react-router';
import { flatten } from 'lib/axios';
import { bannerPayload } from 'modules/banner/config/bannerPayload';
import { BannerFields } from 'modules/banner/types.banner';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { BannerListRoute } from 'routes';
import { snackbarActions } from 'state/notification/notificationActions';

export function useMutationBannerUpdate(bannerID: string) {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async function (submitted: BannerFields) {
      return await AuthorizedAxios.put(
        `/api/banners/${bannerID}`,
        bannerPayload(submitted),
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
        .then(flatten)
        .catch(throwErrorResponse);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.BannerList);
      dispatch(push(BannerListRoute));
      dispatch(
        snackbarActions.enqueueSnackbar({
          title: 'Baner został zmodyfikowany pomyślnie',
          options: { variant: 'success' },
        })
      );
    },
  });
}
