import {
  Button,
  ComboBox,
  DataTableCustomRenderProps,
  Loading,
  TableBody,
  TableHead,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react';
import { Add20 } from '@carbon/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableContainer,
  TableContent,
  TableLoader,
} from 'app/components/Table';
import React, { FC, useMemo, useState } from 'react';
import { isEmpty } from 'ramda';
import { DataTableEmptyRow } from 'app/components/DataTableEmptyRow';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/Option';
import { ListFilters } from 'app/components/ListFilters';
import {
  courseEventStatusList,
  CourseEventStatusListItem,
  EventStatusListItem,
} from 'common/models/courseEvent';
import { DataTableHeader } from 'app/components/DataTableHeader';
import { CourseBundleEventListTableRow } from 'app/components/CourseBundleContent/EventList/CourseBundleEventListTableRow';
import { courseBundleEventListActions } from 'state/courseBundleContent/eventList/courseBundleEventListActions';
import {
  courseBundleEventListSortSelector,
  courseBundleEventListStatusSelector,
} from 'state/courseBundleContent/eventList/courseBundleEventListSelectors';
import { CourseBundleEventListColumn } from 'common/models/courseBundleContent';
import { SelectableListPopover } from 'app/components/CourseBundleContent/SelectableListPopover';
import { useCourseBundleAssignableEventsQuery } from 'common/hooks/courseBundle/useCourseBundleAssignableEventsQuery';
import { useParams } from 'react-router-dom';
import { SelectableEventsForm } from 'app/components/CourseBundleContent/SelectableListPopover/SelectableEventsForm';

export interface CourseBundleEventListDataTableContentProps {
  courseEventListItems?: EventStatusListItem[];
  isFetching?: boolean;
}

export const CourseBundleEventListTableContent: FC<
  CourseBundleEventListDataTableContentProps & DataTableCustomRenderProps
> = ({
  courseEventListItems,
  isFetching = false,
  rows,
  headers,
  getBatchActionProps,
  getSelectionProps,
  getTableProps,
  getTableContainerProps,
  getToolbarProps,
  getRowProps,
}) => {
  const dispatch = useDispatch();

  const eventStatusOption = useSelector(courseBundleEventListStatusSelector);
  const sortData = useSelector(courseBundleEventListSortSelector);

  const { courseBundleId } = useParams<{ courseBundleId: string }>();

  const { data: courseWithEventList } =
    useCourseBundleAssignableEventsQuery(courseBundleId);

  const eventStatus = useMemo(
    () =>
      pipe(
        eventStatusOption,
        getOrElse(() => 'undefined')
      ),
    [eventStatusOption]
  );

  const checkedEventIds = useMemo(
    () => rows.map((row) => parseInt(row.id)),
    [rows]
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const initialValues = useMemo(
    () => ({
      items: courseWithEventList
        ? courseWithEventList.map((course) => ({
            events: course.events?.map((event) => ({
              eventId: event.id,
              checked: checkedEventIds?.includes(event.id) ? true : undefined,
            })),
          }))
        : [],
    }),
    [courseWithEventList, checkedEventIds]
  );

  return (
    <TableContainer
      title="Lista wydarzeń w kursie"
      {...getTableContainerProps()}
    >
      <TableToolbar {...getToolbarProps()}>
        <TableToolbarContent>
          <ListFilters>
            <ComboBox<CourseEventStatusListItem>
              id="filterStatus"
              initialSelectedItem={courseEventStatusList.find(
                (item) => item.id === eventStatus
              )}
              items={courseEventStatusList}
              itemToString={(item) => (item ? item.label : '')}
              light={true}
              onChange={(value) => {
                dispatch(
                  courseBundleEventListActions.setStatus(value.selectedItem?.id)
                );
              }}
              placeholder="Wybierz status.."
            />
          </ListFilters>

          <Button
            kind="primary"
            renderIcon={Add20}
            size="small"
            tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
            onClick={handleClick}
          >
            Dodaj nowe wydarzenie
          </Button>
        </TableToolbarContent>
      </TableToolbar>

      <SelectableListPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <SelectableEventsForm
          courseBundleId={courseBundleId}
          selectableItemList={courseWithEventList}
          initialValues={initialValues}
        />
      </SelectableListPopover>

      <TableContent>
        <Table $loading={isFetching} {...getTableProps()}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <DataTableHeader
                  isSortable={true}
                  isSortHeader={sortData.orderBy === header.key}
                  sortDirection={sortData.direction}
                  onClick={(direction) => {
                    dispatch(
                      courseBundleEventListActions.setSort({
                        direction,
                        orderBy: header.key as CourseBundleEventListColumn,
                      })
                    );
                  }}
                  key={header.key}
                >
                  {header.header}
                </DataTableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(rows) &&
              rows.map((row, index) => (
                <CourseBundleEventListTableRow
                  courseEventListItem={courseEventListItems?.[index]}
                  getRowProps={getRowProps}
                  getSelectionProps={getSelectionProps}
                  key={row.id}
                  row={row}
                />
              ))}

            {isEmpty(rows) && <DataTableEmptyRow colSpan={3} />}
          </TableBody>
        </Table>

        {isFetching && (
          <TableLoader>
            <Loading active={true} withOverlay={false} />
          </TableLoader>
        )}
      </TableContent>
    </TableContainer>
  );
};
