import { authActions } from 'state/auth/authActions';
import { Power20 } from '@carbon/icons-react';
import { FC, useState } from 'react';
import { HeaderGlobalActionWithText } from 'common/components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationModal } from 'common/components/ConfirmationModal';
import { fileUploaderOngoingCountSelector } from 'state/fileUploader/fileUploaderSelectors';

export const LogoutButton: FC = () => {
  const dispatch = useDispatch();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const ongoingUploadsCount = useSelector(fileUploaderOngoingCountSelector);

  const logout = () => {
    dispatch(authActions.logout());
  };

  return (
    <>
      <HeaderGlobalActionWithText
        aria-label="Wyloguj"
        onClick={() => {
          ongoingUploadsCount === 0 ? logout() : setShowConfirmationModal(true);
        }}
      >
        Wyloguj
        <Power20 />
      </HeaderGlobalActionWithText>

      <ConfirmationModal
        buttonSubmitText="Wyloguj"
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onSubmit={logout}
        text={
          <>
            Trwa wysyłanie plików.
            <br />
            Czy na pewno chcesz się wylogować i przerwać wysyłanie plików?
          </>
        }
        title="Wyloguj"
      />
    </>
  );
};
