import { createAction } from 'lib/createAction';
import { PaginationState } from 'common/models/pagination';
import {
  CourseEventSortData,
  CourseEventStatus,
} from 'common/models/courseEvent';

export interface CourseEventListFilters {
  courseId?: string;
  pagination: PaginationState;
  sort: CourseEventSortData;
  status?: CourseEventStatus;
}

export const courseEventActions = {
  clear: createAction('courseEvent/clear')(),
  loadPagination: createAction('courseEvent/loadPagination')(),
  setCourseId: createAction('courseEvent/setCourseId')<string | undefined>(),
  setFilters: createAction('courseEvent/setFilters')<CourseEventListFilters>(),
  setPagination: createAction('courseEvent/setPagination')<PaginationState>(),
  setSort: createAction('courseEvent/setSort')<CourseEventSortData>(),
  setStatus: createAction('courseEvent/setStatus')<
    CourseEventStatus | null | undefined
  >(),
} as const;
