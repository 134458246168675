import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createMatchSelector,
  push,
  RouterRootState,
} from 'connected-react-router';
import { stringify } from 'qs';
import { pipe } from 'fp-ts/function';
import { filter, fromNullable, getOrElse, Option } from 'fp-ts/lib/Option';
import { getQueryParamsMap } from 'lib/getQueryParams';
import { mapObjIndexed, mergeLeft } from 'ramda';
import {
  initialPagination,
  PaginationState,
  SortDirection,
} from 'common/models/pagination';
import { isSomeEnum, isString } from 'lib/typeGuards';
import { courseEventActions } from 'state/courseEvent/courseEventActions';
import {
  courseEventCourseIdSelector,
  courseEventPaginationSelector,
  courseEventSortSelector,
  courseEventStatusSelector,
} from 'state/courseEvent/courseEventSelectors';
import { CourseEventListRoute } from 'routes';
import { rootStateSelector } from 'state/router/routerSelectors';
import {
  CourseEventListColumn,
  courseEventListColumnSettings,
  CourseEventListParams,
  CourseEventSortData,
  CourseEventStatus,
} from 'common/models/courseEvent';
import { isSome } from 'fp-ts/Option';
import { defaultSort } from 'state/courseEvent/courseEventReducer';
import { setParam } from 'common/utils/routing';

function* writeToURLQuery() {
  const courseId: string = yield select(courseEventCourseIdSelector);
  const filterStatusOption: Option<CourseEventStatus> = yield select(
    courseEventStatusSelector
  );
  const pagination: Option<PaginationState> = yield select(
    courseEventPaginationSelector
  );
  const sortData: CourseEventSortData = yield select(courseEventSortSelector);

  const filterStatus = pipe(
    filterStatusOption,
    getOrElse(() => 'undefined')
  );

  const qs = pipe(
    pagination,
    getOrElse(() => initialPagination),
    (item) =>
      isSome(filterStatusOption)
        ? {
            ...item,
            status: filterStatus,
          }
        : item,
    mergeLeft(sortData),
    stringify
  );

  yield put(
    push(`${setParam(':courseId')(CourseEventListRoute)(courseId)}?${qs}`, {
      replace: true,
    })
  );
}

function* readFromURLQuery() {
  const qp = getQueryParamsMap();

  const routerState: RouterRootState = yield select(rootStateSelector);
  const matchSelector = createMatchSelector<
    RouterRootState,
    CourseEventListParams
  >(CourseEventListRoute);

  const match = matchSelector(routerState);

  const pagination = pipe(
    initialPagination,
    mapObjIndexed((value, key) =>
      pipe(
        fromNullable(qp[key]),
        filter(isString),
        getOrElse(() => value.toString()),
        Number
      )
    )
  );

  const sortData: CourseEventSortData = {
    direction: pipe(
      fromNullable(qp['direction']),
      filter(isSomeEnum(SortDirection)),
      getOrElse(() => defaultSort.direction)
    ),
    orderBy: pipe(
      fromNullable(qp['orderBy']),
      filter(isSomeEnum(CourseEventListColumn)),
      filter((column) => courseEventListColumnSettings[column].sortable),
      getOrElse(() => defaultSort.orderBy)
    ),
  };

  yield put(
    courseEventActions.setFilters({
      courseId: match?.params.courseId,
      pagination: pagination,
      sort: sortData,
      status: qp.status ? (String(qp.status) as CourseEventStatus) : undefined,
    })
  );
}

function* readFromURLQueryWatcher() {
  yield takeEvery(courseEventActions.loadPagination.type, readFromURLQuery);
}

function* writeToURLQueryWatcher() {
  yield takeLatest(courseEventActions.setFilters.type, writeToURLQuery);
  yield takeLatest(courseEventActions.setPagination.type, writeToURLQuery);
  yield takeLatest(courseEventActions.setSort.type, writeToURLQuery);
  yield takeLatest(courseEventActions.setStatus.type, writeToURLQuery);
}

export function* courseEventSaga() {
  yield all([writeToURLQueryWatcher(), readFromURLQueryWatcher()]);
}
