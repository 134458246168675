import { QueryObserverOptions, useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { ResultResponse } from 'common/models/response';
import { useSelector } from 'react-redux';
import { getOrElse, isSome, Option } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/function';
import { initialPagination, PaginationState } from 'common/models/pagination';
import { User, UserListQueryFilters } from 'common/models/user';
import { getUserList } from 'common/services/user';
import { RootState } from 'state/reducer';

export interface UserListQueryParams {
  filters?: UserListQueryFilters;
  options?: QueryObserverOptions<ResultResponse<User>>;
  paginationSelector: (state: RootState) => Option<PaginationState>;
}

export const useUserListQuery = ({
  filters,
  options,
  paginationSelector,
}: UserListQueryParams) => {
  const paginationOption = useSelector(paginationSelector);

  const pagination = pipe(
    paginationOption,
    getOrElse(() => initialPagination)
  );

  return useQuery<ResultResponse<User>>(
    [QueryKey.UserList, pagination.page, pagination.pageSize, filters],
    () => getUserList(pagination, filters),
    {
      ...options,
      enabled: isSome(paginationOption),
      keepPreviousData: true,
    }
  );
};
