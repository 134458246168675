import { FC, useEffect, useMemo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  Grid,
  Pagination,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { useDispatch, useSelector } from 'react-redux';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/lib/Option';
import { initialPagination, pageSizes } from 'common/models/pagination';
import { Content } from 'common/components/Grid';
import { userPaginationSelector } from 'state/user/userSelectors';
import { useUserListQuery } from 'common/hooks/user/useUserListQuery';
import { userActions } from 'state/user/userActions';
import { UserListDataTableContent } from 'app/components/UserList/UserListDataTableContent';

const headers = [
  {
    key: 'login',
    header: 'E-mail',
  },
  {
    key: 'name',
    header: 'Imię i nazwisko',
  },
  {
    key: 'role',
    header: 'Rola',
  },
  {
    key: 'plus',
    header: 'Status PLUS',
  },
];

export const UserList: FC = () => {
  const dispatch = useDispatch();
  const paginationOption = useSelector(userPaginationSelector);

  const pagination = useMemo(
    () =>
      pipe(
        paginationOption,
        getOrElse(() => initialPagination)
      ),
    [paginationOption]
  );

  const { data, isFetching, isLoading } = useUserListQuery({
    options: {
      onSuccess: (data) => {
        if (data && data.result.length === 0 && pagination.page !== 1) {
          dispatch(
            userActions.setPagination({
              page: Math.ceil(data.totalResult / pagination.pageSize),
              pageSize: pagination.pageSize,
            })
          );
        }
      },
    },
    paginationSelector: userPaginationSelector,
  });

  const rows = useMemo(
    () =>
      data?.result.map((item) => ({
        id: item.id.toString(),
        login: item.login,
        name: `${item.firstName} ${item.lastName}`,
        role: item.role,
        plus: item.accountPlusStatus ? 'TAK' : '-',
      })),
    [data]
  );

  useEffect(() => {
    dispatch(userActions.loadPagination());
  }, [dispatch]);

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageHeading>Lista użytkowników</PageHeading>

        {!rows && (
          <DataTableSkeleton headers={headers} rowCount={10}>
            {(props: DataTableCustomRenderProps) => (
              <UserListDataTableContent {...props} users={[]} />
            )}
          </DataTableSkeleton>
        )}

        {rows && !isLoading && (
          <DataTable headers={headers} overflowMenuOnHover={false} rows={rows}>
            {(props: DataTableCustomRenderProps) => (
              <UserListDataTableContent
                {...props}
                isFetching={isFetching}
                users={data?.result ?? []}
              />
            )}
          </DataTable>
        )}

        <Pagination
          onChange={(pagination) => {
            dispatch(
              userActions.setPagination({
                page: pagination.page,
                pageSize: pagination.pageSize,
              })
            );
          }}
          page={pagination.page}
          pageSize={pagination.pageSize}
          pageSizes={pageSizes}
          totalItems={data?.totalResult ?? 0}
        />
      </Grid>
    </Content>
  );
};
