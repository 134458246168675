import { uniqBy } from 'ramda';
import { StoredImageDTO } from 'common/models/image';

export enum SpeakerUrlType {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Linkedin = 'LINKEDIN',
  Web = 'WEB',
  Youtube = 'YOUTUBE',
}

export interface SpeakerUrlDTO {
  address: string;
  urlType: SpeakerUrlType;
}

export interface Speaker {
  averageRating: number;
  bio: string;
  company: string;
  firstName: string;
  id: string;
  lastName: string;
  photo: StoredImageDTO;
  signature: string;
  urls: SpeakerUrlDTO[];
}

export interface SpeakerFormModel {
  averageRating: number;
  bio: string;
  company: string;
  firstName: string;
  photo?: File;
  lastName: string;
  signature?: File;
  signatureString?: string;
  urls: SpeakerUrlDTO[];
}

export interface SpeakerDTO {
  averageRating: number;
  bio: string;
  company: string;
  firstName: string;
  lastName: string;
  photo?: StoredImageDTO;
  signature?: string;
  urls: SpeakerUrlDTO[];
}

export interface SpeakerNameInfo {
  firstName: string;
  id: number;
  lastName: string;
}

export interface SpeakerRequestDTO {
  speaker: SpeakerDTO;
  photo?: File;
}

export const initialSpeakerUrls: SpeakerUrlDTO[] = [
  { address: '', urlType: SpeakerUrlType.Web },
  { address: '', urlType: SpeakerUrlType.Linkedin },
  { address: '', urlType: SpeakerUrlType.Facebook },
  { address: '', urlType: SpeakerUrlType.Instagram },
  { address: '', urlType: SpeakerUrlType.Youtube },
];

export const initialSpeakerFormValues: Partial<SpeakerFormModel> = {
  urls: initialSpeakerUrls,
};

export const speakerUrlTypeLabelMap = {
  [SpeakerUrlType.Facebook]: 'Facebook',
  [SpeakerUrlType.Instagram]: 'Instagram',
  [SpeakerUrlType.Linkedin]: 'Linkedin',
  [SpeakerUrlType.Web]: 'Adres WWW',
  [SpeakerUrlType.Youtube]: 'Youtube',
};

export const mapToSpeakerFormModel = ({ urls, ...rest }: Speaker) => ({
  ...rest,
  urls: uniqBy((url) => url.urlType, [...urls, ...initialSpeakerUrls]),
});

export const mapToSpeakerDTO = ({
  averageRating,
  bio,
  company,
  firstName,
  lastName,
  photo,
  signatureString,
  urls,
}: SpeakerFormModel): SpeakerRequestDTO => ({
  speaker: {
    bio,
    company,
    firstName,
    lastName,
    urls,
    averageRating: Number(averageRating.toString().replace(/,/g, '.')),
    signature: signatureString?.split('base64,')[1],
  },
  photo,
});
