import React, { FC } from 'react';
import { ControlProps } from 'app/components/SurveyFormPage/SurveyQuestionControl/common';
import styled, { css } from 'styled-components';
import {
  Button,
  FormLabel,
  TileAboveTheFoldContent,
  TileBelowTheFoldContent,
} from 'carbon-components-react';
import { Add20 } from '@carbon/icons-react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SortableItem } from 'app/components/SurveyFormPage/SortableItem';
import {
  ChoiceOptionControl,
  ChoiceOptionControlModel,
  defaultChoiceOptionControlValue,
} from 'app/components/SurveyFormPage/SurveyQuestionControl/ChoiceOptionControl';
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { ExpandableTileBlock } from 'app/components/ExpandableTileBlock';
import { useControlErrors } from 'common/hooks/useControlErrors';
import { ErrorBlock } from 'app/components/ErrorBlock';

const Container = styled.div<{ $invalid: boolean }>`
  ${({ $invalid }) =>
    $invalid &&
    css`
      outline: 2px solid #da1e28;
    `}
`;

const ControlGroup = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: 1fr 8rem 10rem;
  column-gap: 0.5rem;
`;

const StyledChoiceOptionControl = styled(ChoiceOptionControl)`
  margin-top: 0.5rem;
`;

export const ChoiceOptionListControl: FC<ControlProps<[]>> = ({
  name,
  label,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name,
  });
  const controlErrors = useControlErrors(errors, name);
  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = fields.findIndex((field) => field.id === active.id);
      const newIndex = fields.findIndex((field) => field.id === over.id);

      move(newIndex, oldIndex);
    }
  };

  return (
    <Container $invalid={controlErrors}>
      <ExpandableTileBlock>
        <TileAboveTheFoldContent>
          <FormLabel style={{ height: '1.5rem' }}>
            <div>{label}</div>
            {controlErrors?.message && (
              <ErrorBlock>{controlErrors?.message}</ErrorBlock>
            )}
          </FormLabel>
        </TileAboveTheFoldContent>
        <TileBelowTheFoldContent>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={fields}
              strategy={verticalListSortingStrategy}
            >
              {fields.map((field, index) => (
                <SortableItem
                  id={field.id}
                  key={field.id}
                  onDelete={() => remove(index)}
                >
                  <StyledChoiceOptionControl
                    name={`${name}.${index}`}
                    label={`Odpowiedź nr ${index + 1}`}
                    defaultValue={field as ChoiceOptionControlModel}
                  />
                </SortableItem>
              ))}
            </SortableContext>
          </DndContext>
          <ControlGroup>
            <Button
              kind="secondary"
              iconDescription="Dodaj nowe odpowiedź"
              renderIcon={Add20}
              size="field"
              tooltipAlignment="center"
              tooltipPosition="top"
              onClick={() => append(defaultChoiceOptionControlValue)}
            >
              Dodaj odpowiedź
            </Button>
          </ControlGroup>
        </TileBelowTheFoldContent>
      </ExpandableTileBlock>
    </Container>
  );
};
