import { QueryObserverOptions, useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { ResultResponse } from 'common/models/response';
import { Speaker } from 'common/models/speaker';
import { getSpeakerList } from 'common/services/speaker';

const page = 1;
const pageSize = 1000;

export const useAllSpeakersListQuery = (
  options?: QueryObserverOptions<ResultResponse<Speaker>>
) => {
  return useQuery<ResultResponse<Speaker>>(
    [QueryKey.SpeakerList, page, pageSize],
    () => getSpeakerList({ page, pageSize }),
    options
  );
};
