import { PaginationParams } from 'common/models/pagination';
import { AuthorizedAxios, throwErrorResponse } from 'common/services/axios';
import { mapPageResults, ResultResponse } from 'common/models/response';
import { flatten } from 'lib/axios';
import {
  mapToSurveyListItem,
  SurveyDTO,
  SurveyListItemDTO,
} from 'common/models/survey';
import { pipe } from 'ramda';
import { saveData } from 'common/services/file';

export const getSurveyList = ({ page, pageSize }: PaginationParams) =>
  AuthorizedAxios.get<ResultResponse<SurveyListItemDTO>>('/api/surveys', {
    params: { page, pageSize },
  })
    .then(pipe(flatten, mapPageResults(mapToSurveyListItem)))
    .catch(throwErrorResponse);

export const createSurvey = (dto: SurveyDTO) =>
  AuthorizedAxios.post<SurveyDTO>(`/api/surveys`, dto).catch(
    throwErrorResponse
  );

export const getSurveyById = (id: string) =>
  AuthorizedAxios.get<SurveyDTO>(`/api/surveys/${id}`)
    .then(flatten)
    .catch(throwErrorResponse);

export const updateSurvey = (dto: SurveyDTO) =>
  AuthorizedAxios.put<SurveyDTO>(`/api/surveys/${dto.id}`, dto).catch(
    throwErrorResponse
  );

export const deleteSurvey = (id: string) =>
  AuthorizedAxios.delete<void>(`/api/surveys/${id}`).catch(throwErrorResponse);

export const exportEventSurveyResults = (eventId: string) =>
  AuthorizedAxios.get<string>(`/api/surveys/events/results`, {
    params: {
      eventId,
    },
  })
    .then(flatten)
    .then(saveData(`event-${eventId}-survey-results.csv`))
    .catch(throwErrorResponse);

export const exportElearningSurveyResults = (courseId: string) =>
  AuthorizedAxios.get<string>(`/api/surveys/elearnings/results`, {
    params: {
      courseId,
    },
  })
    .then(flatten)
    .then(saveData(`elearning-${courseId}-survey-results.csv`))
    .catch(throwErrorResponse);
