import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { createRootReducer, RootState } from 'state/reducer';
import { createReducerInjector, createSagaInjector } from 'lib/reduxInjectors';
import { authSagas } from 'state/auth/authSagas';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { speakerSaga } from 'state/speaker/speakerSagas';
import { courseSaga } from 'state/course/courseSagas';
import { surveySaga } from 'state/survey/surveySagas';
import { courseEventSaga } from 'state/courseEvent/courseEventSagas';
import { courseEventParticipantSaga } from 'state/courseEventParticipant/courseEventParticipantSagas';
import { userSaga } from 'state/user/userSagas';
import * as Sentry from '@sentry/react';
import { partnerSaga } from 'state/partner/partnerSagas';
import { elearningSaga } from 'state/elearning/elearningSagas';
import { fileUploaderSaga } from 'state/fileUploader/fileUploaderSagas';
import { elearningLessonSaga } from 'state/elearningLesson/elearningLessonSagas';
import { partnerUsersSaga } from 'state/partnerUsers/partnerUsersSagas';
import { courseBundleSaga } from 'state/courseBundle/courseBundleSagas';
import { courseBundleElearningListSaga } from 'state/courseBundleContent/elearningList/courseBundleElearningListSagas';
import { courseBundleEventListSaga } from 'state/courseBundleContent/eventList/courseBundleEventListSagas';

function* rootSaga() {
  yield all([
    authSagas(),
    courseSaga(),
    courseEventSaga(),
    courseEventParticipantSaga(),
    elearningSaga(),
    elearningLessonSaga(),
    courseBundleSaga(),
    courseBundleElearningListSaga(),
    courseBundleEventListSaga(),
    fileUploaderSaga(),
    partnerSaga(),
    partnerUsersSaga(),
    speakerSaga(),
    surveySaga(),
    userSaga(),
  ]);
}

const rootStateFactory = () => {
  const history = createBrowserHistory();
  const sagaMiddleware = createSagaMiddleware();
  const sentryReduxEnhancer = Sentry.createReduxEnhancer();
  const { injectSaga, deleteSaga } = createSagaInjector(sagaMiddleware.run);
  const enhancer = composeWithDevTools(
    applyMiddleware(routerMiddleware(history), sagaMiddleware),
    sentryReduxEnhancer
  );

  const rootReducer = createRootReducer(history);

  const store = createStore(combineReducers<RootState>(rootReducer), enhancer);
  const persistor = persistStore(store);
  const { injectReducer, deleteReducer } = createReducerInjector(
    rootReducer,
    store.replaceReducer
  );
  injectSaga('root', rootSaga);

  return {
    history,
    store,
    persistor,
    injectReducer,
    deleteReducer,
    injectSaga,
    deleteSaga,
  };
};

export const {
  history,
  store,
  persistor,
  injectReducer,
  deleteReducer,
  injectSaga,
  deleteSaga,
} = rootStateFactory();
