import { Content } from 'common/components/Grid';
import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  Grid,
  Row,
  SkeletonText,
} from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import { ElearningListRoute, RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import React, { FC, useMemo } from 'react';
import { useElearningByIdQuery } from 'common/hooks/elearning/useElearningByIdQuery';
import { ElearningForm } from 'app/components/ElearningForm';
import { useCreateElearningMutation } from 'common/hooks/elearning/useCreateElearningMutation';
import { useUpdateElearningMutation } from 'common/hooks/elearning/useUpdateElearningMutation';
import {
  mapToElearningCreateDTO,
  mapToElearningFormModel,
  mapToElearningUpdateDTO,
} from 'common/models/elearning';

interface ElearningFormPageParams {
  elearningId: string;
}

export const ElearningFormPage: FC<{ create?: boolean }> = ({ create }) => {
  const { elearningId } = useParams<ElearningFormPageParams>();

  const createMutation = useCreateElearningMutation();
  const updateMutation = useUpdateElearningMutation();

  const { data: elearningData, isFetching } = useElearningByIdQuery(
    elearningId,
    !create && elearningId.length > 0
  );

  const formValues = useMemo(
    () => elearningData && mapToElearningFormModel(elearningData),
    [elearningData]
  );

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={ElearningListRoute}>Lista e-learningów</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        {create && <PageHeading>Nowy e-learning</PageHeading>}

        {!create && (
          <PageHeading>
            {isFetching ? (
              <SkeletonText heading={true} width="300px" />
            ) : (
              `${elearningData?.title}`
            )}
          </PageHeading>
        )}

        <Row>
          <Column lg={18} xlg={14}>
            {create && (
              <ElearningForm
                onSubmit={(values) =>
                  createMutation.mutate(mapToElearningCreateDTO(values))
                }
                isCreate={true}
                isSubmitting={createMutation.isLoading}
              />
            )}
            {!create && (
              <ElearningForm
                onSubmit={(values) =>
                  updateMutation.mutate(
                    mapToElearningUpdateDTO(values, elearningId)
                  )
                }
                formValues={elearningData ? formValues : undefined}
                isCreate={false}
                isElearningLoading={isFetching}
                isSubmitting={updateMutation.isLoading}
              />
            )}
          </Column>
        </Row>
      </Grid>
    </Content>
  );
};
