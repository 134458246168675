import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from 'app/components/ErrorMessage';
import {
  Button,
  ComboBox,
  FileUploaderDropContainer,
  FileUploaderItem,
  Form,
  FormLabel,
  InlineLoading,
  Row,
  TextInput,
} from 'carbon-components-react';
import { Stack } from 'common/components/Stack';
import { Category } from 'common/models/category';
import { StoredImageDTO } from 'common/models/image';
import { bannerSchema } from 'modules/banner/config/bannerSchema';
import {
  BannerFields,
  BannerStatus,
  BannerSubpage,
} from 'modules/banner/types.banner';
import { useLayoutEffect, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const ImageContainer = styled.div`
  background-color: rgba(245, 246, 247, 0.5);
  width: 100%;
  display: grid;
  place-content: center;
`;

const Image = styled.img`
  max-width: 100%;
  margin-bottom: 8px;
`;

export function BannerForm({
  defaultValues,
  onValidSubmit,
  create,
  options,
  isSubmitting,
}: {
  create: boolean;
  defaultValues: BannerFields;
  onValidSubmit: (values: BannerFields) => Promise<void>;
  isSubmitting: boolean;
  options: {
    subpages: BannerSubpage[];
    categories: Category[] | undefined;
  };
}) {
  const methods = useForm<BannerFields>({
    defaultValues,
    resolver: yupResolver(bannerSchema()),
  });

  const isCategoryPage = methods.watch('page') === BannerSubpage.CATEGORY;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onValidSubmit)}>
        <Row style={{ alignItems: 'flex-start' }}>
          <Stack lg={11}>
            <Controller
              name="upload"
              render={({ field, fieldState }) => {
                const value: File | StoredImageDTO | null = field.value;

                const isFile = value instanceof File;

                const url = useRef<string | null>(null);
                useLayoutEffect(() => {
                  url.current = isFile ? URL.createObjectURL(value) : null;

                  return () => {
                    url.current && URL.revokeObjectURL(url.current);
                  };
                }, [value]);

                if (!value) {
                  return (
                    <div>
                      <FormLabel htmlFor="upload">Grafika</FormLabel>

                      <FileUploaderDropContainer
                        id="upload"
                        accept={['image/jpeg', 'image/png']}
                        labelText="Kliknij lub przeciągnij grafikę"
                        name="upload"
                        onAddFiles={(e, { addedFiles }) => {
                          field.onChange(addedFiles.at(0));
                        }}
                      />

                      {fieldState.error && (
                        <ErrorMessage className="bx--form-requirement">
                          {fieldState.error.message}
                        </ErrorMessage>
                      )}
                    </div>
                  );
                }

                return (
                  <div>
                    <FormLabel htmlFor="upload">Grafika</FormLabel>

                    <ImageContainer>
                      <Image
                        src={isFile ? URL.createObjectURL(value) : value.url}
                      />
                    </ImageContainer>

                    <FileUploaderItem
                      name={isFile ? value.name : value.filename}
                      status="edit"
                      onDelete={() => field.onChange(null)}
                    />

                    {fieldState.error && (
                      <ErrorMessage className="bx--form-requirement">
                        {fieldState.error.message}
                      </ErrorMessage>
                    )}
                  </div>
                );
              }}
            />

            <Controller
              name="title"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  id="title"
                  labelText="Tytuł"
                  invalid={Boolean(fieldState.error)}
                  invalidText={fieldState.error?.message}
                />
              )}
            />

            <Controller
              name="redirectURL"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  id="redirectURL"
                  labelText="Przekierowanie"
                  invalid={Boolean(fieldState.error)}
                  invalidText={fieldState.error?.message}
                />
              )}
            />
          </Stack>

          <Stack lg={5}>
            <Controller
              name="displayStatus"
              render={({ field, fieldState }) => (
                <ComboBox<BannerStatus>
                  selectedItem={field.value || null}
                  onChange={({ selectedItem }) =>
                    field.onChange(selectedItem || '')
                  }
                  id="displayStatus"
                  titleText="Widoczność baneru"
                  placeholder="Wybierz status"
                  items={Object.values(BannerStatus)}
                  invalid={Boolean(fieldState.error)}
                  invalidText={fieldState.error?.message}
                  itemToString={(item) => {
                    if (!item) {
                      return '';
                    }
                    return {
                      [BannerStatus.HIDDEN]: 'Ukryty',
                      [BannerStatus.VISIBLE]: 'Widoczny',
                    }[item];
                  }}
                />
              )}
            />

            <Controller
              name="page"
              render={({ field, fieldState }) => (
                <ComboBox<BannerSubpage>
                  selectedItem={field.value || null}
                  onChange={({ selectedItem }) =>
                    field.onChange(selectedItem || '')
                  }
                  id="page"
                  titleText="Podstrona"
                  placeholder="Wybierz podstronę"
                  items={options.subpages ?? []}
                  invalid={Boolean(fieldState.error)}
                  invalidText={fieldState.error?.message}
                  itemToString={(item) => {
                    if (!item) {
                      return '';
                    }
                    return {
                      [BannerSubpage.CATEGORY]: 'Kategoria',
                      [BannerSubpage.COURSE_BUNDLE]: 'Kursy',
                      [BannerSubpage.E_LEARNING]: 'E-learning',
                      [BannerSubpage.ONLINE]: 'Szkolenia online',
                    }[item];
                  }}
                />
              )}
            />

            {isCategoryPage && (
              <Controller
                name="category"
                render={({ field, fieldState }) => (
                  <ComboBox<Category>
                    selectedItem={field.value || null}
                    onChange={({ selectedItem }) => {
                      console.log(selectedItem);
                      field.onChange(selectedItem || null);
                    }}
                    id="category"
                    titleText="Kategoria"
                    placeholder="Wybierz kategorię"
                    items={options.categories ?? []}
                    invalid={Boolean(fieldState.error)}
                    invalidText={fieldState.error?.message}
                  />
                )}
              />
            )}
          </Stack>
        </Row>

        <div style={{ margin: '2rem 0 0 0' }}>
          {isSubmitting ? (
            <Button type="submit" disabled>
              <InlineLoading description={'Zapisywanie...'} status="active" />
            </Button>
          ) : (
            <Button type="submit">
              {create ? 'Utwórz baner' : 'Zapisz zmiany'}
            </Button>
          )}
        </div>
      </Form>
    </FormProvider>
  );
}
