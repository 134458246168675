import { createReducer } from '@reduxjs/toolkit';
import { PaginationState, SortDirection } from 'common/models/pagination';
import { none, Option, some } from 'fp-ts/lib/Option';
import { courseEventActions } from 'state/courseEvent/courseEventActions';
import {
  CourseEventListColumn,
  CourseEventSortData,
  CourseEventStatus,
} from 'common/models/courseEvent';

export interface CourseEventState {
  courseId: string;
  pagination: Option<PaginationState>;
  sort: CourseEventSortData;
  status: Option<CourseEventStatus>;
}

export const defaultSort: CourseEventSortData = {
  direction: SortDirection.Asc,
  orderBy: CourseEventListColumn.StartDateTime,
};

const initialState: CourseEventState = {
  courseId: '',
  pagination: none,
  sort: defaultSort,
  status: none,
};

export const courseEventReducer = createReducer<CourseEventState>(
  initialState,
  (builder) =>
    builder
      .addCase(courseEventActions.clear, () => initialState)
      .addCase(courseEventActions.setCourseId, (state, action) => {
        state.courseId = action.payload || '';
      })
      .addCase(courseEventActions.setFilters, (state, action) => {
        state.courseId = action.payload.courseId || '';
        state.pagination = some(action.payload.pagination);
        state.sort = action.payload.sort;
        state.status = action.payload.status
          ? some(action.payload.status)
          : none;
      })
      .addCase(courseEventActions.setPagination, (state, action) => {
        state.pagination = some(action.payload);
      })
      .addCase(courseEventActions.setSort, (state, action) => {
        state.sort = action.payload;
      })
      .addCase(courseEventActions.setStatus, (state, action) => {
        state.status = action.payload ? some(action.payload) : none;
      })
);
