import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import { API } from '@editorjs/editorjs';
import { editorjsTools } from 'config/editorjs';
import { createReactEditorJS } from 'react-editor-js';
import { ErrorMessage } from 'app/components/ErrorMessage';
import {
  isBlocksError,
  isFieldError,
} from 'modules/static-pages/config/typeGuards';

const ReactEditorJS = createReactEditorJS();

const EditorContainer = styled.div`
  background: #fff;
  border-radius: 0.5rem;
  padding-top: 2rem;

  .codex-editor__loader {
    height: 5.25rem;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  padding: 0 1rem 1rem;
`;

export interface ContentEditorProps {
  name: string;
  tools?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const ContentEditor: FC<ContentEditorProps> = ({
  name,
  tools = editorjsTools,
}) => {
  const { control } = useFormContext();

  const editorId = useMemo(() => {
    return (Math.floor(Math.random() * 1000) + Date.now()).toString(36);
  }, []);

  return (
    <EditorContainer>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <ReactEditorJS
              defaultValue={value}
              minHeight={50}
              onChange={async (api: API) => {
                onChange(await api.saver.save());
              }}
              placeholder="Uzupełnij.."
              tools={tools}
              holder={`react-editor-js-${editorId}`}
            />

            {!!error && (
              <StyledErrorMessage className="bx--form-requirement">
                {isBlocksError(error) && error.blocks.message}
                {isFieldError(error) && error.message}
              </StyledErrorMessage>
            )}
          </>
        )}
      />
    </EditorContainer>
  );
};
