export const file = () => {
  return new File([''], 'filename');
};

export const convertFileToBase64 = (file: File): Promise<string | undefined> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const ab2str = (arrayBuffer: ArrayBuffer | null) => {
  const enc = new TextDecoder('utf-8');
  return arrayBuffer ? enc.decode(arrayBuffer) : undefined;
};

export const forceDownload = (url: string) => {
  const iframe = document.createElement('iframe');

  iframe.src = url;
  iframe.style.display = 'none';
  iframe.style.height = '0';

  document.body.appendChild(iframe);

  setTimeout(() => {
    iframe.remove();
  }, 5 * 1000);
};

export const saveData = (function () {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';

  return (fileName: string) => (data: string) => {
    const blob = new File([data], fileName, { type: 'octet/stream' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fileName;
    a.click();

    window.URL.revokeObjectURL(url);
  };
})();

export const getExtension = (filename: string) =>
  filename.substring(filename.lastIndexOf('.') + 1, filename.length) ||
  filename;
