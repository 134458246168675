import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';
import {
  FileUploaderProgress,
  FileUploaderStatus,
} from 'common/models/fileUploader';
import { reduce } from 'ramda';

export const fileUploaderStateSelector = (state: RootState) =>
  state.fileUploader;

export const fileUploaderListSelector = createSelector(
  fileUploaderStateSelector,
  (state) => state.list
);

export const fileUploaderMapSelector = createSelector(
  fileUploaderStateSelector,
  (state) => state.map
);

export const fileUploaderOpenSelector = createSelector(
  fileUploaderStateSelector,
  (state) => state.open
);

export const fileUploaderFinishedCountSelector = createSelector(
  fileUploaderListSelector,
  fileUploaderMapSelector,
  (list, map) =>
    list.filter((itemId) => map[itemId].status !== FileUploaderStatus.Uploading)
      .length
);

export const fileUploaderTotalCountSelector = createSelector(
  fileUploaderListSelector,
  (list) => list.length
);

export const fileUploaderOngoingCountSelector = createSelector(
  fileUploaderFinishedCountSelector,
  fileUploaderTotalCountSelector,
  (finished, total) => total - finished
);

export const fileUploaderOngoingProgressSelector = createSelector(
  fileUploaderListSelector,
  fileUploaderMapSelector,
  (list, map): FileUploaderProgress =>
    reduce(
      (acc, itemId) => {
        if (map[itemId].status !== FileUploaderStatus.Uploading) {
          return acc;
        }

        return {
          current: acc.current + map[itemId].progress.current,
          total: acc.total + map[itemId].progress.total,
        };
      },
      {
        current: 0,
        total: 0,
      },
      list
    )
);

export const createFileUploaderItemSelector = () =>
  createSelector(
    fileUploaderMapSelector,
    (rootState: RootState, itemId: string) => itemId,
    (map, itemId: string) => map[itemId]
  );
