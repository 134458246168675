import React, { FC, useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  ComboBox,
  DatePicker,
  DatePickerInput,
  Form,
  Grid,
  InlineLoading,
  InlineNotification,
  Row,
  SkeletonText,
  TextArea,
  TextAreaSkeleton,
  TextInput,
  TextInputSkeleton,
} from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import {
  CourseListRoute,
  getCourseEventListRoute,
  getCourseFormRoute,
  isCreateParam,
  RootRoute,
} from 'routes';
import { PageHeading } from 'common/components/Heading';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCourseByIdQuery } from 'common/hooks/course/useCourseByIdQuery';
import { Content } from 'common/components/Grid';
import { pipe } from 'fp-ts/function';
import {
  CourseEventAgendaItem,
  CourseEventFormModel,
  mapToCourseEventDTO,
  mapToCourseEventFormModel,
  mapToCourseEventRemarksDTO,
} from 'common/models/courseEvent';
import { useAllTrainersListQuery } from 'common/hooks/user/useAllTrainersListQuery';
import { Trainer } from 'common/models/trainer';
import { EventAgendaControl } from 'app/components/EventAgendaControl';
import { format, timePickerItems } from 'lib/date';
import { useCreateCourseEventMutation } from 'common/hooks/courseEvent/useCreateCourseEventMutation';
import { useUpdateCourseEventMutation } from 'common/hooks/courseEvent/useUpdateCourseEventMutation';
import { forEachObjIndexed } from 'ramda';
import { useCourseEventByIdQuery } from 'common/hooks/courseEvent/useCourseEventByIdQuery';
import { setYupDefaultMessages } from 'common/services/yup';
import { purgeWithout } from 'lib/purge';
import { FormItem } from 'app/components/FormItem';
import { spacing07 } from '@carbon/layout';
import styled from 'styled-components';
import { useUpdateCourseEventRemarksMutation } from 'common/hooks/courseEvent/useUpdateCourseEventRemarksMutation';
import { parseISO } from 'date-fns';
import { coerceUTCDateString } from 'lib/coerceUTCDateString';
import { NumberInput } from 'app/components/NumberInput';
import { ChangeEventImaginaryTarget } from 'lib/carbonExtraTypes';
import {
  fieldsOrder,
  formSchema,
  remarksSchema,
} from 'app/pages/CourseEventForm/config';
import { useScrollToError } from 'common/hooks/useScrollToError';

const StyledInlineNotification = styled(InlineNotification)`
  margin-bottom: ${spacing07};
`;

export interface CourseEventFormUrlParams {
  courseId: string;
  eventId: string;
}

setYupDefaultMessages();

export const CourseEventForm: FC = () => {
  const { courseId, eventId } = useParams<CourseEventFormUrlParams>();
  const [agendaValue, setAgendaValue] = useState<CourseEventAgendaItem[]>([]);

  const createMutation = useCreateCourseEventMutation(courseId);
  const updateMutation = useUpdateCourseEventMutation(courseId, eventId);
  const updateRemarksMutation = useUpdateCourseEventRemarksMutation(
    courseId,
    eventId
  );

  const { data: courseData, isFetching: isLoadingCourse } =
    useCourseByIdQuery(courseId);

  const { data: trainersData, isFetching: isLoadingTrainers } =
    useAllTrainersListQuery();

  const { data: eventData, isFetching: isLoadingCourseEvent } =
    useCourseEventByIdQuery(courseId, eventId, (data) => {
      const formModel = mapToCourseEventFormModel(data);

      forEachObjIndexed((value, key) => {
        setValue(key, value);
      }, formModel);

      setAgendaValue(formModel?.agenda);
    });

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CourseEventFormModel>({
    resolver: eventData?.webinarCreated
      ? yupResolver(remarksSchema)
      : yupResolver(formSchema),
    shouldFocusError: false,
  });

  const onError = useScrollToError(fieldsOrder);

  const onSubmit = handleSubmit((values) => {
    pipe(
      values,
      eventData?.webinarCreated
        ? mapToCourseEventRemarksDTO
        : mapToCourseEventDTO,
      purgeWithout(['remarks']),
      (a) => {
        if (eventData?.webinarCreated) {
          return updateRemarksMutation.mutate(a);
        }

        return isCreateParam(eventId)
          ? createMutation.mutate(a)
          : updateMutation.mutate(a);
      }
    );
  }, onError);

  const isLoading =
    isLoadingCourse || isLoadingTrainers || isLoadingCourseEvent;
  const isMutationLoading =
    createMutation.isLoading ||
    updateMutation.isLoading ||
    updateRemarksMutation.isLoading;

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={CourseListRoute}>Lista szkoleń</Link>
          </BreadcrumbItem>

          {courseData && (
            <BreadcrumbItem>
              <Link to={getCourseFormRoute(courseData.id)}>
                {courseData.title}
              </Link>
            </BreadcrumbItem>
          )}

          <BreadcrumbItem>
            <Link to={getCourseEventListRoute(courseId)}>Lista wydarzeń</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        {isCreateParam(eventId) && <PageHeading>Nowe wydarzenie</PageHeading>}

        {isLoadingCourseEvent && !isCreateParam(eventId) && (
          <SkeletonText heading={true} width="300px" />
        )}

        {!isLoadingCourseEvent && !isCreateParam(eventId) && (
          <PageHeading>
            {eventData
              ? format(
                  parseISO(coerceUTCDateString(eventData.startDateTime)),
                  'dd.MM.yyyy HH:mm'
                )
              : `Edycja wydarzenia`}
          </PageHeading>
        )}

        {eventData?.webinarCreated && (
          <StyledInlineNotification
            hideCloseButton
            kind="warning"
            subtitle="Możliwość edycji została ograniczona do pola 'Uwagi niestandardowe'."
            title="Webinar w zoomie został już utworzony."
          />
        )}

        <Form onSubmit={onSubmit}>
          <Row>
            <Column lg={8}>
              <FormItem>
                {isLoading ? (
                  <TextAreaSkeleton />
                ) : (
                  <TextArea
                    id="remarks"
                    invalid={!!errors.remarks}
                    invalidText={errors.remarks?.message}
                    labelText="Uwagi niestandardowe"
                    {...register('remarks')}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextAreaSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="agenda"
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <EventAgendaControl
                        disabled={eventData?.webinarCreated}
                        errors={error}
                        id="agenda"
                        invalid={!!error}
                        invalidText={error?.message}
                        label="Agenda"
                        onChange={(rows) => {
                          onChange(rows);
                        }}
                        value={agendaValue}
                      />
                    )}
                  />
                )}
              </FormItem>

              {isMutationLoading ? (
                <Button disabled kind="primary" tabIndex={-1}>
                  <InlineLoading
                    description={
                      isCreateParam(eventId) ? 'Dodawanie...' : 'Zapisywanie...'
                    }
                    status="active"
                  />
                </Button>
              ) : (
                <Button kind="primary" tabIndex={0} type="submit">
                  {isCreateParam(eventId) ? 'Dodaj' : 'Zapisz'}
                </Button>
              )}
            </Column>

            <Column lg={4}>
              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="duration"
                    render={({
                      field: { name, value, onChange },
                      fieldState: { error },
                    }) => (
                      <NumberInput
                        disabled={eventData?.webinarCreated}
                        helperText="w minutach"
                        id={name}
                        min={1}
                        name={name}
                        label="Czas trwania"
                        onChange={(
                          ev:
                            | React.ChangeEvent<HTMLInputElement>
                            | React.MouseEvent<HTMLButtonElement>
                        ) =>
                          onChange(
                            (ev as ChangeEventImaginaryTarget<HTMLInputElement>)
                              .imaginaryTarget.value
                          )
                        }
                        value={value ?? ''}
                        allowEmpty
                        invalid={!!error}
                        invalidText={error?.message}
                      />
                    )}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <TextInput
                    defaultValue="zoom"
                    disabled={eventData?.webinarCreated}
                    id="location"
                    invalid={!!errors.location}
                    invalidText={errors.location?.message}
                    labelText="Lokalizacja"
                    {...register('location')}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="trainer"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <ComboBox<Trainer>
                        disabled={eventData?.webinarCreated}
                        id="trainer"
                        invalid={!!error}
                        invalidText={error?.message}
                        items={trainersData ?? []}
                        itemToString={(item) =>
                          item ? `${item?.firstName} ${item?.lastName}` : ''
                        }
                        onChange={(value) => {
                          onChange(value.selectedItem?.id);
                        }}
                        placeholder=""
                        selectedItem={
                          trainersData?.find((item) => item.id === value) ??
                          null
                        }
                        titleText="Trener"
                      />
                    )}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <>
                    <Controller
                      control={control}
                      name="startDate"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <DatePicker
                          dateFormat="d.m.Y"
                          datePickerType="single"
                          locale="pl"
                          onChange={(newDate) => {
                            onChange(format(newDate[0], 'dd.LL.uuuu'));
                          }}
                          value={value}
                        >
                          <DatePickerInput
                            disabled={eventData?.webinarCreated}
                            id="startDate"
                            invalid={!!error}
                            invalidText={error?.message}
                            labelText="Data rozpoczęcia"
                            pattern=".*"
                            placeholder="dd.mm.yyyy"
                          />
                        </DatePicker>
                      )}
                    />
                  </>
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <>
                    <Controller
                      control={control}
                      name="startTime"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <ComboBox
                          disabled={eventData?.webinarCreated}
                          id="startTime"
                          invalid={!!error}
                          invalidText={error?.message}
                          items={timePickerItems}
                          onChange={(value) => {
                            onChange(value.selectedItem);
                          }}
                          placeholder="hh:mm"
                          selectedItem={
                            timePickerItems?.find((item) => item === value) ??
                            null
                          }
                          titleText="Czas rozpoczęcia"
                        />
                      )}
                    />
                  </>
                )}
              </FormItem>
            </Column>
          </Row>

          <input id="status" type="hidden" {...register('status')} />
        </Form>
      </Grid>
    </Content>
  );
};
