export enum TemplateAssetDataType {
  Base64Image = 'BASE64_IMAGE',
  Text = 'TEXT',
}

export interface TemplateAssetDTO {
  dataType: TemplateAssetDataType;
  value: string;
}

export interface CertificatesAssetsDTO {
  boardMemberName: TemplateAssetDTO;
  boardMemberSignature: TemplateAssetDTO;
  certificationNumber: TemplateAssetDTO;
  isoLogo: TemplateAssetDTO;
}

export interface CertificatesAssetsRequestDTO {
  boardMemberName: TemplateAssetDTO;
  boardMemberSignature?: TemplateAssetDTO;
  certificationNumber: TemplateAssetDTO;
  isoLogo?: TemplateAssetDTO;
}

export interface CertificatesAssetsFormModel {
  boardMemberName: string;
  boardMemberSignature?: File;
  boardMemberSignatureString?: string;
  certificationNumber: string;
  isoLogo?: File;
  isoLogoString?: string;
}

export const mapToCertificatesAssetsDTO = ({
  boardMemberName,
  boardMemberSignatureString,
  certificationNumber,
  isoLogoString,
}: CertificatesAssetsFormModel): CertificatesAssetsRequestDTO => ({
  boardMemberName: {
    dataType: TemplateAssetDataType.Text,
    value: boardMemberName,
  },
  boardMemberSignature: boardMemberSignatureString
    ? {
        dataType: TemplateAssetDataType.Base64Image,
        value: boardMemberSignatureString.split('base64,')[1],
      }
    : undefined,
  certificationNumber: {
    dataType: TemplateAssetDataType.Text,
    value: certificationNumber,
  },
  isoLogo: isoLogoString
    ? {
        dataType: TemplateAssetDataType.Base64Image,
        value: isoLogoString.split('base64,')[1],
      }
    : undefined,
});
