import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { QueryKey } from 'config/queryKey';
import { CategoryListRoute } from 'routes';
import { push } from 'connected-react-router';
import { getCategoryById } from 'common/services/category';
import { ID } from 'lib/id';

export function useCategoryByIdQuery(id: ID, enabled: boolean) {
  const dispatch = useDispatch();

  return useQuery([QueryKey.Category, id], () => getCategoryById(id), {
    enabled,
    onError: () => {
      dispatch(push(CategoryListRoute));
    },
  });
}
