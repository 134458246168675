import { chainNullableK, getOrElse, isSome } from 'fp-ts/lib/Option';
import { createSelector } from 'reselect';
import { AuthState } from 'state/auth/authReducer';
import { RootState } from 'state/reducer';
import { pipe } from 'fp-ts/function';

export const authStateSelector = (state: RootState) => state.auth;

export const tokenSelector = createSelector(
  authStateSelector,
  (state: AuthState) => state.token
);

export const isAuthReadySelector = createSelector(
  authStateSelector,
  (state: AuthState) => state.isAuthReady
);

export const isUserSomeSelector = createSelector(authStateSelector, (state) =>
  isSome(state.user)
);

export const userSelector = createSelector(
  authStateSelector,
  (state) => state.user
);

export const userFullNameSelector = createSelector(userSelector, (state) =>
  pipe(
    state,
    chainNullableK((user) => `${user.firstName} ${user.lastName}`),
    getOrElse(() => '')
  )
);
