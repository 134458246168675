import { AuthorizedAxios, throwErrorResponse } from 'common/services/axios';
import { flatten } from 'lib/axios';
import { ResultResponse } from 'common/models/response';
import { PaginationParams } from 'common/models/pagination';
import {
  Course,
  CourseListItem,
  CourseRequestDTO,
  CourseSortData,
} from 'common/models/course';
import { ID } from 'lib/id';

const prepareFormData = (params: CourseRequestDTO) => {
  const formData = new FormData();

  formData.set(
    'course',
    new Blob([JSON.stringify(params.course)], {
      type: 'application/json',
    })
  );

  if (params.image) {
    formData.set('image', params.image);
  }

  return formData;
};

export const createCourse = (params: CourseRequestDTO) =>
  AuthorizedAxios.post<Course>(`/api/courses`, prepareFormData(params), {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(flatten)
    .catch(throwErrorResponse);

export const deleteCourse = (id: string) =>
  AuthorizedAxios.delete<null>(`/api/courses/${id}`).catch(throwErrorResponse);

export const getCourseById = (id: string) =>
  AuthorizedAxios.get<Course>(`/api/courses/${id}`)
    .then(flatten)
    .catch(throwErrorResponse);

export const getCourseList = (
  { page, pageSize }: PaginationParams,
  courseSortData: CourseSortData,
  filters?: { speakerId?: number }
) =>
  AuthorizedAxios.post<ResultResponse<CourseListItem>>(
    '/api/courses/adminCriteria',
    {
      speakerId: filters?.speakerId,
      courseSortData,
      page: { page, pageSize },
    }
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const updateCourseById = (id: string, params: CourseRequestDTO) =>
  AuthorizedAxios.put<Course>(`/api/courses/${id}`, prepareFormData(params), {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(flatten)
    .catch(throwErrorResponse);

export const updateCourseCategory = (id: ID, categoryId: ID) =>
  AuthorizedAxios.put(`/api/courses/${id}/category`, { categoryId })
    .then(flatten)
    .catch(throwErrorResponse);

export const getCourseListByCategory = (categoryId: ID) =>
  AuthorizedAxios.get<ResultResponse<Course>>(
    `/api/courses/category/${categoryId}`,
    {
      params: { page: 1, pageSize: 1000 },
    }
  )
    .then(flatten)
    .catch(throwErrorResponse);
