import { FC, ReactElement } from 'react';
import {
  CarbonIconType,
  CircleFillGlyph,
  CircleStrokeGlyph,
  UndefinedGlyph,
} from '@carbon/icons-react';
import styled from 'styled-components';
import { g10 } from '@carbon/themes';
import { gray60, orange40, orange60 } from '@carbon/colors';

export enum ShapeStatusIndicatorKind {
  Draft = 'draft',
  MediumSeverity = 'mediumSeverity',
  Stable = 'stable',
}

export const shapeStatusIndicatorColorConfig: Record<
  ShapeStatusIndicatorKind,
  string
> = {
  [ShapeStatusIndicatorKind.Draft]: gray60,
  [ShapeStatusIndicatorKind.MediumSeverity]: orange40,
  [ShapeStatusIndicatorKind.Stable]: g10.support02,
};

export const shapeStatusIndicatorStrokeConfig: Record<
  ShapeStatusIndicatorKind,
  string
> = {
  [ShapeStatusIndicatorKind.Draft]: gray60,
  [ShapeStatusIndicatorKind.MediumSeverity]: orange60,
  [ShapeStatusIndicatorKind.Stable]: g10.support02,
};

const shapeStatusIndicatorKindConfig: Record<
  ShapeStatusIndicatorKind,
  ReactElement<CarbonIconType>
> = {
  [ShapeStatusIndicatorKind.Draft]: <CircleStrokeGlyph />,
  [ShapeStatusIndicatorKind.MediumSeverity]: <UndefinedGlyph />,
  [ShapeStatusIndicatorKind.Stable]: <CircleFillGlyph />,
};

const Container = styled.div<{ kind: ShapeStatusIndicatorKind }>`
  align-items: center;
  display: flex;

  svg {
    color: ${({ kind }) => shapeStatusIndicatorColorConfig[kind]};
    stroke: ${({ kind }) => shapeStatusIndicatorStrokeConfig[kind]};
  }
`;

const Label = styled.span`
  margin-left: 0.25rem;
`;

export interface ShapeStatusIndicatorProps {
  kind: ShapeStatusIndicatorKind;
  label: string;
}

export const ShapeStatusIndicator: FC<ShapeStatusIndicatorProps> = ({
  kind,
  label,
}) => {
  return (
    <Container kind={kind}>
      {shapeStatusIndicatorKindConfig[kind]}
      <Label>{label}</Label>
    </Container>
  );
};
