import { CmsAxios, throwErrorResponse } from 'common/services/axios';
import {
  StaticPageTos,
  StaticPageTosDTO,
} from 'modules/static-pages/models/staticPage';
import { flatten } from 'lib/axios';
import { StaticPageCode } from 'modules/static-pages/models/staticPageCode';
import {
  AboutUsStaticPage,
  AboutUsStaticPageDTO,
} from 'modules/static-pages/models/aboutUsStaticPage';
import { stringify } from 'qs';

const FILE_FIELDS = ['id', 'mime', 'name', 'size', 'url'];

const ABOUT_US_URL = `/about-us?${stringify(
  {
    populate: {
      additional: {
        fields: ['heading', 'id', 'subtitle', 'text'],
        populate: {
          image: {
            fields: FILE_FIELDS,
          },
        },
      },
      benefits: {
        fields: ['heading', 'id', 'subtitle'],
        populate: {
          list: {
            fields: ['id', 'text'],
            populate: {
              icon: {
                fields: FILE_FIELDS,
              },
            },
          },
        },
      },
      knowledge: {
        fields: ['heading', 'id', 'subtitle', 'text'],
        populate: {
          image: {
            fields: FILE_FIELDS,
          },
          list: {
            fields: ['id', 'text'],
          },
        },
      },
      possibilities: {
        fields: ['heading', 'id', 'subtitle', 'text'],
        populate: {
          list: {
            fields: ['id', 'text'],
          },
        },
      },
      strengths: {
        fields: ['heading', 'id', 'subtitle'],
        populate: {
          list: {
            fields: ['id', 'text', 'unit', 'value'],
          },
        },
      },
      whatIsPfp: {
        fields: ['heading', 'id', 'text'],
        populate: {
          image: {
            fields: FILE_FIELDS,
          },
        },
      },
    },
  },
  {
    encodeValuesOnly: true,
  }
)}`;

const prepareFormData = (params: StaticPageTosDTO) => {
  const formData = new FormData();

  formData.set('data', JSON.stringify(params.data));

  if (params.file) {
    formData.set('files.attachment.file', params.file);
  }

  return formData;
};

const prepareAboutUsFormData = (params: AboutUsStaticPageDTO) => {
  const formData = new FormData();

  formData.set('data', JSON.stringify(params.data));

  params.additionalImage &&
    formData.set('files.additional.image', params.additionalImage);
  params.benefitsImages &&
    params.benefitsImages.forEach(
      (image, index) =>
        image && formData.set(`files.benefits.list[${index}].icon`, image)
    );
  params.knowledgeImage &&
    formData.set('files.knowledge.image', params.knowledgeImage);
  params.whatIsPfpImage &&
    formData.set('files.whatIsPfp.image', params.whatIsPfpImage);

  return formData;
};

export const getStaticPage = (code: StaticPageCode) =>
  CmsAxios.get<StaticPageTos>(
    `/${code}?${stringify(
      {
        populate: {
          attachment: {
            populate: {
              file: {
                fields: FILE_FIELDS,
              },
            },
          },
          content: '*',
        },
      },
      {
        encodeValuesOnly: true,
      }
    )}`
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const getAboutUsStaticPage = () =>
  CmsAxios.get<AboutUsStaticPage>(ABOUT_US_URL)
    .then(flatten)
    .catch(throwErrorResponse);

export const updateStaticPage = (
  code: StaticPageCode,
  data: StaticPageTosDTO
) =>
  CmsAxios.put<StaticPageTos>(`/${code}`, prepareFormData(data))
    .then(flatten)
    .catch(throwErrorResponse);

export const updateAboutUsStaticPage = (data: AboutUsStaticPageDTO) =>
  CmsAxios.put<AboutUsStaticPage>(ABOUT_US_URL, prepareAboutUsFormData(data))
    .then(flatten)
    .catch(throwErrorResponse);
