import { AuthorizedAxios, throwErrorResponse } from 'common/services/axios';
import { flatten } from 'lib/axios';
import { ResultResponse } from 'common/models/response';
import { PaginationParams } from 'common/models/pagination';
import {
  CourseEvent,
  CourseEventDTO,
  CourseEventListFilters,
  CourseEventListItem,
  CourseEventRemarksDTO,
  CourseEventSortData,
} from 'common/models/courseEvent';

export const closeCourseEvent = (id: string) =>
  AuthorizedAxios.post<void>(`/api/events/${id}/close`).catch(
    throwErrorResponse
  );

export const createCourseEvent = (courseId: string, params: CourseEventDTO) => {
  return AuthorizedAxios.post<CourseEvent>(`/api/events`, {
    courseId: Number(courseId),
    ...params,
  }).catch(throwErrorResponse);
};

export const deleteCourseEvent = (id: string) =>
  AuthorizedAxios.delete<void>(`/api/events/${id}`).catch(throwErrorResponse);

export const getCourseEventById = (id: string) =>
  AuthorizedAxios.get<CourseEvent>(`/api/events/${id}/admin`)
    .then(flatten)
    .catch(throwErrorResponse);

export const getCourseEventList = (
  { page, pageSize }: PaginationParams,
  eventSortData: CourseEventSortData,
  filters?: CourseEventListFilters
) =>
  AuthorizedAxios.post<ResultResponse<CourseEventListItem>>(
    '/api/events/adminCriteria',
    {
      page: { page, pageSize },
      eventSortData,
      ...filters,
    }
  )
    .then(flatten)
    .catch(throwErrorResponse);

export const startCourseEvent = (id: string) =>
  AuthorizedAxios.post<void>(`/api/events/${id}/ongoing`).catch(
    throwErrorResponse
  );

export const updateCourseEventById = (
  courseId: string,
  eventId: string,
  params: CourseEventDTO
) =>
  AuthorizedAxios.put<CourseEvent>(`/api/events/${eventId}`, {
    courseId: Number(courseId),
    ...params,
  }).catch(throwErrorResponse);

export const updateCourseEventRemarksById = (
  eventId: string,
  params: CourseEventRemarksDTO
) =>
  AuthorizedAxios.patch<CourseEvent>(`/api/events/${eventId}`, params).catch(
    throwErrorResponse
  );
