import React, { FC } from 'react';
import {
  ProgressIndicatorSkeleton,
  StructuredListSkeleton,
} from 'carbon-components-react';

export const CategoryDeleteSkeleton: FC = () => (
  <>
    <ProgressIndicatorSkeleton />
    <StructuredListSkeleton />
  </>
);
