import { Controller, useFormContext } from 'react-hook-form';
import {
  Checkbox,
  TextInput,
  TileAboveTheFoldContent,
  TileBelowTheFoldContent,
} from 'carbon-components-react';
import React, { FC } from 'react';
import {
  QuestionControlType,
  ScaleSurveyQuestionControlModel,
} from 'app/components/SurveyFormPage/surveyQuestionModel';
import {
  ControlProps,
  FormItem,
  QuestionControlAccordion,
  QuestionControlAccordionItem,
} from 'app/components/SurveyFormPage/SurveyQuestionControl/common';
import { ConditionControl } from 'app/components/SurveyFormPage/SurveyQuestionControl/ConditionControl';
import { ExpandableTileBlock } from 'app/components/ExpandableTileBlock';
import { useControlErrors } from 'common/hooks/useControlErrors';
import styled from 'styled-components';
import { settings } from 'carbon-components';

const StyledExpandableTileBlock = styled(ExpandableTileBlock)`
  &.bx--tile--is-expanded .${settings.prefix}--tile-content__below-the-fold {
    // for some reason "auto" shows scroll when absolute positioned children bleeds out
    overflow: visible;
  }
`;

export const scaleSurveyQuestionDefaultValues: ScaleSurveyQuestionControlModel =
  {
    id: '',
    type: QuestionControlType.Scale,
    title: '',
    mandatory: true,
    minLabel: '',
    maxLabel: '',
    hasConditionalComment: false,
  };

export const ScaleSurveyQuestionControl: FC<
  ControlProps<ScaleSurveyQuestionControlModel>
> = ({ label, name, defaultValue }) => {
  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useFormContext();

  const questionTitle = watch(`${name}.title`);
  const hasConditionalComment = watch(`${name}.hasConditionalComment`);
  const controlErrors = useControlErrors(errors, name);

  return (
    <QuestionControlAccordion>
      <QuestionControlAccordionItem
        onKeyUp={(ev) => ev.stopPropagation()}
        open
        title={`${label}: ${questionTitle}`}
      >
        <FormItem>
          <div
            onKeyUp={(ev) => ev.stopPropagation()}
            onClick={(ev) => ev.stopPropagation()}
          >
            <TextInput
              id={`${name}.title`}
              {...register(`${name}.title`)}
              defaultValue={defaultValue?.title}
              invalid={!!controlErrors?.title}
              invalidText={controlErrors?.title?.message}
              labelText="Treść pytania"
            />
          </div>
        </FormItem>

        <FormItem>
          <TextInput
            id={`${name}.minLabel`}
            {...register(`${name}.minLabel`)}
            defaultValue={defaultValue?.minLabel}
            invalid={!!controlErrors?.minLabel}
            invalidText={controlErrors?.minLabel?.message}
            labelText="Etykieta wartość minimalna"
            placeholder="Bardzo źle"
          />
        </FormItem>

        <FormItem>
          <TextInput
            id={`${name}.maxLabel`}
            {...register(`${name}.maxLabel`)}
            defaultValue={defaultValue?.maxLabel}
            invalid={!!controlErrors?.maxLabel}
            invalidText={controlErrors?.maxLabel?.message}
            labelText="Etykieta wartość maksymalna"
            placeholder="Bardzo dobrze"
          />
        </FormItem>

        <StyledExpandableTileBlock
          isExpanded={hasConditionalComment}
          showChevron={false}
        >
          <TileAboveTheFoldContent>
            <FormItem>
              <Controller
                control={control}
                name={`${name}.hasConditionalComment`}
                render={({ field: { onChange, value, name } }) => (
                  <Checkbox
                    id={name}
                    labelText="Wyświetl pole do wprowadzenia dodatkowego komentarza"
                    checked={!!value}
                    onChange={onChange}
                  />
                )}
              />
            </FormItem>
          </TileAboveTheFoldContent>
          <TileBelowTheFoldContent>
            <FormItem>
              <ConditionControl
                name={`${name}.condition`}
                label="Warunek wyświetlenia dodatkowego komentarza"
                defaultValue={defaultValue?.condition}
                disabled={!hasConditionalComment}
              />
            </FormItem>
          </TileBelowTheFoldContent>
        </StyledExpandableTileBlock>
      </QuestionControlAccordionItem>
    </QuestionControlAccordion>
  );
};
