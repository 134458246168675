import React, { FC, useEffect, useMemo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  Grid,
  Pagination,
} from 'carbon-components-react';
import { Content } from 'common/components/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/lib/Option';
import { initialPagination, pageSizes } from 'common/models/pagination';
import { coerceUTCDateString } from 'lib/coerceUTCDateString';
import { parseISO } from 'date-fns';
import { CourseEventListDataTableContent } from 'app/components/CourseEventList/CourseEventListDataTableContent';
import {
  CourseEventStatus,
  courseEventStatusLabelMap,
  EventStatusListItem,
} from 'common/models/courseEvent';
import { isSome } from 'fp-ts/Option';
import {
  CourseBundleContentCategory,
  CourseBundleContentUrlParams,
  CourseBundleEventListColumn,
} from 'common/models/courseBundleContent';
import {
  courseBundleEventListCourseIdSelector,
  courseBundleEventListPaginationSelector,
  courseBundleEventListStatusSelector,
} from 'state/courseBundleContent/eventList/courseBundleEventListSelectors';
import { courseBundleEventListActions } from 'state/courseBundleContent/eventList/courseBundleEventListActions';
import { Link, useParams } from 'react-router-dom';
import { useCourseBundleEventListQuery } from 'common/hooks/courseBundle/useCourseBundleEventListQuery';
import { CourseBundleEventListTableContent } from 'app/components/CourseBundleContent/EventList/CourseBundleEventListTableContent';
import { CourseBundleListRoute, RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { CourseBundleContentSwitcher } from 'app/components/CourseBundleContent/ContentSwitcher';
import { courseBundleContentSwitcherActions } from 'state/courseBundleContent/contentSwitcher/contentSwitcherActions';

const headers = [
  {
    key: CourseBundleEventListColumn.Title,
    header: 'Nazwa',
  },
  {
    key: CourseBundleEventListColumn.StartDateTime,
    header: 'Data i godzina rozpoczęcia',
  },
  {
    key: CourseBundleEventListColumn.ParticipantsAccepted,
    header: 'Ilość zgłoszeń',
  },
  {
    key: CourseBundleEventListColumn.ParticipantsPresent,
    header: 'Ilość uczestników',
  },
  {
    key: CourseBundleEventListColumn.Status,
    header: 'Status',
  },
];

const prepareRow = (courseEvent: EventStatusListItem) => ({
  id: courseEvent.id.toString(),
  [CourseBundleEventListColumn.Title]: courseEvent.courseTitle,
  [CourseBundleEventListColumn.ParticipantsAccepted]:
    courseEvent.participantsAccepted,
  [CourseBundleEventListColumn.ParticipantsPresent]:
    courseEvent.participantsPresent,
  [CourseBundleEventListColumn.StartDateTime]: courseEvent.startDateTime
    ? parseISO(coerceUTCDateString(courseEvent.startDateTime))
    : null,
  [CourseBundleEventListColumn.Status]:
    courseEventStatusLabelMap[courseEvent.status],
});

export const CourseBundleEventList: FC = () => {
  const { courseBundleId } = useParams<CourseBundleContentUrlParams>();
  const dispatch = useDispatch();

  const courseId = useSelector(courseBundleEventListCourseIdSelector);
  const paginationOption = useSelector(courseBundleEventListPaginationSelector);
  const eventStatusOption = useSelector(courseBundleEventListStatusSelector);

  dispatch(
    courseBundleContentSwitcherActions.setCourseBundleContentCategory(
      CourseBundleContentCategory.EventList
    )
  );

  const eventStatus = useMemo(
    () =>
      pipe(
        eventStatusOption,
        getOrElse(() => 'undefined')
      ),
    [eventStatusOption]
  );

  const pagination = useMemo(
    () =>
      pipe(
        paginationOption,
        getOrElse(() => initialPagination)
      ),
    [paginationOption]
  );

  const { data, isFetching, isLoading } = useCourseBundleEventListQuery(
    courseBundleId,
    {
      courseId,
      status: isSome(eventStatusOption)
        ? (eventStatus as CourseEventStatus)
        : undefined,
    },
    {
      enabled: true,
      onSuccess: (data) => {
        if (data && data.result.length === 0 && pagination.page !== 1) {
          dispatch(
            courseBundleEventListActions.setPagination({
              page: Math.ceil(data.totalResult / pagination.pageSize),
              pageSize: pagination.pageSize,
            })
          );
        }
      },
    }
  );

  useEffect(() => {
    dispatch(courseBundleEventListActions.loadPagination());

    return () => {
      dispatch(courseBundleEventListActions.clear());
    };
  }, [dispatch]);

  const rows = useMemo(() => data?.result.map(prepareRow), [data]);

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={CourseBundleListRoute}>Lista kursów</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageHeading>Zawartość kursu</PageHeading>
        <CourseBundleContentSwitcher />

        {!rows && (
          <DataTableSkeleton headers={headers} rowCount={10}>
            {(props: DataTableCustomRenderProps) => (
              <CourseEventListDataTableContent {...props} />
            )}
          </DataTableSkeleton>
        )}

        {rows && !isLoading && (
          <DataTable headers={headers} overflowMenuOnHover={false} rows={rows}>
            {(props: DataTableCustomRenderProps) => (
              <CourseBundleEventListTableContent
                {...props}
                courseEventListItems={data?.result}
                isFetching={isFetching}
              />
            )}
          </DataTable>
        )}

        <Pagination
          onChange={(pagination) => {
            dispatch(
              courseBundleEventListActions.setPagination({
                page: pagination.page,
                pageSize: pagination.pageSize,
              })
            );
          }}
          page={pagination.page}
          pageSize={pagination.pageSize}
          pageSizes={pageSizes}
          totalItems={data?.totalResult ?? 0}
        />
      </Grid>
    </Content>
  );
};
