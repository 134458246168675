import { FC, useContext, useState } from 'react';
import {
  isChanged,
  isExistingFile,
  isExistingUrl,
  isInProgress,
  isNewFile,
  isNewUrl,
  isUploading,
  Material,
  MaterialType,
} from 'common/models/material';
import { Download20, Launch20, Upload20 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { MaterialsDeleteButton } from 'app/components/Materials/MaterialsDeleteButton';
import { MaterialsEditButton } from 'app/components/Materials/MaterialsEditButton';
import { MaterialsSaveUrlButton } from 'app/components/Materials/MaterialsSaveUrlButton';
import { MaterialsUpdateButton } from 'app/components/Materials/MaterialsUpdateButton';
import { MaterialsProgressBar } from 'app/components/Materials/MaterialsProgressBar';
import { useDownloadMaterialMutation } from 'common/hooks/materials/useDownloadMaterialMutation';
import { DataTableButton } from 'app/components/DataTableButton';
import { useCreateMaterialMutation } from 'common/hooks/materials/useCreateMaterialMutation';
import { useSelector } from 'react-redux';
import { materialsParentIdSelector } from 'state/materials/materialsSelectors';
import { MaterialsContext } from 'common/contexts/MaterialsContext';

export interface MaterialsActionsProps {
  material: Material;
}

export const MaterialsActions: FC<MaterialsActionsProps> = ({ material }) => {
  const { resource } = useContext(MaterialsContext);
  const parentId = useSelector(materialsParentIdSelector);

  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [uploadingTotal, setUploadingTotal] = useState(0);

  const createFileMutation = useCreateMaterialMutation(
    parentId,
    resource,
    material.id,
    (event) => {
      setUploadingProgress(event.loaded);
      setUploadingTotal(event.total);
    }
  );
  const downloadMutation = useDownloadMaterialMutation();

  if (isInProgress(material.status)) {
    return <></>;
  }

  if (isUploading(material.status)) {
    return (
      <MaterialsProgressBar
        progress={uploadingProgress}
        total={uploadingTotal}
      />
    );
  }

  return (
    <>
      {isNewFile(material.status, material.type) && (
        <DataTableButton
          hasIconOnly
          iconDescription="Wyślij"
          isLoading={false}
          kind="ghost"
          onClick={() =>
            createFileMutation.mutate({
              file: material.file,
              material: {
                name: material.name,
                type: MaterialType.Blob,
              },
            })
          }
          renderIcon={Upload20}
          size="small"
          tooltipAlignment="center"
          tooltipPosition="top"
        />
      )}

      {isNewUrl(material.status, material.type) && (
        <MaterialsSaveUrlButton material={material} />
      )}

      {isExistingFile(material.status, material.type) && (
        <DataTableButton
          hasIconOnly
          iconDescription="Pobierz"
          isLoading={downloadMutation.isLoading}
          kind="ghost"
          onClick={() => downloadMutation.mutate(material)}
          renderIcon={Download20}
          size="small"
          tooltipAlignment="center"
          tooltipPosition="top"
        />
      )}

      {isExistingUrl(material.status, material.type) && (
        <Button
          as="a"
          hasIconOnly
          href={material.url}
          iconDescription="Przejdź"
          kind="ghost"
          rel="noopener"
          renderIcon={Launch20}
          size="small"
          target="_blank"
          tooltipAlignment="center"
          tooltipPosition="top"
        />
      )}

      {isChanged(material.status) && (
        <MaterialsUpdateButton material={material} />
      )}

      <MaterialsEditButton material={material} />
      <MaterialsDeleteButton material={material} />
    </>
  );
};
