import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  DenormalizedRow,
  Grid,
  Pagination,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { RootRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarActions } from 'state/notification/notificationActions';
import { QueryKey } from 'config/queryKey';
import { useQueryClient } from 'react-query';
import { pipe } from 'fp-ts/function';
import {
  chainNullableK,
  fromNullable,
  getOrElse,
  map as mapO,
  match,
  some,
  toUndefined,
} from 'fp-ts/lib/Option';
import { initialPagination, pageSizes } from 'common/models/pagination';
import { Content } from 'common/components/Grid';
import {
  coursePaginationSelector,
  courseSpeakerSelector,
} from 'state/course/courseSelectors';
import { useCourseListQuery } from 'common/hooks/course/useCourseListQuery';
import { courseActions } from 'state/course/courseActions';
import { CourseListDataTableContent } from 'app/components/CourseList/CourseListDataTableContent';
import { coerceUTCDateString } from 'lib/coerceUTCDateString';
import { parseISO } from 'date-fns';
import { CourseListColumn, CourseListItem } from 'common/models/course';
import { useAllSpeakersListQuery } from 'common/hooks/speaker/useAllSpeakersListQuery';
import { find } from 'ramda';
import { Speaker } from 'common/models/speaker';

const headers = [
  {
    key: CourseListColumn.CourseTitle,
    header: 'Nazwa',
  },
  {
    key: CourseListColumn.CategoryName,
    header: 'Kategoria',
  },
  {
    key: CourseListColumn.Speaker,
    header: 'Prowadzący',
  },
  {
    key: CourseListColumn.StartDateTime,
    header: 'Najbliższe wydarzenie',
  },
];

const prepareCourseRow = (course: CourseListItem) => ({
  id: course.courseId.toString(),
  [CourseListColumn.CategoryName]: pipe(
    fromNullable(course.categories),
    chainNullableK((categories) =>
      categories.find((category) => category.main)
    ),
    match(() => fromNullable(course.categories[0]), some),
    mapO((category) => category.label),
    toUndefined
  ),
  [CourseListColumn.CourseTitle]: course.courseTitle,
  [CourseListColumn.Speaker]:
    course.speakers.length > 0
      ? course.speakers
          .map((speaker) => `${speaker.firstName} ${speaker.lastName}`)
          .join('<br />')
      : '',
  [CourseListColumn.StartDateTime]: course.startDateTime
    ? parseISO(coerceUTCDateString(course.startDateTime))
    : null,
});

export const CourseList: FC = () => {
  const dispatch = useDispatch();
  const paginationOption = useSelector(coursePaginationSelector);
  const speakerOption = useSelector(courseSpeakerSelector);
  const queryClient = useQueryClient();

  const pagination = useMemo(
    () =>
      pipe(
        paginationOption,
        getOrElse(() => initialPagination)
      ),
    [paginationOption]
  );

  const speakerId = useMemo(
    () =>
      pipe(
        speakerOption,
        getOrElse(() => 'undefined')
      ),
    [speakerOption]
  );

  const { data: speakersData, isFetching: isLoadingSpeakers } =
    useAllSpeakersListQuery({
      onSuccess: (data) => {
        if (
          data &&
          (data.result.length === 0 ||
            !find<Speaker>((item) => String(item.id) === speakerId)(
              data.result
            ))
        ) {
          dispatch(courseActions.setSpeakerId(undefined));
        }
      },
    });

  const {
    data,
    isFetching,
    isLoading: isLoadingCourses,
  } = useCourseListQuery({
    onSuccess: (data) => {
      if (data && data.result.length === 0 && pagination.page !== 1) {
        dispatch(
          courseActions.setPagination({
            page: Math.ceil(data.totalResult / pagination.pageSize),
            pageSize: pagination.pageSize,
          })
        );
      }
    },
  });

  const rows = useMemo(() => data?.result.map(prepareCourseRow), [data]);

  const handleDeleteSuccess = useCallback(
    (row: DenormalizedRow) => {
      dispatch(
        snackbarActions.enqueueSnackbar({
          options: { variant: 'success' },
          title: `Szkolenie ${row.cells[0].value} zostało usunięte`,
        })
      );
      queryClient.invalidateQueries(QueryKey.CourseList);
    },
    [data, dispatch, pagination]
  );

  useEffect(() => {
    dispatch(courseActions.loadPagination());

    return () => {
      dispatch(courseActions.clear());
    };
  }, [dispatch]);

  const isLoading = isLoadingSpeakers || isLoadingCourses;

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageHeading>Lista szkoleń</PageHeading>

        {!rows && (
          <DataTableSkeleton headers={headers} rowCount={10}>
            {(props: DataTableCustomRenderProps) => (
              <CourseListDataTableContent {...props} />
            )}
          </DataTableSkeleton>
        )}

        {rows && !isLoading && (
          <DataTable headers={headers} overflowMenuOnHover={false} rows={rows}>
            {(props: DataTableCustomRenderProps) => (
              <CourseListDataTableContent
                {...props}
                isFetching={isFetching}
                onDeleteSuccess={handleDeleteSuccess}
                speakers={speakersData?.result ?? []}
              />
            )}
          </DataTable>
        )}

        <Pagination
          onChange={(pagination) => {
            dispatch(
              courseActions.setPagination({
                page: pagination.page,
                pageSize: pagination.pageSize,
              })
            );
          }}
          page={pagination.page}
          pageSize={pagination.pageSize}
          pageSizes={pageSizes}
          totalItems={data?.totalResult ?? 0}
        />
      </Grid>
    </Content>
  );
};
