export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export interface PaginationParams {
  page: number;
  pageSize: number;
}

export interface PaginationState {
  page: number;
  pageSize: number;
}

export const initialPagination: PaginationState = {
  page: 1,
  pageSize: 10,
} as const;

export const pageSizes = [10, 50, 100] as const;
