import * as Dom from 'editorjs/nested-list/utils/dom';
import Caret from 'editorjs/nested-list/utils/caret';
import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools/block-tool';
import { API } from '@editorjs/editorjs';
import { ToolConfig } from '@editorjs/editorjs/types/tools/tool-config';
import { BlockToolData } from '@editorjs/editorjs/types/tools/block-tool-data';

export enum NestedListWrapperStyle {
  Alpha = 'alpha',
  NumberAlpha = 'number_alpha',
  Ordered = 'ordered',
  Unordered = 'unordered',
}

export interface NestedListItem {
  content: string;
  items: NestedListItem[];
}

export interface NestedListData {
  items: NestedListItem[];
  style: string;
}

// eslint-disable-next-line import/no-default-export
export default class NestedList {
  api: API;
  caret: Caret;
  config: ToolConfig;
  data: BlockToolData;
  defaultListStyle: string;
  nodes: Record<string, HTMLElement | null>;
  readOnly: boolean;
  settings: {
    default: boolean;
    icon: string;
    name: NestedListWrapperStyle;
    title: string;
  }[];
  wrapperStyleMapper = {
    [NestedListWrapperStyle.Alpha]: 'cdx-nested-list--alpha',
    [NestedListWrapperStyle.NumberAlpha]: 'cdx-nested-list--number_alpha',
    [NestedListWrapperStyle.Ordered]: 'cdx-nested-list--ordered',
    [NestedListWrapperStyle.Unordered]: 'cdx-nested-list--unordered',
  };

  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Sanitizer rules
   */
  static get sanitize() {
    return {
      br: true,
    };
  }

  static get toolbox() {
    return {
      icon: '<svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg"> <path d="M5.625 4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0-4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0 9.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm-4.5-5a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0-4.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0 9.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25z"/></svg>',
      title: 'List',
    };
  }

  constructor({ data, config, api, readOnly }: BlockToolConstructorOptions) {
    this.nodes = {
      wrapper: null,
    };

    this.api = api;
    this.readOnly = readOnly;
    this.config = config;

    this.settings = [
      {
        name: NestedListWrapperStyle.Unordered,
        title: this.api.i18n.t('Unordered'),
        icon: '<svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg"> <path d="M5.625 4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0-4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0 9.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm-4.5-5a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0-4.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0 9.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25z"/></svg>',
        default: false,
      },
      {
        name: NestedListWrapperStyle.Ordered,
        title: this.api.i18n.t('Ordered'),
        icon: '<svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg"><path d="M5.819 4.607h9.362a1.069 1.069 0 0 1 0 2.138H5.82a1.069 1.069 0 1 1 0-2.138zm0-4.607h9.362a1.069 1.069 0 0 1 0 2.138H5.82a1.069 1.069 0 1 1 0-2.138zm0 9.357h9.362a1.069 1.069 0 0 1 0 2.138H5.82a1.069 1.069 0 0 1 0-2.137zM1.468 4.155V1.33c-.554.404-.926.606-1.118.606a.338.338 0 0 1-.244-.104A.327.327 0 0 1 0 1.59c0-.107.035-.184.105-.234.07-.05.192-.114.369-.192.264-.118.475-.243.633-.373.158-.13.298-.276.42-.438a3.94 3.94 0 0 1 .238-.298C1.802.019 1.872 0 1.975 0c.115 0 .208.042.277.127.07.085.105.202.105.351v3.556c0 .416-.15.624-.448.624a.421.421 0 0 1-.32-.127c-.08-.085-.121-.21-.121-.376zm-.283 6.664h1.572c.156 0 .275.03.358.091a.294.294 0 0 1 .123.25.323.323 0 0 1-.098.238c-.065.065-.164.097-.296.097H.629a.494.494 0 0 1-.353-.119.372.372 0 0 1-.126-.28c0-.068.027-.16.081-.273a.977.977 0 0 1 .178-.268c.267-.264.507-.49.722-.678.215-.188.368-.312.46-.371.165-.11.302-.222.412-.334.109-.112.192-.226.25-.344a.786.786 0 0 0 .085-.345.6.6 0 0 0-.341-.553.75.75 0 0 0-.345-.08c-.263 0-.47.11-.62.329-.02.029-.054.107-.101.235a.966.966 0 0 1-.16.295c-.059.069-.145.103-.26.103a.348.348 0 0 1-.25-.094.34.34 0 0 1-.099-.258c0-.132.031-.27.093-.413.063-.143.155-.273.279-.39.123-.116.28-.21.47-.282.189-.072.411-.107.666-.107.307 0 .569.045.786.137a1.182 1.182 0 0 1 .618.623 1.18 1.18 0 0 1-.096 1.083 2.03 2.03 0 0 1-.378.457c-.128.11-.344.282-.646.517-.302.235-.509.417-.621.547a1.637 1.637 0 0 0-.148.187z"/></svg>',
        default: true,
      },
      {
        name: NestedListWrapperStyle.Alpha,
        title: this.api.i18n.t('Letters'),
        icon: '<svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg"><path d="M5.819 4.607h9.362a1.07 1.07 0 0 1 0 2.138H5.82a1.069 1.069 0 0 1 0-2.138h-.001Zm0-4.607h9.362a1.07 1.07 0 0 1 0 2.138H5.82A1.069 1.069 0 0 1 5.82 0h-.001Zm0 9.357h9.362a1.069 1.069 0 0 1 0 2.138H5.82a1.069 1.069 0 0 1 0-2.137l-.001-.001Z"/><path d="M2.3 11.621q-.27 0-.489-.123-.219-.123-.363-.327-.03.168-.09.294-.036.078-.096.102-.06.024-.174.024h-.054q-.294 0-.294-.174V7.229q0-.168.318-.168h.114q.318 0 .318.168v1.398q.126-.096.297-.162.171-.066.405-.066.576 0 .912.429.336.429.336 1.179 0 .504-.144.867t-.399.555q-.255.192-.597.192Zm-.204-.624q.282 0 .426-.258.144-.258.144-.744 0-.48-.159-.732t-.483-.252q-.156 0-.291.045-.135.045-.243.123v1.44q.12.162.273.27.153.108.333.108ZM1.261 3.707q-.468 0-.702-.243-.234-.243-.234-.639 0-.252.102-.456.102-.204.315-.351.213-.147.561-.222.348-.075.84-.081v-.228q0-.18-.123-.288-.123-.108-.411-.108-.234 0-.408.054-.174.054-.279.108-.105.054-.141.054-.054 0-.12-.084T.55 1.037Q.505.935.505.887q0-.096.15-.189t.411-.153q.261-.06.579-.06.438 0 .714.123.276.123.408.342.132.219.132.513v1.206q0 .288.018.453.018.165.036.246.018.081.018.129t-.066.081q-.066.033-.162.054-.096.021-.186.03-.09.009-.138.009-.09 0-.132-.069-.042-.069-.06-.168-.018-.099-.036-.177-.054.066-.174.174-.12.108-.306.192t-.45.084Zm.204-.606q.228 0 .399-.117t.279-.279v-.456q-.222 0-.411.024-.189.024-.336.078-.147.054-.225.153-.078.099-.078.249 0 .162.093.255t.279.093Z" style="white-space:pre"/></svg>',
        default: false,
      },
      {
        name: NestedListWrapperStyle.NumberAlpha,
        title: this.api.i18n.t('Number and letters'),
        icon: '<svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg"><path d="M5.819 4.607h9.362a1.07 1.07 0 0 1 0 2.138H5.82a1.069 1.069 0 0 1 0-2.138h-.001Zm0-4.607h9.362a1.07 1.07 0 0 1 0 2.138H5.82A1.069 1.069 0 0 1 5.82 0h-.001Zm0 9.357h9.362a1.069 1.069 0 0 1 0 2.138H5.82a1.069 1.069 0 0 1 0-2.137l-.001-.001ZM1.468 4.155V1.33c-.554.404-.926.606-1.118.606a.34.34 0 0 1-.244-.104A.327.327 0 0 1 0 1.59c0-.107.035-.184.105-.234.07-.05.192-.114.369-.192.264-.118.475-.243.633-.373a2.56 2.56 0 0 0 .42-.438c.074-.103.154-.203.238-.298.037-.036.107-.055.21-.055.115 0 .208.042.277.127.07.085.105.202.105.351v3.556c0 .416-.15.624-.448.624a.422.422 0 0 1-.32-.127c-.08-.085-.121-.21-.121-.376Z"/><path d="M1.43 10.592q0 .176.096.3t.304.124q.208 0 .48-.232v-.816l-.416.088q-.464.104-.464.536ZM3.27 9.16v1.592q0 .168.068.224.068.056.22.08l.2.016.072.272q-.432.072-1.08.272-.336-.2-.416-.512-.512.512-.84.512-.464 0-.744-.256-.28-.256-.28-.704 0-.448.336-.66.336-.212.968-.284l.536-.064v-.376q0-.424-.092-.632-.092-.208-.356-.208t-.472.104q-.048.168-.048.728L.846 9.4q-.232-.72-.24-.976.944-.432 1.408-.432.648 0 .952.284.304.284.304.884Z" style="white-space:pre"/></svg>',
        default: false,
      },
    ];

    this.defaultListStyle = 'ordered';

    const initialData = {
      style: this.defaultListStyle,
      items: [],
    };

    this.data = data && Object.keys(data).length ? data : initialData;

    this.caret = new Caret();
  }

  render() {
    this.nodes.wrapper = this.makeListWrapper(this.data.style, [
      this.CSS.baseBlock,
    ]);

    // fill with data
    if (this.data.items.length) {
      this.appendItems(this.data.items, this.nodes.wrapper);
    } else {
      this.appendItems(
        [
          {
            content: '',
            items: [],
          },
        ],
        this.nodes.wrapper
      );
    }

    if (!this.readOnly) {
      // detect keydown on the last item to escape List
      this.nodes.wrapper.addEventListener(
        'keydown',
        (event) => {
          switch (event.key) {
            case 'Enter':
              this.enterPressed(event);
              break;
            case 'Backspace':
              this.backspace(event);
              break;
            case 'Tab':
              if (event.shiftKey) {
                this.shiftTab(event);
              } else {
                this.addTab(event);
              }
              break;
          }
        },
        false
      );
    }

    return this.nodes.wrapper;
  }

  /**
   * Creates Block Tune allowing to change the list style
   *
   * @public
   * @returns {Element}
   */
  renderSettings() {
    const wrapper = Dom.make('div', [this.CSS.settingsWrapper], {});

    this.settings.forEach((item) => {
      const itemEl = Dom.make('div', this.CSS.settingsButton, {
        innerHTML: item.icon,
      });

      itemEl.addEventListener('click', () => {
        this.listStyle = item.name;

        if (!itemEl.parentNode) {
          return;
        }

        /**
         * Clear other buttons
         */
        const buttons = itemEl.parentNode.querySelectorAll(
          '.' + this.CSS.settingsButton
        );

        Array.from(buttons).forEach((button) =>
          button.classList.remove(this.CSS.settingsButtonActive)
        );

        /**
         * Mark active button
         */
        itemEl.classList.toggle(this.CSS.settingsButtonActive);
      });

      this.api.tooltip.onHover(itemEl, item.title, {
        placement: 'top',
        hidingDelay: 500,
      });

      if (this.data.style === item.name) {
        itemEl.classList.add(this.CSS.settingsButtonActive);
      }

      wrapper.appendChild(itemEl);
    });

    return wrapper;
  }

  /**
   * Renders children list
   *
   * @param {ListItem[]} items - items data to append
   * @param {Element} parentItem - where to append
   * @returns {void}
   */
  appendItems(items: NestedListItem[], parentItem: Element) {
    items.forEach((item) => {
      const itemEl = this.createItem(item.content, item.items);

      parentItem.appendChild(itemEl);
    });
  }

  /**
   * Renders the single item
   *
   * @param {string} content - item content to render
   * @param {ListItem[]} [items] - children
   * @returns {Element}
   */
  createItem(content: string, items: NestedListItem[] = []) {
    const itemWrapper = Dom.make('li', this.CSS.item);
    const itemBody = Dom.make('div', this.CSS.itemBody);
    const itemContent = Dom.make('div', this.CSS.itemContent, {
      innerHTML: content,
      contentEditable: !this.readOnly,
    });

    itemBody.appendChild(itemContent);
    itemWrapper.appendChild(itemBody);

    /**
     * Append children if we have some
     */
    if (items && items.length > 0) {
      this.addChildrenList(itemWrapper, items);
    }

    return itemWrapper;
  }

  /**
   * Extracts tool's data from the DOM
   *
   * @returns {ListData}
   */
  save() {
    /**
     * The method for recursive collecting of the child items
     *
     * @param {Element} parent - where to find items
     * @returns {ListItem[]}
     */
    const getItems = (parent: Element | null): NestedListItem[] => {
      if (!parent) {
        return [];
      }

      const children = Array.from(
        parent.querySelectorAll(`:scope > .${this.CSS.item}`)
      );

      return children.map((el) => {
        const subItemsWrapper = el.querySelector(`.${this.CSS.itemChildren}`);
        const content = this.getItemContent(el);
        const subItems = subItemsWrapper ? getItems(subItemsWrapper) : [];

        return {
          content,
          items: subItems,
        };
      });
    };

    return {
      style: this.data.style,
      items: getItems(this.nodes.wrapper),
    };
  }

  /**
   * Append children list to passed item
   *
   * @param {Element} parentItem - item that should contain passed sub-items
   * @param {ListItem[]} items - sub items to append
   */
  addChildrenList(parentItem: Element, items: NestedListItem[]) {
    const itemBody = parentItem.querySelector(`.${this.CSS.itemBody}`);
    const sublistWrapper = this.makeListWrapper(undefined, [
      this.CSS.itemChildren,
    ]);

    this.appendItems(items, sublistWrapper);

    itemBody?.appendChild(sublistWrapper);
  }

  /**
   * Creates main <ul> or <ol> tag depended on style
   *
   * @param {string} [style] - 'ordered' or 'unordered'
   * @param {string[]} [classes] - additional classes to append
   * @returns {HTMLOListElement|HTMLUListElement}
   */
  makeListWrapper(style: string = this.listStyle, classes: string[] = []) {
    let tag: string;
    let styleClass: string;

    switch (style) {
      case NestedListWrapperStyle.Unordered:
        tag = 'ul';
        styleClass = this.CSS.wrapperUnordered;
        break;
      case NestedListWrapperStyle.Alpha:
        tag = 'ol';
        styleClass = 'cdx-nested-list--alpha';
        break;
      case NestedListWrapperStyle.NumberAlpha:
        tag = 'ol';
        styleClass = 'cdx-nested-list--number_alpha';
        break;
      default:
        tag = 'ol';
        styleClass = this.CSS.wrapperOrdered;
    }

    classes.push(styleClass);

    return Dom.make(tag, [this.CSS.wrapper, ...classes]);
  }

  /**
   * Styles
   *
   * @returns {object} - CSS classes names by keys
   *
   * @private
   */
  get CSS() {
    return {
      baseBlock: this.api.styles.block,
      wrapper: 'cdx-nested-list',
      wrapperOrdered: this.wrapperStyleMapper[NestedListWrapperStyle.Ordered],
      wrapperUnordered:
        this.wrapperStyleMapper[NestedListWrapperStyle.Unordered],
      wrapperAlpha: this.wrapperStyleMapper[NestedListWrapperStyle.Alpha],
      wrapperNumberAlpha:
        this.wrapperStyleMapper[NestedListWrapperStyle.NumberAlpha],
      item: 'cdx-nested-list__item',
      itemBody: 'cdx-nested-list__item-body',
      itemContent: 'cdx-nested-list__item-content',
      itemChildren: 'cdx-nested-list__item-children',
      settingsWrapper: 'cdx-nested-list__settings',
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
    };
  }

  /**
   * Get list style name
   *
   * @returns {string}
   */
  get listStyle() {
    return this.data.style || this.defaultListStyle;
  }

  /**
   * Set list style
   *
   * @param {string} style - new style to set
   */
  set listStyle(style: NestedListWrapperStyle) {
    if (!this.nodes.wrapper) {
      return;
    }

    /**
     * Get lists elements
     *
     * @type {any[]}
     */
    const lists = Array.from(
      this.nodes.wrapper.querySelectorAll(`.${this.CSS.wrapper}`)
    );

    /**
     * Add main wrapper to the list
     */
    lists.push(this.nodes.wrapper);

    /**
     * For each list we need to update classes
     */
    lists.forEach((list) => {
      list.classList.remove(
        this.CSS.wrapperAlpha,
        this.CSS.wrapperNumberAlpha,
        this.CSS.wrapperOrdered,
        this.CSS.wrapperUnordered
      );
      list.classList.add(this.wrapperStyleMapper[style]);
    });

    this.data.style = style;
  }

  /**
   * Returns current List item by the caret position
   *
   * @returns {Element}
   */
  get currentItem(): Element | null {
    let currentNode = window.getSelection()?.anchorNode;

    if (!currentNode) {
      return null;
    }

    if (currentNode.nodeType !== Node.ELEMENT_NODE) {
      currentNode = currentNode.parentNode;
    }

    return (currentNode as Element).closest(`.${this.CSS.item}`);
  }

  enterPressed(event: KeyboardEvent) {
    const currentItem = this.currentItem;

    /**
     * Prevent editor.js behaviour
     */
    event.stopPropagation();

    /**
     * Prevent browser behaviour
     */
    event.preventDefault();

    if (event.shiftKey) {
      const brElement = Dom.make('br');

      Caret.range?.deleteContents();
      Caret.range?.insertNode(brElement);

      Caret.range?.setStartAfter(brElement);
      Caret.range?.setEndAfter(brElement);

      return;
    }

    /**
     * On Enter in the last empty item, get out of list
     */
    const isEmpty = this.getItemContent(currentItem).trim().length === 0;
    const isFirstLevelItem = currentItem?.parentNode === this.nodes.wrapper;
    const isLastItem = currentItem?.nextElementSibling === null;

    if (isFirstLevelItem && isLastItem && isEmpty) {
      this.getOutOfList();

      return;
    } else if (isLastItem && isEmpty) {
      this.unshiftItem();

      return;
    }

    /**
     * On other Enters, get content from caret till the end of the block
     * And move it to the new item
     */
    const endingFragment = Caret.extractFragmentFromCaretPositionTillTheEnd();
    const endingHTML = Dom.fragmentToString(endingFragment);
    const itemChildren = currentItem?.querySelector(
      `.${this.CSS.itemChildren}`
    );

    /**
     * Create the new list item
     */
    const itemEl = this.createItem(endingHTML, undefined);

    /**
     * Check if child items exist
     *
     * @type {boolean}
     */
    const childrenExist =
      itemChildren &&
      Array.from(itemChildren.querySelectorAll(`.${this.CSS.item}`)).length > 0;

    /**
     * If item has children, prepend to them
     * Otherwise, insert the new item after current
     */
    if (childrenExist) {
      itemChildren?.prepend(itemEl);
    } else {
      currentItem?.after(itemEl);
    }

    this.focusItem(itemEl);
  }

  /**
   * Decrease indentation of the current item
   *
   * @returns {void}
   */
  unshiftItem() {
    const currentItem = this.currentItem;

    if (!currentItem) {
      return;
    }

    const parentItem = (currentItem.parentNode as Element | undefined)?.closest(
      `.${this.CSS.item}`
    );

    /**
     * If item in the first-level list then no need to do anything
     */
    if (!parentItem) {
      return;
    }

    this.caret.save();

    parentItem.after(currentItem);

    this.caret.restore();

    /**
     * If previous parent's children list is now empty, remove it.
     */
    const prevParentChildrenList = parentItem.querySelector(
      `.${this.CSS.itemChildren}`
    );
    const isPrevParentChildrenEmpty =
      prevParentChildrenList?.children.length === 0;

    if (isPrevParentChildrenEmpty) {
      prevParentChildrenList?.remove();
    }
  }

  /**
   * Return the item content
   *
   * @param {Element} item - item wrapper (<li>)
   * @returns {string}
   */
  getItemContent(item: Element | null): string {
    const contentNode = item?.querySelector(`.${this.CSS.itemContent}`);

    if (Dom.isEmpty(contentNode)) {
      return '';
    }

    return contentNode?.innerHTML ?? '';
  }

  /**
   * Sets focus to the item's content
   *
   * @param {Element} item - item (<li>) to select
   * @param {boolean} atStart - where to set focus: at the start or at the end
   * @returns {void}
   */
  focusItem(item: Element, atStart = true) {
    const itemContent = item.querySelector(`.${this.CSS.itemContent}`);

    if (!itemContent) {
      return;
    }

    Caret.focus(itemContent as HTMLElement, atStart);
  }

  /**
   * Get out from List Tool by Enter on the empty last item
   *
   * @returns {void}
   */
  getOutOfList() {
    this.currentItem?.remove();

    this.api.blocks.insert();
    this.api.caret.setToBlock(this.api.blocks.getCurrentBlockIndex());
  }

  /**
   * Handle backspace
   *
   * @param {KeyboardEvent} event - keydown
   */
  backspace(event: KeyboardEvent) {
    /**
     * Caret is not at start of the item
     * Then backspace button should remove letter as usual
     */
    if (!Caret.isAtStart()) {
      return;
    }

    /**
     * Prevent default backspace behaviour
     */
    event.preventDefault();

    const currentItem = this.currentItem;

    if (!currentItem) {
      return;
    }

    const previousItem = currentItem.previousSibling;
    const parentItem = (currentItem.parentNode as Element | undefined)?.closest(
      `.${this.CSS.item}`
    );

    /**
     * Do nothing with the first item in the first-level list.
     * No previous sibling means that this is the first item in the list.
     * No parent item means that this is a first-level list.
     *
     * Before:
     * 1. |Hello
     * 2. World!
     *
     * After:
     * 1. |Hello
     * 2. World!
     *
     * If it this item and the while list is empty then editor.js should
     * process this behaviour and remove the block completely
     *
     * Before:
     * 1. |
     *
     * After: block has been removed
     *
     */
    if (!previousItem && !parentItem) {
      return;
    }

    /**
     * Prevent editor.js behaviour
     */
    event.stopPropagation();

    /**
     * Lets compute the item which will be merged with current item text
     */
    let targetItem: Element;

    /**
     * If there is a previous item then we get a deepest item in its sublists
     *
     * Otherwise we will use the parent item
     */
    if (previousItem) {
      const childrenOfPreviousItem = (previousItem as Element).querySelectorAll(
        `.${this.CSS.item}`
      );

      targetItem =
        (Array.from(childrenOfPreviousItem).pop() as Element) || previousItem;
    } else {
      targetItem = parentItem as Element;
    }

    /**
     * Get content from caret till the end of the block to move it to the new item
     */
    const endingFragment = Caret.extractFragmentFromCaretPositionTillTheEnd();
    const endingHTML = Dom.fragmentToString(endingFragment);

    /**
     * Get the target item content element
     */
    const targetItemContent = targetItem.querySelector(
      `.${this.CSS.itemContent}`
    );

    if (!targetItemContent) {
      return;
    }

    /**
     * Set a new place for caret
     */
    Caret.focus(targetItemContent, false);

    /**
     * Save the caret position
     */
    this.caret.save();

    /**
     * Update target item content by merging with current item html content
     */
    targetItemContent.insertAdjacentHTML('beforeend', endingHTML);

    /**
     * Get the sublist first-level items for current item
     */
    const currentItemSublistItems = currentItem.querySelectorAll(
      `.${this.CSS.itemChildren} > .${this.CSS.item}`
    );

    /**
     * Create an array from current item sublist items
     */
    const currentItemSublistItemsArray = Array.from(currentItemSublistItems);

    /**
     * Filter items for sublist first-level
     * No need to move deeper items
     */
    const currentItemSublistItemsFiltered = currentItemSublistItemsArray.filter(
      (node) =>
        (node?.parentNode as HTMLElement)?.closest(`.${this.CSS.item}`) ===
        currentItem
    );

    /**
     * Reverse the array to insert items
     */
    currentItemSublistItemsFiltered.reverse().forEach((item) => {
      /**
       * Check if we need to save the indent for current item children
       *
       * If this is the first item in the list then place its children to the same level as currentItem.
       * Same as shift+tab for all of these children.
       *
       * If there is a previous sibling then place children right after target item
       */
      if (!previousItem) {
        /**
         * The first item in the list
         *
         * Before:
         * 1. Hello
         *   1.1. |My
         *     1.1.1. Wonderful
         *     1.1.2. World
         *
         * After:
         * 1. Hello|My
         *   1.1. Wonderful
         *   1.2. World
         */
        currentItem.after(item);
      } else {
        /**
         * Not the first item
         *
         * Before:
         * 1. Hello
         *   1.1. My
         *   1.2. |Dear
         *     1.2.1. Wonderful
         *     1.2.2. World
         *
         * After:
         * 1. Hello
         *   1.1. My|Dear
         *   1.2. Wonderful
         *   1.3. World
         */
        targetItem.after(item);
      }
    });

    /**
     * Remove current item element
     */
    currentItem.remove();

    /**
     * Restore the caret position
     */
    this.caret.restore();
  }

  /**
   * Add indentation to current item
   *
   * @param {KeyboardEvent} event - keydown
   */
  addTab(event: KeyboardEvent) {
    /**
     * Prevent editor.js behaviour
     */
    event.stopPropagation();

    /**
     * Prevent browser tab behaviour
     */
    event.preventDefault();

    const currentItem = this.currentItem;

    if (!currentItem) {
      return;
    }

    const prevItem = currentItem.previousSibling;
    const isFirstChild = !prevItem;

    /**
     * In the first item we should not handle Tabs (because there is no parent item above)
     */
    if (isFirstChild) {
      return;
    }

    const prevItemChildrenList = (prevItem as HTMLElement)?.querySelector(
      `.${this.CSS.itemChildren}`
    );

    this.caret.save();

    /**
     * If prev item has child items, just append current to them
     */
    if (prevItemChildrenList) {
      prevItemChildrenList.appendChild(currentItem);
    } else {
      /**
       * If prev item has no child items
       * - Create and append children wrapper to the previous item
       * - Append current item to it
       */
      const sublistWrapper = this.makeListWrapper(undefined, [
        this.CSS.itemChildren,
      ]);
      const prevItemBody = (prevItem as HTMLElement)?.querySelector(
        `.${this.CSS.itemBody}`
      );

      sublistWrapper.appendChild(currentItem);
      prevItemBody?.appendChild(sublistWrapper);
    }

    this.caret.restore();
  }

  /**
   * Reduce indentation for current item
   *
   * @param {KeyboardEvent} event - keydown
   * @returns {void}
   */
  shiftTab(event: KeyboardEvent) {
    /**
     * Prevent editor.js behaviour
     */
    event.stopPropagation();

    /**
     * Prevent browser tab behaviour
     */
    event.preventDefault();

    /**
     * Move item from current list to parent list
     */
    this.unshiftItem();
  }

  /**
   * Convert from list to text for conversionConfig
   * @param {ListData} data
   * @returns {string}
   */
  static joinRecursive(items: NestedListItem[]): string {
    return items
      .map((item) => `${item.content} ${NestedList.joinRecursive(item.items)}`)
      .join('');
  }

  /**
   * Convert from text to list with import and export list to text
   */
  static get conversionConfig() {
    return {
      export: (data: NestedListData) => {
        return NestedList.joinRecursive(data.items);
      },
      import: (content: string) => {
        return {
          items: [
            {
              content,
              items: [],
            },
          ],
          style: 'unordered',
        };
      },
    };
  }
}
