import { createAction } from 'lib/createAction';
import { createAction as toolkitCreateAction } from '@reduxjs/toolkit';
import { OptionsObject, SnackbarMessage } from 'notistack';
import { WritableDraft } from 'immer/dist/types/types-external';

export const snackbarActions = {
  enqueueSnackbar: toolkitCreateAction(
    'notification/snackbar/enqueue',
    ({
      key,
      options,
      subtitle,
      title,
    }: {
      key?: string;
      options?: WritableDraft<OptionsObject>;
      subtitle?: SnackbarMessage;
      title: SnackbarMessage;
    }) => ({
      payload: {
        key: key ?? `${new Date().getTime() + Math.random()}`,
        options,
        subtitle,
        title,
      },
    })
  ),
  closeSnackbar: createAction('notification/snackbar/close')<{
    key: string;
  }>(),
  closeAllSnackbars: createAction('notification/snackbar/closeAll')(),
  removeSnackbar: createAction('notification/snackbar/remove')<{
    key: string;
  }>(),
};
