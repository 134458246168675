import { useMutation, useQueryClient } from 'react-query';
import { setCourseEventParticipantStatus } from 'common/services/courseEventParticipant';
import { QueryKey } from 'config/queryKey';
import { CourseEventParticipantEventStatus } from 'common/models/courseEventParticipant';

export const useCourseEventParticipantStatusMutation = (
  eventId: string,
  status:
    | CourseEventParticipantEventStatus.Accepted
    | CourseEventParticipantEventStatus.Rejected
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (userId: string) =>
      setCourseEventParticipantStatus(eventId, userId, status),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryKey.CourseEventParticipantList,
          eventId,
        ]);
      },
    }
  );
};
