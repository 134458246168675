import {
  MultiOptionSurveyQuestionControlModel,
  OptionSurveyQuestionControlModel,
  QuestionControlType,
  ScaleSurveyQuestionControlModel,
  SurveyQuestionControlModel,
} from 'app/components/SurveyFormPage/surveyQuestionModel';
import { QuestionDTO, QuestionType, SurveyDTO } from 'common/models/survey';
import { purge } from 'lib/purge';
import { mergeAll } from 'ramda';
import { scaleSurveyQuestionDefaultValues } from 'app/components/SurveyFormPage/SurveyQuestionControl/ScaleSurveyQuestionControl';
import { optionSurveyQuestionDefaultValue } from 'app/components/SurveyFormPage/SurveyQuestionControl/OptionSurveyQuestionControl';
import { multiOptionSurveyQuestionDefaultValue } from 'app/components/SurveyFormPage/SurveyQuestionControl/MultiOptionSurveyQuestionControl';

export interface SurveyFormModel {
  id?: string;
  title: string;
  questions: SurveyQuestionControlModel[];
}

const mapToScaleSurveyQuestion = ({
  id,
  title,
  minLabel,
  maxLabel,
  hasConditionalComment,
  condition,
}: ScaleSurveyQuestionControlModel): QuestionDTO => ({
  id: id ? parseInt(id) : undefined,
  questionType: QuestionType.Scale,
  text: title,
  condition: hasConditionalComment ? condition : undefined,
  minLabel,
  maxLabel,
  customOptionAllowed: false,
  mandatory: true,
});

const mapToOptionSurveyQuestion = ({
  id,
  title,
  choiceOptions,
}: OptionSurveyQuestionControlModel): QuestionDTO => ({
  id: id ? parseInt(id) : undefined,
  questionType: QuestionType.Option,
  text: title,
  choiceOptions,
  mandatory: true,
  customOptionAllowed: false,
});

const mapToMultiOptionSurveyQuestion = ({
  id,
  title,
  choiceOptions,
  customOptionAllowed,
  customOptionLabel,
  customOptionPlaceholder,
}: MultiOptionSurveyQuestionControlModel): QuestionDTO => ({
  id: id ? parseInt(id) : undefined,
  questionType: QuestionType.MultiOption,
  mandatory: true,
  text: title,
  choiceOptions,
  customOptionAllowed: customOptionAllowed ?? false,
  customOptionLabel: customOptionAllowed ? customOptionLabel : undefined,
  customOptionPlaceholder: customOptionAllowed
    ? customOptionPlaceholder
    : undefined,
});

const mapQuestionType = (question: SurveyQuestionControlModel) => {
  switch (question.type) {
    case QuestionControlType.Scale:
      return mapToScaleSurveyQuestion(question);
    case QuestionControlType.Option:
      return mapToOptionSurveyQuestion(question);
    case QuestionControlType.MultiOption:
      return mapToMultiOptionSurveyQuestion(question);
  }
};

export const mapToSurveyDTO = ({
  id,
  title,
  questions,
}: SurveyFormModel): SurveyDTO =>
  purge({
    id: id ? parseInt(id) : undefined,
    title,
    questions: questions.map(mapQuestionType),
  });

const mapToScaleSurveyQuestionControlModel = ({
  id,
  condition,
  text,
  maxLabel,
  minLabel,
}: QuestionDTO): ScaleSurveyQuestionControlModel => {
  const values: ScaleSurveyQuestionControlModel = {
    id: id?.toString(),
    type: QuestionControlType.Scale,
    title: text,
    condition,
    hasConditionalComment: !!condition,
    mandatory: true,
    maxLabel,
    minLabel,
  };

  return mergeAll([scaleSurveyQuestionDefaultValues, values]);
};

const mapToOptionSurveyQuestionControlModel = ({
  id,
  text,
  choiceOptions,
}: QuestionDTO): OptionSurveyQuestionControlModel => {
  const values: OptionSurveyQuestionControlModel = {
    id: id?.toString(),
    type: QuestionControlType.Option,
    mandatory: true,
    title: text,
    choiceOptions: choiceOptions ?? [],
  };

  return mergeAll([optionSurveyQuestionDefaultValue, values]);
};

const mapToMultiOptionSurveyQuestionControlModel = ({
  id,
  text,
  choiceOptions,
  customOptionAllowed,
  customOptionLabel,
  customOptionPlaceholder,
}: QuestionDTO): MultiOptionSurveyQuestionControlModel => {
  const values: MultiOptionSurveyQuestionControlModel = {
    id: id?.toString(),
    type: QuestionControlType.MultiOption,
    mandatory: true,
    title: text,
    choiceOptions: choiceOptions ?? [],
    customOptionAllowed,
    customOptionLabel,
    customOptionPlaceholder,
  };

  return mergeAll([multiOptionSurveyQuestionDefaultValue, values]);
};

const mapToSurveyQuestionControlModel = (question: QuestionDTO) => {
  switch (question.questionType) {
    case QuestionType.Scale:
      return mapToScaleSurveyQuestionControlModel(question);
    case QuestionType.Option:
      return mapToOptionSurveyQuestionControlModel(question);
    case QuestionType.MultiOption:
      return mapToMultiOptionSurveyQuestionControlModel(question);
  }
};

export const mapToSurveyFormModel = ({
  id,
  title,
  questions,
}: SurveyDTO): SurveyFormModel => ({
  id: id?.toString(),
  title,
  questions: questions.map(mapToSurveyQuestionControlModel),
});
