import { label01 } from '@carbon/type';
import { g10 } from '@carbon/themes';
import { UnorderedList } from 'carbon-components-react';
import React, { FC } from 'react';
import styled from 'styled-components';

export interface ErrorsListProps {
  errors: string[];
}

const StyledUnorderedList = styled(UnorderedList)`
  list-style: none;
  padding: 0.5rem 1rem;
`;

const ErrorsListItem = styled.li`
  color: ${g10.danger02};
  ${label01}
`;

export const ErrorsList: FC<ErrorsListProps> = ({ errors }) => {
  return (
    <StyledUnorderedList>
      {errors.map((x) => (
        <ErrorsListItem key={x}>{x}</ErrorsListItem>
      ))}
    </StyledUnorderedList>
  );
};
