import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { QueryKey } from 'config/queryKey';
import { CourseBundleListRoute } from 'routes';
import { push } from 'connected-react-router';
import { getCourseBundleById } from 'common/services/courseBundle';
import { ID } from 'lib/id';

export function useCourseBundleByIdQuery(id: ID, enabled?: boolean) {
  const dispatch = useDispatch();

  return useQuery([QueryKey.CourseBundle, id], () => getCourseBundleById(id), {
    enabled,
    onError: () => {
      dispatch(push(CourseBundleListRoute));
    },
  });
}
