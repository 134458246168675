import { useEffect, useRef } from 'react';
import { RequestStatus } from 'state/requestStatus/requestStatus';
import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit';
import { useRequest } from 'lib/useRequest';

interface UseRequestEffectCallbacks {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useRequestEffect = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionCreator: ActionCreatorWithoutPayload | ActionCreatorWithPayload<any>,
  callbacks: UseRequestEffectCallbacks
) => {
  const { status, timestamp } = useRequest(actionCreator);
  const latestCall = useRef(timestamp ?? 0);

  useEffect(() => {
    if (status && timestamp > latestCall.current) {
      latestCall.current = timestamp;
      if (RequestStatus.Ok === status) {
        callbacks.onSuccess?.();
      } else {
        callbacks.onError?.();
      }
    }
  }, [status, timestamp]);
};
