export const fieldsOrder = [
  'title',
  'autoAccept',
  'slug',
  'mainLimit',
  'comment',
  'recommendationPercent',
  'level',
  'learningPoints',
  'speakers',
  'survey',
  'targetGroups',
  'mainCategory',
  'additionalCategories',
  'certificatePoints',
  'image',
  'description',
  'keywords',
];
