import { createAction } from 'lib/createAction';
import { PaginationState } from 'common/models/pagination';
import { CourseSortData } from 'common/models/course';
import { Speaker } from 'common/models/speaker';

export interface CourseListFilters {
  speakerId?: string;
  pagination: PaginationState;
  sort: CourseSortData;
}

export const courseActions = {
  clear: createAction('course/clear')(),
  loadPagination: createAction('course/loadPagination')(),
  setFilters: createAction('course/setFilters')<CourseListFilters>(),
  setPagination: createAction('course/setPagination')<PaginationState>(),
  setSort: createAction('course/setSort')<CourseSortData>(),
  setSpeaker: createAction('course/setSpeaker')<Speaker | null | undefined>(),
  setSpeakerId: createAction('course/setSpeakerId')<string | undefined>(),
} as const;
