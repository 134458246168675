import { createSelector } from 'reselect';
import { RootState } from 'state/reducer';
import { CourseBundleEventListState } from 'state/courseBundleContent/eventList/courseBundleEventListReducer';

export const courseBundleEventListStateSelector = (state: RootState) =>
  state.courseBundleEventList;

export const courseBundleEventListIdSelector = createSelector(
  courseBundleEventListStateSelector,
  (state: CourseBundleEventListState) => state.courseBundleId
);

export const courseBundleEventListCourseIdSelector = createSelector(
  courseBundleEventListStateSelector,
  (state: CourseBundleEventListState) => state.courseId
);

export const courseBundleEventListPaginationSelector = createSelector(
  courseBundleEventListStateSelector,
  (state: CourseBundleEventListState) => state.pagination
);

export const courseBundleEventListSortSelector = createSelector(
  courseBundleEventListStateSelector,
  (state) => state.sort
);

export const courseBundleEventListStatusSelector = createSelector(
  courseBundleEventListStateSelector,
  (state) => state.status
);
