import React, { FC } from 'react';
import { settings } from 'carbon-components';
import styled, { css } from 'styled-components';
import { gray80, white } from '@carbon/colors';
import { spacing03 } from '@carbon/layout';
import { ChevronDown24 } from '@carbon/icons-react';
import {
  InlineLoading,
  unstable_ProgressBar as ProgressBar,
} from 'carbon-components-react';
import { useSelector } from 'react-redux';
import {
  fileUploaderOngoingCountSelector,
  fileUploaderOngoingProgressSelector,
} from 'state/fileUploader/fileUploaderSelectors';
import { label01 } from '@carbon/type';
import {
  FileUploaderStatus,
  fileUploaderStatusColorMapper,
} from 'common/models/fileUploader';
import { fileUploaderFileCountFormatter } from 'common/services/fileUploader';

const Container = styled.div<{ ongoing: boolean }>`
  border-top: 0.0625rem solid ${gray80};
  grid-area: summary;
  margin-top: -0.0625rem;

  .bx--progress-bar__bar {
    background-color: ${({ ongoing }) =>
      fileUploaderStatusColorMapper[
        ongoing ? FileUploaderStatus.Uploading : FileUploaderStatus.Success
      ]};
  }

  .bx--progress-bar__label {
    display: none;
  }
`;

const Toggle = styled.a`
  ${label01};

  align-items: center;
  cursor: pointer;
  color: ${white};
  display: flex;
  height: 48px;
  padding: ${spacing03};
  position: relative;
  transition: background-color 110ms, border-color 110ms;
  user-select: none;

  &:hover {
    background: ${gray80};
  }

  .${settings.prefix}--inline-loading__text {
    color: ${white};
  }
`;

const ToggleIcon = styled(ChevronDown24)<{ $open: boolean }>`
  color: ${white};
  position: absolute;
  right: ${spacing03};
  top: 0.75rem;
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);

  ${({ $open }) =>
    $open &&
    css`
      transform: rotate(-180deg);
    `}
`;

export interface FileUploaderSummaryProps {
  onClick: () => void;
  open: boolean;
}

export const FileUploaderSummary: FC<FileUploaderSummaryProps> = ({
  onClick,
  open,
}) => {
  const ongoingCount = useSelector(fileUploaderOngoingCountSelector);
  const ongoingProgress = useSelector(fileUploaderOngoingProgressSelector);

  return (
    <Container ongoing={ongoingCount > 0}>
      <Toggle onClick={onClick}>
        {ongoingCount > 0 && (
          <InlineLoading
            description={`Wysyłanie ${fileUploaderFileCountFormatter(
              ongoingCount
            )}`}
          />
        )}

        {ongoingCount === 0 && 'Wysyłanie zakończone'}

        <ToggleIcon $open={open} />
      </Toggle>

      <ProgressBar
        label=""
        max={ongoingProgress.total}
        value={ongoingProgress.current}
      />
    </Container>
  );
};
