import { PaginationParams } from 'common/models/pagination';
import { AuthorizedAxios, throwErrorResponse } from 'common/services/axios';
import { QueryKey } from 'config/queryKey';
import { useQuery } from 'react-query';
import { flatten } from 'lib/axios';
import { EventsInWeekDTO } from 'modules/dashboard/types';

export function useQueryEvents(params: PaginationParams) {
  return useQuery<EventsInWeekDTO>([QueryKey.DashboardEvents, params], () => {
    const result = AuthorizedAxios.get('/api/events/week', { params });

    return result.then(flatten).catch(throwErrorResponse);
  });
}
