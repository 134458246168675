import React, { FC, useMemo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Form,
  Grid,
  InlineLoading,
  Row,
  SkeletonText,
  TextArea,
  TextAreaSkeleton,
  TextInput,
  TextInputSkeleton,
} from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import { isCreateParam, RootRoute, SpeakerListRoute } from 'routes';
import { PageHeading } from 'common/components/Heading';
import * as yup from 'yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateSpeakerMutation } from 'common/hooks/speaker/useCreateSpeakerMutation';
import {
  initialSpeakerFormValues,
  mapToSpeakerDTO,
  mapToSpeakerFormModel,
  SpeakerFormModel,
  SpeakerUrlType,
  speakerUrlTypeLabelMap,
} from 'common/models/speaker';
import { useSpeakerByIdQuery } from 'common/hooks/speaker/useSpeakerByIdQuery';
import { mergeRight, pick } from 'ramda';
import { useUpdateSpeakerMutation } from 'common/hooks/speaker/useUpdateSpeakerMutation';
import { Content } from 'common/components/Grid';
import { ImageControl } from 'app/components/ImageControl';
import { convertFileToBase64 } from 'common/services/file';
import { setYupDefaultMessages } from 'common/services/yup';
import { ChangeEventImaginaryTarget } from 'lib/carbonExtraTypes';
import {
  FormAccordion,
  FormAccordionItem,
  FormItem,
} from 'app/components/FormItem';
import { NumberInput } from 'app/components/NumberInput';
import { averageRatingValidationSchema } from 'common/services/validation';
import { pipe } from 'fp-ts/function';

setYupDefaultMessages();

export interface SpeakerFormUrlParams {
  id: string;
}

export const SpeakerForm: FC = () => {
  const createMutation = useCreateSpeakerMutation();
  const params = useParams<SpeakerFormUrlParams>();
  const updateMutation = useUpdateSpeakerMutation(params.id);

  const formSchema = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const schema: any = {
      averageRating: averageRatingValidationSchema,
      bio: yup.string().required(),
      company: yup.string().max(100).required(),
      firstName: yup.string().max(100).required(),
      lastName: yup.string().max(100).required(),
      urls: yup
        .array()
        .length(5)
        .of(
          yup.object({
            address: yup.string().url().trim(),
            urlType: yup
              .mixed()
              .oneOf([
                SpeakerUrlType.Facebook,
                SpeakerUrlType.Instagram,
                SpeakerUrlType.Linkedin,
                SpeakerUrlType.Web,
                SpeakerUrlType.Youtube,
              ])
              .required(),
          })
        ),
    };

    if (isCreateParam(params.id)) {
      schema.photo = yup.mixed().required();
      schema.signature = yup.mixed().required();
    }

    return yup.object(schema);
  }, [params]);

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<SpeakerFormModel>({
    defaultValues: initialSpeakerFormValues,
    resolver: yupResolver(formSchema),
  });

  const { fields: urlsFields } = useFieldArray({
    control,
    name: 'urls',
  });

  const { data, isLoading } = useSpeakerByIdQuery(params.id, (data) =>
    reset(
      pick(
        ['averageRating', 'bio', 'company', 'firstName', 'lastName', 'urls'],
        mapToSpeakerFormModel(data)
      )
    )
  );

  const onSubmit = handleSubmit(async (values) => {
    const signatureString =
      values.signature && (await convertFileToBase64(values.signature));

    pipe({ signatureString }, mergeRight(values), mapToSpeakerDTO, (a) =>
      isCreateParam(params.id)
        ? createMutation.mutate(a)
        : updateMutation.mutate(a)
    );
  });

  const isMutationLoading =
    createMutation.isLoading || updateMutation.isLoading;

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={SpeakerListRoute}>Lista wykładowców</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        {isCreateParam(params.id) && <PageHeading>Nowy wykładowca</PageHeading>}

        {!isCreateParam(params.id) && (
          <PageHeading>
            {isLoading ? (
              <SkeletonText heading={true} width="300px" />
            ) : (
              `${data?.firstName} ${data?.lastName}`
            )}
          </PageHeading>
        )}

        <Form onSubmit={onSubmit} noValidate>
          <Row>
            <Column lg={8}>
              <Row>
                <Column>
                  <FormItem>
                    {isLoading ? (
                      <TextInputSkeleton />
                    ) : (
                      <TextInput
                        id="firstName"
                        invalid={!!errors.firstName}
                        invalidText={errors.firstName?.message}
                        labelText="Imię"
                        placeholder="Imię"
                        {...register('firstName')}
                      />
                    )}
                  </FormItem>
                </Column>

                <Column>
                  <FormItem>
                    {isLoading ? (
                      <TextInputSkeleton />
                    ) : (
                      <TextInput
                        id="lastName"
                        invalid={!!errors.lastName}
                        invalidText={errors.lastName?.message}
                        labelText="Nazwisko"
                        placeholder="Nazwisko"
                        {...register('lastName')}
                      />
                    )}
                  </FormItem>
                </Column>
              </Row>

              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <TextInput
                    id="company"
                    invalid={!!errors.company}
                    invalidText={errors.company?.message}
                    labelText="Firma"
                    placeholder="Firma"
                    {...register('company')}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextAreaSkeleton />
                ) : (
                  <TextArea
                    id="bio"
                    invalid={!!errors.bio}
                    invalidText={errors.bio?.message}
                    labelText="Bio"
                    placeholder="Bio"
                    {...register('bio')}
                  />
                )}
              </FormItem>

              <FormAccordion>
                <FormAccordionItem title="Linki" open={true}>
                  {urlsFields.map((field, index) => (
                    <FormItem key={field.id}>
                      {isLoading ? (
                        <TextInputSkeleton />
                      ) : (
                        <TextInput
                          id={`urls.${index}.address`}
                          invalid={!!errors.urls?.[index]?.address}
                          invalidText={errors.urls?.[index]?.address?.message}
                          labelText={speakerUrlTypeLabelMap[field.urlType]}
                          {...register(`urls.${index}.address`)}
                        />
                      )}
                    </FormItem>
                  ))}
                </FormAccordionItem>
              </FormAccordion>

              {isMutationLoading ? (
                <Button disabled kind="primary" tabIndex={-1}>
                  <InlineLoading
                    description={
                      isCreateParam(params.id)
                        ? 'Dodawanie...'
                        : 'Zapisywanie...'
                    }
                    status="active"
                  />
                </Button>
              ) : (
                <Button kind="primary" tabIndex={0} type="submit">
                  {isCreateParam(params.id) ? 'Dodaj' : 'Zapisz'}
                </Button>
              )}
            </Column>

            <Column lg={4}>
              <FormItem>
                {isLoading ? (
                  <TextInputSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="averageRating"
                    render={({
                      field: { name, value, onChange },
                      fieldState: { error },
                    }) => (
                      <NumberInput
                        id={name}
                        name={name}
                        label="Średnia ocena szkoleń"
                        onChange={(
                          ev:
                            | React.ChangeEvent<HTMLInputElement>
                            | React.MouseEvent<HTMLButtonElement>
                        ) =>
                          onChange(
                            (ev as ChangeEventImaginaryTarget<HTMLInputElement>)
                              .imaginaryTarget.value
                          )
                        }
                        value={value ?? ''}
                        allowEmpty
                        invalid={!!error}
                        invalidText={error?.message}
                      />
                    )}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextAreaSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="photo"
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <ImageControl
                        currentImage={data?.photo.url}
                        id="photo"
                        invalid={!!error}
                        invalidText={error?.message}
                        onChange={(file) => {
                          onChange(file);
                        }}
                      />
                    )}
                  />
                )}
              </FormItem>

              <FormItem>
                {isLoading ? (
                  <TextAreaSkeleton />
                ) : (
                  <Controller
                    control={control}
                    name="signature"
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <ImageControl
                        currentImage={
                          data?.signature &&
                          `data:image/png;base64,${data?.signature}`
                        }
                        id="signature"
                        invalid={!!error}
                        invalidText={error?.message}
                        label={
                          data?.signature ? 'Zmień podpis' : 'Dodaj podpis'
                        }
                        onChange={(file) => {
                          onChange(file);
                        }}
                      />
                    )}
                  />
                )}
              </FormItem>
            </Column>
          </Row>
        </Form>
      </Grid>
    </Content>
  );
};
