import * as yup from 'yup';
import { mixed, object } from 'yup';
import { durationStep } from 'config/elearning';
import {
  averageRatingValidationSchema,
  isElearningDurationValidTest,
  slugValidationSchema,
  validatorMessages,
} from 'common/services/validation';

export const elearningFormValidationSchema = object({
  averageRating: averageRatingValidationSchema,
  certificatePoints: yup.array().min(1).max(5).required(),
  description: yup.string().required(),
  duration: yup
    .number()
    .min(durationStep)
    .positive()
    .integer()
    .test(
      'isDurationValid',
      validatorMessages.duration.invalidStep,
      isElearningDurationValidTest
    ),
  elearningStatus: yup.string().required(),
  keywords: yup.array().min(1).required(),
  learningPoints: yup.array().min(1).required(),
  level: yup.string().required(),
  mainCategory: yup.string().required(),
  recommendationPercent: yup.number().min(1).max(100).integer(),
  slug: slugValidationSchema,
  speakers: yup.array().min(1).required(),
  survey: yup.string().required(),
  targetGroups: yup.array().min(1).required(),
  title: yup.string().max(200).required(),
  visible: yup.boolean().required(),

  coverPhotoFile: mixed().test((value, context) => {
    const { currentCoverPhoto } = context.parent;
    return !(currentCoverPhoto == null && value == null);
  }),
  imageFile: mixed().test((value, context) => {
    const { currentImage } = context.parent;
    return !(currentImage == null && value == null);
  }),
});

export const fieldsOrder = [
  'title',
  'duration',
  'slug',
  'elearningStatus',
  'comment',
  'recommendationPercent',
  'learningPoints',
  'level',
  'targetGroups',
  'speakers',
  'survey',
  'certificatePoints',
  'mainCategory',
  'description',
  'additionalCategories',
  'keywords',
  'imageFile',
  'coverPhotoFile',
];
