import {
  Button,
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import {
  Attachment20,
  Edit20,
  List20,
  WatsonHealthDownloadStudy32,
} from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import {
  CourseBundleElearningListRoute,
  CourseBundleIdRoute,
  courseBundleIdRouteParam,
  getCourseBundleMaterialsRoute,
} from 'routes';
import { ActionsTableCell } from 'app/components/Table';
import { FC } from 'react';
import { DataTableDeleteButton } from 'app/components/DataTableDeleteButton';
import styled from 'styled-components';
import { DataTableButton } from 'app/components/DataTableButton';
import { setParam } from 'common/utils/routing';
import { useDeleteCourseBundleMutation } from 'common/hooks/courseBundle/useDeleteCourseBundleMutation';
import { useCourseBundleParticipantListExportMutation } from 'common/hooks/courseBundle/useCourseBundleParticipantListExportMutation';
import { statusLabelMap } from 'common/models/status';
import { CourseBundleStatus } from 'common/models/courseBundle';

const StyledActionsTableCell = styled(ActionsTableCell)`
  width: 16rem;
`;

export interface CourseBundleListTableRowProps {
  row: DenormalizedRow;
}

export const CourseBundleListTableRow: FC<
  CourseBundleListTableRowProps &
    Pick<DataTableCustomRenderProps, 'getRowProps'>
> = ({ getRowProps, row }) => {
  const deleteMutation = useDeleteCourseBundleMutation();
  const exportParticipantListMutation =
    useCourseBundleParticipantListExportMutation();

  return (
    <TableRow {...getRowProps({ row })}>
      {row.cells.map((cell) => (
        <TableCell key={cell.id}>{cell.value}</TableCell>
      ))}

      <StyledActionsTableCell>
        {row.cells[3]?.value === statusLabelMap[CourseBundleStatus.Public] && (
          <DataTableButton
            hasIconOnly
            iconDescription="Eksportuj uczestników"
            isLoading={exportParticipantListMutation.isLoading}
            kind="ghost"
            onClick={() => {
              exportParticipantListMutation.mutate(row.id);
            }}
            renderIcon={WatsonHealthDownloadStudy32}
            size="small"
            tooltipAlignment="center"
            tooltipPosition="top"
          />
        )}

        <Button
          as={Link}
          hasIconOnly
          iconDescription="Zawartość kursu"
          kind="ghost"
          renderIcon={List20}
          size="small"
          to={setParam(courseBundleIdRouteParam)(
            CourseBundleElearningListRoute
          )(row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <Button
          as={Link}
          hasIconOnly
          iconDescription="Materiały dodatkowe"
          kind="ghost"
          renderIcon={Attachment20}
          size="small"
          to={getCourseBundleMaterialsRoute(row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <Button
          as={Link}
          hasIconOnly
          iconDescription="Edytuj"
          kind="ghost"
          renderIcon={Edit20}
          size="small"
          to={setParam(courseBundleIdRouteParam)(CourseBundleIdRoute)(row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <DataTableDeleteButton
          confirmationTitle="Usuń kurs"
          isLoading={deleteMutation.isLoading}
          onSubmit={() => deleteMutation.mutate(Number(row.id))}
        />
      </StyledActionsTableCell>
    </TableRow>
  );
};
