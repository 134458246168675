import { useDispatch } from 'react-redux';
import React, { FC, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { snackbarActions } from 'state/notification/notificationActions';
import { ReactQueryDevtools } from 'react-query/devtools';
import { TranslatedError } from 'common/models/error';
import { isApiErrorItem } from 'common/services/error';
import { captureException } from 'common/services/sentry';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const ReactQueryClientProvider: FC = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    queryClient.setDefaultOptions({
      ...queryClient.getDefaultOptions(),
      mutations: {
        onError: (errors) => {
          (errors as TranslatedError[]).forEach((translatedError) => {
            captureException(translatedError);
            dispatch(
              snackbarActions.enqueueSnackbar({
                subtitle: isApiErrorItem(translatedError.error)
                  ? translatedError.error.errorCode
                  : undefined,
                title: translatedError.message,
                options: { variant: 'error' },
              })
            );
          });
        },
      },
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
