import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { snackbarActions } from 'state/notification/notificationActions';
import { push } from 'connected-react-router';
import { RootRoute } from 'routes';
import { updateCertificateSettings } from 'common/services/certificateSettings';
import {
  CertificatesAssetsDTO,
  CertificatesAssetsRequestDTO,
} from 'common/models/certificateSettings';

export const useUpdateCertificateSettingsMutation = () => {
  const dispatch = useDispatch();

  return useMutation<
    CertificatesAssetsDTO,
    unknown,
    CertificatesAssetsRequestDTO
  >((params) => updateCertificateSettings(params), {
    onSuccess: () => {
      dispatch(push(RootRoute));
      dispatch(
        snackbarActions.enqueueSnackbar({
          title: 'Dane certyfikatu zostały zapisane pomyślnie',
          options: { variant: 'success' },
        })
      );
    },
  });
};
