import { array, lazy, mixed, number, object, string } from 'yup';
import {
  QuestionControlType,
  SurveyQuestionControlModel,
} from 'app/components/SurveyFormPage/surveyQuestionModel';
import { AnySchema } from 'yup/lib/schema';

// https://github.com/jquense/yup/issues/298
function emptyStringToNull(value: number, originalValue: number | string) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

const scaleSurveyQuestionValidationSchema = object({
  title: string().required(),
  minLabel: string().max(30).nullable(),
  maxLabel: string().max(30).nullable(),
  condition: mixed().when('hasConditionalComment', {
    is: true,
    then: object().shape({
      comparisonOperator: mixed().required(),
      comparisonValue: number().transform(emptyStringToNull).required(),
    }),
  }),
});

const optionSurveyQuestionValidationSchema = object({
  title: string().required(),
  choiceOptions: array()
    .min(1)
    .of(
      object({
        text: string().required(),
      })
    ),
});

const multiOptionQuestionValidationSchema = object({
  title: string().required(),
  customOptionLabel: mixed().when('customOptionAllowed', {
    is: true,
    then: string().required().max(30),
  }),
  customOptionPlaceholder: mixed().when('customOptionAllowed', {
    is: true,
    then: string().nullable(),
  }),
  choiceOptions: array()
    .min(1)
    .of(
      object({
        text: string().required(),
      })
    ),
});

export const surveyFormValidationSchema = object({
  title: string().max(100).required(),
  questions: array()
    .min(1)
    .of(
      lazy((value: SurveyQuestionControlModel) => {
        switch (value.type) {
          case QuestionControlType.Scale:
            return scaleSurveyQuestionValidationSchema;
          case QuestionControlType.Option:
            return optionSurveyQuestionValidationSchema;
          case QuestionControlType.MultiOption:
            return multiOptionQuestionValidationSchema;
        }
      }) as unknown as AnySchema
    ),
});
