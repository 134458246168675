import { CourseParameter } from 'common/models/course';
import { startsWith } from 'ramda';
import { ID } from 'lib/id';
import { StoredImageDTO } from 'common/models/image';

export interface ElearningAgendaItem {
  id: string;
  item: string;
}

export interface ElearningAgendaItemDTO {
  id?: string;
  item: string;
}

export interface ElearningLessonListParams {
  elearningId: string;
}

export enum ElearningLessonListColumn {
  Title = 'LESSON_TITLE',
  Duration = 'DURATION',
}

export interface ElearningLesson {
  readonly coverPhoto: StoredImageDTO;
  readonly id: number;
  readonly videoId: number;

  agenda: ElearningAgendaItem[];
  duration: number;
  title: string;
}

export interface ElearningLessonFormModel {
  agenda: CourseParameter[];
  coverPhotoFile?: File;
  currentCoverPhoto: string;
  duration: number;
  title: string;
}

export interface ElearningLessonVideoUrl {
  durationSeconds: number;
  url: string;
}

export interface ElearningLessonUploadVideoFormModel {
  duration: string;
  file: File;
}

export interface ElearningLessonUploadVideoDTO {
  duration: number;
  elearningLessonId: number;
  file: File;
}

export interface ElearningLessonUploadToAzureDTO {
  file: File;
  url: string;
}

export interface ElearningLessonUpdateVideoDTO {
  blobId: number;
  duration: number;
  elearningLessonId: number;
}

export interface ElearningLessonDTO {
  agenda: ElearningAgendaItemDTO[];
  duration: number;
  title: string;
}

export interface ElearningLessonCreateDTO {
  coverPhoto: File;
  elearningLesson: ElearningLessonDTO;
}

export interface ElearningLessonUpdateDTO {
  coverPhoto?: File;
  id: ID;
  elearningLesson: ElearningLessonDTO;
}

export const initialElearningLessonFormValues: Partial<ElearningLessonFormModel> =
  {
    agenda: [],
    title: '',
  };

export const mapElearningLessonAgendaToFormModel = ({
  id,
  item,
}: ElearningAgendaItem): CourseParameter => ({
  id,
  value: item,
});

export const mapElearningLessonAgendaToDTO = ({
  id,
  value,
}: CourseParameter): ElearningAgendaItemDTO => ({
  id: startsWith('new', id) ? undefined : id,
  item: value,
});

export const mapToElearningLessonDTO = ({
  agenda,
  duration,
  title,
}: ElearningLessonFormModel): ElearningLessonDTO => ({
  agenda: agenda.map(mapElearningLessonAgendaToDTO),
  duration: Number(duration),
  title,
});

export const mapToElearningLessonCreateDTO = (
  params: ElearningLessonFormModel
): ElearningLessonCreateDTO => ({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  coverPhoto: params.coverPhotoFile!,
  elearningLesson: mapToElearningLessonDTO(params),
});

export const mapToElearningLessonUpdateDTO = (
  params: ElearningLessonFormModel,
  id: ID
): ElearningLessonUpdateDTO => ({
  coverPhoto: params.coverPhotoFile,
  elearningLesson: mapToElearningLessonDTO(params),
  id,
});

export const mapToElearningLessonFormModel = ({
  agenda,
  coverPhoto,
  duration,
  title,
}: ElearningLesson): ElearningLessonFormModel => ({
  agenda: agenda.map(mapElearningLessonAgendaToFormModel),
  currentCoverPhoto: coverPhoto.url,
  duration,
  title,
});
