import {
  Button,
  ComboBox,
  DataTableCustomRenderProps,
  Loading,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react';
import { Add20 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { ElearningCreateRoute } from 'routes';
import {
  Table,
  TableContainer,
  TableContent,
  TableLoader,
} from 'app/components/Table';
import React, { FC, useMemo } from 'react';
import { ElearningListTableRow } from 'app/components/ElearningList/ElearningListTableRow';
import { ListItem } from 'common/models/course';
import { Category } from 'common/models/category';
import { useDispatch, useSelector } from 'react-redux';
import { elearningActions } from 'state/elearning/elearningActions';
import { DataTableEmptyRow } from 'app/components/DataTableEmptyRow';
import { isEmpty } from 'ramda';
import { DataTableHeader } from 'app/components/DataTableHeader';
import {
  elearningCategorySelector,
  elearningSortSelector,
} from 'state/elearning/elearningSelectors';
import { ElearningListColumn } from 'common/models/elearning';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/Option';
import { ListFilters } from 'app/components/ListFilters';

export interface ElearningListTableContentProps {
  categories?: Category[];
  isFetching?: boolean;
}

export const ElearningListTableContent: FC<
  ElearningListTableContentProps & DataTableCustomRenderProps
> = ({
  categories = [],
  isFetching = false,

  rows,
  headers,
  getBatchActionProps,
  getTableProps,
  getTableContainerProps,
  getToolbarProps,
  getRowProps,
}) => {
  const dispatch = useDispatch();

  const categoryOption = useSelector(elearningCategorySelector);
  const sortData = useSelector(elearningSortSelector);

  const categoryId = useMemo(
    () =>
      pipe(
        categoryOption,
        getOrElse(() => 'undefined')
      ),
    [categoryOption]
  );

  return (
    <TableContainer title="E-learning" {...getTableContainerProps()}>
      <TableToolbar {...getToolbarProps()}>
        <TableToolbarContent>
          <ListFilters>
            <ComboBox<ListItem>
              id="filterCategory"
              initialSelectedItem={categories.find(
                (item) => String(item.id) === categoryId
              )}
              items={categories}
              itemToString={(item) => item?.label || ''}
              light={true}
              onChange={(value) => {
                dispatch(elearningActions.setCategory(value.selectedItem));
              }}
              placeholder="Wybierz kategorię.."
            />
          </ListFilters>

          <Button
            as={Link}
            kind="primary"
            renderIcon={Add20}
            size="small"
            tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
            to={ElearningCreateRoute}
          >
            Dodaj nowy e-learning
          </Button>
        </TableToolbarContent>
      </TableToolbar>

      <TableContent>
        <Table $loading={isFetching} {...getTableProps()}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <DataTableHeader
                  isSortable={header.key !== ElearningListColumn.CourseSpeaker}
                  isSortHeader={sortData.orderBy === header.key}
                  sortDirection={sortData.direction}
                  onClick={(direction) => {
                    dispatch(
                      elearningActions.setSort({
                        direction,
                        orderBy: header.key as ElearningListColumn,
                      })
                    );
                  }}
                  key={header.key}
                >
                  {header.header}
                </DataTableHeader>
              ))}

              <TableHeader />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(rows) &&
              rows.map((row) => (
                <ElearningListTableRow
                  getRowProps={getRowProps}
                  key={row.id}
                  row={row}
                />
              ))}

            {isEmpty(rows) && <DataTableEmptyRow colSpan={6} />}
          </TableBody>
        </Table>

        {isFetching && (
          <TableLoader>
            <Loading active={true} withOverlay={false} />
          </TableLoader>
        )}
      </TableContent>
    </TableContainer>
  );
};
