import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { createRequestLoadingSelector } from 'state/requestLoading/requestLoadingSelectors';
import { createRequestTimestampSelector } from 'state/requestTimestamp/requestTimestampSelectors';
import { RootState } from 'state/reducer';
import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit';
import { getRequestActionNameUnsafe } from 'lib/requestActions';
import { createRequestStatusSelector } from 'state/requestStatus/requestStatusSelectors';

export const useRequest = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionCreator: ActionCreatorWithoutPayload | ActionCreatorWithPayload<any>
) => {
  const actionName = getRequestActionNameUnsafe(actionCreator.type);

  const requestLoadingPropsSelector = useMemo(
    () => createRequestLoadingSelector(),
    []
  );
  const requestTimestampPropsSelector = useMemo(
    () => createRequestTimestampSelector(),
    []
  );

  const requestStatusPropsSelector = useMemo(
    () => createRequestStatusSelector(),
    []
  );

  const isLoading = useSelector((state: RootState) =>
    requestLoadingPropsSelector(state, actionName)
  );

  const timestamp = useSelector((state: RootState) =>
    requestTimestampPropsSelector(state, actionName)
  );

  const status = useSelector((state: RootState) =>
    requestStatusPropsSelector(state, actionName)
  );

  return { isLoading, timestamp, status } as const;
};
