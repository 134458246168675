import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { history, store } from 'state';
import { AppLayout } from 'app/AppLayout';
import { ReactQueryClientProvider } from 'common/providers/ReactQueryClientProvider';
import { ConnectedRouter } from 'connected-react-router';
import { SnackbarProvider } from 'common/providers/SnackbarProvider';

export const App: FC = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ReactQueryClientProvider>
          <SnackbarProvider>
            <AppLayout />
          </SnackbarProvider>
        </ReactQueryClientProvider>
      </ConnectedRouter>
    </Provider>
  );
};
