import React from 'react';
import { IconBox, IconProps } from 'lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M33 0c1.4991 0 2.7143 1.19391 2.7143 2.66667V61.3333C35.7143 62.8061 34.4991 64 33 64s-2.7143-1.1939-2.7143-2.6667V2.66667C30.2857 1.19391 31.5009 0 33 0z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M17.5775 14.1813c2.2906-2.2505 5.3974-3.5148 8.6368-3.5148h20.3571c1.4991 0 2.7143 1.1939 2.7143 2.6667s-1.2152 2.6667-2.7143 2.6667H26.2143c-1.7997 0-3.5257.7024-4.7982 1.9526-1.2726 1.2502-1.9875 2.9459-1.9875 4.714 0 1.7682.7149 3.4638 1.9875 4.7141 1.2725 1.2502 2.9985 1.9526 4.7982 1.9526h13.5714c3.2394 0 6.3462 1.2643 8.6368 3.5147C50.7131 35.0984 52 38.1506 52 41.3332c0 3.1826-1.2869 6.2349-3.5775 8.4853-2.2906 2.2504-5.3974 3.5147-8.6368 3.5147H16.7143c-1.4991 0-2.7143-1.1939-2.7143-2.6667 0-1.4727 1.2152-2.6666 2.7143-2.6666h23.0714c1.7997 0 3.5257-.7024 4.7982-1.9526 1.2726-1.2503 1.9875-2.946 1.9875-4.7141s-.7149-3.4638-1.9875-4.714c-1.2725-1.2503-2.9985-1.9527-4.7982-1.9527H26.2143c-3.2394 0-6.3462-1.2642-8.6368-3.5147C15.2869 28.9014 14 25.8491 14 22.6665c0-3.1826 1.2869-6.2348 3.5775-8.4852z"
    />
  </IconBox>
);
