import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'app/App';
import { reportWebVitals } from 'reportWebVitals';
import { enableAllPlugins } from 'immer';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { history } from 'state';
import { environment } from 'config/environment';
import {
  CaptureConsole as CaptureConsoleIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
  ReportingObserver as ReportingObserverIntegration,
} from '@sentry/integrations';

import 'index.css';

enableAllPlugins();

Sentry.init({
  dsn: ['production', 'test', 'uat'].includes(environment.sentryEnv)
    ? environment.sentryDsn
    : undefined,
  environment: environment.sentryEnv,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new CaptureConsoleIntegration(),
    new ExtraErrorDataIntegration({
      depth: 10,
    }),
    new ReportingObserverIntegration(),
  ],
  normalizeDepth: 10,
  release: `${environment.appName}@${environment.appVersion}-${environment.sentryEnv}`,
  tracesSampleRate: 0.1,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
