import {
  Button,
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import { Attachment20, Edit20, List20 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import {
  CourseEventListRoute,
  CourseFormRoute,
  getCourseMaterialsRoute,
} from 'routes';
import { useDeleteCourseMutation } from 'common/hooks/course/useDeleteCourseMutation';
import { ActionsTableCell } from 'app/components/Table';
import { FC } from 'react';
import styled from 'styled-components';
import { DataTableDeleteButton } from 'app/components/DataTableDeleteButton';
import { CourseListColumn } from 'common/models/course';
import { format } from 'lib/date';
import { setParam } from 'common/utils/routing';

const StyledActionsTableCell = styled(ActionsTableCell)`
  width: 12rem;
`;

export interface CourseListDataTableRowProps {
  onDeleteSuccess?: (row: DenormalizedRow) => void;
  row: DenormalizedRow;
}

export const CourseListDataTableRow: FC<
  CourseListDataTableRowProps & Pick<DataTableCustomRenderProps, 'getRowProps'>
> = ({ onDeleteSuccess, getRowProps, row }) => {
  const deleteMutation = useDeleteCourseMutation();

  return (
    <TableRow {...getRowProps({ row })}>
      {row.cells.map((cell) => {
        if (cell.info.header === CourseListColumn.StartDateTime) {
          return (
            <TableCell key={cell.id}>
              {cell.value ? format(cell.value, 'dd.MM.yyyy HH:mm') : 'Brak'}
            </TableCell>
          );
        }

        if (cell.info.header === CourseListColumn.Speaker) {
          return (
            <TableCell key={cell.id}>
              <span dangerouslySetInnerHTML={{ __html: cell.value }} />
            </TableCell>
          );
        }

        return <TableCell key={cell.id}>{cell.value}</TableCell>;
      })}

      <StyledActionsTableCell>
        <Button
          as={Link}
          hasIconOnly
          iconDescription="Wydarzenia"
          kind="ghost"
          renderIcon={List20}
          size="small"
          to={setParam(':courseId')(CourseEventListRoute)(row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />
        <Button
          as={Link}
          hasIconOnly
          iconDescription="Materiały szkoleniowe"
          kind="ghost"
          renderIcon={Attachment20}
          size="small"
          to={getCourseMaterialsRoute(row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />
        <Button
          as={Link}
          hasIconOnly
          iconDescription="Edytuj"
          kind="ghost"
          renderIcon={Edit20}
          size="small"
          to={setParam(':courseId')(CourseFormRoute)(row.id)}
          tooltipAlignment="center"
          tooltipPosition="top"
        />

        <DataTableDeleteButton
          confirmationTitle="Usuń szkolenie"
          isLoading={deleteMutation.isLoading}
          onSubmit={() =>
            deleteMutation.mutate(row.id, {
              onSuccess: () => onDeleteSuccess && onDeleteSuccess(row),
            })
          }
        />
      </StyledActionsTableCell>
    </TableRow>
  );
};
