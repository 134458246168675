import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { CourseBundleListRoute } from 'routes';
import { snackbarActions } from 'state/notification/notificationActions';
import { queryClient } from 'common/providers/ReactQueryClientProvider';
import { QueryKey } from 'config/queryKey';
import { createCourseBundle } from 'common/services/courseBundle';
import { CourseBundleUpdateDTO } from 'common/models/courseBundle';

export function useCreateCourseBundleMutation() {
  const dispatch = useDispatch();

  return useMutation(
    ({ createBundleDTO, image }: Required<CourseBundleUpdateDTO>) =>
      createCourseBundle({ createBundleDTO, image }),
    {
      onSuccess: () => {
        dispatch(push(CourseBundleListRoute));
        dispatch(
          snackbarActions.enqueueSnackbar({
            title: 'Kurs został dodany pomyślnie',
            options: { variant: 'success' },
          })
        );
        queryClient.invalidateQueries(QueryKey.CourseBundle);
      },
    }
  );
}
