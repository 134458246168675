import { useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { CourseListRoute, isCreateParam } from 'routes';
import { snackbarActions } from 'state/notification/notificationActions';
import { useDispatch } from 'react-redux';
import { getCourseById } from 'common/services/course';
import { push } from 'connected-react-router';
import { isNilEmpty } from 'lib/isNilEmpty';

export const useCourseByIdQuery = (id: string) => {
  const dispatch = useDispatch();

  return useQuery([QueryKey.Course, id], () => getCourseById(id), {
    enabled: !isNilEmpty(id) && !isCreateParam(id),
    onError: () => {
      dispatch(push(CourseListRoute));
      dispatch(
        snackbarActions.enqueueSnackbar({
          title: 'Kurs nie został znaleziony',
          options: { variant: 'error' },
        })
      );
    },
  });
};
