import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { snackbarActions } from 'state/notification/notificationActions';
import { queryClient } from 'common/providers/ReactQueryClientProvider';
import { QueryKey } from 'config/queryKey';
import { updateUserAccountPlusStatus } from 'common/services/user';

export function useUpdateUserAccountPlusStatusMutation() {
  const dispatch = useDispatch();

  return useMutation(updateUserAccountPlusStatus, {
    onSuccess: () => {
      dispatch(
        snackbarActions.enqueueSnackbar({
          title: 'Użytkownik został zmodyfikowany pomyślnie',
          options: { variant: 'success' },
        })
      );
      queryClient.invalidateQueries(QueryKey.UserList);
    },
  });
}
