import { useQuery } from 'react-query';
import { QueryKey } from 'config/queryKey';
import { ResultResponse } from 'common/models/response';
import { getSurveyList } from 'common/services/survey';
import { SurveyListItem } from 'common/models/survey';

const page = 1;
const pageSize = 1500;

export const useAllSurveysListQuery = () => {
  return useQuery<ResultResponse<SurveyListItem>>(
    [QueryKey.SurveyList, page, pageSize],
    () => getSurveyList({ page, pageSize })
  );
};
