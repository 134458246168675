import React, { FC } from 'react';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core';
import { StoreSnackbarController } from 'common/components/StoreSnackbarController';

const useStyles = makeStyles({
  SnackbarProviderRoot: {
    pointerEvents: 'all',
    zIndex: 9000,
  },
});

export const SnackbarProvider: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <NotistackSnackbarProvider
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      classes={{
        containerRoot: classes.SnackbarProviderRoot,
      }}
      maxSnack={5}
    >
      {children}
      <StoreSnackbarController />
    </NotistackSnackbarProvider>
  );
};
