import React, { FC, useCallback } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonSkeleton,
  Column,
  FileUploaderSkeleton,
  Form,
  Grid,
  Row,
  TextInput,
  TextInputSkeleton,
} from 'carbon-components-react';
import { Link, useParams } from 'react-router-dom';
import {
  ElearningFormRoute,
  elearningIdRouteParam,
  ElearningLessonFormRoute,
  elearningLessonIdRouteParam,
  ElearningLessonListRoute,
  ElearningListRoute,
  RootRoute,
} from 'routes';
import { PageHeading } from 'common/components/Heading';
import { Content } from 'common/components/Grid';
import { useElearningByIdQuery } from 'common/hooks/elearning/useElearningByIdQuery';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { mixed, object, string } from 'yup';
import { FormItem } from 'app/components/FormItem';
import { useDispatch } from 'react-redux';
import { VideoUploadControl } from 'app/components/VideoUploadControl';
import { fileUploaderActions } from 'state/fileUploader/fileUploaderActions';
import { push } from 'connected-react-router';
import { v4 as uuidv4 } from 'uuid';
import {
  buildDurationString,
  durationRegExp,
  parseDuration,
} from 'lib/duration';
import {
  isVideoDurationValidTest,
  validatorMessages,
} from 'common/services/validation';
import { useElearningLessonVideoQuery } from 'common/hooks/elearningLesson/useElearningLessonVideoQuery';
import { useElearningLessonByIdQuery } from 'common/hooks/elearningLesson/useElearningLessonByIdQuery';
import { ElearningLessonUploadVideoFormModel } from 'common/models/elearningLesson';
import { uploadElearningLessonVideo } from 'common/services/elearningLesson';
import { setParam, setParams } from 'common/utils/routing';

const validationSchema = object({
  duration: string()
    .matches(durationRegExp, { excludeEmptyString: true })
    .test(
      'isDurationValid',
      validatorMessages.duration.unknownUnit,
      isVideoDurationValidTest
    )
    .required(),
  file: mixed().required(),
});

export interface ElearningLessonUploadVideoUrlParams {
  elearningId: string;
  elearningLessonId: string;
}

export const ElearningLessonUploadVideo: FC = () => {
  const dispatch = useDispatch();
  const { elearningId, elearningLessonId } =
    useParams<ElearningLessonUploadVideoUrlParams>();

  const { data: elearningData, isFetching: isElearningFetching } =
    useElearningByIdQuery(elearningId);

  const { data: elearningLessonData, isFetching: isElearningLessonFetching } =
    useElearningLessonByIdQuery(elearningId, elearningLessonId);

  const { data: videoData, isFetching: isVideoFetching } =
    useElearningLessonVideoQuery(elearningLessonId);

  const methods = useForm<Partial<ElearningLessonUploadVideoFormModel>>({
    resolver: yupResolver(validationSchema),
  });

  const handleDurationChange = useCallback(
    (value) => {
      if (value) {
        methods.setValue('duration', buildDurationString(value));
        methods.trigger('duration');
      }
    },
    [methods]
  );

  const handleFileChange = useCallback(
    (value) => {
      methods.setValue('file', value || undefined);
      methods.trigger('file');
    },
    [methods]
  );

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        fileUploaderActions.uploadFile({
          id: uuidv4(),
          title: `Film lekcji "${elearningLessonData?.title}"`,
          uploadFn: (onUploadProgress) =>
            uploadElearningLessonVideo(
              {
                duration: parseDuration(values.duration, 's') || 0,
                elearningLessonId: Number(elearningLessonData?.id),
                file: values.file,
              },
              onUploadProgress
            ),
        })
      );
      dispatch(
        push(
          setParam(elearningIdRouteParam)(ElearningLessonListRoute)(elearningId)
        )
      );
    },
    [elearningLessonData]
  );

  const isLoading =
    isElearningFetching || isElearningLessonFetching || isVideoFetching;

  return (
    <Content>
      <Grid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={RootRoute}>PFP Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={ElearningListRoute}>Lista e-learningów</Link>
          </BreadcrumbItem>

          {elearningData && (
            <BreadcrumbItem>
              <Link
                to={setParam(elearningIdRouteParam)(ElearningFormRoute)(
                  elearningId
                )}
              >
                {elearningData.title}
              </Link>
            </BreadcrumbItem>
          )}

          <BreadcrumbItem>
            <Link
              to={setParam(elearningIdRouteParam)(ElearningLessonListRoute)(
                elearningId
              )}
            >
              Lista lekcji
            </Link>
          </BreadcrumbItem>

          {elearningLessonData && (
            <BreadcrumbItem>
              <Link
                to={setParams(
                  elearningIdRouteParam,
                  elearningLessonIdRouteParam
                )(ElearningLessonFormRoute)(elearningId, elearningLessonId)}
              >
                {elearningLessonData.title}
              </Link>
            </BreadcrumbItem>
          )}
        </Breadcrumb>

        <PageHeading>Dodaj / zmień film</PageHeading>

        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            {isLoading && (
              <FormItem>
                <FileUploaderSkeleton />
              </FormItem>
            )}

            {!isLoading && (
              <FormItem>
                <Controller
                  control={methods.control}
                  name="file"
                  render={({ fieldState: { error } }) => (
                    <VideoUploadControl
                      currentVideoDuration={videoData?.durationSeconds}
                      currentVideoUrl={videoData?.url}
                      invalid={!!error}
                      invalidText={error?.message}
                      onDurationChange={handleDurationChange}
                      onFileChange={handleFileChange}
                    />
                  )}
                />
              </FormItem>
            )}

            <Row>
              <Column lg={6}>
                <FormItem>
                  {isLoading ? (
                    <TextInputSkeleton />
                  ) : (
                    <TextInput
                      id="duration"
                      invalid={methods.formState.errors.duration != null}
                      invalidText={methods.formState.errors.duration?.message}
                      labelText="Czas trwania"
                      {...methods.register('duration')}
                    />
                  )}
                </FormItem>
              </Column>
            </Row>

            {isLoading ? (
              <ButtonSkeleton />
            ) : (
              <Button kind="primary" tabIndex={0} type="submit">
                Wyślij
              </Button>
            )}
          </Form>
        </FormProvider>
      </Grid>
    </Content>
  );
};
