import React, { FC } from 'react';
import {
  Button,
  Form,
  InlineLoading,
  TextInput,
} from 'carbon-components-react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { surveyFormValidationSchema } from 'app/components/SurveyFormPage/surveyFormValidationSchema';
import styled from 'styled-components';
import { SurveyFormModel } from 'app/components/SurveyFormPage/surveyFormModel';
import { SurveyQuestionControl } from 'app/components/SurveyFormPage/SurveyQuestionControl';
import { setYupDefaultMessages } from 'common/services/yup';

setYupDefaultMessages();

const FormItem = styled.div`
  margin-bottom: 2rem;
`;

export interface SurveyFormProps {
  value?: SurveyFormModel;
  submitLabel: string;
  isBusy?: boolean;
  onSubmit: (v: SurveyFormModel) => void;
}

export const SurveyForm: FC<SurveyFormProps> = ({
  value = {},
  submitLabel,
  isBusy = false,
  onSubmit,
}) => {
  const methods = useForm<SurveyFormModel>({
    resolver: yupResolver(surveyFormValidationSchema),
    defaultValues: value,
  });

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormItem>
          <TextInput
            id="title"
            invalid={!!methods.formState.errors.title}
            invalidText={methods.formState.errors.title?.message}
            labelText="Tytuł"
            {...methods.register('title')}
          />
        </FormItem>

        <FormItem>
          <SurveyQuestionControl name="questions" label="Lista pytań" />
        </FormItem>

        {isBusy ? (
          <Button disabled kind="primary" tabIndex={-1}>
            <InlineLoading description={'Zapisywanie...'} status="active" />
          </Button>
        ) : (
          <Button kind="primary" tabIndex={0} type="submit">
            {submitLabel}
          </Button>
        )}
      </Form>
    </FormProvider>
  );
};
